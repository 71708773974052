/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect } from "react";
import { decodeToken } from "react-jwt";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { getAQuestion } from "../../config/question";
import { setQuestions } from "../../reducers/practiceElectiveSlice";
import PreviewExerciseLessonContent from "./PreviewExerciseLessonContent";

const PreviewExerciseLesson = ({ questions }) => {
  // const [questions, setQuestions] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const getQuestions = async (questionIds, token) => {
      try {
        const listQuestion = await axios.all(
          questionIds.map(async (questionId, index) => {
            const { result } = await getAQuestion(questionId, token);
            result.questionIndex = index;
            if (index === 0) {
              result.visible = true;
            } else if (index === questionIds.length - 1) {
              result.isLast = true;
            }
            return result;
          })
        );
        dispatch(setQuestions(listQuestion));
      } catch (error) {
        console.log("errr", error.response?.data?.errorMessage);
      }
    };
    const arrayURL =
      process.env.REACT_APP_ENV === "development"
        ? JSON.parse(process.env.REACT_APP_DEV_ARRAY_ADMIN_URL)
        : JSON.parse(process.env.REACT_APP_ARRAY_ADMIN_URL);
    const handleRes = (e) => {
      if (arrayURL.some((url) => e.origin.includes(url))) {
        if (e?.data.questionIds.length > 0 && e?.data.token) {
          var roles = ["CREATOR", "REVIEWER"];
          if (
            !decodeToken(e?.data.token).roles.some((role) =>
              roles.includes(role)
            )
          ) {
            navigate("/");
          } else {
            getQuestions(e?.data.questionIds, e?.data.token);
          }
        }
      }
    };
    window.addEventListener("message", handleRes);
    return () => window.removeEventListener("message", handleRes);
  }, [navigate]);
  return (
    <div className="m-auto" style={{ maxWidth: "60rem", minHeight: "100vh" }}>
      {questions &&
        questions.map((question, index) => {
          if (question.visible) {
            return <PreviewExerciseLessonContent key={index} data={question} />;
          } else {
            return null;
          }
        })}
    </div>
  );
};
function mapStateToProps(state) {
  return {
    questions: state.practiceElective.questions,
  };
}

export default connect(mapStateToProps)(PreviewExerciseLesson);
