import React, { useEffect } from "react";

const AndroidApp = () => {
  useEffect(() => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=vn.kurio.android";
  }, []);
  return <></>;
};

export default AndroidApp;
