/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { decodeToken } from "react-jwt";
import { useNavigate } from "react-router";
import { getAQuestion } from "../../config/question";
import PreviewQuestionContent from "./PreviewQuestionContent";

const PreviewQuestion = () => {
  const [question, setQuestion] = useState(null);
  const navigate = useNavigate();
  // const cookies = new Cookies();
  // const token = cookies.get("signinUser");
  useEffect(() => {
    const getQuestion = async (questionId, token) => {
      try {
        const { result } = await getAQuestion(questionId, token);
        setQuestion(result);
      } catch (error) {
        console.log("errr", error.response?.data?.errorMessage);
      }
    };
    const arrayURL =
      process.env.REACT_APP_ENV === "development"
        ? JSON.parse(process.env.REACT_APP_DEV_ARRAY_ADMIN_URL)
        : JSON.parse(process.env.REACT_APP_ARRAY_ADMIN_URL);
    const handleRes = (e) => {
      if (arrayURL.some((url) => e.origin.includes(url))) {
        if (e?.data.questionId && e?.data.token) {
          var roles = ["CREATOR", "REVIEWER"];
          if (
            !decodeToken(e?.data.token).roles.some((role) =>
              roles.includes(role)
            )
          ) {
            navigate("/");
          } else {
            getQuestion(e?.data.questionId, e?.data.token);
          }
        }
      }
    };
    window.addEventListener("message", handleRes);
    return () => window.removeEventListener("message", handleRes);
  }, [navigate]);
  return (
    <div className="m-auto" style={{ maxWidth: "60rem", minHeight: "100vh" }}>
      {question && <PreviewQuestionContent data={question} />}
    </div>
  );
};

export default PreviewQuestion;
