import { decodeToken } from "react-jwt";
import instance from "./instance";

const practiceUrl = "/practice/v1/user/practice-quiz";
const practiceAdminUrl = "/practice/v1/admin/quiz";

export const getAPracticeQuestion = (id, token, page, size) => {
  const roles = ["CREATOR", "REVIEWER"];
  const decode = decodeToken(token);
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  if (
    token !== undefined &&
    decode.roles.some((role) => roles.includes(role))
  ) {
    return instance.get(`${practiceAdminUrl}/get-a-quiz/${id}`,config);
  } else {
    // checkAndCallDailyLoginAPI()
    return instance.get(
      `${practiceUrl}/get-practice-questions/${id}`
    );
  }
};

export const createNewPractices = (id, token) => {
  // checkAndCallDailyLoginAPI()
  return instance.put(`${practiceUrl}/create-new-practice/${id}`, []);
};

export const submitPracticeQuestionAnswers = (id, answer) => {
  // checkAndCallDailyLoginAPI()
  return instance.post(`${practiceUrl}/submit-practice-answers/${id}`, answer);
};
