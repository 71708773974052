import React from "react";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";

const ErrorServerTime = () => {
  // const arrayPath = [
  //   "/account",
  //   "/profile",
  //   "/upgrade",
  //   "/list-unit",
  //   "/exercise",
  //   "/activities",
  //   "/achievement",
  //   "/",
  // ];
  // const cookies = new Cookies();
  // const dispatch = useDispatch();
  const handleClick = () => {
    // const listCookies = cookies.getAll();
    // localStorage.clear();
    // dispatch(clearStateGrade());
    // Object.keys(listCookies).forEach((cookie) => {
    //   cookies.remove(cookie);
    // });
    // arrayPath
    //   .forEach((path) => {
    //     if (cookies.get("signinUser", { path: path })) {
    //       cookies.remove("signinUser", { path: path });
    //     }
    //   })
    //   ?.then(() => {
    //     window.location.href = process.env.REACT_APP_ENV === "development"? process.env.REACT_APP_DEV_LANDING_PAGE_URL : process.env.REACT_APP_LANDING_PAGE_URL;;
    //   })
    //   .catch((error) => {
    //     console.error("Error removing cookie:", error);
    //   });
    // const check = arrayPath.every((path) =>
    //   cookies.get("signinUser", { path: path })
    // );
    // if (!check) {
    //   window.location.href = process.env.REACT_APP_ENV === "development"? process.env.REACT_APP_DEV_LANDING_PAGE_URL : process.env.REACT_APP_LANDING_PAGE_URL;;
    // }
    window.location.href = window.location.origin + "/";
  };
  return (
    <div
      className="is-flex is-align-items-center is-justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div
        className="has-text-centered is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
        style={{ gap: "2.875rem" }}
      >
        <img src="/images/error/kurio-error-image.svg" alt="loading" />
        <p className="has-text-centered has-text-kurio-main-color-black">Thời gian trên máy của bạn không phải múi giờ tiêu chuẩn, hãy đồng bộ hoá hoặc liên hệ số <b>0971 500 120</b> để được giúp đỡ</p>
        <PrimaryButtonPageLevel
          isDarkColor={true}
          onClick={handleClick}
          style={{ height: "4rem" }}
        >
          Về Trang Chủ
        </PrimaryButtonPageLevel>
      </div>
    </div>
  );
};

export default ErrorServerTime;
