import { useState } from "react";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import { checkImage } from "../../constants/checkImage";
import { loadWidthImage } from "../../constants/loadWidthImage";
import QuestionNumberTag from "../../detail-components/QuestionNumberTag";
import ScrollRef from "../../detail-components/ScrollRef";
import jsonParser from "../helper/jsonParser";
import { cacheImage, getCacheImage, getImageUrl } from "../helper/parseImage";

function QPrompt1_1({ data, order, isLast }) {
  const content = data?.content || data?.prompt;
  const contentText =
    content?.texts[0] !== null &&
      content?.texts[0] !== undefined &&
      content?.texts[0]?.length > 0
      ? jsonParser(content?.texts[0])
      : content?.texts[0];
  const [isLoading, setIsLoading] = useState(
    checkImage(content?.images[0])
      ? getCacheImage([content?.images[0]])
        ? false
        : true
      : false
  );
  var key = getImageUrl(content?.images[0]);
  loadWidthImage(key, (err, img) => {
    cacheImage([key]);
    setIsLoading(false);
  });

  return (
    <div>
      {!isLast &&
        !isLoading &&
        <ScrollRef />}
      <BackgroundImageFromUrl
        objectId={content?.backgroundImage}
        className={`${isLoading ? "is-hidden" : ""
          } has-text-centered is-flex is-flex-direction-column is-justify-content-center is-align-items-center has-text-kurio-main-color-black has-background-kurio-main-color-white`}
        style={{
          borderRadius: "1rem 1rem 0 0",
          fontSize: "1.25rem",
          lineHeight: "160%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          gap: "2rem",
        }}
      >
        <div
          className={`${isLoading ? "is-hidden" : ""
            } m-auto has-text-centered is-flex is-flex-direction-column is-justify-content-center is-align-items-center`}
          style={{
            maxWidth: "40rem",
            padding: "2rem 2rem 0rem",
            gap: "1rem",
          }}
        >
          <div>
            {data?.template.question !== null &&
              data?.template.question &&
              data?.template.question.length > 0 && (
                <div className="">
                  <QuestionNumberTag
                    number={order === 0 ? data?.order : order}
                  />
                </div>
              )}
          </div>
          {contentText}
        </div>
        <img style={{ width: "100%" }} src={key} alt={content?.images[0]} />
      </BackgroundImageFromUrl>
    </div>
  );
}

export default QPrompt1_1;
