import React from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import PaginationBigButton from "../../../components/button/pagination-button/PaginationBigButton";
import { clearLessons } from "../../../reducers/gradesSlice";
import { clearStateQuestion } from "../../../reducers/questionsSlice";
import "../../../styling/layout-components/LessonLeftWrapper.css";
import jsonParser from "../../../templates/helper/jsonParser";

function LessonLeftWrapper({
  header,
  sections,
  selected,
  handleSectionChange,
  handleSetShowPopup,
  heightScreen,
  listAnUnit, setScrollTop
}) {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const dispatch = useDispatch();
  // const token = cookies.get("signinUser");
  // var unitId = cookies.get("unitId");
  const handleChange = (val) => {
    if (sections) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    setScrollTop(true)
    handleSectionChange(val);
  };
  const handleClose = () => {
    dispatch(clearStateQuestion());
    dispatch(clearLessons());
    localStorage.removeItem("arrowDown")
    navigate("/list-lesson");
  };

  const checkTitleLesson = () => {
    if (!cookies.get("indexLesson")) {
      return listAnUnit?.lessons.findIndex(obj => obj._id === listAnUnit?.lessons.findIndex(obj => obj._id === cookies.get("lessonId"))) > 9 ?
        listAnUnit?.lessons.findIndex(obj => obj._id === cookies.get("lessonId")) :
        `0` + listAnUnit?.lessons.findIndex(obj => obj._id === cookies.get("lessonId"))
    } else {
      return cookies.get("indexLesson")
    }
  }


  return (
    <div
      className="is-flex is-flex-direction-column is-align-items-flex-start"
      style={{ padding: "2.5rem", gap: "2rem", height: "100%" }}
    >
      <div
        className=" is-flex is-flex-gap-3 is-align-items-center is-justify-content-center has-text-weight-bold has-background-kurio-main-color-white is-clickable"
        onClick={handleClose}
        style={{
          maxWidth: "5.375rem",
          gap: "0.75rem",
          fontSize: "0.85rem",
          height: "2.5rem",
          color: "#0A2A66",
          padding: "0.5rem 0.75rem",
          border: "1.5px solid #0a2a66",
          boxShadow: " 0.125rem 0.125rem 0rem 0rem #0a2a66",
          borderRadius: "1rem",
          lineHeight: "normal",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="7"
          height="15"
          viewBox="0 0 7 15" // button/button-close-lesson.svg
          fill="none"
        >
          <path
            d="M5.82866 14.4997C5.53666 14.4997 5.24666 14.3727 5.04866 14.1267L0.22066 8.12674C-0.07734 7.75574 -0.07334 7.22574 0.23166 6.85974L5.23166 0.85974C5.58466 0.43574 6.21566 0.37874 6.64066 0.73174C7.06466 1.08474 7.12166 1.71574 6.76766 2.13974L2.29266 7.51074L6.60766 12.8727C6.95366 13.3027 6.88566 13.9327 6.45466 14.2787C6.27066 14.4277 6.04866 14.4997 5.82866 14.4997Z"
            fill="#0A2A66"
          />
        </svg>
        <p>Thoát</p>
      </div>
      {heightScreen <= 35 && header && (
        <div
          className="has-text-kurio-main-color-black is-flex is-flex-direction-row is-flex-gap-4 is-justify-content-center is-align-items-center is-align-self-stretch py-4 has-background-kurio-main-color-light-gray has-text-weight-bold is-clickable"
          style={{ width: "100%", borderRadius: "1rem" }}
          onClick={() => handleSetShowPopup(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M9 6C9 5.448 9.448 5 10 5C10.552 5 11 5.448 11 6V11C11 11.552 10.552 12 10 12C9.448 12 9 11.552 9 11V6ZM9 14C9 13.448 9.448 13 10 13C10.552 13 11 13.448 11 14C11 14.552 10.552 15 10 15C9.448 15 9 14.552 9 14ZM10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C14.411 2 18 5.589 18 10C18 14.411 14.411 18 10 18ZM10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0Z"
              fill="#0A2A66"
            />
          </svg>
          <p
            style={{
              fontSize: "0.875rem",
              fontStyle: "normal",
              lineHeight: "normal",
            }}
          >
            Thông tin Bài học
          </p>
        </div>
      )}
      {header && heightScreen > 35 && (
        <div
          className="is-flex is-flex-direction-column has-text-kurio-main-color-black is-flex-gap-4 is-align-items-flex-start"
          style={{ maxWidth: "100%" }}
        >
          <div
            className="has-text-weight-bold is-uppercase has-background-kurio-gray-light-2 tooltip-lesson-container is-relative"
            style={{
              fontSize: "0.875rem",
              lineHeight: "normal",
              padding: "0.5rem 0.75rem",
              borderRadius: "1rem",
              maxWidth: "100%",
              height: "2rem",
            }}
          >
            {header?.unit?.name?.length > 20 && (
              <p
                className="tooltip-lesson has-background-kurio-purple-light-2"
                style={{
                  position: "absolute",
                  top: "-2rem",
                  left: "3.5rem",
                  zIndex: "2",
                  padding: "0.5rem 0.75rem",
                  borderRadius: "1rem 1rem 1rem 0rem",
                  width: "100%",
                  fontSize: "0.875rem",
                  lineHeight: "normal",
                }}
              >
                {jsonParser(header?.unit?.name, 0, "UnitName")}
              </p>
            )}
            <p
              style={{
                WebkitBoxOrient: "vertical",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {jsonParser(header?.unit?.name, 0, "UnitName")}
            </p>
          </div>
          <div className="is-flex is-flex-direction-column is-align-items-flex-start is-uppercase has-text-weight-bold">
            <p
              style={{
                fontSize: "0.875rem",
                lineHeight: "normal",
                fontStyle: "normal",
              }}
            >
              Bài
            </p>
            <p
              className=""
              style={{
                fontSize: "2.5rem",
                lineHeight: "normal",
                fontStyle: "normal",
              }}
            >{`${!window.location.pathname.includes("preview") ? Number(checkTitleLesson()) === 0 ? "Khởi động" : checkTitleLesson() : "Preview"}`}</p>
          </div>
          {Number(checkTitleLesson()) !== 0 && (
            <p
              className="has-text-weight-medium"
              style={{ fontSize: "1.125rem", lineHeight: "normal" }}
            >
              {jsonParser(header.title, 0, "Title")}
            </p>
          )}
        </div>
      )}
      {sections && (
        <div
          className="columns is-multiline p-0 m-0"
          style={{ gap: "0.75rem", width: "100%" }}
        >
          {sections.map((section, index) => (
            <PaginationBigButton
              disabled={!section.visible}
              isClick={section.visible && index + 1 === selected}
              onClick={() => handleChange(index + 1)}
              isSection={true}
              key={index}
            >
              {index + 1}
            </PaginationBigButton>
          ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    header: state.questions.header,
    sections: state.questions.sections,
    units: state.grades.units,
  };
}

export default connect(mapStateToProps)(LessonLeftWrapper);
