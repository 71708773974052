import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import jsonParser from "../../templates/helper/jsonParser";
import "./SelectUnit.style.css";
const SelectUnit = ({ grades, handleSelect, listGrades, height }) => {
  const [checkAll, setCheckAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [elementsWithEllipsis, setElementsWithEllipsis] = useState([]);
  // const dispatch = useDispatch();
  // const cookies = new Cookies();
  // const page = 1;
  // const size = 50;
  // const token = cookies.get("signinUser");
  // const units = useSelector(selectUnits);

  const handleCheckAll = () => {
    setCheckAll(!checkAll);
    setSelected(listGrades.map((item) => item._id));
    handleSelect(listGrades.map((item) => item._id));
    if (checkAll) {
      setSelected([]);
      handleSelect([]);
    }
  };

  const handleCheck = (_id) => {
    if (selected.includes(_id)) {
      setSelected(selected.filter((item) => item !== _id));
      setCheckAll(false);
      handleSelect(selected.filter((item) => item !== _id));
    } else {
      if (checkAll) {
        setSelected(selected.filter((item) => item !== _id));
        handleSelect(
          listGrades.map((item) => item._id).filter((i) => i !== _id)
        );
      } else {
        if ([...selected, _id].length === listGrades?.length) {
          setCheckAll(true);
        } else {
          setCheckAll(false);
        }
        setSelected([...selected, _id]);
        handleSelect([...selected, _id]);
      }
    }
  };

  useEffect(() => {
    const elements = document.querySelectorAll(".ellipsis-text");
    const elementsWithEllipsisArray = [];
    elements.forEach((element) => {
      if (element.scrollHeight > element.clientHeight) {
        elementsWithEllipsisArray.push(element?.innerText);
      }
    });
    setElementsWithEllipsis(elementsWithEllipsisArray);
  }, [listGrades]);

  return (
    <div className="is-flex is-flex-direction-column" style={{ gap: "1.5rem" }}>
      <div
        className="main-content-select columns is-center is-multiline m-auto"
        style={{
          width: "43.75rem",
          gap: "1rem",
          maxHeight: "17.5rem",
          overflowY: "overlay",
        }}
      >
        <div className="is-flex is-flex-direction-row" style={{ gap: "1rem" }}>
          <button
            className="btn-select-all"
            checked={checkAll}
            onClick={handleCheckAll}
            style={
              checkAll
                ? { backgroundColor: "#0A2A66", color: "#FAFBFC" }
                : { boxShadow: "0.125rem 0.125rem 0rem 0rem #0A2A66" }
            }
          >
            <div
              className="columns is-center is-align-items-center is-justify-content-center"
              style={{
                padding: "0rem 1.5rem 0rem 0.625rem",
                gap: "1.5rem",
                cursor: "pointer",
              }}
            >
              <p
                className="is-narrow"
                style={{
                  padding: "1rem 0",
                  fontSize: "0.875rem",
                }}
              >
                Tất cả chương
              </p>
              <input
                name="checkAll"
                onClick={handleCheckAll}
                onChange={handleCheckAll}
                checked={checkAll}
                type={"checkbox"}
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  backgroundColor: "#FAFBFC",
                  cursor: "pointer",
                }}
              />
            </div>
          </button>
          {listGrades &&
            listGrades.map((item, index) => {
              if (index === 0) {
                return (
                  <button
                    onClick={() => {
                      handleCheck(item._id);
                    }}
                    key={index}
                    className="column btn-select"
                    style={
                      checkAll || selected.includes(item._id)
                        ? {
                            color: "#ffffff",
                            backgroundColor: "#0A2A66",
                          }
                        : { boxShadow: "0.125rem 0.125rem 0rem 0rem #0A2A66" }
                    }
                  >
                    <div
                      className="columns is-center is-align-items-center is-relative"
                      style={{
                        padding: "0rem 1.5rem 0rem 0.625rem",
                        gap: "1.5rem",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className="column is-narrow p-0"
                        style={{
                          width: "6.875rem",
                          borderRadius: "0.5rem",
                          height: "4.5rem",
                        }}
                      >
                        <ImageFromUrl
                          objectId={item.image}
                          alt={item.image}
                          style={{
                            borderRadius: "0.5rem",
                            height: "100%",
                            maxWidth: "6.875rem",
                          }}
                        />
                      </div>
                      <div
                        className="column has-text-weight-bold is-flex is-align-items-end is-flex-direction-row tooltip-unit-container"
                        style={{
                          padding: "1rem 0",
                          // width: "20rem",
                          fontSize: "0.875rem",
                        }}
                      >
                        {elementsWithEllipsis?.length > 0 &&
                          elementsWithEllipsis.some((element) =>
                            element.includes(item.unitName)
                          ) && (
                            <p
                              className="tooltip-unit has-text-kurio-main-color-black has-text-weight-medium"
                              style={{
                                position: "absolute",
                                top: "-0.25rem",
                                left: "2.75rem",
                                zIndex: 999,
                                padding: "0.5rem 0.75rem",
                                borderRadius: "0.5rem",
                                width: "18rem",
                                fontSize: "0.875rem",
                                lineHeight: "normal",
                                background: "#fffec8",
                                filter:"saturate(50%)"
                              }}
                            >
                              {jsonParser(item.unitName, 0, "UnitName")}
                            </p>
                          )}
                        <p
                          className="is-narrow ellipsis-text"
                          style={
                            checkAll || selected.includes(item._id)
                              ? {
                                  color: "#fff",
                                }
                              : {
                                  color: "#0A2A66",
                                }
                          }
                        >
                          {item.unitName &&
                            jsonParser(
                              item.unitName,
                              index,
                              "select-unit-name"
                            )}
                        </p>
                      </div>
                      <input
                        onClick={() => {
                          handleCheck(item._id);
                        }}
                        onChange={(e) => {
                          handleCheck(item._id);
                        }}
                        checked={
                          checkAll
                            ? checkAll
                            : selected.includes(item._id)
                            ? true
                            : false
                        }
                        type={"checkbox"}
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          backgroundColor: "#FAFBFC",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </button>
                );
              }
              return true;
            })}
        </div>
        <div className="columns m-0 p-0 is-multiline" style={{ gap: "1rem" }}>
          {listGrades &&
            listGrades.map((item, index) => {
              if (index !== 0) {
                return (
                  <button
                    onClick={() => {
                      handleCheck(item._id);
                    }}
                    key={index}
                    className="column is-narrow btn-select"
                    style={
                      checkAll || selected.includes(item._id)
                        ? {
                            color: "#ffffff",
                            backgroundColor: "#0A2A66",
                            width: "20.625rem",
                          }
                        : {
                            width: "20.625rem",
                            boxShadow: "0.125rem 0.125rem 0rem 0rem #0A2A66",
                          }
                    }
                  >
                    <div
                      className="columns is-center is-align-items-center is-relative"
                      style={{
                        padding: "0rem 1.5rem 0rem 0.625rem",
                        gap: "1.5rem",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className="column is-narrow p-0"
                        style={{
                          width: "6.875rem",
                          borderRadius: "0.5rem",
                          height: "4.5rem",
                        }}
                      >
                        <ImageFromUrl
                          objectId={item.image}
                          alt={item.image}
                          style={{
                            borderRadius: "0.5rem",
                            height: "100%",
                            maxWidth: "6.875rem",
                          }}
                        />
                      </div>
                      <div
                        className="column tooltip-unit-container has-text-weight-bold is-flex is-align-items-end is-flex-direction-row "
                        style={{
                          padding: "1rem 0",
                          // width: "20rem",
                          fontSize: "0.875rem",
                        }}
                      >
                        {elementsWithEllipsis?.length > 0 &&
                          elementsWithEllipsis.some((element) =>
                            element.includes(item.unitName)
                          ) && (
                            <p
                              className="tooltip-unit has-text-kurio-main-color-black has-text-weight-medium"
                              style={{
                                position: "absolute",
                                top: "-1rem",
                                left: "2.75rem",
                                zIndex: 999,
                                padding: "0.5rem 0.75rem",
                                borderRadius: "0.5rem",
                                width: "18rem",
                                fontSize: "0.875rem",
                                lineHeight: "normal",
                                background: "#fffec8",
                                filter:"saturate(50%)"
                              }}
                            >
                              {jsonParser(item.unitName, 0, "UnitName")}
                            </p>
                          )}
                        <p
                          className="is-narrow ellipsis-text"
                          style={
                            checkAll || selected.includes(item._id)
                              ? {
                                  color: "#fff",
                                }
                              : {
                                  color: "#0A2A66",
                                }
                          }
                        >
                          {item.unitName &&
                            jsonParser(
                              item.unitName,
                              index,
                              "select-unit-name"
                            )}
                        </p>
                      </div>
                      {/* <CheckBox /> */}
                      <input
                        onClick={() => {
                          handleCheck(item._id);
                        }}
                        onChange={(e) => {
                          handleCheck(item._id);
                        }}
                        checked={
                          checkAll
                            ? checkAll
                            : selected.includes(item._id)
                            ? true
                            : false
                        }
                        type={"checkbox"}
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          backgroundColor: "#FAFBFC",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </button>
                );
              }
              return true;
            })}
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    grades: state.grades.gradeId,
    listGrades: state.grades.grades,
  };
}
export default connect(mapStateToProps)(SelectUnit);
