import React, { useEffect, useState } from "react";
import "./flutter.style.css";

const EloCalibration = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  return (
    <div>
      {isLoading && (
        <div
          className="has-background-kurio-main-color-white is-flex is-justify-content-center is-align-items-center"
          style={{ height: "100vh" }}
        >
          <span className="loader_flutter"></span>
        </div>
      )}
      <iframe
        className={`${isLoading ? "is-hidden" : ""}`}
        title="Flutter App"
        src="https://d16a8epid9x6kc.cloudfront.net"
        width="100%"
        style={{ minHeight: "100vh" }}
      ></iframe>
    </div>
  );
};

export default EloCalibration;
