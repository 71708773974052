import React, { useState } from "react";
import BackgroundImageFromUrl from "../../parseImage/BackgroundImageFromUrl";

const NotificationsButton = ({
  disabled,
  isClick,
  onClick,
  children,
  isNew,
}) => {
  var style = {
    width: "3.5rem",
    height: "3.5rem",
    borderRadius: "1rem",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    boxShadow: "none",
    // backgroundSize:"cover"
  };
  var elipStyle = {
    position: "absolute",
    borderRadius: "9999rem",
    border: "0.125rem solid #FAFBFC",
    left: "70%",
    right: "0%",
    top: "0%",
    bottom: "70%",
  };
  const [defaultState, setDefaultState] = useState(true);
  const [hoveredState, setHoveredState] = useState(false);
  const [clickedState, setClickedState] = useState(isClick ? isClick : false);
  const audio = new Audio("/sounds/click.mp3");
  var objectId = "";
  if (hoveredState || clickedState) {
    objectId = "admin/frontend-images/button/daily-mission.svg";
  }
  if (defaultState) {
    objectId = "admin/frontend-images/button/daily-mission.svg";
  }
  const handleMouseEnter = () => {
    if (!clickedState) {
      setDefaultState(false);
      setHoveredState(true);
    }
  };
  const handleMouseLeave = () => {
    if (!clickedState) {
      setDefaultState(true);
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    setClickedState(!clickedState);
    setHoveredState(false);
    setDefaultState(clickedState);
    audio.play();
    if (onClick) {
      onClick();
    }
  };
  const handleBlur = () => {
    setClickedState(false);
    setDefaultState(true);
  };
  return (
    <div
      style={{ width: "3.5rem", height: "3.5rem" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onBlur={handleBlur}
    >
      <BackgroundImageFromUrl
        objectId={objectId}
        className={`${
          defaultState
            ? "is-secondary-button-default-color"
            : hoveredState || clickedState
            ? "is-secondary-button-clicked-color"
            : ""
        }  button main-color p-0 is-relative has-text-weight-bold is-uppercase has-text-centered`}
        style={style}
        onClick={handleClick}
        type="button"
      >
        {children}
        {isNew && (
          <div
            style={elipStyle}
            className="has-background-secondary-button-clicked-color"
          ></div>
        )}
      </BackgroundImageFromUrl>
    </div>
  );
};

export default NotificationsButton;
