const cache = {};
export const cachesQuestion = (section, slide, imgWidth, maxWidth) => {
  if (cache[section]) {
    cache[section][slide] = { imgWidth: imgWidth, maxWidth: maxWidth };
  } else {
    cache[section] = { [slide]: { imgWidth: imgWidth, maxWidth: maxWidth } };
  }
};
export const getCachesQuestion = (section, slide) => {
  return cache[section]?.[slide];
};
