import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ConstantStrings from "../../constants/ConstantStrings";
import { checkImage } from "../../constants/checkImage";
import QuestionNumberTag from "../../detail-components/QuestionNumberTag";
import {
  selectAnsweredQuestions,
  setQuestionAnswered,
  setQuestionFinished,
  setQuestionState,
  setQuestionsAnswered,
  submitAnswers
} from "../../reducers/practiceQuestionSlice";
import "../../styling/layout-components/MultipleScroll.style.css";
import jsonParser from "../helper/jsonParser";

function VerticalMultipleResponse({
  sections,
  data,
  order,
  question,
  questions,
  questionId,
  handleQuestionChange,
  backgroundImage,
  questionIndex,
  practiceQuizId,
}) {
  var questionTexts = question.texts
    ? question.texts.map((text, index) => jsonParser(text, index))
    : question.texts;
  var questionChoices = question.choices;
  var questionSolutions = question.solutions;
  const dispatch = useDispatch();
  const answeredQuestions = useSelector(selectAnsweredQuestions);
  const cookies = new Cookies();
  const token = cookies.get("signinUser");

  const [selectedItem] = useState(() => {
    return questions
      .filter((question) => question.questionIndex === questionIndex)
      .map((item) => item.answer)
      .flat()
      .indexOf(true);
  });

  //create a state variable to store the selected answers
  var [selected, setSelected] = useState(() => {
    if (question.state) {
      return question.state.selected;
    } else {
      if (selectedItem !== -1) {
        return selectedItem;
      } else {
        return [];
      }
    }
  });
  const [imgWidth, setImgWidth] = useState(0);
  const [widthButton, setWidthButton] = useState(0);
  const [maxWidth, setMaxWidth] = useState(60);

  var maxLength = 0;
  for (var i = 0; i < questionChoices.length; i++) {
    if (questionChoices[i].length > maxLength) {
      maxLength = questionChoices[i].length;
    }
  }
  var backgroundStyle = {
    borderRadius: "1rem 0 0 1rem",
    height: `62.25rem`,
    width: "50%",
  };
  var backgroundLeftStyle = {};
  if (
    backgroundImage[0] !== null &&
    backgroundImage[0] &&
    checkImage(backgroundImage[0])
  ) {
    backgroundLeftStyle = {
      ...backgroundStyle,
      width: "100%",
      // backgroundImage: `url(${memoizedImageData[1][0]})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundLeftStyle = {
      ...backgroundStyle,
      width: "100%",
    };
  }
  if (
    backgroundImage[1] !== null &&
    backgroundImage[1] &&
    checkImage(backgroundImage[1])
  ) {
    backgroundStyle = {
      ...backgroundStyle,
      borderRadius: "0 1rem 1rem 0",
      // backgroundImage: `url(${memoizedImageData[1][1]})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundStyle = {
      ...backgroundStyle,
      borderRadius: "0 1rem 1rem 0",
    };
  }
  let buttonStyle = {};
  if (maxLength <= 10 && !checkImage(questionChoices[0])) {
    buttonStyle = {
      minWidth: "7rem",
      width: "auto",
      maxWidth: "10rem",
      padding: "1rem",
      height: "4rem",
      opacity: 1,
      fontWeight: "bold",
      fontSize: "1.125rem",
    };
  } else if (
    maxLength >= 11 &&
    maxLength <= 32 &&
    !checkImage(questionChoices[0])
  ) {
    buttonStyle = {
      minWidth: "15rem",
      maxWidth: "20rem",
      padding: "1rem",
      width: `auto`,
      height: "4rem",
      fontWeight: "bold",
      outline: "0.0625rem solid",
      opacity: 1,
      fontSize: "1.125rem",
    };
  } else {
    if (checkImage(questionChoices[0])) {
      buttonStyle = {
        maxWidth: "20rem",
        width: `${imgWidth !== 0 ? `${imgWidth + 0.125}rem` : "auto"}`,
        height: "auto",
        padding: "1rem",
        whiteSpace: "pre-wrap",
        outline: "0.0625rem solid",
        opacity: 1,
        borderRadius: "1.5rem",
        fontWeight: "bold",
        fontSize: "1.125rem",
      };
    } else {
      buttonStyle = {
        maxWidth: "20rem",
        minWidth: "15rem",
        width: "auto",
        height: "auto",
        padding: "1rem 2.5rem",
        whiteSpace: "pre-wrap",
        outline: "0.0625rem solid",
        opacity: 1,
        fontWeight: "bold",
        fontSize: "1.125rem",
        color: "#0A2A66",
      };
    }
  }

  var selectedStyle = {
    ...buttonStyle,
    backgroundColor: "#E6E1F5",
    outline: "2px solid #572CD1",
  };

  var nextButtonStyle = {
    position: "absolute",
    width: "100%",
    padding: "3rem 0",
    left: "0rem",
  };

  var iconStyle = {
    zIndex: 100,
    position: "relative",
    top: "1.25rem",
    width: "1.5rem",
    height: "1.5rem",
    left: "0.8rem",
  };

  //McqButton component
  var maxHeight = 0;
  var maxWidthButton = 0;
  const divRef = useRef(null);
  const McqButton = ({ id, index, children }) => {
    const buttonRef = useRef(null);
    useEffect(() => {
      const image = new Image();
      if (checkImage(questionChoices[0])) {
        image.src = questionChoices[0];
        image.onload = () => {
          setImgWidth(
            (image.width * ConstantStrings.MULTIPLIERIMAGE) /
              ConstantStrings.BASE_REM
          );
        };
      }

      if (buttonRef.current.clientHeight > maxHeight) {
        maxHeight = buttonRef.current.clientHeight;
      }
      if (buttonRef.current.clientWidth > maxWidthButton) {
        maxWidthButton = buttonRef.current.clientWidth;
      }
      if (widthButton < maxWidthButton / ConstantStrings.BASE_REM) {
        setWidthButton(maxWidthButton / ConstantStrings.BASE_REM);
      }
      buttonRef.current.style.width = `${
        maxWidthButton / ConstantStrings.BASE_REM
      }rem`;
      //set all auto-height buttons to the same height
    }, [id]);
    return (
      //if index in selected, set the style to selectedStyle
      //if index in selected and checkingState = true, set the style to correctStyle if the answer is correct, set the style to incorrectStyle if the answer is incorrect
      <div>
        {/* <button id={id} ref={buttonRef} className="button" style={index === selected ? (checkingState ? (questionSolutions[selected] === "True" ? correctStyle : incorrectStyle) : selectedStyle) : buttonStyle} onClick={() => handleSelect(index)} disabled={checkingState}>
                    {children}
                </button> */}
        {selected.includes(index) ? (
          questionSolutions[index] === "True" ? (
            <img src="/images/common/greenV.svg" style={iconStyle} alt="loading" />
          ) : (
            <img src="/images/common/redX.svg" style={iconStyle} alt="loading" />
          )
        ) : (
          <></>
        )}
        <button
          id={id}
          ref={buttonRef}
          className={`${
            selected.includes(index)
              ? "has-background-kurio-purple-light-3 is-kurio-purple-main-accent has-text-kurio-purple-main-accent"
              : ""
          } button is-shadowless has-text-weight-bold is-outlined`}
          style={selected.includes(index) ? selectedStyle : buttonStyle}
          onClick={() => handleSelect(index)}
        >
          {checkImage(children) ? (
            <>
              <img alt={children} src={children} />
            </>
          ) : (
            jsonParser(children)
          )}
        </button>
      </div>
    );
  };

  //mapping each choice to a component
  const MapChoices = questionChoices.map((choice, index) => {
    return (
      //id = "auto-height" if the choice is longer than 32 characters
      <div className="column is-narrow is-centered p-2" key={index}>
        <McqButton
          id={choice.length > 10 && !checkImage(choice) ? "auto-height" : ""}
          index={index}
        >
          {choice}
        </McqButton>
      </div>
    );
  });

  //create a function to handle the selection of an answer
  //when an answer is selected, add the index of the answer to the selected state variable
  const handleSelect = (index) => {
    if (selected.includes(index)) {
      setSelected(selected.filter((item) => item !== index));
    } else {
      setSelected([...selected, index]);
    }
  };

  const showNextQuestion = () => {
    dispatch(
      setQuestionState({
        index: questionIndex,
        state: {
          selected: selected,
        },
      })
    );
    var answer = questionChoices.map((item) => false);

    if (selected.length > 0) {
      selected.map((item) => (answer[item] = true));
    } else {
      answer = null;
    }
    var newAnswer = { questionId: questionId, answer: answer };
    // setSelected(selected);
    var result = [...answeredQuestions];
    if (result.length === 0) {
      result.push(newAnswer);
    } else {
      var flag = true;
      for (let question = 0; question < answeredQuestions.length; question++) {
        if (answeredQuestions[question].questionId === newAnswer.questionId) {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      if (!flag) {
        result.push(newAnswer);
      } else {
        result = answeredQuestions.map((question) =>
          question.questionId === questionId ? newAnswer : question
        );
      }
    }
    dispatch(setQuestionsAnswered(result));

    const submit = { submit: false, questions: [newAnswer] };
    dispatch(submitAnswers({ submit, token, practiceQuizId }));
    dispatch(setQuestionFinished(questionIndex));

    if (answer !== null || selectedItem !== -1) {
      dispatch(
        setQuestionAnswered({ questionIndex: questionIndex, status: true })
      );
    } else {
      dispatch(
        setQuestionAnswered({ questionIndex: questionIndex, status: false })
      );
    }
    if (questionIndex !== questions.length - 1) {
      handleQuestionChange(questionIndex + 1);
    } else {
      return;
    }
  };

  const showPreviusQuestion = () => {
    dispatch(
      setQuestionState({
        index: questionIndex,
        state: {
          selected: selected,
        },
      })
    );
    var answer = questionChoices.map((item) => false);

    if (selected.length > 0) {
      selected.map((item) => (answer[item] = true));
    } else {
      answer = null;
    }
    var newAnswer = { questionId: questionId, answer: answer };
    // setSelected(selected);
    var result = [...answeredQuestions];
    if (result.length === 0) {
      result.push(newAnswer);
    } else {
      var flag = true;
      for (let question = 0; question < answeredQuestions.length; question++) {
        if (answeredQuestions[question].questionId === newAnswer.questionId) {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      if (!flag) {
        result.push(newAnswer);
      } else {
        result = answeredQuestions.map((question) =>
          question.questionId === questionId ? newAnswer : question
        );
      }
    }

    dispatch(setQuestionsAnswered(result));

    const submit = { submit: false, questions: [newAnswer] };
    dispatch(submitAnswers({ submit, token, practiceQuizId }));

    dispatch(setQuestionFinished(questionIndex));

    if (answer !== null || selectedItem !== -1) {
      dispatch(
        setQuestionAnswered({ questionIndex: questionIndex, status: true })
      );
    } else {
      dispatch(
        setQuestionAnswered({ questionIndex: questionIndex, status: false })
      );
    }
    handleQuestionChange(questionIndex - 1);
  };

  useEffect(() => {
    if (divRef.current.clientWidth / ConstantStrings.BASE_REM !== maxWidth) {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    };

    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [maxWidth]);
  return (
    <div
      style={{ height: `62.25rem` }}
      className="is-flex is-flex-direction-row"
    >
      <div style={{ width: "50%" }} ref={divRef}>
        <BackgroundImageFromUrl
          objectId={backgroundImage[0]}
          style={backgroundLeftStyle}
          className={`${
            !checkImage(backgroundImage[0])
              ? "has-background-kurio-main-color-light-gray"
              : ""
          }`}
        ></BackgroundImageFromUrl>
      </div>
      <BackgroundImageFromUrl
        objectId={backgroundImage[1]}
        style={{
          ...backgroundStyle,
          width: "50%",
          padding: "2rem 2rem 1.5rem",
          position: "relative",
        }}
        className={`${
          !checkImage(backgroundImage[1])
            ? "has-background-kurio-main-color-light-gray"
            : ""
        }`}
      >
        <div className="question-container">
          <div>
            {data?.template.question !== null &&
              data?.template.question &&
              data?.template.question.length > 0 && (
                <div className="mb-4">
                  <QuestionNumberTag
                    number={order === 0 ? data?.order : order}
                  />
                </div>
              )}
          </div>
          {
            //render questionTexts if its array has length greater than 0
            questionTexts !== null &&
              questionTexts !== undefined &&
              questionTexts?.length > 0 && (
                <div className=" has-text-kurio-main-color-black mb-4">
                  {questionTexts}
                </div>
              )
          }
          {questionChoices.length === 4 &&
          (widthButton + 1.5) * 4 + 4 > maxWidth ? (
            <div>
              <div className="columns  p-0 m-0">
                {MapChoices[0]}
                {MapChoices[1]}
              </div>
              <div className="columns  p-0 m-0">
                {MapChoices[2]}
                {MapChoices[3]}
              </div>
            </div>
          ) : // if questionChoices has length 5, render the choices in a 3x2 grid
          questionChoices.length === 5 ? (
            <>
              {(widthButton + 1.5) * 2 + 4 > maxWidth ? (
                <div className="columns is-multiline  p-0 m-0">
                  {MapChoices}
                </div>
              ) : (
                <div>
                  <div className="columns p-0 m-0">
                    {MapChoices[0]}
                    {MapChoices[1]}
                  </div>
                  <div className="columns p-0 m-0">
                    {MapChoices[2]}
                    {MapChoices[3]}
                  </div>
                  <div className="columns p-0 m-0">{MapChoices[4]}</div>
                </div>
              )}
            </>
          ) : // if questionChoices has length 6, render the choices in a 3x3 grid
          questionChoices.length === 6 ? (
            <>
              {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                <div>
                  <div className="columns  p-0 m-0">
                    {MapChoices[0]}
                    {MapChoices[1]}
                  </div>
                  <div className="columns  p-0 m-0">
                    {MapChoices[2]}
                    {MapChoices[3]}
                  </div>
                  <div className="columns  p-0 m-0">
                    {MapChoices[4]}
                    {MapChoices[5]}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="columns  p-0 m-0">
                    {MapChoices[0]}
                    {MapChoices[1]}
                    {MapChoices[2]}
                  </div>
                  <div className="columns  p-0 m-0">
                    {MapChoices[3]}
                    {MapChoices[4]}
                    {MapChoices[5]}
                  </div>
                </div>
              )}
            </>
          ) : questionChoices.length === 3 ? (
            <>
              {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                <div className="columns is-multiline  p-0 m-0">
                  {MapChoices}
                </div>
              ) : (
                <div>
                  <div className="columns  p-0 m-0">
                    {MapChoices[0]}
                    {MapChoices[1]}
                  </div>
                  <div className="columns  p-0 m-0">{MapChoices[2]}</div>
                </div>
              )}
            </>
          ) : (
            <div className="columns is-multiline  p-0 m-0">{MapChoices}</div>
          )}
        </div>

        {question && (
          <div
            style={nextButtonStyle}
            className="is-flex is-flex-direction-row is-justify-content-space-between"
          >
            <button
              className="button"
              onClick={showPreviusQuestion}
              // style={nextButtonStyle}
              disabled={questionIndex === 0}
            >
              Previus
            </button>

            {questionIndex !== questions.length - 1 && (
              <button
                className="button"
                onClick={showNextQuestion}
                style={{ position: "absolute", right: "0" }}
              >
                Next
              </button>
            )}
          </div>
        )}
      </BackgroundImageFromUrl>
    </div>
  );
}

export default VerticalMultipleResponse;
