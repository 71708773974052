// convert timezone to UTC+7 format DD/MM/YYYY hh:mm:ss 
const convertTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Asia/Ho_Chi_Minh' // Múi giờ UCT + 7
        };
        const formattedDate = date.toLocaleString('vi-VN', options);

        const parts = formattedDate.split(' '); // Tách ngày và giờ thành 2 phần riêng biệt
        const datePart = parts[1];
        const formattedDateTime = `${datePart}`; // Kết hợp lại thành chuỗi hoàn chỉnh
        return formattedDateTime; // return format: DD/MM/YYYY hh:mm:ss
      
};

export default convertTimestamp;
