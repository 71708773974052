/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import DuplicateLoginErrorComponent from "../../../../components/DuplicateLoginErrorComponent";
import ImageFromUrl from "../../../../components/parseImage/ImageFromUrl";
import { getAnUnit } from "../../../../reducers/gradesSlice";
import jsonParser from "../../../../templates/helper/jsonParser";
import "./ListUnit.css";

const MainContentListUnit = ({
  listGradeUnits,
  gradeUnitProgress,
  errorGrade,
  unitId,
}) => {
  const cookies = new Cookies();
  const [defaultState, setDefaultState] = useState(true);
  const [hoverState, setHoverState] = useState(false);
  const [hoverItem, setHoverItem] = useState("");
  const [startUnit, setStartUnit] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // if ((cookies.get("unitId") || cookies.get("unitId") === null) && units === null) {
  //   cookies.remove("unitId");
  // }
  if (cookies.get("lessonId") || cookies.get("lessonId") === null) {
    cookies.remove("lessonId");
  }
  const FilterUnitProgress = (id, lessons) => {
    var value = 0;
    var subArray = [];
    for (var i = 0; i < gradeUnitProgress.length; i++) {
      if (gradeUnitProgress[i]._id === id && lessons.length !== 0) {
        subArray = gradeUnitProgress[i].totalLessonsCompleted.filter((item) =>
          lessons.includes(item)
        );
        value = (subArray.length / lessons.length) * 100;
      }
    }

    return value;
  };

  const handleSelectImage = async (unitId) => {
    try {
      cookies.set("unitId", unitId);
      dispatch(getAnUnit({ unitId }));
      setStartUnit(true);
      // setTimeout(() => {
      //   navigate("/list-lesson");
      // }, 300);
    } catch (error) {
      console.log(error.errorMessage);
    }
  };

  var divStyleNew = {
    // marginTop: "1.5rem",
    padding: "0.25rem 0.375rem",
    width: "2.125rem",
    height: "1.25rem",
    borderRadius: "0.5rem",
    backgroundColor: "#0A2A66",
  };

  var pStyleNew = {
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "0.625rem",
    textTransform: "uppercase",
    color: "#FAFBFC",
    lineHeight: "0.761875rem",
  };

  var divStyleFinished = {
    // marginTop: "1.5rem",
    padding: "0.25rem 0.375rem",
    width: "5.375rem",
    height: "1.25rem",
    borderRadius: "0.5rem",
    backgroundColor: "#FAFBFC",
  };

  var pStyleFinished = {
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "0.625rem",
    textTransform: "uppercase",
    color: "#572CD1",
    lineHeight: "0.761875rem",
  };

  var divStyleOnGoing = {
    // marginTop: "1.5rem",
    padding: "0.25rem 0.375rem",
    width: "4.4375rem",
    height: "1.25rem",
    borderRadius: "0.5rem",
    backgroundColor: "#572CD1",
  };

  var pStyleOnGoing = {
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "0.625rem",
    textTransform: "uppercase",
    color: "#FAFBFC",
    lineHeight: "0.761875rem",
  };
  const handleHover = (id) => {
    if ((defaultState && !hoverState) || hoverItem?.length > 0) {
      if (hoverItem === id) {
        setDefaultState(true);
        setHoverItem("");
        setHoverState(false);
      } else {
        setDefaultState(false);
        setHoverItem(id);
        setHoverState(true);
      }
    } else if (!defaultState && hoverState) {
      setDefaultState(true);
      setHoverItem("");
      setHoverState(false);
    }
  };
  if (startUnit) {
    if (errorGrade !== null && errorGrade === 409) {
      localStorage.setItem("clearAll",true)
      return <DuplicateLoginErrorComponent isShowPopup={true} />;
    } else {
      setTimeout(() => {
        if (unitId !== null) {
          navigate("/list-lesson");
        }
      }, 300);
    }
  }

  return (
    <div>
      <div
        className="is-flex is-flex-wrap-wrap is-align-items-center is-flex-shrink-0"
        style={{
          width: "60rem",
          marginBottom: "4rem",
          gap: "1.8rem", // 1.75rem comment
        }}
      >
        {listGradeUnits &&
          gradeUnitProgress &&
          listGradeUnits.map((item, index) => {
            return (
              <div
                onMouseEnter={() => handleHover(item._id)}
                onMouseLeave={() => handleHover(item._id)}
                className="unit-item"
                key={index}
              >
                {item.accessible && (
                  <div
                    className="row-solution is-clickable unit-title has-background-kurio-main-color-white px-6 is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-flex-gap-5"
                    onClick={() =>
                      handleSelectImage(item._id, item.lessonCount)
                    }
                    style={
                      `${
                        FilterUnitProgress(item._id, item.lessons) === 0 ||
                        gradeUnitProgress.length < 0
                      }`
                        ? {
                            width: "18.75rem",
                            border: "0.09375rem solid #0A2A66",
                            boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
                            borderRadius: "1rem",
                            height: "22.5rem",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundImage:
                              FilterUnitProgress(item._id, item.lessons) === 100
                                ? "url('/images/unit/background-unit-finished.svg')"
                                : FilterUnitProgress(item._id, item.lessons) ===
                                    0 || gradeUnitProgress.length < 0
                                ? ""
                                : 1 <=
                                    FilterUnitProgress(
                                      item._id,
                                      item.lessons
                                    ) &&
                                  FilterUnitProgress(item._id, item.lessons) <
                                    100
                                ? "url('/images/unit/background-unit-on-going.svg')"
                                : {},
                            // backgroundAttachment: "fixed",
                          }
                        : {}
                    }
                  >
                    <div
                      style={{
                        height: "9.75rem",
                        borderRadius: "1rem",
                      }}
                    >
                      <ImageFromUrl
                        objectId={item.image}
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                          borderRadius: "1rem",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleSelectImage(item._id, item.lessonCount)
                        }
                        alt="Anh bai giang"
                      />
                    </div>
                    <div className="is-flex is-flex-direction-column is-align-items-flex-start is-align-self-stretch is-justify-content-center is-flex-gap-2">
                      <div>
                        <span
                          style={{
                            textTransform: "uppercase",
                            fontSize: "0.875rem",
                            fontWeight: 700,
                            lineHeight: "1rem",
                            textAlign: "left",
                            color: "#0a2a66",
                            maxWidth: "20.375rem",
                            padding: "0.5rem 0.75rem",
                            backgroundColor: "#ffffff",
                            display: "inline-block",
                            height: "2rem",
                            borderRadius: "1rem",
                          }}
                          className={`${
                            FilterUnitProgress(item._id, item.lessons) !== 0
                              ? "has-background-kurio-main-color-white"
                              : "has-background-kurio-main-color-light-gray"
                          }`}
                        >
                          <p
                            style={{
                              maxWidth: "20.375rem !important",
                            }}
                          >
                            {`Chương ${index + 1}`}
                          </p>
                        </span>
                      </div>
                      <div
                        className={`
                         is-flex is-flex-direction-column is-flex-gap-3`}
                        style={{
                          // padding: "1.5rem",
                          borderRadius: "0rem 0rem 1rem 1rem",
                          boxShadow: "none ",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "1.125rem",
                            fontWeight: 700,
                            lineHeight: "1.4rem",
                            minHeight: "2.75rem",
                            cursor: "pointer",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <p className="has-text-kurio-main-color-black">
                            {jsonParser(item.unitName, index, "list-unit-name")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="is-flex is-justify-content-space-between is-align-items-flex-end is-align-self-stretch">
                      <div
                        style={
                          FilterUnitProgress(item._id, item.lessons) === 100
                            ? divStyleFinished
                            : FilterUnitProgress(item._id, item.lessons) ===
                                0 || gradeUnitProgress.length < 0
                            ? divStyleNew
                            : 1 <= FilterUnitProgress(item._id, item.lessons) &&
                              FilterUnitProgress(item._id, item.lessons) < 100
                            ? divStyleOnGoing
                            : {}
                        }
                      >
                        <p
                          style={
                            FilterUnitProgress(item._id, item.lessons) === 100
                              ? pStyleFinished
                              : FilterUnitProgress(item._id, item.lessons) ===
                                  0 || gradeUnitProgress.length < 0
                              ? pStyleNew
                              : 1 <=
                                  FilterUnitProgress(item._id, item.lessons) &&
                                FilterUnitProgress(item._id, item.lessons) < 100
                              ? pStyleOnGoing
                              : {}
                          }
                        >
                          {FilterUnitProgress(item._id, item.lessons) === 100
                            ? "Hoàn Thành"
                            : FilterUnitProgress(item._id, item.lessons) ===
                                0 || gradeUnitProgress.length < 0
                            ? "Mới"
                            : 1 <= FilterUnitProgress(item._id, item.lessons) &&
                              FilterUnitProgress(item._id, item.lessons) < 100
                            ? "Đang Học"
                            : ""}
                        </p>
                      </div>
                      <progress
                        style={{
                          // borderRadius: 0,
                          width: "7.5rem",
                          height: "0.5rem",
                        }}
                        className={`${
                          FilterUnitProgress(item._id, item.lessons) > 0
                            ? "is-kurio-main-color-purple"
                            : "is-kurio-gray-light-1"
                        } progress`}
                        value={
                          FilterUnitProgress(item._id, item.lessons)
                            ? FilterUnitProgress(item._id, item.lessons)
                            : 0
                        }
                        max="100"
                      ></progress>
                    </div>
                  </div>
                )}

                {!item.accessible && (
                  <div
                    className="row-solution has-background-kurio-gray-light-2 px-6 is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-flex-gap-5"
                    style={{
                      pointerEvents: "none",
                      width: "18.75rem",
                      border: "0.09375rem solid #0A2A66",
                      boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
                      borderRadius: "1rem",
                      height: "22.5rem",
                    }}
                  >
                    <div
                      className="is-relative"
                      style={{
                        height: "9.75rem",
                        borderRadius: "1rem",
                      }}
                    >
                      <div
                        className="has-background-kurio-main-color-black has-text-centered"
                        style={{
                          opacity: 0.5,
                          position: "absolute",
                          width: "14.75rem",
                          height: "9.75rem",
                          borderRadius: "1rem",
                          zIndex: 1,
                        }}
                      ></div>
                      <ImageFromUrl
                        style={{
                          position: "absolute",
                          top: "2.75rem",
                          left: "5.37rem",
                          width: "4rem",
                          height: "4rem",
                          zIndex: 2,
                        }}
                        objectId="admin/frontend-images/units/lock-white.svg"
                      />
                      <ImageFromUrl
                        objectId={item.image}
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                          borderRadius: "1rem",
                          filter: "grayScale(100%)",
                        }}
                        onClick={() =>
                          handleSelectImage(item._id, item.lessonCount)
                        }
                        alt="Anh bai giang"
                      />
                    </div>

                    <div className="is-flex is-flex-direction-column is-align-items-flex-start is-align-self-stretch is-justify-content-center is-flex-gap-2 has-text-kurio-gray-main-accent">
                      <div>
                        <span
                          style={{
                            textTransform: "uppercase",
                            fontSize: "0.875rem",
                            fontWeight: 700,
                            lineHeight: "1rem",
                            textAlign: "left",
                            maxWidth: "20.375rem",
                            padding: "0.5rem 0.75rem",
                            backgroundColor: "#ffffff",
                            display: "inline-block",
                            height: "2rem",
                            borderRadius: "1rem",
                          }}
                          className="has-background-kurio-main-color-light-gray"
                        >
                          <p
                            style={{
                              maxWidth: "20.375rem !important",
                            }}
                          >
                            {`Chương ${index + 1}`}
                          </p>
                        </span>
                      </div>
                      <div
                        className={`
                         is-flex is-flex-direction-column is-flex-gap-3`}
                        style={{
                          // padding: "1.5rem",
                          borderRadius: "0rem 0rem 1rem 1rem",
                          boxShadow: "none ",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "1.125rem",
                            fontWeight: 700,
                            lineHeight: "1.4rem",
                            minHeight: "2.75rem",
                            cursor: "pointer",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <p className="">
                            {jsonParser(item.unitName, index, "list-unit-name")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className=" has-text-kurio-main-color-white is-flex is-align-items-center is-align-self-stretch is-flex-gap-2"
                      style={{
                        padding: "0.25rem 0.375rem",
                        borderRadius: "0.5rem",
                        width: "3.75rem",
                        height: "1.25rem",
                      }}
                    >
                      <ImageFromUrl
                        style={{
                          width: "1.25rem",
                          height: "1.25rem",
                          filter: "scale(100%)",
                        }}
                        objectId="admin/frontend-images/units/lock.svg"
                      />
                      <p
                        className="is-uppercase has-text-weight-bold has-background-kurio-main-color-black"
                        style={{
                          fontSize: "0.625rem",
                          lineHeight: "0.761875rem",
                          padding: "0.25rem 0.375rem",
                          borderRadius: "0.5rem",
                        }}
                      >
                        khóa
                      </p>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    errorGrade: state.grades.error,
    unitId: state.grades.unitId,
  };
}

export default connect(mapStateToProps)(MainContentListUnit);
