/* eslint-disable react-hooks/exhaustive-deps */
// a React template for Gapfill type question

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
// import { setCorrectAnswer, setSkippedQuestions, setWrongAnswer } from "../../reducers/practiceQuestionSlice";
// import { setQuestionState } from "../../reducers/questionsSlice";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import SecondaryButtonQuestion from "../../components/button/secondary-button/SecondaryButtonQuestion";
import TertiaryButtonQuestion from "../../components/button/tertiary-button/TertiaryButtonQuestion";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import GapFill from "../../components/question/GapFill";
import { checkImage } from "../../constants/checkImage";
import ConstantStrings from "../../constants/ConstantStrings";
import {
  setCorrectAnswer,
  setCorrectQuestion,
  setQuestionFinished,
  setQuestionState,
  setQuestionVisible,
  setWrongAnswer,
} from "../../reducers/practiceElectiveSlice";
import { cachesQuestion, getCachesQuestion } from "../helper/cacheQuestion";
import jsonParser from "../helper/jsonParser";

function Gapfill({
  question,
  explanation,
  backgroundImage,
  questions,
  questionIndex,
  isLast,
  nextQuestion,
  showEndPractice,
  submitAnswers,
  isPreview,
  setNextScroll,
  seenQuestions,
}) {
  const cache = getCachesQuestion("practice-elective", questionIndex);
  var questionTexts =
    question.texts !== null &&
      question.texts !== undefined &&
      question.texts?.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;
  var questionChoices = question.choices;
  var questionSolutions = question.solutions;
  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      explanation.texts.some((item) => item?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      explanation.images.some((item) => item?.length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }
  var backgroundStyle = {
    borderRadius: "0 0 1rem 1rem",
    maxWidth: "60rem",
  };
  if (
    backgroundImage !== null &&
    backgroundImage &&
    checkImage(backgroundImage)
  ) {
    backgroundStyle = {
      ...backgroundStyle,
      // backgroundImage: `url(${memoizedImageData[2]})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  }
  var exerciseState = JSON.parse(localStorage.getItem("exerciseState"));
  const setLocalStorage = (
    answer,
    correct,
    answers,
    correctAnswers,
    checkingState,
    explanationState,
    seenAnswer
  ) => {
    localStorage.setItem(
      "exerciseState",
      JSON.stringify({
        correct,
        answer,
        questionState: {
          index: questionIndex,
          state: {
            answers: answers,
            correctAnswers: correctAnswers,
            checkingState: checkingState,
            explanationState: explanationState,
            seenAnswer: seenAnswer,
          },
        },
      })
    );
  };

  const dispatch = useDispatch();

  const [answers, setAnswers] = useState(() => {
    if (question.state) {
      return question.state.answers;
    } else {
      return [];
    }
  });

  const [correctAnswers, setCorrectAnswers] = useState(() => {
    if (question.state) {
      return question.state.correctAnswers;
    } else {
      return [];
    }
  });

  const [correct, setCorrect] = useState(false);

  const [gapFillWithImages, setGapFillWithImages] = useState(
    questionChoices !== null &&
      questionChoices &&
      questionChoices.length > 0 &&
      questionChoices[0] !== null &&
      questionChoices[0].length > 0
      ? true
      : false
  );

  const [explanationState, setExplanationState] = useState(() => {
    if (question.state) {
      return question.state.explanationState;
    } else {
      return false;
    }
  });

  const [checkingState, setCheckingState] = useState(() => {
    if (question.state) {
      return question.state.checkingState;
    } else {
      return false;
    }
  });

  const [seenAnswer, setSeenAnswer] = useState(() => {
    if (question.state) {
      return question.state.seenAnswer;
    } else {
      return false;
    }
  });

  const [maxWidth, setMaxWidth] = useState(() => {
    if (cache?.maxWidth) {
      return cache.maxWidth;
    } else {
      return 60;
    }
  });

  const [isLoading, setIsLoading] = useState(
    cache || !checkImage(questionChoices[0]) ? false : true
  );
  if (
    questionChoices !== null &&
    questionChoices &&
    questionChoices.length > 0 &&
    questionChoices[0] !== null &&
    questionChoices[0].length > 0
  ) {
    cachesQuestion("practice-elective", questionIndex, 0, maxWidth);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }
  const divRef = useRef(null);

  // check first time render if questionChoices are not empty strings
  useEffect(() => {
    if (
      questionChoices !== null &&
      questionChoices &&
      questionChoices.length > 0 &&
      questionChoices[0] !== null &&
      questionChoices[0].length > 0
    ) {
      setGapFillWithImages(true);
    }
    if (
      divRef.current.clientWidth / ConstantStrings.BASE_REM !== maxWidth &&
      divRef.current.clientWidth / ConstantStrings.BASE_REM < 60
    ) {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    };

    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [questionChoices, maxWidth]);

  const handleChange = (index) => (e) => {
    e.preventDefault();
    var answer = questionSolutions.map((item) => false);
    let newAnswers = [...answers];
    if (newAnswers.length < index) {
      for (let i = 0; i < index; i++) {
        if (!newAnswers[i]) {
          newAnswers[i] = "";
        }
      }
    }
    newAnswers[index] = e.target.value;

    if (newAnswers.length > 0) {
      newAnswers.map((item, index) => (answer[index] = item));
    } else {
      answer = null;
    }
    setLocalStorage(
      answer,
      null,
      newAnswers,
      correctAnswers,
      checkingState,
      explanationState,
      seenAnswer
    );
    setAnswers(newAnswers);
  };

  const handleCheck = () => {
    if (!isPreview) {
      setNextScroll(true)
    }
    //alert please fill in all gaps if not all gaps are filled
    if (answers.length < questionSolutions.length) {
      alert(ConstantStrings.REQUEST_FILL_IN_ALL_GAPS);
      return;
    }
    var answer = questionSolutions.map((item) => false);
    answer = answers.map(
      (item, index) =>
        (answer[index] = item !== null && item.length > 0 ? item : false)
    );
    setCheckingState(true);
    //check answer to each gap and set correctAnswers
    let newCorrectAnswers = [];
    for (let i = 0; i < questionSolutions.length; i++) {
      if (answers[i] === questionSolutions[i]) {
        newCorrectAnswers[i] = true;
      } else {
        newCorrectAnswers[i] = false;
      }
    }
    setCorrectAnswers(newCorrectAnswers);
    //check if all answers are correct
    let allCorrect = true;
    for (let i = 0; i < newCorrectAnswers.length; i++) {
      if (newCorrectAnswers[i] === false) {
        allCorrect = false;
      }
    }
    if (!isPreview || isPreview === undefined) {
      dispatch(
        setCorrectQuestion({
          questionIndex: questionIndex,
          correct: allCorrect,
        })
      );
      if (allCorrect) {
        dispatch(setCorrectAnswer(1));
      } else {
        dispatch(setWrongAnswer(1));
      }
    }
    setLocalStorage(
      answer,
      allCorrect,
      answers,
      newCorrectAnswers,
      true,
      explanationState,
      seenAnswer
    );
    if (!isPreview || isPreview === undefined) {
      const exerciseStateLocal = JSON.parse(
        localStorage.getItem("exerciseState")
      );
      dispatch(setQuestionState(exerciseStateLocal.questionState));
      submitAnswers(answer);
    }
    setCorrect(allCorrect);
  };

  // const handleReset = () => {
  //   setAnswers([]);
  //   setExplanationState(false);
  //   setLocalStorage(null, null, [], [], false, false, seenAnswer);
  //   setCheckingState(false);
  //   setCorrectAnswers([]);
  // };

  const handleShowExplanation = () => {
    setExplanationState(true);
    if (exerciseState) {
      exerciseState.questionState.state.explanationState = true;
      exerciseState.questionState.state.seenAnswer = true;
      localStorage.setItem("exerciseState", JSON.stringify(exerciseState));
    } else {
      var questionState = {
        index: questionIndex,
        state: {
          answers: answers,
          correctAnswers: correctAnswers,
          checkingState: checkingState,
          explanationState: true,
          seenAnswer: true,
        },
      };
      localStorage.setItem("exerciseState", JSON.stringify({ questionState }));
    }
    setSeenAnswer(true);
  };

  const showNextQuestion = () => {
    if (!isPreview) {
      setNextScroll(false)
    }
    if (exerciseState === null || !checkingState) {
      setLocalStorage(
        null,
        null,
        answers,
        correctAnswers,
        checkingState,
        explanationState,
        seenAnswer
      );
      if (!isPreview || isPreview === undefined) {
        submitAnswers(null);
      }
    }
    if (!isPreview || isPreview === undefined) {
      dispatch(setQuestionFinished(questionIndex));
      if (!isLast) {
        nextQuestion();
      } else {
        showEndPractice();
      }
    } else {
      const exerciseState = JSON.parse(localStorage.getItem("exerciseState"));
      dispatch(setQuestionState(exerciseState.questionState));
      dispatch(setQuestionVisible(questionIndex + 1));
    }
  };

  return (
    <div
      style={backgroundStyle}
      ref={divRef}
      className={`${!checkImage(backgroundImage)
        ? "has-background-kurio-main-color-light-gray"
        : ""
        }`}
    >
      {/* if gapFillWithImages is false
                if questionChoices has length 4, render the textinput in a 2x2 grid
                else use columns is-multiline is-centered
            */}
      {isLoading && (
        <div className="has-text-kurio-main-color-black">Loading</div>
      )}
      {!explanationState && !gapFillWithImages && (
        <div
          className={`${isLoading ? "is-hidden" : ""
            } question-container is-flex is-flex-direction-column has-text-kurio-main-color-black`}
          style={{
            padding: "1.5rem 2rem",
            gap: "1rem",
          }}
        >
          {
            //render questionTexts if its array has length greater than 0
            questionTexts !== null &&
            questionTexts !== undefined &&
            questionTexts?.length > 0 && (
              <div
                className="has-text-centered m-auto"
                style={{ maxWidth: "40rem" }}
              >
                {questionTexts}
              </div>
            )
          }
          {questionChoices.length === 4 ? (
            <>
              {(10 + 1) * 4 + 4 >= maxWidth ? (
                <>
                  <div>
                    <div className="columns is-centered m-0 ">
                      <GapFill
                        index={0}
                        onChange={handleChange(0)}
                        answer={answers[0] || ""}
                        correctAnswers={correctAnswers[0]}
                        checkingState={checkingState}
                        choice={questionChoices[0]}
                      />
                      <GapFill
                        index={1}
                        onChange={handleChange(1)}
                        answer={answers[1] || ""}
                        correctAnswers={correctAnswers[1]}
                        checkingState={checkingState}
                        choice={questionChoices[1]}
                      />
                    </div>
                    <div className="columns is-centered m-0 ">
                      <GapFill
                        index={2}
                        onChange={handleChange(2)}
                        answer={answers[2] || ""}
                        correctAnswers={correctAnswers[2]}
                        checkingState={checkingState}
                        choice={questionChoices[2]}
                      />
                      <GapFill
                        index={3}
                        onChange={handleChange(3)}
                        answer={answers[3] || ""}
                        correctAnswers={correctAnswers[3]}
                        checkingState={checkingState}
                        choice={questionChoices[3]}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <div className="columns is-multiline is-centered m-0 ">
                      <GapFill
                        index={0}
                        onChange={handleChange(0)}
                        answer={answers[0] || ""}
                        correctAnswers={correctAnswers[0]}
                        checkingState={checkingState}
                        choice={questionChoices[0]}
                      />
                      <GapFill
                        index={1}
                        onChange={handleChange(1)}
                        answer={answers[1] || ""}
                        correctAnswers={correctAnswers[1]}
                        checkingState={checkingState}
                        choice={questionChoices[1]}
                      />
                      <GapFill
                        index={2}
                        onChange={handleChange(2)}
                        answer={answers[2] || ""}
                        correctAnswers={correctAnswers[2]}
                        checkingState={checkingState}
                        choice={questionChoices[2]}
                      />
                      <GapFill
                        index={3}
                        onChange={handleChange(3)}
                        answer={answers[3] || ""}
                        correctAnswers={correctAnswers[3]}
                        checkingState={checkingState}
                        choice={questionChoices[3]}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {questionChoices.length === 6 ? (
                <>
                  {(10 + 1) * 3 + 4 <= maxWidth ? (
                    <>
                      <div>
                        <div className="columns is-centered m-0 ">
                          <GapFill
                            index={0}
                            onChange={handleChange(0)}
                            answer={answers[0] || ""}
                            correctAnswers={correctAnswers[0]}
                            checkingState={checkingState}
                            choice={questionChoices[0]}
                          />
                          <GapFill
                            index={1}
                            onChange={handleChange(1)}
                            answer={answers[1] || ""}
                            correctAnswers={correctAnswers[1]}
                            checkingState={checkingState}
                            choice={questionChoices[1]}
                          />
                          <GapFill
                            index={2}
                            onChange={handleChange(2)}
                            answer={answers[2] || ""}
                            correctAnswers={correctAnswers[2]}
                            checkingState={checkingState}
                            choice={questionChoices[2]}
                          />
                        </div>
                        <div className="columns is-centered m-0 ">
                          <GapFill
                            index={3}
                            onChange={handleChange(3)}
                            answer={answers[3] || ""}
                            correctAnswers={correctAnswers[3]}
                            checkingState={checkingState}
                            choice={questionChoices[3]}
                          />
                          <GapFill
                            index={4}
                            onChange={handleChange(4)}
                            answer={answers[4] || ""}
                            correctAnswers={correctAnswers[4]}
                            checkingState={checkingState}
                            choice={questionChoices[4]}
                          />
                          <GapFill
                            index={5}
                            onChange={handleChange(5)}
                            answer={answers[5] || ""}
                            correctAnswers={correctAnswers[5]}
                            checkingState={checkingState}
                            choice={questionChoices[5]}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="columns is-multiline is-centered m-0 ">
                        {questionChoices.map((choice, index) => {
                          return (
                            <GapFill
                              key={index}
                              index={index}
                              onChange={handleChange(index)}
                              answer={answers[index] || ""}
                              correctAnswers={correctAnswers[index]}
                              checkingState={checkingState}
                              choice={questionChoices[index]}
                            />
                          );
                        })}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="columns is-multiline is-centered m-0">
                    {questionChoices.map((choice, index) => {
                      return (
                        <GapFill
                          key={index}
                          index={index}
                          onChange={handleChange(index)}
                          answer={answers[index] || ""}
                          correctAnswers={correctAnswers[index]}
                          checkingState={checkingState}
                          choice={questionChoices[index]}
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}

      {!explanationState && gapFillWithImages && (
        <div
          className={`${isLoading ? "is-hidden" : ""
            } question-container is-flex is-flex-direction-column has-text-kurio-main-color-black`}
          style={{
            padding: "1.5rem 2rem",
            gap: "1rem",
          }}
        >
          {
            //render questionTexts if its array has length greater than 0
            questionTexts !== null &&
            questionTexts !== undefined &&
            questionTexts?.length > 0 && (
              <div
                className="has-text-centered m-auto"
                style={{ maxWidth: "40rem" }}
              >
                {questionTexts}
              </div>
            )
          }
          {questionChoices.length === 4 ? (
            <>
              {(3 + 10 + 1 + 1) * 4 + 2 * 3 + 4 >= maxWidth ? (
                <>
                  <div
                    className="columns is-centered m-0 "
                    style={{
                      gap: "2rem",
                    }}
                  >
                    <GapFill
                      index={0}
                      onChange={handleChange(0)}
                      answer={answers[0] || ""}
                      correctAnswers={correctAnswers[0]}
                      checkingState={checkingState}
                      choice={questionChoices[0]}
                    />
                    <GapFill
                      index={1}
                      onChange={handleChange(1)}
                      answer={answers[1] || ""}
                      correctAnswers={correctAnswers[1]}
                      checkingState={checkingState}
                      choice={questionChoices[1]}
                    />
                  </div>
                  <div
                    className="columns is-centered m-0 "
                    style={{
                      gap: "2rem",
                    }}
                  >
                    <GapFill
                      index={2}
                      onChange={handleChange(2)}
                      answer={answers[2] || ""}
                      correctAnswers={correctAnswers[2]}
                      checkingState={checkingState}
                      choice={questionChoices[2]}
                    />
                    <GapFill
                      index={3}
                      onChange={handleChange(3)}
                      answer={answers[3] || ""}
                      correctAnswers={correctAnswers[3]}
                      checkingState={checkingState}
                      choice={questionChoices[3]}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="columns is-centered m-0 "
                    style={{
                      gap: "2rem",
                    }}
                  >
                    {questionChoices.map((choice, index) => {
                      return (
                        <GapFill
                          key={index}
                          index={index}
                          onChange={handleChange(index)}
                          answer={answers[index] || ""}
                          correctAnswers={correctAnswers[index]}
                          checkingState={checkingState}
                          choice={questionChoices[index]}
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {questionChoices.length === 6 ? (
                <>
                  {(3 + 10 + 1 + 1) * 3 + 2 * 2 + 4 < maxWidth ? (
                    <>
                      <div
                        className="columns is-centered m-0 "
                        style={{
                          gap: "2rem",
                        }}
                      >
                        <GapFill
                          index={0}
                          onChange={handleChange(0)}
                          answer={answers[0] || ""}
                          correctAnswers={correctAnswers[0]}
                          checkingState={checkingState}
                          choice={questionChoices[0]}
                        />
                        <GapFill
                          index={1}
                          onChange={handleChange(1)}
                          answer={answers[1] || ""}
                          correctAnswers={correctAnswers[1]}
                          checkingState={checkingState}
                          choice={questionChoices[1]}
                        />
                        <GapFill
                          index={2}
                          onChange={handleChange(2)}
                          answer={answers[2] || ""}
                          correctAnswers={correctAnswers[2]}
                          checkingState={checkingState}
                          choice={questionChoices[2]}
                        />
                      </div>
                      <div
                        className="columns is-centered m-0 "
                        style={{
                          gap: "2rem",
                        }}
                      >
                        <GapFill
                          index={3}
                          onChange={handleChange(3)}
                          answer={answers[3] || ""}
                          correctAnswers={correctAnswers[3]}
                          checkingState={checkingState}
                          choice={questionChoices[3]}
                        />
                        <GapFill
                          index={4}
                          onChange={handleChange(4)}
                          answer={answers[4] || ""}
                          correctAnswers={correctAnswers[4]}
                          checkingState={checkingState}
                          choice={questionChoices[4]}
                        />
                        <GapFill
                          index={5}
                          onChange={handleChange(5)}
                          answer={answers[5] || ""}
                          correctAnswers={correctAnswers[5]}
                          checkingState={checkingState}
                          choice={questionChoices[5]}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="columns is-centered m-0 "
                        style={{
                          gap: "2rem",
                        }}
                      >
                        <GapFill
                          index={0}
                          onChange={handleChange(0)}
                          answer={answers[0] || ""}
                          correctAnswers={correctAnswers[0]}
                          checkingState={checkingState}
                          choice={questionChoices[0]}
                        />
                        <GapFill
                          index={1}
                          onChange={handleChange(1)}
                          answer={answers[1] || ""}
                          correctAnswers={correctAnswers[1]}
                          checkingState={checkingState}
                          choice={questionChoices[1]}
                        />
                      </div>
                      <div
                        className="columns is-centered m-0 "
                        style={{
                          gap: "2rem",
                        }}
                      >
                        <GapFill
                          index={2}
                          onChange={handleChange(2)}
                          answer={answers[2] || ""}
                          correctAnswers={correctAnswers[2]}
                          checkingState={checkingState}
                          choice={questionChoices[2]}
                        />
                        <GapFill
                          index={3}
                          onChange={handleChange(3)}
                          answer={answers[3] || ""}
                          correctAnswers={correctAnswers[3]}
                          checkingState={checkingState}
                          choice={questionChoices[3]}
                        />
                      </div>
                      <div
                        className="columns is-centered m-0 "
                        style={{
                          gap: "2rem",
                        }}
                      >
                        <GapFill
                          index={4}
                          onChange={handleChange(4)}
                          answer={answers[4] || ""}
                          correctAnswers={correctAnswers[4]}
                          checkingState={checkingState}
                          choice={questionChoices[4]}
                        />
                        <GapFill
                          index={5}
                          onChange={handleChange(5)}
                          answer={answers[5] || ""}
                          correctAnswers={correctAnswers[5]}
                          checkingState={checkingState}
                          choice={questionChoices[5]}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div
                  className="columns m-0 is-multiline is-centered"
                  style={{ gap: "2rem" }}
                >
                  {questionChoices.map((choice, index) => {
                    return (
                      <GapFill
                        key={index}
                        index={index}
                        onChange={handleChange(index)}
                        answer={answers[index] || ""}
                        correctAnswers={correctAnswers[index]}
                        checkingState={checkingState}
                        choice={questionChoices[index]}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
      )}

      {explanationState && (
        <>
          <div
            className={`${isLoading ? "is-hidden" : ""
              } question-container is-flex is-flex-direction-column has-text-kurio-main-color-black`}
            style={{
              padding: "1.5rem",
              gap: "1.5rem",
            }}
          >
            <div
              className="tag has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
              style={{
                borderRadius: "1rem",
                fontSize: "1.25rem",
                margin: "0 auto",
              }}
            >
              <p
                style={{
                  fontWeight: 700,
                }}
              >
                Đáp án
              </p>
            </div>

            {questionChoices.length === 4 ? (
              <>
                {(3 + 10 + 1 + 1) * 4 + 2 * 3 + 4 >= maxWidth ? (
                  <>
                    <>
                      <div className="columns is-centered m-0 "
                        style={{
                          gap: "2rem",
                        }}>
                        <GapFill
                          index={0}
                          onChange={handleChange(0)}
                          questionSolutions={questionSolutions[0] || ""}
                          correctAnswers={correctAnswers[0]}
                          checkingState={checkingState}
                          choice={questionChoices[0]}
                        />
                        <GapFill
                          index={1}
                          onChange={handleChange(1)}
                          questionSolutions={questionSolutions[1] || ""}
                          correctAnswers={correctAnswers[1]}
                          checkingState={checkingState}
                          choice={questionChoices[1]}
                        />
                      </div>
                      <div className="columns is-centered m-0 "
                        style={{
                          gap: "2rem",
                        }}>
                        <GapFill
                          index={2}
                          onChange={handleChange(2)}
                          questionSolutions={questionSolutions[2] || ""}
                          correctAnswers={correctAnswers[2]}
                          checkingState={checkingState}
                          choice={questionChoices[2]}
                        />
                        <GapFill
                          index={3}
                          onChange={handleChange(3)}
                          questionSolutions={questionSolutions[3] || ""}
                          correctAnswers={correctAnswers[3]}
                          checkingState={checkingState}
                          choice={questionChoices[3]}
                        />
                      </div>
                    </>
                  </>
                ) : (
                  <>
                    <div className="columns is-centered m-0 "
                      style={{
                        gap: "2rem",
                      }}>
                      <GapFill
                        index={0}
                        onChange={handleChange(0)}
                        questionSolutions={questionSolutions[0] || ""}
                        correctAnswers={correctAnswers[0]}
                        checkingState={checkingState}
                        choice={questionChoices[0]}
                      />
                      <GapFill
                        index={1}
                        onChange={handleChange(1)}
                        questionSolutions={questionSolutions[1] || ""}
                        correctAnswers={correctAnswers[1]}
                        checkingState={checkingState}
                        choice={questionChoices[1]}
                      />
                      <GapFill
                        index={2}
                        onChange={handleChange(2)}
                        questionSolutions={questionSolutions[2] || ""}
                        correctAnswers={correctAnswers[2]}
                        checkingState={checkingState}
                        choice={questionChoices[2]}
                      />
                      <GapFill
                        index={3}
                        onChange={handleChange(3)}
                        questionSolutions={questionSolutions[3] || ""}
                        correctAnswers={correctAnswers[3]}
                        checkingState={checkingState}
                        choice={questionChoices[3]}
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {questionChoices.length === 6 ? (
                  <>
                    {(3 + 10 + 1 + 1) * 3 + 2 * 2 + 4 < maxWidth ? (
                      <>
                        <div className="columns is-centered m-0 "
                          style={{
                            gap: "2rem",
                          }}>
                          <GapFill
                            index={0}
                            onChange={handleChange(0)}
                            questionSolutions={questionSolutions[0] || ""}
                            correctAnswers={correctAnswers[0]}
                            checkingState={checkingState}
                            choice={questionChoices[0]}
                          />
                          <GapFill
                            index={1}
                            onChange={handleChange(1)}
                            questionSolutions={questionSolutions[1] || ""}
                            correctAnswers={correctAnswers[1]}
                            checkingState={checkingState}
                            choice={questionChoices[1]}
                          />
                          <GapFill
                            index={2}
                            onChange={handleChange(2)}
                            questionSolutions={questionSolutions[2] || ""}
                            correctAnswers={correctAnswers[2]}
                            checkingState={checkingState}
                            choice={questionChoices[2]}
                          />
                        </div>
                        <div className="columns is-centered m-0 "
                          style={{
                            gap: "2rem",
                          }}>
                          <GapFill
                            index={3}
                            onChange={handleChange(3)}
                            questionSolutions={questionSolutions[3] || ""}
                            correctAnswers={correctAnswers[3]}
                            checkingState={checkingState}
                            choice={questionChoices[3]}
                          />
                          <GapFill
                            index={4}
                            onChange={handleChange(4)}
                            questionSolutions={questionSolutions[4] || ""}
                            correctAnswers={correctAnswers[4]}
                            checkingState={checkingState}
                            choice={questionChoices[4]}
                          />
                          <GapFill
                            index={5}
                            onChange={handleChange(5)}
                            questionSolutions={questionSolutions[5] || ""}
                            correctAnswers={correctAnswers[5]}
                            checkingState={checkingState}
                            choice={questionChoices[5]}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="columns is-centered m-0 "
                          style={{
                            gap: "2rem",
                          }}>
                          {questionChoices.map((choice, index) => {
                            return (
                              <GapFill
                                key={index}
                                index={index}
                                onChange={handleChange(index)}
                                questionSolutions={
                                  questionSolutions[index] || ""
                                }
                                correctAnswers={correctAnswers[index]}
                                checkingState={checkingState}
                                choice={questionChoices[index]}
                              />
                            );
                          })}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="columns is-centered m-0 "
                      style={{
                        gap: "2rem",
                      }}>
                      {questionChoices.map((choice, index) => {
                        return (
                          <GapFill
                            key={index}
                            index={index}
                            onChange={handleChange(index)}
                            questionSolutions={questionSolutions[index] || ""}
                            correctAnswers={correctAnswers[index]}
                            checkingState={checkingState}
                            choice={questionChoices[index]}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
              </>
            )}

            {(explanationTexts !== null &&
              explanationTexts !== undefined &&
              explanationTexts?.length > 0) ||
              (explanationImages !== null &&
                explanationImages !== undefined &&
                explanationImages?.length > 0) ? (
              <div
                className="explanation-container has-text-kurio-main-color-black is-flex is-flex-direction-column is-align-items-center"
                style={{
                  gap: "1.5rem",
                  fontSize: "1.25rem",
                }}
              >
                <div
                  className="tag has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
                  style={{
                    borderRadius: "1rem",
                    fontSize: "1.25rem",
                  }}
                >
                  <p
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    Giải thích
                  </p>
                </div>

                <div
                  className="is-flex is-flex-direction-column is-align-items-center"
                  style={{
                    gap: "1.5rem",
                    fontSize: "1.25rem",
                    padding: "1rem 1.5rem",
                    width: "100%",
                  }}
                >
                  <>
                    {((explanationTexts !== null &&
                      explanationTexts !== undefined &&
                      explanationTexts?.length > 0) ||
                      (explanationImages !== null &&
                        explanationImages !== undefined &&
                        explanationImages?.length > 0)) &&
                      explanation.texts?.length >= explanation.images?.length && (
                        <>
                          {explanation.texts.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="is-flex is-flex-direction-column has-text-justified has-text-kurio-main-color-black"
                                style={{
                                  gap: "1.5rem",
                                  width: "100%",
                                }}
                              >
                                {explanationTexts !== null &&
                                  explanationTexts !== undefined &&
                                  item?.length > 0 && (
                                    <div key={index}>{explanationTexts[index]}</div>
                                  )}
                                {/* only appears when explanationImages has length greater than 0 */}
                                {explanationImages !== null &&
                                  explanationImages !== undefined &&
                                  explanationImages[index]?.length > 0 && (
                                    <div
                                      className="column is-narrow is-flex is-justify-content-center is-align-items-center is-centered m-0 p-0"
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <ImageFromUrl
                                        objectId={explanationImages[index]}
                                      />
                                    </div>
                                  )}
                              </div>
                            );
                          })}
                        </>
                      )}
                    {((explanationTexts !== null &&
                      explanationTexts !== undefined &&
                      explanationTexts?.length > 0) ||
                      (explanationImages !== null &&
                        explanationImages !== undefined &&
                        explanationImages?.length > 0)) &&
                      explanation.texts?.length < explanation.images?.length && (
                        <>
                          {explanationImages.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="is-flex is-flex-direction-column has-text-justified has-text-kurio-main-color-black"
                                style={{
                                  gap: "1.5rem",
                                  width: "100%",
                                }}
                              >
                                {explanationTexts !== null &&
                                  explanationTexts !== undefined &&
                                  explanation.texts[index]?.length > 0 && (
                                    <div key={index}>{explanationTexts[index]}</div>
                                  )}
                                {/* only appears when explanationImages has length greater than 0 */}
                                {explanationImages !== null &&
                                  explanationImages !== undefined &&
                                  item?.length > 0 && (
                                    <div
                                      className="column is-narrow is-flex is-justify-content-center is-align-items-center is-centered m-0 p-0"
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <ImageFromUrl
                                        objectId={explanationImages[index]}
                                      />
                                    </div>
                                  )}

                              </div>
                            );
                          })}
                        </>
                      )}
                  </>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}

      <div
        className={` is-flex is-flex-direction-row ${!isPreview
          ? !question.state
            ? checkingState &&
              correct &&
              explanation?.texts?.every((item) => item?.length <= 0) &&
              explanation?.images?.every((item) => item?.length <= 0)
              ? "is-align-items-center is-justify-content-center"
              : "is-align-items-center is-justify-content-center"
            : questionIndex < seenQuestions - 1
              ? "is-align-items-center is-justify-content-center"
              : checkingState &&
                correct &&
                explanation?.texts?.every((item) => item?.length <= 0) &&
                explanation?.images?.every((item) => item?.length <= 0)
                ? "is-align-items-center is-justify-content-center"
                : "is-align-items-center is-justify-content-center"
          : "is-align-items-center is-justify-content-center"
          } `}
        style={{
          padding: "0 2rem 2rem",
          gap: "1rem",
        }}
      >
        <div
          className="is-flex is-flex-direction-row is-align-items-flex-start p-0"
          style={{ gap: "1rem" }}
        >
          {/* only appears when checkingState is false */}
          {!checkingState && !seenAnswer && (
            <PrimaryButtonQuestion
              onClick={handleCheck}
              disabled={
                answers.some((item) => item === undefined || item === "") ||
                answers.length < questionSolutions?.length
              }
            >
              {ConstantStrings.SUBMIT}
            </PrimaryButtonQuestion>
          )}
          {/* Next and "Xem giai thich" button appears when checkingState is true */}
          {checkingState && !explanationState && (
            <>
              <SecondaryButtonQuestion
                defaultColor={"is-kurio-purple-light-2"}
                onClick={handleShowExplanation}
              >
                {ConstantStrings.SHOW_EXPLANATION_PRACTICE}
              </SecondaryButtonQuestion>
            </>
          )}
          {/* "Xem cau hoi" button appears when explanationState is true */}
          {explanationState && (
            <SecondaryButtonQuestion
              defaultColor={"is-kurio-purple-light-2"}
              onClick={() => {
                // setLocalStorage(
                //   null,
                //   null,
                //   answers,
                //   correctAnswers,
                //   checkingState,
                //   false,
                //   seenAnswer
                // );
                setExplanationState(false);
              }}
            >
              {ConstantStrings.SHOW_QUESTION}
            </SecondaryButtonQuestion>
          )}
        </div>

        {checkingState && (
          <div
            className={` ${question.state && questionIndex < seenQuestions - 1
              }`}
          >
            <PrimaryButtonQuestion onClick={showNextQuestion}>
              {ConstantStrings.NEXT}
            </PrimaryButtonQuestion>
          </div>
        )}
        {!checkingState && (
          <div
            className={` ${question.state && questionIndex < seenQuestions - 1 && !isPreview
              ? "is-hidden"
              : ""
              }`}
          >
            <TertiaryButtonQuestion
              onClick={showNextQuestion}
              rotate={true}
              suffix={"admin/frontend-images/button/button-skip-icon"}
            >
              {ConstantStrings.SKIP}
            </TertiaryButtonQuestion>
          </div>
        )}
      </div>
    </div>
  );
}

export default Gapfill;
