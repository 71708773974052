import React, { useState } from "react";

const SlideOnSlider = ({ isLight, onClick, children, isClick, disabled }) => {
  const [focus, setFocus] = useState(false);
  const [click, setClick] = useState(false);
  const audio = new Audio("/sounds/click.mp3");
  var style = {
    width: "0.75rem",
    height: "0.75rem",
    border: "none",
    borderRadius: "9999rem",
    boxShadow: "none",
  };
  if (((!isLight && !focus) || (isLight && !focus)) && !isClick) {
    style = {
      ...style,
      opacity: "0.3",
    };
  }
  const handleMouseEnter = () => {
    if (!click) {
      setFocus(true);
    }
  };
  const handleMouseLeave = () => {
    if (!click) {
      setFocus(false);
    }
  };
  const handleClick = () => {
    setClick(true);
    setFocus(false);
    audio.play();
    if (onClick) {
      onClick();
    }
  };
  return (
    <button
      className={`${
        isLight
          ? "has-background-secondary-button-default-color"
          : "has-background-secondary-button-clicked-color"
      } is-clickable `}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={style}
      disabled={disabled}
      onClick={handleClick}
      type="button"
    >
      {children}
    </button>
  );
};

export default SlideOnSlider;
