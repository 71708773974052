import React from "react";
import { useNavigate } from "react-router-dom";

const PracticeIKMCLayout = ({ children }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/practice-ikmc");
  };
  return (
    <div
      className="columns is-mobile m-0 p-0"
      style={{
        minHeight: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        // backgroundImage: "url('/images/hub/background-list-lesson.svg')",
        backgroundAttachment: "fixed",
      }}
    >
      <div
        className=" is-flex is-align-items-center is-align-self-stretch"
        style={{ minHeight: "100%", position: "fixed" }}
      >
        <div
          className="column is-narrow has-background-kurio-main-color-light-gray has-text-kurio-main-color-black"
          style={{
            maxWidth: "17.5rem",
            width: "17.5rem",
            padding: "2.5rem",
            height: "34.5rem",
            position: "fixed",
            borderRadius: "0 1rem 1rem 0",
            border: "0.09375rem solid #0A2A66",
            boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
          }}
        >
          <div
            className="is-flex is-flex-direction-column is-justify-content-space-between"
            style={{
              position: "fixed",
              width: "12.5rem",
              height: "25rem",
            }}
          >
            <div
              className="has-text-uppercase has-text-kurio-main-color-white has-text-weight-bold is-clickable is-flex is-align-items-center has-background-kurio-main-color-black is-align-items-center"
              style={{
                fontSize: "0.875rem",
                lineHeight: "1rem",
                color: "#0A2A66",
                // width: "12.75rem",
                height: "2.5rem",
                borderRadius: "0rem 1rem 1rem 0rem",
                border: "0.09375rem solid #0A2A66",
                boxShadow: "0.125rem 0.125rem 0rem 0rem #0A2A66",
                padding: "0.5rem 0.75rem",
                position: "absolute",
                top: "-3.85rem",
                left: "-2.59375rem",
                gap: "0.75rem",
              }}
              onClick={handleClick}
            >
              <p style={{ fontSize: "1.4rem", lineHeight: "1rem" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="15"
                  viewBox="0 0 7 15"
                  fill="none"
                >
                  <path
                    d="M5.82866 14.4997C5.53666 14.4997 5.24666 14.3727 5.04866 14.1267L0.22066 8.12674C-0.07734 7.75574 -0.07334 7.22574 0.23166 6.85974L5.23166 0.85974C5.58466 0.43574 6.21566 0.37874 6.64066 0.73174C7.06466 1.08474 7.12166 1.71574 6.76766 2.13974L2.29266 7.51074L6.60766 12.8727C6.95366 13.3027 6.88566 13.9327 6.45466 14.2787C6.27066 14.4277 6.04866 14.4997 5.82866 14.4997Z"
                    fill="#FAFBFC"
                  />
                </svg>
              </p>
              <p>Quay lại</p>
            </div>
            <img src="/images/practice-ikmc/menu-ikmc-left-icon.svg" alt="" />
            <div className="is-flex is-flex-gap-5 is-flex-direction-column is-align-items-flex-start">
              <p
                className="has-text-weight-bold has-text-centered"
                style={{
                  fontSize: "1.375rem",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  lineHeight: "120%",
                }}
              >
                Luyện thi Kangaroo - IKMC
              </p>
              <div
                className="is-flex is-flex-gap-4 is-flex-direction-column is-align-items-flex-start has-text-weight-normal"
                style={{ width: "100%" }}
              >
                <div
                  className=" is-flex is-justify-content-center is-align-items-center has-text-centered is-align-self-stretch is-clickable has-text-weight-bold"
                  onClick={() => navigate("/roadmap-exam")}
                  style={{
                    padding: "0.625rem 1rem",
                    width: "100%",
                    height: "3.5rem",
                    border:
                      window.location.pathname === "/roadmap-exam"
                        ? "0.09375rem solid #0A2A66"
                        : "",
                    borderRadius: "1rem",
                    boxShadow:
                      window.location.pathname === "/roadmap-exam"
                        ? "0.125rem 0.125rem 0rem 0rem #0A2A66"
                        : "",
                    fontSize: "1rem",
                    // fontFamily: "Dela Gothic One",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    background:
                      window.location.pathname === "/roadmap-exam"
                        ? "#FAFBFC"
                        : "#D8E1F4",
                  }}
                >
                  Chinh phục<br></br>Điểm 10 Môn Toán
                </div>
                <div
                  className=" is-flex is-justify-content-center is-align-items-center is-align-self-stretch is-clickable has-text-weight-bold"
                  onClick={() => navigate("/exams-vault")}
                  style={{
                    padding: "1rem 0rem",
                    width: "100%",
                    height: "3.5rem",
                    border:
                      window.location.pathname === "/exams-vault"
                        ? "0.09375rem solid #0A2A66"
                        : "",
                    borderRadius: "1rem",
                    boxShadow:
                      window.location.pathname === "/exams-vault"
                        ? "0.125rem 0.125rem 0rem 0rem #0A2A66"
                        : "",
                    fontSize: "1rem",
                    // fontFamily: "Dela Gothic One",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    background:
                      window.location.pathname === "/exams-vault"
                        ? "#FAFBFC"
                        : "#D8E1F4",
                  }}
                >
                  Đề thi IKMC
                </div>
                <div
                  className=" is-flex is-justify-content-center is-align-items-center is-align-self-stretch is-clickable has-text-weight-bold"
                  onClick={() => navigate("/challenge-ikmc")}
                  style={{
                    padding: "1rem 0rem",
                    width: "100%",
                    height: "3.5rem",
                    border:
                      window.location.pathname === "/challenge-ikmc"
                        ? "0.09375rem solid #0A2A66"
                        : "",
                    borderRadius: "1rem",
                    boxShadow:
                      window.location.pathname === "/challenge-ikmc"
                        ? "0.125rem 0.125rem 0rem 0rem #0A2A66"
                        : "",
                    fontSize: "1rem",
                    // fontFamily: "Dela Gothic One",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    background:
                      window.location.pathname === "/challenge-ikmc"
                        ? "#FAFBFC"
                        : "#D8E1F4",
                  }}
                >
                  Thử thách IKMC
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="column is-flex is-align-items-center is-justify-content-center "
        style={{
          padding: "5rem 0rem",
          marginLeft: "17.5rem",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default PracticeIKMCLayout;
