import React, { useEffect } from 'react';

const IOSApp = () => {
  useEffect(() => {
    window.location.href =
      "https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573";
  }, []);
  return <></>;
}

export default IOSApp