import React, { createContext, useEffect, useState } from "react";
import PopupCloseButton from "../../../components/button/special-button/PopupCloseButton";
import Keyboard from "./Keyboard";
import "./Numberle.style.css";
import Score from "./Score";
import Tile from "./Tile";

export const NumberleContext = createContext();
const Numberle = () => {
  const createArray = (columns, rows) => {
    let array = [];
    for (let i = 0; i < rows; i++) {
      let row = [];
      for (let j = 0; j < columns; j++) {
        row.push({ letter: "", status: "white" });
      }
      array.push(row);
    }
    return array;
  };
  const initialState = {
    board: [],
    correctList: [],
    foundList: [],
    usedList: [],
    currentRow: 0,
    currentLetterPos: 0,
    currentNumber: "",
    errorMessage: "Bạn có đoán được tôi đang nghĩ đến số nào không?",
    gameOver: false,
    // gamesPlayed: 0,
    // gamesWon: 0,
  };
  const [state, setState] = useState(initialState);
  const [showModal, setShowModal] = useState(true);
  const [mode, setMode] = useState(2); //2: 2 chữ số, 3: 3 chữ số, số 4: 4 chữ số, 5: 5 chữ số

  const generateRandomString = (number) => {
    let randomString = "";
    for (let i = 0; i < number; i++) {
      let randomNumber = 0;
      if (randomString === "" && i === 0) {
        randomNumber = Math.floor(Math.random() * 9) + 1;
      } else {
        randomNumber = Math.floor(Math.random() * 10);
      }
      randomString += randomNumber.toString();
    }
    return randomString;
  };

  const randomNumber = async () => {
    setState((prevState) => ({
      ...prevState,
      board: createArray(mode, 6),
      currentNumber: generateRandomString(mode),
    }));
  };

  const rePlay = () => {
    const newState = {
      board: createArray(mode, 6),
      correctList: [],
      foundList: [],
      usedList: [],
      currentRow: 0,
      currentLetterPos: 0,
      currentNumber: generateRandomString(mode),
      errorMessage: "Bạn có đoán được tôi đang nghĩ đến số nào không?",
      gameOver: false,
    };
    setState({ ...state, ...newState });
  };
  const onDelete = () => {
    let currRow = state.currentRow;
    let currentLetterPos = state.currentLetterPos;
    const newBoard = state.board;
    if (currentLetterPos === 0) return;
    newBoard[currRow][currentLetterPos - 1].letter = "";
    setState((prevState) => ({
      ...prevState,
      board: newBoard,
      currentLetterPos: currentLetterPos - 1,
    }));
  };
  const onSelect = (keyVal) => {
    let currRow = state.currentRow;
    let currBoard = state.board;
    if (!currBoard[currRow].every((item) => item.letter !== "")) {
      let currentLetterPos = state.currentLetterPos;
      currBoard[currRow][currentLetterPos].letter = keyVal;
      setState((prevState) => ({
        ...prevState,
        board: currBoard,
        currentLetterPos: currentLetterPos + 1,
      }));
    }
  };
  const onEnter = () => {
    let currRow = state.currentRow;
    let currentLetterPos = state.currentLetterPos;
    let currentBoard = state.board;
    let errorMessage = state.errorMessage;
    let tileColor = "white";
    let correctNumber = state.currentNumber;
    let foundList = [...state.foundList];
    let usedList = [...state.usedList];
    let correctList = [...state.correctList];
    // let gamesWon = state.gamesWon;
    // let gamesPlayed = state.gamesPlayed;
    let newRow = [];
    let currWord = "";
    for (let i = 0; i < mode; i++) {
      currWord += currentBoard[currRow][i].letter;
    }
    if (currentLetterPos !== mode) return;
    for (let i = 0; i < currWord.length; i++) {
      if (!usedList.includes(currWord[i])) {
        usedList.push(currWord[i]);
      }

      if (correctNumber.includes(currWord[i])) {
        if (!foundList.includes(currWord[i])) {
          foundList.push(currWord[i]);
        }

        if (correctNumber[i] === currWord[i]) {
          tileColor = "#4B9631";
          correctList.push(currWord[i]);
        } else {
          if (foundEmAll(currWord, currWord[i], i)) {
            tileColor = "#8C9DC1";
          } else {
            tileColor = "#FED131";
          }
        }
      } else {
        tileColor = "#8C9DC1";
      }

      newRow[i] = {
        letter: currWord[i],
        status: tileColor,
      };

      tileColor = "white";
    }
    currentBoard[currRow] = newRow;
    let gameOver = false;
    currRow++;

    if (currRow > 5) {
      gameOver = true;
      errorMessage =
        "Tiếc quá! Số bí ẩn là " + correctNumber + ". Cùng thử lại nhé!";
    }

    if (currWord === correctNumber) {
      errorMessage = "Chúc mừng bạn là người thắng cuộc!";
      gameOver = true;
    }
    setState((prevState) => ({
      ...prevState,
      board: currentBoard,
      currentLetterPos: 0,
      currentRow: currRow,
      gameOver: gameOver,
      errorMessage: errorMessage,
      usedList: usedList,
      foundList: foundList,
      correctList: correctList,
    }));
  };
  const handleShowModal = () => {
    setShowModal(true);
  };

  // Function to check if the submitted Number matches the current Number and if all letters are in the right spot
  const foundEmAll = (NumberSubmitted, letterToCheck, letterIndex) => {
    let NumberArray = NumberSubmitted.split("");
    const counts = {};
    for (const num of NumberArray) {
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }

    let actualNumberArray = state.currentNumber.split("");
    let countsActual = {};
    for (const num of actualNumberArray) {
      countsActual[num] = countsActual[num] ? countsActual[num] + 1 : 1;
    }
    let foundCounter = 0;
    let foundIt = false;

    for (let i = 0; i < 5; i++) {
      if (
        NumberSubmitted[i] === state.currentNumber[i] &&
        NumberSubmitted[i] === letterToCheck
      ) {
        foundIt = true;
        foundCounter++;
      }
      if (foundIt) {
        if (countsActual[letterToCheck] <= foundCounter) {
          return true;
        }
      }
      foundIt = false;
    }
    return false;
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const handleChangeMode = (mode) => {
    setMode(mode);
    const newState = {
      board: createArray(mode, 6),
      correctList: [],
      foundList: [],
      usedList: [],
      currentRow: 0,
      currentLetterPos: 0,
      currentNumber: generateRandomString(mode),
      errorMessage: "Bạn có đoán được tôi đang nghĩ đến số nào không?",
      gameOver: false,
    };
    setState({ ...state, ...newState });
  };

  useEffect(() => {
    randomNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="is-flex is-flex-direction-column is-justify-content-flex-start pt-7 pb-4 px-2 numberle"
      style={{
        minHeight: "100vh",
      }}
    >
      {showModal && (
        <div className="modal is-active" style={{ opacity: 1, zIndex: 100 }}>
          <div
            className=" modal-background"
            style={{ opacity: 0.8 }}
            onClick={closeModal}
          ></div>
          <div
            className="is-relative px-2 py-3 has-text-kurio-main-color-black is-relative has-background-kurio-main-color-white is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
            style={{
              width: "90vw",
              maxWidth: "30rem",
              borderRadius: "0.5rem",
              zIndex: 1,
            }}
          >
            <div
              className=" rule-numberle"
              style={{
                fontSize: "0.875rem",
                lineHeight: "normal",
                overflowY: "overlay",
                padding: "0rem 2rem",
                maxHeight: "90vh",
              }}
            >
              <div className="is-flex is-flex-direction-column is-flex-gap-4">
                <div className="is-flex is-flex-direction-column is-flex-gap-4">
                  <p>
                    Cùng chơi <b>SỐ BÍ ẨN!</b> Bạn có vài lần để đoán số
                  </p>
                  <p>Mỗi lần bạn đoán 1 dãy chữ số. Ấn Enter để nộp.</p>
                  <p>Sau mỗi lần đoán, màu sắc của các ô sẽ thay đổi.</p>
                </div>
                <hr
                  className="m-0"
                  style={{ border: "1px solid #0A2A66", height: "0rem" }}
                />
                <p className="is-uppercase has-text-weight-bold">Ví dụ:</p>
                <p>
                  Chữ số 7 có trong số <b>SỐ BÍ ẨN</b> và ở đúng vị trí.
                </p>
                <div className="is-flex is-flex-gap-2 tile-modal">
                  <Tile letter={"7"} color={"#4B9631"} />
                  <Tile letter={"5"} color={"white"} />
                  <Tile letter={"5"} color={"white"} />
                  <Tile letter={"4"} color={"white"} />
                  <Tile letter={"3"} color={"white"} />
                </div>
                <p>
                  Chữ số 6 có trong số <b>SỐ BÍ ẨN</b> nhưng ở sai vị trí.
                </p>
                <div className="is-flex is-flex-gap-2 tile-modal">
                  <Tile letter={"1"} color={"white"} />
                  <Tile letter={"6"} color={"#FED131"} />
                  <Tile letter={"8"} color={"white"} />
                  <Tile letter={"9"} color={"white"} />
                  <Tile letter={"0"} color={"white"} />
                </div>
                <p>
                  Chữ số 2 không có trong số <b>SỐ BÍ ẨN</b> ở bất kỳ vị trí
                  nào.
                </p>
                <div className="is-flex is-flex-gap-2 tile-modal">
                  <Tile letter={"5"} color={"white"} />
                  <Tile letter={"4"} color={"white"} />
                  <Tile letter={"2"} color={"#8C9DC1"} />
                  <Tile letter={"8"} color={"white"} />
                  <Tile letter={"9"} color={"white"} />
                </div>
                <p
                  className="mt-4"
                  style={{ fontSize: "0.625rem", lineHeight: "normal" }}
                >
                  Dựa trên trò chơi Wordle của Josh Wardle
                </p>
              </div>
            </div>
            <div
              onClick={closeModal}
              className="is-clickable close-modal-numberle"
              style={{
                position: "absolute",
              }}
            >
              <PopupCloseButton className={"close-modal-numberle-btn"} />
            </div>
          </div>
        </div>
      )}
      <NumberleContext.Provider
        value={{
          state,
          setState,
          onSelect,
          onDelete,
          onEnter,
        }}
      >
        <div className="is-flex is-flex-direction-column is-flex-gap-3">
          {state.errorMessage && (
            <div className="has-text-kurio-main-color-black has-text-weight-bold has-text-centered numberle-message">
              {state.errorMessage}
            </div>
          )}
          <div className="is-flex group-btn-mode is-flex-wrap-wrap is-align-items-center is-justify-content-center">
            {[2, 3, 4, 5].map((number) => (
              <div
                key={number}
                className={`${
                  mode === number
                    ? "has-text-kurio-main-color-white has-background-kurio-main-color-purple"
                    : "has-text-kurio-main-color-black has-background-main-color-white"
                } btn-mode is-clickable is-flex is-align-items-center is-justify-content-center is-uppercase has-text-weight-bold`}
                style={{
                  height: "auto",
                  borderRadius: "1rem",
                  border: mode === number ? "transparent" : "2px solid #0A2A66",
                }}
                onClick={() => handleChangeMode(number)}
              >
                <p>{`${number} chữ số`}</p>
              </div>
            ))}
          </div>
        </div>
        <div
          className="is-flex is-flex-direction-column is-flex-gap-2 p-2 is-align-items-center is-justify-content-center"
          // style={{ width: "21.875rem" }}
        >
          {state.board.map((board, idx) => {
            return (
              <div
                className="is-flex is-flex-direction is-flex-gap-2 is-align-items-center is-justify-content-center"
                key={idx}
              >
                {board.map((row, idx) => {
                  return (
                    <div
                      className="is-flex is-flex-gap-2 is-align-items-center is-justify-content-center"
                      key={idx}
                    >
                      <Tile letter={row.letter} color={row.status} />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <Keyboard />
        <Score
          gamesWon={state.gamesWon}
          gamesPlayed={state.gamesPlayed}
          handleShowModal={handleShowModal}
          rePlay={rePlay}
        />
      </NumberleContext.Provider>
    </div>
  );
};

export default Numberle;
