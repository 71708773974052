import instance from "./instance";

const practiceUrl = "/practice/v1/user/practice-elective";

export const createPractice = (filter) => {
  // checkAndCallDailyLoginAPI()
  return instance.put(`${practiceUrl}/create-new-practice`, filter);
};
export const getPracticeElectiveQuestionList = (id, page, size) => {
  // checkAndCallDailyLoginAPI()
  return instance.get(
    `${practiceUrl}/get-practice-questions/${id}?page=${page}&size=${size}`
  );
};
export const submitPracticeAnswers = (id, answer) => {
  // checkAndCallDailyLoginAPI()
  return instance.post(`${practiceUrl}/submit-practice-answers/${id}`, answer);
};
