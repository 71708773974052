import React from "react";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";

const Error = () => {
  const handleClick = () => {
    sessionStorage.removeItem("error");
    window.location.href = window.location.origin + "/";
  };
  return (
    <div
      className="is-flex is-align-items-center is-justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div
        className="has-text-centered is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
        style={{ gap: "2.875rem" }}
      >
        <img src="/images/error/kurio-error-image.svg" alt="loading" />
        <div
          className="is-flex is-justify-content-center is-align-items-center"
          style={{ gap: "2.5rem", width: "39.9375rem" }}
        >
          <p
            className="has-text-kurio-main-color-black has-text-right"
            style={{ lineHeight: "normal", fontStyle: "1.25rem" }}
          >
            Bạn vui lòng nhấn nút bên cạnh để quay lại trang chủ nhé!
          </p>
          <PrimaryButtonPageLevel
            isDarkColor={true}
            onClick={handleClick}
            style={{ height: "4rem" }}
          >
            Về Trang Chủ
          </PrimaryButtonPageLevel>
        </div>
        <p className="has-text-danger">
          {sessionStorage.getItem("error") && sessionStorage.getItem("error")}
        </p>
      </div>
    </div>
  );
};

export default Error;
