export const chunk = (items, size) => {
  const chunks = [];
  items = [].concat(...items);

  while (items.length) {
    chunks.push(items.splice(0, size));
  }

  return chunks;
};
export const findLastItemNonEmpty = (array) => {
  const lastNonEmptyElement = array
    .map((item) => (item.length > 0 ? item : null))
    .filter((item) => item !== null)
    .pop();
  return lastNonEmptyElement;
};
