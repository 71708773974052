/* eslint-disable no-loop-func */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import TagCheckMultipleResponse from "../../components/question/TagCheckMultipleResponse";
import ConstantStrings from "../../constants/ConstantStrings";
import { checkImage } from "../../constants/checkImage";
import { selectAnsweredQuestions } from "../../reducers/examsQuestionSlice";
import { cachesQuestion, getCachesQuestion } from "../helper/cacheQuestion";
import jsonParser from "../helper/jsonParser";
import { getCacheImage } from "../helper/parseImage";

function MultipleResponse({
  question,
  explanation,
  backgroundImage,
  questionIndex,
  questionId,
  seenQuestions,
  isPreview,
  questions,
  takeExamsQuestionId,
  handleQuestionChange,
  IKMCMode,
}) {
  const cache = getCachesQuestion(questionId, questionIndex);
  var questionTexts =
    question.texts !== null &&
      question.texts !== undefined &&
      question.texts.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;
  var questionChoices = question.choices;
  const answeredQuestions = useSelector(selectAnsweredQuestions);
  const cookies = new Cookies();
  //check if explanation is null
  var questionSolutions = question.solutions;
  //check if explanation is null
  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      (explanation.texts[0]?.length > 0 || explanation.texts[1]?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      (explanation.images[0]?.length > 0 || explanation.images[1]?.length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }

  const [selectedItem] = useState(() => {
    // const result = [];
    // result.push(
    //   );
    return questions
      .filter((question) => question.questionIndex === questionIndex)
      .map((item) => item.answer)
      .flat()
      .map((item, index) => {
        if (item === true) {
          return index;
        } else {
          return true;
        }
      });

    // return result;
  });

  //check if explanation is null
  //create a state variable to store the selected answer

  var [selected, setSelected] = useState(() => {
    if (question.state) {
      return question.state.selected;
    } else {
      if (selectedItem !== -1) {
        return selectedItem;
      } else {
        return [];
      }
    }
  });

  var [explanationState] = useState(() => {
    if (question.state) {
      return question.state.explanationState;
    } else {
      return false;
    }
  });

  const [allCorrect] = useState(() => {
    if (
      JSON.stringify(
        questions
          .filter((question) => question.questionIndex === questionIndex)
          .map((item) => item.question.solutions)
      ) ===
      JSON.stringify(
        questions
          .filter((question) => question.questionIndex === questionIndex)
          .map((item) => item.answer)
      )
    ) {
      return true;
    } else {
      return false;
    }
  });

  var maxLength = 0;
  for (var i = 0; i < questionChoices.length; i++) {
    if (questionChoices[i].length > maxLength) {
      maxLength = questionChoices[i].length;
    }
  }
  const [imgWidth, setImgWidth] = useState(() => {
    if (cache?.imgWidth) {
      return cache.imgWidth;
    } else {
      return 0;
    }
  });
  const [widthButton] = useState(
    checkImage(questionChoices[0]) ? 0 : maxLength >= 11 ? 20 : 10
  );
  const [maxWidth, setMaxWidth] = useState(() => {
    if (cache?.maxWidth) {
      return cache.maxWidth;
    } else {
      return 60;
    }
  });
  const [isLoading, setIsLoading] = useState(
    imgWidth === 0 && checkImage(questionChoices[0]) ? true : false
  );
  var backgroundStyle = {
    borderRadius: "0 0 1rem 1rem",
  };
  var nextButtonStyle = {
    width: "100%",
    maxWidth: "60rem",
    padding: "2rem 0 0 0",
  };
  if (IKMCMode) {
    nextButtonStyle = {
      ...nextButtonStyle,
      position: "absolute",
    };
  }
  if (backgroundImage !== null && backgroundImage) {
    backgroundStyle = {
      ...backgroundStyle,
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundStyle = {
      ...backgroundStyle,
      backgroundColor: "#E9EEF8",
    };
  }
  let buttonStyle = {};
  if (maxLength <= 10 && !checkImage(questionChoices[0])) {
    buttonStyle = {
      width: "10rem",
      padding: "0.75rem 1rem",
      height: "4rem",
      opacity: 1,
      fontWeight: "bold",
      fontSize: "1.125rem",
    };
  } else {
    if (checkImage(questionChoices[0])) {
      buttonStyle = {
        maxWidth: "20rem",
        width: `${imgWidth !== 0 ? `${imgWidth + 0.125}rem` : "auto"}`,
        height: "auto",
        padding: "1rem",
        whiteSpace: "pre-wrap",
        outline: "0.0625rem solid",
        opacity: 1,
        borderRadius: "1.5rem",
        fontWeight: "bold",
        fontSize: "1.125rem",
      };
    } else {
      buttonStyle = {
        width: "20rem",
        height: "auto",
        padding: "1rem 2.5rem",
        whiteSpace: "pre-wrap",
        outline: "0.0625rem solid",
        opacity: 1,
        fontWeight: "bold",
        fontSize: "1.125rem",
      };
    }
  }

  var selectedStyle = {
    ...buttonStyle,
    outline: "0.125rem solid",
  };

  var iconStyle = {
    zIndex: 1,
    position: "absolute",
    top: "0.7rem",
    width: "1.5rem",
    height: "1.5rem",
    left: "-0.7rem",
  };

  //McqButton component
  const handleSetWidthImage = (width) => {
    setImgWidth(width);
  };
  const divRef = useRef(null);
  const McqButton = ({ index, children }) => {
    const buttonRef = useRef(null);

    return (
      //if index in selected, set the style to selectedStyle
      //if index in selected and checkingState = true, set the style to correctStyle if the answer is correct, set the style to incorrectStyle if the answer is incorrect
      <div className="is-relative">
        {selected.includes(index) && question.showSolution ? (
          questionSolutions[index] === true && allCorrect === true ? (
            <img
              src="/images/common/greenV.svg"
              style={iconStyle}
              alt="loading"
            />
          ) : (
            <>
              <div
                className="icon"
                style={{
                  ...iconStyle,
                  width: "0.75rem",
                  height: "0.75rem",
                }}
              ></div>
            </>
          )
        ) : (
          <div className="icon" style={iconStyle}></div>
        )}
        <button
          ref={buttonRef}
          className={`${selected.includes(index)
              ? question.showSolution && allCorrect === true
                ? questionSolutions[index] === true
                  ? "has-background-kurio-green-light-3 is-kurio-green-main-accent has-text-kurio-green-main-accent"
                  : "has-background-kurio-red-light-3 is-kurio-red-main-accent has-text-kurio-red-main-accent"
                : "has-background-kurio-purple-light-3 is-kurio-purple-main-accent has-text-kurio-purple-main-accent"
              : question.showSolution
                ? " is-kurio-gray-main-accent has-text-kurio-gray-main-accent"
                : "has-text-kurio-main-color-black is-kurio-main-color-black"
            } button is-shadowless has-text-weight-bold is-outlined has-background-kurio-main-color-white`}
          style={
            selected.includes(index) && !question.showSolution
              ? selectedStyle
              : buttonStyle
          }
          onClick={() => handleSelect(index)}
          disabled={question.showSolution || isPreview}
        >
          {!children.slice(0, 2).includes("@@") && checkImage(children) ? (
            <>
              {getCacheImage([children]) &&
                getCachesQuestion(questionId, questionIndex) ? (
                <>
                  <img src={getCacheImage([children])} alt="loading" />
                </>
              ) : (
                <>
                  {index === 0 ? (
                    <ImageFromUrl
                      setImgWidth={(width) => handleSetWidthImage(width)}
                      objectId={children}
                    />
                  ) : (
                    <ImageFromUrl objectId={children} />
                  )}
                </>
              )}
            </>
          ) : (
            jsonParser(children)
          )}
        </button>
      </div>
    );
  };

  //mapping each choice to a component
  const MapChoices = questionChoices.map((choice, index) => {
    return (
      //id = "auto-height" if the choice is longer than 32 characters
      <div className="column is-narrow is-centered p-2" key={index}>
        <McqButton
          id={choice.length > 10 && !checkImage(choice) ? "auto-height" : ""}
          index={index}
        >
          {choice}
        </McqButton>
      </div>
    );
  });

  const McqButtonShowSolution = ({ index, children }) => {
    const buttonRef = useRef(null);

    let test = questionSolutions.map((item, index) => {
      if (item === true) {
        return index;
      } else {
        return true;
      }
    });

    test = test.filter((value) => value !== true);

    return (
      //if index in selected, set the style to selectedStyle
      //if index in selected and checkingState = true, set the style to correctStyle if the answer is correct, set the style to incorrectStyle if the answer is incorrect
      <div className="is-relative">
        {(test.includes(index) && question.showSolution) || isPreview ? (
          questionSolutions[index] === true ? (
            <img
              src="/images/common/greenV.svg"
              style={iconStyle}
              alt="loading"
            />
          ) : (
            <>
              <div
                className="icon"
                style={{
                  ...iconStyle,
                  width: "0.75rem",
                  height: "0.75rem",
                }}
              ></div>
            </>
          )
        ) : (
          <div className="icon" style={iconStyle}></div>
        )}
        <button
          ref={buttonRef}
          className={`${test.includes(index)
              ? question.showSolution || isPreview
                ? questionSolutions[index] === true &&
                // test.forEach((value) => {
                //   if (value !== true) {
                //     return value;
                //   }
                // })
                "has-background-kurio-green-light-3 is-kurio-green-main-accent has-text-kurio-green-main-accent"
                : "has-background-kurio-purple-light-3 is-kurio-purple-main-accent has-text-kurio-purple-main-accent"
              : question.showSolution || isPreview
                ? " is-kurio-gray-main-accent has-text-kurio-gray-main-accent"
                : "has-text-kurio-main-color-black is-kurio-main-color-black"
            } button is-shadowless has-text-weight-bold is-outlined has-background-kurio-main-color-white`}
          style={
            test.includes(index) && !question.showSolution
              ? selectedStyle
              : buttonStyle
          }
          onClick={() => handleSelect(test[index])}
          disabled={question.showSolution || isPreview}
        >
          {!children.slice(0, 2).includes("@@") && checkImage(children) ? (
            <>
              {getCacheImage([children]) &&
                getCachesQuestion(questionId, questionIndex) ? (
                <>
                  <img src={getCacheImage([children])} alt="loading" />
                </>
              ) : (
                <>
                  {index === 0 ? (
                    <ImageFromUrl
                      setImgWidth={(width) => handleSetWidthImage(width)}
                      objectId={children}
                    />
                  ) : (
                    <ImageFromUrl objectId={children} />
                  )}
                </>
              )}
            </>
          ) : (
            jsonParser(children)
          )}
        </button>
      </div>
    );
  };

  //mapping each choice to a component
  const MapChoicesShowSolution = questionChoices.map((choice, index) => {
    let test = questionSolutions.map((item, index) => {
      if (item === true) {
        return index;
      } else {
        return true;
      }
    });

    test = test.filter((value) => value !== true);

    if (test.includes(index) && questionSolutions[index] === true) {
      return (
        //id = "auto-height" if the choice is longer than 32 characters
        <div className="column is-narrow is-centered p-2" key={index}>
          <McqButtonShowSolution
            id={choice.length > 10 && !checkImage(choice) ? "auto-height" : ""}
            index={index}
          >
            {choice}
          </McqButtonShowSolution>
        </div>
      );
    }
  });

  const saveAnswer = (index) => {
    var questionState = {
      index: questionIndex,
      state: {
        selected: index,
      },
    };
    var answer = questionChoices.map((item) => false);
    index.map((item) => (answer[item] = true));

    if (index.length > 0) {
      index.map((item) => (answer[item] = true));
    } else {
      answer = null;
    }

    var newAnswer = {
      questionId: questionId,
      answer: answer,
      questionIndex: questionIndex,
      selected: index,
    };
    // setSelected(selected);
    var result = [...answeredQuestions];
    if (result.length === 0) {
      result.push(newAnswer);
    } else {
      var flag = true;
      for (let question = 0; question < answeredQuestions.length; question++) {
        if (answeredQuestions[question].questionId === newAnswer.questionId) {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      if (!flag) {
        result.push(newAnswer);
      } else {
        result = answeredQuestions.map((question) =>
          question.questionId === questionId ? newAnswer : question
        );
      }
    }
    var questionsAnswered = result;

    const submit = { submit: false, questions: [newAnswer] };
    var questionAnswered = { questionIndex: questionIndex, status: true };

    if (index.length === 0) {
      questionAnswered.status = false;
    }

    const value = {
      questionState,
      questionAnswered,
      questionsAnswered,
      submit,
    };

    localStorage.setItem("questionStateExams", JSON.stringify(value));
  };

  const handleSelect = (index) => {
    if (selected.includes(index)) {
      saveAnswer(selected.filter((item) => item !== index));
      setSelected(selected.filter((item) => item !== index));
    } else {
      if (!question.showSolution) {
        saveAnswer([...selected, index]);
      }
      setSelected([...selected, index]);
    }
  };

  const showNextQuestion = () => {
    if (questionIndex !== questions.length - 1) {
      handleQuestionChange(questionIndex + 1);
    } else {
      return;
    }
  };

  const showPreviousQuestion = () => {
    handleQuestionChange(questionIndex - 1);
  };

  const McqImageWrap = () => {
    return (
      <>
        {questionChoices.length === 4 &&
          (imgWidth + 0.125 + 1.5) * 4 + 8.5 > maxWidth ? (
          <div>
            <div className="columns is-centered p-0 m-0">
              {MapChoices[0]}
              {MapChoices[1]}
            </div>
            <div className="columns is-centered p-0 m-0">
              {MapChoices[2]}
              {MapChoices[3]}
            </div>
          </div>
        ) : questionChoices.length === 5 ? (
          <>
            {(imgWidth + 0.125 + 1.5) * 3 + 4 > maxWidth ? (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[2]}
                  {MapChoices[3]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[4]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                  {MapChoices[2]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[3]}
                  {MapChoices[4]}
                </div>
              </div>
            )}
          </>
        ) : questionChoices.length === 6 ? (
          <>
            {(imgWidth + 0.125 + 1.5) * 3 + 4 > maxWidth ? (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[2]}
                  {MapChoices[3]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[4]}
                  {MapChoices[5]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                  {MapChoices[2]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[3]}
                  {MapChoices[4]}
                  {MapChoices[5]}
                </div>
              </div>
            )}
          </>
        ) : questionChoices.length === 3 ? (
          <>
            {(imgWidth + 0.125 + 1.5) * 3 + 4 > maxWidth ? (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[2]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                  {MapChoices[2]}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="columns is-multiline is-centered p-2">
            {MapChoices}
          </div>
        )}
      </>
    );
  };

  const McqImageSolutionWrap = () => {
    return (
      <>
        {questionChoices.length === 4 &&
          (imgWidth + 0.125 + 1.5) * 4 + 8.5 > maxWidth ? (
          <div>
            <div className="columns is-centered p-0 m-0">
              {MapChoicesShowSolution[0]}
              {MapChoicesShowSolution[1]}
            </div>
            <div className="columns is-centered p-0 m-0">
              {MapChoicesShowSolution[2]}
              {MapChoicesShowSolution[3]}
            </div>
          </div>
        ) : questionChoices.length === 5 ? (
          <>
            {(imgWidth + 0.125 + 1.5) * 3 + 4 > maxWidth ? (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoicesShowSolution[0]}
                  {MapChoicesShowSolution[1]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoicesShowSolution[2]}
                  {MapChoicesShowSolution[3]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoicesShowSolution[4]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoicesShowSolution[0]}
                  {MapChoicesShowSolution[1]}
                  {MapChoicesShowSolution[2]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoicesShowSolution[3]}
                  {MapChoicesShowSolution[4]}
                </div>
              </div>
            )}
          </>
        ) : questionChoices.length === 6 ? (
          <>
            {(imgWidth + 0.125 + 1.5) * 3 + 4 > maxWidth ? (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoicesShowSolution[0]}
                  {MapChoicesShowSolution[1]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoicesShowSolution[2]}
                  {MapChoicesShowSolution[3]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoicesShowSolution[4]}
                  {MapChoicesShowSolution[5]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoicesShowSolution[0]}
                  {MapChoicesShowSolution[1]}
                  {MapChoicesShowSolution[2]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoicesShowSolution[3]}
                  {MapChoicesShowSolution[4]}
                  {MapChoicesShowSolution[5]}
                </div>
              </div>
            )}
          </>
        ) : questionChoices.length === 3 ? (
          <>
            {(imgWidth + 0.125 + 1.5) * 3 + 4 > maxWidth ? (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoicesShowSolution[0]}
                  {MapChoicesShowSolution[1]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoicesShowSolution[2]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoicesShowSolution[0]}
                  {MapChoicesShowSolution[1]}
                  {MapChoicesShowSolution[2]}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="columns is-multiline is-centered p-2">
            {MapChoicesShowSolution}
          </div>
        )}
      </>
    );
  };

  if (imgWidth !== 0) {
    cachesQuestion(questionId, questionIndex, imgWidth, maxWidth);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }
  useEffect(() => {
    if (divRef.current.clientWidth / ConstantStrings.BASE_REM !== maxWidth) {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    };
    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [maxWidth]);
  return (
    <>
      {/* <ScrollRef /> */}
      <div className="is-relative" ref={divRef}>
        <BackgroundImageFromUrl
          style={backgroundStyle}
          objectId={backgroundImage}
          className={`${!checkImage(backgroundImage)
              ? "has-background-kurio-main-color-light-gray"
              : ""
            }`}
        >
          {isLoading && <div className="has-text-centered">Loading</div>}
          {!explanationState && (
            <>
              <div
                className={`${isLoading ? "is-hidden" : ""
                  } question-container is-flex is-flex-direction-column is-align-items-center`}
                style={{
                  padding: "1.5rem 2rem",
                  gap: "1.5rem",
                }}
              >
                {
                  //render questionTexts if its array has length greater than 0
                  questionTexts !== null &&
                  questionTexts !== undefined &&
                  questionTexts.length > 0 && (
                    <div className="content">
                      <div>{questionTexts}</div>
                    </div>
                  )
                }
                {checkImage(questionChoices[0]) ? (
                  <>
                    <McqImageWrap />
                  </>
                ) : (
                  <>
                    {questionChoices.length === 4 &&
                      (widthButton + 1.5) * 4 + 4 > maxWidth ? (
                      <div>
                        <div className="columns is-centered p-0 m-0">
                          {MapChoices[0]}
                          {MapChoices[1]}
                        </div>
                        <div className="columns is-centered p-0 m-0">
                          {MapChoices[2]}
                          {MapChoices[3]}
                        </div>
                      </div>
                    ) : // if questionChoices has length 5, render the choices in a 3x2 grid
                      questionChoices.length === 5 ? (
                        <>
                          {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                            <div>
                              <div className="columns is-centered p-0 m-0">
                                {MapChoices[0]}
                                {MapChoices[1]}
                              </div>
                              <div className="columns is-centered p-0 m-0">
                                {MapChoices[2]}
                                {MapChoices[3]}
                              </div>
                              <div className="columns is-centered p-0 m-0">
                                {MapChoices[4]}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="columns is-centered p-0 m-0">
                                {MapChoices[0]}
                                {MapChoices[1]}
                                {MapChoices[2]}
                              </div>
                              <div className="columns is-centered p-0 m-0">
                                {MapChoices[3]}
                                {MapChoices[4]}
                              </div>
                            </div>
                          )}
                        </>
                      ) : // if questionChoices has length 6, render the choices in a 3x3 grid
                        questionChoices.length === 6 ? (
                          <>
                            {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                              <div>
                                <div className="columns is-centered p-0 m-0">
                                  {MapChoices[0]}
                                  {MapChoices[1]}
                                </div>
                                <div className="columns is-centered p-0 m-0">
                                  {MapChoices[2]}
                                  {MapChoices[3]}
                                </div>
                                <div className="columns is-centered p-0 m-0">
                                  {MapChoices[4]}
                                  {MapChoices[5]}
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="columns is-centered p-0 m-0">
                                  {MapChoices[0]}
                                  {MapChoices[1]}
                                  {MapChoices[2]}
                                </div>
                                <div className="columns is-centered p-0 m-0">
                                  {MapChoices[3]}
                                  {MapChoices[4]}
                                  {MapChoices[5]}
                                </div>
                              </div>
                            )}
                          </>
                        ) : questionChoices.length === 3 ? (
                          <>
                            {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                              <div>
                                <div className="columns is-centered p-0 m-0">
                                  {MapChoices[0]}
                                  {MapChoices[1]}
                                </div>
                                <div className="columns is-centered p-0 m-0">
                                  {MapChoices[2]}
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="columns is-centered p-0 m-0">
                                  {MapChoices[0]}
                                  {MapChoices[1]}
                                  {MapChoices[2]}
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="columns is-multiline is-centered p-0 m-0">
                            {MapChoices}
                          </div>
                        )}
                  </>
                )}

                {question.showSolution && allCorrect === true && (
                  <div>
                    <TagCheckMultipleResponse check={true} />
                  </div>
                )}
                {question.showSolution && allCorrect === false && (
                  <div>
                    <TagCheckMultipleResponse check={false} />
                  </div>
                )}
              </div>

              {(question.showSolution || isPreview) &&
                localStorage.getItem("solutionInfo") !== null ? (
                JSON.parse(localStorage.getItem("solutionInfo"))
                  ?.takeExamIdLP !== null &&
                JSON.parse(localStorage.getItem("solutionInfo"))
                  ?.solutionsViewable === true && (
                  <>
                    <div
                      className="is-flex is-flex-direction-column is-justify-content-center"
                      style={{
                        gap: "2rem",
                      }}
                    >
                      <ImageFromUrl
                        style={{ padding: "0rem 2rem" }}
                        objectId="admin/frontend-images/exam/Vector 2.png"
                      />
                    </div>

                    <p
                      className="has-text-kurio-main-color-black"
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontWeight: 700,
                        textTransform: "uppercase",
                        padding: "1rem 0rem 0rem",
                      }}
                    >
                      Đáp án
                    </p>

                    <div
                      className={`${isLoading ? "is-hidden" : ""
                        } question-container is-flex is-flex-direction-column is-align-items-center`}
                      style={{
                        padding: "1.5rem 2rem",
                        gap: "1.5rem",
                      }}
                    >
                      {checkImage(questionChoices[0]) ? (
                        <>
                          <McqImageSolutionWrap />
                        </>
                      ) : (
                        <>
                          {questionChoices.length === 4 &&
                            (widthButton + 1.5) * 4 + 4 > maxWidth ? (
                            <div>
                              <div className="columns is-centered p-0 m-0">
                                {MapChoicesShowSolution[0]}
                                {MapChoicesShowSolution[1]}
                              </div>
                              <div className="columns is-centered p-0 m-0">
                                {MapChoicesShowSolution[2]}
                                {MapChoicesShowSolution[3]}
                              </div>
                            </div>
                          ) : // if questionChoices has length 5, render the choices in a 3x2 grid
                            questionChoices.length === 5 ? (
                              <>
                                {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                                  <div>
                                    <div className="columns is-centered p-0 m-0">
                                      {MapChoicesShowSolution[0]}
                                      {MapChoicesShowSolution[1]}
                                    </div>
                                    <div className="columns is-centered p-0 m-0">
                                      {MapChoicesShowSolution[2]}
                                      {MapChoicesShowSolution[3]}
                                    </div>
                                    <div className="columns is-centered p-0 m-0">
                                      {MapChoicesShowSolution[4]}
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <div className="columns is-centered p-0 m-0">
                                      {MapChoicesShowSolution[0]}
                                      {MapChoicesShowSolution[1]}
                                      {MapChoicesShowSolution[2]}
                                    </div>
                                    <div className="columns is-centered p-0 m-0">
                                      {MapChoicesShowSolution[3]}
                                      {MapChoicesShowSolution[4]}
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : // if questionChoices has length 6, render the choices in a 3x3 grid
                              questionChoices.length === 6 ? (
                                <>
                                  {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                                    <div>
                                      <div className="columns is-centered p-0 m-0">
                                        {MapChoicesShowSolution[0]}
                                        {MapChoicesShowSolution[1]}
                                      </div>
                                      <div className="columns is-centered p-0 m-0">
                                        {MapChoicesShowSolution[2]}
                                        {MapChoicesShowSolution[3]}
                                      </div>
                                      <div className="columns is-centered p-0 m-0">
                                        {MapChoicesShowSolution[4]}
                                        {MapChoicesShowSolution[5]}
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="columns is-centered p-0 m-0">
                                        {MapChoicesShowSolution[0]}
                                        {MapChoicesShowSolution[1]}
                                        {MapChoicesShowSolution[2]}
                                      </div>
                                      <div className="columns is-centered p-0 m-0">
                                        {MapChoicesShowSolution[3]}
                                        {MapChoicesShowSolution[4]}
                                        {MapChoicesShowSolution[5]}
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : questionChoices.length === 3 ? (
                                <>
                                  {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                                    <div>
                                      <div className="columns is-centered p-0 m-0">
                                        {MapChoicesShowSolution[0]}
                                        {MapChoicesShowSolution[1]}
                                      </div>
                                      <div className="columns is-centered p-0 m-0">
                                        {MapChoicesShowSolution[2]}
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="columns is-centered p-0 m-0">
                                        {MapChoicesShowSolution[0]}
                                        {MapChoicesShowSolution[1]}
                                        {MapChoicesShowSolution[2]}
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <div className="columns is-multiline is-centered p-0 m-0">
                                  {MapChoicesShowSolution}
                                </div>
                              )}
                        </>
                      )}

                      {question.showSolution && (
                        <div>
                          <TagCheckMultipleResponse check={true} />
                        </div>
                      )}
                    </div>
                  </>
                )
              ) : (
                <>
                  {(question.showSolution || isPreview) && (
                    <>
                      <div
                        className="is-flex is-flex-direction-column is-justify-content-center"
                        style={{
                          gap: "2rem",
                        }}
                      >
                        <ImageFromUrl
                          style={{ padding: "0rem 2rem" }}
                          objectId="admin/frontend-images/exam/Vector 2.png"
                        />
                      </div>

                      <p
                        className="has-text-kurio-main-color-black"
                        style={{
                          width: "100%",
                          textAlign: "center",
                          fontWeight: 700,
                          textTransform: "uppercase",
                          padding: "1rem 0rem 0rem",
                        }}
                      >
                        Đáp án
                      </p>

                      <div
                        className={`${isLoading ? "is-hidden" : ""
                          } question-container is-flex is-flex-direction-column is-align-items-center`}
                        style={{
                          padding: "1.5rem 2rem",
                          gap: "1.5rem",
                        }}
                      >
                        {checkImage(questionChoices[0]) ? (
                          <>
                            <McqImageSolutionWrap />
                          </>
                        ) : (
                          <>
                            {questionChoices.length === 4 &&
                              (widthButton + 1.5) * 4 + 4 > maxWidth ? (
                              <div>
                                <div className="columns is-centered p-0 m-0">
                                  {MapChoicesShowSolution[0]}
                                  {MapChoicesShowSolution[1]}
                                </div>
                                <div className="columns is-centered p-0 m-0">
                                  {MapChoicesShowSolution[2]}
                                  {MapChoicesShowSolution[3]}
                                </div>
                              </div>
                            ) : // if questionChoices has length 5, render the choices in a 3x2 grid
                              questionChoices.length === 5 ? (
                                <>
                                  {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                                    <div>
                                      <div className="columns is-centered p-0 m-0">
                                        {MapChoicesShowSolution[0]}
                                        {MapChoicesShowSolution[1]}
                                      </div>
                                      <div className="columns is-centered p-0 m-0">
                                        {MapChoicesShowSolution[2]}
                                        {MapChoicesShowSolution[3]}
                                      </div>
                                      <div className="columns is-centered p-0 m-0">
                                        {MapChoicesShowSolution[4]}
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="columns is-centered p-0 m-0">
                                        {MapChoicesShowSolution[0]}
                                        {MapChoicesShowSolution[1]}
                                        {MapChoicesShowSolution[2]}
                                      </div>
                                      <div className="columns is-centered p-0 m-0">
                                        {MapChoicesShowSolution[3]}
                                        {MapChoicesShowSolution[4]}
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : // if questionChoices has length 6, render the choices in a 3x3 grid
                                questionChoices.length === 6 ? (
                                  <>
                                    {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                                      <div>
                                        <div className="columns is-centered p-0 m-0">
                                          {MapChoicesShowSolution[0]}
                                          {MapChoicesShowSolution[1]}
                                        </div>
                                        <div className="columns is-centered p-0 m-0">
                                          {MapChoicesShowSolution[2]}
                                          {MapChoicesShowSolution[3]}
                                        </div>
                                        <div className="columns is-centered p-0 m-0">
                                          {MapChoicesShowSolution[4]}
                                          {MapChoicesShowSolution[5]}
                                        </div>
                                      </div>
                                    ) : (
                                      <div>
                                        <div className="columns is-centered p-0 m-0">
                                          {MapChoicesShowSolution[0]}
                                          {MapChoicesShowSolution[1]}
                                          {MapChoicesShowSolution[2]}
                                        </div>
                                        <div className="columns is-centered p-0 m-0">
                                          {MapChoicesShowSolution[3]}
                                          {MapChoicesShowSolution[4]}
                                          {MapChoicesShowSolution[5]}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ) : questionChoices.length === 3 ? (
                                  <>
                                    {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                                      <div>
                                        <div className="columns is-centered p-0 m-0">
                                          {MapChoicesShowSolution[0]}
                                          {MapChoicesShowSolution[1]}
                                        </div>
                                        <div className="columns is-centered p-0 m-0">
                                          {MapChoicesShowSolution[2]}
                                        </div>
                                      </div>
                                    ) : (
                                      <div>
                                        <div className="columns is-centered p-0 m-0">
                                          {MapChoicesShowSolution[0]}
                                          {MapChoicesShowSolution[1]}
                                          {MapChoicesShowSolution[2]}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <div className="columns is-multiline is-centered p-0 m-0">
                                    {MapChoicesShowSolution}
                                  </div>
                                )}
                          </>
                        )}

                        {question.showSolution && (
                          <div>
                            <TagCheckMultipleResponse check={true} />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}

          {(question.showSolution || isPreview) && (
            <>
              {(explanation?.images?.length > 0 ||
                explanation?.texts?.length > 0) && (
                  <>
                    <div
                      className="is-flex is-flex-direction-column is-justify-content-center"
                      style={{
                        gap: "2rem",
                      }}
                    >
                      <ImageFromUrl
                        style={{ padding: "0rem 2rem" }}
                        objectId="admin/frontend-images/exam/Vector 2.png"
                      />
                    </div>

                    <p
                      className="has-text-kurio-main-color-black"
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontWeight: 700,
                        textTransform: "uppercase",
                        padding: "1rem 0rem 0rem",
                      }}
                    >
                      Giải thích
                    </p>

                    <div
                      className="is-flex is-flex-direction-column is-align-items-center"
                      style={{
                        gap: "1.5rem",
                        fontSize: "1.25rem",
                        padding: "1rem 3rem",
                        width: "100%",
                      }}
                    >
                      {/* Next and "Xem giai thich" button appears when checkingState is true and selected answer is correct */}
                      {(question.showSolution || isPreview) &&
                        explanationState === false && (
                          <>
                            {((explanationTexts !== null &&
                              explanationTexts !== undefined &&
                              explanationTexts?.length > 0) ||
                              (explanationImages !== null &&
                                explanationImages !== undefined &&
                                explanationImages?.length > 0)) &&
                              explanation.texts?.length >=
                              explanation.images?.length && (
                                <>
                                  {explanation.texts.map((item, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        width: "100%",
                                        gap: "1.5rem", 
                                      }}
                                      className="is-flex has-text-justified is-flex-direction-column has-text-kurio-main-color-black"
                                    >
                                      {explanationTexts !== null &&
                                        explanationTexts !== undefined &&
                                        item?.length > 0 && (
                                          <div key={item}>
                                            {explanationTexts[index]}
                                          </div>
                                        )}
                                      {/* only appears when explanationImages has length greater than 0 */}
                                      {explanationImages !== null &&
                                        explanationImages !== undefined &&
                                        explanationImages[index]?.length > 0 && (
                                          <div
                                            className="column is-flex is-justify-content-center is-align-items-center is-narrow is-centered m-0 p-0"
                                            style={{
                                              width: "100%",
                                            }}
                                          >
                                            <ImageFromUrl
                                              objectId={explanationImages[index]}
                                            />
                                          </div>
                                        )}
                                    </div>
                                  ))}
                                </>
                              )}
                            {((explanationTexts !== null &&
                              explanationTexts !== undefined &&
                              explanationTexts?.length > 0) ||
                              (explanationImages !== null &&
                                explanationImages !== undefined &&
                                explanationImages?.length > 0)) &&
                              explanation.texts?.length <
                              explanation.images?.length && (
                                <>
                                  {explanationImages.map((item, index) => (
                                    <div
                                      key={item}
                                      className="is-flex has-text-justified is-flex-direction-column has-text-kurio-main-color-black"
                                      style={{ gap: "1.5rem", width: "100%" }}
                                    >
                                      {explanationTexts !== null &&
                                        explanationTexts !== undefined &&
                                        explanation.texts[index]?.length > 0 && (
                                          <div key={index}>
                                            {explanationTexts[index]}
                                          </div>
                                        )}
                                      {/* only appears when explanationImages has length greater than 0 */}
                                      {explanationImages !== null &&
                                        explanationImages !== undefined &&
                                        item?.length > 0 && (
                                          <div
                                            className="column is-flex is-justify-content-center is-align-items-center is-narrow is-centered m-0 p-0"
                                            style={{
                                              width: "100%",
                                            }}
                                          >
                                            <ImageFromUrl
                                              objectId={explanationImages[index]}
                                            />
                                          </div>
                                        )}
                                    </div>
                                  ))}
                                </>
                              )}
                          </>
                        )}
                    </div>
                  </>
                )}
            </>
          )}
        </BackgroundImageFromUrl>
        {question && (
          <div
            style={nextButtonStyle}
            className="is-flex is-flex-direction-row is-justify-content-space-between"
          >
            <PrimaryButtonQuestion
              onClick={showPreviousQuestion}
              // style={nextButtonStyle}
              disabled={questionIndex === 0}
            >
              {ConstantStrings.PREVIOUS_QUESTION}
            </PrimaryButtonQuestion>

            {questionIndex !== questions.length - 1 &&
              (selected?.length > 0 ? (
                <PrimaryButtonQuestion onClick={showNextQuestion}>
                  {ConstantStrings.NEXT_QUESTION}
                </PrimaryButtonQuestion>
              ) : (
                <PrimaryButtonQuestion onClick={showNextQuestion}>
                  {ConstantStrings.SKIP}
                </PrimaryButtonQuestion>
              ))}
          </div>
        )}
      </div>
    </>
  );
}

export default MultipleResponse;
