import instance from "./instance";
const url = "/utilities/api/v1/files";
const urlUpload = "/utilities/api/v1/user/files";

const userUrl = "/auth/v1/user";

export const getUrlImage = (key, token) => {
  // checkAndCallDailyLoginAPI()
  return instance.get(`${url}/get-url?key=${JSON.stringify(key)}`);
};

export const updateUrlImage = (data, token) => {
    return instance.post(`${userUrl}/account/update-profile-image`, data)
}

export const uploadImages = (formData) => {
  return instance.post(`${urlUpload}/upload`, formData) 
}
