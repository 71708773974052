export const countTotalSlide = (sections) => {
    var totalSlide = 0;
    sections.map((item) => {
        totalSlide += item.slides.length;
        return totalSlide;
    });
    return totalSlide;
};
export const countCurrentSLide = (sections, sectionIndex, slideIndex) => {
    var currentSlide = 0;
    loop1:
    for (let i = 0; i < sections.length; i++) {
        for (let j = 0; j < sections[i].slides.length; j++) {
            if (i === sectionIndex && j === slideIndex) {
                currentSlide++;
                break loop1;
            } else {
                currentSlide++;
            }
        }
    }
    return currentSlide
};
export const findSlideBySlideId = (sections, slideId) =>{
  var check = sections.map((section)=> section.slides).flat().some((item)=> item._id === slideId)
  return check
}
