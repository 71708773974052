import React, { useState } from "react";
import BackgroundImageFromUrl from "../../parseImage/BackgroundImageFromUrl";

const TertiaryButtonQuestion = ({
  disabled,
  prefix,
  suffix,
  rotate,
  onClick,
  children,
  style,
  buttonSkipRef
}) => {
  const [defaultState, setDefaultState] = useState(disabled ? false : true);
  const [hoveredState, setHoveredState] = useState(false);
  const [clickedState, setClickedState] = useState(false);
  const audio = new Audio("/sounds/click.mp3");

  var buttonStyle = {
    // width: "6.75rem",
    height: "1.0625rem",
    fontSize: "1.125rem",
    border: "none",
    boxShadow: "none",
    backgroundColor: "transparent",
  };
  var prefixStyle = {
    minWidth: "1rem",
    height: "1rem",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  };
  var suffixStyle = {
    minWidth: "1rem",
    height: "1rem",
    rotate: rotate ? "-90deg" : "",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  };
  var objectIdPrefix = `${prefix}.svg`;
  var objectIdSuffix = `${suffix}.svg`;
  if (hoveredState) {
    objectIdPrefix = `${prefix}-hovered.svg`;
    objectIdSuffix = `${suffix}-hovered.svg`;
  }
  if (disabled) {
    objectIdPrefix = `${prefix}-disabled.svg`;
    objectIdSuffix = `${suffix}-disabled.svg`;
  }
  if (clickedState) {
    objectIdPrefix = `${prefix}-clicked.svg`;
    objectIdSuffix = `${suffix}-clicked.svg`;
  }
  const handleMouseEnter = () => {
    if (!clickedState && !disabled) {
      setDefaultState(false);
      setHoveredState(true);
    }
  };
  const handleMouseLeave = () => {
    if (!clickedState && !disabled) {
      setDefaultState(true);
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    if (!disabled) {
      audio.play();
      setClickedState(!clickedState);
      setHoveredState(false);
      setDefaultState(clickedState);
      if (onClick) {
        onClick();
      }
    }
  };

  return (
    <div
      ref={buttonSkipRef}
      className="main is-flex is-flex-direction-row is-align-items-center p-0 is-clickable"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        maxWidth: style?.maxWidth ? "" : "6.75rem",
        gap: "0.5rem",
      }}
    >
      <button
        className={`${defaultState
          ? "has-text-secondary-button-clicked-color"
          : hoveredState
            ? "has-text-primary-button-hovered-color is-underlined"
            : clickedState
              ? "has-text-secondary-button-clicked-color is-underlined"
              : disabled
                ? "has-text-button-disabled-text-color"
                : ""
          } button main-color has-text-centered has-text-weight-medium p-0 is-flex is-flex-direction-row is-align-items-center`}
        style={{ ...buttonStyle, gap: "0.75rem" }}
        onClick={handleClick}
      >
        {prefix && (
          <BackgroundImageFromUrl
            objectId={objectIdPrefix}
            style={prefixStyle}
          ></BackgroundImageFromUrl>
        )}
        {children}
        {suffix && (
          <BackgroundImageFromUrl
            objectId={objectIdSuffix}
            style={suffixStyle}
          ></BackgroundImageFromUrl>
        )}
      </button>
    </div>
  );
};

export default TertiaryButtonQuestion;
