import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/700.css";
import { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import ComponentList from "./components/ComponentList";
import LoadingBeforeRedirect from "./components/LoadingBeforeRedirect";
import BackgroundImageFromUrl from "./components/parseImage/BackgroundImageFromUrl";
import ConstantStrings from "./constants/ConstantStrings";
import { default as HomePage } from "./layout-components/HomePage";
import AchievementMainWrapper from "./layout-components/achievement/AchievementMainWrapper";
import ActivitiesMainWrapper from "./layout-components/activities/ActivitiesMainWrapper";
import Calibration from "./layout-components/auth/Calibration";
import ChangePassword from "./layout-components/auth/ChangePassword";
import LoadingResetPassword from "./layout-components/auth/LoadingResetPassword";
import ResetPassword from "./layout-components/auth/ResetPassword";
import ValidateEmailRegister from "./layout-components/auth/ValidateEmailRegister";
import ValidatePhone from "./layout-components/auth/ValidatePhone";
import Signin from "./layout-components/auth/signin/Signin";
import Register from "./layout-components/auth/signup/Register";
import ChallengeIKMC from "./layout-components/challenge-ikmc/ChallengeIKMC";
import ChangePasswordUserInfo from "./layout-components/change-password/ChangePasswordUserInfor";
import DeleteAccount from "./layout-components/delete-account/DeleteAccount";
import Error from "./layout-components/error/Error";
import ErrorServerTime from "./layout-components/error/ErrorServerTime";
import ExamPreparation from "./layout-components/exam-preparation/ExamPreparation";
import ExamsQuestionVault from "./layout-components/exams-question-vault/ExamsQuestionVault";
import ExerciseLayout from "./layout-components/filter-question/ExerciseLayout";
import EloCalibration from "./layout-components/flutter/EloCalibration";
import Flutter from "./layout-components/flutter/Flutter";
import Numberle from "./layout-components/games/numberle/Numberle";
import ListUnitMainWrapper from "./layout-components/grade/ListUnitMainWrapper";
import { default as IkmcExamQuestionsMainWrapper } from "./layout-components/ikmc-exam-questions/IkmcExamQuestionsMainWrapper";
import LandingPage from "./layout-components/landing-page/LandingPage";
import PaymentMethods from "./layout-components/landing-page/PaymentMethod";
import PaymentPolicy from "./layout-components/landing-page/PaymentPolicy";
import PrivacyPolicy from "./layout-components/landing-page/PrivacyPolicy";
import RegisterMobilePage from "./layout-components/landing-page/RegisterMobilePage";
import ReturnPolicy from "./layout-components/landing-page/ReturnPolicy";
import { default as LessonMainWrapper } from "./layout-components/lesson/LessonMainWrapper";
import MockLessonMainWrapper from "./layout-components/lesson/MockLessonMainWrapper";
import MenuMainWrapper from "./layout-components/menu-bar/MenuMainWrapper";
import AndroidApp from "./layout-components/mobile-app/AndroidApp";
import IOSApp from "./layout-components/mobile-app/IOSApp";
import MonthlyExamQuestionsMainWrapper from "./layout-components/monthly-exam-questions/MonthlyExamQuestionsMainWrapper";
import MonthlyExamsMainWrapper from "./layout-components/monthly-exams/MonthlyExamsMainWrapper";
import PracticeElectiveMainWrapper from "./layout-components/practice-elective/PracticeElectiveMainWrapper";
import PracticeIKMC from "./layout-components/practice-ikmc/PracticeIKMC";
import PracticeIKMCLayout from "./layout-components/practice-ikmc/PracticeIKMCLayout";
import PreviewExam from "./layout-components/preview/PreviewExam";
import PreviewExerciseLesson from "./layout-components/preview/PreviewExerciseLesson";
import { default as PreviewLesson } from "./layout-components/preview/PreviewLesson";
import PreviewQuestion from "./layout-components/preview/PreviewQuestion";
import PreviewQuiz from "./layout-components/preview/PreviewQuiz";
import ProfileMainWrapper from "./layout-components/profile/ProfileMainWrapper";
import StaticGames from "./layout-components/static-games/StaticGames";
import UnitQuizMainWrapper from "./layout-components/unit-quiz/UnitQuizMainWrapper";
import ListLessonMainWrapper from "./layout-components/unit/ListLessonMainWrapper";
import PaymentSuccess from "./layout-components/upgrade-account/PaymentSuccess";
import UpgradeAccount from "./layout-components/upgrade-account/UpgradeAccount";
import UserInformationMainWrapper from "./layout-components/user-information/UserInformationMainWrapper";
import Voucher from "./layout-components/voucher/Voucher";
import PrivateRouter from "./templates/helper/PrivateRouter";
import { default as ReCaptChaV2 } from "./templates/helper/ReCaptCha";

function App() {
  // const token = cookies.get("signinUser") || cookies.get("register");
  
  const [widthDiv, setWidthDiv] = useState(
    window.innerWidth / ConstantStrings.BASE_REM
  );
  const [image, setImage] = useState(null);
  const [showLayout, setShowLayout] = useState(false);
  const location = useLocation();

  ReactGA.initialize(ConstantStrings.GA_MEASUREMENT_ID);
  const initPixel = () => {
    const advancedMatching = {};
    const options = {
      autoConfig: true,
      debug: true,
    };

    ReactPixel.init(
      process.env.REACT_APP_FACEBOOK_PIXEL_ID,
      advancedMatching,
      options
    );
    ReactPixel.pageView();
  };

  const divRef = useRef(null);
  var backgroundStyle = {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    // backgroundImage: "url('/images/hub/background-wide-screen.svg')",
    backgroundAttachment: "fixed",
  };
  const [pathname, setPathName] = useState("/");
  const handleChangePathName = (newPathName) => {
    setPathName(newPathName);
  };
  var objectId = "";
  if (window.location.pathname === "/validate-phone") {
    objectId = "admin/frontend-images/auth/bg-desktop-signup.svg";
  } else if (
    pathname === "/" ||
    window.location.pathname === "/request-delete-account"
  ) {
    if (widthDiv < 120) {
      backgroundStyle = {
        ...backgroundStyle,
      };
      objectId = "admin/frontend-images/hub/background-hub-1.svg";
    } else {
      objectId = "admin/frontend-images/hub/background-hub-2.svg";
    }
  } else if (pathname === "/list-unit") {
    objectId = "admin/frontend-images/hub/background-list-unit.svg";
  } else if (pathname === "/upgrade" || pathname === "/payment-success") {
    objectId = "admin/frontend-images/hub/background-upgrade.svg";
  } else if (pathname === "/exercise") {
    if (showLayout) {
      objectId =
        "admin/frontend-images/exercise/background-layout-exercise.svg";
    } else {
      objectId = "admin/frontend-images/hub/background-exercise-update.svg";
    }
  } else if (
    pathname === "/account" ||
    pathname === "/achievement" ||
    pathname === "/profile"
  ) {
    objectId = "admin/frontend-images/hub/background-account.jpg";
  } else if (pathname === "/monthly-exams") {
    objectId = "admin/frontend-images/hub/background-kurio-full-size.svg";
  } else if (
    window.location.pathname === "/exams-vault" ||
    pathname === "/practice-ikmc" ||
    window.location.pathname === "/challenge-ikmc"
  ) {
    objectId = "admin/frontend-images/hub/background-exam-vault.jpg";
  } else if (pathname === "/activities") {
    objectId = "admin/frontend-images/hub/background-activities.png";
  } else if (window.location.pathname.includes("preview")) {
    objectId = "admin/frontend-images/exercise/bg-ket-thuc-bai.svg";
  } else if (pathname === "/voucher") {
    objectId = "admin/frontend-images/hub/background-voucher.svg";
  } else if (pathname === "/kho-tro-choi") {
    objectId = "admin/frontend-images/hub/background-game.svg";
  }
  useEffect(() => {
    const cookies = new Cookies();
    if (process.env.REACT_APP_ENV === "production") {
      initPixel();
    }
    const setWidth = () => {
      setWidthDiv(divRef?.current.clientWidth / ConstantStrings.BASE_REM);
    };
    if (divRef?.current.clientWidth / ConstantStrings.BASE_REM !== widthDiv) {
      setWidthDiv(divRef?.current.clientWidth / ConstantStrings.BASE_REM);
    }
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Câu lạc bộ toán Kurio",
    });
    // window.onbeforeunload = () => {
    // };
    if (localStorage.getItem("clearAll")) {
      const listCookies = cookies.getAll();
      Object.keys(listCookies).forEach((cookie) => {
        cookies.remove(cookie);
      });
      const arrayPath = [
        "/account",
        "/profile",
        "/upgrade",
        "/list-unit",
        "/exercise",
        "/activities",
        "/achievement",
        "/",
      ];
      localStorage.clear();
      arrayPath
        .forEach((path) => {
          if (cookies.get("signinUser", { path: path })) {
            cookies.remove("signinUser", { path: path });
          }
        })
        ?.then(() => {
          window.location.href =
            process.env.REACT_APP_ENV === "development"
              ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
              : process.env.REACT_APP_LANDING_PAGE_URL;
        })
        .catch((error) => {
          console.error("Error removing cookie:", error);
        });
      const check = arrayPath.every((path) =>
        cookies.get("signinUser", { path: path })
      );
      if (!check) {
        window.location.href =
          process.env.REACT_APP_ENV === "development"
            ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
            : process.env.REACT_APP_LANDING_PAGE_URL;
      }
    }
    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [location.pathname, widthDiv]);
  // console.log(cookie);

  return (
    <div
      className="App notranslate" //class notranslate disables google translate as of Oct 19 2023
      ref={divRef}
      style={{
        ...backgroundStyle,
        minHeight: "100vh",
      }}
    >
      <BackgroundImageFromUrl
        objectId={objectId}
        style={{ ...backgroundStyle, minHeight: "100vh" }}
      >
        {(widthDiv >= 64 ||
          ConstantStrings.ARRAY_LANDING_PAGE_PATHS.includes(
            window.location.pathname
          )) && (
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRouter page={"PRIVATE"}>
                  <MenuMainWrapper
                    handleChangePathName={(pathname) =>
                      handleChangePathName(pathname)
                    }
                    pathname={pathname}
                    image={image}
                  />
                </PrivateRouter>
              }
            >
              <Route index element={<HomePage />} />
              <Route
                path="list-unit"
                element={
                  <PrivateRouter page={"BLOCK_ROUTER"}>
                    <ListUnitMainWrapper />
                  </PrivateRouter>
                }
              />
              <Route path="achievement" element={<AchievementMainWrapper />} />
              <Route
                path="activities"
                element={
                  <PrivateRouter page={"BLOCK_ROUTER"}>
                    <ActivitiesMainWrapper />
                  </PrivateRouter>
                }
              />
              <Route path="profile" element={<ProfileMainWrapper />} />
              <Route path="voucher" element={<Voucher />} />
              <Route
                path="change-password"
                element={<ChangePasswordUserInfo />}
              />
              <Route
                path="exercise"
                element={
                  <DndProvider backend={HTML5Backend}>
                    <PrivateRouter page={"BLOCK_ROUTER"}>
                      <ExerciseLayout setShowLayout={setShowLayout} />
                    </PrivateRouter>
                  </DndProvider>
                }
              />
              <Route index element={<ActivitiesMainWrapper />} />
              <Route
                path="monthly-exams"
                element={<MonthlyExamsMainWrapper />}
              />
              <Route path="practice-ikmc" element={<PracticeIKMC />} />
              <Route
                path="upgrade"
                element={
                  <PrivateRouter page={"REQUIRE_LOGIN"}>
                    <UpgradeAccount />
                  </PrivateRouter>
                }
              />
              <Route path="payment-success" element={<PaymentSuccess />} />
              <Route
                path="account"
                element={<UserInformationMainWrapper setImage={setImage} />}
              />
            </Route>
            <Route
              path="exams-vault"
              element={
                <PracticeIKMCLayout>
                  <ExamsQuestionVault />
                </PracticeIKMCLayout>
              }
            />
            <Route path="ios" element={<IOSApp />} />
            <Route path="android" element={<AndroidApp />} />
            <Route
              path="challenge-ikmc"
              element={
                <PracticeIKMCLayout>
                  <ChallengeIKMC />
                </PracticeIKMCLayout>
              }
            />
            <Route
              path="roadmap-exam"
              element={
                <DndProvider backend={HTML5Backend}>
                  <ExamPreparation />
                </DndProvider>
              }
            />
            <Route path="kho-tro-choi">
              <Route index element={<StaticGames />} />
              <Route path="day-so-bi-an" element={<Numberle />} />
            </Route>

            <Route path="request-delete-account" element={<DeleteAccount />} />
            <Route path="error" element={<Error />} />
            <Route path="error-server-time" element={<ErrorServerTime />} />
            <Route path="calibration" element={<Calibration />} />
            <Route
              path="signin"
              element={
                <PrivateRouter page={"LOGIN"}>
                  <Signin />
                </PrivateRouter>
              }
            />
            <Route path="about-us" element={<LandingPage />} />
            <Route path="payment-methods" element={<PaymentMethods />} />
            <Route path="payment-policy" element={<PaymentPolicy />} />
            <Route path="return-policy" element={<ReturnPolicy />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="register-mobile" element={<RegisterMobilePage />} />
            <Route path="policy" element={<PaymentPolicy />} />

            <Route
              path="register"
              element={
                <PrivateRouter page={"LOGIN"}>
                  <Register />
                </PrivateRouter>
              }
            />
            <Route path="validate-email" element={<ValidateEmailRegister />} />
            <Route
              path="validate-phone"
              element={
                <PrivateRouter page={"VALIDATE_PHONE"}>
                  <ValidatePhone />
                </PrivateRouter>
              }
            />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="flutter" element={<Flutter />} />
            <Route path="elocalibration" element={<EloCalibration />} />
            <Route
              path="password-service/:requestId/:otp"
              element={<LoadingResetPassword />}
            />
            <Route path="reset-password-email" element={<ChangePassword />} />

            <Route
              path="lesson"
              element={
                <DndProvider backend={HTML5Backend}>
                  <PrivateRouter page={"BLOCK_ROUTER"}>
                    <LessonMainWrapper />
                  </PrivateRouter>
                </DndProvider>
              }
            />
            <Route
              path="list-lesson"
              element={
                <PrivateRouter page={"BLOCK_ROUTER"}>
                  <ListLessonMainWrapper />
                </PrivateRouter>
              }
            />
            <Route
              path="mock-data"
              element={
                <DndProvider backend={HTML5Backend}>
                  <MockLessonMainWrapper />
                </DndProvider>
              }
            />
            <Route
              path="practice-elective"
              element={
                <DndProvider backend={HTML5Backend}>
                  <PracticeElectiveMainWrapper />
                </DndProvider>
              }
            />
            <Route path="component" element={<ComponentList />} />
            <Route path="recaptcha" element={<ReCaptChaV2 />} />
            <Route
              path="unit-quiz"
              element={
                <DndProvider backend={HTML5Backend}>
                  <UnitQuizMainWrapper />
                </DndProvider>
              }
            />

            <Route
              path="ikmc-exam-questions"
              element={
                <PrivateRouter page={"IKMC-EXAM-QUESTION"}>
                  <DndProvider backend={HTML5Backend}>
                    <IkmcExamQuestionsMainWrapper />
                  </DndProvider>
                </PrivateRouter>
              }
            />

            <Route
              path="monthly-exam-questions"
              element={
                <PrivateRouter page={"MONTHLY-EXAM-QUESTION"}>
                  <DndProvider backend={HTML5Backend}>
                    <MonthlyExamQuestionsMainWrapper />
                  </DndProvider>
                </PrivateRouter>
              }
            />

            <Route
              path="preview-question"
              element={
                <PrivateRouter page={"ADMIN"}>
                  {" "}
                  <DndProvider backend={HTML5Backend}>
                    <PreviewQuestion />
                  </DndProvider>
                </PrivateRouter>
              }
            />
            <Route
              path="preview-quiz"
              element={
                <PrivateRouter page={"ADMIN"}>
                  {" "}
                  <DndProvider backend={HTML5Backend}>
                    <PreviewQuiz />
                  </DndProvider>
                </PrivateRouter>
              }
            />
            <Route
              path="preview-lesson"
              element={
                <PrivateRouter page={"ADMIN"}>
                  <DndProvider backend={HTML5Backend}>
                    <PreviewLesson />
                  </DndProvider>
                </PrivateRouter>
              }
            />

            <Route
              path="preview-exam"
              element={
                <PrivateRouter page={"ADMIN"}>
                  <DndProvider backend={HTML5Backend}>
                    <PreviewExam />
                  </DndProvider>
                </PrivateRouter>
              }
            />
            <Route
              path="preview-question"
              element={
                <PrivateRouter page={"ADMIN"}>
                  {" "}
                  <DndProvider backend={HTML5Backend}>
                    <PreviewQuestion />
                  </DndProvider>
                </PrivateRouter>
              }
            />
            <Route
              path="preview-quiz"
              element={
                <PrivateRouter page={"ADMIN"}>
                  {" "}
                  <DndProvider backend={HTML5Backend}>
                    <PreviewQuiz />
                  </DndProvider>
                </PrivateRouter>
              }
            />
            <Route
              path="preview-lesson"
              element={
                <PrivateRouter page={"ADMIN"}>
                  <DndProvider backend={HTML5Backend}>
                    <PreviewLesson />
                  </DndProvider>
                </PrivateRouter>
              }
            />
            <Route
              path="preview-exercise"
              element={
                <PrivateRouter page={"ADMIN"}>
                  <DndProvider backend={HTML5Backend}>
                    <PreviewExerciseLesson />
                  </DndProvider>
                </PrivateRouter>
              }
            />
            <Route path="*" element={<Navigate to={"/error"} />} />
          </Routes>
        )}
        {widthDiv < 64 &&
        !ConstantStrings.ARRAY_LANDING_PAGE_PATHS.includes(
          window.location.pathname
        ) ? (
          <LoadingBeforeRedirect />
        ) : (
          <></>
        )}
      </BackgroundImageFromUrl>
    </div>
  );
}

export default App;
