import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import SelectGradeButton from "../../components/button/special-button/SelectGradeButton";
import InputDate from "../../components/input/InputDate";
import InputText from "../../components/input/InputText";
import { getGradeCategory, updateGrade } from "../../config/ListLesson";
import { editUserInfo, getUserInfo } from "../../config/auth";
import ConstantStrings from "../../constants/ConstantStrings";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";

const Calibration = () => {
  const [userInfo, setUserInfo] = useState({
    fullName: "",
    fatherName: "",
    motherName: "",
    birthday: "",
    fatherEmail: "",
    motherEmail: "",
    fatherPhone: "",
    motherPhone: "",
  });
  const [error, setError] = useState({
    fullName: "",
    fatherName: "",
    birthday: "",
    motherName: "",
    fatherEmail: "",
    motherEmail: "",
    fatherPhone: "",
    motherPhone: "",
  });
  const [selectGradeState, setSelectGradeState] = useState(false);
  const [selected, setSelected] = useState("");
  const [grades, setGrades] = useState([]);
  const cookies = new Cookies();
  const handleChange = (value, index, name) => {
    setUserInfo({ ...userInfo, [name]: value });
    setError({
      fullName: "",
      fatherName: "",
      birthday: "",
      motherName: "",
      fatherEmail: "",
      motherEmail: "",
      fatherPhone: "",
      motherPhone: "",
    });
  };
  const navigate = useNavigate();
  const handleSubmit = async () => {
    var check = false;
    const checkArr = Object.keys(userInfo).filter(
      (item) => item === "fullName" || item === "birthday"
    );
    const keyErrorArr = Object.keys(error).filter(
      (item) => item === "fullName" || item === "birthday"
    );
    var objError = { ...error };
    for (let index = 0; index < checkArr.length; index++) {
      if (
        keyErrorArr.includes(checkArr[index]) &&
        (userInfo[checkArr[index]] === "" ||
          userInfo[checkArr[index]].includes("Invalid"))
      ) {
        check = true;
        objError = {
          ...objError,
          [checkArr[index]]: ConstantStrings.DO_NOT_LEAVE_BLANK,
        };
      }
    }
    if (check) {
      setError(objError);
    } else {
      try {
        await editUserInfo(userInfo);
        setSelectGradeState(true);
      } catch (error) {
        console.log("error", error.response.data?.errorMessage);
      }
    }
    if (selectGradeState) {
      try {
        await updateGrade({ gradeId: selected });
        if (localStorage.getItem("registerAnonymousUser")) {
          cookies.set("gradeId", selected);
          navigate("/roadmap-exam");
          localStorage.removeItem("registerAnonymousUser");
        } else {
          navigate("/");
        }
        // var expires = 7 * 24 * 3600;
        // cookies.set("signinUser", token, { maxAge: expires });
      } catch (error) {
        console.log("error", error.response.data?.errorMessage);
      }
    }
  };
  const handleUpdateGrade = (_id) => {
    if (_id === selected) {
      setSelected("");
    } else {
      setSelected(_id);
    }
  };
  useEffect(() => {
    const getListGrades = async () => {
      try {
        const { result } = await getGradeCategory();
        setGrades(result);
      } catch (error) {
        console.log("error", error);
      }
    };
    const getUserInformation = async () => {
      const { result } = await getUserInfo();
      if (
        result?.fullName?.length > 0 &&
        result?.birthday?.length > 0 &&
        result?.gradeId?.length > 0 &&
        result?.gradeName?.length > 0
      ) {
        navigate("/");
      } else {
        if (result?.fullName?.length > 0 && result?.birthday?.length > 0) {
          setSelectGradeState(true);
        }
      }
    };
    if (selectGradeState) {
      getListGrades();
    } else {
      getUserInformation();
    }
  }, [navigate, selectGradeState]);
  return (
    <BackgroundImageFromUrl
      objectId="admin/frontend-images/auth/bg-desktop-signin.svg"
      className=" m-auto is-flex is-flex-direction-column is-justify-content-space-between"
      style={{
        backgroundSize: "cover",
        fontSize: "1.125rem",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        minHeight: "100vh",
      }}
    >
      {!selectGradeState && (
        <div
          className="is-flex is-justify-content-center is-align-items-center is-align-content-center"
          style={{ height: "calc(100vh - 8.3125rem)" }}
        >
          <div
            className="is-flex is-flex-direction-column is-align-items-center is-justify-space-between"
            style={{ gap: "2.5rem" }}
          >
            <div
              className="has-text-kurio-main-color-white has-text-centered is-flex is-flex-direction-column is-flex-gap-4 is-align-items-center"
              // style={{ marginTop: "6.1875rem" }}
            >
              <p
                className="has-text-weight-bold"
                style={{ fontSize: "2.25rem", lineHeight: "normal" }}
              >
                Chào mừng bạn đến với KURIO!
              </p>
              <p style={{ fontSize: "1.25rem" }}>
                Bổ sung thêm một vài thông tin trước khi bắt đầu nhé!
              </p>
            </div>
            <form>
              {/* <input hidden type="submit" /> */}
              <div
                className="is-flex is-flex-direction-column is-align-items-center m-0 p-0 is-multiline"
                style={{ width: "41.5rem", gap: "1.5rem" }}
              >
                <div className="" style={{ width: "20rem", height: "5rem" }}>
                  <InputText
                    label={"Họ & tên Học sinh*"}
                    type="text"
                    name="fullName"
                    value={userInfo.fullName}
                    onChange={(e) => {
                      handleChange(e.target.value, 0, e.target.name);
                    }}
                  />
                  {error.fullName && (
                    <p className="has-text-danger subtitle is-size-6 m-0">
                      {error.fullName}
                    </p>
                  )}
                </div>
                <div className="" style={{ width: "20rem", height: "5rem" }}>
                  <InputDate
                    label={"Nhập ngày sinh*"}
                    type="date"
                    name="birthday"
                    value={userInfo.birthday}
                    onChange={(name, value) => {
                      handleChange(value, 0, name);
                    }}
                  />
                  {error.birthday && (
                    <p className="has-text-danger subtitle is-size-6 m-0">
                      {error.birthday}
                    </p>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {selectGradeState && (
        <div
          className="is-flex is-justify-content-center is-align-items-center is-align-content-center"
          style={{ height: "calc(100vh - 8.3125rem)" }}
        >
          <div
            className="is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between "
            style={{ gap: "2.5rem" }}
          >
            <div
              className="has-text-kurio-main-color-white has-text-centered"
              style={{ marginTop: "6.1875rem" }}
            >
              <p style={{ fontSize: "2.25rem" }}>Chọn lớp</p>
            </div>
            <div
              className="columns m-0 p-0 is-multiline is-centered"
              style={{
                gap: "0.5rem",
                width: grades?.length > 5 ? "48rem" : "35.875rem",
              }}
            >
              {grades &&
                grades.length > 0 &&
                grades
                  .filter((item, index) => index !== 8)
                  // .filter((item, index) => index <= 4)
                  .map((grade, index) => (
                    <div key={index}>
                      <SelectGradeButton
                        onClick={() => handleUpdateGrade(grade._id)}
                        isClick={selected === grade._id}
                      >
                        {grade.name.slice(4)}
                      </SelectGradeButton>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      )}
      <div
        className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"
        style={{ paddingRight: "2.875rem" }}
      >
        <img
          style={{
            width: "21.5rem",
            height: "8.3125rem",
          }}
          src="/images/auth/logo-signin.svg"
          alt="loading"
        />
        <PrimaryButtonPageLevel
          onClick={handleSubmit}
          disabled={
            selectGradeState
              ? selected.length === 0
              : userInfo.fullName.length === 0 ||
                userInfo?.birthday?.length === 0 ||
                userInfo.birthday === null
          }
          style={{ height: "4rem" }}
          isDarkColor={true}
        >
          Tiếp Theo
        </PrimaryButtonPageLevel>
      </div>
    </BackgroundImageFromUrl>
  );
};

export default Calibration;
