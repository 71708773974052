import React, { memo, useEffect, useState } from "react";
import { getGradeCategory } from "../../config/ListLesson";
import "./ChallengeIKMC.style.css";

const SelectLevelIKMC = memo(({ handleSelect, gradeId }) => {
  const [checkAll, setCheckAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [listGrade, setListGrade] = useState([]);
  // const cookies = new Cookies();
  // const token = cookies.get("signinUser");
  const handleCheckAll = () => {
    setCheckAll(!checkAll);
    setSelected(listGrade.map((item) => item._id));
    handleSelect(listGrade.map((item) => item._id));
    if (checkAll) {
      setSelected([]);
      handleSelect([]);
    }
  };
  const handleCheck = (_id) => {
    if (selected.includes(_id)) {
      setSelected(selected.filter((item) => item !== _id));
      setCheckAll(false);
      handleSelect(selected.filter((item) => item !== _id));
    } else {
      if ([...selected, _id].length === 3) {
        setCheckAll(true);
        setSelected(listGrade.map((item) => item._id));
        handleSelect(listGrade.map((item) => item._id));
      } else {
        setCheckAll(false);
        const index = listGrade.findIndex((item) => item._id === _id);
        setSelected([...selected, _id]);
        if (index + 1 < listGrade.length) {
          handleSelect([...selected, _id, listGrade[index + 1]._id]);
        } else {
          handleSelect([...selected, _id]);
        }
      }
    }
  };
  const handleChange = (name, check, value) => {
    if (name && name === "checkAll") {
      if (check) {
        handleSelect(value);
      } else {
        handleSelect([]);
      }
    } else if (check) {
      handleSelect([...selected, value]);
    } else {
      handleSelect(selected.filter((i) => i !== value));
    }
  };
  useEffect(() => {
    const getListGrades = async () => {
      const { result } = await getGradeCategory();
      setListGrade(result);
    };
    if (listGrade.length <= 0) {
      getListGrades();
    }
  }, [listGrade.length]);
  return (
    <div>
      <div
        className="is-flex is-flex-direction-column is-justify-content-center is-align-items-start"
        style={{
          width: "25rem",
          // height: "23.375rem",
          gap: "1rem",
          borderRadius: "0.125rem",
        }}
      >
        {listGrade &&
          listGrade
            .filter((item, index) => index !== 8)
            .map((item, index) => {
              return (
                <button
                  className={`${
                    item.name === "Lớp 2" ||
                    item.name === "Lớp 4" ||
                    item.name === "Lớp 6" ||
                    item.name === "Lớp 8"
                      ? "is-hidden"
                      : ""
                  } is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center btn-button-IKMC`}
                  key={index}
                  onClick={() => {
                    handleCheck(item._id);
                  }}
                  style={
                    checkAll || selected.includes(item._id)
                      ? {
                          color: "#FAFBFC",
                          backgroundColor: "#0A2A66",
                        }
                      : { boxShadow: "0.125rem 0.125rem 0rem 0rem #0A2A66" }
                  }
                >
                  <p
                    className="has-text-weight-bold"
                    style={
                      checkAll || selected.includes(item._id)
                        ? {
                            color: "#fff",
                            fontSize: "1.125rem",
                          }
                        : {
                            color: "#0A2A66",
                            fontSize: "1.125rem",
                          }
                    }
                  >
                    {item.name === "Lớp 1"
                      ? "Level 1 (lớp 1-2)"
                      : item.name === "Lớp 3"
                      ? "Level 2 (lớp 3-4)"
                      : item.name === "Lớp 5"
                      ? "Level 3 (lớp 5-6)"
                      : item.name === "Lớp 7"
                      ? "Level 4 (lớp 7-8)"
                      : ""}
                  </p>
                  <input
                    onClick={() => {
                      handleCheck(item._id);
                    }}
                    onChange={(e) => {
                      handleChange(e.target.name, e.target.checked, item._id);
                    }}
                    checked={
                      checkAll
                        ? checkAll
                        : selected.includes(item._id)
                        ? true
                        : false
                    }
                    type={"checkbox"}
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      backgroundColor: "#FAFBFC",
                      cursor: "pointer",
                    }}
                  />
                </button>
              );
            })}
        <div
          className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center btn-button-IKMC"
          onClick={handleCheckAll}
          style={
            checkAll
              ? {
                  color: "#FAFBFC",
                  backgroundColor: "#0A2A66",
                }
              : { boxShadow: "0.125rem 0.125rem 0rem 0rem #0A2A66" }
          }
        >
          <p
            className="has-text-weight-bold"
            style={
              checkAll
                ? {
                    color: "#fff",
                    fontSize: "1.125rem",
                  }
                : {
                    color: "#0A2A66",
                    fontSize: "1.125rem",
                  }
            }
          >
            Chọn tất cả
          </p>
          <input
            name="checkAll"
            onChange={(e) => {
              handleChange(
                e.target.name,
                e.target.checked,
                listGrade.map((item) => item._id)
              );
            }}
            onClick={handleCheckAll}
            checked={checkAll}
            type={"checkbox"}
            style={{
              width: "1.5rem",
              height: "1.5rem",
              backgroundColor: "#FAFBFC",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    </div>
  );
});

export default SelectLevelIKMC;
