import { getDailyLogin } from "./examsQuestion";
// Hàm kiểm tra và gọi API daily-login

const checkAndCallDailyLoginAPI = () => {
  // Bước 1: Kiểm tra cache lastLoginDate
  const lastLoginDate = localStorage.getItem("lastLoginDate");
  if (lastLoginDate) {
    var systemTime = new Date(); // Lấy giờ hệ thống
    const systemTimeMidnight = systemTime.setHours(0, 0, 0, 0) / 1000; // Lấy giờ 0:00 đêm hôm nay
    // Bước 2: Kiểm tra system time đã qua 12h đêm GMT+7 chưa
    if (lastLoginDate === "null") {
      localStorage.removeItem("lastLoginDate");
      window.location.reload();
    } else if (Number(lastLoginDate) < systemTimeMidnight) {
      // Bước 3: Gọi API daily-login
      localStorage.removeItem("lastLoginDate");
      window.location.reload();
    }
  } else {
    callDailyLoginAPI(new Date());
  }
};

// Hàm gọi API daily-login
const callDailyLoginAPI = async (systemTime) => {
  try {
    if (!window.location.pathname.includes("error")) {
      if (!window.location.pathname.includes("preview")) {
        const { result } = await getDailyLogin();

        const serverTimeUnix = Math.floor(new Date(result).getTime() / 1000); // Lấy giờ server time (UNIX timestamp)
        const systemTimeUnix = Math.floor(systemTime.getTime() / 1000); // Lấy giờ system time (UNIX timestamp)

        // Bước 4: So sánh giờ server time và giờ system time
        const timeDifference = Math.abs(serverTimeUnix - systemTimeUnix);
        const maxTimeDifference = 15 * 60; // 15 phút

        if (timeDifference > maxTimeDifference) {
          // Bước 5: Chuyển sang trang error-time nếu lệch quá 15 phút
          localStorage.setItem("lastLoginDate", serverTimeUnix);
          localStorage.setItem("showPopupDailyNow", true);
          // window.location.href = "error-server-time";
          return true;
        } else {
          // Bước 6: Lưu giờ server time mới vào cache
          localStorage.setItem("lastLoginDate", serverTimeUnix);
          localStorage.setItem("showPopupDailyNow", true);
          return false;
        }
      }
    } else {
      return;
    }
  } catch (error) {
    console.error(error);
  }
};
export const checkServerTimeError = async () => {
  const result = await callDailyLoginAPI(new Date());
  return result;
};

export default checkAndCallDailyLoginAPI;
