/* eslint-disable react-hooks/exhaustive-deps */
// a React template for Gapfill type question

import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import SecondaryButtonQuestion from "../../components/button/secondary-button/SecondaryButtonQuestion";
import TertiaryButtonQuestion from "../../components/button/tertiary-button/TertiaryButtonQuestion";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import GapFill from "../../components/question/GapFill";
import { checkImage } from "../../constants/checkImage";
import ConstantStrings from "../../constants/ConstantStrings";
import {
  setQuestionState,
  setQuestionVisible
} from "../../reducers/questionsSlice";
import { cachesQuestion, getCachesQuestion } from "../helper/cacheQuestion";
import jsonParser from "../helper/jsonParser";

function Gapfill({
  sections,
  question,
  explanation,
  backgroundImage,
  section,
  slide,
  submitLesson,
  buttonSkipRef,
  setScrollTop,
  nextSection,
}) {
  var questionTexts =
    question.texts !== null &&
      question.texts !== undefined &&
      question.texts?.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;

  var questionChoices = question.choices;
  var questionSolutions = question.solutions;
  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      explanation.texts.some((item) => item?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      explanation.images.some((item) => item?.length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }
  var backgroundStyle = {
    borderRadius: "0 0 1rem 1rem",
    maxWidth: "60rem",
  };
  if (
    backgroundImage !== null &&
    backgroundImage &&
    checkImage(backgroundImage)
  ) {
    backgroundStyle = {
      ...backgroundStyle,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundStyle = {
      ...backgroundStyle,
      backgroundColor: "#E9EEF8",
    };
  }

  const dispatch = useDispatch();
  const cache = getCachesQuestion(section, slide);
  const [checkInput, setCheckInput] = useState(false);
  const [answers, setAnswers] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.answers;
    } else {
      return [];
    }
  });
  const [correctAnswers, setCorrectAnswers] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.correctAnswers;
    } else {
      return [];
    }
  });
  const [gapFillWithImages, setGapFillWithImages] = useState(
    questionChoices !== null &&
      questionChoices &&
      questionChoices.length > 0 &&
      questionChoices[0] !== null &&
      questionChoices[0].length > 0
      ? true
      : false
  );
  const [correct, setCorrect] = useState(false);
  const [explanationState, setExplanationState] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.explanationState;
    } else {
      return false;
    }
  });
  const [checkingState, setCheckingState] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.checkingState;
    } else {
      return false;
    }
  });
  const [seenAnswer, setSeenAnswer] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.seenAnswer;
    } else {
      return false;
    }
  });
  // const [imgWidth, setImgWidth] = useState(0);
  const [maxWidth, setMaxWidth] = useState(() => {
    if (cache?.maxWidth) {
      return cache.maxWidth;
    } else {
      return 60;
    }
  });
  const [isLoading, setIsLoading] = useState(
    cache || !checkImage(questionChoices[0]) ? false : true
  );
  const divRef = useRef(null);
  if (
    questionChoices !== null &&
    questionChoices &&
    questionChoices.length > 0 &&
    questionChoices[0] !== null &&
    questionChoices[0].length > 0
  ) {
    cachesQuestion(section, slide, 0, maxWidth);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }

  // check first time render if questionChoices are not empty strings
  useEffect(() => {
    if (
      questionChoices !== null &&
      questionChoices &&
      questionChoices.length > 0 &&
      questionChoices[0] !== null &&
      questionChoices[0].length > 0
    ) {
      setGapFillWithImages(true);
    }
    if (divRef.current.clientWidth / ConstantStrings.BASE_REM !== maxWidth) {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    };
    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [maxWidth]);

  const handleChange = (index) => (e) => {
    e.preventDefault();
    let newAnswers = [...answers];
    if (newAnswers.length < index) {
      for (let i = 0; i < index; i++) {
        if (!newAnswers[i]) {
          newAnswers[i] = "";
        }
      }
    }
    newAnswers[index] = e.target.value;
    setAnswers(newAnswers);
  };

  const handleCheck = () => {
    // e.preventDefault();
    //alert please fill in all gaps if not all gaps are filled
    if (
      answers.length < questionSolutions.length ||
      answers.some((item) => item === undefined || item === "")
    ) {
      setCheckInput(true);
      return;
    }
    setCheckingState(true);
    //check answer to each gap and set correctAnswers
    let newCorrectAnswers = [];
    for (let i = 0; i < questionSolutions.length; i++) {
      if (answers[i] === questionSolutions[i]) {
        newCorrectAnswers[i] = true;
      } else {
        newCorrectAnswers[i] = false;
      }
    }
    setCorrectAnswers(newCorrectAnswers);
    //check if all answers are correct
    let allCorrect = true;
    for (let i = 0; i < newCorrectAnswers.length; i++) {
      if (newCorrectAnswers[i] === false) {
        allCorrect = false;
      }
    }
    setCorrect(allCorrect);
  };

  const handleReset = () => {
    setAnswers([]);
    setCorrect(false);
    setExplanationState(false);
    setCheckingState(false);
    setCheckInput(false);
    setCorrectAnswers([]);
  };

  const handleShowExplanation = () => {
    setExplanationState(true);
    setSeenAnswer(true);
  };

  const showNextSlide = () => {
    dispatch(
      setQuestionState({
        section: section,
        index: slide,
        state: {
          answers: answers,
          correctAnswers: correctAnswers,
          checkingState: checkingState,
          explanationState: explanationState,
          seenAnswer: seenAnswer,
        },
      })
    );

    submitLesson(section, slide);
    if (sections[section].slides[slide].isLast) {
      nextSection();
    } else {
      dispatch(setQuestionVisible({ section: section, index: slide + 1 }));
    }

    setScrollTop(false);

    // window.scrollTo({
    //   top: document.documentElement.scrollHeight,
    //   behavior: 'smooth',
    // });

  };

  return (
    <div ref={divRef}>
      <BackgroundImageFromUrl
        style={backgroundStyle}
        objectId={backgroundImage}
        className={`${!checkImage(backgroundImage)
          ? "has-background-kurio-main-color-light-gray"
          : ""
          }`}
      >
        {/* if gapFillWithImages is false
                if questionChoices has length 4, render the textinput in a 2x2 grid
                else use columns is-multiline is-centered
            */}
        {isLoading && (
          <div className="has-text-kurio-main-color-black has-text-centered">
            Loading
          </div>
        )}
        {!explanationState && !gapFillWithImages && (
          <div
            className={`${isLoading ? "is-hidden" : ""
              } question-container is-flex is-flex-direction-column has-text-kurio-main-color-black`}
            style={{
              padding: "1.5rem 2rem",
              gap: "1rem",
            }}
          >
            {
              //render questionTexts if its array has length greater than 0
              questionTexts !== null &&
              questionTexts !== undefined &&
              questionTexts?.length > 0 && (
                <div
                  className="has-text-centered m-auto"
                  style={{ maxWidth: "40rem" }}
                >
                  {questionTexts}
                </div>
              )
            }
            {questionChoices.length === 4 ? (
              <>
                {(10 + 1) * 4 + 4 >= maxWidth ? (
                  <>
                    <div>
                      <div className="columns is-centered m-0 ">
                        <GapFill
                          index={0}
                          onChange={handleChange(0)}
                          answer={answers[0] || ""}
                          correctAnswers={correctAnswers[0]}
                          checkingState={checkingState}
                          choice={questionChoices[0]}
                        />
                        <GapFill
                          index={1}
                          onChange={handleChange(1)}
                          answer={answers[1] || ""}
                          correctAnswers={correctAnswers[1]}
                          checkingState={checkingState}
                          choice={questionChoices[1]}
                        />
                      </div>
                      <div className="columns is-centered m-0 ">
                        <GapFill
                          index={2}
                          onChange={handleChange(2)}
                          answer={answers[2] || ""}
                          correctAnswers={correctAnswers[2]}
                          checkingState={checkingState}
                          choice={questionChoices[2]}
                        />
                        <GapFill
                          index={3}
                          onChange={handleChange(3)}
                          answer={answers[3] || ""}
                          correctAnswers={correctAnswers[3]}
                          checkingState={checkingState}
                          choice={questionChoices[3]}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <div className="columns is-multiline is-centered m-0 ">
                        <GapFill
                          index={0}
                          onChange={handleChange(0)}
                          answer={answers[0] || ""}
                          correctAnswers={correctAnswers[0]}
                          checkingState={checkingState}
                          choice={questionChoices[0]}
                        />
                        <GapFill
                          index={1}
                          onChange={handleChange(1)}
                          answer={answers[1] || ""}
                          correctAnswers={correctAnswers[1]}
                          checkingState={checkingState}
                          choice={questionChoices[1]}
                        />
                        <GapFill
                          index={2}
                          onChange={handleChange(2)}
                          answer={answers[2] || ""}
                          correctAnswers={correctAnswers[2]}
                          checkingState={checkingState}
                          choice={questionChoices[2]}
                        />
                        <GapFill
                          index={3}
                          onChange={handleChange(3)}
                          answer={answers[3] || ""}
                          correctAnswers={correctAnswers[3]}
                          checkingState={checkingState}
                          choice={questionChoices[3]}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {questionChoices.length === 6 ? (
                  <>
                    {(10 + 1) * 3 + 4 <= maxWidth ? (
                      <>
                        <div>
                          <div className="columns is-centered m-0 ">
                            <GapFill
                              index={0}
                              onChange={handleChange(0)}
                              answer={answers[0] || ""}
                              correctAnswers={correctAnswers[0]}
                              checkingState={checkingState}
                              choice={questionChoices[0]}
                            />
                            <GapFill
                              index={1}
                              onChange={handleChange(1)}
                              answer={answers[1] || ""}
                              correctAnswers={correctAnswers[1]}
                              checkingState={checkingState}
                              choice={questionChoices[1]}
                            />
                            <GapFill
                              index={2}
                              onChange={handleChange(2)}
                              answer={answers[2] || ""}
                              correctAnswers={correctAnswers[2]}
                              checkingState={checkingState}
                              choice={questionChoices[2]}
                            />
                          </div>
                          <div className="columns is-centered m-0 ">
                            <GapFill
                              index={3}
                              onChange={handleChange(3)}
                              answer={answers[3] || ""}
                              correctAnswers={correctAnswers[3]}
                              checkingState={checkingState}
                              choice={questionChoices[3]}
                            />
                            <GapFill
                              index={4}
                              onChange={handleChange(4)}
                              answer={answers[4] || ""}
                              correctAnswers={correctAnswers[4]}
                              checkingState={checkingState}
                              choice={questionChoices[4]}
                            />
                            <GapFill
                              index={5}
                              onChange={handleChange(5)}
                              answer={answers[5] || ""}
                              correctAnswers={correctAnswers[5]}
                              checkingState={checkingState}
                              choice={questionChoices[5]}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="columns is-multiline is-centered m-0 ">
                          {questionChoices.map((choice, index) => {
                            return (
                              <GapFill
                                key={index}
                                index={index}
                                onChange={handleChange(index)}
                                answer={answers[index] || ""}
                                correctAnswers={correctAnswers[index]}
                                checkingState={checkingState}
                                choice={questionChoices[index]}
                              />
                            );
                          })}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="columns is-multiline is-centered">
                      {questionChoices.map((choice, index) => {
                        return (
                          <GapFill
                            key={index}
                            index={index}
                            onChange={handleChange(index)}
                            answer={answers[index] || ""}
                            correctAnswers={correctAnswers[index]}
                            checkingState={checkingState}
                            choice={questionChoices[index]}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
        {!explanationState && gapFillWithImages && (
          <div
            className={`${isLoading ? "is-hidden" : ""}`}
            style={{
              padding: "1.5rem 2rem",
            }}
          >
            {questionChoices.length === 4 ? (
              <>
                {(3 + 10 + 1 + 1) * 4 + 6 >= maxWidth ? (
                  <>
                    <div
                      className="columns is-centered m-0 "
                      style={{
                        gap: "2rem",
                      }}
                    >
                      <GapFill
                        index={0}
                        onChange={handleChange(0)}
                        answer={answers[0] || ""}
                        correctAnswers={correctAnswers[0]}
                        checkingState={checkingState}
                        choice={questionChoices[0]}
                      />
                      <GapFill
                        index={1}
                        onChange={handleChange(1)}
                        answer={answers[1] || ""}
                        correctAnswers={correctAnswers[1]}
                        checkingState={checkingState}
                        choice={questionChoices[1]}
                      />
                    </div>
                    <div
                      className="columns is-centered m-0 "
                      style={{
                        gap: "2rem",
                      }}
                    >
                      <GapFill
                        index={2}
                        onChange={handleChange(2)}
                        answer={answers[2] || ""}
                        correctAnswers={correctAnswers[2]}
                        checkingState={checkingState}
                        choice={questionChoices[2]}
                      />
                      <GapFill
                        index={3}
                        onChange={handleChange(3)}
                        answer={answers[3] || ""}
                        correctAnswers={correctAnswers[3]}
                        checkingState={checkingState}
                        choice={questionChoices[3]}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="columns is-centered m-0 "
                      style={{
                        gap: "2rem",
                      }}
                    >
                      {questionChoices.map((choice, index) => {
                        return (
                          <GapFill
                            key={index}
                            index={index}
                            onChange={handleChange(index)}
                            answer={answers[index] || ""}
                            correctAnswers={correctAnswers[index]}
                            checkingState={checkingState}
                            choice={questionChoices[index]}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {questionChoices.length === 6 ? (
                  <>
                    {(3 + 10 + 1 + 1) * 3 + 4 < maxWidth ? (
                      <>
                        <div
                          className="columns is-centered m-0 "
                          style={{
                            gap: "2rem",
                          }}
                        >
                          <GapFill
                            index={0}
                            onChange={handleChange(0)}
                            answer={answers[0] || ""}
                            correctAnswers={correctAnswers[0]}
                            checkingState={checkingState}
                            choice={questionChoices[0]}
                          />
                          <GapFill
                            index={1}
                            onChange={handleChange(1)}
                            answer={answers[1] || ""}
                            correctAnswers={correctAnswers[1]}
                            checkingState={checkingState}
                            choice={questionChoices[1]}
                          />
                          <GapFill
                            index={2}
                            onChange={handleChange(2)}
                            answer={answers[2] || ""}
                            correctAnswers={correctAnswers[2]}
                            checkingState={checkingState}
                            choice={questionChoices[2]}
                          />
                        </div>
                        <div
                          className="columns is-centered m-0 "
                          style={{
                            gap: "2rem",
                          }}
                        >
                          <GapFill
                            index={3}
                            onChange={handleChange(3)}
                            answer={answers[3] || ""}
                            correctAnswers={correctAnswers[3]}
                            checkingState={checkingState}
                            choice={questionChoices[3]}
                          />
                          <GapFill
                            index={4}
                            onChange={handleChange(4)}
                            answer={answers[4] || ""}
                            correctAnswers={correctAnswers[4]}
                            checkingState={checkingState}
                            choice={questionChoices[4]}
                          />
                          <GapFill
                            index={5}
                            onChange={handleChange(5)}
                            answer={answers[5] || ""}
                            correctAnswers={correctAnswers[5]}
                            checkingState={checkingState}
                            choice={questionChoices[5]}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="columns is-centered m-0 "
                          style={{
                            gap: "2rem",
                          }}
                        >
                          <GapFill
                            index={0}
                            onChange={handleChange(0)}
                            answer={answers[0] || ""}
                            correctAnswers={correctAnswers[0]}
                            checkingState={checkingState}
                            choice={questionChoices[0]}
                          />
                          <GapFill
                            index={1}
                            onChange={handleChange(1)}
                            answer={answers[1] || ""}
                            correctAnswers={correctAnswers[1]}
                            checkingState={checkingState}
                            choice={questionChoices[1]}
                          />
                        </div>
                        <div
                          className="columns is-centered m-0 "
                          style={{
                            gap: "2rem",
                          }}
                        >
                          <GapFill
                            index={2}
                            onChange={handleChange(2)}
                            answer={answers[2] || ""}
                            correctAnswers={correctAnswers[2]}
                            checkingState={checkingState}
                            choice={questionChoices[2]}
                          />
                          <GapFill
                            index={3}
                            onChange={handleChange(3)}
                            answer={answers[3] || ""}
                            correctAnswers={correctAnswers[3]}
                            checkingState={checkingState}
                            choice={questionChoices[3]}
                          />
                        </div>
                        <div
                          className="columns is-centered m-0 "
                          style={{
                            gap: "2rem",
                          }}
                        >
                          <GapFill
                            index={4}
                            onChange={handleChange(4)}
                            answer={answers[4] || ""}
                            correctAnswers={correctAnswers[4]}
                            checkingState={checkingState}
                            choice={questionChoices[4]}
                          />
                          <GapFill
                            index={5}
                            onChange={handleChange(5)}
                            answer={answers[5] || ""}
                            correctAnswers={correctAnswers[5]}
                            checkingState={checkingState}
                            choice={questionChoices[5]}
                          />
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div
                    className="columns m-0 is-multiline is-centered"
                    style={{ gap: "2rem" }}
                  >
                    {questionChoices.map((choice, index) => {
                      return (
                        <GapFill
                          key={index}
                          index={index}
                          onChange={handleChange(index)}
                          answer={answers[index] || ""}
                          correctAnswers={correctAnswers[index]}
                          checkingState={checkingState}
                          choice={questionChoices[index]}
                        />
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {explanationState && (
          <div
            className="explanation-container has-text-kurio-main-color-black is-flex is-flex-direction-column is-align-items-center"
            style={{
              gap: "1.5rem",
              fontSize: "1.25rem",
              padding: " 2rem 2rem 1.5rem",
            }}
          >
            {((explanationTexts !== null &&
              explanationTexts !== undefined &&
              explanationTexts?.length > 0) ||
              (explanationImages !== null &&
                explanationImages !== undefined &&
                explanationImages?.length > 0)) &&
              explanation.texts?.length >= explanation.images?.length && (
                <>
                  {explanation.texts.map((item, index) => (
                    <div
                      key={index}
                      className="is-flex is-flex-direction-column is-align-items-center"
                      style={{ gap: "1.5rem" }}
                    >
                      {explanationTexts !== null &&
                        explanationTexts !== undefined &&
                        item?.length > 0 && (
                          <div key={item}>{explanationTexts[index]}</div>
                        )}
                      {/* only appears when explanationImages has length greater than 0 */}
                      {explanationImages !== null &&
                        explanationImages !== undefined &&
                        explanationImages[index]?.length > 0 && (
                          <ImageFromUrl objectId={explanationImages[index]} />
                        )}
                    </div>
                  ))}
                </>
              )}
            {((explanationTexts !== null &&
              explanationTexts !== undefined &&
              explanationTexts?.length > 0) ||
              (explanationImages !== null &&
                explanationImages !== undefined &&
                explanationImages?.length > 0)) &&
              explanation.texts?.length < explanation.images?.length && (
                <>
                  {explanationImages.map((item, index) => (
                    <div
                      key={item}
                      className="is-flex is-flex-direction-column is-align-items-center"
                      style={{ gap: "1.5rem" }}
                    >
                      {explanationTexts !== null &&
                        explanationTexts !== undefined &&
                        explanation.texts[index]?.length > 0 && (
                          <div key={index}>{explanationTexts[index]}</div>
                        )}
                      {/* only appears when explanationImages has length greater than 0 */}
                      {explanationImages !== null &&
                        explanationImages !== undefined &&
                        item?.length > 0 && (
                          <ImageFromUrl objectId={explanationImages[index]} />
                        )}
                    </div>
                  ))}
                </>
              )}
          </div>
        )}

        <div
          className={` is-flex is-flex-direction-row ${!(slide + 1 > sections[section].slides.length - 1
            ? sections[section].isFinished
            : sections[section].slides[slide + 1].visible)
            ? checkingState &&
              correct &&
              explanation?.texts?.every((item) => item?.length <= 0) &&
              explanation?.images?.every((item) => item?.length <= 0)
              ? "is-align-items-center is-justify-content-center"
              : "is-align-items-flex-end is-justify-content-space-between"
            : "is-align-items-center is-justify-content-center"
            }`}
          style={{
            padding: "0 2rem 2rem",
            gap: "1rem",
          }}
        >
          {/* only appears when checkingState is true and the answers are incorrect */}
          <div
            className="is-flex is-flex-direction-row is-align-items-flex-start p-0"
            style={{ gap: "1rem" }}
          >
            {/* only appears when checkingState is false */}
            {!checkingState && !seenAnswer && (
              <>
                <PrimaryButtonQuestion
                  onClick={handleCheck}
                  disabled={
                    answers.some((item) => item === undefined || item === "") ||
                    answers.length < questionSolutions?.length
                  }
                >
                  {ConstantStrings.SUBMIT}
                </PrimaryButtonQuestion>
                {checkInput && (
                  <p
                    style={{
                      color: "red",
                      display: "inline-block",
                      fontSize: "1.1rem",
                      lineHeight: "2.5rem",
                    }}
                  >
                    {ConstantStrings.REQUEST_FILL_IN_ALL_GAPS}
                  </p>
                )}
              </>
            )}
            {/* Next and "Xem giai thich" button appears when checkingState is true */}
            {checkingState && explanationState === false && (
              <span>
                {explanation !== null &&
                  explanation !== undefined &&
                  ((explanationImages !== null &&
                    explanationImages !== undefined &&
                    explanationImages.length > 0) ||
                    (explanationTexts !== null &&
                      explanationTexts !== undefined &&
                      explanationTexts.length > 0)) && (
                    <SecondaryButtonQuestion
                      defaultColor={"is-kurio-purple-light-2"}
                      onClick={handleShowExplanation}
                    >
                      {ConstantStrings.SHOW_EXPLANATION}
                    </SecondaryButtonQuestion>
                  )}
              </span>
            )}
            {checkingState &&
              answers.toString() !== questionSolutions.toString() &&
              !seenAnswer && (
                <PrimaryButtonQuestion onClick={handleReset}>
                  {ConstantStrings.RETRY}
                </PrimaryButtonQuestion>
              )}

            {/* "Xem cau hoi" button appears when explanationState is true */}
            {explanationState && (
              <SecondaryButtonQuestion
                className="button"
                defaultColor={"is-kurio-purple-light-2"}
                onClick={() => {
                  setExplanationState(false);
                }}
              >
                {ConstantStrings.SHOW_QUESTION}
              </SecondaryButtonQuestion>
            )}
          </div>
          {((checkingState &&
            answers.toString() === questionSolutions.toString()) ||
            explanationState ||
            seenAnswer) && (
              <div
                className={`${(
                  slide + 1 > sections[section].slides.length - 1
                    ? sections[section].isFinished
                    : sections[section].slides[slide + 1].visible
                )
                  ? "is-hidden"
                  : ""
                  } `}
              >
                <PrimaryButtonQuestion onClick={showNextSlide}>
                  {ConstantStrings.NEXT}
                </PrimaryButtonQuestion>
              </div>
            )}

          {((checkingState && !correct && !explanationState && !seenAnswer) ||
            (!checkingState && !explanationState && !seenAnswer)) && (
              <div
                className={`${(
                  slide + 1 > sections[section].slides.length - 1
                    ? sections[section].isFinished
                    : sections[section].slides[slide + 1].visible
                )
                  ? "is-hidden"
                  : ""
                  } `}
              >
                <TertiaryButtonQuestion
                  onClick={showNextSlide}
                  suffix="admin/frontend-images/button/button-skip-icon"
                >
                  {ConstantStrings.SKIP}
                </TertiaryButtonQuestion>
              </div>
            )}
        </div>
      </BackgroundImageFromUrl>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    sections: state.questions.sections,
  };
}

export default connect(mapStateToProps)(Gapfill);
