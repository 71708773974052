/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ScrollBar from "../../components/scrollBar/ScrollBar";
import {
  setQuestionFinished,
  setQuestionVisible,
  setSeenQuestions
} from "../../reducers/practiceElectiveSlice";
import "../../styling/layout-components/PracticeElective.css";
import QPrompt1_1 from "../../templates/content/QPrompt1_1";
import QPrompt1_2 from "../../templates/content/QPrompt1_2";
import QPrompt1_3 from "../../templates/content/QPrompt1_3";
import QPrompt2_1 from "../../templates/content/QPrompt2_1";
import QPrompt2_2 from "../../templates/content/QPrompt2_2";
import QPrompt2_3 from "../../templates/content/QPrompt2_3";
import QPrompt2_4 from "../../templates/content/QPrompt2_4";
import QPrompt3_1 from "../../templates/content/QPrompt3_1";
import QPrompt3_2 from "../../templates/content/QPrompt3_2";
import DragDrop from "../../templates/practice-elective/DragDrop";
import DragDropMulti from "../../templates/practice-elective/DragDropMulti";
import Gapfill from "../../templates/practice-elective/Gapfill";
import MultipleChoice from "../../templates/practice-elective/MultipleChoice";
import MultipleResponse from "../../templates/practice-elective/MultipleResponse";
import VerticalGapfill from "../../templates/practice-elective/VerticalGapfill";
import VerticalMultipleChoice from "../../templates/practice-elective/VerticalMultipleChoice";
import VerticalMultipleResponse from "../../templates/practice-elective/VerticalMultipleResponse";


function PracticeContent({
  question,
  questions,
  seenQuestions,
  handleQuestionChange,
  submitAnswers,
  closeModal,
  questionIndex,
  showEndPractice,
  setIsScroll,
}) {
  const dispatch = useDispatch();
  const [nextScroll, setNextScroll] = useState(false)
  const promptTemplate = question.template.prompt;
  const questionTemplate = question.template.question;
  var backgroundStyle = {
    backgroundSize: "cover",
    // backgroundImage: `url(${question?.backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    boxShadow: "2px 4px 0px 0px #0a2a66",
    borderRadius: "1rem",
    border: "1.5px solid #0a2a66"
  };
  const handleSkip = () => {
    if (question.questionIndex === seenQuestions - 1) {
      dispatch(setQuestionVisible(seenQuestions));
      dispatch(setSeenQuestions(seenQuestions + 1));
    }
    dispatch(setQuestionFinished(question.questionIndex));
    handleQuestionChange(question.questionIndex + 1);
    // setIsScroll(false)
  };
  // const handleEndPractice = ()=>{
  //   if(question.isLast && closeModal){
  //     closeModal()
  //   }
  // }

  const TemplateComponent = () => {
    switch (promptTemplate) {
      case "QPrompt1_1":
        return (
          <QPrompt1_1 data={question} isLast={true} order={question.questionIndex + 1} />
        );
      case "QPrompt1_2":
        return (
          <QPrompt1_2 data={question} isLast={true} order={question.questionIndex + 1} />
        );
      case "QPrompt1_3":
        return (
          <QPrompt1_3 data={question} isLast={true} order={question.questionIndex + 1} />
        );
      case "QPrompt2_1":
        return (
          <QPrompt2_1 data={question} isLast={true} order={question.questionIndex + 1} />
        );
      case "QPrompt2_2":
        return (
          <QPrompt2_2 data={question} isLast={true} order={question.questionIndex + 1} />
        );
      case "QPrompt2_3":
        return (
          <QPrompt2_3 data={question} isLast={true} order={question.questionIndex + 1} />
        );
      case "QPrompt2_4":
        return (
          <QPrompt2_4 data={question} isLast={true} order={question.questionIndex + 1} />
        );
      case "QPrompt3_1":
        return (
          <QPrompt3_1 data={question} isLast={true} order={question.questionIndex + 1} />
        );
      case "QPrompt3_2":
        return (
          <QPrompt3_2 data={question} order={question.questionIndex + 1} />
        );
      default:
        return <div>Template not found</div>;
    }
  };

  const TemplateQuestion = () => {
    switch (questionTemplate) {
      case "MultipleChoice":
        return (
          <MultipleChoice
            question={question.question}
            explanation={question.explanation}
            backgroundImage={question?.question.backgroundImage}
            questionIndex={question.questionIndex}
            isLast={question?.isLast}
            nextQuestion={handleSkip}
            setNextScroll={setNextScroll}
            showEndPractice={showEndPractice}
            submitAnswers={submitAnswers}
            seenQuestions={seenQuestions}
          />
        );
      case "VerticalMultipleChoice":
        return (
          <VerticalMultipleChoice
            question={question.question}
            explanation={question.explanation}
            backgroundImage={[
              question?.prompt.backgroundImage,
              question?.question.backgroundImage,
            ]}
            questionIndex={question.questionIndex}
            isLast={question?.isLast}
            nextQuestion={handleSkip}
            showEndPractice={showEndPractice}
            submitAnswers={submitAnswers}
            seenQuestions={seenQuestions}
          />
        );

      case "VerticalMultipleResponse":
        return (
          <VerticalMultipleResponse
            question={question.question}
            explanation={question.explanation}
            backgroundImage={[
              question?.prompt.backgroundImage,
              question?.question.backgroundImage,
            ]}
            questionIndex={question.questionIndex}
            isLast={question?.isLast}
            nextQuestion={handleSkip}
            showEndPractice={showEndPractice}
            submitAnswers={submitAnswers}
            seenQuestions={seenQuestions}
          />
        );

      case "VerticalGapfill":
        return (
          <VerticalGapfill
            question={question.question}
            explanation={question.explanation}
            backgroundImage={[
              question?.prompt.backgroundImage,
              question?.question.backgroundImage,
            ]}
            questionIndex={question.questionIndex}
            isLast={question?.isLast}
            nextQuestion={handleSkip}
            showEndPractice={showEndPractice}
            submitAnswers={submitAnswers}
            seenQuestions={seenQuestions}
          />
        );
      case "MultipleResponse":
        return (
          <MultipleResponse
            question={question.question}
            explanation={question.explanation}
            backgroundImage={question?.question.backgroundImage}
            questionIndex={question.questionIndex}
            isLast={question?.isLast}
            nextQuestion={handleSkip}
            setNextScroll={setNextScroll}
            showEndPractice={showEndPractice}
            submitAnswers={submitAnswers}
            seenQuestions={seenQuestions}
          />
        );
      case "Gapfill":
        return (
          <Gapfill
            question={question.question}
            explanation={question.explanation}
            backgroundImage={question?.question.backgroundImage}
            questionIndex={question.questionIndex}
            isLast={question?.isLast}
            nextQuestion={handleSkip}
            setNextScroll={setNextScroll}
            showEndPractice={showEndPractice}
            submitAnswers={submitAnswers}
            seenQuestions={seenQuestions}
          />
        );
      case "DragDrop":
        return (
          <DragDrop
            question={question.question}
            explanation={question.explanation}
            questionIndex={question.questionIndex}
            isLast={question?.isLast}
            nextQuestion={handleSkip}
            setNextScroll={setNextScroll}
            showEndPractice={showEndPractice}
            submitAnswers={submitAnswers}
            seenQuestions={seenQuestions}
          />
        );
      case "DragDropMulti":
        return (
          <DragDropMulti
            question={question.question}
            explanation={question.explanation}
            questionIndex={question.questionIndex}
            isLast={question?.isLast}
            nextQuestion={handleSkip}
            setNextScroll={setNextScroll}
            showEndPractice={showEndPractice}
            submitAnswers={submitAnswers}
            seenQuestions={seenQuestions}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ScrollBar question={question}>
      <BackgroundImageFromUrl
        objectId={question?.backgroundImage}
        className="is-centered"
        style={{
          ...backgroundStyle,
          // display: "flex",
          // flexDirection: "column",
          width: "99.5%",
          // marginLeft: "1.5rem",
          // paddingRight: "1.5rem",
          // position: "fixed",
          // top: "10rem",
          // height: "100%",
        }}
      >
        <div className="">
          <TemplateComponent />
        </div>
        <div className="">
          <TemplateQuestion />
        </div>
        {/* {!question.isLast && (
                <button
                    className="button m-4"
                    onClick={handleSkip}
                    style={{ width: "fit-content", alignSelf: "center" }}
                >
                    Skip
                </button>
            )} */}
        {/* {question.isLast &&(
                <button
                    className="button m-4"
                    onClick={handleEndPractice}
                    style={{ width: "fit-content", alignSelf: "center" }}
                >
                    End Practice
                </button>
            )} */}
      </BackgroundImageFromUrl>
    </ScrollBar>
  );
}

export default PracticeContent;
