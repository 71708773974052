import React from "react";
import ButtonRoadmap from "./ButtonRoadmap";

const ProblemSet = ({
  status,
  disabled,
  index,
  onClick,
  currentScore,
  maxScore,
}) => {
  //status: 1 - start, 2 - redo, 3 - finished

  var cardStye = {
    padding: "0rem 1rem",
    borderRadius: "1rem",
    border: "0.09375rem solid #0A2A66",
    boxShadow:
      "0.125rem 0.25rem 0rem 0rem #0A2A66, 0rem 0.25rem 0.5rem 0rem #000000",
    fontSize: "1.25rem",
    lineHeight: "normal",
    height: "3.5rem",
  };
  var boxRightStyle = {
    width: "5rem",
    height: "2rem",
    gap: "0.625rem",
    borderRadius: "1rem",
  };
  const handleClick = () => {
    if (!disabled) {
      if (onClick) {
        onClick();
      }
    }
  };
  return (
    <div
      className={`${disabled
        ? "has-text-kurio-gray-main-accent"
        : "has-text-kurio-main-color-black"
        } is-relative is-flex is-align-items-center is-flex-gap-4 has-text-weight-bold has-background-kurio-main-color-white`}
      style={cardStye}
    >
      <p>{index}</p>
      <div style={{ width: "3.5rem", height: "3rem" }}></div>
      <div style={{ position: "absolute", left: "4.1875rem" }}>
        <ButtonRoadmap
          status={status}
          disabled={disabled}
          onClick={handleClick}
        />
      </div>
      <div
        className={`${disabled
          ? "has-text-kurio-gray-main-accent has-background-kurio-gray-light-2"
          : status === 1
            ? "has-text-kurio-main-color-purple has-background-kurio-purple-light-3"
            : status === 2
              ? "has-text-kurio-blue-dark has-background-kurio-blue-light-2"
              : status === 3
                ? "has-text-kurio-green-main-accent has-background-kurio-green-light-2"
                : ""
          } is-flex is-align-items-center is-justify-content-center has-text-weight-bold`}
        style={boxRightStyle}
      >
        {disabled ? "Khóa" : status === 1 ? "Làm" : currentScore + " / " + maxScore}
      </div>
    </div>
  );
};

export default ProblemSet;
