/* eslint-disable react-hooks/exhaustive-deps */
// Still needs to review explanation part
// Revamp color and scaling

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import SecondaryButtonQuestion from "../../components/button/secondary-button/SecondaryButtonQuestion";
import TertiaryButtonQuestion from "../../components/button/tertiary-button/TertiaryButtonQuestion";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import ConstantStrings from "../../constants/ConstantStrings";
import { checkImage } from "../../constants/checkImage";
import {
  setCorrectAnswer,
  setCorrectQuestion,
  setQuestionFinished,
  setQuestionState,
  setQuestionVisible,
  setWrongAnswer,
} from "../../reducers/practiceElectiveSlice";
import { cachesQuestion, getCachesQuestion } from "../helper/cacheQuestion";
import { sumWithButton } from "../helper/calculateWidthButton";
import jsonParser from "../helper/jsonParser";
import { getCacheImage } from "../helper/parseImage";

function MultipleChoice({
  question,
  explanation,
  backgroundImage,
  questionIndex,
  isLast,
  nextQuestion,
  showEndPractice,
  submitAnswers,
  isPreview,
  setNextScroll,
  seenQuestions,
}) {
  const dispatch = useDispatch();
  const cache = getCachesQuestion("practice-elective", questionIndex);
  var questionTexts =
    question.texts !== null &&
      question.texts !== undefined &&
      question.texts?.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;
  var questionChoices = question.choices;
  var questionSolutions = question.solutions;
  //check if explanation is null
  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      explanation.texts.some((item) => item?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      explanation.images.some((item) => item?.length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }
  var exerciseState = JSON.parse(localStorage.getItem("exerciseState"));

  //create a state variable to store the selected answer
  var [selected, setSelected] = useState(() => {
    if (question.state) {
      return question.state.selected;
    } else {
      return -1;
    }
  });
  //create a state variable to store the checking state
  var [checkingState, setCheckingState] = useState(() => {
    if (question.state) {
      return question.state.checkingState;
    } else {
      return false;
    }
  });
  //create a state variable to store the explanation state
  var [explanationState, setExplanationState] = useState(() => {
    if (question.state) {
      return question.state.explanationState;
    } else {
      return false;
    }
  });

  //get max length of a choice
  //get max length of a choice
  var maxLength = 0;
  for (var i = 0; i < questionChoices.length; i++) {
    if (questionChoices[i].length > maxLength) {
      maxLength = questionChoices[i].length;
    }
  }
  const [imgWidth, setImgWidth] = useState(() => {
    if (cache?.imgWidth) {
      return cache.imgWidth;
    } else {
      return 0;
    }
  });
  const [widthButton] = useState(
    checkImage(questionChoices[0]) ? 0 : maxLength >= 11 ? 20 : 10
  );
  const [maxWidth, setMaxWidth] = useState(() => {
    if (cache?.maxWidth) {
      return cache.maxWidth;
    } else {
      return 60;
    }
  });
  const [isLoading, setIsLoading] = useState(
    imgWidth === 0 && checkImage(questionChoices[0]) ? true : false
  );

  var backgroundStyle = {
    borderRadius: "0 0 1rem 1rem",
    opacity: 1,
  };

  if (
    backgroundImage !== null &&
    backgroundImage &&
    checkImage(backgroundImage)
  ) {
    backgroundStyle = {
      ...backgroundStyle,
      // backgroundImage: `url(${memoizedImageData[2]})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  }
  let buttonStyle = {};
  if (maxLength <= 10 && !checkImage(questionChoices[0])) {
    buttonStyle = {
      width: "10rem",
      padding: "0.75rem 1rem",
      height: "3rem",
      opacity: 1,
      fontWeight: "bold",
      fontSize: "1.125rem",
    };
  } else {
    if (checkImage(questionChoices[0])) {
      buttonStyle = {
        maxWidth: "20rem",
        width: `${imgWidth !== 0 ? `${imgWidth + 0.125 + 2}rem` : "auto"}`,
        height: "auto",
        padding: "1rem",
        whiteSpace: "pre-wrap",
        outline: "0.0625rem solid",
        opacity: 1,
        borderRadius: "1.5rem",
        fontWeight: "bold",
        fontSize: "1.125rem",
      };
    } else {
      buttonStyle = {
        width: "20rem",
        height: "auto",
        padding: "0.75rem 1.25rem",
        whiteSpace: "pre-wrap",
        outline: "0.0625rem solid",
        opacity: 1,
        fontWeight: "bold",
        fontSize: "1.125rem",
      };
    }
  }

  var selectedStyle = {
    ...buttonStyle,
    outline: "0.125rem solid",
  };

  var iconStyle = {
    zIndex: 1,
    position: "absolute",
    top: "0.7rem",
    width: "1.5rem",
    height: "1.5rem",
    left: "-0.7rem",
  };
  const setLocalStorage = (
    answer,
    correct,
    selected,
    checkingState,
    explanationState
  ) => {
    localStorage.setItem(
      "exerciseState",
      JSON.stringify({
        correct,
        answer,
        questionState: {
          index: questionIndex,
          state: {
            selected: selected,
            checkingState: checkingState,
            explanationState: explanationState,
          },
        },
      })
    );
  };
  //McqButton component
  const divRef = useRef(null);
  const handleSetWidthImage = (width) => {
    setImgWidth(width);
  };
  const McqButton = ({ index, children }) => {
    const buttonRef = useRef(null);
    return (
      //if index = selected, set the style to selectedStyle
      //if index = selected and checkingState = true, set the style to correctStyle if the answer is correct, set the style to incorrectStyle if the answer is incorrect
      <div className="is-relative">
        {index === selected && checkingState ? (
          questionSolutions[selected] === true ? (
            <img
              src="/images/common/greenV.svg"
              style={iconStyle}
              alt="loading"
            />
          ) : (
            <img
              src="/images/common/redX.svg"
              style={iconStyle}
              alt="loading"
            />
          )
        ) : (
          <div
            className="icon"
            style={{
              ...iconStyle,
              width: "0.75rem",
              height: "0.75rem",
            }}
          ></div>
        )}
        <button
          ref={buttonRef}
          className={`${index === selected
            ? checkingState
              ? questionSolutions[selected] === true
                ? "has-background-kurio-green-light-3 is-kurio-green-main-accent has-text-kurio-green-main-accent"
                : "has-background-kurio-red-light-3 is-kurio-red-main-accent has-text-kurio-red-main-accent"
              : "has-background-kurio-purple-light-3 is-kurio-purple-main-accent has-text-kurio-purple-main-accent"
            : checkingState
              ? " is-kurio-gray-main-accent has-text-kurio-gray-main-accent"
              : "has-text-kurio-main-color-black is-kurio-main-color-black"
            } button is-shadowless has-text-weight-bold is-outlined has-background-kurio-main-color-white`}
          style={
            index === selected && !checkingState ? selectedStyle : buttonStyle
          }
          onClick={() => handleSelect(index)}
          disabled={checkingState}
        >
          {!children.slice(0, 2).includes("@@") && checkImage(children) ? (
            <>
              {getCacheImage([children]) &&
                getCachesQuestion("practice-elective", questionIndex) ? (
                <>
                  <img src={getCacheImage([children])} alt="loading" />
                </>
              ) : (
                <>
                  {index === 0 ? (
                    <ImageFromUrl
                      setImgWidth={(width) => handleSetWidthImage(width)}
                      objectId={children}
                    />
                  ) : (
                    <ImageFromUrl objectId={children} />
                  )}
                </>
              )}
            </>
          ) : (
            jsonParser(children)
          )}
        </button>
      </div>
    );
  };

  //mapping each choice to a component
  const MapChoices = questionChoices.map((choice, index) => {
    return (
      //id = "auto-height" if the choice is longer than 32 characters
      <div
        className="column is-narrow"
        style={{ padding: "0.5rem 0.75rem" }}
        key={index}
      >
        <McqButton index={index}>{choice}</McqButton>
      </div>
    );
  });

  const McqButtonShowSolution = ({ index, children }) => {
    const buttonRef = useRef(null);
    return (
      //if index = selected, set the style to selectedStyle
      //if index = selected and checkingState = true, set the style to correctStyle if the answer is correct, set the style to incorrectStyle if the answer is incorrect
      <div className="is-relative">
        {index === questionSolutions.indexOf(true) && checkingState ? (
          <>
            {questionSolutions[questionSolutions.indexOf(true)] === true && (
              <img
                src="/images/common/greenV.svg"
                style={iconStyle}
                alt="loading"
              />
            )}
          </>
        ) : (
          <div
            className="icon"
            style={{
              ...iconStyle,
              width: "0.75rem",
              height: "0.75rem",
            }}
          ></div>
        )}
        <button
          ref={buttonRef}
          className={`${index === questionSolutions.indexOf(true)
            ? checkingState
              ? questionSolutions[questionSolutions.indexOf(true)] === true &&
              "has-background-kurio-green-light-3 is-kurio-green-main-accent has-text-kurio-green-main-accent"
              : "has-background-kurio-purple-light-3 is-kurio-purple-main-accent has-text-kurio-purple-main-accent"
            : checkingState
              ? " is-kurio-gray-main-accent has-text-kurio-gray-main-accent"
              : "has-text-kurio-main-color-black is-kurio-main-color-black"
            } button is-shadowless has-text-weight-bold is-outlined has-background-kurio-main-color-white`}
          style={
            index === questionSolutions.indexOf(true) && !checkingState
              ? selectedStyle
              : buttonStyle
          }
          onClick={() => handleSelect(index)}
          disabled={checkingState}
        >
          {!children.slice(0, 2).includes("@@") && checkImage(children) ? (
            <>
              {getCacheImage([children]) &&
                getCachesQuestion("practice-elective", questionIndex) ? (
                <>
                  <img src={getCacheImage([children])} alt="loading" />
                </>
              ) : (
                <>
                  {index === 0 ? (
                    <ImageFromUrl
                      setImgWidth={(width) => handleSetWidthImage(width)}
                      objectId={children}
                    />
                  ) : (
                    <ImageFromUrl objectId={children} />
                  )}
                </>
              )}
            </>
          ) : (
            jsonParser(children)
          )}
        </button>
      </div>
    );
  };

  const MapChoicesShowSolution = questionChoices.map((choice, index) => {
    return (
      //id = "auto-height" if the choice is longer than 32 characters
      <div
        className="column is-narrow"
        style={{ padding: "0.5rem 0.75rem" }}
        key={index}
      >
        <McqButtonShowSolution index={index}>{choice}</McqButtonShowSolution>
      </div>
    );
  });

  //create a function to handle the selection of an answer
  //when an answer is selected, set the selected state variable to the index of the answer
  const handleSelect = (index) => {
    var answer = questionSolutions.map((item) => false);
    if (selected === index) {
      setSelected(-1);
      answer = null;
    } else {
      setSelected(index);
      answer[index] = true;
    }
    var questionState = {
      index: questionIndex,
      state: {
        selected: index === selected ? -1 : index,
        checkingState: checkingState,
        explanationState: explanationState,
      },
    };
    localStorage.setItem(
      "exerciseState",
      JSON.stringify({ answer, questionState, correct: null })
    );
  };

  //create a function to handle the checking of the answer
  //when the answer is checked, set the checking state variable to true
  //if the selected answer is correct, alert "Correct!", change the background color of the selected answer to green
  //if the selected answer is incorrect, alert "Incorrect!", change the background color of the selected answer to red
  const handleCheck = () => {
    var correct = null;
    var answer = questionSolutions.map((item, index) =>
      index === selected ? true : false
    );
    if (selected !== -1) {
      if (!isPreview) {
        setNextScroll(true)
      }
      if (questionSolutions[selected] === true) {
        // alert("Correct!");
        correct = true;
        if (!isPreview || isPreview === undefined) {
          dispatch(setCorrectAnswer(1));
          dispatch(
            setCorrectQuestion({
              questionIndex: questionIndex,
              correct: true,
            })
          );
        }
      } else {
        // alert("Incorrect!");
        if (!isPreview || isPreview === undefined) {
          dispatch(setWrongAnswer(1));
          dispatch(
            setCorrectQuestion({
              questionIndex: questionIndex,
              correct: false,
            })
          );
        }
        correct = false;
      }
      setLocalStorage(answer, correct, selected, true, false);
      if (!isPreview || isPreview === undefined) {
        const exerciseStateLocal = JSON.parse(
          localStorage.getItem("exerciseState")
        );
        dispatch(setQuestionState(exerciseStateLocal.questionState));
        submitAnswers(answer);
      }
      setCheckingState(true);
    } else {
      // alert("Please select an answer!");
    }
  };

  //create a function to handle the showing of the explanation
  const handleShowExplanation = () => {
    if (exerciseState) {
      exerciseState.questionState.state.explanationState = true;
      localStorage.setItem("exerciseState", JSON.stringify(exerciseState));
    } else {
      var questionState = {
        index: questionIndex,
        state: {
          selected: selected,
          checkingState: checkingState,
          explanationState: true,
        },
      };
      localStorage.setItem("exerciseState", JSON.stringify({ questionState }));
    }
    setExplanationState(true);
  };

  const showNextQuestion = () => {
    if (!isPreview) {
      setNextScroll(false)
    }
    if (exerciseState === null || !checkingState) {
      setLocalStorage(null, null, selected, checkingState, explanationState);
      if (!isPreview || isPreview === undefined) {
        submitAnswers(null);
      }
    }
    if (!isPreview || isPreview === undefined) {
      dispatch(setQuestionFinished(questionIndex));
      if (!isLast) {
        nextQuestion();
      } else {
        showEndPractice();
      }
    } else {
      const exerciseState = JSON.parse(localStorage.getItem("exerciseState"));
      dispatch(setQuestionState(exerciseState.questionState));
      dispatch(setQuestionVisible(questionIndex + 1));
    }
  };
  const McqImageWrap = () => {
    return (
      <>
        {questionChoices.length === 4 &&
          sumWithButton("IMAGE", imgWidth, 4) >= maxWidth ? (
          <div>
            <div className="columns is-centered m-0 p-0">
              {MapChoices[0]}
              {MapChoices[1]}
            </div>
            <div className="columns is-centered m-0 p-0">
              {MapChoices[2]}
              {MapChoices[3]}
            </div>
          </div>
        ) : questionChoices.length === 5 ? (
          <>
            {sumWithButton("IMAGE", imgWidth, 5) >= maxWidth ? (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[2]}
                  {MapChoices[3]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[4]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                  {MapChoices[2]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[3]}
                  {MapChoices[4]}
                </div>
              </div>
            )}
          </>
        ) : questionChoices.length === 6 ? (
          <>
            {sumWithButton("IMAGE", imgWidth, 6) >= maxWidth ? (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[2]}
                  {MapChoices[3]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[4]}
                  {MapChoices[5]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                  {MapChoices[2]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[3]}
                  {MapChoices[4]}
                  {MapChoices[5]}
                </div>
              </div>
            )}
          </>
        ) : questionChoices.length === 3 ? (
          <>
            {sumWithButton("IMAGE", imgWidth, 3) >= maxWidth ? (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[2]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                  {MapChoices[2]}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="columns is-multiline is-centered m-0 p-0">
            {MapChoices}
          </div>
        )}
      </>
    );
  };
  const McqImageWrapSolution = () => {
    return (
      <>
        {questionChoices.length === 4 &&
          sumWithButton("IMAGE", imgWidth, 4) >= maxWidth ? (
          <div>
            <div className="columns is-centered m-0 p-0">
              {MapChoicesShowSolution[0]}
              {MapChoicesShowSolution[1]}
            </div>
            <div className="columns is-centered m-0 p-0">
              {MapChoicesShowSolution[2]}
              {MapChoicesShowSolution[3]}
            </div>
          </div>
        ) : questionChoices.length === 5 ? (
          <>
            {sumWithButton("IMAGE", imgWidth, 5) >= maxWidth ? (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoicesShowSolution[0]}
                  {MapChoicesShowSolution[1]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoicesShowSolution[2]}
                  {MapChoicesShowSolution[3]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoicesShowSolution[4]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoicesShowSolution[0]}
                  {MapChoicesShowSolution[1]}
                  {MapChoicesShowSolution[2]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoicesShowSolution[3]}
                  {MapChoicesShowSolution[4]}
                </div>
              </div>
            )}
          </>
        ) : questionChoices.length === 6 ? (
          <>
            {sumWithButton("IMAGE", imgWidth, 6) >= maxWidth ? (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoicesShowSolution[0]}
                  {MapChoicesShowSolution[1]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoicesShowSolution[2]}
                  {MapChoicesShowSolution[3]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoicesShowSolution[4]}
                  {MapChoicesShowSolution[5]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoicesShowSolution[0]}
                  {MapChoicesShowSolution[1]}
                  {MapChoicesShowSolution[2]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoicesShowSolution[3]}
                  {MapChoicesShowSolution[4]}
                  {MapChoicesShowSolution[5]}
                </div>
              </div>
            )}
          </>
        ) : questionChoices.length === 3 ? (
          <>
            {sumWithButton("IMAGE", imgWidth, 3) >= maxWidth ? (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoicesShowSolution[0]}
                  {MapChoicesShowSolution[1]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoicesShowSolution[2]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoicesShowSolution[0]}
                  {MapChoicesShowSolution[1]}
                  {MapChoicesShowSolution[2]}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="columns is-multiline is-centered m-0 p-0">
            {MapChoicesShowSolution}
          </div>
        )}
      </>
    );
  };

  if (imgWidth !== 0) {
    cachesQuestion("practice-elective", questionIndex, imgWidth, maxWidth);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }
  useEffect(() => {
    if (divRef.current.clientWidth / ConstantStrings.BASE_REM !== maxWidth) {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    };
    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [maxWidth]);

  return (
    <div ref={divRef}>
      <BackgroundImageFromUrl
        objectId={backgroundImage}
        style={backgroundStyle}
        className={`${!checkImage(backgroundImage)
          ? "has-background-kurio-main-color-light-gray"
          : ""
          }`}
      >
        {isLoading && <div className="has-text-centered">Loading</div>}
        {!explanationState && (
          <div
            className={`${isLoading ? "is-hidden" : ""
              } question-container is-flex is-flex-direction-column has-text-kurio-main-color-black`}
            style={{
              padding: "1.5rem 2rem",
              gap: "1rem",
            }}
          >
            {/* {jsonParser("$$\\begin{aligned} 12 \\times (20 - 15) &= 12 \\times 5 \\\\ &=  \\color{red}{60}\\end{aligned}$$  \\n @@<p style='color:red'>test ok</p>@@ ")} */}
            {
              //render questionTexts if its array has length greater than 0
              questionTexts !== null &&
              questionTexts !== undefined &&
              questionTexts?.length > 0 && (
                <div
                  className=" has-text-centered m-auto"
                  style={{ maxWidth: "40rem" }}
                >
                  {questionTexts}
                </div>
              )
            }
            {checkImage(questionChoices[0]) ? (
              <>
                <McqImageWrap />
              </>
            ) : (
              <>
                {questionChoices.length === 4 &&
                  sumWithButton("TEXT", widthButton, 4) >= maxWidth ? (
                  <div>
                    <div className="columns is-centered m-0">
                      {MapChoices[0]}
                      {MapChoices[1]}
                    </div>
                    <div className="columns is-centered m-0">
                      {MapChoices[2]}
                      {MapChoices[3]}
                    </div>
                  </div>
                ) : // if questionChoices has length 5, render the choices in a 3x2 grid
                  questionChoices.length === 5 ? (
                    <>
                      {sumWithButton("TEXT", widthButton, 5) > maxWidth ? (
                        <div>
                          <div className="columns is-centered m-0">
                            {MapChoices[0]}
                            {MapChoices[1]}
                          </div>
                          <div className="columns is-centered m-0">
                            {MapChoices[2]}
                            {MapChoices[3]}
                          </div>
                          <div className="columns is-centered m-0">
                            {MapChoices[4]}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="columns is-centered m-0">
                            {MapChoices[0]}
                            {MapChoices[1]}
                            {MapChoices[2]}
                          </div>
                          <div className="columns is-centered m-0">
                            {MapChoices[3]}
                            {MapChoices[4]}
                          </div>
                        </div>
                      )}
                    </>
                  ) : // if questionChoices has length 6, render the choices in a 3x3 grid
                    questionChoices.length === 6 ? (
                      <>
                        {sumWithButton("TEXT", widthButton, 6) > maxWidth ? (
                          <div>
                            <div className="columns is-centered m-0">
                              {MapChoices[0]}
                              {MapChoices[1]}
                            </div>
                            <div className="columns is-centered m-0">
                              {MapChoices[2]}
                              {MapChoices[3]}
                            </div>
                            <div className="columns is-centered m-0">
                              {MapChoices[4]}
                              {MapChoices[5]}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="columns is-centered m-0">
                              {MapChoices[0]}
                              {MapChoices[1]}
                              {MapChoices[2]}
                            </div>
                            <div className="columns is-centered m-0">
                              {MapChoices[3]}
                              {MapChoices[4]}
                              {MapChoices[5]}
                            </div>
                          </div>
                        )}
                      </>
                    ) : questionChoices.length === 3 ? (
                      <>
                        {sumWithButton("TEXT", widthButton, 3) > maxWidth ? (
                          <div>
                            <div className="columns is-centered p-0 m-0">
                              {MapChoices[0]}
                              {MapChoices[1]}
                            </div>
                            <div className="columns is-centered p-0 m-0">
                              {MapChoices[2]}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="columns is-centered p-0 m-0">
                              {MapChoices[0]}
                              {MapChoices[1]}
                              {MapChoices[2]}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="columns p-0 m-0 is-multiline is-centered">
                        {MapChoices}
                      </div>
                    )}
              </>
            )}
          </div>
        )}

        {explanationState && (
          <>
            <div
              className={`${isLoading ? "is-hidden" : ""
                } question-container is-flex is-flex-direction-column has-text-kurio-main-color-black`}
              style={{
                padding: "1.5rem",
                gap: "1.5rem",
              }}
            >
              <div
                className="tag has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
                style={{
                  borderRadius: "1rem",
                  fontSize: "1.25rem",
                  margin: "0 auto",
                }}
              >
                <p
                  style={{
                    fontWeight: 700,
                  }}
                >
                  Đáp án
                </p>
              </div>

              {checkImage(questionChoices[0]) ? (
                <>
                  <McqImageWrapSolution />
                </>
              ) : (
                <>
                  {questionChoices.length === 4 &&
                    sumWithButton("TEXT", widthButton, 4) >= maxWidth ? (
                    <div>
                      <div className="columns is-centered m-0">
                        {MapChoicesShowSolution[0]}
                        {MapChoicesShowSolution[1]}
                      </div>
                      <div className="columns is-centered m-0">
                        {MapChoicesShowSolution[2]}
                        {MapChoicesShowSolution[3]}
                      </div>
                    </div>
                  ) : // if questionChoices has length 5, render the choices in a 3x2 grid
                    questionChoices.length === 5 ? (
                      <>
                        {sumWithButton("TEXT", widthButton, 5) > maxWidth ? (
                          <div>
                            <div className="columns is-centered m-0">
                              {MapChoicesShowSolution[0]}
                              {MapChoicesShowSolution[1]}
                            </div>
                            <div className="columns is-centered m-0">
                              {MapChoicesShowSolution[2]}
                              {MapChoicesShowSolution[3]}
                            </div>
                            <div className="columns is-centered m-0">
                              {MapChoicesShowSolution[4]}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="columns is-centered m-0">
                              {MapChoicesShowSolution[0]}
                              {MapChoicesShowSolution[1]}
                              {MapChoicesShowSolution[2]}
                            </div>
                            <div className="columns is-centered m-0">
                              {MapChoicesShowSolution[3]}
                              {MapChoicesShowSolution[4]}
                            </div>
                          </div>
                        )}
                      </>
                    ) : // if questionChoices has length 6, render the choices in a 3x3 grid
                      questionChoices.length === 6 ? (
                        <>
                          {sumWithButton("TEXT", widthButton, 6) > maxWidth ? (
                            <div>
                              <div className="columns is-centered m-0">
                                {MapChoicesShowSolution[0]}
                                {MapChoicesShowSolution[1]}
                              </div>
                              <div className="columns is-centered m-0">
                                {MapChoicesShowSolution[2]}
                                {MapChoicesShowSolution[3]}
                              </div>
                              <div className="columns is-centered m-0">
                                {MapChoicesShowSolution[4]}
                                {MapChoicesShowSolution[5]}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="columns is-centered m-0">
                                {MapChoicesShowSolution[0]}
                                {MapChoicesShowSolution[1]}
                                {MapChoicesShowSolution[2]}
                              </div>
                              <div className="columns is-centered m-0">
                                {MapChoicesShowSolution[3]}
                                {MapChoicesShowSolution[4]}
                                {MapChoicesShowSolution[5]}
                              </div>
                            </div>
                          )}
                        </>
                      ) : questionChoices.length === 3 ? (
                        <>
                          {sumWithButton("TEXT", widthButton, 3) > maxWidth ? (
                            <div>
                              <div className="columns is-centered p-0 m-0">
                                {MapChoicesShowSolution[0]}
                                {MapChoicesShowSolution[1]}
                              </div>
                              <div className="columns is-centered p-0 m-0">
                                {MapChoicesShowSolution[2]}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="columns is-centered p-0 m-0">
                                {MapChoicesShowSolution[0]}
                                {MapChoicesShowSolution[1]}
                                {MapChoicesShowSolution[2]}
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="columns p-0 m-0 is-multiline is-centered">
                          {MapChoicesShowSolution}
                        </div>
                      )}
                </>
              )}

              {(explanationTexts !== null &&
                explanationTexts !== undefined &&
                explanationTexts?.length > 0) ||
                (explanationImages !== null &&
                  explanationImages !== undefined &&
                  explanationImages?.length > 0) ? (
                <div
                  className="explanation-container has-text-kurio-main-color-black is-flex is-flex-direction-column is-align-items-center"
                  style={{
                    gap: "1.5rem",
                    fontSize: "1.25rem",
                  }}
                >
                  <div
                    className="tag has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
                    style={{
                      borderRadius: "1rem",
                      fontSize: "1.25rem",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      Giải thích
                    </p>
                  </div>
                  <div
                    className="is-flex is-flex-direction-column is-align-items-center"
                    style={{
                      gap: "1.5rem",
                      fontSize: "1.25rem",
                      padding: "1rem 1.5rem",
                      width: "100%",
                    }}
                  >
                    <>
                      {((explanationTexts !== null &&
                        explanationTexts !== undefined &&
                        explanationTexts?.length > 0) ||
                        (explanationImages !== null &&
                          explanationImages !== undefined &&
                          explanationImages?.length > 0)) &&
                        explanation.texts?.length >= explanation.images?.length && (
                          <>
                            {explanation.texts.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="is-flex is-flex-direction-column has-text-justified has-text-kurio-main-color-black"
                                  style={{
                                    gap: "1.5rem",
                                    width: "100%",
                                  }}
                                >
                                  {explanationTexts !== null &&
                                    explanationTexts !== undefined &&
                                    item?.length > 0 && (
                                      <div key={index}>
                                        {explanationTexts[index]}
                                      </div>
                                    )}
                                  {/* only appears when explanationImages has length greater than 0 */}
                                  {explanationImages !== null &&
                                    explanationImages !== undefined &&
                                    explanationImages[index]?.length > 0 && (
                                      <div
                                        className="column is-narrow is-flex is-justify-content-center is-align-items-center is-centered m-0 p-0"
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <ImageFromUrl
                                          objectId={explanationImages[index]}
                                        />
                                      </div>
                                    )}
                                </div>
                              );
                            })}
                          </>
                        )
                      }
                      {((explanationTexts !== null &&
                        explanationTexts !== undefined &&
                        explanationTexts?.length > 0) ||
                        (explanationImages !== null &&
                          explanationImages !== undefined &&
                          explanationImages?.length > 0)) &&
                        explanation.texts?.length < explanation.images?.length && (
                          <>
                            {explanationImages.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="is-flex is-flex-direction-column has-text-justified has-text-kurio-main-color-black"
                                  style={{ gap: "1.5rem", width: "100%", }}
                                >
                                  {explanationTexts !== null &&
                                    explanationTexts !== undefined &&
                                    explanation.texts[index]?.length > 0 && (
                                      <div key={index}>
                                        {explanationTexts[index]}
                                      </div>
                                    )}
                                  {/* only appears when explanationImages has length greater than 0 */}
                                  {explanationImages !== null &&
                                    explanationImages !== undefined &&
                                    item?.length > 0 && (
                                      <div
                                        className="column is-narrow is-flex is-justify-content-center is-align-items-center is-centered m-0 p-0"
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <ImageFromUrl
                                          objectId={explanationImages[index]}
                                        />
                                      </div>
                                    )}
                                </div>
                              );
                            })}
                          </>
                        )}
                    </>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        )}

        {/* only appears when explanationState is true */}

        <div
          className={` is-flex is-flex-direction-row ${!isPreview
            ? !question.state
              ? checkingState &&
                questionSolutions[selected] === true &&
                explanation?.texts?.every((item) => item?.length <= 0) &&
                explanation?.images?.every((item) => item?.length <= 0)
                ? "is-align-items-center is-justify-content-center"
                : "is-align-items-center is-justify-content-center"
              : questionIndex < seenQuestions - 1
                ? "is-align-items-center is-justify-content-center"
                : checkingState &&
                  questionSolutions[selected] === true &&
                  explanation?.texts?.every((item) => item?.length <= 0) &&
                  explanation?.images?.every((item) => item?.length <= 0)
                  ? "is-align-items-center is-justify-content-center"
                  : "is-align-items-center is-justify-content-center"
            : "is-align-items-center is-justify-content-center"
            }`}
          style={{
            padding: "0 2rem 2rem",
            gap: "1rem",
          }}
        >
          {/* only appears when checkingState is false */}
          {!checkingState && (
            <PrimaryButtonQuestion
              className="button"
              disabled={selected === -1}
              onClick={handleCheck}
            >
              {ConstantStrings.SUBMIT}
            </PrimaryButtonQuestion>
          )}
          {/* only appears when checkingState is true and the selected answer is incorrect */}
          {/* {checkingState && questionSolutions[selected] === false && (
            <PrimaryButtonQuestion className="button" onClick={handleReset}>
              {ConstantStrings.RETRY}
            </PrimaryButtonQuestion>
          )} */}
          <div
            className="is-flex is-flex-direction-row is-align-items-flex-start"
            style={{ gap: "1rem" }}
          >
            {/* Next and "Xem giai thich" button appears when checkingState is true and selected answer is correct */}
            {checkingState && !explanationState && (
              <>
                <SecondaryButtonQuestion
                  defaultColor={"is-kurio-purple-light-2"}
                  onClick={handleShowExplanation}
                >
                  {ConstantStrings.SHOW_EXPLANATION_PRACTICE}
                </SecondaryButtonQuestion>
              </>
            )}
            {explanationState && (
              <SecondaryButtonQuestion
                defaultColor={"is-kurio-purple-light-2"}
                onClick={() => {
                  // setLocalStorage(selected, checkingState, false);
                  setExplanationState(false);
                }}
              >
                {ConstantStrings.SHOW_QUESTION}
              </SecondaryButtonQuestion>
            )}
          </div>
          {checkingState && (
            <div
              className={` ${question.state && questionIndex < seenQuestions - 1
                }`}
            >
              <PrimaryButtonQuestion onClick={showNextQuestion}>
                {ConstantStrings.NEXT}
              </PrimaryButtonQuestion>
            </div>
          )}
          {!checkingState && (
            <div
              className={` ${question.state &&
                questionIndex < seenQuestions - 1 &&
                !isPreview
                ? "is-hidden"
                : ""
                }`}
            >
              <TertiaryButtonQuestion
                onClick={showNextQuestion}
                rotate={true}
                suffix={"admin/frontend-images/button/button-skip-icon"}
              >
                {ConstantStrings.SKIP}
              </TertiaryButtonQuestion>
            </div>
          )}
        </div>
      </BackgroundImageFromUrl>
    </div>
  );
}

export default MultipleChoice;
