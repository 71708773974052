import React, { useState } from "react";
import ReactPlayer from "react-player";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import PopupCloseButton from "../../components/button/special-button/PopupCloseButton";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import ScrollRef from "../../detail-components/ScrollRef";
import "../../styling/layout-components/MainContentVideo.styles.css";
import jsonParser from "../helper/jsonParser";
import MainContent1Title from "./MainContent1Title";

const MainContent7VideoPopupThumbnail = ({ data, isLast }) => {
  const content = data?.content;
  const contentText = content?.texts[0]
    ? jsonParser(content?.texts[0])
    : content?.texts[0];
  const contentVideo = content?.video[0];
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = () => {
    setShowModal(true);
  };

  const removeVideo = () => {
    setShowModal(false);
  };

  return (
    <div className="is-relative has-text-centered">
      {!isLast && <ScrollRef />}

      {showModal && (
        <div>
          <div className="modal is-active m-auto">
            <div
              className="is-relative"
              style={{
                maxWidth: "67.5rem",
                width: "100%",
                zIndex: 1000,
              }}
            >
              <ReactPlayer
                style={{
                  margin: "0 auto",
                  position: "relative",
                  padding: "0",
                  zIndex: 9998,
                  maxWidth: "67.5rem",
                  aspectRatio: "16/9",
                }}
                width="100%"
                height="auto"
                playing={true}
                url={contentVideo}
                controls={true}
              />
              <div
                onClick={removeVideo}
                className=""
                style={{
                  position: "absolute",
                  top: "-8%",
                  left: "-4%",
                  zIndex: 10000,
                  background: "transparent",
                  border: "none",
                  width: "5rem",
                  fontSize: "2rem",
                }}
              >
                <PopupCloseButton />
              </div>
            </div>
            <div
              className="modal-background"
              onClick={removeVideo}
              style={{ opacity: "60%" }}
            ></div>
          </div>
        </div>
      )}

      <BackgroundImageFromUrl
        objectId={content?.backgroundImage}
        className="m-auto columns has-background-kurio-main-color-white is-align-items-center "
        style={{
          maxWidth: "60rem",
          borderRadius: "1rem",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          width: "100%",
        }}
      >
        <div
          className="main-content-left column is-half has-text-left has-text-kurio-main-color-black is-flex is-flex-direction-column"
          style={{
            padding: "0 2.5rem",
            gap: "2rem",
          }}
        >
          {data?.title && (
            <MainContent1Title
              style={{
                margin: "0 auto",
                fontSize: "1.5rem",
                textAlign: "start",
              }}
              title={data?.title}
            />
          )}
          <div>{contentText}</div>
          <PrimaryButtonPageLevel
            style={{ height: "4rem", borderRadius: "2rem" }}
            onClick={handleSubmit}
            prefix={"images/button/video.svg"}
          >
            xem video
          </PrimaryButtonPageLevel>
        </div>
        <ImageFromUrl
          className="main-content-right column is-half p-0"
          style={{
            width: "50%",
            borderRadius: "0 1rem 1rem 0",
          }}
          objectId={content?.images[0]}
        />
      </BackgroundImageFromUrl>
    </div>
  );
};

export default MainContent7VideoPopupThumbnail;
