/* eslint-disable react-hooks/exhaustive-deps */
// a React template for Gapfill type question

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
// import { setCorrectAnswer, setSkippedQuestions, setWrongAnswer } from "../../reducers/practiceQuestionSlice";
// import { setQuestionState } from "../../reducers/questionsSlice";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import TertiaryButtonQuestion from "../../components/button/tertiary-button/TertiaryButtonQuestion";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import GapFill from "../../components/question/GapFill";
import { checkImage } from "../../constants/checkImage";
import ConstantStrings from "../../constants/ConstantStrings";
import {
  selectQuizEvents,
  setQuestionFinished
} from "../../reducers/dailyMissionSlice";
import { cachesQuestion, getCachesQuestion } from "../helper/cacheQuestion";
import jsonParser from "../helper/jsonParser";

function Gapfill({
  question,
  questions,
  explanation,
  backgroundImage,
  questionIndex,
  questionId,
  handleQuestionChange,
  submitAnswers,
  setNextScroll,
  selectedQuestion,
  isPreview,
  seenQuestions,
}) {
  const cache = getCachesQuestion("daily-mission", questionIndex);
  var questionTexts =
    question.texts !== null &&
      question.texts !== undefined &&
      question.texts?.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;
  var questionChoices = question.choices;
  var questionSolutions = question.solutions;
  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      explanation.texts.some((item) => item?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      explanation.images.some((item) => item?.length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }
  var backgroundStyle = {
    borderRadius: "0 0 1rem 1rem",
    maxWidth: "60rem",
  };
  if (
    backgroundImage !== null &&
    backgroundImage &&
    checkImage(backgroundImage)
  ) {
    backgroundStyle = {
      ...backgroundStyle,
      // backgroundImage: `url(${memoizedImageData[2]})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  }
  const cookies = new Cookies();
  const answerQuizEvent = useSelector(selectQuizEvents);

  const dispatch = useDispatch();

  const [answersItem] = useState(() => {
    return questions
      .filter((question) => question.questionIndex === questionIndex)
      .map((item) => item.answer)
      .flat();
  });

  const [answers, setAnswers] = useState(() => {
    if (questions[selectedQuestion].correct !== null) {
      if (question.state) {
        return question.state.answers;
      } else {
        if (answersItem && answersItem.length > 0) {
          return answersItem;
        } else {
          return [];
        }
      }
    } else {
      return [];
    }
  });

  const [correctAnswers, setCorrectAnswers] = useState(() => {
    if (questions[selectedQuestion].correct !== null) {
      let newCorrectAnswers = [];
      for (let i = 0; i < questionSolutions.length; i++) {
        if (answersItem[i] === questionSolutions[i]) {
          newCorrectAnswers[i] = true;
        } else {
          newCorrectAnswers[i] = false;
        }
      }

      return newCorrectAnswers;
    } else {
      return [];
    }
  });

  const [correct] = useState(false);
  const [gapFillWithImages, setGapFillWithImages] = useState(false);
  const [explanationState] = useState(() => {
    if (question.state) {
      return question.state.explanationState;
    } else {
      return false;
    }
  });
  const [checkingState] = useState(() => {
    if (question.state) {
      return question.state.checkingState;
    } else {
      return false;
    }
  });

  const [maxWidth, setMaxWidth] = useState(() => {
    if (cache?.maxWidth) {
      return cache.maxWidth;
    } else {
      return 60;
    }
  });
  const divRef = useRef(null);

  const saveAnswer = (answers) => {
    if (!question.showSolution) {
      var questionState = {
        index: questionIndex,
        state: {
          answers: answers,
        },
      };
      var answer = questionChoices.map((item) => false);

      if (answers.length > 0) {
        answers.map((item, index) => (answer[index] = item));
      } else {
        answer = null;
      }

      if (
        answer.every((item) => item === "" || item === false || item === null)
      ) {
        answer = null;
      }

      var result = [...answerQuizEvent];
      var newAnswer = {
        questionId: questionId,
        answer: answer,
        questionIndex: questionIndex,
      };
      // setSelected(selected);
      if (result.length === 0) {
        result.push(newAnswer);
      } else {
        var flag = true;
        for (let question = 0; question < answerQuizEvent.length; question++) {
          if (answerQuizEvent[question].questionId === newAnswer.questionId) {
            flag = true;
            break;
          } else {
            flag = false;
          }
        }
        if (!flag) {
          result.push(newAnswer);
        } else {
          result = answerQuizEvent.map((question) =>
            question.questionId === questionId ? newAnswer : question
          );
        }
      }
      var questionsAnswered = result;
      const submit = { submit: false, questions: [newAnswer] };
      var questionAnswered = {
        questionIndex: questionIndex,
        status: true,
      };

      if (answer === null) {
        questionAnswered.status = false;
      }
      cookies.set("quizEventState", {
        questionState,
        questionAnswered,
        questionsAnswered,
        submit,
      });
    }
  };

  const handleChange = (index) => (e) => {
    e.preventDefault();
    var answer = questionSolutions.map((item) => false);
    let newAnswers = [...answers];
    if (newAnswers.length < index) {
      for (let i = 0; i < index; i++) {
        if (!newAnswers[i]) {
          newAnswers[i] = "";
        }
      }
    }

    newAnswers[index] = e.target.value;
    if (newAnswers.length > 0) {
      saveAnswer(newAnswers.map((item, index) => (answer[index] = item)));
    } else {
      saveAnswer(newAnswers);

      answer = null;
    }
    setAnswers(newAnswers);
  };

  const showNextQuestion = () => {
    setNextScroll(false)
    if (!isPreview) {
      dispatch(setQuestionFinished(questionIndex));
      if (questionIndex !== questions.length - 1) {
        handleQuestionChange(questionIndex + 1);
      } else {
        return;
      }
    } else {
      //   const exerciseState = JSON.parse(localStorage.getItem("exerciseState"));
      //   dispatch(setQuestionState(exerciseState.questionState));
      //   dispatch(setQuestionVisible(questionIndex + 1));
    }
  };

  const submitAnswerDailyMission = () => {
    setNextScroll(true)
    submitAnswers();
  };

  if (
    questionChoices !== null &&
    questionChoices &&
    questionChoices.length > 0 &&
    questionChoices[0] !== null &&
    questionChoices[0].length > 0
  ) {
    cachesQuestion("daily-mission", questionIndex, 0, maxWidth);
  }

  useEffect(() => {
    if (
      questionChoices !== null &&
      questionChoices &&
      questionChoices.length > 0 &&
      questionChoices[0] !== null &&
      questionChoices[0].length > 0
    ) {
      setGapFillWithImages(true);
    }
    if (
      divRef.current.clientWidth / ConstantStrings.BASE_REM !== maxWidth &&
      divRef.current.clientWidth / ConstantStrings.BASE_REM < 60
    ) {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    };

    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [questionChoices, maxWidth]);

  return (
    <div
      style={backgroundStyle}
      ref={divRef}
      className={`${!checkImage(backgroundImage)
        ? "has-background-kurio-main-color-light-gray"
        : ""
        }`}
    >
      {/* if gapFillWithImages is false
                if questionChoices has length 4, render the textinput in a 2x2 grid
                else use columns is-multiline is-centered
            */}
      {!explanationState && !gapFillWithImages && (
        <div
          className="question-container is-flex is-flex-direction-column has-text-kurio-main-color-black"
          style={{
            padding: "1.5rem 2rem",
            gap: "1rem",
          }}
        >
          {
            //render questionTexts if its array has length greater than 0
            questionTexts !== null &&
            questionTexts !== undefined &&
            questionTexts?.length > 0 && (
              <div
                className="has-text-centered m-auto"
                style={{ maxWidth: "40rem" }}
              >
                {questionTexts}
              </div>
            )
          }
          {questionChoices.length === 4 ? (
            <>
              {(10 + 1) * 4 + 4 >= maxWidth ? (
                <>
                  <div>
                    <div className="columns is-centered m-0 ">
                      <GapFill
                        index={0}
                        onChange={handleChange(0)}
                        answer={answers[0] || ""}
                        correctAnswers={correctAnswers[0]}
                        checkingState={
                          questions[selectedQuestion].correct !== null
                        }
                        choice={questionChoices[0]}
                      />
                      <GapFill
                        index={1}
                        onChange={handleChange(1)}
                        answer={answers[1] || ""}
                        correctAnswers={correctAnswers[1]}
                        checkingState={
                          questions[selectedQuestion].correct !== null
                        }
                        choice={questionChoices[1]}
                      />
                    </div>
                    <div className="columns is-centered m-0 ">
                      <GapFill
                        index={2}
                        onChange={handleChange(2)}
                        answer={answers[2] || ""}
                        correctAnswers={correctAnswers[2]}
                        checkingState={
                          questions[selectedQuestion].correct !== null
                        }
                        choice={questionChoices[2]}
                      />
                      <GapFill
                        index={3}
                        onChange={handleChange(3)}
                        answer={answers[3] || ""}
                        correctAnswers={correctAnswers[3]}
                        checkingState={
                          questions[selectedQuestion].correct !== null
                        }
                        choice={questionChoices[3]}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <div className="columns is-multiline is-centered m-0 ">
                      <GapFill
                        index={0}
                        onChange={handleChange(0)}
                        answer={answers[0] || ""}
                        correctAnswers={correctAnswers[0]}
                        checkingState={
                          questions[selectedQuestion].correct !== null
                        }
                        choice={questionChoices[0]}
                      />
                      <GapFill
                        index={1}
                        onChange={handleChange(1)}
                        answer={answers[1] || ""}
                        correctAnswers={correctAnswers[1]}
                        checkingState={
                          questions[selectedQuestion].correct !== null
                        }
                        choice={questionChoices[1]}
                      />
                      <GapFill
                        index={2}
                        onChange={handleChange(2)}
                        answer={answers[2] || ""}
                        correctAnswers={correctAnswers[2]}
                        checkingState={
                          questions[selectedQuestion].correct !== null
                        }
                        choice={questionChoices[2]}
                      />
                      <GapFill
                        index={3}
                        onChange={handleChange(3)}
                        answer={answers[3] || ""}
                        correctAnswers={correctAnswers[3]}
                        checkingState={
                          questions[selectedQuestion].correct !== null
                        }
                        choice={questionChoices[3]}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {questionChoices.length === 6 ? (
                <>
                  {(10 + 1) * 3 + 4 <= maxWidth ? (
                    <>
                      <div>
                        <div className="columns is-centered m-0 ">
                          <GapFill
                            index={0}
                            onChange={handleChange(0)}
                            answer={answers[0] || ""}
                            correctAnswers={correctAnswers[0]}
                            checkingState={
                              questions[selectedQuestion].correct !== null
                            }
                            choice={questionChoices[0]}
                          />
                          <GapFill
                            index={1}
                            onChange={handleChange(1)}
                            answer={answers[1] || ""}
                            correctAnswers={correctAnswers[1]}
                            checkingState={
                              questions[selectedQuestion].correct !== null
                            }
                            choice={questionChoices[1]}
                          />
                          <GapFill
                            index={2}
                            onChange={handleChange(2)}
                            answer={answers[2] || ""}
                            correctAnswers={correctAnswers[2]}
                            checkingState={
                              questions[selectedQuestion].correct !== null
                            }
                            choice={questionChoices[2]}
                          />
                        </div>
                        <div className="columns is-centered m-0 ">
                          <GapFill
                            index={3}
                            onChange={handleChange(3)}
                            answer={answers[3] || ""}
                            correctAnswers={correctAnswers[3]}
                            checkingState={
                              questions[selectedQuestion].correct !== null
                            }
                            choice={questionChoices[3]}
                          />
                          <GapFill
                            index={4}
                            onChange={handleChange(4)}
                            answer={answers[4] || ""}
                            correctAnswers={correctAnswers[4]}
                            checkingState={
                              questions[selectedQuestion].correct !== null
                            }
                            choice={questionChoices[4]}
                          />
                          <GapFill
                            index={5}
                            onChange={handleChange(5)}
                            answer={answers[5] || ""}
                            correctAnswers={correctAnswers[5]}
                            checkingState={
                              questions[selectedQuestion].correct !== null
                            }
                            choice={questionChoices[5]}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="columns is-multiline is-centered m-0 ">
                        {questionChoices.map((choice, index) => {
                          return (
                            <GapFill
                              key={index}
                              index={index}
                              onChange={handleChange(index)}
                              answer={answers[index] || ""}
                              correctAnswers={correctAnswers[index]}
                              checkingState={
                                questions[selectedQuestion].correct !== null
                              }
                              choice={questionChoices[index]}
                            />
                          );
                        })}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="columns is-multiline is-centered m-0">
                    {questionChoices.map((choice, index) => {
                      return (
                        <GapFill
                          key={index}
                          index={index}
                          onChange={handleChange(index)}
                          answer={answers[index] || ""}
                          correctAnswers={correctAnswers[index]}
                          checkingState={
                            questions[selectedQuestion].correct !== null
                          }
                          choice={questionChoices[index]}
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
      {!explanationState && gapFillWithImages && (
        <div
          className="question-container is-flex is-flex-direction-column has-text-kurio-main-color-black"
          style={{
            padding: "1.5rem 2rem",
            gap: "1rem",
          }}
        >
          {
            //render questionTexts if its array has length greater than 0
            questionTexts !== null &&
            questionTexts !== undefined &&
            questionTexts?.length > 0 && (
              <div
                className="has-text-centered m-auto"
                style={{ maxWidth: "40rem" }}
              >
                {questionTexts}
              </div>
            )
          }
          {questionChoices.length === 4 ? (
            <>
              {(3 + 10 + 1 + 1) * 4 + 2 * 3 + 4 >= maxWidth ? (
                <>
                  <div
                    className="columns is-centered m-0 "
                    style={{
                      gap: "2rem",
                    }}
                  >
                    <GapFill
                      index={0}
                      onChange={handleChange(0)}
                      answer={answers[0] || ""}
                      correctAnswers={correctAnswers[0]}
                      checkingState={
                        questions[selectedQuestion].correct !== null
                      }
                      choice={questionChoices[0]}
                    />
                    <GapFill
                      index={1}
                      onChange={handleChange(1)}
                      answer={answers[1] || ""}
                      correctAnswers={correctAnswers[1]}
                      checkingState={
                        questions[selectedQuestion].correct !== null
                      }
                      choice={questionChoices[1]}
                    />
                  </div>
                  <div
                    className="columns is-centered m-0 "
                    style={{
                      gap: "2rem",
                    }}
                  >
                    <GapFill
                      index={2}
                      onChange={handleChange(2)}
                      answer={answers[2] || ""}
                      correctAnswers={correctAnswers[2]}
                      checkingState={
                        questions[selectedQuestion].correct !== null
                      }
                      choice={questionChoices[2]}
                    />
                    <GapFill
                      index={3}
                      onChange={handleChange(3)}
                      answer={answers[3] || ""}
                      correctAnswers={correctAnswers[3]}
                      checkingState={
                        questions[selectedQuestion].correct !== null
                      }
                      choice={questionChoices[3]}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="columns is-centered m-0 "
                    style={{
                      gap: "2rem",
                    }}
                  >
                    {questionChoices.map((choice, index) => {
                      return (
                        <GapFill
                          key={index}
                          index={index}
                          onChange={handleChange(index)}
                          answer={answers[index] || ""}
                          correctAnswers={correctAnswers[index]}
                          checkingState={
                            questions[selectedQuestion].correct !== null
                          }
                          choice={questionChoices[index]}
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {questionChoices.length === 6 ? (
                <>
                  {(3 + 10 + 1 + 1) * 3 + 2 * 2 + 4 < maxWidth ? (
                    <>
                      <div
                        className="columns is-centered m-0 "
                        style={{
                          gap: "2rem",
                        }}
                      >
                        <GapFill
                          index={0}
                          onChange={handleChange(0)}
                          answer={answers[0] || ""}
                          correctAnswers={correctAnswers[0]}
                          checkingState={
                            questions[selectedQuestion].correct !== null
                          }
                          choice={questionChoices[0]}
                        />
                        <GapFill
                          index={1}
                          onChange={handleChange(1)}
                          answer={answers[1] || ""}
                          correctAnswers={correctAnswers[1]}
                          checkingState={
                            questions[selectedQuestion].correct !== null
                          }
                          choice={questionChoices[1]}
                        />
                        <GapFill
                          index={2}
                          onChange={handleChange(2)}
                          answer={answers[2] || ""}
                          correctAnswers={correctAnswers[2]}
                          checkingState={
                            questions[selectedQuestion].correct !== null
                          }
                          choice={questionChoices[2]}
                        />
                      </div>
                      <div
                        className="columns is-centered m-0 "
                        style={{
                          gap: "2rem",
                        }}
                      >
                        <GapFill
                          index={3}
                          onChange={handleChange(3)}
                          answer={answers[3] || ""}
                          correctAnswers={correctAnswers[3]}
                          checkingState={
                            questions[selectedQuestion].correct !== null
                          }
                          choice={questionChoices[3]}
                        />
                        <GapFill
                          index={4}
                          onChange={handleChange(4)}
                          answer={answers[4] || ""}
                          correctAnswers={correctAnswers[4]}
                          checkingState={
                            questions[selectedQuestion].correct !== null
                          }
                          choice={questionChoices[4]}
                        />
                        <GapFill
                          index={5}
                          onChange={handleChange(5)}
                          answer={answers[5] || ""}
                          correctAnswers={correctAnswers[5]}
                          checkingState={
                            questions[selectedQuestion].correct !== null
                          }
                          choice={questionChoices[5]}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="columns is-centered m-0 "
                        style={{
                          gap: "2rem",
                        }}
                      >
                        <GapFill
                          index={0}
                          onChange={handleChange(0)}
                          answer={answers[0] || ""}
                          correctAnswers={correctAnswers[0]}
                          checkingState={
                            questions[selectedQuestion].correct !== null
                          }
                          choice={questionChoices[0]}
                        />
                        <GapFill
                          index={1}
                          onChange={handleChange(1)}
                          answer={answers[1] || ""}
                          correctAnswers={correctAnswers[1]}
                          checkingState={
                            questions[selectedQuestion].correct !== null
                          }
                          choice={questionChoices[1]}
                        />
                      </div>
                      <div
                        className="columns is-centered m-0 "
                        style={{
                          gap: "2rem",
                        }}
                      >
                        <GapFill
                          index={2}
                          onChange={handleChange(2)}
                          answer={answers[2] || ""}
                          correctAnswers={correctAnswers[2]}
                          checkingState={
                            questions[selectedQuestion].correct !== null
                          }
                          choice={questionChoices[2]}
                        />
                        <GapFill
                          index={3}
                          onChange={handleChange(3)}
                          answer={answers[3] || ""}
                          correctAnswers={correctAnswers[3]}
                          checkingState={
                            questions[selectedQuestion].correct !== null
                          }
                          choice={questionChoices[3]}
                        />
                      </div>
                      <div
                        className="columns is-centered m-0 "
                        style={{
                          gap: "2rem",
                        }}
                      >
                        <GapFill
                          index={4}
                          onChange={handleChange(4)}
                          answer={answers[4] || ""}
                          correctAnswers={correctAnswers[4]}
                          checkingState={
                            questions[selectedQuestion].correct !== null
                          }
                          choice={questionChoices[4]}
                        />
                        <GapFill
                          index={5}
                          onChange={handleChange(5)}
                          answer={answers[5] || ""}
                          correctAnswers={correctAnswers[5]}
                          checkingState={
                            questions[selectedQuestion].correct !== null
                          }
                          choice={questionChoices[5]}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div
                  className="columns m-0 is-multiline is-centered"
                  style={{ gap: "2rem" }}
                >
                  {questionChoices.map((choice, index) => {
                    return (
                      <GapFill
                        key={index}
                        index={index}
                        onChange={handleChange(index)}
                        answer={answers[index] || ""}
                        correctAnswers={correctAnswers[index]}
                        checkingState={
                          questions[selectedQuestion].correct !== null
                        }
                        choice={questionChoices[index]}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
      )}
      {explanationState && (
        <div
          className="explanation-container has-text-kurio-main-color-black is-flex is-flex-direction-column is-align-items-center"
          style={{
            gap: "1.5rem",
            fontSize: "1.25rem",
            padding: " 2rem 2rem 1.5rem",
          }}
        >
          {((explanationTexts !== null &&
            explanationTexts !== undefined &&
            explanationTexts?.length > 0) ||
            (explanationImages !== null &&
              explanationImages !== undefined &&
              explanationImages?.length > 0)) &&
            explanation.texts?.length >= explanation.images?.length && (
              <>
                {explanation.texts.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="is-flex has-text-justified is-flex-direction-column has-text-kurio-main-color-black"
                      style={{ gap: "1.5rem", width: "100%" }}
                    >
                      {explanationTexts !== null &&
                        explanationTexts !== undefined &&
                        item?.length > 0 && (
                          <div key={index}>{explanationTexts[index]}</div>
                        )}
                      {/* only appears when explanationImages has length greater than 0 */}
                      {explanationImages !== null &&
                        explanationImages !== undefined &&
                        explanationImages[index]?.length > 0 && (
                          <div
                            className="column is-narrow is-flex is-justify-content-center is-align-items-center is-centered m-0 p-0"
                            style={{
                              width: "100%",
                            }}
                          >
                            <ImageFromUrl objectId={explanationImages[index]} />
                          </div>
                        )}
                    </div>
                  );
                })}
              </>
            )}
          {((explanationTexts !== null &&
            explanationTexts !== undefined &&
            explanationTexts?.length > 0) ||
            (explanationImages !== null &&
              explanationImages !== undefined &&
              explanationImages?.length > 0)) &&
            explanation.texts?.length < explanation.images?.length && (
              <>
                {explanationImages.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="is-flex has-text-justified is-flex-direction-column has-text-kurio-main-color-black"
                      style={{ gap: "1.5rem", width: "100%" }}
                    >
                      {explanationTexts !== null &&
                        explanationTexts !== undefined &&
                        explanation.texts[index]?.length > 0 && (
                          <div key={index}>{explanationTexts[index]}</div>
                        )}
                      {/* only appears when explanationImages has length greater than 0 */}
                      {explanationImages !== null &&
                        explanationImages !== undefined &&
                        item?.length > 0 && (
                          <div
                            className="column is-narrow is-flex is-justify-content-center is-align-items-center is-centered m-0 p-0"
                            style={{
                              width: "100%",
                            }}
                          >
                            <ImageFromUrl objectId={explanationImages[index]} />
                          </div>
                        )}
                    </div>
                  );
                })}
              </>
            )}
        </div>
      )}

      <div
        className={` is-flex is-flex-direction-row ${!isPreview
          ? !question.state
            ? checkingState &&
              correct &&
              explanation?.texts?.every((item) => item?.length <= 0) &&
              explanation?.images?.every((item) => item?.length <= 0)
              ? "is-align-items-center is-justify-content-center"
              : "is-align-items-center is-justify-content-center"
            : questionIndex < seenQuestions - 1
              ? "is-align-items-center is-justify-content-center"
              : checkingState &&
                correct &&
                explanation?.texts?.every((item) => item?.length <= 0) &&
                explanation?.images?.every((item) => item?.length <= 0)
                ? "is-align-items-center is-justify-content-center"
                : "is-align-items-center is-justify-content-center"
          : "is-align-items-center is-justify-content-center"
          } `}
        style={{
          padding: "0 2rem 2rem",
          gap: "2rem",
        }}
      >
        <div
          className="is-flex is-flex-direction-row is-align-items-flex-start p-0"
          style={{ gap: "1rem" }}
        >
          {/* only appears when checkingState is false */}
          {questions[selectedQuestion].correct === null && (
            <PrimaryButtonQuestion
              onClick={submitAnswerDailyMission}
              disabled={
                answers?.some((item) => item === undefined || item === "") ||
                answers?.length < questionSolutions?.length
              }
            >
              {ConstantStrings.SUBMIT}
            </PrimaryButtonQuestion>
          )}
        </div>

        {questions[selectedQuestion].correct !== null &&
          questionIndex !== questions.length - 1 && (
            <div
              className={` ${question.state &&
                questionIndex < seenQuestions - 1 &&
                !isPreview
                ? "is-hidden"
                : ""
                }`}
            >
              <PrimaryButtonQuestion onClick={showNextQuestion}>
                {ConstantStrings.NEXT}
              </PrimaryButtonQuestion>
            </div>
          )}
        {questions[selectedQuestion].correct === null &&
          questionIndex !== questions.length - 1 && (
            <div
              className={` ${question.state &&
                questionIndex < seenQuestions - 1 &&
                !isPreview
                ? "is-hidden"
                : ""
                }`}
            >
              <TertiaryButtonQuestion
                style={{ maxWidth: true }}
                onClick={showNextQuestion}
                suffix={
                  "admin/frontend-images/button/button-skip-icon-daily-quiz"
                }
              >
                {ConstantStrings.SKIP_DAILY_QUIZ_EVENT}
              </TertiaryButtonQuestion>
            </div>
          )}
      </div>
    </div>
  );
}

export default Gapfill;
