import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import ConstantStrings from "../../constants/ConstantStrings";

const ReCaptChaV2 = ({handleChangeCaptcha, handleSubmit}) => {
  const handleCaptcha = (token) => {
    // Xử lý token ở đây'
    handleChangeCaptcha(token)
    // handleSubmit()
  };

  return (
    <div
      className="m-auto is-flex is-flex-direction-column is-align-items-center has-background-kurio-main-color-white has-text-centered"
      style={{
        maxWidth: "40rem",
        height: "10rem",
        borderRadius: "1rem",
        padding: "2.6875rem ",
      }}
    >
      <ReCAPTCHA
        sitekey={ConstantStrings.DATA_SITE_KEY}
        onChange={handleCaptcha}
        size="normal"
        render="explicit"
        hl="vi"
        difficulty="hard"
        theme="light"
        tabindex={0}
        type="image"
      />
    </div>
  );
};

export default ReCaptChaV2;
