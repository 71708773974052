/* eslint-disable react/jsx-pascal-case */
import React, { memo } from "react";
import QPrompt1_1 from "../../../templates/content/QPrompt1_1";
import QPrompt1_2 from "../../../templates/content/QPrompt1_2";
import QPrompt1_3 from "../../../templates/content/QPrompt1_3";
import QPrompt2_1 from "../../../templates/content/QPrompt2_1";
import QPrompt2_2 from "../../../templates/content/QPrompt2_2";
import QPrompt2_3 from "../../../templates/content/QPrompt2_3";
import QPrompt2_4 from "../../../templates/content/QPrompt2_4";
import QPrompt3_1 from "../../../templates/content/QPrompt3_1";
import QPrompt3_2 from "../../../templates/content/QPrompt3_2";
import DragDrop from "../../../templates/road-map/DragDrop";
import DragDropMulti from "../../../templates/road-map/DragDropMulti";
import Gapfill from "../../../templates/road-map/Gapfill";
import MultipleChoice from "../../../templates/road-map/MultipleChoice";
import MultipleResponse from "../../../templates/road-map/MultipleResponse";

export const ProblemSetRightWrapper = memo(
  function ProblemSetRightWrapper({
    question,
    seenQuestions,
    handleQuestionChange,
    questions,
    isPreview,
    takeExamsQuestionId,
  }) {
    const promptTemplate = question?.template.prompt;
    const questionTemplate = question?.template.question;
    const backgroundStyle = {
      backgroundSize: "cover",
      backgroundImage: `url(${question?.backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      borderRadius: "1rem",
    };

    const TemplateComponent = () => {
      switch (promptTemplate) {
        case "QPrompt1_1":
          return (
            <QPrompt1_1 data={question} order={question.questionIndex + 1} />
          );
        case "QPrompt1_2":
          return (
            <QPrompt1_2 data={question} order={question.questionIndex + 1} />
          );
        case "QPrompt1_3":
          return (
            <QPrompt1_3 data={question} order={question.questionIndex + 1} />
          );
        case "QPrompt2_1":
          return (
            <QPrompt2_1 data={question} order={question.questionIndex + 1} />
          );
        case "QPrompt2_2":
          return (
            <QPrompt2_2 data={question} order={question.questionIndex + 1} />
          );
        case "QPrompt2_3":
          return (
            <QPrompt2_3 data={question} order={question.questionIndex + 1} />
          );
        case "QPrompt2_4":
          return (
            <QPrompt2_4 data={question} order={question.questionIndex + 1} />
          );
        case "QPrompt3_1":
          return (
            <QPrompt3_1 data={question} order={question.questionIndex + 1} />
          );
        case "QPrompt3_2":
          return (
            <QPrompt3_2 data={question} order={question.questionIndex + 1} />
          );
        default:
          return <div></div>;
      }
    };

    const TemplateQuestion = () => {
      switch (questionTemplate) {
        case "MultipleChoice":
          return (
            <MultipleChoice
              IKMCMode={true}
              question={question.question}
              backgroundImage={question?.question.backgroundImage}
              questionIndex={question.questionIndex}
              isLast={question?.isLast}
              isPreview={isPreview}
              explanation={question.explanation}
              questions={questions}
              handleQuestionChange={handleQuestionChange}
              questionId={question.questionId}
              seenQuestions={seenQuestions}
              takeExamsQuestionId={takeExamsQuestionId}
            />
          );
        case "MultipleResponse":
          return (
            <MultipleResponse
              IKMCMode={true}
              isPreview={isPreview}
              question={question.question}
              backgroundImage={question?.question.backgroundImage}
              questionIndex={question.questionIndex}
              isLast={question?.isLast}
              explanation={question.explanation}
              handleQuestionChange={handleQuestionChange}
              questionId={question.questionId}
              seenQuestions={seenQuestions}
              questions={questions}
              takeExamsQuestionId={takeExamsQuestionId}
            />
          );
        case "Gapfill":
          return (
            <Gapfill
              IKMCMode={true}
              isPreview={isPreview}
              question={question.question}
              explanation={question.explanation}
              backgroundImage={question?.question.backgroundImage}
              questionIndex={question.questionIndex}
              isLast={question?.isLast}
              handleQuestionChange={handleQuestionChange}
              questionId={question.questionId}
              seenQuestions={seenQuestions}
              questions={questions}
              takeExamsQuestionId={takeExamsQuestionId}
            />
          );
        case "DragDrop":
          return (
            <DragDrop
              IKMCMode={true}
              isPreview={isPreview}
              question={question.question}
              explanation={question.explanation}
              questionIndex={question.questionIndex}
              isLast={question?.isLast}
              handleQuestionChange={handleQuestionChange}
              questionId={question.questionId}
              seenQuestions={seenQuestions}
              questions={questions}
              takeExamsQuestionId={takeExamsQuestionId}
            />
          );
        case "DragDropMulti":
          return (
            <DragDropMulti
              IKMCMode={true}
              isPreview={isPreview}
              question={question.question}
              explanation={question.explanation}
              questionIndex={question.questionIndex}
              isLast={question?.isLast}
              handleQuestionChange={handleQuestionChange}
              questionId={question.questionId}
              seenQuestions={seenQuestions}
              questions={questions}
              takeExamsQuestionId={takeExamsQuestionId}
            />
          );
        default:
          return null;
      }
    };

    return (
      <>
        <div
          className="is-centered"
          style={{
            ...backgroundStyle,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: "60rem",
          }}
        >
          <div
            style={{
              border: "1.5px solid #0A2A66",
              borderBottom: "none",
              boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
              borderRadius: "1rem 1rem 0rem 0rem"
            }}
          >
            <TemplateComponent />
          </div>
          <div
            style={{
              border: "1.5px solid #0A2A66",
              borderTop: "none",
              boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
              borderRadius: "0rem 0rem 1rem 1rem"
            }}
          >
            <TemplateQuestion />
          </div>
        </div>
      </>
    );
  }
);

export default ProblemSetRightWrapper;
