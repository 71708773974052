import { memo } from "react";
import { useDrop } from "react-dnd";
import BackgroundImageFromUrl from "../../../components/parseImage/BackgroundImageFromUrl";
import { Choice } from "./Choice";
const style = {
    height: "7.5rem",
    width: "9rem",
    color: "#FFFFFF",
    position: "relative",
    borderRadius: "0.5rem",
};
export const Target = memo(function Target({
    content,
    accept,
    lastDroppedItem,
    onDrop,
    resultStyle,
    iconStyle,
    choices,
    disable,
    dropOutside,
    index = -1,
}) {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept,
        drop: onDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const isActive = isOver && canDrop;
    let backgroundColor = "#8C9DC1";
    let borderColor = "#8C9DC1";
    if (isActive) {
        backgroundColor = "#572CD1";
        borderColor = "#572CD1";
    } else if (canDrop) {
        backgroundColor = "#D9E1F0";
    } else if (resultStyle?.backgroundColor) {
        backgroundColor = resultStyle.backgroundColor;
        borderColor = resultStyle.borderColor;
    }
    let currentItem = null;
    if (lastDroppedItem) {
        currentItem = choices.find((item) => item.name === lastDroppedItem);
    }
    return (
        <div
            className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
            style={{gap: "0.5rem", padding:"1rem" }}
        >
            {content && content.length && (
                <>
                    <BackgroundImageFromUrl objectId={content} style={{ ...style, backgroundColor:"#8C9DC1",
                        backgroundSize:"cover",
                        backgroundRepeat:"no-repeat",
                        outline:"0.25rem solid",
                        backgroundPosition:"center center", }}>
                        
                    </BackgroundImageFromUrl>
                    <div style={{
                      width:"0",
                      height:"1.5rem",
                      border:"0.125rem dashed",
                      borderRadius:"1rem",
                      borderColor
                    }}></div>
                </>
            )}

            <div
                ref={drop}
                style={
                    currentItem
                        ? {
                              ...style,
                              ...resultStyle,
                          }
                        : {
                              ...style,
                              outline: `${
                                  resultStyle?.backgroundColor
                                      ? "solid"
                                      : "dashed"
                              } 0.25rem `,
                              outlineColor: borderColor,
                          }
                }
            >
                <div
                    style={
                        currentItem
                            ? {
                                  ...style,
                                  ...resultStyle,
                              }
                            : {
                                  ...style,
                                //   width: "8.5rem",
                                //   height: "7rem",
                                  borderRadius: "0.25rem",
                                  backgroundColor,
                              }
                    }
                >
                    <div className="is-flex is-flex-direction-column is-align-items-center">
                        <div
                            style={
                                currentItem
                                    ? { height: "7.5rem", width: "9rem" }
                                    : { height: "7.5rem", width: "9rem" }
                            }
                        >
                            {currentItem && (
                                <Choice
                                    name={currentItem.name}
                                    type={currentItem.type}
                                    disable={disable}
                                    dropOutside={dropOutside}
                                    index={index}
                                    resultStyle={{
                                        outline: "0.25rem solid",
                                        outlineColor: resultStyle
                                            ? resultStyle.borderColor
                                            : "",
                                        backgroundColor:resultStyle?.backgroundColor
                                    }}
                                />
                            )}
                        </div>
                        {resultStyle && (
                            <div>
                                {resultStyle.borderColor === "#E34D4D" && (
                                    <img
                                        src="/images/common/redX.svg"
                                        alt="loading"
                                        style={iconStyle}
                                    />
                                )}
                                {resultStyle.borderColor === "#4B9631" && (
                                    <img
                                        src="/images/common/greenV.svg"
                                        alt="loading"
                                        style={iconStyle}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});
