import instance from "./instance";

const quizEventUrl = "/practice/v1/user/user-quiz-event";

// export const createPractice = (filter) => {
//   // checkAndCallDailyLoginAPI()
//   return instance.put(`${practiceUrl}/create-new-practice`, filter);
// };

export const getListCurrentEvent = (id) => {
  // checkAndCallDailyLoginAPI()
  return instance.get(`${quizEventUrl}/get-current-event`);
};

export const submitQuizEventAnswers = (id, answer) => {
  // checkAndCallDailyLoginAPI()
  return instance.post(`${quizEventUrl}/submit-answer/${id}`, answer);
};
