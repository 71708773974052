import React, { useEffect } from "react";

function ScrollRefWrapper() {
  const tagRef = React.useRef(null);
  //   const scrollToRef = () => tagRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 300);
  }, []);

  return <div ref={tagRef}></div>;
}

export default ScrollRefWrapper;
