/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { decodeToken } from "react-jwt";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { getAPracticeQuestions } from "../../reducers/practiceQuestionSlice";
import { LoadingImage } from "../../templates/helper/LoadingImage";
import { getAllImage } from "../../templates/helper/getAllImages";
import PreviewQuizMainWrapper from "./PreviewQuizMainWrapper";

const PreviewQuiz = ({ questions }) => {
  const navigate = useNavigate();
  const [loadingImage, setLoadingImage] = useState(false);
  const handleSetLoadingImage = (state) => {
    setLoadingImage(state);
  };
  // const cookies = new Cookies();
  // const token = cookies.get("signinUser");
  const dispatch = useDispatch();
  useEffect(() => {
    const getQuizPreview = async (practiceQuizId, token) => {
      try {
        if (localStorage.getItem("questionState") || localStorage.getItem("targets")) {
          localStorage.removeItem("questionState");
          localStorage.removeItem("targets");
        }
        dispatch(getAPracticeQuestions({ practiceQuizId, token }));
        setLoadingImage(true);
      } catch (error) {
        console.log("errr", error.response?.data?.errorMessage);
        alert(error.response?.data?.errorMessage);
      }
    };
    const arrayURL =
      process.env.REACT_APP_ENV === "development"
        ? JSON.parse(process.env.REACT_APP_DEV_ARRAY_ADMIN_URL)
        : JSON.parse(process.env.REACT_APP_ARRAY_ADMIN_URL);
    const handleRes = (e) => {
      if (arrayURL.some((url) => e.origin.includes(url))) {
        if (e?.data.quizId && e?.data.token) {
          var roles = ["CREATOR", "REVIEWER"];
          var check = decodeToken(e?.data.token).roles.some((role) =>
            roles.includes(role)
          );
          if (!check) {
            alert("Khong duoc vao");
            navigate("/");
          } else {
            // cookies.set("signinUser", e?.data.token);
            getQuizPreview(e?.data.quizId, e?.data.token);
          }
        }
      }
    };
    window.addEventListener("message", handleRes);
    return () => window.removeEventListener("message", handleRes);
  }, [dispatch, navigate]);
  if (loadingImage && questions !== null && questions?.length > 0) {
    const result = getAllImage(questions, "QUIZ");
    if (result && result?.length > 0) {
      return (
        <>
          <LoadingImage
            images={result}
            router={""}
            handleSetLoadingImage={(state) => handleSetLoadingImage(state)}
          />
        </>
      );
    }else {
      setLoadingImage(false);
    }
  }
  return (
    <>
      {!loadingImage && questions !== null && questions?.length > 0 && (
        <div className="m-auto">{<PreviewQuizMainWrapper />}</div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    questions: state.practiceQuestion.questions,
  };
}
export default connect(mapStateToProps)(PreviewQuiz);
