// a React template for Gapfill type question

import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import GapFill from "../../components/question/GapFill";
import ConstantStrings from "../../constants/ConstantStrings";
import { checkImage } from "../../constants/checkImage";
import { selectAnsweredQuestions } from "../../reducers/roadmapSlice";
import { cachesQuestion, getCachesQuestion } from "../helper/cacheQuestion";
import jsonParser from "../helper/jsonParser";

export const Gapfill = memo(function Gapfill({
  question,
  explanation,
  backgroundImage,
  questionIndex,
  questions,
  practiceQuizId,
  seenQuestions,
  isPreview,
  handleQuestionChange,
  questionId,
  IKMCMode,
}) {
  const cache = getCachesQuestion(questionId, questionIndex);

  var questionTexts =
    question.texts !== null &&
      question.texts !== undefined &&
      question.texts?.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;
  var questionChoices = question.choices;
  var questionSolutions = question.solutions;

  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      (explanation.texts[0]?.length > 0 || explanation.texts[1]?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      (explanation.images[0]?.length > 0 || explanation?.images[1]?.length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }

  // const cookies = new Cookies();
  const answeredQuestions = useSelector(selectAnsweredQuestions);

  var backgroundStyle = {
    borderRadius: "0 0 1rem 1rem",
  };
  if (backgroundImage !== null && backgroundImage) {
    backgroundStyle = {
      ...backgroundStyle,
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundStyle = {
      ...backgroundStyle,
      backgroundColor: "#E9EEF8",
    };
  }

  var nextButtonStyle = {
    width: "100%",
    maxWidth: "60rem",
    padding: "2rem 0 0 0",
  };
  if (IKMCMode) {
    nextButtonStyle = {
      ...nextButtonStyle,
      position: "absolute",
    };
  }

  const [answersItem] = useState(() => {
    return questions
      .filter((question) => question.questionIndex === questionIndex)
      .map((item) => item.answer)
      .flat();
  });

  const [explanationState] = useState(() => {
    if (question.state) {
      return question.state.explanationState;
    } else {
      return false;
    }
  });

  const [answers, setAnswers] = useState(() => {
    if (question.state) {
      return question.state.answers;
    } else {
      if (answersItem && answersItem.length > 0) {
        return answersItem;
      } else {
        return [];
      }
    }
  });

  const [correctAnswers] = useState(() => {
    if (question.showSolution || isPreview) {
      let newCorrectAnswers = [];
      for (let i = 0; i < questionSolutions.length; i++) {
        if (answersItem[i] === questionSolutions[i]) {
          newCorrectAnswers[i] = true;
        } else {
          newCorrectAnswers[i] = false;
        }
      }

      return newCorrectAnswers;
    } else {
      return [];
    }
  });

  const [gapFillWithImages, setGapFillWithImages] = useState(
    questionChoices !== null &&
      questionChoices &&
      questionChoices.length > 0 &&
      questionChoices[0] !== null &&
      questionChoices[0].length > 0
      ? true
      : false
  );
  const [maxWidth, setMaxWidth] = useState(() => {
    if (cache?.maxWidth) {
      return cache.maxWidth;
    } else {
      return 60;
    }
  });
  const [isLoading, setIsLoading] = useState(
    cache || !checkImage(questionChoices[0]) ? false : true
  );
  if (
    questionChoices !== null &&
    questionChoices &&
    questionChoices.length > 0 &&
    questionChoices[0] !== null &&
    questionChoices[0].length > 0
  ) {
    cachesQuestion(questionId, questionIndex, 0, maxWidth);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }
  const divRef = useRef(null);

  // check first time render if questionChoices are not empty strings
  useEffect(() => {
    if (
      questionChoices !== null &&
      questionChoices &&
      questionChoices.length > 0 &&
      questionChoices[0] !== null &&
      questionChoices[0].length > 0
    ) {
      setGapFillWithImages(true);
    }
    if (
      divRef.current.clientWidth / ConstantStrings.BASE_REM !== maxWidth &&
      divRef.current.clientWidth / ConstantStrings.BASE_REM < 60
    ) {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    };
    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [questionChoices, maxWidth]);

  const saveAnswer = (answers) => {
    if (!question.showSolution) {
      var questionState = {
        index: questionIndex,
        state: {
          answers: answers,
        },
      };
      var answer = questionChoices.map((item) => false);

      if (answers.length > 0) {
        answers.map((item, index) => (answer[index] = item));
      } else {
        answer = null;
      }

      if (
        answer.every((item) => item === "" || item === false || item === null)
      ) {
        answer = null;
      }

      var result = [...answeredQuestions];
      var newAnswer = {
        questionId: questionId,
        answer: answer,
        questionIndex: questionIndex,
      };
      // setSelected(selected);
      if (result.length === 0) {
        result.push(newAnswer);
      } else {
        var flag = true;
        for (
          let question = 0;
          question < answeredQuestions.length;
          question++
        ) {
          if (answeredQuestions[question].questionId === newAnswer.questionId) {
            flag = true;
            break;
          } else {
            flag = false;
          }
        }
        if (!flag) {
          result.push(newAnswer);
        } else {
          result = answeredQuestions.map((question) =>
            question.questionId === questionId ? newAnswer : question
          );
        }
      }
      var questionsAnswered = result;
      const submit = { submit: false, questions: [newAnswer] };
      var questionAnswered = {
        questionIndex: questionIndex,
        status: true,
      };

      if (answer === null) {
        questionAnswered.status = false;
      }
      const value = {
        questionState,
        questionAnswered,
        questionsAnswered,
        submit,
      };

      localStorage.setItem("questionStateExams", JSON.stringify(value));
    }
  };

  const handleChange = (index) => (e) => {
    e.preventDefault();
    let newAnswers = [...answers];
    newAnswers[index] = e.target.value;
    saveAnswer(newAnswers);
    setAnswers(newAnswers);
  };

  const showNextQuestion = () => {
    if (questionIndex !== questions.length - 1) {
      handleQuestionChange(questionIndex + 1);
    } else {
      return;
    }
  };

  const showPreviousQuestion = () => {
    handleQuestionChange(questionIndex - 1);
  };

  return (
    <>
      <div className="is-relative" style={backgroundStyle} ref={divRef}>
        <BackgroundImageFromUrl
          style={backgroundStyle}
          objectId={backgroundImage}
        >
          {/* if gapFillWithImages is false
        if questionChoices has length 4, render the textinput in a 2x2 grid
        else use columns is-multiline is-centered
    */}
          {isLoading && (
            <div className="has-text-kurio-main-color-black has-text-centered">
              Loading
            </div>
          )}
          {!explanationState && !gapFillWithImages && (
            <>
              <div
                className={`${isLoading ? "is-hidden" : ""
                  } question-container is-flex is-flex-direction-column`}
                style={{
                  padding: "1.5rem 2rem",
                  gap: "1rem",
                }}
              >
                {
                  //render questionTexts if its array has length greater than 0
                  questionTexts !== null &&
                  questionTexts !== undefined &&
                  questionTexts?.length > 0 && (
                    <div
                      className="has-text-centered m-auto"
                      style={{ maxWidth: "40rem" }}
                    >
                      {questionTexts}
                    </div>
                  )
                }
                {questionChoices.length === 4 ? (
                  <>
                    {(10 + 1.5 + 0.5) * 4 + 6 >= maxWidth ? (
                      <>
                        <div>
                          <div
                            className="columns is-centered m-0 "
                            style={{
                              padding: "0.5rem",
                            }}
                          >
                            <GapFill
                              index={0}
                              onChange={handleChange(0)}
                              answer={answers[0] || ""}
                              checkingState={question.showSolution || isPreview}
                              correctAnswers={correctAnswers[0]}
                              choice={questionChoices[0]}
                            />
                            <GapFill
                              index={1}
                              onChange={handleChange(1)}
                              answer={answers[1] || ""}
                              checkingState={question.showSolution || isPreview}
                              correctAnswers={correctAnswers[1]}
                              choice={questionChoices[1]}
                            />
                          </div>
                          <div
                            className="columns is-centered m-0 "
                            style={{
                              padding: "0.5rem",
                            }}
                          >
                            <GapFill
                              index={2}
                              onChange={handleChange(2)}
                              answer={answers[2] || ""}
                              checkingState={question.showSolution || isPreview}
                              correctAnswers={correctAnswers[2]}
                              choice={questionChoices[2]}
                            />
                            <GapFill
                              index={3}
                              onChange={handleChange(3)}
                              answer={answers[3] || ""}
                              checkingState={question.showSolution || isPreview}
                              correctAnswers={correctAnswers[3]}
                              choice={questionChoices[3]}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <div
                            className="columns is-multiline is-centered m-0 "
                            style={{
                              padding: "0.5rem",
                            }}
                          >
                            <GapFill
                              index={0}
                              onChange={handleChange(0)}
                              answer={answers[0] || ""}
                              checkingState={question.showSolution || isPreview}
                              correctAnswers={correctAnswers[0]}
                              choice={questionChoices[0]}
                            />
                            <GapFill
                              index={1}
                              onChange={handleChange(1)}
                              answer={answers[1] || ""}
                              checkingState={question.showSolution || isPreview}
                              correctAnswers={correctAnswers[1]}
                              choice={questionChoices[1]}
                            />
                            <GapFill
                              index={2}
                              onChange={handleChange(2)}
                              answer={answers[2] || ""}
                              checkingState={question.showSolution || isPreview}
                              correctAnswers={correctAnswers[2]}
                              choice={questionChoices[2]}
                            />
                            <GapFill
                              index={3}
                              onChange={handleChange(3)}
                              answer={answers[3] || ""}
                              checkingState={question.showSolution || isPreview}
                              correctAnswers={correctAnswers[3]}
                              choice={questionChoices[3]}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {questionChoices.length === 6 ? (
                      <>
                        {(10 + 1.5 + 0.5 + 0.5) * 3 + 6 <= maxWidth ? (
                          <>
                            <div>
                              <div
                                className="columns is-centered m-0 "
                                style={{
                                  gap: "0.5rem",
                                  padding: "0.5rem",
                                }}
                              >
                                <GapFill
                                  index={0}
                                  onChange={handleChange(0)}
                                  answer={answers[0] || ""}
                                  checkingState={
                                    question.showSolution || isPreview
                                  }
                                  correctAnswers={correctAnswers[0]}
                                  choice={questionChoices[0]}
                                />
                                <GapFill
                                  index={1}
                                  onChange={handleChange(1)}
                                  answer={answers[1] || ""}
                                  checkingState={
                                    question.showSolution || isPreview
                                  }
                                  correctAnswers={correctAnswers[1]}
                                  choice={questionChoices[1]}
                                />
                                <GapFill
                                  index={2}
                                  onChange={handleChange(2)}
                                  answer={answers[2] || ""}
                                  checkingState={
                                    question.showSolution || isPreview
                                  }
                                  correctAnswers={correctAnswers[2]}
                                  choice={questionChoices[2]}
                                />
                              </div>
                              <div
                                className="columns is-centered m-0 "
                                style={{
                                  gap: "0.5rem",
                                  padding: "0.5rem",
                                }}
                              >
                                <GapFill
                                  index={3}
                                  onChange={handleChange(3)}
                                  answer={answers[3] || ""}
                                  checkingState={
                                    question.showSolution || isPreview
                                  }
                                  correctAnswers={correctAnswers[3]}
                                  choice={questionChoices[3]}
                                />
                                <GapFill
                                  index={4}
                                  onChange={handleChange(4)}
                                  answer={answers[4] || ""}
                                  choice={questionChoices[4]}
                                  checkingState={
                                    question.showSolution || isPreview
                                  }
                                  correctAnswers={correctAnswers[4]}
                                />
                                <GapFill
                                  index={5}
                                  onChange={handleChange(5)}
                                  answer={answers[5] || ""}
                                  choice={questionChoices[5]}
                                  checkingState={
                                    question.showSolution || isPreview
                                  }
                                  correctAnswers={correctAnswers[5]}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="columns is-multiline is-centered m-0 "
                              style={{
                                gap: "0.5rem",
                              }}
                            >
                              {questionChoices.map((choice, index) => {
                                return (
                                  <GapFill
                                    key={index}
                                    index={index}
                                    onChange={handleChange(index)}
                                    answer={answers[index] || ""}
                                    checkingState={
                                      question.showSolution || isPreview
                                    }
                                    correctAnswers={correctAnswers[index]}
                                    choice={questionChoices[index]}
                                  />
                                );
                              })}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div
                          className="columns is-multiline is-centered"
                          style={{ gap: "0.5rem" }}
                        >
                          {questionChoices.map((choice, index) => {
                            return (
                              <GapFill
                                key={index}
                                index={index}
                                onChange={handleChange(index)}
                                answer={answers[index] || ""}
                                checkingState={
                                  question.showSolution || isPreview
                                }
                                correctAnswers={correctAnswers[index]}
                                choice={questionChoices[index]}
                              />
                            );
                          })}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>

              {(question.showSolution || isPreview) &&
                (localStorage.getItem("submitted") || localStorage.getItem("submittedProblemSet")) && (
                  <>
                    <div
                      className="is-flex is-flex-direction-column is-justify-content-center"
                      style={{
                        gap: "2rem",
                      }}
                    >
                      <ImageFromUrl
                        style={{ padding: "0rem 2rem" }}
                        objectId="admin/frontend-images/exam/Vector 2.png"
                      />
                    </div>

                    <p
                      className="has-text-kurio-main-color-black"
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontWeight: 700,
                        textTransform: "uppercase",
                        padding: "1rem 0rem 0rem",
                      }}
                    >
                      Đáp án
                    </p>
                    <div
                      className={`${isLoading ? "is-hidden" : ""
                        } question-container is-flex is-flex-direction-column`}
                      style={{
                        padding: "1.5rem 2rem",
                        gap: "1rem",
                      }}
                    >
                      {questionChoices.length === 4 ? (
                        <>
                          {(10 + 1.5 + 0.5) * 4 + 6 >= maxWidth ? (
                            <>
                              <div>
                                <div
                                  className="columns is-centered m-0 "
                                  style={{
                                    padding: "0.5rem",
                                  }}
                                >
                                  <GapFill
                                    index={0}
                                    onChange={handleChange(0)}
                                    questionSolutions={
                                      questionSolutions[0] || ""
                                    }
                                    checkingState={question.showSolution}
                                    correctAnswers={correctAnswers[0]}
                                    choice={questionChoices[0]}
                                  />
                                  <GapFill
                                    index={1}
                                    onChange={handleChange(1)}
                                    questionSolutions={
                                      questionSolutions[1] || ""
                                    }
                                    checkingState={question.showSolution}
                                    correctAnswers={correctAnswers[1]}
                                    choice={questionChoices[1]}
                                  />
                                </div>
                                <div
                                  className="columns is-centered m-0 "
                                  style={{
                                    padding: "0.5rem",
                                  }}
                                >
                                  <GapFill
                                    index={2}
                                    onChange={handleChange(2)}
                                    questionSolutions={
                                      questionSolutions[2] || ""
                                    }
                                    checkingState={question.showSolution}
                                    correctAnswers={correctAnswers[2]}
                                    choice={questionChoices[2]}
                                  />
                                  <GapFill
                                    index={3}
                                    onChange={handleChange(3)}
                                    questionSolutions={
                                      questionSolutions[3] || ""
                                    }
                                    checkingState={question.showSolution}
                                    correctAnswers={correctAnswers[3]}
                                    choice={questionChoices[3]}
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                <div
                                  className="columns is-multiline is-centered m-0 "
                                  style={{
                                    padding: "0.5rem",
                                  }}
                                >
                                  <GapFill
                                    index={0}
                                    onChange={handleChange(0)}
                                    questionSolutions={
                                      questionSolutions[0] || ""
                                    }
                                    checkingState={question.showSolution}
                                    correctAnswers={correctAnswers[0]}
                                    choice={questionChoices[0]}
                                  />
                                  <GapFill
                                    index={1}
                                    onChange={handleChange(1)}
                                    questionSolutions={
                                      questionSolutions[1] || ""
                                    }
                                    checkingState={question.showSolution}
                                    correctAnswers={correctAnswers[1]}
                                    choice={questionChoices[1]}
                                  />
                                  <GapFill
                                    index={2}
                                    onChange={handleChange(2)}
                                    questionSolutions={
                                      questionSolutions[2] || ""
                                    }
                                    checkingState={question.showSolution}
                                    correctAnswers={correctAnswers[2]}
                                    choice={questionChoices[2]}
                                  />
                                  <GapFill
                                    index={3}
                                    onChange={handleChange(3)}
                                    questionSolutions={
                                      questionSolutions[3] || ""
                                    }
                                    checkingState={question.showSolution}
                                    correctAnswers={correctAnswers[3]}
                                    choice={questionChoices[3]}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {questionChoices.length === 6 ? (
                            <>
                              {(10 + 1.5 + 0.5 + 0.5) * 3 + 6 <= maxWidth ? (
                                <>
                                  <div>
                                    <div
                                      className="columns is-centered m-0 "
                                      style={{
                                        gap: "0.5rem",
                                        padding: "0.5rem",
                                      }}
                                    >
                                      <GapFill
                                        index={0}
                                        onChange={handleChange(0)}
                                        questionSolutions={
                                          questionSolutions[0] || ""
                                        }
                                        checkingState={question.showSolution}
                                        correctAnswers={correctAnswers[0]}
                                        choice={questionChoices[0]}
                                      />
                                      <GapFill
                                        index={1}
                                        onChange={handleChange(1)}
                                        questionSolutions={
                                          questionSolutions[1] || ""
                                        }
                                        checkingState={question.showSolution}
                                        correctAnswers={correctAnswers[1]}
                                        choice={questionChoices[1]}
                                      />
                                      <GapFill
                                        index={2}
                                        onChange={handleChange(2)}
                                        questionSolutions={
                                          questionSolutions[2] || ""
                                        }
                                        checkingState={question.showSolution}
                                        correctAnswers={correctAnswers[2]}
                                        choice={questionChoices[2]}
                                      />
                                    </div>
                                    <div
                                      className="columns is-centered m-0 "
                                      style={{
                                        gap: "0.5rem",
                                        padding: "0.5rem",
                                      }}
                                    >
                                      <GapFill
                                        index={3}
                                        onChange={handleChange(3)}
                                        questionSolutions={
                                          questionSolutions[3] || ""
                                        }
                                        checkingState={question.showSolution}
                                        correctAnswers={correctAnswers[3]}
                                        choice={questionChoices[3]}
                                      />
                                      <GapFill
                                        index={4}
                                        onChange={handleChange(4)}
                                        questionSolutions={
                                          questionSolutions[4] || ""
                                        }
                                        choice={questionChoices[4]}
                                        checkingState={question.showSolution}
                                        correctAnswers={correctAnswers[4]}
                                      />
                                      <GapFill
                                        index={5}
                                        onChange={handleChange(5)}
                                        questionSolutions={
                                          questionSolutions[5] || ""
                                        }
                                        choice={questionChoices[5]}
                                        checkingState={question.showSolution}
                                        correctAnswers={correctAnswers[5]}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="columns is-multiline is-centered m-0 "
                                    style={{
                                      gap: "0.5rem",
                                    }}
                                  >
                                    {questionChoices.map((choice, index) => {
                                      return (
                                        <GapFill
                                          key={index}
                                          index={index}
                                          onChange={handleChange(index)}
                                          questionSolutions={
                                            questionSolutions[index] || ""
                                          }
                                          checkingState={question.showSolution}
                                          correctAnswers={correctAnswers[index]}
                                          choice={questionChoices[index]}
                                        />
                                      );
                                    })}
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <div
                                className="columns is-multiline is-centered"
                                style={{ gap: "0.5rem" }}
                              >
                                {questionChoices.map((choice, index) => {
                                  return (
                                    <GapFill
                                      key={index}
                                      index={index}
                                      onChange={handleChange(index)}
                                      questionSolutions={
                                        questionSolutions[index] || ""
                                      }
                                      checkingState={question.showSolution}
                                      correctAnswers={correctAnswers[index]}
                                      choice={questionChoices[index]}
                                    />
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )
              }
            </>
          )}
          {!explanationState && gapFillWithImages && (
            <>
              <div
                className={`${isLoading ? "is-hidden" : ""} `}
                style={{
                  padding: "1.5rem 2rem",
                }}
              >
                {questionChoices.length === 4 ? (
                  <>
                    {(3 + 10 + 1 + 1.5) * 4 + 4 >= maxWidth ? (
                      <>
                        <div
                          className="columns is-centered m-0 "
                          style={{
                            gap: "0.5rem",
                            padding: "0.5rem",
                          }}
                        >
                          <GapFill
                            index={0}
                            onChange={handleChange(0)}
                            answer={answers[0] || ""}
                            correctAnswers={correctAnswers[0]}
                            checkingState={question.showSolution}
                            choice={questionChoices[0]}
                          />
                          <GapFill
                            index={1}
                            onChange={handleChange(1)}
                            answer={answers[1] || ""}
                            correctAnswers={correctAnswers[1]}
                            checkingState={question.showSolution}
                            choice={questionChoices[1]}
                          />
                        </div>
                        <div
                          className="columns is-centered m-0 "
                          style={{
                            gap: "0.5rem",
                            padding: "0.5rem",
                          }}
                        >
                          <GapFill
                            index={2}
                            onChange={handleChange(2)}
                            answer={answers[2] || ""}
                            checkingState={question.showSolution}
                            correctAnswers={correctAnswers[2]}
                            choice={questionChoices[2]}
                          />
                          <GapFill
                            index={3}
                            onChange={handleChange(3)}
                            answer={answers[3] || ""}
                            checkingState={question.showSolution}
                            correctAnswers={correctAnswers[3]}
                            choice={questionChoices[3]}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="columns is-centered m-0 "
                          style={{
                            gap: "0.5rem",
                          }}
                        >
                          {questionChoices.map((choice, index) => {
                            return (
                              <GapFill
                                key={index}
                                index={index}
                                onChange={handleChange(index)}
                                answer={answers[index] || ""}
                                correctAnswers={correctAnswers[index]}
                                checkingState={question.showSolution}
                                choice={questionChoices[index]}
                              />
                            );
                          })}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div
                    className="columns m-0 is-multiline is-centered"
                    style={{ gap: "0.5rem" }}
                  >
                    {questionChoices.map((choice, index) => {
                      return (
                        <GapFill
                          key={index}
                          index={index}
                          onChange={handleChange(index)}
                          answer={answers[index] || ""}
                          correctAnswers={correctAnswers[index]}
                          checkingState={question.showSolution}
                          choice={questionChoices[index]}
                        />
                      );
                    })}
                  </div>
                )}
              </div>

              {question.showSolution &&
                JSON.parse(localStorage.getItem("solutionInfo")) ? (
                JSON.parse(localStorage.getItem("solutionInfo"))
                  ?.takeExamIdLP !== null &&
                JSON.parse(localStorage.getItem("solutionInfo"))
                  ?.solutionsViewable === true && (
                  <>
                    <div
                      className="is-flex is-flex-direction-column is-justify-content-center"
                      style={{
                        gap: "2rem",
                      }}
                    >
                      <ImageFromUrl
                        style={{ padding: "0rem 2rem" }}
                        objectId="admin/frontend-images/exam/Vector 2.png"
                      />
                    </div>

                    <p
                      className="has-text-kurio-main-color-black"
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontWeight: 700,
                        textTransform: "uppercase",
                        padding: "1rem 0rem 0rem",
                      }}
                    >
                      Đáp án
                    </p>
                    <div
                      className={`${isLoading ? "is-hidden" : ""} `}
                      style={{
                        padding: "1.5rem 2rem",
                      }}
                    >
                      {questionChoices.length === 4 ? (
                        <>
                          {(3 + 10 + 1 + 1.5) * 4 + 4 >= maxWidth ? (
                            <>
                              <div
                                className="columns is-centered m-0 "
                                style={{
                                  gap: "0.5rem",
                                  padding: "0.5rem",
                                }}
                              >
                                <GapFill
                                  index={0}
                                  onChange={handleChange(0)}
                                  questionSolutions={questionSolutions[0] || ""}
                                  correctAnswers={correctAnswers[0]}
                                  checkingState={question.showSolution}
                                  choice={questionChoices[0]}
                                />
                                <GapFill
                                  index={1}
                                  onChange={handleChange(1)}
                                  questionSolutions={questionSolutions[0] || ""}
                                  correctAnswers={correctAnswers[1]}
                                  checkingState={question.showSolution}
                                  choice={questionChoices[1]}
                                />
                              </div>
                              <div
                                className="columns is-centered m-0 "
                                style={{
                                  gap: "0.5rem",
                                  padding: "0.5rem",
                                }}
                              >
                                <GapFill
                                  index={2}
                                  onChange={handleChange(2)}
                                  questionSolutions={questionSolutions[0] || ""}
                                  checkingState={question.showSolution}
                                  correctAnswers={correctAnswers[2]}
                                  choice={questionChoices[2]}
                                />
                                <GapFill
                                  index={3}
                                  onChange={handleChange(3)}
                                  questionSolutions={questionSolutions[0] || ""}
                                  checkingState={question.showSolution}
                                  correctAnswers={correctAnswers[3]}
                                  choice={questionChoices[3]}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className="columns is-centered m-0 "
                                style={{
                                  gap: "0.5rem",
                                }}
                              >
                                {questionChoices.map((choice, index) => {
                                  return (
                                    <GapFill
                                      key={index}
                                      index={index}
                                      onChange={handleChange(index)}
                                      questionSolutions={
                                        questionSolutions[0] || ""
                                      }
                                      correctAnswers={correctAnswers[index]}
                                      checkingState={question.showSolution}
                                      choice={questionChoices[index]}
                                    />
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <div
                          className="columns m-0 is-multiline is-centered"
                          style={{ gap: "0.5rem" }}
                        >
                          {questionChoices.map((choice, index) => {
                            return (
                              <GapFill
                                key={index}
                                index={index}
                                onChange={handleChange(index)}
                                questionSolutions={questionSolutions[0] || ""}
                                correctAnswers={correctAnswers[index]}
                                checkingState={question.showSolution}
                                choice={questionChoices[index]}
                              />
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </>
                )
              ) : (
                <>
                  {question.showSolution !== undefined && (
                    <>
                      <div
                        className="is-flex is-flex-direction-column is-justify-content-center"
                        style={{
                          gap: "2rem",
                        }}
                      >
                        <ImageFromUrl
                          style={{ padding: "0rem 2rem" }}
                          objectId="admin/frontend-images/exam/Vector 2.png"
                        />
                      </div>

                      <p
                        className="has-text-kurio-main-color-black"
                        style={{
                          width: "100%",
                          textAlign: "center",
                          fontWeight: 700,
                          textTransform: "uppercase",
                          padding: "1rem 0rem 0rem",
                        }}
                      >
                        Đáp án
                      </p>
                      <div
                        className={`${isLoading ? "is-hidden" : ""} `}
                        style={{
                          padding: "1.5rem 2rem",
                        }}
                      >
                        {questionChoices.length === 4 ? (
                          <>
                            {(3 + 10 + 1 + 1.5) * 4 + 4 >= maxWidth ? (
                              <>
                                <div
                                  className="columns is-centered m-0 "
                                  style={{
                                    gap: "0.5rem",
                                    padding: "0.5rem",
                                  }}
                                >
                                  <GapFill
                                    index={0}
                                    onChange={handleChange(0)}
                                    questionSolutions={
                                      questionSolutions[0] || ""
                                    }
                                    correctAnswers={correctAnswers[0]}
                                    checkingState={question.showSolution}
                                    choice={questionChoices[0]}
                                  />
                                  <GapFill
                                    index={1}
                                    onChange={handleChange(1)}
                                    questionSolutions={
                                      questionSolutions[0] || ""
                                    }
                                    correctAnswers={correctAnswers[1]}
                                    checkingState={question.showSolution}
                                    choice={questionChoices[1]}
                                  />
                                </div>
                                <div
                                  className="columns is-centered m-0 "
                                  style={{
                                    gap: "0.5rem",
                                    padding: "0.5rem",
                                  }}
                                >
                                  <GapFill
                                    index={2}
                                    onChange={handleChange(2)}
                                    questionSolutions={
                                      questionSolutions[0] || ""
                                    }
                                    checkingState={question.showSolution}
                                    correctAnswers={correctAnswers[2]}
                                    choice={questionChoices[2]}
                                  />
                                  <GapFill
                                    index={3}
                                    onChange={handleChange(3)}
                                    questionSolutions={
                                      questionSolutions[0] || ""
                                    }
                                    checkingState={question.showSolution}
                                    correctAnswers={correctAnswers[3]}
                                    choice={questionChoices[3]}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className="columns is-centered m-0 "
                                  style={{
                                    gap: "0.5rem",
                                  }}
                                >
                                  {questionChoices.map((choice, index) => {
                                    return (
                                      <GapFill
                                        key={index}
                                        index={index}
                                        onChange={handleChange(index)}
                                        questionSolutions={
                                          questionSolutions[0] || ""
                                        }
                                        correctAnswers={correctAnswers[index]}
                                        checkingState={question.showSolution}
                                        choice={questionChoices[index]}
                                      />
                                    );
                                  })}
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <div
                            className="columns m-0 is-multiline is-centered"
                            style={{ gap: "0.5rem" }}
                          >
                            {questionChoices.map((choice, index) => {
                              return (
                                <GapFill
                                  key={index}
                                  index={index}
                                  onChange={handleChange(index)}
                                  questionSolutions={questionSolutions[0] || ""}
                                  correctAnswers={correctAnswers[index]}
                                  checkingState={question.showSolution}
                                  choice={questionChoices[index]}
                                />
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}

          {(question.showSolution || isPreview) && (
            <>
              {(explanation?.images?.length > 0 ||
                explanation?.texts?.length > 0) && (
                  <>
                    <div
                      className="is-flex is-flex-direction-column is-justify-content-center"
                      style={{
                        gap: "2rem",
                      }}
                    >
                      <ImageFromUrl
                        style={{ padding: "0rem 2rem" }}
                        objectId="admin/frontend-images/exam/Vector 2.png"
                      />
                    </div>

                    <p
                      className="has-text-kurio-main-color-black"
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontWeight: 700,
                        textTransform: "uppercase",
                        padding: "1rem 0rem 0rem",
                      }}
                    >
                      Giải thích
                    </p>

                    <div
                      className="is-flex is-flex-direction-column is-align-items-center"
                      style={{
                        gap: "1.5rem",
                        fontSize: "1.25rem",
                        padding: "1rem 3rem",
                        width: "100%",
                      }}
                    >
                      {/* Next and "Xem giai thich" button appears when checkingState is true and selected answer is correct */}
                      {(question.showSolution || isPreview) &&
                        explanationState === false && (
                          <>
                            {((explanationTexts !== null &&
                              explanationTexts !== undefined &&
                              explanationTexts?.length > 0) ||
                              (explanationImages !== null &&
                                explanationImages !== undefined &&
                                explanationImages?.length > 0)) &&
                              explanation.texts?.length >=
                              explanation.images?.length && (
                                <>
                                  {explanation.texts.map((item, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        width: "100%",
                                        gap: "1.5rem",
                                      }}
                                      className="is-flex has-text-justified is-flex-direction-column has-text-kurio-main-color-black"
                                    >
                                      {explanationTexts !== null &&
                                        explanationTexts !== undefined &&
                                        item?.length > 0 && (
                                          <div key={item}>
                                            {explanationTexts[index]}
                                          </div>
                                        )}
                                      {/* only appears when explanationImages has length greater than 0 */}
                                      {explanationImages !== null &&
                                        explanationImages !== undefined &&
                                        explanationImages[index]?.length > 0 && (
                                          <div
                                            className="column is-flex is-justify-content-center is-align-items-center  is-narrow is-centered m-0 p-0"
                                            style={{
                                              width: "100%",
                                            }}
                                          >
                                            <ImageFromUrl
                                              objectId={explanationImages[index]}
                                            />
                                          </div>
                                        )}
                                    </div>
                                  ))}
                                </>
                              )}
                            {((explanationTexts !== null &&
                              explanationTexts !== undefined &&
                              explanationTexts?.length > 0) ||
                              (explanationImages !== null &&
                                explanationImages !== undefined &&
                                explanationImages?.length > 0)) &&
                              explanation.texts?.length <
                              explanation.images?.length && (
                                <>
                                  {explanationImages.map((item, index) => (
                                    <div
                                      key={item}
                                      className="is-flex has-text-justified is-flex-direction-column is-align-items-center has-text-kurio-main-color-black"
                                      style={{ gap: "1.5rem", width: "100%" }}
                                    >
                                      {explanationTexts !== null &&
                                        explanationTexts !== undefined &&
                                        explanation.texts[index]?.length > 0 && (
                                          <div key={index}>
                                            {explanationTexts[index]}
                                          </div>
                                        )}
                                      {/* only appears when explanationImages has length greater than 0 */}
                                      {explanationImages !== null &&
                                        explanationImages !== undefined &&
                                        item?.length > 0 && (
                                          <div
                                            className="column is-flex is-justify-content-center is-align-items-center is-narrow is-centered m-0 p-0"
                                            style={{
                                              width: "100%",
                                            }}
                                          >
                                            <ImageFromUrl
                                              objectId={explanationImages[index]}
                                            />
                                          </div>
                                        )}
                                    </div>
                                  ))}
                                </>
                              )}
                          </>
                        )}
                    </div>
                  </>
                )}
            </>
          )}
        </BackgroundImageFromUrl>
        {question && (
          <div
            style={nextButtonStyle}
            className="is-flex is-flex-direction-row is-justify-content-space-between"
          >
            <PrimaryButtonQuestion
              onClick={showPreviousQuestion}
              // style={nextButtonStyle}
              disabled={questionIndex === 0}
            >
              {ConstantStrings.PREVIOUS_QUESTION}
            </PrimaryButtonQuestion>

            {questionIndex !== questions.length - 1 &&
              (answers.some((item) => item !== "") ? (
                <PrimaryButtonQuestion
                  className=" is-flex is-is-align-items-flex-end"
                  onClick={showNextQuestion}
                >
                  {ConstantStrings.NEXT_QUESTION}
                </PrimaryButtonQuestion>
              ) : (
                <PrimaryButtonQuestion
                  className=" is-flex is-is-align-items-flex-end"
                  onClick={showNextQuestion}
                >
                  {ConstantStrings.SKIP}
                </PrimaryButtonQuestion>
              ))}
          </div>
        )}
      </div>
    </>
  );
});

export default Gapfill;
