import React from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import MenuLeft from "./MenuLeft";
import MenuTopBar from "./MenuTopBar";

const MenuMainWrapper = ({ image, handleChangePathName, pathname }) => {
  return (
    <div
      className="is-flex is-flex-direction-column is-justify-content-center m-auto wrapper-main-content"
      style={{ maxWidth: "80rem", minHeight: "100vh" }}
    >
      <div className="columns is-mobile m-0 p-0" style={{ minHeight: "100vh" }}>
        <div
          className="column p-0 m-0 is-one-fifth"
          style={{ maxWidth: "13.5rem" }}
        >
          <MenuLeft />
        </div>
        <div className="column p-0 m-0 " style={{ maxWidth: "66.5rem" }}>
          <MenuTopBar
            handleChangePathName={(pathname) => handleChangePathName(pathname)}
            image={image}
            pathname={pathname}
          />
          <Outlet />
        </div>

        
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    questions: state.practiceElective.questions,
    seenQuestions: state.practiceElective.seenQuestions,
    correctAnswers: state.practiceElective.correctAnswers,
  };
}

export default connect(mapStateToProps)(MenuMainWrapper);
