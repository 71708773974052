import React from "react";
import ReactPlayer from "react-player";
import { connect } from "react-redux";

const MainContent8VideoPopup = ({
    sections,
    data,
    section,
    slide,
    nextSection,
}) => {
    const content = data?.content;
    const contentVideo = content?.videos[0];

    // var videoPlayer = {
    //     zIndex: 1000,
    //     position: "relative",
    //     width: "calc(100% - 20rem) !important",
    //     maxWidth: "67.5rem",
    // };

    return (
        // <div
        //     className="m-auto"
        //     style={{
        //         textAlign: "center",
        //         maxWidth: "67.5rem",
        //     }}
        // >
        // </div>
            <ReactPlayer
                style={{
                    width: "calc(100% - 20rem) !important",
                    margin: "0 auto",
                    zIndex: 1000,
                    position: "relative",
                    maxWidth: "67.5rem",
                }}
                width="calc(100% - 20rem)"
                height="auto"
                playing={true}
                url={contentVideo}
                controls={true}
            />
    );
};
function mapStateToProps(state) {
    return {
        sections: state.questions.sections,
    };
}
export default connect(mapStateToProps)(MainContent8VideoPopup);
