import React from "react";
import "./CheckBox.style.css";
const CheckBox = ({ children, onClick, style, isClick }) => {
  const audio = new Audio("/sounds/click.mp3");
  const handleClick = () => {
    audio.play();
    if (onClick) {
      onClick();
    }
  };
  return (
    <div>
      <label className="is-flex is-align-items-center " style={{ gap: "1rem" }}>
        <input
          type="checkbox"
          style={style}
          className="is-clickable"
          onClick={handleClick}
        />
        {children}
      </label>
    </div>
  );
};

export default CheckBox;
