import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import {
  getExamQuestion,
  getExamQuestionSolutions,
  getViewableExamSolutions,
  setQuestionAnswered,
  setQuestionState,
  setQuestionsAnswered,
  submitExamAnswer
} from "../../reducers/examsQuestionSlice";
import { LoadingImage } from "../../templates/helper/LoadingImage";
import { getAllImage } from "../../templates/helper/getAllImages";
import MonthlyExamQuestionsLeftWrapper from "./left/MonthlyExamQuestionsLeftWrapper";
import MonthlyExamQuestionsRightWrapper from "./right/MonthlyExamQuestionsRightWrapper";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";

const MonthlyExamQuestionsMainWrapper = ({
  takeExamsQuestionId,
  examQuestions,
  seenQuestions,
  takeExamIdInfo,
  error,
}) => {
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [loadingImage, setLoadingImage] = useState(false);
  const [pagination, setPagination] = useState(
    localStorage.getItem("pagination")
      ? parseInt(localStorage.getItem("pagination"))
      : 1
  );
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const token = cookies.get("signinUser");
  const page = 1;
  const size = 60;
  const handleSetLoadingImage = (state) => {
    setLoadingImage(state);
  };
  const navigate = useNavigate();

  if (localStorage.getItem("listExamInfoMonthly")) {
    if (
      (takeExamIdInfo !== null &&
        localStorage.getItem("takeExamMonthlyQuestionId")) ||
      (JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
        ?.takeExamId === null &&
        !localStorage.getItem("takeExamIdLPMonthly"))
    ) {
      localStorage.setItem(
        "takeExamIdInfo",
        localStorage.getItem("takeExamMonthlyQuestionId")
          ? localStorage.getItem("takeExamMonthlyQuestionId")
          : JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
            ?.takeExamId
      );
    } else if (
      (takeExamIdInfo === null &&
        localStorage.getItem("takeExamMonthlyQuestionId")) ||
      (JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
        ?.takeExamId === null &&
        !localStorage.getItem("takeExamIdLPMonthly"))
    ) {
      localStorage.setItem(
        "takeExamIdInfo",
        localStorage.getItem("takeExamMonthlyQuestionId")
          ? localStorage.getItem("takeExamMonthlyQuestionId")
          : JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
            ?.takeExamId
      );
    }
  }

  const handleQuestionChange = (val) => {
    if (val !== selectedQuestion) {
      const result = JSON.parse(localStorage.getItem("questionStateExams"));
      if (
        val < 20 &&
        (!localStorage.getItem("pagination") ||
          parseInt(localStorage.getItem("pagination")) === 2)
      ) {
        localStorage.setItem("pagination", 1);
        setPagination(1);
      } else if (
        val >= 20 &&
        (!localStorage.getItem("pagination") ||
          parseInt(localStorage.getItem("pagination")) === 1)
      ) {
        localStorage.setItem("pagination", 2);
        setPagination(2);
      }

      if (result) {
        dispatch(setQuestionState(result.questionState));
        dispatch(setQuestionAnswered(result.questionAnswered));
        dispatch(setQuestionsAnswered(result.questionsAnswered));
        var submit = result.submit;
        dispatch(
          submitExamAnswer({
            submit,
            examAnswerId: localStorage.getItem("takeExamIdInfo")
              ? localStorage.getItem("takeExamIdInfo")
              : JSON.parse(localStorage.getItem("listExamInfoMonthly"))
                ?.length > 0
                ? JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
                  .takeExamId
                : localStorage.getItem("takeExamYearQuestionId"),
          })
        );
      } else {
        var answeredQuestions = examQuestions.map((question) => ({
          questionId: question.questionId,
          questionIndex: question.questionIndex,
          answer: question.answer?.length > 0 ? question.answer : [],
        }));
        dispatch(setQuestionsAnswered(answeredQuestions));
      }

      localStorage.removeItem("questionStateExams");
      // localStorage.removeItem("targets");
      setSelectedQuestion(val);
    }
  };

  const handleChangePagination = (pagination) => {
    setPagination(pagination);
  };

  const checkingState = examQuestions?.every(
    (item) => item.question.showSolution === true
  );

  useEffect(() => {
    if (localStorage.getItem("takeExamIdLPMonthly") && examQuestions !== null) {
      if (localStorage.getItem("takeExamIdLPMonthly")) {
        dispatch(
          getExamQuestionSolutions({
            takeExamsQuestionId: localStorage.getItem("takeExamIdLPMonthly"),
            page,
            size,
          })
        );
        if (examQuestions === null || !examQuestions) {
          setLoadingImage(true);
        }
      } else {
        navigate("/monthly-exams");
      }

      window.onbeforeunload = () => {
        navigate("/monthly-exam-questions", {
          state: { prevRouter: "/monthly-exams" },
        });
      };
    } else if (
      localStorage.getItem("takeExamIdLPMonthly") &&
      examQuestions === null
    ) {
      if (localStorage.getItem("takeExamIdLPMonthly")) {
        dispatch(
          getExamQuestionSolutions({
            takeExamsQuestionId: localStorage.getItem("takeExamIdLPMonthly"),
            page,
            size,
          })
        );
        if (examQuestions === null || !examQuestions) {
          setLoadingImage(true);
        }
      } else {
        navigate("/monthly-exams");
      }
      window.onbeforeunload = () => {
        navigate("/monthly-exam-questions", {
          state: { prevRouter: "/monthly-exams" },
        });
      };
    }

    // Get exam question monthly
    if (
      localStorage.getItem("createExamQuestionId") &&
      (localStorage.getItem("takeExamIdInfo") ||
        localStorage.getItem("takeExamMonthlyQuestionId")) &&
      localStorage.getItem("listExamInfoMonthly") &&
      examQuestions === null
    ) {
      if (
        localStorage.getItem("takeExamIdInfo") !== null &&
        localStorage.getItem("takeExamMonthlyQuestionId")
      ) {
        dispatch(
          getExamQuestion({
            takeExamsQuestionId: localStorage.getItem("takeExamIdInfo")
              ? localStorage.getItem("takeExamIdInfo")
              : localStorage.getItem("takeExamMonthlyQuestionId"),
            page: 1,
            size: 60,
            token,
          })
        );
        if (examQuestions === null || !examQuestions) {
          setLoadingImage(true);
        }
        window.onbeforeunload = () => {
          localStorage.removeItem("questionStateExams")
          navigate("/monthly-exam-questions", {
            state: { prevRouter: "/monthly-exams" },
          });
        };
      } else {
        navigate("/monthly-exams");
      }
    } else if (
      localStorage.getItem("createExamQuestionId") &&
      (localStorage.getItem("takeExamIdInfo") ||
        localStorage.getItem("takeExamMonthlyQuestionId")) &&
      localStorage.getItem("listExamInfoMonthly") &&
      examQuestions !== null
    ) {
      if (
        localStorage.getItem("takeExamIdInfo") !== null &&
        localStorage.getItem("takeExamMonthlyQuestionId")
      ) {
        dispatch(
          getExamQuestion({
            takeExamsQuestionId: localStorage.getItem("takeExamIdInfo")
              ? localStorage.getItem("takeExamIdInfo")
              : localStorage.getItem("takeExamMonthlyQuestionId"),
            page: 1,
            size: 60,
            token,
          })
        );
        if (examQuestions === null || !examQuestions) {
          setLoadingImage(true);
        }
        window.onbeforeunload = () => {
          localStorage.removeItem("questionStateExams")
          navigate("/monthly-exam-questions", {
            state: { prevRouter: "/monthly-exams" },
          });
        };
      } else {
        navigate("/monthly-exams");
      }
    }

    if (
      JSON.parse(localStorage.getItem("listExamInfoMonthly"))?.length > 0 &&
      !localStorage.getItem("takeExamIdLPMonthly") &&
      !localStorage.getItem("examYearIdMonthly") &&
      examQuestions === null
    ) {
      if (
        JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
          ?.takeExamId !== null ||
        localStorage.getItem("takeExamIdInfo") !== null
      ) {
        dispatch(
          getExamQuestion({
            takeExamsQuestionId:
              localStorage.getItem("takeExamIdInfo") !== null
                ? localStorage.getItem("takeExamIdInfo")
                : JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
                  ?.takeExamId,
            token,
          })
        );
        if (examQuestions === null || !examQuestions) {
          setLoadingImage(true);
        }
        window.onbeforeunload = () => {
          localStorage.removeItem("questionStateExams")
          navigate("/monthly-exam-questions", {
            state: { prevRouter: "/monthly-exams" },
          });
        };
      }
    }

    if (localStorage.getItem("examYearIdMonthly") && examQuestions !== null) {
      if (localStorage.getItem("examYearIdMonthly")) {
        dispatch(
          getViewableExamSolutions({
            examYearId: localStorage.getItem("examYearIdMonthly"),
            page,
            size,
            token,
          })
        );
        if (examQuestions === null || !examQuestions) {
          setLoadingImage(true);
        }

        window.onbeforeunload = () => {
          navigate("/monthly-exam-questions", {
            state: { prevRouter: "/monthly-exams" },
          });
        };
      } else {
        navigate("/monthly-exams");
      }
    } else if (
      localStorage.getItem("examYearIdMonthly") &&
      examQuestions === null
    ) {
      if (localStorage.getItem("examYearIdMonthly")) {
        dispatch(
          getViewableExamSolutions({
            examYearId: localStorage.getItem("examYearIdMonthly"),
            page,
            size,
            token,
          })
        );
        if (examQuestions === null || !examQuestions) {
          setLoadingImage(true);
        }
        window.onbeforeunload = () => {
          navigate("/monthly-exam-questions", {
            state: { prevRouter: "/monthly-exams" },
          });
        };
      } else {
        navigate("/monthly-exams");
      }
    }
  }, [dispatch]);

  // if (error && location.state.prevRouter === "/monthly-exams") {
  //   navigate("/monthly-exams");
  // } else if (error && location.state.prevRouter === "/") {
  //   navigate("/");
  // }

  if (loadingImage && examQuestions !== null && examQuestions?.length > 0) {
    const result = getAllImage(examQuestions, "EXAM");
    if (result && result?.length > 0) {
      return (
        <>
          <LoadingImage
            images={result}
            router={""}
            handleSetLoadingImage={(state) => handleSetLoadingImage(state)}
          />
        </>
      );
    } else {
      setLoadingImage(false);
    }
  }

  return (
    <div>
      {/* {loadingImage && <LoadingImage />} */}
      {!loadingImage && examQuestions !== null && examQuestions?.length > 0 && (
        <BackgroundImageFromUrl
          objectId={checkingState !== undefined && !checkingState
            ? "admin/frontend-images/exam/background-monthly-exam-update.svg"
            : "admin/frontend-images/exam/background-solution-monthly-exam.svg"}
          className="columns is-mobile m-0 p-0"
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
          }}
        >
          {examQuestions && (
            <div
              style={{
                // backgroundColor: checkingState ? "#F7F1DF" : "#DEEBF7",
                minHeight: "100vh",
                padding: "5rem 2.5rem 10rem 2.5rem",
                marginRight: "18.75rem",
                width: "calc(100% - 18.75rem)",
              }}
              className="column is-four-fifths is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
            >
              <MonthlyExamQuestionsRightWrapper
                question={examQuestions[selectedQuestion]}
                selectedQuestion={selectedQuestion}
                seenQuestions={seenQuestions}
                handleQuestionChange={handleQuestionChange}
                questions={examQuestions}
                takeExamsQuestionId={takeExamsQuestionId}
              />
            </div>
          )}
          <div
            className="column m-0 is-one-fifth p-0 is-justify-content-center is-align-item-flex-start"
            style={{
              position: "fixed",
              right: "0",
              display: "flex",
              zIndex: 1000,
              flexDirection: "column",
              width: "18.75rem",
              height: "100%",
            }}
          >
            <MonthlyExamQuestionsLeftWrapper
              selectedIndex={selectedQuestion}
              handleChangePagination={(pagination) =>
                handleChangePagination(pagination)
              }
              handleQuestionChange={handleQuestionChange}
              takeExamsQuestionId={takeExamsQuestionId}
              pagination={pagination}
            />
          </div>
        </BackgroundImageFromUrl>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    takeExamIdInfo: state.examsQuestion?.examInformation?.takeExamId,
    takeExamsQuestionId: state.examsQuestion.takeExamsQuestion,
    takeExamIdInformation: state.examsQuestion.takeExamIdInformation,
    takeExamIdLPInformation: state.examsQuestion.takeExamIdLPInformation,
    examQuestions: state.examsQuestion.questions,
    seenQuestions: state.examsQuestion.seenQuestions,
    error: state.examsQuestion.error,
  };
}

export default connect(mapStateToProps)(MonthlyExamQuestionsMainWrapper);
