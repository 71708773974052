import React, { useState } from "react";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
// import { connect, useDispatch } from "react-redux";
// import ConstantStrings from "../../constants/ConstantStrings";
import ScrollRef from "../../detail-components/ScrollRef";
// import { setQuestionVisible } from "../../reducers/questionsSlice";
import { checkImage } from "../../constants/checkImage";
import { loadWidthImage } from "../../constants/loadWidthImage";
import jsonParser from "../helper/jsonParser";
import { cacheImage, getCacheImage, getImageUrl } from "../helper/parseImage";
import "./MainContent.css";
import MainContent1Title from "./MainContent1Title";

const MainContent9Conclusion = ({ data, isLast }) => {
  const content = data?.content;
  const contentText1 =
    content?.texts[0] !== null &&
    content?.texts[0] !== undefined &&
    content?.texts[0]?.length > 0
      ? jsonParser(content?.texts[0], 0, "MainContent9Context0")
      : content?.texts[0];
  const contentText2 =
    content?.texts[1] !== null &&
    content?.texts[1] !== undefined &&
    content?.texts[1]?.length > 0
      ? jsonParser(content?.texts[1], 1, "MainContent9Context1")
      : content?.texts[1];
  const [isLoading, setIsLoading] = useState(
    checkImage(content?.images[0])
      ? getCacheImage([content?.images[0]])
        ? false
        : true
      : false
  );
  var key = getImageUrl(content?.images[0]);
  loadWidthImage(key, (err, img) => {
    cacheImage([key]);
    setIsLoading(false);
  });

  return (
    <div className="has-text-centered">
      {!isLast && !isLoading && <ScrollRef />}
      {data?.title && !isLoading && <MainContent1Title title={data?.title} />}
      <BackgroundImageFromUrl
        objectId={content?.backgroundImage}
        className={`main-content-conclusion m-auto is-flex is-flex-direction-column has-text-centered has-background-kurio-purple-light-3 has-text-kurio-main-color-purple`}
        style={{
          borderRadius: "1rem",
          maxWidth: "40rem",
          padding: "2rem",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          gap: "2rem",
        }}
      >
        <div
          className={`${isLoading ? "is-hidden" : ""} has-text-weight-bold`}
          style={{ fontSize: "1.5rem" }}
        >
          {contentText1}
        </div>
        <img src={key} alt={content?.images[0]} />
        <div
          className={`${
            isLoading ? "is-hidden" : ""
          } has-text-kurio-main-color-black has-text-weight-bold`}
          style={{ fontSize: "1rem" }}
        >
          {contentText2}
        </div>
      </BackgroundImageFromUrl>
    </div>
  );
};
export default MainContent9Conclusion;
