import React from "react";
import BackgroundImageFromUrl from "../../parseImage/BackgroundImageFromUrl";

const ExerciseLessonsListButton = ({ onClick, disabled, children }) => {
  var objectId = "";
  var style = {
    width: "3rem",
    height: "3rem",
    border: "none",
    borderRadius: "9999rem",
    boxShadow: "none",
  };
  const audio = new Audio("/sounds/click.mp3");
  if (!disabled) {
    style = {
      ...style,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    };
    objectId =
      "admin/frontend-images/button/exercise-lessons-list-button-bg.svg";
  } else {
    style = {
      ...style,
      backgroundSize: "1.75rem 1.75rem",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    };
    objectId = "admin/frontend-images/button/exercise-lessons-list-button.svg";
  }
  const handleClick = () => {
    if (!disabled) {
      audio.play();
      if (onClick) {
        onClick();
      }
    }
  };
  return (
    <>
      <BackgroundImageFromUrl
        objectId={objectId}
        className={`${
          !disabled ? "" : "is-kurio-gray-light-1"
        } button main-color has-text-weight-bold is-uppercase has-text-centered`}
        style={style}
        onClick={handleClick}
        type="button"
      >
        {children}
      </BackgroundImageFromUrl>
    </>
  );
};
export default ExerciseLessonsListButton;
