import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "universal-cookie";
import { getAQuestion } from "../config/question";
import { getARoadMap, getAllRoadMapCategory, getProblemSetQuestions, getProblemSetSolutions, getRoadMapExamQuestions, getRoadMapExamSolutions, resetRoadMap, restartProblemSets, submitProblemSetAnswers, submitRoadMapExamAnswers, takeExamRoadMap, takeProblemSet } from "../config/roadMap";

const cookies = new Cookies();
const token = cookies.get("signinUser");
// const roles = ["CREATOR", "REVIEWER"];
// const decode = decodeToken(token);

const initialState = {
  checkExamTime: null,
  idExamAdmin: null,
  serverTime: null,
  questions: null,
  seenQuestions: 0,
  recordCount: 0,
  pageCount: 0,
  currentPage: 0,
  pageSize: 0,
  correctAnswers: 0,
  currentScore: 0,
  maxScore: 0,
  wrongAnswers: 0,
  skippedQuestions: 0,
  status: "idle",
  error: null,
  examsRoadMap: null,
  takeProblemSetId: null,
  listRoadMap: null,
  // takeExamIdInformation: null,
  takeRoadMapId: null,
  problemSetImage: null,
  categories:null,
  answeredQuestions: [],
};

export const getRoadMap = createAsyncThunk(
  "roadMap/getARoadMap",
  async ({ gradeId, isAnonymous, anonymousUserId, categoryId }, thunkAPI) => {
    try {
      const { result } = await getARoadMap(gradeId, isAnonymous, anonymousUserId, categoryId);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);
export const getListRoadMapCategories = createAsyncThunk(
  "roadMap/getListRoadMapCategories",
  async (thunkAPI)=>{
    try {
      const { result } = await getAllRoadMapCategory();
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
)

export const resetRoadAMap = createAsyncThunk(
  "roadMap/resetRoadAMap",
  async ({ roadMapId, isAnonymous, anonymousUserId }, thunkAPI) => {
    try {
      const { result } = await resetRoadMap(roadMapId, isAnonymous, anonymousUserId);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);


export const getTakeRoadMapExamId = createAsyncThunk(
  "roadMap/getTakeRoadMapExamId",
  async ({ roadmapId, examId, isAnonymous, anonymousUserId }, thunkAPI) => {
    try {
      const { result } = await takeExamRoadMap(roadmapId, examId, isAnonymous, anonymousUserId);
      localStorage.setItem("takeRoadMapExamId", result)
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const getRoadMapExamQuestion = createAsyncThunk(
  "roadMap/getRoadMapExamQuestion",
  async ({ examId, page, size, isAnonymous, anonymousUserId }, thunkAPI) => {
    try {
      const { result } = await getRoadMapExamQuestions(
        examId, page, size, isAnonymous, anonymousUserId
      );

      if (
        result.questions?.some(
          (question) => !question.question && !localStorage.getItem("toggle")
        )
      ) {
        localStorage.setItem("nameExam", result.name);
        var listQuestion = await axios.all(
          result.questions.map(async (questionItem) => {
            const { result } = await getAQuestion(
              questionItem.questionId,
              token
            );

            questionItem = {
              ...questionItem,
              explanation: result.explanation,
              prompt: result.prompt,
              question: result.question,
              type: result.type,
            };

            return questionItem;
          })
        );

        result.questions = listQuestion;
      }
      return result;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const submitRoadMapExamAnswer = createAsyncThunk(
  "roadMap/submitRoadMapExamAnswer",
  async ({ examId, answers, isAnonymous, anonymousUserId }, thunkAPI) => {
    try {
      const { result } = await submitRoadMapExamAnswers(examId, answers, isAnonymous, anonymousUserId);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const getRoadMapExamSolution = createAsyncThunk(
  "roadMap/getRoadMapExamSolution",
  async ({ examId, page, size, isAnonymous, anonymousUserId, showSolution }, thunkAPI) => {
    try {
      const { result } = await getRoadMapExamSolutions(
        examId, page, size, isAnonymous, anonymousUserId
      );
      if (showSolution) {
        result.records.map((record) => (record.answer = []))
        result.records.map((record) => (record.correct = null))
        result.records.map((record) => (record.skipped = null))
      }

      result.records.map((record) => (record.question.showSolution = true));

      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

// Problem-set

export const getTakeProblemSetId = createAsyncThunk(
  "roadMap/getTakeProblemSetId",
  async ({ roadmapId, examId, problemSetId, isAnonymous, anonymousUserId }, thunkAPI) => {
    try {
      const { result } = await takeProblemSet(roadmapId, examId, problemSetId, isAnonymous, anonymousUserId);
      localStorage.setItem("takeRoadMapProblemSetId", result)
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const getProblemSetQuestion = createAsyncThunk(
  "roadMap/getProblemSetQuestion",
  async ({ problemSetId, page, size, isAnonymous, anonymousUserId }, thunkAPI) => {
    try {
      const { result } = await getProblemSetQuestions(
        problemSetId, page, size, isAnonymous, anonymousUserId
      );

      if (
        result.questions?.some(
          (question) => !question.question && !localStorage.getItem("toggle")
        )
      ) {
        localStorage.setItem("nameExam", result.name);
        var listQuestion = await axios.all(
          result.questions.map(async (questionItem) => {
            const { result } = await getAQuestion(
              questionItem.questionId,
              token
            );

            questionItem = {
              ...questionItem,
              explanation: result.explanation,
              prompt: result.prompt,
              question: result.question,
              type: result.type,
            };

            return questionItem;
          })
        );

        result.questions = listQuestion;
      }
      return result;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const submitProblemSetAnswer = createAsyncThunk(
  "roadMap/submitProblemSetAnswer",
  async ({ problemSetId, answers, isAnonymous, anonymousUserId }, thunkAPI) => {
    try {
      const { result } = await submitProblemSetAnswers(problemSetId, answers, isAnonymous, anonymousUserId);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const getProblemSetSolution = createAsyncThunk(
  "roadMap/getProblemSetSolution",
  async ({ problemSetId, page, size, isAnonymous, anonymousUserId, showSolution }, thunkAPI) => {
    try {
      const { result } = await getProblemSetSolutions(
        problemSetId, page, size, isAnonymous, anonymousUserId
      );
      if (showSolution) {
        result.records.map((record) => (record.answer = []))
        result.records.map((record) => (record.correct = null))
        result.records.map((record) => (record.skipped = null))
      }

      result.records.map((record) => (record.question.showSolution = true));

      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const restartProblemSet = createAsyncThunk(
  "roadMap/restartProblemSet",
  async ({ problemSetId, isAnonymous, anonymousUserId }, thunkAPI) => {
    try {
      const { result } = await restartProblemSets(problemSetId, isAnonymous, anonymousUserId);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const roadMap = createSlice({
  name: "roadMap",
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },

    setARoadMap: (state, action) => {
      state.listRoadMap = action.payload;
    },

    setTakeRoadMapExam: (state, action) => {
      state.takeRoadMapId = action.payload;
    },

    setQuestionState: (state, action) => {
      state.questions[action.payload.index].question.state =
        action.payload.state;
    },

    setQuestionsAnswered: (state, action) => {
      state.answeredQuestions = action.payload;
      for (var i = 0; i < action.payload.length; i++) {
        if (action.payload[i]) {
          state.questions[action.payload[i].questionIndex].answer =
            action.payload[i].answer;
        }
      }
    },

    setQuestionAnswered: (state, action) => {
      state.questions[action.payload.questionIndex].isAnswered =
        action.payload.status;
    },

    setSeenQuestions: (state, action) => {
      state.seenQuestions = action.payload;
    },

    setQuestionFinished: (state, action) => {
      state.questions[action.payload].isFinished = true;
    },

    setSkippedQuestions: (state, action) => {
      state.skippedQuestions = action.payload + state.skippedQuestions;
    },

    setCorrectAnswer: (state, action) => {
      state.correctAnswers = action.payload + state.correctAnswers;
    },

    setWrongAnswer: (state, action) => {
      state.wrongAnswers = action.payload + state.wrongAnswers;
    },

    setProblemSetImage: (state, action) => {
      state.problemSetImage = action.payload;
    },

    clearStateRoadMap: () => initialState
  },

  extraReducers: (builder) => {
    builder
      .addCase(getListRoadMapCategories.fulfilled,(state,action)=>{
        state.status = "succeeded";
        state.categories = action.payload
      })
      .addCase(getRoadMap.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.examsRoadMap = action.payload?.exams;
        state.listRoadMap = action.payload;
      })

      .addCase(getTakeRoadMapExamId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.takeRoadMapId = action.payload;
      })

      .addCase(getTakeRoadMapExamId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(getRoadMapExamQuestion.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getRoadMapExamQuestion.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.maxScore = action.payload.recordCount;
        state.correctAnswers = action.payload.correctAnswers
        state.currentScore = action.payload.currentScore;
        state.idExamAdmin = action.payload._id;
        state.checkExamTime = action.payload.deadline;
        state.serverTime = action.payload.serverTime;
        state.questions = action.payload.records
          ? action.payload.records
          : action.payload.questions;
        state.correctAnswers = action.payload.correctAnswers;
        state.wrongAnswers = action.payload.wrongAnswers;
        state.skippedQuestions = action.payload.skippedQuestions;
        state.seenQuestions =
          action.payload.seenQuestions === 0 ? 1 : action.payload.seenQuestions;
        state.questions[state?.questions?.length - 1].isLast = true;
        state.questions[0].visible = true;
        state.questions.forEach((question, index) => {
          question.questionIndex = index;
          question.isAnswered =
            question?.answer !== null && question?.answer?.length > 0;
        });
        state.questions
          .filter((question, index) => index < state.seenQuestions)
          .forEach((question) => {
            question.done = true;
            question.visible = true;
          });
      })

      .addCase(getRoadMapExamQuestion.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(getRoadMapExamSolution.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getRoadMapExamSolution.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questions = action.payload.records;
        state.correctAnswers = action.payload.correctAnswers;
        state.currentScore = action.payload.currentScore;
        state.recordCount = action.payload.recordCount;
        state.maxScore = action.payload.maxScore;
        state.wrongAnswers = action.payload.wrongAnswers;
        state.skippedQuestions = action.payload.skippedQuestions;
        state.questions[0].visible = true;
        state.seenQuestions =
          action.payload.seenQuestions === 0 ? 1 : action.payload.seenQuestions;
        state.questions[state.questions.length - 1].isLast = true;
        state.questions.forEach((question, index) => {
          question.questionIndex = index;
          if (question.question.showSolution) {
            question.isCorrect =
              question.answer !== null &&
              question.answer.length > 0 &&
              question.answer.every(
                (item, index) => item === question.question.solutions[index]
              );
          } else {
            question.isAnswered =
              question.answer !== null && question.answer.length > 0;
          }
        });
        state.questions
          .filter((question, index) => index < state.seenQuestions)
          .forEach((question) => {
            question.done = true;
            question.visible = true;
          });
      })

      .addCase(getRoadMapExamSolution.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(submitRoadMapExamAnswer.fulfilled, (state, action) => {
        state.status = "succeeded";
      })

      // ProblemSet

      .addCase(getTakeProblemSetId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.takeProblemSetId = action.payload;
      })

      .addCase(getTakeProblemSetId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(getProblemSetQuestion.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getProblemSetQuestion.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.maxScore = action.payload.maxScore;
        state.currentScore = action.payload.currentScore;
        state.idExamAdmin = action.payload._id;
        state.checkExamTime = action.payload.deadline;
        state.serverTime = action.payload.serverTime;
        state.questions = action.payload.records
          ? action.payload.records
          : action.payload.questions;
        state.correctAnswers = action.payload.correctAnswers;
        state.wrongAnswers = action.payload.wrongAnswers;
        state.skippedQuestions = action.payload.skippedQuestions;
        state.seenQuestions =
          action.payload.seenQuestions === 0 ? 1 : action.payload.seenQuestions;
        state.questions[state?.questions?.length - 1].isLast = true;
        state.questions[0].visible = true;
        state.questions.forEach((question, index) => {
          question.questionIndex = index;
          question.isAnswered =
            question?.answer !== null && question?.answer?.length > 0;
        });
        state.questions
          .filter((question, index) => index < state.seenQuestions)
          .forEach((question) => {
            question.done = true;
            question.visible = true;
          });
      })

      .addCase(getProblemSetQuestion.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(getProblemSetSolution.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getProblemSetSolution.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questions = action.payload.records;
        state.correctAnswers = action.payload.correctAnswers;
        state.currentScore = action.payload.currentScore;
        state.maxScore = action.payload.maxScore;
        state.wrongAnswers = action.payload.wrongAnswers;
        state.skippedQuestions = action.payload.skippedQuestions;
        state.questions[0].visible = true;
        state.seenQuestions =
          action.payload.seenQuestions === 0 ? 1 : action.payload.seenQuestions;
        state.questions[state.questions.length - 1].isLast = true;
        state.questions.forEach((question, index) => {
          question.questionIndex = index;
          if (question.question.showSolution) {
            question.isCorrect =
              question.answer !== null &&
              question.answer.length > 0 &&
              question.answer.every(
                (item, index) => item === question.question.solutions[index]
              );
          } else {
            question.isAnswered =
              question.answer !== null && question.answer.length > 0;
          }
        });
        state.questions
          .filter((question, index) => index < state.seenQuestions)
          .forEach((question) => {
            question.done = true;
            question.visible = true;
          });
      })

      .addCase(getProblemSetSolution.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(submitProblemSetAnswer.fulfilled, (state, action) => {
        state.status = "succeeded";
      })

      .addCase(restartProblemSet.fulfilled, (state, action) => {
        state.status = "succeeded";
      })

      .addCase(resetRoadAMap.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
  },
});

export const {
  setStatus,
  setQuestionsAnswered,
  setQuestionAnswered,
  setQuestionVisible,
  setQuestionState,
  setSeenQuestions,
  setCorrectAnswer,
  setSkippedQuestions,
  setWrongAnswer,
  setQuestionFinished,
  setTakeExamIdInformation,
  setTakeExamsQuestion,
  setTakeExamIdLPInformation,
  setProblemSetImage,
  clearStateRoadMap,
} = roadMap.actions;

export const selectQuestions = (state) => state.roadMap.questions;
export const selectAnsweredQuestions = (state) =>
  state.roadMap.answeredQuestions;

export const selectStatus = (state) => state.roadMap.status;

export default roadMap.reducer;
