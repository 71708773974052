import React, { useState } from "react";
import CheckBox from "./button/check-box-and-radio/CheckBox";
import Radio from "./button/check-box-and-radio/Radio";
import HelpButton from "./button/pagination-button/HelpButton";
import NextButton from "./button/pagination-button/NextButton";
import NotificationsButton from "./button/pagination-button/NotificationsButton";
import PaginationBigButton from "./button/pagination-button/PaginationBigButton";
import PaginationButtonSmall from "./button/pagination-button/PaginationButtonSmall";
import PreviousButton from "./button/pagination-button/PreviousButton";
import SkipToFirstButton from "./button/pagination-button/SkipToFirstButton";
import SkipToLastButton from "./button/pagination-button/SkipToLastButton";
import SlideOnSlider from "./button/pagination-button/SlideOnSlider";
import PrimaryButtonPageLevel from "./button/primary-button/PrimaryButtonLevelPage";
import PrimaryButtonLevelSection from "./button/primary-button/PrimaryButtonLevelSection";
import PrimaryButtonQuestion from "./button/primary-button/PrimaryButtonQuestion";
import SecondaryButtonLevelPage from "./button/secondary-button/SecondaryButtonLevelPage";
import SecondaryButtonLevelSection from "./button/secondary-button/SecondaryButtonLevelSection";
import SecondaryButtonQuestion from "./button/secondary-button/SecondaryButtonQuestion";
import CompanionChatAndHelp from "./button/special-button/CompanionChatAndHelp";
import { default as ExerciseLessonsListButton } from "./button/special-button/ExcerciseLessonsListButton";
import PopupCloseButton from "./button/special-button/PopupCloseButton";
import SelectGradeButton from "./button/special-button/SelectGradeButton";
import TertiaryButtonLevelPage from "./button/tertiary-button/TertiaryButtonLevelPage";
import TertiaryButtonLevelSection from "./button/tertiary-button/TertiaryButtonLevelSection";
import TertiaryButtonQuestion from "./button/tertiary-button/TertiaryButtonQuestion";
import InputDate from "./input/InputDate";
import InputText from "./input/InputText";
import Otp from "./input/Otp";

const ComponentList = () => {
  const [otp, setOtp] = useState([]);
  const [inputValue, setInputValue] = useState({});
  const handleChangeInputText = (name, value) => {
    setInputValue({ ...inputValue, [name]: value });
  };
  const handleChangeInputDate = (name, value) => {
    setInputValue({ ...inputValue, [name]: value });
  };

  const handleChangeOtp = (value, index, name) => {
    let newValue = [...otp];
    newValue[index] = value;
    setOtp(newValue);
  };
  return (
    <div className=" p-4">
      <div className="columns">
        <h2 className="has-text-centered"> Primary Button </h2>
        <div className="column">
          <div className="mb-4">
            <PrimaryButtonPageLevel style={{ height: "5rem" }}>page level</PrimaryButtonPageLevel>
          </div>
          <div className="mb-4">
            <PrimaryButtonLevelSection>section level</PrimaryButtonLevelSection>
          </div>
          <div className="mb-4">
            <PrimaryButtonQuestion>question</PrimaryButtonQuestion>
          </div>
        </div>
        <div className="column">
          <h2 className="has-text-centered"> Secondary Button </h2>
          <div className="mb-4">
            <SecondaryButtonLevelPage>secondary</SecondaryButtonLevelPage>
          </div>
          <div className="mb-4">
            <SecondaryButtonLevelSection>secondary</SecondaryButtonLevelSection>
          </div>
          <div className="mb-4">
            <SecondaryButtonQuestion>secondary</SecondaryButtonQuestion>
          </div>
        </div>
        <div className="column">
          <h2 className="has-text-centered"> Teritiary Button </h2>
          <div className="mb-4">
            <TertiaryButtonLevelPage>tertiary</TertiaryButtonLevelPage>
          </div>
          <div className="mb-4">
            <TertiaryButtonLevelSection>tertiary</TertiaryButtonLevelSection>
          </div>
          <div className="mb-4">
            <TertiaryButtonQuestion>tertiary</TertiaryButtonQuestion>
          </div>
        </div>
      </div>
      <div className="columns">
        <h2 className="has-text-centered"> Special Button </h2>
        <div className="column">
          <CompanionChatAndHelp>Hỗ trợ</CompanionChatAndHelp>
        </div>
        <div className="column">
          <PopupCloseButton />
        </div>
        <div className="column">
          <ExerciseLessonsListButton />
        </div>
        <div className="column">
          <SelectGradeButton>1</SelectGradeButton>
        </div>
      </div>
      <div className="columns">
        <h2 className="has-text-centered"> Pagination Button </h2>
        <div className="column">
          <div className="mb-4">
            <HelpButton></HelpButton>
          </div>
          <div className="mb-4">
            <NotificationsButton />
          </div>
          <div className="mb-4">
            <CheckBox />
          </div>
          <div className="mb-4">
            <Radio />
          </div>
        </div>
        <div className="column">
          <div className="mb-4">
            <NextButton></NextButton>
          </div>
          <div className="mb-4">
            <PreviousButton />
          </div>
          <div className="mb-4">
            <SkipToFirstButton />
          </div>
          <div className="mb-4">
            <SkipToLastButton />
          </div>
        </div>
        <div className="column">
          <div className="mb-4">
            <PaginationBigButton>1</PaginationBigButton>
          </div>
          <div className="mb-4">
            <PaginationButtonSmall>2</PaginationButtonSmall>
          </div>
          <div className="mb-4">
            <SlideOnSlider />
          </div>
        </div>
      </div>
      <div className="columns">
        <h2 className="has-text-centered"> Input </h2>
        <div className="column">
          <InputText
            label={"Nhập email"}
            type="text"
            name={"email"}
            onChange={(e) => {
              handleChangeInputText(e.target.name, e.target.value);
            }}
          />
        </div>
        <div className="column">
          <InputDate
            type={"date"}
            label={"Nhập ngày"}
            name={"date"}
            value={inputValue?.date}
            onChange={handleChangeInputDate}
          />
        </div>
        <div className="column">
          <Otp
            value={otp}
            onChange={(value, name, index) =>
              handleChangeOtp(value, name, index)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ComponentList;
