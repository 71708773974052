import React, { useEffect, useState } from "react";
import ImageFromUrl from "../../parseImage/ImageFromUrl";

const SkipToLastButton = ({ onClick, disabled, isClick, children }) => {
  const [defaultState, setDefaultState] = useState(disabled ? false : true);
  const [hoveredState, setHoveredState] = useState(false);
  const [clickedState, setClickedState] = useState(isClick ? isClick : false);
  const audio = new Audio("/sounds/click.mp3");
  var style = {
    width: "2.5rem",
    height: "2.5rem",
    border: "none",
    borderRadius: "9999rem",
    boxShadow: "none",
  };
  var imgStyle = {
    position: "absolute",
    left: "30%",
    right: "35%",
    top: "32.5%",
    bottom: "32.5%",
  };
  if (hoveredState) {
    style = {
      ...style,
      filter: "drop-shadow(0rem 0.375rem 0.75rem rgba(10, 42, 102, 0.25))",
    };
  }

  const handleMouseEnter = () => {
    if (!clickedState && !disabled) {
      setDefaultState(false);
      setHoveredState(true);
    }
  };
  const handleMouseLeave = () => {
    if (!clickedState && !disabled) {
      setDefaultState(true);
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    if (!disabled) {
      audio.play();
      setClickedState(!clickedState);
      setHoveredState(false);
      setDefaultState(clickedState);
      if (onClick) {
        onClick();
      }
    }
  };
  useEffect(() => {
    if (disabled === false || disabled === undefined) {
      setDefaultState(true);
    } else {
      setDefaultState(false);
      setClickedState(false);
    }
  }, [disabled]);
  return (
    <div className="main" style={{ maxWidth: "5rem" }}>
      <button
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`${
          defaultState
            ? "is-kurio-main-color-white"
            : hoveredState
            ? "is-kurio-main-color-white"
            : clickedState
            ? "is-kurio-main-color-black "
            : ""
        }  button is-relative main-color has-text-kurio-main-color-white has-text-weight-bold is-uppercase has-text-centered`}
        style={style}
        onClick={handleClick}
        type="button"
      >
        <ImageFromUrl
          style={imgStyle}
          objectId={`${
            disabled
              ? "admin/frontend-images/button/skip-disable-button.svg"
              : clickedState
              ? "admin/frontend-images/button/skip-clicked-button.svg"
              : "admin/frontend-images/button/skip-button.svg"
          }`}
          alt=""
        />
      </button>
    </div>
  );
};

export default SkipToLastButton;
