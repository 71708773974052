import { memo } from "react";
import { useDrag } from "react-dnd";
import BackgroundImageFromUrl from "../../../components/parseImage/BackgroundImageFromUrl";

export const Choice = memo(function Choice({
    name,
    type,
    disable,
    dropOutside,
    index,
    resultStyle,
    indexItem
}) {
    const [{ opacity }, drag] = useDrag(
        () => ({
            type,
            item: { name, index ,indexItem},
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.4 : 1,
            }),
            end: (item, monitor) => {
                const didDrop = monitor.didDrop();
                if (!didDrop) {
                    dropOutside(item);
                }
            },
            canDrag:!disable
        }),
        [name, type, disable]
    );
    return (
        <div ref={drag}>
            <BackgroundImageFromUrl
                objectId={name}
                style={{
                    opacity,
                    height: "7.5rem",
                    width: "9rem",
                    position: "relative",
                    zIndex: 100,
                    borderRadius: "0.5rem",
                    // backgroundImage:`url(${name})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    outline: "0.25rem solid #FAFBFC",
                    // backgroundColor: "#FAFBFC",
                    ...resultStyle,
                }}
            ></BackgroundImageFromUrl>
        </div>
    );
});
