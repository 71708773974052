import React from "react";
import ReactPlayer from "react-player";
import ScrollRef from "../../detail-components/ScrollRef";
import "../../styling/layout-components/MainContentVideo.styles.css";
import MainContent1Title from "./MainContent1Title";

const MainContent6VideoNormal = ({ data, isLast }) => {
    const content = data?.content;
    const contentVideo = content?.video[0];

    return (
        <div className="m-auto has-text-centered">
            {!isLast && <ScrollRef />}
            {data?.title && <MainContent1Title title={data?.title} />}
            <div
                className="main-content-video m-auto"
                style={{
                    maxWidth: "60rem",
                    borderRadius: "1rem",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    width: "100%",
                }}
            >
                <ReactPlayer
                    className="video-react__player"
                    style={{
                        width: "100% !important",
                        margin: "0 auto",
                        aspectRatio: "16/9",
                    }}
                    width="100%"
                    height="auto"
                    url={contentVideo}
                    controls={true}
                />
            </div>
        </div>
    );
};

export default MainContent6VideoNormal;
