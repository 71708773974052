import Cookies from "universal-cookie";
import ConstantStrings from "../../constants/ConstantStrings";

const cookies = new Cookies();

export const checkAndGetAnonymousUserId = () => {
  var anonymousUserId = cookies.get("anonymousUser");
  if (anonymousUserId) {
    return anonymousUserId;
  } else {
    let uuid = window.crypto.randomUUID();
    anonymousUserId = uuid.split("-").join("").substring(0, 24);

    cookies.set("anonymousUser", anonymousUserId, { path: '/', maxAge: 24 * 60 * 60 });

    return anonymousUserId;
  }
};

export const getCategoryRoadmap = (gradeName) => {
  switch (true) {
    case ConstantStrings.GRADES_USE_CATEGORY_ROADMAP_1.includes(gradeName):
      return ConstantStrings.CATEGORY_ROADMAP_1;
    case ConstantStrings.GRADES_USE_CATEGORY_ROADMAP_2.includes(gradeName):
      return ConstantStrings.CATEGORY_ROADMAP_2;
    default:
      return ConstantStrings.CATEGORY_ROADMAP_1;
  }
};
