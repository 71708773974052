import React from "react";

const Tile = ({ letter, color }) => {
  const bgStyle = {
    backgroundColor: color,
    outline: "2px solid ",
    outlineColor: color !== "white" ? color : "#cccccc",
  };

  return (
    <div
      className={`${color === "white" ?"has-text-kurio-main-color-black":"has-text-kurio-main-color-white"} tile-numberle has-text-centered is-flex is-justify-content-center is-align-items-center has-text-weight-bold`}
      style={bgStyle}
    >
      {letter}
    </div>
  );
};

export default Tile;
