/* eslint-disable react-hooks/exhaustive-deps */
// Description: Drag and Drop question template
import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import SecondaryButtonQuestion from "../../components/button/secondary-button/SecondaryButtonQuestion";
import TertiaryButtonQuestion from "../../components/button/tertiary-button/TertiaryButtonQuestion";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import ConstantStrings from "../../constants/ConstantStrings";
import {
  setCorrectAnswer,
  setCorrectQuestion,
  setQuestionFinished,
  setQuestionState,
  setQuestionVisible,
  setWrongAnswer,
} from "../../reducers/practiceElectiveSlice";
import jsonParser from "../helper/jsonParser";
import { ChoiceContainer } from "./dragdrop-components/ChoiceContainer";
import { ItemTypes } from "./dragdrop-components/ItemTypes";
import { Target } from "./dragdrop-components/Target";

export const DragDropMulti = memo(function DragDropMulti({
  question,
  explanation,
  questionIndex,
  isLast,
  nextQuestion,
  showEndPractice,
  submitAnswers,
  setNextScroll,
  isPreview,
  seenQuestions,
}) {
  const dispatch = useDispatch();
  const questionTexts =
    question.texts !== null &&
      question.texts !== undefined &&
      question.texts?.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;
  // const [imgWidth, setImgWidth] = useState(0);
  const questionChoices = question.choices;
  const questionTargets =
    question.targets?.length > 0
      ? question.targets
      : question.choices.map((item) => "");
  const questionSolutions = question.solutions;
  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      explanation.texts.some((item) => item?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      explanation.images.some((item) => item?.length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }

  const correctStyle = {
    backgroundColor: "#E5F0CC",
    borderColor: "#4B9631",
  };

  const incorrectStyle = {
    backgroundColor: "#F8E3E3",
    borderColor: "#E34D4D",
  };
  var iconStyle = {
    width: "1.5rem",
    height: "1.5rem",
    zIndex: 2,
    position: "relative",
    top: "-0.75rem",
    left: "0rem",
  };
  var exerciseState = JSON.parse(localStorage.getItem("exerciseState"));
  const setLocalStorage = (
    answer,
    correct,
    isCorrect,
    checkingState,
    explanationState,
    answerStyle,
    targets
  ) => {
    localStorage.setItem(
      "exerciseState",
      JSON.stringify({
        correct,
        answer,
        questionState: {
          index: questionIndex,
          state: {
            isCorrect: isCorrect,
            checkingState: checkingState,
            explanationState: explanationState,
            answerStyle: answerStyle,
            answers: targets.map((target) => target.lastDroppedItem),
            indexItems: targets.map((target) => target.indexItem),
          },
        },
      })
    );
  };

  //create a state variable to store the correct state
  const [isCorrect, setIsCorrect] = useState(() => {
    if (question.state) {
      return question.state.isCorrect;
    } else {
      return false;
    }
  });
  //create a state variable to store the checking state
  const [checkingState, setCheckingState] = useState(() => {
    if (question.state) {
      return question.state.checkingState;
    } else {
      return false;
    }
  });
  //create a state variable to store the explanation state
  const [explanationState, setExplanationState] = useState(() => {
    if (question.state) {
      return question.state.explanationState;
    } else {
      return false;
    }
  });

  const [indexItems] = useState(() => {
    if (question.state) {
      return question.state.indexItems;
    } else {
      return null;
    }
  });

  const [answers] = useState(() => {
    if (question.state) {
      return question.state.answers;
    } else {
      return null;
    }
  });

  //create a state variable to store the choices
  const [choices] = useState(
    questionChoices.map((choice, index) => {
      return {
        name: choice,
        type: ItemTypes.CHOICE,
        id: index,
      };
    })
  );

  //create a state variable to store the targets
  const [targets, setTargets] = useState(
    questionTargets.map((target, index) => {
      return {
        name: target,
        accepts: [ItemTypes.CHOICE],
        lastDroppedItem: answers ? answers[index] : null,
        indexItem: indexItems ? indexItems[index] : null,
        id: index,
      };
    })
  );

  const [targetsSolution] = useState(
    questionTargets.map((target, index) => {
      return {
        name: target,
        accepts: [ItemTypes.CHOICE],
        lastDroppedItem: questionChoices[questionSolutions[index] - 1],
        id: index,
      };
    })
  );

  const [answerSolutionStyle] = useState(() => {
    if (questionSolutions) {
      let correct = true;
      let newAnswerStyle = [];
      for (let i = 0; i < targetsSolution.length; i++) {
        // console.log(questionChoices.indexOf(targets[i].lastDroppedItem) + 1);

        if (questionSolutions[i]) {
          //   console.log(12);
          //   correct = false;
          //   newAnswerStyle.push(incorrectStyle);
          // } else {
          //   console.log(1);

          newAnswerStyle.push(correctStyle);
        }
      }
      if (correct) {
        setIsCorrect(true);
      } else {
        setIsCorrect(false);
      }

      return newAnswerStyle;
    } else {
      return [];
    }
  });

  const [answerStyle, setAnswerStyle] = useState(() => {
    if (question.state) {
      return question.state.answerStyle;
    } else {
      if (explanationState) {
        let correct = true;
        let newAnswerStyle = [];
        for (let i = 0; i < targets.length; i++) {
          if (
            questionChoices.indexOf(targets[i].lastDroppedItem) + 1 !==
            questionSolutions[i]
          ) {
            correct = false;
            newAnswerStyle.push(incorrectStyle);
          } else {
            newAnswerStyle.push(correctStyle);
          }
        }
        if (correct) {
          setIsCorrect(true);
        } else {
          setIsCorrect(false);
        }
        return newAnswerStyle;
      } else {
        return [];
      }
    }
  });

  const isChoiceSelected = (choice, indexItem) => {
    let selected = true;
    targets.forEach((target) => {
      if (
        (target.lastDroppedItem !== choice && target.indexItem !== indexItem) ||
        target.indexItem !== null
      ) {
        selected = false;
      }
    });
    return selected;
  };

  const dropOutside = (item) => {
    var answer = questionSolutions.map((item) => false);
    if (item.index !== -1) {
      setTargets((targets) => {
        return targets.map((target, i) => {
          if (i === item.index) {
            return {
              ...target,
              lastDroppedItem: null,
              indexItem: null,
            };
          } else {
            return target;
          }
        });
      });
      const newTargets = targets.map((target, i) => {
        if (i === item.index) {
          return {
            ...target,
            lastDroppedItem: null,
            indexItem: null,
          };
        } else {
          return target;
        }
      });
      if (newTargets.some((target) => target.lastDroppedItem != null)) {
        newTargets.map(
          (target, index) =>
          (answer[index] =
            target.indexItem == null ? false : target.indexItem + 1)
        );
      } else {
        answer = null;
      }
      const newAnswerStyle = answerStyle.map((style, i) => {
        if (i === item.index) {
          return {
            $set: null,
          };
        } else {
          return style;
        }
      });
      setLocalStorage(
        answer,
        null,
        isCorrect,
        checkingState,
        explanationState,
        newAnswerStyle,
        newTargets
      );
      setAnswerStyle((answerStyle) => {
        return answerStyle.map((style, i) => {
          if (i === item.index) {
            return {
              $set: null,
            };
          } else {
            return style;
          }
        });
      });
    }
  };

  const handleDrop = useCallback(
    (index, item) => {
      var answer = questionSolutions.map((item) => false);
      if (item.index !== -1) {
        setTargets((targets) => {
          return targets.map((target, i) => {
            if (i === index) {
              return {
                ...target,
                lastDroppedItem: item.name,
                indexItem: item.indexItem,
              };
            } else if (i === item.index) {
              return {
                ...target,
                lastDroppedItem: null,
                indexItem: null,
              };
            } else {
              return target;
            }
          });
        });
        const newTargets = targets.map((target, i) => {
          if (i === index) {
            return {
              ...target,
              lastDroppedItem: item.name,
              indexItem: item.indexItem,
            };
          } else if (i === item.index) {
            return {
              ...target,
              lastDroppedItem: null,
              indexItem: null,
            };
          } else {
            return target;
          }
        });
        if (newTargets.some((target) => target.lastDroppedItem != null)) {
          newTargets.map(
            (target, index) =>
            (answer[index] =
              target.indexItem === null ? false : target.indexItem + 1)
          );
        } else {
          answer = null;
        }
        const newAnswerStyle = answerStyle.map((style, i) => {
          if (i === index) {
            return {
              $set: null,
            };
          } else if (i === item.index) {
            return {
              $set: null,
            };
          } else {
            return style;
          }
        });
        setLocalStorage(
          answer,
          null,
          isCorrect,
          checkingState,
          explanationState,
          newAnswerStyle,
          newTargets
        );

        setAnswerStyle((answerStyle) => {
          return answerStyle.map((style, i) => {
            if (i === index) {
              return {
                $set: null,
              };
            } else if (i === item.index) {
              return {
                $set: null,
              };
            } else {
              return style;
            }
          });
        });
      } else {
        setTargets((targets) => {
          return targets.map((target, i) => {
            if (i === index) {
              return {
                ...target,
                lastDroppedItem: item.name,
                indexItem: item.indexItem,
              };
            } else {
              return target;
            }
          });
        });
        const newTargets = targets.map((target, i) => {
          if (i === index) {
            return {
              ...target,
              lastDroppedItem: item.name,
              indexItem: item.indexItem,
            };
          } else {
            return target;
          }
        });
        if (newTargets.some((target) => target.lastDroppedItem != null)) {
          newTargets.map(
            (target, index) =>
            (answer[index] =
              target.indexItem === null ? false : target.indexItem + 1)
          );
        } else {
          answer = null;
        }
        const newAnswerStyle = answerStyle.map((style, i) => {
          if (i === index) {
            return {
              $set: null,
            };
          } else {
            return style;
          }
        });
        setLocalStorage(
          answer,
          null,
          isCorrect,
          checkingState,
          explanationState,
          newAnswerStyle,
          newTargets
        );
        setAnswerStyle((answerStyle) => {
          return answerStyle.map((style, i) => {
            if (i === index) {
              return {
                $set: null,
              };
            } else {
              return style;
            }
          });
        });
      }
    },
    [targets]
  );

  const handleCheck = () => {
    if (!isPreview) {
      setNextScroll(true)
    }
    setCheckingState(true);
    let correct = true;
    let newAnswerStyle = [];
    let answer = questionSolutions.map((item) => false);
    answer = targets.map((target) => target.indexItem + 1);
    for (let i = 0; i < targets.length; i++) {
      if (
        targets[i].indexItem === null ||
        targets[i].indexItem + 1 !== questionSolutions[i]
      ) {
        correct = false;
        newAnswerStyle.push(incorrectStyle);
      } else {
        newAnswerStyle.push(correctStyle);
      }
    }
    setAnswerStyle(newAnswerStyle);
    setLocalStorage(
      answer,
      correct,
      correct,
      true,
      explanationState,
      newAnswerStyle,
      targets
    );
    if (!isPreview || isPreview === undefined) {
      const exerciseStateLocal = JSON.parse(
        localStorage.getItem("exerciseState")
      );
      dispatch(setQuestionState(exerciseStateLocal.questionState));
      submitAnswers(answer);
      dispatch(
        setCorrectQuestion({
          questionIndex: questionIndex,
          correct: correct,
        })
      );
      if (correct) {
        dispatch(setCorrectAnswer(1));
      } else {
        dispatch(setWrongAnswer(1));
      }
    }
    if (correct) {
      dispatch(setCorrectAnswer(1));
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
      dispatch(setWrongAnswer(1));
    }
  };

  // const handleReset = () => {
  //   setCheckingState(false);
  //   const newTargets = targets.map((target, index) => {
  //     return {
  //       name: target.name,
  //       accepts: [ItemTypes.CHOICE],
  //       lastDroppedItem: null,
  //       indexItem: null,
  //       id: index,
  //     };
  //   });
  //   setTargets(newTargets);
  //   setLocalStorage(
  //     null,
  //     null,
  //     isCorrect,
  //     false,
  //     explanationState,
  //     [],
  //     newTargets
  //   );
  //   setAnswerStyle([]);
  // };

  const handleShowExplanation = () => {
    setExplanationState(true);
  };

  const showNextQuestion = () => {
    if (!isPreview) {
      setNextScroll(false)
    }
    if (exerciseState === null || !checkingState) {
      setLocalStorage(
        null,
        null,
        isCorrect,
        checkingState,
        explanationState,
        answerStyle,
        targets
      );
      if (!isPreview || isPreview === undefined) {
        submitAnswers(null);
      }
    }
    if (!isPreview || isPreview === undefined) {
      dispatch(setQuestionFinished(questionIndex));
      if (!isLast) {
        nextQuestion();
      } else {
        showEndPractice();
      }
    } else {
      const exerciseState = JSON.parse(localStorage.getItem("exerciseState"));
      dispatch(setQuestionState(exerciseState.questionState));
      dispatch(setQuestionVisible(questionIndex + 1));
    }
  };

  return (
    <div
      className="has-background-kurio-main-color-light-gray"
      style={{ borderRadius: "0 0 1rem 1rem" }}
    >
      {!explanationState && (
        <div
          className="question-container is-flex is-flex-direction-column has-text-kurio-main-color-black"
          style={{ padding: "1.5rem 2rem" }}
        >
          {questionTexts !== null &&
            questionTexts !== undefined &&
            questionTexts?.length > 0 && (
              <div
                className="has-text-centered m-auto"
                style={{ maxWidth: "40rem" }}
              >
                {questionTexts}
              </div>
            )}

          <div
            className="is-flex is-flex-direction-row is-justify-content-center is-align-items-start has-background-kurio-gray-light-1"
            style={{
              padding: "1rem 0rem",
              borderRadius: "1rem 1rem 0 0",
            }}
          >
            {targets.length >= 1 &&
              targets.length <= 4 &&
              targets.map(
                ({ name, accepts, lastDroppedItem, indexItem }, index) => (
                  <Target
                    content={name}
                    accept={accepts}
                    lastDroppedItem={lastDroppedItem}
                    onDrop={(item) => handleDrop(index, item)}
                    disable={checkingState}
                    resultStyle={answerStyle[index]}
                    iconStyle={iconStyle}
                    key={index}
                    choices={choices}
                    dropOutside={dropOutside}
                    indexItem={indexItem}
                    index={index}
                  />
                )
              )}
          </div>
          <div
            className="has-background-kurio-gray-light-2 is-flex is-flex-direction-row is-justify-content-center is-align-items-start m-0"
            style={{
              padding: "1rem 0",
              borderRadius: "0 0 1rem 1rem",
            }}
          >
            {(choices.length === 2 ||
              choices.length === 3 ||
              choices.length === 4) && (
                <div
                  className="is-flex is-flex-direction-row is-justify-content-center is-align-items-start m-0"
                  style={{
                    padding: "1rem 0",
                    borderRadius: "0 0 1rem 1rem",
                  }}
                >
                  {choices.map(({ name, type }, index) => (
                    <div
                      key={index}
                      style={{
                        padding: "1rem",
                      }}
                    >
                      <ChoiceContainer
                        name={name}
                        type={type}
                        key={index}
                        indexItem={index}
                        isChoiceSelected={isChoiceSelected(name, index)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                  ))}
                </div>
              )}
            {choices.length === 5 && (
              <>
                <div
                  style={{
                    padding: "1rem 0",
                    borderRadius: "0 0 1rem 1rem",
                  }}
                >
                  <div className="columns is-centered">
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[0].name}
                        type={choices[0].type}
                        key={0}
                        indexItem={0}
                        isChoiceSelected={isChoiceSelected(choices[0].name, 0)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[1].name}
                        type={choices[1].type}
                        key={1}
                        indexItem={1}
                        isChoiceSelected={isChoiceSelected(choices[1].name, 1)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[2].name}
                        type={choices[2].type}
                        key={2}
                        indexItem={2}
                        isChoiceSelected={isChoiceSelected(choices[2].name, 2)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                  </div>
                  <div className="columns is-centered">
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[3].name}
                        type={choices[3].type}
                        key={3}
                        indexItem={3}
                        isChoiceSelected={isChoiceSelected(choices[3].name, 3)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[4].name}
                        type={choices[4].type}
                        key={4}
                        indexItem={4}
                        isChoiceSelected={isChoiceSelected(choices[4].name, 4)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {choices.length === 6 && (
              <>
                <div
                  style={{
                    padding: "1rem 0rem",
                    borderRadius: "0 0 1rem 1rem",
                  }}
                >
                  <div className="columns is-centered">
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[0].name}
                        type={choices[0].type}
                        key={0}
                        indexItem={0}
                        isChoiceSelected={isChoiceSelected(choices[0].name, 0)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[1].name}
                        type={choices[1].type}
                        key={1}
                        indexItem={1}
                        isChoiceSelected={isChoiceSelected(choices[1].name, 1)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[2].name}
                        type={choices[2].type}
                        key={2}
                        indexItem={2}
                        isChoiceSelected={isChoiceSelected(choices[2].name, 2)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                  </div>
                  <div className="columns is-centered">
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[3].name}
                        type={choices[3].type}
                        key={3}
                        indexItem={3}
                        isChoiceSelected={isChoiceSelected(choices[3].name, 3)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[4].name}
                        type={choices[4].type}
                        key={4}
                        indexItem={4}
                        isChoiceSelected={isChoiceSelected(choices[4].name, 4)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[5].name}
                        type={choices[5].type}
                        key={5}
                        indexItem={5}
                        isChoiceSelected={isChoiceSelected(choices[5].name, 5)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {/* only appears when explanationState is true */}
      {explanationState && (
        <>
          <div
            className="
            is-flex is-flex-direction-column is-is-align-items-center is-justify-content-center has-text-kurio-main-color-black"
            style={{
              gap: "1.5rem",
              padding: "1.5rem",
            }}
          >
            <div
              className="tag has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
              style={{
                borderRadius: "1rem",
                fontSize: "1.25rem",
                margin: "0 auto",
              }}
            >
              <p
                style={{
                  fontWeight: 700,
                }}
              >
                Đáp án
              </p>
            </div>
            <div className="question-container is-flex is-flex-direction-column has-text-kurio-main-color-black">
              <div
                className="is-flex is-flex-direction-row is-justify-content-center is-align-items-start has-background-kurio-gray-light-1"
                style={{
                  padding: "1.5rem 0rem",
                  borderRadius: "1rem 1rem 1rem 1rem",
                }}
              >
                {targets.length >= 1 &&
                  targets.length <= 4 &&
                  targetsSolution.map(
                    ({ name, accepts, lastDroppedItem, indexItem }, index) => (
                      <Target
                        content={name}
                        accept={accepts}
                        lastDroppedItem={lastDroppedItem}
                        onDrop={(item) => handleDrop(index, item)}
                        disable={checkingState}
                        resultStyle={answerSolutionStyle[index]}
                        iconStyle={iconStyle}
                        key={index}
                        choices={choices}
                        dropOutside={dropOutside}
                        indexItem={indexItem}
                        index={index}
                      />
                    )
                  )}
              </div>
            </div>

            {(explanationTexts !== null &&
              explanationTexts !== undefined &&
              explanationTexts?.length > 0) ||
              (explanationImages !== null &&
                explanationImages !== undefined &&
                explanationImages?.length > 0) ? (
              <div
                className="explanation-container has-text-kurio-main-color-black is-flex is-flex-direction-column is-align-items-center"
                style={{
                  gap: "1.5rem",
                  fontSize: "1.25rem",
                }}
              >
                <div
                  className="tag has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
                  style={{
                    borderRadius: "1rem",
                    fontSize: "1.25rem",
                  }}
                >
                  <p
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    Giải thích
                  </p>
                </div>
                <div
                  className="is-flex is-flex-direction-column is-align-items-center"
                  style={{
                    gap: "1.5rem",
                    fontSize: "1.25rem",
                    padding: "1rem 1.5rem",
                    width: "100%",
                  }}
                >
                  <>
                    {((explanationTexts !== null &&
                      explanationTexts !== undefined &&
                      explanationTexts?.length > 0) ||
                      (explanationImages !== null &&
                        explanationImages !== undefined &&
                        explanationImages?.length > 0)) &&
                      explanation.texts?.length >= explanation.images?.length && (
                        <>
                          {explanation.texts.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="is-flex is-flex-direction-column has-text-justified has-text-kurio-main-color-black"
                                style={{
                                  gap: "1.5rem",
                                  width: "100%",
                                }}
                              >
                                {explanationTexts !== null &&
                                  explanationTexts !== undefined &&
                                  item?.length > 0 && (
                                    <div key={index}>{explanationTexts[index]}</div>
                                  )}
                                {/* only appears when explanationImages has length greater than 0 */}
                                {explanationImages !== null &&
                                  explanationImages !== undefined &&
                                  explanationImages[index]?.length > 0 && (
                                    <div
                                      className="column is-narrow is-flex is-justify-content-center is-align-items-center is-centered m-0 p-0"
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <ImageFromUrl
                                        objectId={explanationImages[index]}
                                      />
                                    </div>
                                  )}
                              </div>
                            );
                          })}
                        </>
                      )}
                    {((explanationTexts !== null &&
                      explanationTexts !== undefined &&
                      explanationTexts?.length > 0) ||
                      (explanationImages !== null &&
                        explanationImages !== undefined &&
                        explanationImages?.length > 0)) &&
                      explanation.texts?.length < explanation.images?.length && (
                        <>
                          {explanationImages.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="is-flex is-flex-direction-column has-text-justified has-text-kurio-main-color-black"
                                style={{
                                  gap: "1.5rem",
                                  width: "100%",
                                }}
                              >
                                {explanationTexts !== null &&
                                  explanationTexts !== undefined &&
                                  explanation.texts[index]?.length > 0 && (
                                    <div key={index}>{explanationTexts[index]}</div>
                                  )}
                                {/* only appears when explanationImages has length greater than 0 */}
                                {explanationImages !== null &&
                                  explanationImages !== undefined &&
                                  item?.length > 0 && (
                                    <div
                                      className="column is-narrow is-flex is-justify-content-center is-align-items-center is-centered m-0 p-0"
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <ImageFromUrl
                                        objectId={explanationImages[index]}
                                      />
                                    </div>
                                  )}
                              </div>
                            );
                          })}
                        </>
                      )}
                  </>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}

      <div
        className={` is-flex is-flex-direction-row ${!isPreview
          ? !question.state
            ? checkingState &&
              isCorrect &&
              explanation?.texts?.every((item) => item?.length <= 0) &&
              explanation?.images?.every((item) => item?.length <= 0)
              ? "is-align-items-center is-justify-content-center"
              : "is-align-items-center is-justify-content-center"
            : questionIndex < seenQuestions - 1
              ? "is-align-items-center is-justify-content-center"
              : checkingState &&
                isCorrect &&
                explanation?.texts?.every((item) => item?.length <= 0) &&
                explanation?.images?.every((item) => item?.length <= 0)
                ? "is-align-items-center is-justify-content-center"
                : "is-align-items-center is-justify-content-center"
          : "is-align-items-center is-justify-content-center"
          }`}
        style={{ padding: "0 2rem 2rem", gap: "1rem" }}
      >
        {/* only appears when checkingState is false */}
        {!checkingState && (
          <PrimaryButtonQuestion
            className="button"
            onClick={handleCheck}
            disabled={targets.every((item) => item.lastDroppedItem === null)}
          >
            {ConstantStrings.SUBMIT}
          </PrimaryButtonQuestion>
        )}

        {/* Next and "Xem giai thich" button appears when checkingState is true and all selected answers is correct */}
        {checkingState && !explanationState && (
          <span>
            <SecondaryButtonQuestion
              className="button"
              onClick={handleShowExplanation}
              defaultColor={"is-kurio-purple-light-2"}
            >
              {ConstantStrings.SHOW_EXPLANATION_PRACTICE}
            </SecondaryButtonQuestion>
          </span>
        )}
        {/* "Xem cau hoi" button appears when explanationState is true */}
        {explanationState && (
          <SecondaryButtonQuestion
            className="button"
            onClick={() => {
              // setLocalStorage(
              //   null,
              //   null,
              //   isCorrect,
              //   checkingState,
              //   false,
              //   answerStyle,
              //   targets
              // );
              setExplanationState(false);
            }}
            defaultColor={"is-kurio-purple-light-2"}
          >
            {ConstantStrings.SHOW_QUESTION}
          </SecondaryButtonQuestion>
        )}
        {checkingState && (
          <div
            className={` ${question.state && questionIndex < seenQuestions - 1
              }`}
          >
            <PrimaryButtonQuestion onClick={showNextQuestion}>
              {ConstantStrings.NEXT}
            </PrimaryButtonQuestion>
          </div>
        )}
        {!checkingState && (
          <div
            className={` ${question.state && questionIndex < seenQuestions - 1 && !isPreview
              ? "is-hidden"
              : ""
              }`}
          >
            <TertiaryButtonQuestion
              onClick={showNextQuestion}
              rotate={true}
              suffix={"admin/frontend-images/button/button-skip-icon"}
            >
              {ConstantStrings.SKIP}
            </TertiaryButtonQuestion>
          </div>
        )}
      </div>
    </div>
  );
});

export default DragDropMulti;
