/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import "./GapFill.style.css";
const VerticalGapFill = ({
    choice,
    answer,
    correctAnswers,
    onChange,
    checkingState,
    placeholder,
    index,
}) => {
    var gapStyle = {
        width: "10rem",
        height: "3rem",
        padding:"0.5rem",
        borderRadius: "0.5rem",
        fontWeight: "bold",
        border: "0.0625rem solid",
        fontSize:"1.125rem"
    };
    var focusStyle = {
        ...gapStyle,
        border: "0.125rem solid",
    };

    var iconStyle = {
        zIndex: 100,
        position: "relative",
        width: "1.5rem",
        height: "1.5rem",
        top: "0.75rem",
        left: "-0.75rem",
    };
    const [focus, setFocus] = useState(false);
    const handleChangeValue = (index) => (e) => {
        e.preventDefault();
        const regex =  /^[a-zA-Z0-9.,\/]+$/
        var value = e.target.value
        if(regex.test(value) === true ||  !value){
          onChange(e);
        }
    };
    const IconComponent = ({ index }) => {
        return (
            <>
                {checkingState ? (
                    correctAnswers === true ? (
                        <img
                            src="/images/common/greenV.svg"
                            style={iconStyle}
                            alt="loading"
                        />
                    ) : (
                        <img
                            src="/images/common/redX.svg"
                            style={iconStyle}
                            alt="loading"
                        />
                    )
                ) : (
                    <div className="icon" style={{...iconStyle, width:"0.75rem", height:"0.75rem"}}></div>
                )}
            </>
        );
    };

    return (
        <div>
            <div
                className="column is-flex is-flex-direction-column is-relative is-narrow p-0"
                style={{ gap: "1rem" , padding:"0.5rem"}}
            >
                <div>
                    <input
                        style={focus && !checkingState ? focusStyle : gapStyle}
                        onFocus={() => {
                            setFocus(true);
                        }}
                        onBlur={() => {
                            setFocus(false);
                        }}
                        className={`${
                            correctAnswers === true
                                ? "has-background-kurio-green-light-3 is-kurio-green-main-accent has-text-kurio-green-main-accent"
                                : correctAnswers === false
                                ? "has-background-kurio-red-light-3 is-kurio-red-main-accent has-text-kurio-red-main-accent"
                                : focus
                                ? "has-background-kurio-main-color-white has-text-kurio-main-color-black is-kurio-main-color-black"
                                : ""
                        } input is-shadowless has-text-kurio-main-color-black has-text-weight-bold has-text-centered `}
                        type="text"
                        placeholder={placeholder ? placeholder : "  Trả lời..."}
                        value={answer || ""}
                        onChange={handleChangeValue(index)}
                        disabled={checkingState}
                    />
                    <IconComponent index={index} />
                </div>
            </div>
        </div>
    );
};

export default VerticalGapFill;
