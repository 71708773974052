import React, { useEffect, useState } from "react";
import BackgroundImageFromUrl from "../../parseImage/BackgroundImageFromUrl";

const PaginationButtonSmall = ({
  disabled,
  isClick,
  onClick,
  children,
  isCorrect,
}) => {
  var style = {
    width: "2rem",
    height: "2rem",
    border: "none",
    borderRadius: "9999rem",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    boxShadow: "none",
  };

  const [defaultState, setDefaultState] = useState(disabled ? false : true);
  const [hoveredState, setHoveredState] = useState(false);
  const [clickedState, setClickedState] = useState(isClick ? isClick : false);
  const audio = new Audio("/sounds/click.mp3");
  var objectId = "";
  if (hoveredState) {
    style = {
      ...style,
      filter: "drop-shadow(0rem 0.375rem 1rem rgba(10, 42, 102, 0.25))",
    };
    if (isCorrect !== undefined && isCorrect !== null) {
      if (isCorrect) {
        objectId =
          "admin/frontend-images/button/pagination-unit-quizz-success-hover.svg";
      } else {
        objectId =
          "admin/frontend-images/button/pagination-unit-quiz-failed-hover.svg";
      }
    } else {
      objectId =
        "admin/frontend-images/button/pagination-button-small-hovered-bg.svg";
    }
  }
  if (defaultState) {
    style = {
      ...style,

      filter: "drop-shadow(0rem 0.375rem 1rem rgba(10, 42, 102, 0.25))",
    };
    if (isCorrect !== undefined && isCorrect !== null) {
      if (isCorrect) {
        objectId =
          "admin/frontend-images/button/pagination-unit-quiz-success-default.svg";
      } else {
        objectId =
          "admin/frontend-images/button/pagination-unit-quiz-failed-default.svg";
      }
    } else {
      objectId =
        "admin/frontend-images/button/pagination-button-small-default.svg";
    }
  }
  if (clickedState) {
    if (isCorrect !== undefined && isCorrect !== null) {
      if (isCorrect) {
        objectId =
          "admin/frontend-images/button/pagination-unit-quiz-click-success.svg";
      } else {
        objectId =
          "admin/frontend-images/button/pagination-unit-quiz-click-failed.svg";
      }
    } else {
      objectId =
        "admin/frontend-images/button/pagination-button-small-clicked-bg.svg";
    }
  }

  const handleMouseEnter = () => {
    if (!clickedState && !disabled) {
      setDefaultState(false);
      setHoveredState(true);
    }
  };
  const handleMouseLeave = () => {
    if (!clickedState && !disabled) {
      setDefaultState(true);
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    if (!disabled && !isClick) {
      setClickedState(!clickedState);
      setHoveredState(false);
      setDefaultState(clickedState);
      audio.play();
      if (onClick) {
        onClick();
      }
    }
    if (!disabled && isClick) {
      // setClickedState(!clickedState);
      // setHoveredState(false);
      // setDefaultState(!clickedState);
      audio.play();
      if (onClick) {
        onClick();
      }
    }
  };

  useEffect(() => {
    if (!disabled && isClick !== undefined) {
      setDefaultState(!isClick);
      setClickedState(isClick);
    } else {
      setDefaultState(!disabled);
      setClickedState(!disabled);
    }
  }, [disabled, isClick]);
  return (
    <div
      className="main"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ maxWidth: "2rem" }}
    >
      <BackgroundImageFromUrl
        objectId={objectId}
        className={`${
          defaultState && !isClick
            ? isCorrect === undefined || isCorrect === null
              ? "is-kurio-purple-light-2 has-text-kurio-main-color-purple"
              : isCorrect === true
              ? "is-kurio-green-light-1 has-text-kurio-main-color-white"
              : "is-kurio-red-light-1 has-text-kurio-main-color-white"
            : hoveredState
            ? isCorrect === undefined || isCorrect === null
              ? "is-kurio-main-color-white has-text-kurio-main-color-purpler"
              : isCorrect === true
              ? "is-kurio-green-light-1 has-text-kurio-main-color-white"
              : "is-kurio-red-light-1 has-text-kurio-main-color-white"
            : clickedState
            ? isCorrect === undefined || isCorrect === null
              ? "is-kurio-main-color-purple-main-accent has-text-kurio-main-color-white"
              : isCorrect === true
              ? "is-kurio-green-dark has-text-kurio-main-color-white"
              : "is-kurio-red-dark has-text-kurio-main-color-white"
            : disabled
            ? "is-kurio-gray-light-2 has-text-kurio-gray-main-accent"
            : ""
        }  button  main-color has-text-weight-bold is-uppercase has-text-centered`}
        style={style}
        onClick={handleClick}
        type="button"
      >
        {children}
      </BackgroundImageFromUrl>
    </div>
  );
};

export default PaginationButtonSmall;
