import instance from "./instance";

const practiceUrl = "/practice/v1/user/practice-topic";
const topicUrl = "/practice/v1/user/topic";

export const getAPracticeTopicQuestion = (id, token, page, size) => {
  return instance.get(
    `${practiceUrl}/get-practice-questions/${id}?page=${1}&size=${30}`
  );
};

export const createNewPracticesTopic = (body, token) => {
  // checkAndCallDailyLoginAPI()
  return instance.post(`${practiceUrl}/create-new-practice`, body);
};
export const getALLTopic = () => {
  return instance.get(`${topicUrl}/get-all-topics`);
};
export const submitPracticeTopicAnswer = (id, answer)=>{
  return instance.post(`${practiceUrl}/submit-practice-answers/${id}`,answer)
}