import { memo } from "react";
import { Choice } from "./Choice";
var style = {
  height: "7.5rem",
  width: "9rem",
  color: "#000000",
  borderRadius: "0.5rem",
  cursor: "move",
};
export const ChoiceContainer = memo(function ChoiceContainer({
  name,
  type,
  isChoiceSelected,
  disable,
  dropOutside,
  indexItem,
}) {
  return (
    <div
      style={
        !isChoiceSelected
          ? style
          : { ...style, outline: "0.25rem dashed #FAFBFC" }
      }
    >
      <div
        style={
          !isChoiceSelected
            ? style
            : {
                ...style,
                height: "7.5rem",
                width: "9rem",
                borderRadius: "0.25rem",
                backgroundColor: "#FAFBFC",
              }
        }
      >
        {!isChoiceSelected && (
          <Choice
            name={name}
            type={type}
            disable={disable}
            dropOutside={dropOutside}
            index={-1}
            indexItem={indexItem}
          />
        )}
      </div>
    </div>
  );
});
