// a React template for Gapfill type question

import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import VerticalGapFill from "../../components/question/VerticalGapFill";
import ConstantStrings from "../../constants/ConstantStrings";
import QuestionNumberTag from "../../detail-components/QuestionNumberTag";
import {
    selectAnsweredQuestions,
    setQuestionAnswered,
    setQuestionFinished,
    setQuestionsAnswered,
    setQuestionState,
    submitAnswers
} from "../../reducers/practiceQuestionSlice";
import "../../styling/layout-components/MultipleScroll.style.css";
import jsonParser from "../helper/jsonParser";

function VerticalGapfill({
  data,
  order,
  question,
  explanation,
  backgroundImage,
  questionIndex,
  questions,
  practiceQuizId,
  seenQuestions,
  handleQuestionChange,
  questionId,
}) {
  var questionTexts =
    question.texts !== null &&
    question.texts !== undefined &&
    question.texts?.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;
  var questionChoices = question.choices;
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const token = cookies.get("signinUser");
  const answeredQuestions = useSelector(selectAnsweredQuestions);

  const [answersItem] = useState(() => {
    return questions
      .filter((question) => question.questionIndex === questionIndex)
      .map((item) => item.answer)
      .flat();
  });
  const [answers, setAnswers] = useState(() => {
    if (question.state) {
      return question.state.answers;
    } else {
      if (answersItem && answersItem.length > 0) {
        return answersItem;
      } else {
        return [];
      }
    }
  });
  const [gapFillWithImages, setGapFillWithImages] = useState(false);

  const [maxWidth, setMaxWidth] = useState(60);

  var nextButtonStyle = {
    width: "100%",
    padding: "1rem 0",
    marginTop: "2.5rem",
  };
  const divRef = useRef(null);

  var backgroundStyle = {
    borderRadius: "1rem 0 0 1rem",
    height: "43.5rem",
    width: "50%",
  };
  var backgroundLeftStyle = {};
  if (backgroundImage[0] !== null && backgroundImage[0]) {
    backgroundLeftStyle = {
      ...backgroundStyle,
      width: "100%",
      // backgroundImage: `url(${memoizedImageData[1][0]})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundLeftStyle = {
      ...backgroundStyle,
      width: "100%",
    };
  }
  if (backgroundImage[1] !== null && backgroundImage[1]) {
    backgroundStyle = {
      ...backgroundStyle,
      borderRadius: "0 1rem 1rem 0",
      // backgroundImage: `url(${memoizedImageData[1][1]})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundStyle = {
      ...backgroundStyle,
      borderRadius: "0 1rem 1rem 0",
      backgroundColor: "#E9EEF8",
    };
  }

  // check first time render if questionChoices are not empty strings
  useEffect(() => {
    if (
      questionChoices !== null &&
      questionChoices &&
      questionChoices.length > 0 &&
      questionChoices[0] !== null &&
      questionChoices[0].length > 0
    ) {
      setGapFillWithImages(true);
    }
    if (divRef.current.clientWidth / ConstantStrings.BASE_REM !== maxWidth) {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    };

    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [questionChoices, maxWidth]);

  const handleChange = (index) => (e) => {
    e.preventDefault();
    let newAnswers = [...answers];
    newAnswers[index] = e.target.value;
    setAnswers(newAnswers);
  };

  const showNextQuestion = () => {
    dispatch(
      setQuestionState({
        index: questionIndex,
        state: {
          answers: answers,
        },
      })
    );
    var answer = questionChoices.map((item) => false);

    if (answers.length > 0) {
      answers.map((item, index) => (answer[index] = item));
    } else {
      answer = null;
    }

    var result = [...answeredQuestions];
    var newAnswer = {
      questionId: questionId,
      answer: answer,
      questionIndex: questionIndex,
    };
    // setSelected(selected);
    if (result.length === 0) {
      result.push(newAnswer);
    } else {
      var flag = true;
      for (let question = 0; question < answeredQuestions.length; question++) {
        if (answeredQuestions[question].questionId === newAnswer.questionId) {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      if (!flag) {
        result.push(newAnswer);
      } else {
        result = answeredQuestions.map((question) =>
          question.questionId === questionId ? newAnswer : question
        );
      }
    }
    dispatch(setQuestionsAnswered(result));
    const submit = { submit: false, questions: [newAnswer] };
    dispatch(submitAnswers({ submit, token, practiceQuizId }));
    if (questionIndex === seenQuestions - 1) {
      // dispatch(setQuestionVisible(seenQuestions));
      // dispatch(setSeenQuestions(seenQuestions + 1));
    }
    dispatch(setQuestionFinished(questionIndex));

    if (answer !== null) {
      dispatch(
        setQuestionAnswered({ questionIndex: questionIndex, status: true })
      );
    } else {
      dispatch(
        setQuestionAnswered({ questionIndex: questionIndex, status: false })
      );
    }
    if (questionIndex !== questions.length - 1) {
      handleQuestionChange(questionIndex + 1);
    } else {
      return;
    }
  };

  const showPreviousQuestion = () => {
    dispatch(
      setQuestionState({
        index: questionIndex,
        state: {
          answers: answers,
        },
      })
    );
    var answer = questionChoices.map((item) => false);

    if (answers.length > 0) {
      answers.map((item, index) => (answer[index] = item));
    } else {
      answer = null;
    }

    var result = [...answeredQuestions];
    var newAnswer = {
      questionId: questionId,
      answer: answer,
      questionIndex: questionIndex,
    };
    // setSelected(selected);
    if (result.length === 0) {
      result.push(newAnswer);
    } else {
      var flag = true;
      for (let question = 0; question < answeredQuestions.length; question++) {
        if (answeredQuestions[question].questionId === newAnswer.questionId) {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      if (!flag) {
        result.push(newAnswer);
      } else {
        result = answeredQuestions.map((question) =>
          question.questionId === questionId ? newAnswer : question
        );
      }
    }
    dispatch(setQuestionsAnswered(result));
    const submit = { submit: false, questions: [newAnswer] };
    dispatch(submitAnswers({ submit, token, practiceQuizId }));
    dispatch(setQuestionFinished(questionIndex));

    if (answer !== null) {
      dispatch(
        setQuestionAnswered({ questionIndex: questionIndex, status: true })
      );
    } else {
      dispatch(
        setQuestionAnswered({ questionIndex: questionIndex, status: false })
      );
    }
    handleQuestionChange(questionIndex - 1);
  };

  return (
    <div style={{ height: `43.5rem` }}>
      <div className="is-flex is-flex-direction-row">
        <div style={{ width: "50%" }} ref={divRef}>
          <BackgroundImageFromUrl
            style={backgroundLeftStyle}
            objectId={backgroundImage[0]}
          ></BackgroundImageFromUrl>
        </div>
        <BackgroundImageFromUrl
          style={{
            ...backgroundStyle,
            padding: "2rem",
            width: "50%",
            position: "relative",
          }}
          objectId={backgroundImage[1]}
        >
          <div>
            <div>
              {data?.template.question !== null &&
                data?.template.question &&
                data?.template.question.length > 0 && (
                  <div className="mb-4">
                    <QuestionNumberTag
                      number={order === 0 ? data?.order : order}
                    />
                  </div>
                )}
            </div>

            <>
              {questionTexts !== null &&
                questionTexts !== undefined &&
                questionTexts?.length > 0 && (
                  <div className="mb-4">{questionTexts}</div>
                )}
            </>

            {/* if gapFillWithImages is false
                if questionChoices has length 4, render the textinput in a 2x2 grid
                else use columns is-multiline is-centered
            */}
            {!gapFillWithImages && (
              <div
                className="question-container"
                style={{
                  padding: "1.5rem 2rem",
                }}
              >
                <div
                  className="columns is-multiline is-centered"
                  style={{ gap: "0.5rem" }}
                >
                  {questionChoices.map((choice, index) => {
                    return (
                      <VerticalGapFill
                        key={index}
                        index={index}
                        onChange={handleChange(index)}
                        answer={answers[index] || ""}
                        choice={questionChoices[index]}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          {question && (
            <div
              style={nextButtonStyle}
              className="is-flex is-flex-direction-row is-justify-content-space-between"
            >
              <button
                className="button"
                onClick={showPreviousQuestion}
                // style={nextButtonStyle}
                disabled={questionIndex === 0}
              >
                Previous
              </button>

              {questionIndex !== questions.length - 1 && (
                <button
                  className="button"
                  onClick={showNextQuestion}
                  style={{ position: "absolute", right: "0" }}
                >
                  Next
                </button>
              ) }
            </div>
          )}
        </BackgroundImageFromUrl>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    sections: state.questions.sections,
  };
}

export default connect(mapStateToProps)(VerticalGapfill);
