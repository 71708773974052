import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import BackgroundImageFromUrl from "../../../components/parseImage/BackgroundImageFromUrl";
import {
  setQuestionAnswered,
  setQuestionState,
  setQuestionsAnswered,
  getProblemSetQuestion,
  getProblemSetSolution,
  submitProblemSetAnswer,
} from "../../../reducers/roadmapSlice";
import { checkAndGetAnonymousUserId } from "../../../templates/helper/CheckAndGetAnonymousUserId";
import { LoadingImage } from "../../../templates/helper/LoadingImage";
import { getAllImage } from "../../../templates/helper/getAllImages";
import ProblemSetRightWrapper from "./ProblemSetRightWrapper";
import ProblemSetLeftWrapper from "./ProblemSetLeftWrapper";

const ProblemSetMainWrapper = ({
  seenQuestions,
  questionsRoadMap,
  setShowPopupProblemSet,
  setShowBigExam,
  error,
}) => {
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [loadingImage, setLoadingImage] = useState(false);
  const [pagination, setPagination] = useState(
    localStorage.getItem("pagination")
      ? parseInt(localStorage.getItem("pagination"))
      : 1
  );
  const dispatch = useDispatch();
  const takeBigExamId = localStorage.getItem("takeRoadMapProblemSetId");
  const submitted = JSON.parse(localStorage.getItem("submittedProblemSet"));
  const cookies = new Cookies();
  const token = cookies.get("signinUser") || cookies.get("register");

  // const navigate = useNavigate();
  // const location = useLocation();
  const handleChangePagination = (pagination) => {
    setPagination(pagination);
  };
  const handleSetLoadingImage = (state) => {
    setLoadingImage(state);
  };

  const handleQuestionChange = (val) => {
    if (val !== selectedQuestion) {
      if (
        val < 20 &&
        (!localStorage.getItem("pagination") ||
          parseInt(localStorage.getItem("pagination")) === 2)
      ) {
        localStorage.setItem("pagination", 1);
        setPagination(1);
      } else if (
        val >= 20 &&
        (!localStorage.getItem("pagination") ||
          parseInt(localStorage.getItem("pagination")) === 1)
      ) {
        localStorage.setItem("pagination", 2);
        setPagination(2);
      }
      const result = JSON.parse(localStorage.getItem("questionStateExams"));
      if (result) {
        dispatch(setQuestionState(result.questionState));
        dispatch(setQuestionAnswered(result.questionAnswered));
        dispatch(setQuestionsAnswered(result.questionsAnswered));
        var submit = result.submit;
        dispatch(
          submitProblemSetAnswer({
            problemSetId: localStorage.getItem("takeRoadMapProblemSetId"),
            answers: submit,
            isAnonymous: token ? false : true,
            anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
          })
        );
      } else {
        var answeredQuestions = questionsRoadMap.map((question) => ({
          questionId: question.questionId,
          questionIndex: question.questionIndex,
          answer: question.answer?.length > 0 ? question.answer : [],
        }));
        dispatch(setQuestionsAnswered(answeredQuestions));
      }

      localStorage.removeItem("questionStateExams");
      setSelectedQuestion(val);
    }
  };

  const checkingState = questionsRoadMap?.every(
    (item) => item.question.showSolution === true
  );

  useEffect(() => {
    if (questionsRoadMap === null) {
      if (takeBigExamId && !submitted) {
        dispatch(
          getProblemSetQuestion({
            problemSetId: takeBigExamId,
            page: 1,
            size: 50,
            isAnonymous: token ? false : true,
            anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
          })
        );
      } else {

        dispatch(
          getProblemSetSolution({
            problemSetId: takeBigExamId,
            page: 1,
            size: 50,
            isAnonymous: token ? false : true,
            anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
          })
        );
      }
      setLoadingImage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsRoadMap]);

  if (
    loadingImage &&
    questionsRoadMap !== null &&
    questionsRoadMap?.length > 0
  ) {
    const result = getAllImage(questionsRoadMap, "EXAM");
    if (result && result?.length > 0 && !localStorage.getItem("currentIndex")) {
      return (
        <>
          <LoadingImage
            images={result}
            router={""}
            handleSetLoadingImage={(state) => handleSetLoadingImage(state)}
          />
        </>
      );
    } else {
      setLoadingImage(false);
    }
  }

  return (
    <div>
      {/* {loadingImage && <LoadingImage />} */}
      {questionsRoadMap !== null && questionsRoadMap?.length > 0 && (
        <BackgroundImageFromUrl
          className="columns is-mobile m-0 p-0"
          objectId={
            checkingState !== undefined && !checkingState
              ? "admin/frontend-images/hub/background-exam-vault.jpg"
              : "admin/frontend-images/hub/background-solution-exam.svg"
          }
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            // backgroundImage:
            //   checkingState !== undefined && !checkingState
            //     ? "url('/images/hub/background-exam-vault.jpg')"
            //     : "url('/images/hub/background-solution-exam.svg')",
            backgroundAttachment: "fixed",
          }}
        >
          {questionsRoadMap && (
            <div
              style={{
                // backgroundColor: checkingState ? "#F7F1DF" : "#DEEBF7",
                minHeight: "100vh",
                padding: "5rem 2.5rem 10rem 2.5rem",
                marginRight: "18.75rem",
                width: "calc(100% - 18.75rem)",
              }}
              className="column is-four-fifths is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
            >
              <ProblemSetRightWrapper
                question={questionsRoadMap[selectedQuestion]}
                selectedQuestion={selectedQuestion}
                seenQuestions={seenQuestions}
                handleQuestionChange={handleQuestionChange}
                questions={questionsRoadMap}
                takeExamsQuestionId={takeBigExamId}
              />
            </div>
          )}
          <div
            className="column m-0 is-one-fifth p-0 is-justify-content-center is-align-item-flex-start"
            style={{
              position: "fixed",
              right: "0",
              display: "flex",
              zIndex: 1000,
              flexDirection: "column",
              width: "18.75rem",
              height: "100%",
            }}
          >
            <ProblemSetLeftWrapper
              setShowBigExam={setShowBigExam}
              setShowPopupProblemSet={setShowPopupProblemSet}
              selectedIndex={selectedQuestion}
              handleQuestionChange={handleQuestionChange}
              handleChangePagination={(pagination) =>
                handleChangePagination(pagination)
              }
              takeExamsQuestionId={takeBigExamId}
              pagination={pagination}
            />
          </div>
        </BackgroundImageFromUrl>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    questionsRoadMap: state.roadMap.questions,
    seenQuestions: state.examsQuestion.seenQuestions,
    error: state.examsQuestion.error,
  };
}

export default connect(mapStateToProps)(ProblemSetMainWrapper);
