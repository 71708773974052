import React, { useState } from "react";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import { checkImage } from "../../constants/checkImage";
import { loadWidthImage } from "../../constants/loadWidthImage";
import ScrollRef from "../../detail-components/ScrollRef";
import jsonParser from "../helper/jsonParser";
import { cacheImage, getCacheImage, getImageUrl } from "../helper/parseImage";
import MainContent1Title from "./MainContent1Title";

const MainContent10MemoryCard = ({ data, isLast }) => {
  const content = data?.content;
  const contentText1 =
    content?.texts[0] !== null &&
    content?.texts[0] !== undefined &&
    content?.texts[0]?.length > 0
      ? jsonParser(content?.texts[0], 0, "MainContent10Context")
      : content?.texts[0];
  var key = getImageUrl(
    content?.images?.length > 1 && checkImage(content?.images[1]) ? content?.images[1] : content?.images[0]
  );
  const [isLoading, setIsLoading] = useState(() => {
    if (
      getCacheImage([key]) ||
      (!checkImage(content?.images[0]) && !checkImage(content?.images[1]))
    ) {
      return false;
    } else {
      return true;
    }
  });
  loadWidthImage(key, (err, img) => {
    cacheImage([key]);
    setIsLoading(false);
  });
  return (
    <div className="has-text-centered">
      {!isLast && !isLoading && <ScrollRef />}
      {/* {isLoading && <div>Loading</div>} */}
      {data?.title && !isLoading && <MainContent1Title title={data?.title} />}
      <BackgroundImageFromUrl
        objectId={content?.backgroundImage}
        className={`${
          isLoading ? "is-hidden" : ""
        } main-content-memory-card has-background-kurio-main-color-white m-auto has-text-centered is-flex is-flex-direction-column is-align-items-flex-start`}
        style={{
          maxWidth: "40rem",
          borderRadius: "1rem",
          boxShadow: "0rem 0.375rem 1rem rgba(10, 42, 102, 0.25)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          fontSize: "1.25rem",
        }}
      >
        <div
          className="has-text-weight-bold has-background-kurio-purple-light-3 m-auto has-text-centered is-flex is-flex-direction-column is-align-items-flex-start "
          style={{
            padding: "2rem",
            width: "100%",
            borderRadius: "1rem",
            gap: "1.5rem",
          }}
        >
          {content?.texts[0] !== undefined &&
            content?.texts[0] !== null &&
            content?.texts[0]?.length > 0 && (
              <div
                className={`${
                  isLoading ? "is-hidden" : ""
                } content-text has-text-kurio-main-color-purple `}
                style={{
                  fontSize: "1.5rem",
                  borderRadius: "1rem",
                  width: "100%",
                }}
              >
                {contentText1}
              </div>
            )}

          {content?.images[0] !== undefined &&
            content?.images[0] !== null &&
            content?.images[0]?.length > 0 && (
              <img
                src={getImageUrl(content?.images[0])}
                style={{ width: "100%", borderRadius: "1rem" }}
                alt={content?.images[0]}
              />
            )}
        </div>
        {content?.images[1] !== undefined &&
          content?.images[1] !== null &&
          content?.images[1]?.length > 0 && (
            <img
              src={getImageUrl(content?.images[1])}
              style={{ width: "100%", borderRadius: "1rem" }}
              alt={content?.images[2]}
            />
          )}
      </BackgroundImageFromUrl>
    </div>
  );
};

export default MainContent10MemoryCard;
