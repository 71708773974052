/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import NotificationUpgradeAccount from "../../components/NotificationUpgradeAccount";
import NextButton from "../../components/button/pagination-button/NextButton";
import PreviousButton from "../../components/button/pagination-button/PreviousButton";
import SlideOnSlider from "../../components/button/pagination-button/SlideOnSlider";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import PrimaryButtonLevelSection from "../../components/button/primary-button/PrimaryButtonLevelSection";
import SecondaryButtonLevelPage from "../../components/button/secondary-button/SecondaryButtonLevelPage";
import PopupCloseButton from "../../components/button/special-button/PopupCloseButton";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import { checkServerTimeError } from "../../config/checkAndCallDailyLoginApi";
import { getExamRanking } from "../../config/examsQuestion";
import ScrollRefWrapper from "../../detail-components/ScrollRefWrapper";
import {
  clearExamQuestion,
  createNewExams,
  getExamQuestion,
  getExamQuestionSolutions,
  getListExamsInformation,
  getListMonthlyExamsQuestion,
  getViewableExamSolutions,
} from "../../reducers/examsQuestionSlice";
import { setIsChangeGrade } from "../../reducers/gradesSlice";
import { chunk, findLastItemNonEmpty } from "../../templates/helper/splitArray";
import "./MonthlyExamMainWrapper.css";

const MonthlyExamsMainWrapper = ({
  listMonthlyExams,
  deadline,
  maxScore,
  currentScore,
  serverTime,
  grades,
  status,
  listUserInfo,
  isChangeGrade,
  error,
}) => {
  const [countdown, setCountdown] = useState(null);
  const [showPopupMonthlyExam, setShowPopupMonthlyExam] = useState(false);
  const [showPopupWarning, setShowPopupWarning] = useState(false);
  const [showPopupFinished, setShowPopupFinished] = useState(false);
  const [showPopupRanking, setShowPopupRanking] = useState(false);
  const [showPopupContinue, setShowPopupContinue] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [iconSolution, setIconSolution] = useState(false);
  const [examMonthly, setExamMonthly] = useState([]);
  const [apiCalled, setApiCalled] = useState(false);
  const [topRanking, setTopRanking] = useState(null);
  const [userRank, setUserRank] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [startExam, setStartExam] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prevRoute = useLocation();
  const cookies = new Cookies();
  const token = cookies.get("signinUser");
  var solutionInfoLC = JSON.parse(localStorage.getItem("solutionInfo"));
  var listExamInfoMonthly = JSON.parse(
    localStorage.getItem("listExamInfoMonthly")
  );

  // var examMonthlyNow = JSON.parse(localStorage.getItem("examMonthlyNow"));
  const handleSetStartExam = (state) => {
    setStartExam(state);
    setShowPopupMonthlyExam(false);
    setShowPopupRanking(false);
    setShowPopupFinished(false);
    setShowPopupWarning(false);
  };

  const handleClickShowPopup = async () => {
    const checkServerTime = await checkServerTimeError();
    if (checkServerTime) {
      // checkServerTime === true: lệch thời gian với server
      window.location.href = "error-server-time";
    } else {
      if (listUserInfo?.premiumFrom === null &&
        listUserInfo?.premiumTo === null) {
        setTimeout(() => {
          setStartExam(true);
        }, 300);
      } else {
        if (listUserInfo?.premiumFrom &&
          listUserInfo?.premiumTo &&
          new Date(listUserInfo?.premiumFrom).getTime() <=
          new Date().getTime() &&
          new Date(listUserInfo?.premiumTo).getTime() <
          new Date().getTime()) {
          setTimeout(() => {
            setStartExam(true);
          }, 300);
        } else {
          if (listMonthlyExams !== null) {
            const remainingTime =
              new Date(listMonthlyExams[0]?.endDate).getTime() -
              new Date().getTime();
            const minutesTime = Math.floor(remainingTime / (60 * 1000));

            if (minutesTime <= listMonthlyExams[0]?.examTime) {
              setShowPopupWarning(true);
            } else {
              if (
                localStorage.getItem("takeExamIdInfo") ||
                listExamInfoMonthly[0]?.takeExamId
              ) {
                setShowPopupContinue(true);
              } else {
                setShowPopupMonthlyExam(true);
              }
            }
          }
        }
      }

    }
  };

  const handleClickShowSolution = () => {
    if (solutionInfoLC !== undefined && solutionInfoLC.takeExamIdLP !== null) {
      dispatch(
        getExamQuestionSolutions({
          takeExamsQuestionId: solutionInfoLC.takeExamIdLP,
        })
      );
      localStorage.setItem("takeExamIdLPMonthly", solutionInfoLC.takeExamIdLP);
      setTimeout(() => {
        setStartExam(true);
      }, 300);
      // setTimeout(() => {
      //   navigate("/exam-questions", {
      //     state: { prevRouter: prevRoute.pathname },
      //   });
      // }, 500);
    }

    if (
      solutionInfoLC !== undefined &&
      solutionInfoLC.takeExamIdLP === null &&
      solutionInfoLC.solutionsViewable === true
    ) {
      dispatch(
        getViewableExamSolutions({
          examYearId: solutionInfoLC?._id,
          token,
        })
      );

      localStorage.setItem("examYearIdMonthly", solutionInfoLC?._id);

      setTimeout(() => {
        setStartExam(true);
      }, 300);
      // setTimeout(() => {
      //   navigate("/exam-questions", {
      //     state: { prevRouter: prevRoute.pathname },
      //   });
      // }, 500);
    }
  };

  const handleClickStartExam = () => {
    // const getLocalStorage =
    //   localStorage.getItem("listExamInfo") &&
    //   JSON.parse(localStorage.getItem("listExamInfo"))[0];
    localStorage.removeItem("questionStateExams");
    localStorage.removeItem("pagination");

    if (listExamInfoMonthly[0]?.takeExamId === null) {
      dispatch(
        createNewExams({
          examsQuestionId: listExamInfoMonthly[0].id,
          token,
        })
      );

      localStorage.setItem("createExamQuestionId", listExamInfoMonthly[0].id);
      dispatch(clearExamQuestion());

      setTimeout(() => {
        setStartExam(true);
      }, 300);
      // setTimeout(() => {
      //   navigate("/exam-questions", {
      //     state: { prevRouter: prevRoute.pathname },
      //   });
      // }, 500);
    } else {
      // dispatch(
      //   getExamQuestion({
      //     takeExamsQuestionId: localStorage.getItem("takeExamIdInfo")
      //       ? localStorage.getItem("takeExamIdInfo")
      //       : listExamInfoMonthly[0]?.takeExamId,
      //     token,
      //   })
      // );
      dispatch(clearExamQuestion());

      setTimeout(() => {
        setStartExam(true);
      }, 300);
      // setTimeout(() => {
      //   navigate("/exam-questions", {
      //     state: { prevRouter: prevRoute.pathname },
      //   });
      // }, 500);
    }
    localStorage.removeItem("showPopupDailyNow")
  };

  const handleClickRanking = () => {
    if (topRanking && topRanking?.length > 0) {
      setShowPopupRanking(true);
    }
  };

  const removePopup = () => {
    setShowPopupMonthlyExam(false);
    setShowPopupRanking(false);
    setShowPopupFinished(false);
    setShowPopupWarning(false);
    setShowPopupContinue(false);

    localStorage.removeItem("showPopupDailyNow")
  };

  const removePopupOutSide = (e) => {
    if (e?.target?.className === "modal is-active modal-background") {
      setShowPopupMonthlyExam(false);
      setShowPopupFinished(false);
      setShowPopupRanking(false);
      setShowPopupWarning(false);
    }
  };
  const handleSelectPage = (index) => {
    setCurrentPage(index);
  };
  const prevSelect = () => {
    if (currentPage > 0) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };
  const nextSelect = () => {
    if (currentPage < topRanking.length) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  const rerender = ({ days, hours, minutes, seconds, completed }) => {
    return (
      <div className="time-content-card-detail">
        {isLoading ? (
          <span className="loader"></span>
        ) : (
          `${zeroPad(days)} ngày ${zeroPad(hours)} : ${zeroPad(
            minutes
          )} : ${zeroPad(seconds)}`
        )}
      </div>
    );
  };

  function isValidImageAtIndex(list, index) {
    return (
      Array.isArray(list) &&
      list !== undefined &&
      list !== null &&
      list.length > 0 &&
      list[0]?.images?.length > index &&
      list[0].images[index] !== ""
    );
  }

  const formatDate = (inputDateStr) => {
    const inputDate = new Date(inputDateStr);
    const hours = inputDate.getHours().toString().padStart(1, "0");
    const minutes = inputDate.getMinutes().toString().padStart(2, "0");
    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1;
    const year = inputDate.getFullYear();

    const outputStr = `${hours}h${minutes} | ${day}/${month}/${year}`;

    return outputStr;
  };

  const convertTimeZoneUTC = (date) => {
    const currentDate = date;

    // Specify the timezone (UTC+7:00 Bangkok, Hanoi)
    const timeZone = "Asia/Bangkok";

    // Create a formatter with the specified timezone
    const formatter = new Intl.DateTimeFormat("en-US", {
      timeZone,
      month: "numeric",
    });

    // Format the current date and time according to the specified timezone
    const formattedDate = formatter.format(currentDate);
    let monthValue = parseInt(formattedDate, 10); // Chuyển đổi sang số và cộng thêm 1

    // Đặt giá trị là 12 nếu tháng là 1
    if (monthValue === 1) {
      monthValue = 12;
    } else {
      // Trừ đi 1 cho giá trị zero-based cho các tháng khác
      monthValue -= 1;
    }

    return Number(monthValue);
  };

  useEffect(() => {
    localStorage.removeItem("takeExamIdInfo");
    localStorage.removeItem("questionStateExams");
    localStorage.removeItem("targets");
    localStorage.removeItem("createExamQuestionId");
    localStorage.removeItem("takeExamMonthlyQuestionId");
    localStorage.removeItem("listExamInfo");
    localStorage.removeItem("listNewExamSolutions");
    localStorage.removeItem("takeExamIdLPMonthly");
    localStorage.removeItem("currentScore");
    localStorage.removeItem("maxScore");
    localStorage.removeItem("yearExamNow");
    localStorage.removeItem("minutes");
    localStorage.removeItem("seconds");
    localStorage.removeItem("pagination");

    // dispatch(clearState());
    dispatch(clearExamQuestion());

    const listCookies = cookies.getAll();
    Object.keys(listCookies).map(
      (cookie) =>
        cookie !== "signinUser" &&
        cookie !== "register" &&
        cookie !== "gradeId" &&
        cookies.remove(cookie)
    );
    const lastLoginKey = "lastLoginDate";
    const clearAllKey = "clearAll";
    window.onbeforeunload = () => {
      if (window.location.pathname === "/monthly-exams") {
        const keys = Object.keys(localStorage);
        keys.forEach((key) => {
          if (key !== lastLoginKey && key !== clearAllKey) {
            localStorage.removeItem(key);
          }
        });
      }
      window.history.replaceState(null, "");
    };

    const getRanking = async (id) => {
      try {
        const { result } = await getExamRanking(id);
        setUserRank(result.userRanking);
        setTopRanking(chunk(result.topRanking, 10));
      } catch (error) {
        console.log("err", error);
      }
    };
    if (solutionInfoLC && !isChangeGrade) {
      getRanking(solutionInfoLC?._id);
      // getRanking("649a456cc6d62f3369ffc4de");
    }

    if (
      listExamInfoMonthly?.length > 0 &&
      localStorage.getItem("examMonthlyNow") !== undefined &&
      localStorage.getItem("solutionInfo")
    ) {
      if (solutionInfoLC?.takeExamIdLP !== null && !isChangeGrade) {
        if (
          solutionInfoLC?.takeExamIdLP !== listExamInfoMonthly[0]?.takeExamLP
        ) {
          if (solutionInfoLC?.takeExamIdLP !== null) {
            dispatch(
              getExamQuestionSolutions({
                takeExamsQuestionId: solutionInfoLC?.takeExamIdLP,
              })
            );

            setIconSolution(false);
          }
        } else {
          if (
            solutionInfoLC?.takeExamIdLP === null &&
            solutionInfoLC?.solutionsViewable === true
          ) {
            dispatch(
              getViewableExamSolutions({
                examYearId: solutionInfoLC?._id,
                token,
              })
            );
          }
          setIconSolution(true);
        }
      } else {
        setIconSolution(true);
      }
    } else {
      setIconSolution(true);
    }

    if (isChangeGrade && grades) {
      // dispatch(clearState([]))
      const keys = Object.keys(localStorage);
      keys.forEach((key) => {
        if (key !== lastLoginKey && key !== clearAllKey) {
          localStorage.removeItem(key);
        }
      });
      // setCountdown(null);

      // if (JSON.parse(localStorage.getItem("solutionInfo"))) {
      //   localStorage.removeItem("solutionInfo");
      // }

      dispatch(getListMonthlyExamsQuestion({ page: 1, size: 50 }));

      if (localStorage.getItem("solutionInfo") !== null) {
        if (solutionInfoLC?.takeExamIdLP !== null) {
          dispatch(
            getExamQuestionSolutions({
              takeExamsQuestionId: JSON.parse(
                localStorage.getItem("solutionInfo")
              )?.takeExamIdLP,
            })
          );
        } else if (
          solutionInfoLC?.takeExamIdLP === null &&
          solutionInfoLC?.solutionsViewable === true
        ) {
          dispatch(
            getViewableExamSolutions({
              examYearId: solutionInfoLC?._id,
              token,
            })
          );
        }
      }
      if (solutionInfoLC !== null) {
        getRanking(solutionInfoLC?._id);
        // getRanking("649a456cc6d62f3369ffc4de");
      } else {
        setUserRank(null);
        setTopRanking(null);
      }

      dispatch(setIsChangeGrade(false));
    }

    if (listMonthlyExams !== null || listMonthlyExams !== undefined) {
      const currentDate = new Date();
      setExamMonthly(
        listMonthlyExams?.filter(
          (item, index) =>
            item.startDate !== null &&
            item.endDate !== null &&
            new Date(item.endDate).getTime() >=
            new Date(currentDate).getTime() &&
            new Date(item.startDate).getTime() <=
            new Date(currentDate).getTime()
        )
      );

      const endDate = new Date(
        JSON.parse(localStorage.getItem("examMonthlyNow"))?.endDate
      ); // Ngày kết thúc đếm ngược

      const now = new Date();
      if (endDate && endDate.toString() !== "Invalid Date") {
        const remainingTime = endDate.getTime() - now.getTime();

        if (!isNaN(remainingTime)) {
          if (remainingTime > 0) {
            setCountdown(remainingTime);
          } else {
            setCountdown(Math.abs(remainingTime));
          }
        }

        setTimeout(() => {
          setIsLoading(false); // Set isLoading to false after the data has loaded
        }, 300);
      } else {
        setIsLoading(false);
      }

      // if (Object.values(countdown).every((item) => isNaN(item) === true)) {
      //   setShowPopupFinished(true);
      // }

      if (listExamInfoMonthly?.length > 0) {
        if (
          listExamInfoMonthly[0]?.takeExamId !== null ||
          localStorage.getItem("takeExamIdInfo")
        ) {
          dispatch(
            getListExamsInformation({
              examYearId: listExamInfoMonthly[0]?.id,
            })
          );

          dispatch(
            getExamQuestion({
              takeExamsQuestionId:
                localStorage.getItem("takeExamIdInfo") !== null
                  ? localStorage.getItem("takeExamIdInfo")
                  : listExamInfoMonthly[0]?.takeExamId,
              token,
            })
          );
        }
      }

      dispatch(clearExamQuestion());
      if (!apiCalled) {
        dispatch(getListMonthlyExamsQuestion({ page: 1, size: 50 }));
        setApiCalled(true);
      }
    } else {
      localStorage.removeItem("solutionInfo");
      localStorage.removeItem("examMonthlyNow");
      localStorage.removeItem("listExamInfoMonthly");

      dispatch(getListMonthlyExamsQuestion({ page: 1, size: 50 }));
    }
  }, [listMonthlyExams, apiCalled, dispatch, isChangeGrade, grades]);

  if (startExam) {
    if (listUserInfo?.premiumFrom === null &&
      listUserInfo?.premiumTo === null) {
      return (
        <NotificationUpgradeAccount
          handleSetShowError={(state) => handleSetStartExam(state)}
          isShowPopup={startExam}
        />
      );
    } else {
      if (
        listUserInfo?.premiumFrom &&
        listUserInfo?.premiumTo &&
        new Date(listUserInfo?.premiumFrom).getTime() <=
        new Date().getTime() &&
        new Date(listUserInfo?.premiumTo).getTime() <
        new Date().getTime()
      ) {
        return (
          <NotificationUpgradeAccount
            handleSetShowError={(state) => handleSetStartExam(state)}
            isShowPopup={startExam}
          />
        );
      } else {

        setTimeout(() => {
          navigate("/monthly-exam-questions", {
            state: { prevRouter: prevRoute.pathname },
          });
        }, 500);
      }
    }

  }

  return (
    <>
      <ScrollRefWrapper />
      {showPopupRanking && (
        <div
          onClick={removePopupOutSide}
          className="modal is-active modal-background"
          style={{ opacity: 1, zIndex: 1000 }}
        >
          <BackgroundImageFromUrl
            objectId="admin/frontend-images/hub/background-top-ranking-popup.png"
            className="popup-ranking-monthly-exam"
          >
            <div className="header-top-popup-ranking">
              <div className="title-ranking-popup">
                <div className="ranking-title">
                  <ImageFromUrl
                    objectId="admin/frontend-images/hub/title-ranking-monthly.png"
                    alt=""
                  />
                  <img
                    style={{ marginLeft: "-1rem" }}
                    className="monthly-number"
                    src={`/images/hub/ranking-monthly-${String(convertTimeZoneUTC(new Date()))}.svg`}
                    alt=""
                  />
                </div>
                <div className="ranking-personal">
                  <p>Hạng cá nhân</p>
                  <span>{userRank ? userRank : "-"}</span>
                </div>
              </div>

              <div className="ranking-popup-monthly-exam is-inline-flex is-flex-direction-column is-align-items-center">
                <div
                  className="is-flex is-flex-direction-row is-relative has-text-centered"
                  style={{ height: "8.3rem", width: "30.1rem" }}
                >
                  <div
                    className="is-flex is-flex-direction-column is-align-items-center has-text-centered"
                    style={{
                      gap: "0.69rem",
                      position: "absolute",
                      left: "2.5rem",
                      top: "2.5rem",
                      width: "4.5rem",
                    }}
                  >
                    {topRanking &&
                      topRanking?.length > 0 &&
                      topRanking[0][1]?.profileImage?.length > 0 ? (
                      <ImageFromUrl
                        style={{
                          width: "4.5rem",
                          height: "4.5rem",
                          border: "0.25rem solid #FAFBFC",
                          borderRadius: "3rem",
                        }}
                        objectId={
                          topRanking &&
                          topRanking?.length > 0 &&
                          topRanking[0][1]?.profileImage
                        }
                      />
                    ) : (
                      <ImageFromUrl
                        style={{
                          width: "4.5rem",
                          height: "4.5rem",
                          outline: "0.25rem solid #FAFBFC",
                          borderRadius: "3rem",
                        }}
                        objectId="admin/frontend-images/avatar/chim-bt.svg"
                        alt=""
                      />
                    )}
                    <span
                      className="has-text-kurio-main-color-white has-text-weight-bold"
                      style={{ fontSize: "1.25rem", fontStyle: "normal" }}
                    >
                      {topRanking &&
                        topRanking?.length > 0 &&
                        topRanking[0]?.length > 1 &&
                        findLastItemNonEmpty(
                          topRanking[0][1]?.fullname?.split(" ")
                        )}
                    </span>
                  </div>
                  <div
                    className="is-flex is-flex-direction-column is-align-items-center has-text-centered"
                    style={{
                      gap: "0.69rem",
                      width: "4.5rem",
                      position: "absolute",
                      top: "-1rem",
                      left: "12.85rem",
                    }}
                  >
                    {topRanking &&
                      topRanking?.length > 0 &&
                      topRanking[0][0]?.profileImage?.length > 0 ? (
                      <ImageFromUrl
                        style={{
                          width: "4.5rem",
                          height: "4.5rem",
                          border: "0.25rem solid #FAFBFC",
                          borderRadius: "3rem",
                        }}
                        objectId={
                          topRanking &&
                          topRanking?.length > 0 &&
                          topRanking[0][0]?.profileImage
                        }
                      />
                    ) : (
                      <ImageFromUrl
                        style={{
                          width: "4.5rem",
                          height: "4.5rem",
                          outline: "0.25rem solid #FAFBFC",
                          borderRadius: "3rem",
                        }}
                        objectId="admin/frontend-images/avatar/chim-bt.svg"
                        alt=""
                      />
                    )}
                    <span
                      className="has-text-kurio-main-color-white has-text-weight-bold"
                      style={{
                        fontSize: "1.25rem",
                        fontStyle: "normal",
                        width: "4.5rem",
                      }}
                    >
                      {topRanking &&
                        topRanking?.length > 0 &&
                        findLastItemNonEmpty(
                          topRanking[0][0]?.fullname?.split(" ")
                        )}
                    </span>
                  </div>
                  <div
                    className="is-flex is-flex-direction-column is-align-items-center has-text-centered"
                    style={{
                      gap: "0.69rem",
                      position: "absolute",
                      width: "4.5rem",
                      top: "4rem",
                      right: "2.5rem",
                    }}
                  >
                    {topRanking &&
                      topRanking?.length > 0 &&
                      topRanking[0][2]?.profileImage?.length > 0 ? (
                      <ImageFromUrl
                        style={{
                          width: "4.5rem",
                          height: "4.5rem",
                          border: "0.25rem solid #FAFBFC",
                          borderRadius: "3rem",
                        }}
                        objectId={
                          topRanking &&
                          topRanking?.length > 0 &&
                          topRanking[0][2]?.profileImage
                        }
                      />
                    ) : (
                      <ImageFromUrl
                        style={{
                          width: "4.5rem",
                          height: "4.5rem",
                          outline: "0.25rem solid #FAFBFC",
                          borderRadius: "3rem",
                        }}
                        objectId="admin/frontend-images/avatar/chim-bt.svg"
                        alt=""
                      />
                    )}
                    <span
                      className="has-text-kurio-main-color-white has-text-weight-bold"
                      style={{ fontSize: "1.25rem", fontStyle: "normal" }}
                    >
                      {topRanking &&
                        topRanking?.length > 0 &&
                        topRanking[0]?.length > 2 &&
                        findLastItemNonEmpty(
                          topRanking[0][2]?.fullname?.split(" ")
                        )}
                    </span>
                  </div>
                </div>
                <ImageFromUrl
                  objectId="admin/frontend-images/hub/buc-ranking-popup.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="main-content-ranking">
              <div className="left is-flex is-flex-direction-column is-align-items-flex-start is-flex-gap-3">
                {topRanking &&
                  topRanking[currentPage]?.length > 0 &&
                  topRanking[currentPage]
                    ?.filter((item, index) => index <= 4 && item)
                    .map((itemRanking, i) => {
                      return (
                        <div
                          key={i}
                          className={` ${itemRanking.ranking === 1
                            ? ""
                            : itemRanking.ranking === 2
                              ? "has-background-kurio-red-light-3"
                              : itemRanking.ranking === 3
                                ? "has-background-kurio-blue-light-3"
                                : itemRanking.ranking === 4
                                  ? "has-background-kurio-green-light-3"
                                  : itemRanking.ranking === 5
                                    ? "has-background-kurio-green-light-3"
                                    : "has-background-kurio-light-gray"
                            } item-ranking`}
                        >
                          <span
                            className={`${itemRanking.ranking === 1
                              ? "has-background-kurio-main-color-yellow has-text-kurio-main-color-purple"
                              : itemRanking.ranking === 2
                                ? "has-background-kurio-red-main-accent has-text-kurio-main-color-white"
                                : itemRanking.ranking === 3
                                  ? "has-background-kurio-blue-main-accent has-text-kurio-main-color-white"
                                  : itemRanking.ranking === 4
                                    ? "has-background-kurio-green-main-accent has-text-kurio-main-color-white"
                                    : itemRanking.ranking === 5
                                      ? "has-background-kurio-green-main-accent has-text-kurio-main-color-white"
                                      : "has-background-kurio-gray-light-1 has-text-kurio-main-color-black"
                              }`}
                          >
                            {itemRanking.ranking}
                          </span>
                          <p className="name-user">{itemRanking.fullname}</p>
                          <p className="exam-score">{itemRanking.score}</p>
                        </div>
                      );
                    })}
              </div>
              <div className="right is-flex is-flex-direction-column is-align-items-flex-start is-flex-gap-3">
                {topRanking &&
                  topRanking[currentPage]?.length > 6 &&
                  topRanking[currentPage]
                    ?.filter((item, index) => index > 4 && item)
                    .map((itemRanking, i) => {
                      return (
                        <div
                          key={i}
                          className={` ${itemRanking.ranking === 1
                            ? ""
                            : itemRanking.ranking === 2
                              ? "has-background-kurio-red-light-3"
                              : itemRanking.ranking === 3
                                ? "has-background-kurio-blue-light-3"
                                : itemRanking.ranking === 4
                                  ? "has-background-kurio-green-light-3"
                                  : itemRanking.ranking === 5
                                    ? "has-background-kurio-green-light-3"
                                    : "has-background-kurio-light-gray"
                            } item-ranking`}
                        >
                          <span
                            className={`${itemRanking.ranking === 1
                              ? "has-background-kurio-main-color-yellow has-text-kurio-main-color-purple"
                              : itemRanking.ranking === 2
                                ? "has-background-kurio-red-main-accent has-text-kurio-main-color-white"
                                : itemRanking.ranking === 3
                                  ? "has-background-kurio-blue-main-accent has-text-kurio-main-color-white"
                                  : itemRanking.ranking === 4
                                    ? "has-background-kurio-green-main-accent has-text-kurio-main-color-white"
                                    : itemRanking.ranking === 5
                                      ? "has-background-kurio-green-main-accent has-text-kurio-main-color-white"
                                      : "has-background-kurio-gray-light-1 has-text-kurio-main-color-black"
                              }`}
                          >
                            {itemRanking.ranking}
                          </span>
                          <p className="name-user">{itemRanking.fullname}</p>
                          <p className="exam-score">{itemRanking.score}</p>
                        </div>
                      );
                    })}
              </div>
            </div>

            <div className="pagination-ranking">
              <PreviousButton
                disabled={currentPage === 0}
                onClick={prevSelect}
              />

              <div className="slide-pagination">
                {topRanking.map((item, index) => {
                  return (
                    <SlideOnSlider
                      onClick={() => handleSelectPage(index)}
                      isClick={index === currentPage}
                      // disabled={currentPage === 0}
                      key={index}
                    />
                  );
                })}
              </div>

              <NextButton
                disabled={currentPage + 1 === topRanking?.length}
                onClick={nextSelect}
              />
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "0rem",
                left: "-3rem",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </BackgroundImageFromUrl>
        </div>
      )}

      {showPopupContinue && (
        <div
          onClick={removePopupOutSide}
          className="modal is-active modal-background"
          style={{ opacity: 1, zIndex: 1000 }}
        >
          <div className="modal-quiz is-relative">
            <h2 style={{ color: "#0A2A66" }} className="title-popup-quiz">
              ! Thi tháng !
            </h2>
            <p className="documnent-quiz">
              Bạn đang làm <b>Đề thi Tháng {new Date().getMonth()}. </b>
            </p>

            <div className="is-flex " style={{ gap: "1rem", margin: "0 auto" }}>
              <PrimaryButtonPageLevel
                onClick={handleClickStartExam}
                style={{ height: "4rem", borderRadius: "1rem" }}
              >
                Làm tiếp
              </PrimaryButtonPageLevel>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {showPopupMonthlyExam && (
        <div
          onClick={removePopupOutSide}
          className="modal is-active modal-background"
          style={{ opacity: 1, zIndex: 1000 }}
        >
          <div className="modal-quiz is-relative">
            <h2 style={{ color: "#0A2A66" }} className="title-popup-quiz">
              ! Lưu ý !
            </h2>
            <p className="documnent-quiz">
              Bạn chỉ được làm{" "}
              {listMonthlyExams !== null && listMonthlyExams[0]?.name}{" "}
              <b>một lần duy nhất</b>. <br /> Thời gian làm bài là{" "}
              <b>
                {listMonthlyExams !== null && listMonthlyExams[0]?.examTime}{" "}
                phút
              </b>
              . <br /> Bạn có chắc chắn muốn bắt đầu?
            </p>

            <div
              className="is-flex is-flex-direction-row"
              style={{ gap: "1rem", margin: "0 auto" }}
            >
              <SecondaryButtonLevelPage
                style={{ borderRadius: "1rem" }}
                onClick={removePopup}
              >
                Để lúc khác
              </SecondaryButtonLevelPage>
              <PrimaryButtonPageLevel
                onClick={handleClickStartExam}
                style={{ height: "4rem", borderRadius: "1rem" }}
              >
                {localStorage.getItem("takeExamIdInfo") ||
                  listExamInfoMonthly[0]?.takeExamId
                  ? "Làm tiếp"
                  : "Bắt đầu ngay"}
              </PrimaryButtonPageLevel>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {showPopupWarning && (
        <div
          onClick={removePopupOutSide}
          className="modal is-active modal-background"
          style={{ opacity: 1, zIndex: 1000 }}
        >
          <div className="modal-quiz is-relative">
            <h2 style={{ color: "#0A2A66" }} className="title-popup-quiz">
              ! Lưu ý !
            </h2>
            <p className="documnent-quiz">
              {listMonthlyExams !== null && listMonthlyExams[0]?.name}{" "}
              <b>
                chỉ còn{" "}
                {listMonthlyExams !== null &&
                  Math.floor(
                    (new Date(listMonthlyExams[0]?.endDate).getTime() -
                      new Date().getTime()) /
                    (60 * 1000)
                  )}{" "}
                phút
              </b>
              . <br /> Thời gian làm bài là{" "}
              <b>
                {listMonthlyExams !== null && listMonthlyExams[0]?.examTime}{" "}
                phút
              </b>
              . <br /> Bạn có chắc chắn muốn bắt đầu?
            </p>

            <div
              className="is-flex is-flex-direction-row"
              style={{ gap: "1rem", margin: "0 auto" }}
            >
              <SecondaryButtonLevelPage onClick={removePopup}>
                Để lúc khác
              </SecondaryButtonLevelPage>
              <PrimaryButtonPageLevel
                onClick={handleClickStartExam}
                style={{ height: "4rem" }}
              >
                {localStorage.getItem("takeExamIdInfo") ||
                  listExamInfoMonthly[0]?.takeExamId
                  ? "Làm tiếp"
                  : "Bắt đầu ngay"}
              </PrimaryButtonPageLevel>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {showPopupFinished && (
        <div
          onClick={removePopupOutSide}
          className="modal is-active modal-background"
          style={{ opacity: 1, zIndex: 1000 }}
        >
          <div className="modal-quiz is-relative">
            <h2 style={{ color: "#0A2A66" }} className="title-popup-quiz">
              ! Hết giờ !
            </h2>
            <p className="documnent-quiz">
              Thời hạn hoàn thành Đề thi tháng {new Date().getMonth()} đã kết
              thúc. <br />
              Lần sau bạn hãy cố gắng làm sớm hơn nhé!{" "}
            </p>

            <div
              className="is-flex is-flex-direction-row"
              style={{ gap: "1rem", margin: "0 auto" }}
            >
              <PrimaryButtonPageLevel
                style={{ height: "4rem" }}
                onClick={removePopup}
              >
                Hoàn Thành
              </PrimaryButtonPageLevel>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      <div
        className="is-relative is-flex is-align-items-center is-justify-content-center"
        style={{ minHeight: "100vh", top: "-3rem" }}
      >
        <div style={{ padding: "1rem 4.5rem 3rem 4.5rem" }}>
          <div className="content-monthly-exam">
            <h3>Thi tháng</h3>

            {examMonthly?.length > 0 ? (
              <BackgroundImageFromUrl
                style={{ height: "15rem" }}
                objectId={
                  isValidImageAtIndex(listMonthlyExams, 4)
                    ? listMonthlyExams[0]?.images[4]
                    : "admin/frontend-images/exam/background-default-monthly-exam.svg"
                }
                className="content-detail-monthly-exam"
              >
                <div className="card-detail-monthly-exam">
                  <div className="card-detail-monthly-exam-left">
                    <div className="date-time-card-detail-monthly-exam">
                      <div
                        style={{
                          width: "5rem",
                          padding: "0.1875rem 0.4375rem",
                          borderRadius: "0.5rem",
                          background: "#1948A0",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "0.875rem",
                            fontWeight: 700,
                            textAlign: "center",
                            color: "#FFF",
                          }}
                        >
                          Bắt đầu
                        </p>
                      </div>
                      <div
                        style={{
                          width: "10.5rem",
                          padding: "0.25rem 0.75rem",
                          borderRadius: "0.5rem",
                          background: "#fff",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "0.875rem",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                          className="has-text-kurio-main-color-black"
                        >
                          {formatDate(examMonthly[0]?.startDate)}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "2.3rem" }}
                      className="date-time-card-detail-monthly-exam"
                    >
                      <div
                        style={{
                          width: "5rem",
                          padding: "0.1875rem 0.4375rem",
                          borderRadius: "0.5rem",
                          background: "#288F85",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "0.875rem",
                            fontWeight: 700,
                            textAlign: "center",
                            color: "#FFF",
                          }}
                        >
                          Kết thúc
                        </p>
                      </div>
                      <div
                        style={{
                          width: "10.5rem",
                          padding: "0.25rem 0.75rem",
                          borderRadius: "0.5rem",
                          background: "#fff",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "0.875rem",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                          className="has-text-kurio-main-color-black"
                        >
                          {formatDate(examMonthly[0]?.endDate)}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card-detail-monthly-exam-right">
                    <div className="time-finished-card-detail">
                      <p>Còn lại</p>
                    </div>

                    {isLoading ? (
                      <span className="loader"></span>
                    ) : (
                      <Countdown
                        key="countdown1"
                        date={Date.now() + countdown}
                        renderer={rerender}
                      />
                    )}

                    {isLoading ? (
                      <span className="loader"></span>
                    ) : (listExamInfoMonthly?.length > 0 &&
                      listExamInfoMonthly[0]?.takeExamLP !== null) ||
                      (deadline &&
                        serverTime &&
                        new Date(deadline).getTime() -
                        new Date(serverTime).getTime() <=
                        0) ? (
                      <ImageFromUrl
                        objectId="admin/frontend-images/hub/button-success-exam-monthly.svg"
                        alt=""
                      />
                    ) : (
                      <PrimaryButtonPageLevel
                        onClick={handleClickShowPopup}
                        style={{ height: "4rem", borderRadius: "1rem" }}
                      >
                        {listExamInfoMonthly?.length > 0 &&
                          listExamInfoMonthly[0]?.takeExamId
                          ? "Làm Tiếp"
                          : "Làm Ngay"}
                      </PrimaryButtonPageLevel>
                    )}
                  </div>
                </div>

                <ImageFromUrl
                  style={{
                    position: "absolute",
                    top: "-1.85rem",
                    right: "21.81rem",
                    zindex: 1,
                    width: "12.5rem",
                    height: "16.75rem",
                  }}
                  objectId={
                    isValidImageAtIndex(listMonthlyExams, 5)
                      ? listMonthlyExams[0]?.images[5]
                      : ""
                  }
                  alt=""
                />
              </BackgroundImageFromUrl>
            ) : (
              <BackgroundImageFromUrl
                style={{ height: "15rem" }}
                objectId={
                  isValidImageAtIndex(listMonthlyExams, 4)
                    ? listMonthlyExams[0]?.images[4]
                    : "admin/frontend-images/exam/background-default-monthly-exam.svg"
                }
                className="content-detail-monthly-exam"
              >
                <div className="card-detail-monthly-exam">
                  <div className="card-detail-monthly-exam-left">
                    <div className="date-time-card-detail-monthly-exam">
                      {/* <p>
                      {listMonthlyExams !== null &&
                        listMonthlyExams !== undefined &&
                        `${convertTimestamp(listMonthlyExams[0]?.startDate)} -
                        ${convertTimestamp(listMonthlyExams[0]?.endDate)}`}
                    </p>  */}
                    </div>
                  </div>

                  <div className="card-detail-monthly-exam-right">
                    <div className="time-finished-card-detail">
                      <p>Còn lại</p>
                    </div>

                    <Countdown
                      key="countdown3"
                      date={Date.now() - 1}
                      renderer={rerender}
                    />

                    {(listExamInfoMonthly?.length > 0 &&
                      listExamInfoMonthly[0]?.takeExamLP !== null) ||
                      (deadline &&
                        serverTime &&
                        new Date(deadline).getTime() -
                        new Date(serverTime).getTime() <=
                        0) ||
                      localStorage.getItem("successExam") ? (
                      <ImageFromUrl
                        objectId="admin/frontend-images/hub/button-success-exam-monthly.svg"
                        alt=""
                      />
                    ) : (
                      <PrimaryButtonPageLevel
                        disabled={true}
                        style={{ height: "4rem" }}
                      >
                        Làm ngay
                      </PrimaryButtonPageLevel>
                    )}
                  </div>
                </div>
                <ImageFromUrl
                  style={{
                    position: "absolute",
                    top: "-1.85rem",
                    right: "21.81rem",
                    zindex: 1,
                    width: "12.5rem",
                    height: "16.75rem",
                  }}
                  objectId={
                    isValidImageAtIndex(listMonthlyExams, 5)
                      ? listMonthlyExams[0]?.images[5]
                      : ""
                  }
                  alt=""
                />
              </BackgroundImageFromUrl>
            )}

            <div className="ranking-and-answer-monthly-exam">
              <BackgroundImageFromUrl
                // objectId="admin/frontend-images/exam/background-ranking-monthly.svg"
                objectId={topRanking && topRanking?.length > 0
                  ? "admin/frontend-images/hub/background-monthly-ranking-result.svg"
                  : "admin/frontend-images/hub/background-monthly-ranking-result.svg"}
                style={{
                  backgroundRepeat: "no-repeart",
                  backgroundPosition: "top center",
                  backgroundSize: "cover",
                }}
                className={`${topRanking && topRanking?.length > 0
                  ? " is-flex-direction-row is-justify-content-center"
                  : " is-align-items-flex-end is-justify-content-end pr-5"
                  } ranking-monthly-exam is-inline-flex`}
                onClick={handleClickRanking}
              >
                {/* {topRanking && topRanking?.length > 0 && ( */}
                <div className="has-text-kurio-main-color-white has-text-weight-bold is-flex is-flex-direction-column is-align-items-flex-start is-justify-content-center is-flex-gap-5">
                  <div
                    style={{ gap: "1.5rem" }}
                    className="is-flex is-flex-direction-column is-align-items-flex-start"
                  >
                    <div style={{ height: "5.8125rem" }}>
                      <p
                        style={{
                          fontSize: "1.25rem",
                          fontStyle: "normal",
                          lineHeight: "110%",
                          fontFamily: "Dela Gothic One",
                          textShadow: "2px 2px 0px #0A2A66",
                          height: "2.75rem",
                        }}
                      >
                        Xếp hạng <br /> thi tháng
                      </p>
                      <p
                        style={{
                          fontSize: "4rem",
                          marginTop: "-1rem",
                          textShadow: "2px 2px 0px #0A2A66",
                        }}
                      >
                        {convertTimeZoneUTC(new Date())}
                      </p>
                    </div>

                    <div>
                      <NextButton />
                    </div>
                  </div>
                </div>
                {/* )} */}
                <div className="is-inline-flex is-flex-direction-column is-align-items-flex-end is-justify-content-end">
                  <div
                    className="is-flex is-flex-direction-row is-relative has-text-centered"
                    style={{ width: "15.75rem", height: "6.25rem" }}
                  >
                    <div
                      className="is-flex is-flex-direction-column is-align-items-center"
                      style={{
                        // gap: "0.25rem",
                        width: "3rem",
                        position: "absolute",
                        top: "5rem",
                        left: "1rem",
                      }}
                    >
                      {topRanking && topRanking?.length > 0 && (
                        <>
                          {topRanking[0][1]?.profileImage ? (
                            <ImageFromUrl
                              style={{
                                width: "3rem",
                                height: "3rem",
                                outline: "0.25rem solid #FAFBFC",
                                borderRadius: "3rem",
                              }}
                              objectId={
                                topRanking &&
                                topRanking?.length > 0 &&
                                topRanking[0][1]?.profileImage
                              }
                            />
                          ) : (
                            <ImageFromUrl
                              style={{
                                width: "3rem",
                                height: "3rem",
                                outline: "0.25rem solid #FAFBFC",
                                borderRadius: "3rem",
                              }}
                              objectId="admin/frontend-images/avatar/chim-bt.svg"
                              alt=""
                            />
                          )}
                        </>
                      )}
                      {topRanking && topRanking?.length > 0 && (
                        <span
                          className="has-text-kurio-main-color-white has-text-weight-bold has-background-kurio-red-main-accent"
                          style={{
                            fontSize: "0.75rem",
                            fontStyle: "normal",
                            borderRadius: "1rem",
                            padding: "0.25rem 0.5rem",
                            boxShadow: " 2px 2px 0px 0px #0A2A66",
                            position: "absolute",
                            top: "2rem",
                          }}
                        >
                          {topRanking &&
                            topRanking?.length > 0 &&
                            topRanking[0]?.length > 1 &&
                            findLastItemNonEmpty(
                              topRanking[0][1]?.fullname?.split(" ")
                            )}
                        </span>
                      )}
                    </div>
                    <div
                      className="is-flex is-flex-direction-column is-align-items-center"
                      style={{
                        // gap: "0.25rem",
                        width: "4rem",
                        position: "absolute",
                        top: "2rem",
                        left: "5.75rem",
                      }}
                    >
                      {topRanking && topRanking?.length > 0 && (
                        <>
                          {topRanking[0][0]?.profileImage ? (
                            <ImageFromUrl
                              style={{
                                width: "4rem",
                                height: "4rem",
                                outline: "0.25rem solid #FAFBFC",
                                borderRadius: "3rem",
                              }}
                              objectId={
                                topRanking &&
                                topRanking?.length > 0 &&
                                topRanking[0][0]?.profileImage
                              }
                            />
                          ) : (
                            <ImageFromUrl
                              style={{
                                width: "4rem",
                                height: "4rem",
                                outline: "0.25rem solid #FAFBFC",
                                borderRadius: "3rem",
                              }}
                              objectId="admin/frontend-images/avatar/chim-bt.svg"
                              alt=""
                            />
                          )}
                        </>
                      )}
                      {topRanking && topRanking?.length > 0 && (
                        <span
                          className="has-text-kurio-main-color-black has-text-weight-bold has-background-kurio-main-color-yellow"
                          style={{
                            fontSize: "0.75rem",
                            fontStyle: "normal",
                            width: "4rem",
                            borderRadius: "1rem",
                            padding: "0.25rem 0.5rem",
                            boxShadow: " 2px 2px 0px 0px #0A2A66",
                            position: "absolute",
                            top: "3rem",
                          }}
                        >
                          {topRanking &&
                            topRanking?.length > 0 &&
                            findLastItemNonEmpty(
                              topRanking[0][0]?.fullname?.split(" ")
                            )}
                        </span>
                      )}
                    </div>
                    <div
                      className="is-flex is-flex-direction-column is-align-items-center"
                      style={{
                        // gap: "0.25rem",
                        width: "3rem",
                        position: "absolute",
                        top: "6rem",
                        right: "1.5rem",
                      }}
                    >
                      {topRanking && topRanking?.length > 0 && (
                        <>
                          {topRanking[0][2]?.profileImage?.length > 0 ? (
                            <ImageFromUrl
                              style={{
                                width: "3rem",
                                height: "3rem",
                                outline: "0.25rem solid #FAFBFC",
                                borderRadius: "3rem",
                              }}
                              objectId={
                                topRanking &&
                                topRanking?.length > 0 &&
                                topRanking[0][2]?.profileImage
                              }
                            />
                          ) : (
                            <ImageFromUrl
                              style={{
                                width: "3rem",
                                height: "3rem",
                                outline: "0.25rem solid #FAFBFC",
                                borderRadius: "3rem",
                              }}
                              objectId="admin/frontend-images/avatar/chim-bt.svg"
                              alt=""
                            />
                          )}
                        </>
                      )}
                      {topRanking && topRanking?.length > 0 && (
                        <span
                          className="has-text-kurio-main-color-white has-text-weight-bold has-background-kurio-blue-main-accent"
                          style={{
                            fontSize: "0.75rem",
                            fontStyle: "normal",
                            borderRadius: "1rem",
                            padding: "0.25rem 0.5rem",
                            boxShadow: " 2px 2px 0px 0px #0A2A66",
                            position: "absolute",
                            top: "2rem",
                          }}
                        >
                          {topRanking &&
                            topRanking?.length > 0 &&
                            topRanking[0]?.length > 2 &&
                            findLastItemNonEmpty(
                              topRanking[0][2]?.fullname?.split(" ")
                            )}
                        </span>
                      )}
                    </div>
                  </div>
                  <ImageFromUrl
                    // style={{height:"12.5rem", width:"15.75rem"}}
                    objectId="admin/frontend-images/exam/buc-ranking-exam.svg"
                    alt=""
                  />
                </div>
              </BackgroundImageFromUrl>

              <BackgroundImageFromUrl
                objectId={
                  isValidImageAtIndex(listMonthlyExams, 6)
                    ? listMonthlyExams[0]?.images[6]
                    : "admin/frontend-images/exam/background-defaulut-see-solution-exam.svg"
                }
                className="see-solution-exam"
              >
                {!localStorage.getItem("examMonthlyNow") ||
                  localStorage.getItem("examMonthlyNow") === undefined ||
                  (JSON.parse(localStorage.getItem("solutionInfo")) &&
                    JSON.parse(localStorage.getItem("listExamInfoMonthly")) &&
                    listExamInfoMonthly?.length > 0 &&
                    JSON.parse(localStorage.getItem("solutionInfo"))[0]?._id !==
                    JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
                      ?.id) ? (
                  <div className="show-solution-monthly">
                    <div className="card-detail-monthly-exam-solution">
                      <div className="time-finished-card-show-solution">
                        <p>Điểm tháng trước</p>
                      </div>

                      <div className="time-content-card-show-solution">
                        {isLoading ? (
                          <span className="loader"></span>
                        ) : solutionInfoLC?.takeExamIdLP !== null &&
                          !iconSolution ? (
                          <p>
                            {currentScore} / {maxScore}
                          </p>
                        ) : (
                          <ImageFromUrl
                            objectId="admin/frontend-images/hub/icon-point-exam.svg"
                            alt=""
                          />
                        )}
                      </div>
                    </div>

                    <PrimaryButtonLevelSection
                      disabled={!solutionInfoLC || solutionInfoLC === undefined}
                      onClick={handleClickShowSolution}
                    >
                      Xem Lời Giải
                    </PrimaryButtonLevelSection>
                  </div>
                ) : (
                  <div className="card-detail-monthly-exam-solution">
                    <div className="time-finished-card-detail-solution">
                      <p>Xem lời giải</p>
                    </div>

                    {isLoading ? (
                      <span className="loader"></span>
                    ) : (
                      <Countdown
                        key="countdown2"
                        date={Date.now() + countdown}
                        renderer={rerender}
                      />
                    )}
                  </div>
                )}
              </BackgroundImageFromUrl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    examQuestions: state.examsQuestion.questions,
    takeExamIdLPInfo: state.examsQuestion?.examInformation?.takeExamIdLP,
    maxScore: state.examsQuestion.maxScore,
    currentScore: state.examsQuestion.currentScore,
    listUserInfo: state.grades.listUserInfo,
    listMonthlyExams: state.examsQuestion.listMonthlyExams,
    deadline: state.examsQuestion.checkExamTime,
    serverTime: state.examsQuestion.serverTime,
    isChangeGrade: state.grades.isChangeGrade,
    grades: state.grades.gradeId,
    error: state.examsQuestion.error,
    status: state.examsQuestion.status,
  };
}

export default connect(mapStateToProps)(MonthlyExamsMainWrapper);
