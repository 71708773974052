import React, { useState } from "react";
import BackgroundImageFromUrl from "../../parseImage/BackgroundImageFromUrl";

const ButtonQuizExam = ({ disabled, isClick, onClick, children, style }) => {
  var buttonStyle = {
    width: `${style?.width ? style?.width : "100%"}`,
    height: `${style?.height ? style?.height : "5.1rem"}`,
    border: "none",
    backgroundSize: "cover",
    fontSize: "1.125rem",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    boxShadow: "none",
    // color: disabled ? "#8C9DC1" : "#FAFBFC",
  };

  const [defaultState, setDefaultState] = useState(disabled ? false : true);
  const [hoveredState, setHoveredState] = useState(false);
  const [clickedState, setClickedState] = useState(isClick ? isClick : false);
  const audio = new Audio("/sounds/click.mp3");
  var objectId = "";
  if (defaultState) {
    objectId = "admin/frontend-images/button/button-quiz-exam.svg";
  }
  if (hoveredState) {
    objectId = "admin/frontend-images/button/button-quiz-exam-hovered.svg";
  }

  const handleMouseEnter = () => {
    if (!clickedState && !disabled) {
      setDefaultState(false);
      setHoveredState(true);
      setClickedState(false);
    }
  };
  const handleMouseLeave = () => {
    if (!clickedState && !disabled) {
      setDefaultState(true);
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    if (!disabled) {
      audio.play();
      if (onClick) {
        onClick();
        setClickedState(clickedState);
      }
      setHoveredState(false);
      setDefaultState(!clickedState);
    }
  };
  const handleBlur = () => {
    if (!disabled) {
      setClickedState(false);
      setDefaultState(true);
    }
  };

  return (
    <div>
      <div
        className="button-primary-level-page is-relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onBlur={handleBlur}
      >
        <BackgroundImageFromUrl
          className={`${
            defaultState
              ? "is-primary-button-default-color"
              : hoveredState
              ? "is-primary-button-hovered-color"
              : clickedState
              ? "is-secondary-button-clicked-color"
              : disabled
              ? "is-button-disabled-color"
              : ""
          }  button  main-color has-text-weight-bold is-uppercase has-text-centered ${
            disabled
              ? "has-text-button-disabled-text-color"
              : "has-text-secondary-button-default-color"
          }`}
          objectId={objectId}
          style={{ ...buttonStyle, ...style }}
          onClick={handleClick}
          type="button"
        >
          {children}
        </BackgroundImageFromUrl>
      </div>
    </div>
  );
};

export default ButtonQuizExam;
