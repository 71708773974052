/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DuplicateLoginErrorComponent from "../../components/DuplicateLoginErrorComponent";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import PrimaryButtonLevelSection from "../../components/button/primary-button/PrimaryButtonLevelSection";
import SecondaryButtonLevelPage from "../../components/button/secondary-button/SecondaryButtonLevelPage";
import SecondaryButtonLevelSection from "../../components/button/secondary-button/SecondaryButtonLevelSection";
import PopupCloseButton from "../../components/button/special-button/PopupCloseButton";
import InputDate from "../../components/input/InputDate";
import InputText from "../../components/input/InputText";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import { editUserInfo, getUserInfo } from "../../config/auth";
import {
  getUrlImage,
  updateUrlImage,
  uploadImages,
} from "../../config/utilities";
import ScrollRefWrapper from "../../detail-components/ScrollRefWrapper";
import convertTimestamp from "../../templates/helper/convertTimestamp";
import { getImageUrl } from "../../templates/helper/parseImage";
import "./UserInfomationMainWrapper.style.css";
const UserInformationMainWrapper = ({ setImage }) => {
  const [inputValue, setInputValue] = useState({
    fullName: "",
    phone: "",
    email: "",
    birthday: "",
    password: "",
    fatherEmail: "",
    premiumTo: "",
    premiumFrom: "",
    fatherName: "",
    fatherPhone: "",
    motherEmail: "",
    motherName: "",
    motherPhone: "",
    profileImage: "",
  });

  const [error, setError] = useState({
    fullName: "",
    phone: "",
    email: "",
    birthday: null,
  });
  const data = [
    "admin/frontend-images/avatar/chim-bt.svg",
    "admin/frontend-images/avatar/chim-buồn-ngủ.svg",
    "admin/frontend-images/avatar/chim-vui.svg",
    "admin/frontend-images/avatar/chim-buồn.svg",
    "admin/frontend-images/avatar/ava-nam.svg",
    "admin/frontend-images/avatar/ava-my.svg",
    "admin/frontend-images/avatar/ava-ly.svg",
    "admin/frontend-images/avatar/ava-quân.svg",
    "admin/frontend-images/avatar/ava-mèo-vàng.svg",
    "admin/frontend-images/avatar/ava-mèo-xám.svg",
    "admin/frontend-images/avatar/ava-chó.svg",
    "admin/frontend-images/avatar/ava-bò.svg",
    "admin/frontend-images/avatar/ava-heo.svg",
    "admin/frontend-images/avatar/ava-logo-kurio.svg",
  ];
  // const { setImageData } = useImageContext();
  const [showImageModal, setShowImageModal] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nameImage, setNameImage] = useState("");
  const [uploadImage, setUploadImage] = useState(null);
  const [showFormStudent, setShowFormStudent] = useState(true);
  // const [checkPremiumTo, setCheckPremiumTo] = useState(false)
  const [showFormParent, setShowFormParent] = useState(false);
  const [editForm, setEditForm] = useState(true);
  const [cancelEditForm, setCancelEditForm] = useState(false);
  const [showModalUpdateImage, setShowModalUpdateImage] = useState(false);
  const [avatarDefault, setAvatarDefault] = useState("");
  const [showError, setShowError] = useState(false);

  // const cookies = new Cookies();
  // var token = cookies.get("signinUser");
  const navigate = useNavigate();
  const handleShowFormStudent = () => {
    setShowFormStudent(true);
    setShowFormParent(false);
  };

  const handleShowFormParent = () => {
    setShowFormParent(true);
    setShowFormStudent(false);
  };

  const handleClickEdit = () => {
    setCancelEditForm(true);
    setEditForm(false);
  };

  const handleClickCancelEdit = () => {
    setCancelEditForm(false);
    setEditForm(true);
    setInputValue({ ...inputValue });
    setError({
      fullName: "",
      phone: "",
      birthday: null,
      email: "",
    });
  };

  const handleChangeInput = (name, value) => {
    if (!value) {
      if (name === "fullName") {
        setError({ ...error, fullName: "Vui lòng nhập tên học sinh" });
      } else if (name === "birthday") {
        setError({ ...error, birthday: "Vui lòng nhập ngày tháng năm sinh" });
      }
    } else if (value) {
      if (name === "fullName") {
        setError({
          fullName: "",
          birthday: error.birthday,
        });
      } else if (name === "birthday") {
        setError({
          fullName: error.fullName,
          birthday: null,
        });
      }
    }

    // if (name === "birthday" && !value) {
    //   setError({ ...error, birthday: "Vui lòng nhập ngày tháng năm sinh" });
    // } else {
    //   setError({
    //     fullName: "",
    //     birthday: null,
    //   });
    // }

    // if (inputValue.phone === "") {
    //   setError({ ...error, phone: "Vui lòng nhập số điện thoại" });
    // }
    // if (inputValue.fullName === "") {

    // }
    // if (inputValue.birthday === null) {
    //
    // }
    if (name === "profileImage") {
      setAvatarDefault(value);
    }

    setInputValue({ ...inputValue, [name]: value });
  };

  const handleSubmit = async (name, value) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    // verify phone
    if (!phoneno.test(inputValue.phone)) {
      setError({ ...error, phone: "Số điện thoại không hợp lệ" });
    } else {
      try {
        if (inputValue.phone !== phoneNumber) {
          alert("Bạn không thể thay đổi số điện thoại");
          setInputValue({
            ...inputValue,
            phone: phoneNumber,
          });
          return;
        } else {
          await editUserInfo({
            ...inputValue,
            [name]: value,
            profileImage: showImageModal,
          });
        }

        setError({
          fullName: "",
          phone: "",
          birthday: null,
          email: "",
        });
        alert("edit thành công!");
        navigate(0);
        setEditForm(true);
        setCancelEditForm(false);
      } catch (error) {
        if (error?.response?.status === 409) {
          setShowError(true);
        }
        // setError({
        //   error: error.response.data?.errorMessage,
        // });
      }
    }
  };

  const handleClickUpdateImage = async (images) => {
    try {
      const response = await getUserInfo();

      if (response.result.profileImage) {
        let saveImage = response.result.profileImage.split(" ");

        const getUrl = await getUrlImage(saveImage);

        setNameImage(
          getUrl.result[0]?.name.substring(
            getUrl.result[0]?.name.lastIndexOf("/") + 1
          )
        );
      }

      setShowModalUpdateImage(!showModalUpdateImage);
    } catch (error) {
      if (error?.response?.status === 409) {
        setShowError(true);
      }
    }
  };

  const removePopup = () => {
    if (inputValue?.profileImage !== showImageModal) {
      setShowImageModal(inputValue?.profileImage);
      setNameImage("");
    }
    if (inputValue?.profileImage !== avatarDefault) {
      setShowImageModal(inputValue?.profileImage);
      setNameImage("");
    }
    setShowModalUpdateImage(false);
  };

  // onClick button upload new images
  const handleButtonClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";

    input.click();
    input.onchange = handleImageUpload;
  };

  // onChange upload new images
  const handleImageUpload = async (e) => {
    if (e.target.files[0].name.includes(".svg")) {
      alert("Chúng tôi không hỗ trợ định dạng file này !");

      // let file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setShowImageModal(uploadImage);
      };

      // reader.readAsDataURL(file);

      setUploadImage(uploadImage);
      return;
    } else if (e.target.files[0].size > 5120000) {
      alert("file size của ảnh quá lớn");
      return;
    } else {
      let file = e.target.files[0];

      const reader = new FileReader();
      reader.onloadend = () => {
        setShowImageModal(reader.result);
      };

      reader.readAsDataURL(file);

      let formData = new FormData();
      formData.append("file", file);
      setNameImage(file.name);
      formData.append("folder", 20230328);

      try {
        const response = await uploadImages(formData);
        const data = response.result.map((data) => data.objectId);
        setAvatarDefault("");
        setUploadImage(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // on click button save image
  const onHandleSaveImage = async (saveImage) => {
    if (saveImage !== null) {
      try {
        const objectId = saveImage.toString();
        await updateUrlImage({ imageUrl: objectId });
        const response = await getUrlImage(saveImage);

        const data = response.result.map((data) => data.url);

        setInputValue({
          ...inputValue,
          profileImage: data,
        });

        setImage(response.result.map((data) => data.name));
        setUploadImage(null);
        setShowModalUpdateImage(false);
      } catch (error) {
        if (error?.response?.status === 409) {
          setShowError(true);
        }
      }
    } else {
      if (avatarDefault?.length > 0) {
        try {
          await updateUrlImage({ imageUrl: avatarDefault });
          setInputValue({
            ...inputValue,
            profileImage: getImageUrl(avatarDefault),
          });

          setImage(avatarDefault);
          setShowModalUpdateImage(false);
        } catch (error) {
          if (error?.response?.status === 409) {
            setShowError(true);
          }
        }
      } else {
        setInputValue({
          ...inputValue,
          profileImage: uploadImage,
        });

        setImage(uploadImage);

        setShowModalUpdateImage(false);
      }
    }
  };

  const handleClickChangePassword = () => {
    navigate("/change-password");
  };

  useEffect(() => {
    // if (!token) {
    //   window.location.href =
    //     process.env.REACT_APP_ENV === "development"
    //       ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
    //       : process.env.REACT_APP_LANDING_PAGE_URL;
    // }
    const getListUserInfor = async () => {
      try {
        let response = await getUserInfo();

        let urlImage;
        if (response.result.profileImage) {
          let saveImage = response.result.profileImage.toString();

          await updateUrlImage({ imageUrl: saveImage });
          const newImage = await getUrlImage([response.result.profileImage]);

          const data = newImage.result.map((data) => data.url);
          urlImage = data;

          setShowImageModal(urlImage);
        }
        setInputValue({
          ...response.result,
          [response.result.name]: response.result.value,
          profileImage: urlImage,
        });

        setPhoneNumber(response.result.phone);
      } catch (error) {
        if (error?.response?.status === 409) {
          setShowError(true);
        }
      }
    };
    getListUserInfor();
  }, [cancelEditForm]);
  if (showError) {
    localStorage.setItem("clearAll", true);
    return <DuplicateLoginErrorComponent isShowPopup={true} />;
  }

  return (
    <div
      className="is-flex is-align-items-center is-justify-content-center"
      // style={{ minHeight: "100vh", top: "-4rem" }}
    >
      <ScrollRefWrapper />
      <div className="user-infomation__main-wrapper">
        <div className="user-infomation__left">
          <div className="avatar__user-information">
            {!inputValue?.profileImage ? (
              <BackgroundImageFromUrl
                className="preview-image-border"
                objectId={"admin/frontend-images/avatar/chim-bt.svg"}
              ></BackgroundImageFromUrl>
            ) : (
              <div
                className="preview-image-border"
                style={{
                  backgroundImage: `url(${
                    inputValue?.profileImage
                      ? inputValue?.profileImage
                      : showImageModal
                  })`,
                }}
              />
            )}
          </div>

          <SecondaryButtonLevelSection
            style={{ boxShadow: "0.125rem 0.125rem 0rem 0rem #0a2a66" }}
            // isClick={showModalUpdateImage}
            onClick={() => handleClickUpdateImage(inputValue?.profileImage)}
          >
            Thay ảnh
          </SecondaryButtonLevelSection>
        </div>

        <div className="user-information__right">
          {inputValue?.premiumFrom !== null &&
          inputValue?.premiumTo !== null &&
          new Date(inputValue?.premiumFrom).getTime() <= new Date().getTime() &&
          new Date(inputValue?.premiumTo).getTime() > new Date().getTime() ? (
            <>
              <button type="" className="btn-student">
                Tài khoản VIP
              </button>

              <button type="" className="btn-premiumto">
                Hết hạn: {convertTimestamp(inputValue?.premiumTo)}
              </button>
            </>
          ) : (
            <button type="" className="btn-parent">
              Tài khoản miễn phí
            </button>
          )}

          <div className="title-user-information">
            <h1>Thông tin cá nhân</h1>
            {editForm && (
              <SecondaryButtonLevelSection onClick={handleClickEdit}>
                Chỉnh sửa
              </SecondaryButtonLevelSection>
            )}

            {cancelEditForm && (
              <div
                className="is-flex"
                style={{
                  alignItems: "center",
                  gap: "1rem",
                  marginTop: "-1.5rem",
                }}
              >
                <SecondaryButtonLevelSection
                  onClick={handleClickCancelEdit}
                  className="btn-cancel"
                >
                  Hủy
                </SecondaryButtonLevelSection>
                <PrimaryButtonLevelSection
                  disabled={error.fullName !== "" || error.birthday !== null}
                  onClick={handleSubmit}
                >
                  Lưu thay đổi
                </PrimaryButtonLevelSection>
              </div>
            )}
          </div>

          <div className="classify-user-information">
            <span
              className={showFormStudent ? "focus" : "no-focus"}
              onClick={handleShowFormStudent}
            >
              <p>Học sinh</p>
            </span>

            <span
              className={showFormParent ? "focus" : "no-focus"}
              onClick={handleShowFormParent}
            >
              <p>Phụ huynh</p>
            </span>
          </div>

          <form onSubmit={handleSubmit}>
            {showFormStudent && (
              <div
                className={
                  cancelEditForm ? "styleBackground" : "form-user-information"
                }
              >
                <div className="is-relative">
                  <InputText
                    className="input__name-user-infomation"
                    label="Tên"
                    name="fullName"
                    readOnly={cancelEditForm ? false : true}
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    value={inputValue?.fullName || ""}
                  />
                  {inputValue?.fullName === "" && (
                    <p
                      className="has-text-danger subtitle is-size-7"
                      style={{
                        zIndex: 1,
                        position: "absolute",
                        top: "5.1rem",
                        left: "0.5rem",
                        width: "20rem",
                      }}
                    >
                      {error.fullName}
                    </p>
                  )}
                </div>
                <div className="is-flex" style={{ gap:"1rem"}}>
                  <div style={{width:"15rem"}}>
                    <InputText
                      className="input__phonenumber-user-infomation"
                      label="Số điện thoại"
                      name="phone"
                      disabled={true}
                      readOnly={cancelEditForm ? false : true}
                      onChange={(e) =>
                        handleChangeInput(e.target.name, e.target.value)
                      }
                      value={inputValue?.phone || ""}
                    />
                    <p
                      className="has-text-danger subtitle is-size-7"
                      style={{
                        zIndex: 1,
                        position: "absolute",
                        top: "26rem",
                        right: "47rem",
                      }}
                    >
                      {error.phone}
                    </p>
                  </div>
                  <div style={{width:"24rem"}}>
                    <InputText
                      className="input__email-user-infomation"
                      label="Email"
                      name="email"
                      type="email"
                      disabled={true}
                      readOnly={cancelEditForm ? false : true}
                      onChange={(e) =>
                        handleChangeInput(e.target.name, e.target.value)
                      }
                      value={inputValue?.email || ""}
                    />
                    {inputValue?.email === "" && (
                      <p
                        className="has-text-danger subtitle is-size-7"
                        style={{
                          zIndex: 1,
                          position: "absolute",
                          top: "26rem",
                        }}
                      >
                        {error.email}
                      </p>
                    )}
                  </div>
                </div>
                <div style={{ width: "40rem", height: "5rem" }}>
                  {cancelEditForm ? (
                    <>
                      <InputDate
                        className="input__date-user-infomation"
                        label="Ngày sinh"
                        name="birthday"
                        readOnly={cancelEditForm ? false : true}
                        onChange={(name, value) =>
                          handleChangeInput(name, value)
                        }
                        value={inputValue?.birthday || ""}
                      />
                      {inputValue?.birthday === null && (
                        <p
                          className="has-text-danger subtitle is-size-7"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            top: "37rem",
                          }}
                        >
                          {error.birthday}
                        </p>
                      )}
                    </>
                  ) : (
                    <InputText
                      className="input__date-user-infomation"
                      label="Ngày sinh"
                      name="birthday"
                      readOnly={cancelEditForm ? false : true}
                      onChange={(e) =>
                        handleChangeInput(e.target.name, e.target.value)
                      }
                      value={inputValue?.birthday || ""}
                    />
                  )}
                </div>
                <div
                  className="is-flex"
                  style={{
                    gap: "1rem",
                    width: "40rem",
                    justifyContent: "space-between",
                  }}
                >
                  <InputText
                    className="input__password-user-infomation"
                    label="Mật khẩu"
                    readOnly
                    value="********"
                  />
                  <PrimaryButtonPageLevel
                    style={{ height: "5rem" }}
                    onClick={handleClickChangePassword}
                  >
                    Đổi mật khẩu
                  </PrimaryButtonPageLevel>
                </div>
              </div>
            )}

            {showFormParent && (
              <div
                className={
                  cancelEditForm ? "styleBackground" : "form-user-information"
                }
              >
                <InputText
                  className="input__name-user-infomation"
                  label="Tên bố"
                  name="fatherName"
                  readOnly={cancelEditForm ? false : true}
                  onChange={(e) =>
                    handleChangeInput(e.target.name, e.target.value)
                  }
                  value={inputValue?.fatherName || ""}
                />
                <div className="is-flex" style={{ gap: "1rem" }}>
                  <div style={{ width: "15rem", height: "5rem" }}>
                    <InputText
                      className="input__phonenumber-user-infomation"
                      label="Số điện thoại bố"
                      name="fatherPhone"
                      readOnly={cancelEditForm ? false : true}
                      onChange={(e) =>
                        handleChangeInput(e.target.name, e.target.value)
                      }
                      value={inputValue?.fatherPhone || ""}
                    />
                  </div>
                  <div style={{ width: "25rem", height: "5rem" }}>
                    <InputText
                      className="input__email-user-infomation"
                      label="Email bố"
                      name="fatherEmail"
                      readOnly={cancelEditForm ? false : true}
                      onChange={(e) =>
                        handleChangeInput(e.target.name, e.target.value)
                      }
                      value={inputValue?.fatherEmail || ""}
                    />
                  </div>
                </div>
                <div style={{ gap: "1rem" }}>
                  <InputText
                    className="input__name-user-infomation"
                    label="Tên mẹ"
                    name="motherName"
                    readOnly={cancelEditForm ? false : true}
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    value={inputValue?.motherName || ""}
                  />
                </div>
                <div className="is-flex" style={{ gap: "1rem" }}>
                  <div style={{ width: "15rem", height: "5rem" }}>
                    <InputText
                      className="input__phonenumber-user-infomation"
                      label="Số điện thoại mẹ"
                      name="motherPhone"
                      readOnly={cancelEditForm ? false : true}
                      onChange={(e) =>
                        handleChangeInput(e.target.name, e.target.value)
                      }
                      value={inputValue?.motherPhone || ""}
                    />
                  </div>
                  <div style={{ width: "25rem", height: "5rem" }}>
                    <InputText
                      className="input__email-user-infomation"
                      label="Email mẹ"
                      name="motherEmail"
                      readOnly={cancelEditForm ? false : true}
                      onChange={(e) =>
                        handleChangeInput(e.target.name, e.target.value)
                      }
                      value={inputValue?.motherEmail || ""}
                    />
                  </div>
                </div>
              </div>
            )}

            {showModalUpdateImage && (
              <div
                className="modal is-active modal-background"
                style={{ opacity: 1 }}
              >
                <div
                  className="modal-update-image is-flex is-flex-direction-row is-relative"
                  style={{ gap: "3rem" }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "-2.5rem",
                      left: "-2.5rem",
                      zIndex: 1000,
                    }}
                  >
                    <PopupCloseButton onClick={removePopup} />
                  </div>
                  <div
                    className="is-flex is-flex-direction-column btn-update-image"
                    style={{
                      width: "23rem",
                      height: "23.5rem",
                      gap: "2.5rem",
                      overflow: "hidden",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <SecondaryButtonLevelPage
                        style={{ width: "23rem" }}
                        onClick={handleButtonClick}
                      >
                        Tải lên ảnh khác
                      </SecondaryButtonLevelPage>
                    </div>
                    <div
                      style={{ gap: "1rem", height: "16rem" }}
                      className="is-flex is-flex-direction-column"
                    >
                      <div>
                        <span className="title-box-image">
                          Hoặc chọn ảnh đại diện
                        </span>
                      </div>
                      <div
                        className="columns is-multiline group-old-image"
                        style={{
                          gap: "0.75rem",
                          width: "23rem",
                          margin: "0 auto",
                          overflowY: "scroll",
                        }}
                      >
                        {data.map((item, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                setAvatarDefault(item);
                                const parts = item.split(
                                  "admin/frontend-images/avatar/"
                                );
                                setNameImage(parts[1]);
                              }}
                              className="box-image column is-narrow m-0 p-0 is-clickable"
                              style={{ width: "5rem" }}
                            >
                              <ImageFromUrl objectId={item} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div
                    className="is-flex is-flex-direction-column preview-image"
                    style={{ gap: "1.5rem" }}
                  >
                    <div
                      className="is-flex is-align-items-center"
                      style={{
                        width: "14.3125rem",
                        gap: "0.625rem",
                      }}
                    >
                      <span>Preview</span>
                      <p className="ellipsis">{nameImage}</p>
                    </div>

                    <div
                      className="avatar__user-information"
                      style={{ margin: "0 auto" }}
                    >
                      <div
                        className="preview-image-border"
                        style={{
                          backgroundImage: `url(${
                            avatarDefault?.length > 0
                              ? getImageUrl(avatarDefault)
                              : showImageModal
                              ? showImageModal
                              : inputValue?.profileImage
                          })`,
                        }}
                      ></div>
                    </div>

                    <div className="btn-save-change">
                      <PrimaryButtonPageLevel
                        style={{
                          maxWidth: "17.5rem",
                          width: "17.5rem",
                          height: "4rem",
                        }}
                        onClick={() => onHandleSaveImage(uploadImage)}
                      >
                        Lưu thay đổi
                      </PrimaryButtonPageLevel>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>

          <Link
            className="has-text-kurio-main-color-black"
            style={{ width: "7rem", display: "inline-block" }}
            to={"/request-delete-account"}
          >
            <p
              style={{
                textDecoration: "underline",
                fontSize: "0.875rem",
                marginTop: "1rem",
              }}
            >
              Xóa tài khoản?
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserInformationMainWrapper;
