import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import DuplicateLoginErrorComponent from "../../components/DuplicateLoginErrorComponent";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import SecondaryButtonLevelPage from "../../components/button/secondary-button/SecondaryButtonLevelPage";
import PopupCloseButton from "../../components/button/special-button/PopupCloseButton";
import InputText from "../../components/input/InputText";
import { changePassword, getUserInfo } from "../../config/auth";
import ConstantStrings from "../../constants/ConstantStrings";
import "./ChangePasswordUserInfo.style.css";

const ChangePasswordUserInfo = () => {
  const [onChangePassword, setOnChangePassword] = useState({
    oldPassword: "",
    newPassword: "",
    reEnterNewPassword: "",
  });
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [showModalWarning, setShowModalWarning] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const cookies = new Cookies();
  var token = cookies.get("signinUser");
  const navigate = useNavigate();
  const [error, setError] = useState({
    oldPassword: "",
    newPassword: "",
    reEnterNewPassword: "",
  });

  const handleChange = (value, name) => {
    setOnChangePassword({ ...onChangePassword, [name]: value });
    setError({
      oldPassword: "",
      newPassword: "",
      reEnterNewPassword: "",
    });
  };

  const handleSubmit = async (e) => {
    var check = false;
    const checkArr = Object.keys(onChangePassword);
    const keyErrorArr = Object.keys(error);
    var objError = { ...error };
    for (let index = 0; index < checkArr.length; index++) {
      if (
        keyErrorArr.includes(checkArr[index]) &&
        onChangePassword[checkArr[index]] === ""
      ) {
        check = true;
        objError = {
          ...objError,
          [checkArr[index]]: ConstantStrings.DO_NOT_LEAVE_BLANK,
        };
      }
    }
    if (check) {
      setError(objError);
    } else if (onChangePassword.oldPassword === onChangePassword.newPassword) {
      setError({
        ...error,
        newPassword: ConstantStrings.PASSWORD_NEW,
        oldPassword: "",
        reEnterNewPassword: "",
      });
    } else if (
      onChangePassword.newPassword !== onChangePassword.reEnterNewPassword
    ) {
      setError({
        ...error,
        reEnterNewPassword: ConstantStrings.PASSWORD_INCORRECT,
        oldPassword: "",
        newPassword: "",
      });
    } else {
      try {
        await changePassword({
          ...onChangePassword,
          oldPassword: onChangePassword.oldPassword,
          newPassword: onChangePassword.newPassword,
        });
        setChangePasswordSuccess(true);
        setError({
          oldPassword: "",
          newPassword: "",
          reEnterNewPassword: "",
        });
      } catch (error) {
        if (error?.response?.data?.errorMessage.includes("không đúng")) {
          setError({
            ...error,
            oldPassword: error.response.data?.errorMessage,
        
          });
        }

        if (error?.response?.data?.errorMessage.includes("6 ký tự")) {
          setError({
            ...error,
            newPassword: error?.response?.data?.errorMessage,
            reEnterNewPassword: error?.response?.data?.errorMessage,
          });
        }

        if (error?.response?.status === 409) {
          setShowError(true);
        }
      }
    }
  };

  const handleClickBack = () => {
    navigate("/account");
  };

  const handleClickShowModal = () => {
    setShowModalWarning(!showModalWarning);
  };

  const handleSubmitNavigate = () => {
    if (token) {
      cookies.remove("signinUser");
    }
    navigate("/reset-password");
  };

  useEffect(() => {
    const getListUserInfo = async () => {
      const response = await getUserInfo();

      setEmailSuccess(response.result.email);
    };

    getListUserInfo();
  }, [token]);
  if (showError) {
    localStorage.setItem("clearAll", true);
    return <DuplicateLoginErrorComponent isShowPopup={true} />;
  }
  // };
  return (
    <div style={{ padding: "5rem" }}>
      <h4
        onClick={handleClickBack}
        style={{
          fontWeight: 700,
          fontSize: "0.875rem",
          lineHeight: "1rem",
          textTransform: "uppercase",
          color: "#0a2a66",
          width: "15rem",
          height: "1rem",
          cursor: "pointer",
          display: "flex",
          gap: "0.8rem",
        }}
      >
        {" "}
        <span style={{ fontSize: "1.5rem" }}>&lt;</span> Quay lại
      </h4>

      {changePasswordSuccess ? (
        <div
          className="main-content__change-password"
          style={{ marginTop: "10%" }}
        >
          <div className="title-change-password">Đổi mật khẩu</div>
          <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center m-auto">
            <p
              style={{
                textAlign: "center",
                width: "30rem",
                color: "#0A2A66",
                fontSize: "1.1rem",
                lineHeight: "1.3rem",
                marginBottom: "2rem",
              }}
            >
              Bạn đã hoàn thành đổi mật khẩu cho tài khoản <br />{" "}
              <span>{emailSuccess}.</span>
            </p>

            <div>
              <PrimaryButtonPageLevel
                style={{ height: "5rem" }}
                onClick={handleClickBack}
              >
                Trở về
              </PrimaryButtonPageLevel>
            </div>
          </div>
        </div>
      ) : (
        <div className="main-content__change-password">
          <div className="title-change-password">Đổi mật khẩu</div>
          <div style={{ width: "100%" }}>
            <form>
              <InputText
                className="input-change-password"
                label={"Mật khẩu cũ"}
                type="password"
                name="oldPassword"
                value={onChangePassword.oldPassword || ""}
                onChange={(e) => handleChange(e.target.value, e.target.name)}
              />

              <p className="has-text-danger subtitle is-size-7 mt-3">
                {error.oldPassword}
              </p>

              <InputText
                className="input-change-password"
                style={{ height: "4rem" }}
                value={onChangePassword.newPassword || ""}
                name="newPassword"
                onChange={(e) => handleChange(e.target.value, e.target.name)}
                label={"Mật khẩu mới"}
                type="password"
              />

              <p className="has-text-danger subtitle is-size-7 mt-3">
                {error.newPassword}
              </p>

              <InputText
                className="input-change-password"
                style={{ height: "4rem" }}
                value={onChangePassword.reEnterNewPassword || ""}
                name="reEnterNewPassword"
                onChange={(e) => handleChange(e.target.value, e.target.name)}
                label={"Nhập lại mật khẩu mới"}
                type="password"
              />

              <p className="has-text-danger subtitle is-size-7 mt-3">
                {error.reEnterNewPassword}
              </p>

              <div className="btn-next">
                <PrimaryButtonPageLevel
                  style={{ maxWidth: "20rem", width: "20rem", height: "5rem" }}
                  onClick={handleSubmit}
                >
                  Tiếp theo
                </PrimaryButtonPageLevel>
              </div>
            </form>
          </div>
          <div className="forgot-password" onClick={handleClickShowModal}>
            Quên mật khẩu?
          </div>
        </div>
      )}

      {showModalWarning && (
        <div
          className="modal is-active modal-background"
          style={{ opacity: 1 }}
        >
          <div className="modal-warning__change-password is-flex is-flex-direction-column fa-align-center">
            <div
              style={{
                position: "absolute",
                top: "-2.5rem",
                left: "-3rem",
                zIndex: 1000,
              }}
            >
              <PopupCloseButton onClick={handleClickShowModal} />
            </div>

            <div className="title-warning__change-password">
              <p>! Cảnh báo !</p>
            </div>

            <div className="document__change-password">
              Bạn sẽ tự động ĐĂNG XUẤT để tới trang QUÊN MẬT KHẨU. <br /> Bạn
              chỉ được khởi tạo lại mật khẩu 1 lần trong vòng 1 tiếng.
            </div>

            <div
              className="is-flex is-flex-direction-row"
              style={{ gap: "1rem", margin: "0 auto" }}
            >
              <SecondaryButtonLevelPage onClick={handleClickShowModal}>
                Hủy
              </SecondaryButtonLevelPage>
              <PrimaryButtonPageLevel
                style={{ height: "4rem" }}
                onClick={handleSubmitNavigate}
              >
                Tiếp tục
              </PrimaryButtonPageLevel>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePasswordUserInfo;
