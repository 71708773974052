import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import {
  setQuestionAnswered,
  setQuestionState,
  setQuestionsAnswered,
} from "../../reducers/practiceQuestionSlice";
import UnitQuizLeftWrapper from "../unit-quiz/left/UnitQuizLeftWrapper";
import UnitQuizRightWrapper from "../unit-quiz/right/UnitQuizRightWrapper";

const PreviewQuizMainWrapper = ({
  questions,
  seenQuestions,
  correctAnswers,
  quiz,
  practiceQuizId,
  practiceQuizIdLP,
  newPracticeId,
}) => {
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [isPreview, setIsPreview] = useState(false);
  const dispatch = useDispatch();
  // const cookies = new Cookies();
  const handleQuestionChange = (val) => {
    if (val !== selectedQuestion) {
      const result = JSON.parse(localStorage.getItem("questionStateQuiz"));
      if (result) {
        dispatch(setQuestionState(result.questionState));
        dispatch(setQuestionAnswered(result.questionAnswered));
        dispatch(setQuestionsAnswered(result.questionsAnswered));
        // var submit = result.submit
        // dispatch(submitAnswers({submit, token, practiceQuizId:newPracticeId ? newPracticeId : practiceQuizId }));
      }
      setSelectedQuestion(val);
      localStorage.removeItem("questionStateQuiz")
    }
  };
  return (
    <div>
      <BackgroundImageFromUrl
        className="columns is-mobile m-0 p-0"
        objectId={"admin/frontend-images/hub/background-exam-vault.jpg"}
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          // backgroundImage: "url('/images/hub/background-exam-vault.jpg')",
          backgroundAttachment: "fixed",
        }}
      >
        {questions && (
          <div
            style={{
              // backgroundColor: checkingState ? "#F7F1DF" : "#DEEBF7",
              minHeight: "100vh",
              padding: "5rem 2.5rem 10rem 2.5rem",
              marginRight: "18.75rem",
              width: "calc(100% - 18.75rem)",
            }}
            className="column is-four-fifths is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
          >
            <UnitQuizRightWrapper
              question={questions[selectedQuestion]}
              selectedQuestion={selectedQuestion}
              seenQuestions={seenQuestions}
              handleQuestionChange={handleQuestionChange}
              isPreview={isPreview}
              questions={questions}
              practiceQuizId={newPracticeId ? newPracticeId : practiceQuizId}
            />
          </div>
        )}
        <div
          className="column m-0 is-one-fifth p-0 is-justify-content-center is-align-item-flex-start"
          style={{
            position: "fixed",
            right: "0",
            display: "flex",
            zIndex: 1000,
            flexDirection: "column",
            width: "18.75rem",
            height: "100%",
          }}
        >
          <UnitQuizLeftWrapper
            selectedIndex={selectedQuestion}
            isPreview={isPreview}
            setIsPreview={setIsPreview}
            quiz={quiz}
            handleQuestionChange={handleQuestionChange}
            practiceQuizId={newPracticeId ? newPracticeId : practiceQuizId}
          />
        </div>
      </BackgroundImageFromUrl>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    practiceQuizId: state.practiceQuestion.quizInformation?.practiceQuizId,
    practiceQuizIdLP: state.practiceQuestion.quizInformation?.practiceQuizIdLP,
    newPracticeId: state.practiceQuestion.newPracticeId,
    quiz: state.practiceQuestion.quizInformation,
    questions: state.practiceQuestion.questions,
    seenQuestions: state.practiceQuestion.seenQuestions,
    correctAnswers: state.practiceQuestion.correctAnswers,
  };
}
export default connect(mapStateToProps)(PreviewQuizMainWrapper);
