import React from "react";

const ButtonRoadmap = ({ status, disabled, onClick }) => {
  //status: 1 - start, 2 - redo, 3 - finished
  const audio = new Audio("/sounds/click.mp3");
  var buttonStyle = {
    width: "3.5rem",
    height: "3.5rem",
    borderRadius: "3.5rem",
    outline: "0.25rem solid #FAFBFC",
    // borderBottom:"0.25rem solid #0A2A66"
    // boxShadow: " 0.25rem 0.25rem 0rem 0rem #0A2A66"
  };
  if (status === 1 && !disabled) {
    buttonStyle = {
      ...buttonStyle,
      background: "linear-gradient(180deg, #572CCF 0%, #202057 100%)",
    };
  }
  if (status === 2 && !disabled) {
    buttonStyle = {
      ...buttonStyle,
      background: "linear-gradient(180deg, #315F96 0%, #103367 100%)",
    };
  }
  if (status === 3 && !disabled) {
    buttonStyle = {
      ...buttonStyle,
      background: "linear-gradient(180deg, #4B9631 0%, #276710 100%)",
    };
  }
  if (disabled) {
    buttonStyle = {
      ...buttonStyle,
      background: "linear-gradient(180deg, #CAD5DB 0%, #5F686D 100%)",
    };
  }
  const handleClick = () => {
    if (!disabled) {
      audio.play();
      if (onClick) {
        onClick();
      }
    }
  };
  return (
    <div
      className="is-flex is-align-items-center is-justify-content-center is-clickable"
      style={buttonStyle}
      onClick={handleClick}
    >
      <img
        src={
          disabled
            ? "/images/exam-preparation/icon-disabled.svg"
            : status === 1
              ? "/images/exam-preparation/icon-start.svg"
              : status === 2
                ? "/images/exam-preparation/icon-redo.svg"
                : status === 3
                  ? "/images/exam-preparation/icon-finished.svg"
                  : ""
        }
        alt=""
      />
    </div>
  );
};

export default ButtonRoadmap;
