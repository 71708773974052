import React, { useEffect, useState } from "react";
import BackgroundImageFromUrl from "../../parseImage/BackgroundImageFromUrl";

const PaginationBigButton = ({
  disabled,
  isClick,
  onClick,
  children,
  isAnswered,
  isSection,
  isCorrect,
  IKMCMode,
}) => {
  var style = {
    width: "2.5rem",
    height: "2.5rem",
    border: "none",
    borderRadius: IKMCMode ? "0.5rem" : "99999rem",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    boxShadow: "none",
  };
  const [defaultState, setDefaultState] = useState(
    disabled ? false : isClick ? false : true
  );
  const [hoveredState, setHoveredState] = useState(false);
  const [clickedState, setClickedState] = useState(isClick ? isClick : false);
  const audio = new Audio("/sounds/click.mp3");
  var objectId = "";
  if (hoveredState) {
    if (isSection) {
      style = {
        ...style,

        filter: "drop-shadow(0rem 0.375rem 1rem rgba(10, 42, 102, 0.25))",
      };
      objectId =
        "admin/frontend-images/button/pagination-button-section-hovered.svg";
    } else if (!isAnswered && isCorrect === undefined) {
      if (IKMCMode) {
        style = {
          ...style,

          // filter: "drop-shadow(0rem 0.375rem 1rem rgba(10, 42, 102, 0.25))",
          backgroundImage:
            "url('/images/button/pagination-button-ikmc-current.svg')",
        };
      } else {
        objectId =
          "admin/frontend-images/button/pagination-button-big-hovered-bg.svg";
      }
    } else if (!isAnswered && isCorrect !== undefined) {
      if (isCorrect) {
        if (IKMCMode) {
          style = {
            ...style,

            // filter: "drop-shadow(0rem 0.375rem 1rem rgba(10, 42, 102, 0.25))",
            backgroundImage:
              "url('/images/button/pagination-button-ikmc-current.svg')",
          };
        } else {
          objectId =
            "admin/frontend-images/button/pagination-unit-quizz-success-hover.svg";
        }
      } else {
        if (IKMCMode) {
          style = {
            ...style,

            // filter: "drop-shadow(0rem 0.375rem 1rem rgba(10, 42, 102, 0.25))",
            backgroundImage:
              "url('/images/button/pagination-button-ikmc-current.svg')",
          };
        } else {
          objectId =
            "admin/frontend-images/button/pagination-unit-quiz-failed-hover.svg";
        }
      }
    } else {
      if (IKMCMode) {
        style = {
          ...style,

          // filter: "drop-shadow(0rem 0.375rem 1rem rgba(10, 42, 102, 0.25))",
          backgroundImage:
            "url('/images/button/pagination-button-ikmc-finished.svg')",
        };
      } else {
        objectId = "admin/frontend-images/button/pagination-hover.svg";
      }
    }
  }

  if (defaultState) {
    if (isSection) {
      objectId =
        "admin/frontend-images/button/pagination-button-section-default.svg";
    } else if (!isAnswered && isCorrect === undefined) {
      if (IKMCMode) {
        style = {
          ...style,
          backgroundImage:
            "url('/images/button/pagination-button-ikmc-default.svg')",
        };
      } else {
        objectId =
          "admin/frontend-images/button/pagination-button-big-clicked-bg.svg";
      }
    } else if (!isAnswered && isCorrect !== undefined) {
      if (isCorrect) {
        if (IKMCMode) {
          style = {
            ...style,
            backgroundImage:
              "url('/images/button/pagination-button-ikmc-correct.svg')",
          };
        } else {
          objectId =
            "admin/frontend-images/button/pagination-unit-quiz-success-default.svg";
        }
      } else {
        if (IKMCMode) {
          style = {
            ...style,
            backgroundImage:
              "url('/images/button/pagination-button-ikmc-incorrect.svg')",
          };
        } else {
          objectId =
            "admin/frontend-images/button/pagination-unit-quiz-failed-default.svg";
        }
      }
    } else {
      if (IKMCMode) {
        style = {
          ...style,
          backgroundImage:
            "url('/images/button/pagination-button-ikmc-finished.svg')",
        };
      } else {
        objectId = "admin/frontend-images/button/pagination-unit-quiz.svg";
      }
    }
  }

  if (clickedState || isClick) {
    if (isSection) {
      objectId =
        "admin/frontend-images/button/pagination-button-big-clicked-bg.svg";
    } else if (!isAnswered && isCorrect === undefined) {
      if (IKMCMode) {
        style = {
          ...style,
          backgroundImage:
            "url('/images/button/pagination-button-ikmc-current.svg')",
        };
      } else {
        objectId = "admin/frontend-images/button/panigation-click.svg";
      }
    } else if (!isAnswered && isCorrect !== undefined) {
      if (isCorrect) {
        if (IKMCMode) {
          style = {
            ...style,
            backgroundImage:
              "url('/images/button/pagination-button-ikmc-current.svg')",
          };
        } else {
          objectId =
            "admin/frontend-images/button/pagination-unit-quiz-click-success.svg";
        }
      } else {
        if (IKMCMode) {
          style = {
            ...style,
            backgroundImage:
              "url('/images/button/pagination-button-ikmc-current.svg')",
          };
        } else {
          objectId =
            "admin/frontend-images/button/pagination-unit-quiz-click-failed.svg";
        }
      }
    } else {
      if (IKMCMode) {
        style = {
          ...style,
          // filter: "drop-shadow(0rem 0.375rem 1rem rgba(10, 42, 102, 0.25))",
          backgroundImage:
            "url('/images/button/pagination-button-ikmc-current.svg')",
        };
      } else {
        objectId = "admin/frontend-images/button/panigation-click-finished.svg";
      }
    }
  }

  const handleMouseEnter = () => {
    if (!clickedState && !disabled) {
      setDefaultState(false);
      setHoveredState(true);
    }
  };
  const handleMouseLeave = () => {
    if (!clickedState && !disabled) {
      setDefaultState(true);
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    if (!disabled && !isClick) {
      setClickedState(!clickedState);
      setHoveredState(false);
      setDefaultState(clickedState);
      audio.play();
      if (onClick) {
        onClick();
      }
    }
    if (!disabled && isClick) {
      // setClickedState(!clickedState);
      // setHoveredState(false);
      // setDefaultState(!clickedState);
      audio.play();
      if (onClick) {
        onClick();
      }
    }
  };
  useEffect(() => {
    if (!disabled && isClick !== undefined) {
      setDefaultState(!isClick);
      setClickedState(isClick);
    } else {
      setDefaultState(!disabled);
      setClickedState(!disabled);
    }
  }, [disabled, isClick]);
  return (
    <div
      className="main"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ maxWidth: "2.5rem" }}
    >
      <BackgroundImageFromUrl
        objectId={objectId}
        className={`${defaultState
          ? `${isSection
            ? "has-text-kurio-main-color-purple"
            : !isAnswered && isCorrect === undefined
              ? IKMCMode
                ? "has-text-kurio-main-color-purple"
                : " has-text-kurio-main-color-white"
              : !isAnswered && isCorrect !== undefined && isCorrect
                ? "is-kurio-green-main-accent has-text-kurio-main-color-white"
                : !isAnswered && isCorrect !== undefined && !isCorrect
                  ? "is-kurio-red-main-accent has-text-kurio-main-color-white"
                  : "is-kurio-main-color-yellow has-text-kurio-main-color-black"
          }`
          : hoveredState
            ? `${isSection
              ? "has-text-kurio-main-color-purple"
              : !isAnswered && isCorrect === undefined
                ? "is-kurio-purple-light-0 has-text-kurio-main-color-white"
                : !isAnswered && isCorrect !== undefined && isCorrect
                  ? "is-kurio-green-light-1 has-text-kurio-main-color-white"
                  : !isAnswered && isCorrect !== undefined && !isCorrect
                    ? "is-kurio-red-light-1 has-text-kurio-main-color-white"
                    : "is-kurio-yellow-light-1 has-text-kurio-main-color-white"
            }`
            : clickedState || isClick
              ? `${isSection
                ? "has-text-kurio-main-color-white is-kurio-main-color-purple"
                : !isAnswered && isCorrect === undefined
                  ? "has-text-kurio-main-color-white is-kurio-purple-dark"
                  : !isAnswered && isCorrect !== undefined && isCorrect
                    ? "is-kurio-green-dark has-text-kurio-main-color-white"
                    : !isAnswered && isCorrect !== undefined && !isCorrect
                      ? "is-kurio-red-dark has-text-kurio-main-color-white"
                      : "is-kurio-yellow-dark has-text-kurio-main-color-white"
              }`
              : disabled
                ? "is-kurio-gray-light-2 has-text-kurio-gray-main-accent"
                : ""
          }  button main-color has-text-weight-bold is-uppercase has-text-centered`}
        style={style}
        onClick={handleClick}
        type="button"
      >
        {children}
      </BackgroundImageFromUrl>
    </div>
  );
};

export default PaginationBigButton;
