/* eslint-disable react/jsx-pascal-case */
import React, { memo } from "react";
import QPrompt1_1 from "../../../templates/content/QPrompt1_1";
import QPrompt1_2 from "../../../templates/content/QPrompt1_2";
import QPrompt1_3 from "../../../templates/content/QPrompt1_3";
import QPrompt2_1 from "../../../templates/content/QPrompt2_1";
import QPrompt2_2 from "../../../templates/content/QPrompt2_2";
import QPrompt2_3 from "../../../templates/content/QPrompt2_3";
import QPrompt2_4 from "../../../templates/content/QPrompt2_4";
import QPrompt3_1 from "../../../templates/content/QPrompt3_1";
import QPrompt3_2 from "../../../templates/content/QPrompt3_2";
import DragDrop from "../../../templates/exams-question/DragDrop";
import DragDropMulti from "../../../templates/exams-question/DragDropMulti";
import Gapfill from "../../../templates/exams-question/Gapfill";
import MultipleChoice from "../../../templates/exams-question/MultipleChoice";
import MultipleResponse from "../../../templates/exams-question/MultipleResponse";
import VerticalGapfill from "../../../templates/exams-question/VerticalGapfill";
import VerticalMultipleChoice from "../../../templates/exams-question/VerticalMultipleChoice";
import VerticalMultipleResponse from "../../../templates/exams-question/VerticalMultipleResponse";

export const MonthlyExamQuestionsRightWrapper = memo(function MonthlyExamQuestionsRightWrapper({
  question,
  seenQuestions,
  handleQuestionChange,
  questions,
  takeExamsQuestionId,
  isPreview
}) {
  const promptTemplate = question?.template.prompt;
  const questionTemplate = question?.template.question;
  const backgroundStyle = {
    backgroundSize: "cover",
    backgroundImage: `url(${question?.backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: "1rem",
  };

  const TemplateComponent = () => {
    switch (promptTemplate) {
      case "QPrompt1_1":
        return (
          <QPrompt1_1 data={question} isLast={true} order={question.questionIndex + 1} />
        );
      case "QPrompt1_2":
        return (
          <QPrompt1_2 data={question} isLast={true} order={question.questionIndex + 1} />
        );
      case "QPrompt1_3":
        return (
          <QPrompt1_3 data={question} isLast={true} order={question.questionIndex + 1} />
        );
      case "QPrompt2_1":
        return (
          <QPrompt2_1 data={question} isLast={true} order={question.questionIndex + 1} />
        );
      case "QPrompt2_2":
        return (
          <QPrompt2_2 data={question} isLast={true} order={question.questionIndex + 1} />
        );
      case "QPrompt2_3":
        return (
          <QPrompt2_3 data={question} isLast={true} order={question.questionIndex + 1} />
        );
      case "QPrompt2_4":
        return (
          <QPrompt2_4 data={question} isLast={true} order={question.questionIndex + 1} />
        );
      case "QPrompt3_1":
        return (
          <QPrompt3_1 data={question} isLast={true} order={question.questionIndex + 1} />
        );
      case "QPrompt3_2":
        return (
          <QPrompt3_2 data={question} isLast={true} order={question.questionIndex + 1} />
        );
      default:
        return <div></div>;
    }
  };

  const TemplateQuestion = () => {
    switch (questionTemplate) {
      case "MultipleChoice":
        return (
          <MultipleChoice
            question={question.question}
            IKMCMode={true}
            isPreview={isPreview}
            backgroundImage={question?.question.backgroundImage}
            questionIndex={question.questionIndex}
            isLast={question?.isLast}
            explanation={question.explanation}
            questions={questions}
            handleQuestionChange={handleQuestionChange}
            questionId={question.questionId}
            seenQuestions={seenQuestions}
            takeExamsQuestionId={takeExamsQuestionId}
          />
        );
      case "VerticalMultipleChoice":
        return (
          <VerticalMultipleChoice
            question={question.question}
            backgroundImage={question?.question.backgroundImage}
            questionIndex={question.questionIndex}
            data={question}
            explanation={question.explanation}
            order={question.questionIndex + 1}
            isLast={question?.isLast}
            questions={questions}
            handleQuestionChange={handleQuestionChange}
            questionId={question.questionId}
            seenQuestions={seenQuestions}
            takeExamsQuestionId={takeExamsQuestionId}
          />
        );

      case "VerticalMultipleResponse":
        return (
          <VerticalMultipleResponse
            question={question.question}
            backgroundImage={question?.question.backgroundImage}
            questionIndex={question.questionIndex}
            data={question}
            order={question.questionIndex + 1}
            isLast={question?.isLast}
            explanation={question.explanation}
            questions={questions}
            handleQuestionChange={handleQuestionChange}
            questionId={question.questionId}
            seenQuestions={seenQuestions}
            takeExamsQuestionId={takeExamsQuestionId}
          />
        );

      case "VerticalGapfill":
        return (
          <VerticalGapfill
            question={question.question}
            backgroundImage={question?.question.backgroundImage}
            questionIndex={question.questionIndex}
            explanation={question.explanation}
            data={question}
            order={question.questionIndex + 1}
            isLast={question?.isLast}
            questions={questions}
            handleQuestionChange={handleQuestionChange}
            questionId={question.questionId}
            seenQuestions={seenQuestions}
            takeExamsQuestionId={takeExamsQuestionId}
          />
        );
      case "MultipleResponse":
        return (
          <MultipleResponse
            question={question.question}
            IKMCMode={true}
            isPreview={isPreview}
            backgroundImage={question?.question.backgroundImage}
            questionIndex={question.questionIndex}
            isLast={question?.isLast}
            explanation={question.explanation}
            handleQuestionChange={handleQuestionChange}
            questionId={question.questionId}
            seenQuestions={seenQuestions}
            questions={questions}
            takeExamsQuestionId={takeExamsQuestionId}
          />
        );
      case "Gapfill":
        return (
          <Gapfill
            question={question.question}
            IKMCMode={true}
            isPreview={isPreview}
            explanation={question.explanation}
            backgroundImage={question?.question.backgroundImage}
            questionIndex={question.questionIndex}
            isLast={question?.isLast}
            handleQuestionChange={handleQuestionChange}
            questionId={question.questionId}
            seenQuestions={seenQuestions}
            questions={questions}
            takeExamsQuestionId={takeExamsQuestionId}
          />
        );
      case "DragDrop":
        return (
          <DragDrop
            question={question.question}
            isPreview={isPreview}
            IKMCMode={true}
            explanation={question.explanation}
            questionIndex={question.questionIndex}
            isLast={question?.isLast}
            handleQuestionChange={handleQuestionChange}
            questionId={question.questionId}
            seenQuestions={seenQuestions}
            questions={questions}
            takeExamsQuestionId={takeExamsQuestionId}
          />
        );
      case "DragDropMulti":
        return (
          <DragDropMulti
            question={question.question}
            isPreview={isPreview}
            IKMCMode={true}
            explanation={question.explanation}
            questionIndex={question.questionIndex}
            isLast={question?.isLast}
            handleQuestionChange={handleQuestionChange}
            questionId={question.questionId}
            seenQuestions={seenQuestions}
            questions={questions}
            takeExamsQuestionId={takeExamsQuestionId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div
        className="is-centered"
        style={{
          ...backgroundStyle,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "60rem",
          filter: "drop-shadow(0px 6px 16px rgba(10, 42, 102, 0.25))",
        }}
      >
        <div className="">
          <TemplateComponent />
        </div>
        <div className="mb-6">
          <TemplateQuestion />
        </div>
      </div>
    </>
  );
});

export default MonthlyExamQuestionsRightWrapper;
