import instance from "./instance";

const practiceUrl = "/practice/v1/user/practice-by-elo";

export const createPracticeByElo = () => {
  // checkAndCallDailyLoginAPI()
  return instance.get(`${practiceUrl}/create-new-practice`);
};
export const getPracticeByElo = (id, page, size) => {
  // checkAndCallDailyLoginAPI()
  return instance.get(
    `${practiceUrl}/get-practice-questions/${id}?page=${page}&size=${size}`
  );
};
export const submitPracticeByEloAnswers = (id, answer) => {
  // checkAndCallDailyLoginAPI()
  return instance.post(`${practiceUrl}/submit-practice-answers/${id}`, answer);
};
