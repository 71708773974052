import React from "react";

const TagCheckMultipleResponse = ({ check }) => {
  var imgStyle = {
    width:"1.5rem",
    height:"1.5rem"
  }
    return (
        <div
            className={`${
                check === true
                    ? "has-background-kurio-green-main-accent"
                    : "has-background-kurio-red-main-accent"
            } is-flex is-flex-direction-row has-text-kurio-main-color-white has-text-weight-bold has-text-centered`}
            style={{ gap: "0.625rem", fontSize: "1rem" , borderRadius:"1rem", padding:"0.5rem 0.75rem"}}
        >
            {check === true && (
                <>
                    <img src="/images/common/correct.svg" style={imgStyle} alt="loading" />
                    <p className="">Trả lời đúng</p>
                </>
            )}
            {check === false && (
                <>
                    <img src="/images/common/wrong.svg" style={imgStyle} alt="loading" />
                    <p className="">Trả lời sai</p>
                </>
            )}
        </div>
    );
};

export default TagCheckMultipleResponse;
