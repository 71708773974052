import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { decodeToken } from "react-jwt";
import Cookies from "universal-cookie";
import {
  getExamQuestionSolution,
  getExamQuestions,
  getListExamInformation,
  getListMonthlyExams,
  getListYearExams,
  getViewAbleExam,
  submitExamAnswers,
  takeExams
} from "../config/examsQuestion";
import { getAQuestion } from "../config/question";

const cookies = new Cookies();
const token = cookies.get("signinUser");
const roles = ["CREATOR", "REVIEWER"];
const decode = decodeToken(token);

const initialState = {
  checkExamTime: null,
  idExamAdmin: null,
  serverTime: null,
  questions: null,
  seenQuestions: 0,
  listYearExams: null,
  listMonthlyExams: null,
  recordCount: 0,
  pageCount: 0,
  currentPage: 0,
  pageSize: 0,
  correctAnswers: 0,
  currentScore: 0,
  maxScore: 0,
  wrongAnswers: 0,
  skippedQuestions: 0,
  status: "idle",
  error: null,
  examInformation: null,
  takeExamsQuestion: null,
  takeExamIdInformation: null,
  takeExamIdLPInformation: null,
  answeredQuestions: [],
};

export const getListYearExamsQuestion = createAsyncThunk(
  "examsQuestion/getListYearExamsQuestion",
  async ({ page, size }, thunkAPI) => {
    try {
      const { result } = await getListYearExams(page, size);
      if (
        result !== undefined &&
        result.records.length > 0 &&
        result !== null
      ) {
        var listExamInfo = await axios.all(
          result.records.map(async (item) => {
            const { result: examInfo } = await getListExamInformation(item._id);
            return examInfo;
          })
        );

        const listNewExamInfo = listExamInfo.map((item) => {
          return {
            id: item._id,
            takeExamId: item.takeExamId,
            takeExamLP: item.takeExamIdLP,
          };
        });
        localStorage.setItem("listExamInfo", JSON.stringify(listNewExamInfo));

        const resultExamInfo = JSON.parse(localStorage.getItem("listExamInfo"));
        var listExamSolutions = await axios.all(
          resultExamInfo.map(async (item) => {
            if (item.takeExamLP !== null) {
              try {
                const { result } = await getExamQuestionSolution(
                  item.takeExamLP,
                  page,
                  size
                );

                return result;
              } catch (error) {
                return error.response?.data?.errorMessage;
              }
            }
          })
        );

        const listNewExamSolutions = listExamSolutions.map((item) => {
          if (item !== null && item !== undefined) {
            return {
              maxScore: item.maxScore,
              currentScore: item.currentScore,
            };
          }
          return true;
        });

        localStorage.setItem(
          "listNewExamSolutions",
          JSON.stringify(listNewExamSolutions)
        );
      }
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const getListMonthlyExamsQuestion = createAsyncThunk(
  "examsQuestion/getListMonthlyExamsQuestion",
  async ({ page, size }, thunkAPI) => {
    try {
      const { result } = await getListMonthlyExams(page, size);

      if (
        result.records.length > 0 &&
        result !== undefined &&
        result !== null
      ) {
        var currentDate = new Date();

        let minDate = new Date(result.records[0].endDate);
        let minDate_index = 0;

        let minDate_indexSolution = 0;

        for (var i = 0; i < result.records.length; i++) {
          const dateRecords = new Date(result.records[i].endDate);

          // console.log("minDate", minDate);
          // console.log("currentDate", currentDate);
          // console.log("dateRecords", dateRecords);

          // if (minDate.getTime() > currentDate.getTime()) {
          //   minDate = new Date(result.records[i].endDate);
          //   minDate_index = i ;
          // }

          if (
            currentDate.getTime() - minDate.getTime() >=
            currentDate.getTime() - dateRecords.getTime() &&
            currentDate.getTime() > dateRecords.getTime()
          ) {
            minDate = dateRecords;
            minDate_index = i;
          }

          if (dateRecords.getTime() <= currentDate.getTime()) {
            const dateTime = result.records.filter(
              (item) => new Date(item.endDate).getTime() < currentDate.getTime()
            );

            const listEndDateTime = dateTime.map((item) =>
              new Date(item.endDate).getTime()
            );

            // const listEndDateTime = dateTime.map((item) =>
            //   new Date(item.endDate).getTime()
            // );

            minDate_indexSolution = i;
          }
        }

        if (
          new Date(result.records[minDate_indexSolution].endDate).getTime() <
          currentDate.getTime()
        ) {
          if (result.records[minDate_indexSolution]._id !== undefined) {
            const { result: solutionInfo } = await getListExamInformation(
              result.records[minDate_indexSolution]._id
            );

            localStorage.setItem("solutionInfo", JSON.stringify({ ...solutionInfo, name: result.records[minDate_indexSolution].name }));
          }
        }

        const test = result.records.filter(
          (item, index) =>
            item.startDate !== null &&
            item.endDate !== null &&
            new Date(item.endDate).getTime() >=
            new Date(currentDate).getTime() &&
            new Date(item.startDate).getTime() <=
            new Date(currentDate).getTime()
        );

        if (result.records.length === 1) {
          if (
            new Date(result.records[0].endDate).getTime() <=
            new Date(currentDate).getTime()
          ) {
            // const { result: solutionInfo } = await getListExamInformation(
            //   result.records[minDate_index - 1]._id
            // );
            // localStorage.setItem("solutionInfo", JSON.stringify(solutionInfo));
          } else {
            // const { result: solutionInfo } = await getListExamInformation(
            //   result.records[minDate_index - 1]._id
            // );
            // localStorage.setItem("solutionInfo", JSON.stringify(solutionInfo));

            localStorage.removeItem("solutionInfo");
          }
        } else if (test?.length === 0) {
          const { result: solutionInfo } = await getListExamInformation(
            result.records[minDate_index - 1]._id
          );

          localStorage.setItem("solutionInfo", JSON.stringify(solutionInfo));
        }

        result.records =
          test?.length === 0
            ? result.records.filter((item, index) => index === minDate_index)
            : test;

        if (
          result.records[0] &&
          new Date(result.records[0].endDate).getTime() >=
          new Date(currentDate).getTime() &&
          new Date(result.records[0].startDate).getTime() <=
          new Date(currentDate).getTime()
        ) {
          localStorage.setItem(
            "examMonthlyNow",
            JSON.stringify(result.records[0])
          );

        } else {

          localStorage.setItem(
            "examMonthlyNow",
            JSON.stringify(result.records[0])
          );

          // localStorage.removeItem("examMonthlyNow");)
        }

        var listExamInfo = await axios.all(
          result.records.map(async (item) => {
            const { result: examInfo } = await getListExamInformation(item._id);
            return examInfo;
          })
        );

        var listNewExamInfo = listExamInfo?.map((item) => {
          return {
            id: item._id,
            takeExamId: item.takeExamId
              ? item.takeExamId
              : localStorage.getItem("takeExamIdInfo"),
            takeExamLP: item.takeExamIdLP,
          };
        });

        localStorage.setItem(
          "listExamInfoMonthly",
          JSON.stringify(listNewExamInfo)
        );

        return result;
      } else {
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const getListExamsInformation = createAsyncThunk(
  "examsQuestion/getListExamsInformation",
  async ({ examYearId }, thunkAPI) => {
    try {
      const { result } = await getListExamInformation(examYearId);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const createNewExams = createAsyncThunk(
  "examsQuestion/createNewExams",
  async ({ examsQuestionId }, thunkAPI) => {
    try {
      const { result } = await takeExams(examsQuestionId);
      if (localStorage.getItem("examMonthlyNow")) {
        localStorage.setItem("takeExamMonthlyQuestionId", result);
      } else {
        localStorage.setItem("takeExamYearQuestionId", result);
      }

      return result;
    } catch (error) {
      // if (error) {
      //   alert(error.response?.data?.errorMessage)
      //   window.location.href = "/";
      // }
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const getExamQuestion = createAsyncThunk(
  "examsQuestion/getExamQuestion",
  async ({ takeExamsQuestionId, page, size, token }, thunkAPI) => {
    try {
      const { result } = await getExamQuestions(
        takeExamsQuestionId,
        page,
        size,
        token
      );

      if (
        result.questions?.some(
          (question) => !question.question && !localStorage.getItem("toggle")
        )
      ) {
        localStorage.setItem("nameExam", result.name);
        var listQuestion = await axios.all(
          result.questions.map(async (questionItem) => {
            const { result } = await getAQuestion(
              questionItem.questionId,
              token
            );

            questionItem = {
              ...questionItem,
              explanation: result.explanation,
              prompt: result.prompt,
              question: result.question,
              type: result.type,
            };

            return questionItem;
          })
        );

        result.questions = listQuestion;
      }
      return result;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const submitExamAnswer = createAsyncThunk(
  "examsQuestion/submitExamAnswer",
  async ({ submit, examAnswerId }, thunkAPI) => {
    try {
      const { result } = await submitExamAnswers(submit, examAnswerId);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const getExamQuestionSolutions = createAsyncThunk(
  "examsQuestion/getExamQuestionSolutions",
  async ({ takeExamsQuestionId, page, size, showSolution }, thunkAPI) => {
    try {
      const { result } = await getExamQuestionSolution(
        takeExamsQuestionId,
        page,
        size
      );
      if (showSolution) {
        result.records.map((record) => (record.answer = []))
        result.records.map((record) => (record.correct = null))
        result.records.map((record) => (record.skipped = null))
      }

      result.records.map((record) => (record.question.showSolution = true));

      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const getViewableExamSolutions = createAsyncThunk(
  "examsQuestion/getViewableExamSolutions",
  async ({ examYearId, page, size, token }, thunkAPI) => {
    try {
      const { result } = await getViewAbleExam(examYearId, page, size, token);

      if (decode?.roles?.some((role) => roles.includes(role))) {
        if (result.questions?.some((question) => !question.question)) {
          var listQuestionPreview = await axios.all(
            result?.questions?.map(async (questionItem) => {
              const { result } = await getAQuestion(
                questionItem.questionId,
                token
              );

              questionItem = {
                ...questionItem,
                explanation: result.explanation,
                prompt: result.prompt,
                question: result.question,
                type: result.type,
              };

              return questionItem;
            })
          );

          result.questions = listQuestionPreview;
        } else {
          var listQuestion = await axios.all(
            result?.records?.map(async (questionItem) => {
              const { result } = await getAQuestion(
                questionItem.questionId,
                token
              );

              questionItem = {
                ...questionItem,
                explanation: result.explanation,
                prompt: result.prompt,
                question: result.question,
                type: result.type,
              };

              return questionItem;
            })
          );

          result.questions = listQuestion;
        }
      }

      if (decode?.roles?.some((role) => roles.includes(role))) {
        result.questions.map((item) => (item.answer = item.question.solutions));

        result.questions.map((record) => (record.question.showSolution = true));
        result.questions.map((record) => (record.isCorrect = true));
      } else {
        result.records.map((item) => (item.answer = item.question.solutions));

        result.records.map((record) => (record.question.showSolution = true));
      }

      return result;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const examsQuestion = createSlice({
  name: "examsQuestion",
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },

    setListYearExams: (state, action) => {
      state.listYearExams = action.payload;
    },
    setTakeExamIdInformation: (state, action) => {
      state.takeExamIdInformation = action.payload;
    },
    setTakeExamIdLPInformation: (state, action) => {
      state.takeExamIdLPInformation = action.payload;
    },
    setTakeExamsQuestion: (state, action) => {
      state.takeExamsQuestion = action.payload;
    },
    setListExamInformation: (state, action) => {
      state.examInformation = action.payload;
    },

    setQuestionState: (state, action) => {
      state.questions[action.payload.index].question.state =
        action.payload.state;
    },

    setQuestionsAnswered: (state, action) => {
      state.answeredQuestions = action.payload;
      for (var i = 0; i < action.payload.length; i++) {
        if (action.payload[i]) {
          state.questions[action.payload[i].questionIndex].answer =
            action.payload[i].answer;
        }
      }
    },

    clearState: (state) => {
      state.questions = null;
      state.createNewPractice = null;
      state.answeredQuestions = [];
      state.newPracticeId = null;
      state.error = null;
    },
    clearTime: (state) => {
      state.checkExamTime = null;
      state.serverTime = null;
    },

    clearTakeExamId: (state) => {
      state.takeExamsQuestion = null;
    },

    clearExamQuestion: (state) => {
      state.questions = null;
      state.listYearExams = null;
    },

    setQuestionAnswered: (state, action) => {
      state.questions[action.payload.questionIndex].isAnswered =
        action.payload.status;
    },

    setSeenQuestions: (state, action) => {
      state.seenQuestions = action.payload;
    },
    setQuestionFinished: (state, action) => {
      state.questions[action.payload].isFinished = true;
    },
    setSkippedQuestions: (state, action) => {
      state.skippedQuestions = action.payload + state.skippedQuestions;
    },
    setCorrectAnswer: (state, action) => {
      state.correctAnswers = action.payload + state.correctAnswers;
    },
    setWrongAnswer: (state, action) => {
      state.wrongAnswers = action.payload + state.wrongAnswers;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getListYearExamsQuestion.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.listYearExams = action.payload.records;
        state.recordCount = action.payload.recordCount;
        state.pageCount = action.payload.pageCount;
        state.currentPage = action.payload.currentPage;
        state.pageSize = action.payload.pageSize;
      })

      .addCase(getListMonthlyExamsQuestion.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.listMonthlyExams = action?.payload?.records;
        state.recordCount = action?.payload?.recordCount;
        state.pageCount = action?.payload?.pageCount;
        state.currentPage = action?.payload?.currentPage;
        state.pageSize = action?.payload?.pageSize;
      })

      .addCase(createNewExams.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.takeExamsQuestion = action.payload;
      })

      .addCase(createNewExams.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(getExamQuestion.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getExamQuestion.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.maxScore = action.payload.maxScore;
        state.currentScore = action.payload.currentScore;
        state.idExamAdmin = action.payload._id;
        state.checkExamTime = action.payload.deadline;
        state.serverTime = action.payload.serverTime;
        state.questions = action.payload.records
          ? action.payload.records
          : action.payload.questions;
        state.correctAnswers = action.payload.correctAnswers;
        state.wrongAnswers = action.payload.wrongAnswers;
        state.skippedQuestions = action.payload.skippedQuestions;
        state.seenQuestions =
          action.payload.seenQuestions === 0 ? 1 : action.payload.seenQuestions;
        state.questions[state?.questions?.length - 1].isLast = true;
        state.questions[0].visible = true;
        state.questions.forEach((question, index) => {
          question.questionIndex = index;
          question.isAnswered =
            question?.answer !== null && question?.answer?.length > 0;
        });
        state.questions
          .filter((question, index) => index < state.seenQuestions)
          .forEach((question) => {
            question.done = true;
            question.visible = true;
          });
      })

      .addCase(getExamQuestion.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(getExamQuestionSolutions.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getExamQuestionSolutions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questions = action.payload.records;
        state.correctAnswers = action.payload.correctAnswers;
        state.currentScore = action.payload.currentScore;
        state.maxScore = action.payload.maxScore;
        state.wrongAnswers = action.payload.wrongAnswers;
        state.skippedQuestions = action.payload.skippedQuestions;
        state.questions[0].visible = true;
        state.seenQuestions =
          action.payload.seenQuestions === 0 ? 1 : action.payload.seenQuestions;
        state.questions[state.questions.length - 1].isLast = true;
        state.questions.forEach((question, index) => {
          question.questionIndex = index;
          if (question.question.showSolution) {

            if (question.answer !== null &&
              question.answer.length > 0) {
              question.isCorrect = question.correct;
            } else {
              question.isCorrect = undefined;
            }

          } else {
            question.isAnswered =
              question.answer !== null && question.answer.length > 0;;
          }
        });
        state.questions
          .filter((question, index) => index < state.seenQuestions)
          .forEach((question) => {
            question.done = true;
            question.visible = true;
          });
      })
      .addCase(getExamQuestionSolutions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(getViewableExamSolutions.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getViewableExamSolutions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questions = action.payload.questions
          ? action.payload.questions
          : action.payload.records;
        state.correctAnswers = action.payload.correctAnswers;
        state.currentScore = action.payload.currentScore;
        state.maxScore = action.payload.maxScore;
        state.wrongAnswers = action.payload.wrongAnswers;
        state.skippedQuestions = action.payload.skippedQuestions;
        if (state.questions[0]) {
          state.questions[0].visible = true;
        }
        state.seenQuestions =
          action.payload.seenQuestions === 0 ? 1 : action.payload.seenQuestions;
        if (state.questions[state.questions.length - 1]) {
          state.questions[state.questions.length - 1].isLast = true;
        }
        state.questions.forEach((question, index) => {
          question.questionIndex = index;
          if (question.question.showSolution) {
            question.isCorrect = undefined;
            // question.answer !== null &&
            // question.answer.length > 0 &&
            // question.answer.every(
            //   (item, index) => item === question.question.solutions[index]
            // );
          } else {
            question.isAnswered =
              question.answer !== null && question.answer.length > 0;
          }
        });
        state.questions
          .filter((question, index) => index < state.seenQuestions)
          .forEach((question) => {
            question.done = true;
            question.visible = true;
          });
      })

      .addCase(getViewableExamSolutions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(submitExamAnswer.fulfilled, (state, action) => {
        state.status = "succeeded";
      })

      .addCase(getListExamsInformation.fulfilled, (state, action) => {
        state.status = "failed";
        state.examInformation = action.payload;
      });
  },
});

export const {
  setStatus,
  setListYearExams,
  setQuestionsAnswered,
  clearState,
  setQuestionAnswered,
  setQuestionVisible,
  setQuestionState,
  setSeenQuestions,
  setCorrectAnswer,
  setSkippedQuestions,
  setWrongAnswer,
  setQuestionFinished,
  setTakeExamIdInformation,
  setTakeExamsQuestion,
  setTakeExamIdLPInformation,
  setListExamInformation,
  clearExamQuestion,
  clearTakeExamId,
  clearError,
  clearTime
} = examsQuestion.actions;

export const selectQuestions = (state) => state.examsQuestion.questions;
export const selectAnsweredQuestions = (state) =>
  state.examsQuestion.answeredQuestions;

export const selectStatus = (state) => state.examsQuestion.status;

export default examsQuestion.reducer;
