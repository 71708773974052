import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import {
  clearExamQuestion,
  clearState,
  clearTime,
  getExamQuestion,
  getExamQuestionSolutions,
  setQuestionAnswered,
  setQuestionState,
  setQuestionsAnswered,
  submitExamAnswer,
} from "../../reducers/examsQuestionSlice";
import { LoadingImage } from "../../templates/helper/LoadingImage";
import { getAllImage } from "../../templates/helper/getAllImages";
import IkmcExamQuestionsLeftWrapper from "./left/IkmcExamQuestionsLeftWrapper";
import ExamQuestionRightWrapper from "./right/IkmcExamQuestionsRightWrapper";

const IkmcExamQuestionsMainWrapper = ({
  takeExamsQuestionId,
  examQuestions,
  seenQuestions,
  takeExamIdInfo,
  error,
}) => {
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [loadingImage, setLoadingImage] = useState(false);
  const [pagination, setPagination] = useState(
    localStorage.getItem("pagination")
      ? parseInt(localStorage.getItem("pagination"))
      : 1
  );
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const token = cookies.get("signinUser");
  const page = 1;
  const size = 60;
  const handleSetLoadingImage = (state) => {
    setLoadingImage(state);
  };
  const navigate = useNavigate();
  // const location = useLocation();
  const handleChangePagination = (pagination) => {
    setPagination(pagination);
  };

  const handleQuestionChange = (val) => {
    if (val !== selectedQuestion) {
      if (
        val < 20 &&
        (!localStorage.getItem("pagination") ||
          parseInt(localStorage.getItem("pagination")) === 2)
      ) {
        localStorage.setItem("pagination", 1);
        setPagination(1);
      } else if (
        val >= 20 &&
        (!localStorage.getItem("pagination") ||
          parseInt(localStorage.getItem("pagination")) === 1)
      ) {
        localStorage.setItem("pagination", 2);
        setPagination(2);
      }
      const result = JSON.parse(localStorage.getItem("questionStateExams"));
      if (result) {
        dispatch(setQuestionState(result.questionState));
        dispatch(setQuestionAnswered(result.questionAnswered));
        dispatch(setQuestionsAnswered(result.questionsAnswered));
        var submit = result.submit;
        dispatch(
          submitExamAnswer({
            submit,
            examAnswerId: localStorage.getItem("takeExamYearQuestionId")
              ? localStorage.getItem("takeExamYearQuestionId")
              : localStorage.getItem("takeExamIdInformation"),
          })
        );
      } else {
        var answeredQuestions = examQuestions.map((question) => ({
          questionId: question.questionId,
          questionIndex: question.questionIndex,
          answer: question.answer?.length > 0 ? question.answer : [],
        }));
        dispatch(setQuestionsAnswered(answeredQuestions));
      }

      localStorage.removeItem("questionStateExams");
      setSelectedQuestion(val);
    }
  };

  const checkingState = examQuestions?.every(
    (item) => item.question.showSolution === true
  );

  useEffect(() => {
    if (
      ((localStorage.getItem("takeExamYearQuestionId") &&
        localStorage.getItem("takeExamIdInformation")) ||
        examQuestions === null) &&
      !localStorage.getItem("takeExamIdLPInformation") &&
      !localStorage.getItem("examYearIdMonthly") &&
      !localStorage.getItem("takeExamIdLPMonthly") &&
      !localStorage.getItem("listExamInfoMonthly") &&
      !localStorage.getItem("examMonthlyNow") &&
      !localStorage.getItem("takeExamIdInfo") &&
      !localStorage.getItem("showSolutionId")
    ) {
      dispatch(
        getExamQuestion({
          takeExamsQuestionId:
            takeExamsQuestionId ||
            localStorage.getItem("takeExamYearQuestionId")
              ? takeExamsQuestionId !== null
                ? takeExamsQuestionId
                : localStorage.getItem("takeExamYearQuestionId")
              : localStorage.getItem("takeExamIdInformation"),
          page,
          size,
          token,
        })
      );
      if (examQuestions === null || !examQuestions) {
        setLoadingImage(true);
      }

      window.onbeforeunload = () => {
        localStorage.removeItem("questionStateExams")
        navigate("/ikmc-exam-questions", {
          state: { prevRouter: "/exams-vault" },
        });
      };
    } else if (
      ((localStorage.getItem("takeExamYearQuestionId") &&
        localStorage.getItem("takeExamIdInformation")) ||
        examQuestions !== null) &&
      !localStorage.getItem("takeExamIdLPInformation") &&
      !localStorage.getItem("examYearIdMonthly") &&
      !localStorage.getItem("takeExamIdLPMonthly") &&
      !localStorage.getItem("listExamInfoMonthly") &&
      !localStorage.getItem("examMonthlyNow") &&
      !localStorage.getItem("takeExamIdInfo") &&
      !localStorage.getItem("showSolutionId")
    ) {
      dispatch(
        getExamQuestion({
          takeExamsQuestionId:
            takeExamsQuestionId ||
            localStorage.getItem("takeExamYearQuestionId")
              ? takeExamsQuestionId !== null
                ? takeExamsQuestionId
                : localStorage.getItem("takeExamYearQuestionId")
              : localStorage.getItem("takeExamIdInformation"),
          page,
          size,
          token,
        })
      );
      if (examQuestions === null || !examQuestions) {
        setLoadingImage(true);
      }

      window.onbeforeunload = () => {
        localStorage.removeItem("questionStateExams")
        navigate("/ikmc-exam-questions", {
          state: { prevRouter: "/exams-vault" },
        });
      };
    }

    if (
      localStorage.getItem("takeExamIdLPInformation") &&
      examQuestions !== null
    ) {
      // dispatch(getListYearExamsQuestion({ page, size }));
      dispatch(
        getExamQuestionSolutions({
          takeExamsQuestionId: localStorage.getItem("takeExamIdLPInformation"),
          page,
          size,
        })
      );
      if (examQuestions === null || !examQuestions) {
        setLoadingImage(true);
      }

      window.onbeforeunload = () => {
        navigate("/ikmc-exam-questions", {
          state: { prevRouter: "/exams-vault" },
        });
      };
    } else if (
      localStorage.getItem("takeExamIdLPInformation") &&
      examQuestions === null
    ) {
      // dispatch(getListYearExamsQuestion({ page, size }));
      dispatch(
        getExamQuestionSolutions({
          takeExamsQuestionId: localStorage.getItem("takeExamIdLPInformation"),
          page,
          size,
        })
      );
      if (examQuestions === null || !examQuestions) {
        setLoadingImage(true);
      }
      window.onbeforeunload = () => {
        navigate("/ikmc-exam-questions", {
          state: { prevRouter: "/exams-vault" },
        });
      };
    }
    if (localStorage.getItem("showSolutionId") && examQuestions !== null) {
      // dispatch(getListYearExamsQuestion({ page, size }));
      dispatch(
        getExamQuestionSolutions({
          takeExamsQuestionId: localStorage.getItem("showSolutionId"),
          page,
          size,
          showSolution: true,
        })
      );
      if (examQuestions === null || !examQuestions) {
        setLoadingImage(true);
      }

      window.onbeforeunload = () => {
        navigate("/ikmc-exam-questions", {
          state: { prevRouter: "/exams-vault" },
        });
      };
    } else if (
      localStorage.getItem("showSolutionId") &&
      examQuestions === null
    ) {
      // dispatch(getListYearExamsQuestion({ page, size }));
      dispatch(
        getExamQuestionSolutions({
          takeExamsQuestionId: localStorage.getItem("showSolutionId"),
          page,
          size,
          showSolution: true,
        })
      );
      if (examQuestions === null || !examQuestions) {
        setLoadingImage(true);
      }
      window.onbeforeunload = () => {
        navigate("/ikmc-exam-questions", {
          state: { prevRouter: "/exams-vault" },
        });
      };
    }
    window.onpopstate = () => {
      dispatch(clearExamQuestion());
      dispatch(clearTime());
      dispatch(clearState());
    };
    // if (!window.location.pathname.includes("preview")) {
    //   if (
    //     localStorage.getItem("examMonthlyNow") &&
    //     !localStorage.getItem("examYearIdMonthly")
    //   ) {
    //     dispatch(
    //       getListExamsInformation({
    //         examYearId: JSON.parse(localStorage.getItem("examMonthlyNow"))?._id,
    //       })
    //     );
    //   }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // if (error && location.state.prevRouter === "/exams-vault") {
  //   navigate("/exams-vault");
  // }

  if (loadingImage && examQuestions !== null && examQuestions?.length > 0 ) {
    const result = getAllImage(examQuestions, "EXAM");
    if (result && result?.length > 0 && !localStorage.getItem("currentIndex")) {
      return (
        <>
          <LoadingImage
            images={result}
            router={""}
            handleSetLoadingImage={(state) => handleSetLoadingImage(state)}
          />
        </>
      );
    } else {
      setLoadingImage(false);
    }
  }

  return (
    <div>
      {/* {loadingImage && <LoadingImage />} */}
      {!loadingImage && examQuestions !== null && examQuestions?.length > 0 && (
        <BackgroundImageFromUrl
          className="columns is-mobile m-0 p-0"
          objectId={ checkingState !== undefined && !checkingState
            ? "admin/frontend-images/hub/background-exam-vault.jpg"
            : "admin/frontend-images/hub/background-solution-exam.svg"}
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            // backgroundImage:
            //   checkingState !== undefined && !checkingState
            //     ? "url('/images/hub/background-exam-vault.jpg')"
            //     : "url('/images/hub/background-solution-exam.svg')",
            backgroundAttachment: "fixed",
          }}
        >
          {examQuestions && (
            <div
              style={{
                // backgroundColor: checkingState ? "#F7F1DF" : "#DEEBF7",
                minHeight: "100vh",
                padding: "5rem 2.5rem 10rem 2.5rem",
                marginRight: "18.75rem",
                width: "calc(100% - 18.75rem)",
              }}
              className="column is-four-fifths is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
            >
              <ExamQuestionRightWrapper
                question={examQuestions[selectedQuestion]}
                selectedQuestion={selectedQuestion}
                seenQuestions={seenQuestions}
                handleQuestionChange={handleQuestionChange}
                questions={examQuestions}
                takeExamsQuestionId={takeExamsQuestionId}
              />
            </div>
          )}
          <div
            className="column m-0 is-one-fifth p-0 is-justify-content-center is-align-item-flex-start"
            style={{
              position: "fixed",
              right: "0",
              display: "flex",
              zIndex: 1000,
              flexDirection: "column",
              width: "18.75rem",
              height: "100%",
            }}
          >
            <IkmcExamQuestionsLeftWrapper
              selectedIndex={selectedQuestion}
              handleQuestionChange={handleQuestionChange}
              handleChangePagination={(pagination) =>
                handleChangePagination(pagination)
              }
              takeExamsQuestionId={takeExamsQuestionId}
              pagination={pagination}
            />
          </div>
        </BackgroundImageFromUrl>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    takeExamIdInfo: state.examsQuestion?.examInformation?.takeExamId,
    takeExamsQuestionId: state.examsQuestion.takeExamsQuestion,
    takeExamIdInformation: state.examsQuestion.takeExamIdInformation,
    takeExamIdLPInformation: state.examsQuestion.takeExamIdLPInformation,
    examQuestions: state.examsQuestion.questions,
    seenQuestions: state.examsQuestion.seenQuestions,
    error: state.examsQuestion.error,
  };
}

export default connect(mapStateToProps)(IkmcExamQuestionsMainWrapper);
