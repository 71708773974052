import { decodeToken } from "react-jwt";
import instance from "./instance";
const practiceUrl = "/practice/v1/user";
const takeExamUrl = "/practice/v1/user/take-exam";
const admUrl = "/practice/v1/admin/exam";

export const getListYearExams = (page, size) => {
  // checkAndCallDailyLoginAPI()
  return instance.get(
    `${practiceUrl}/exam/get-list-year-exams?page=${page}&size=${size}`
  );
};

export const getListMonthlyExams = (page, size) => {
  // checkAndCallDailyLoginAPI()
  return instance.get(
    `${practiceUrl}/exam/get-list-month-exams?page=${page}&size=${size}`
  );
};

export const getListExamInformation = (id) => {
  // checkAndCallDailyLoginAPI()
  return instance.get(`${practiceUrl}/exam/get-exam-information/${id}`);
};

export const takeExams = (id, page, size) => {
  // checkAndCallDailyLoginAPI()
  return instance.put(`${takeExamUrl}/take-exam/${id}?page=${page}&size=${size}`, []);
};

export const getExamQuestions = (id, page, size, token) => {
  const roles = ["CREATOR", "REVIEWER"];
  const decode = decodeToken(token);
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  if (
    token !== undefined &&
    decode.roles.some((role) => roles.includes(role))
  ) {
    return instance.get(`${admUrl}/get-an-exam/${id}`,config);
  } else {
    // checkAndCallDailyLoginAPI()
    return instance.get(
      `${takeExamUrl}/get-exam-questions/${id}?page=${1}&size=${60}`
    );
  }
};

export const submitExamAnswers = (answer, id) => {
  // checkAndCallDailyLoginAPI()
  return instance.post(`${takeExamUrl}/submit-exam-answers/${id}`, answer);
};

export const getExamQuestionSolution = (id, page, size) => {
  // checkAndCallDailyLoginAPI()
  return instance.get(`${takeExamUrl}/get-exam-solutions/${id}?page=${page}&size=${size}`);
};

export const getViewAbleExam = (id, page, size, token) => {
  const roles = ["CREATOR", "REVIEWER"];
  const decode = decodeToken(token);

  if (
    token !== undefined &&
    decode.roles.some((role) => roles.includes(role))
  ) {
    return instance.get(`${admUrl}/get-an-exam/${id}`);
  } else {
    // checkAndCallDailyLoginAPI()
    return instance.get(
      `${practiceUrl}/exam/get-viewable-exam-solutions/${id}?page=${page}&size=${size}`
    );
  }
};

export const getDailyLogin = () => {
  return instance.post(
    `${practiceUrl}/user-lifetime-statistic/daily-login`,
  );
};
export const getExamRanking = (id) => {
  // checkAndCallDailyLoginAPI()
  return instance.get(
    `${practiceUrl}/exam/get-exam-ranking/${id}`,
  );
};
