import instance from "./instance";

const lessonUserUrl = "/lesson/v1/user";
const progressUserUrl = "/progress/v1/user";
const categoryGrade = "/category/v1/user";
const userUrl = "/auth/v1/user";
const practiceUrl = "/practice/v1/user";

export const getListUnit = (id) => {
  // checkAndCallDailyLoginAPI()
  return instance.get(`${lessonUserUrl}/unit/get-an-unit/${id}`);
};
export const getGradeUnits = (id, page, size) => {
  // checkAndCallDailyLoginAPI()
  return instance.get(
    `${lessonUserUrl}/unit/get-grade-units/${id}?page=${page}&size=${size}`
  );
};
export const getGradeUnitProgress = (id) => {
  // checkAndCallDailyLoginAPI()
  return instance.get(
    `${progressUserUrl}/unit-progress/get-grade-unit-progress/${id}`
  );
};
export const getUnitLessonProgress = (id) => {
  // checkAndCallDailyLoginAPI()
  return instance.get(
    `${progressUserUrl}/lesson-progress/get-unit-lesson-progress/${id}`
  );
};
export const getLastLesson = () => {
  // checkAndCallDailyLoginAPI()
  return instance.get(`${progressUserUrl}/lesson-progress/get-last-lesson`);
};
export const getGradeCategory = () => {
  // checkAndCallDailyLoginAPI()
  return instance.get(`${categoryGrade}/grade/get-grade-category`);
};
export const updateGrade = (id) => {
  // checkAndCallDailyLoginAPI()
  return instance.post(`${userUrl}/account/update-grade`, id);
};
export const getQuiz = (id) => {
  // checkAndCallDailyLoginAPI()
  return instance.get(`${practiceUrl}/quiz/get-quiz-information/${id}`);
};
