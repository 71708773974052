import React from "react";
import BackgroundImageFromUrl from "../parseImage/BackgroundImageFromUrl";

const RoadMapExam = ({
  type,
  index,
  title,
  disabled,
  status,
  currentScore,
  maxScore,
  onClick,
}) => {
  // type === 1 : bài exam đầu tiên, type === 2 : các bài exam 2 3 4, type === 3 : bài exam cuối
  // status === 1 : chưa làm, status === 2 : đã làm xong
  // index số thứ tự exam
  var boxScoreStyle = {
    width: "10rem",
    height: "4rem",
    borderRadius: "1rem",
    background: "#FAFBFC",
    fontSize: "1.5rem",
    lineHeight: "normal",
  };
  var buttonReviewStyle = {
    width: "6rem",
    height: "1.75rem",
    fontSize: "0.875rem",
    lineHeight: "normal",
    padding: "0 0.5rem",
    borderRadius: "1rem",
    background: "#FAFBFC",
    marginTop: "-1rem",
    gap: "0.625rem",
  };
  if (type === 1) {
    boxScoreStyle = { ...boxScoreStyle, border: "0.25rem solid #572CD1" };
    buttonReviewStyle = { ...buttonReviewStyle, background: "#572CD1" };
  }
  if (type === 2) {
    boxScoreStyle = { ...boxScoreStyle, border: "0.25rem solid #3273A8" };
    buttonReviewStyle = { ...buttonReviewStyle, background: "#3273A8" };
  }
  if (type === 3) {
    boxScoreStyle = {
      ...boxScoreStyle,
      border: "0.25rem solid #C06B0C",
      color: "#C06B0C",
    };
    buttonReviewStyle = { ...buttonReviewStyle, background: "#C06B0C" };
  }
  const audio = new Audio("/sounds/click.mp3");
  const handleClick = () => {
    audio.play();
    if (onClick) {
      onClick();
    }
  };
  return (
    <div
      className={`${disabled ? "" : status === 1 ? "" : status === 2 ? "" : ""
        } is-flex is-justify-content-space-between is-align-items-center is-flex-shrink-0 is-relative`}
      style={{
        width: "35rem",
        border: "0.09375rem solid #0A2A66",
        boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
        height: "6.5rem",
        borderRadius: "1rem",
        zIndex: 2,
      }}
    >
      <BackgroundImageFromUrl
        objectId={type === 1
          ? "admin/frontend-images/road-map/background-big-exam-1.svg"
          : type === 2
            ? "admin/frontend-images/road-map/background-big-exam-2.svg"
            : "admin/frontend-images/road-map/background-big-exam-3.svg"}
        className={`${disabled ? "" : status === 1 ? "" : status === 2 ? "" : ""
          } is-flex is-justify-content-space-between is-align-items-center is-flex-shrink-0`}
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "35rem",
          height: "6.5rem",
          borderRadius: "1rem",
          filter: disabled ? "grayScale(100%)" : "",
          position: "absolute",
          top: "0",
          zIndex: 1,
        }}
      ></BackgroundImageFromUrl>
      <div
        className={`${disabled ? "" : status === 1 ? "" : status === 2 ? "" : ""
          } is-flex is-justify-content-space-between is-align-items-center is-flex-shrink-0 is-relative`}
        style={{
          padding: "1.25rem 1.5rem 1.25rem 0rem",
          width: "35rem",
          height: "6.5rem",
          zIndex: 5,
        }}
      >
        <div className="is-flex is-justify-content-center is-align-items-center is-flex-gap-5">
          <div
            className={` has-text-kurio-main-color-white is-flex is-justify-content-center is-align-items-center has-text-weight-bold`}
            style={{
              fontSize: "2.5rem",
              lineHeight: "normal",
              borderRadius: "0rem 1rem 1rem 0rem",
              background: disabled
                ? "linear-gradient(90deg, #ADADAD 0%, #827C7B 50%)"
                : type === 1
                  ? "linear-gradient(90deg, #9747FF 0%, #572CD1 50%)"
                  : type === 2
                    ? "linear-gradient(90deg, #48A9F8 0%, #2162C0 50%)"
                    : type === 3
                      ? "linear-gradient(90deg, #FF8B00 0%, #A9533F 50%)"
                      : "",
              width: "5rem",
              height: "4rem",
            }}
          >
            {index}
          </div>
          <p
            className={`${disabled
              ? "has-text-kurio-gray-dark"
              : type === 1
                ? "has-text-kurio-main-color-purple"
                : type === 2
                  ? "has-text-kurio-main-color-black"
                  : ""
              }  has-text-weight-bold`}
            style={{
              fontSize: "1.5rem",
              lineHeight: "normal",
              color: type === 3 ? "#A9533F" : "",
            }}
          >
            {title}
          </p>
        </div>
        {disabled && (
          <div
            className="is-flex is-align-items-center is-justify-content-center is-flex-gap-4 is-flex-shrink-0 has-background-kurio-main-color-white has-text-weight-bold has-text-kurio-gray-dark is-uppercase"
            style={{
              fontSize: "1.25rem",
              lineHeight: "normal",
              borderRadius: "1rem",
              border: "0.09375rem solid #54668E",
              width: "10rem",
              height: "4rem",
            }}
          >
            <img
              src="/images/exam-preparation/icon-khoa.svg"
              style={{
                height: "1.5rem",
              }}
              alt=""
            />
            <p>khóa</p>
          </div>
        )}
        {!disabled && status === 1 && (
          <div
            className="has-text-kurio-main-color-white has-text-weight-bold is-uppercase is-flex is-align-items-center is-justify-content-center is-clickable"
            style={{
              width: "10rem",
              maxWidth: "10rem",
              height: "4rem",
              borderRadius: "1rem",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundImage:
                "url('/images/exam-preparation/background-primary-button.svg')",
            }}
            onClick={handleClick}
          >
            {type === 3 ? "Kết thúc" : "Bắt đầu"}
          </div>
        )}
        {!disabled && status === 2 && (
          <div
            className={` is-flex is-flex-direction-column is-align-items-center`}
            // style={{gap:"-0.875rem"}}
            onClick={handleClick}
          >
            <div
              className={`${type === 1
                ? "has-text-kurio-main-color-purple"
                : type === 2
                  ? "has-text-kurio-blue-dark"
                  : ""
                } has-text-weight-bold is-flex is-align-items-center is-justify-content-center`}
              style={boxScoreStyle}
            >
              {currentScore} / {maxScore}
            </div>
            <div
              className="is-uppercase is-clickable has-text-kurio-main-color-white has-text-weight-bold is-flex is-align-items-center is-justify-content-center"
              style={buttonReviewStyle}
            >
              xem lại
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RoadMapExam;
