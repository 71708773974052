/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import DuplicateLoginErrorComponent from "../../components/DuplicateLoginErrorComponent";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import {
  getGradeUnitProgress,
  getListUnit,
  getUnitLessonProgress,
} from "../../config/ListLesson";
import ScrollRefWrapper from "../../detail-components/ScrollRefWrapper";
import {
  getListGradeUnits,
  getListLastLesson,
} from "../../reducers/gradesSlice";
import { getPracticeElectiveQuestion } from "../../reducers/practiceElectiveSlice";
import PracticeElectiveMainWrapper from "../practice-elective/PracticeElectiveMainWrapper";
import ListLessonLeftWrapper from "./left/ListLessonLeftWrapper";
import ListLessonRightWrapper from "./right/ListLessonRightWrapper";

const ListLessonMainWrapper = ({
  unitId,
  gradeName,
  gradeId,
  lastLesson,
  units,
  quiz,
  listGradeUnit,
}) => {
  const [listAnUnit, setListAnUnit] = useState(units);
  const [listGradeUnitProgress, setListGradeUnitProgress] = useState(null);
  const [unitLessonProgress, setUnitLessonProgress] = useState(null);
  const [practiceQuestionListId, setPracticeQuestionListId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();

  const cookies = new Cookies();
  var token = cookies.get("signinUser");
  unitId && cookies.set("unitId", unitId);
  const dispatch = useDispatch();

  // dispatch(getGradeUserInfo());
  const handleShowModal = (practiceId) => {
    setPracticeQuestionListId(practiceId);
    setShowModal(true);
    dispatch(
      getPracticeElectiveQuestion({
        id: practiceId,
        token,
        page: 1,
        size: 50,
      })
    );
  };
  const handleEndPractice = () => {
    setShowModal(false);
    setPracticeQuestionListId("");
  };

  window.addEventListener('popstate', function (event) {
    setTimeout(function () {
      navigate("/list-unit");

    }, 0);
  });


  useEffect(() => {
    const listUnit = async () => {
      try {
        if (unitId !== null && unitId) {
          const response = await getListUnit(unitId, token);
          setListAnUnit(response.result);
        } else {
          const response = await getListUnit(cookies.get("unitId"), token);
          setListAnUnit(response.result);
        }
      } catch (error) {
        if (error?.response?.status === 409) {
          setShowError(true); 
         }
      }
    };

    const gradeUnitProgress = async () => {
      try {
        if (gradeId !== null && gradeId) {
          const response = await getGradeUnitProgress(gradeId, token);
          setListGradeUnitProgress(response.result);
        } else {
          const response = await getGradeUnitProgress(
            cookies.get("gradeId"),
            token
          );

          setListGradeUnitProgress(response.result);
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    const UnitLessonProgress = async () => {
      try {
        if (unitId !== null && unitId) {
          const response = await getUnitLessonProgress(unitId, token);
          setUnitLessonProgress(response.result);
        } else {
          const response = await getUnitLessonProgress(
            cookies.get("unitId"),
            token
          );
          setUnitLessonProgress(response.result);
        }
      } catch (error) {        
        console.log(error.message);
      }
    };
    if (unitId === null || cookies.get("unitId")) {
      if (listGradeUnit === null) {
        dispatch(
          getListGradeUnits({
            grades: cookies.get("gradeId"),
            page: 1,
            size: 50,
          })
        );
      }
      if (lastLesson === null) {
        dispatch(getListLastLesson());
      }
      if (listAnUnit === null) {
        listUnit();
      }
      if (listGradeUnitProgress === null) {
        gradeUnitProgress();
      }
      if (unitLessonProgress === null) {
        UnitLessonProgress();
      }
    }
    if (listAnUnit !== null && listGradeUnit !== null) {
      const index = listGradeUnit.findIndex(
        (item) => item._id === listAnUnit._id
      );
      setListAnUnit({ ...listAnUnit, order: index + 1 });
    }
    if (!showModal) {
      localStorage.removeItem("exerciseState");
    }
  }, [unitId, listGradeUnit, showError]);
  if (showError) {
    console.log("show err")
    localStorage.setItem("clearAll", true);
    return <DuplicateLoginErrorComponent isShowPopup={true} />;
  }
  return (
    <BackgroundImageFromUrl
      objectId={"admin/frontend-images/hub/background-list-lesson.svg"}
      className="columns p-0 m-0 is-mobile"
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        // backgroundImage: "url('/images/hub/background-list-lesson.svg')",
        backgroundAttachment: "fixed",
        minHeight: "100vh",
      }}
    >
      <ScrollRefWrapper />
      {!cookies.get("unitId") && !unitId && (
        <Navigate to={`/list-unit`} replace={true} />
      )}
      {!showModal && (
        <>
          <div
            className=" is-flex is-align-items-center is-align-self-stretch"
            style={{ minHeight: "100%", position: "fixed" }}
          >
            <div
              className="column is-narrow has-background-kurio-main-color-white"
              style={{
                maxWidth: "26.5rem",
                width: "26.5rem",
                padding: "2.5rem",
                height: "37.5rem",
                position: "fixed",
                borderRadius: "0 1rem 1rem 0",
                border: "0.09375rem solid #0A2A66",
                boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
              }}
            >
              {listAnUnit !== null && (
                <ListLessonLeftWrapper
                  gradeName={gradeName}
                  listAnUnit={listAnUnit}
                  listGradeUnitProgress={listGradeUnitProgress}
                />
              )}
            </div>
          </div>

          <div
            className="column is-flex is-align-items-center is-justify-content-center "
            style={{
              padding: "5rem 0rem",
              marginLeft: "26.5rem",
            }}
          >
            <ListLessonRightWrapper
              unitLessonProgress={unitLessonProgress}
              unitId={cookies.get("unitId")}
              handleShowModal={(practiceId) => handleShowModal(practiceId)}
              listAnUnit={listAnUnit}
            />
          </div>
        </>
      )}
      {showModal && (
        <PracticeElectiveMainWrapper
          practiceQuestionListId={practiceQuestionListId}
          handleEndPractice={handleEndPractice}
        />
      )}
    </BackgroundImageFromUrl>
  );
};

function mapStateToProps(state) {
  return {
    gradeLessons: state.grades.grades,
    gradeId: state.grades.gradeId,
    listLessons: state.grades.lessons,
    listUnits: state.grades.units,
    unitId: state.grades.unitId,
    gradeName: state.grades.gradeName,
    lastLesson: state.grades.lastlesson,
    units: state.grades.units,
    listGradeUnit: state.grades.grades,
    quiz: state.practiceQuestion.quizInformation,
  };
}

export default connect(mapStateToProps)(ListLessonMainWrapper);
