import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getPracticeElectiveQuestionList } from "../config/practice";
import { getPracticeByElo } from "../config/practiceByElo";
import { getAPracticeTopicQuestion } from "../config/practiceTopic";

const initialState = {
  questions: null,
  seenQuestions: 0,
  correctAnswers: 0,
  wrongAnswers: 0,
  skippedQuestions: 0,
  status: "idle",
  error: null,
  practiceImages: null
};

export const fetchPracticeElective = createAsyncThunk(
  "practiceElective/fetchPracticeElective",
  async () => {
    // TODO: Change this to the actual API endpoint
    const { data } = await axios.get("mock-lesson-data/practice.json");
    return data;
  }
);

export const getPracticeElectiveQuestion = createAsyncThunk(
  "practiceElective/getPracticeElectiveQuestion",
  async ({ id, page, size }, thunkAPI) => {
    try {
      const { result } = await getPracticeElectiveQuestionList(id, page, size);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.errorMessage);
    }
  }
);

export const getPracticeSmartQuestion = createAsyncThunk(
  "practiceElective/getPracticeSmartQuestion",
  async ({ id, page, size }, thunkAPI) => {
    try {
      const { result } = await getPracticeByElo(id, page, size);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.errorMessage);
    }
  }
);

export const getPracticeTopicQuestion = createAsyncThunk(
  "practiceElective/getPracticeTopicQuestion",
  async ({ id, page, size }, thunkAPI) => {
    try {
      const { result } = await getAPracticeTopicQuestion(id, page, size);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.errorMessage);
    }
  }
);

export const practiceElectiveSlice = createSlice({
  name: "practiceElective",
  initialState,
  reducers: {
    clearPracticeSlice: (state) => {
      state.questions = null
    },

    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setQuestionVisible: (state, action) => {
      if (state.questions[action.payload - 1].isLast) {
        return;
      } else {
        state.questions[action.payload].visible = true;
      }
    },
    setQuestionState: (state, action) => {
      state.questions[action.payload.index].question.state =
        action.payload.state;
    },
    setSeenQuestions: (state, action) => {
      state.seenQuestions = action.payload;
    },
    setQuestionFinished: (state, action) => {
      state.questions[action.payload].isFinished = true;
    },
    setSkippedQuestions: (state, action) => {
      state.skippedQuestions = action.payload + state.skippedQuestions;
    },
    setCorrectQuestion: (state, action) => {
      state.questions[action.payload.questionIndex].isCorrect =
        action.payload.correct;
    },
    setCorrectAnswer: (state, action) => {
      state.correctAnswers = action.payload + state.correctAnswers;
    },
    setWrongAnswer: (state, action) => {
      state.wrongAnswers = action.payload + state.wrongAnswers;
    },
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    setPracticeImage: (state, action) => {
      state.practiceImages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPracticeElective.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPracticeElective.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questions = action.payload.result.records;
        state.seenQuestions = action.payload.result.seenQuestions;
        state.questions[state.questions.length - 1].isLast = true;
        state.questions.forEach((question, index) => {
          question.questionIndex = index;
        });
        state.questions
          .filter((question, index) => index < state.seenQuestions)
          .forEach((question) => {
            question.done = true;
            question.visible = true;
          });
      })
      .addCase(fetchPracticeElective.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getPracticeElectiveQuestion.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPracticeElectiveQuestion.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questions = action.payload.records;
        state.correctAnswers = action.payload.correctAnswers;
        state.wrongAnswers = action.payload.wrongAnswers;
        state.skippedQuestions = action.payload.skippedQuestions;
        state.seenQuestions =
          action.payload.seenQuestions === 0 ? 1 : action.payload.seenQuestions;
        state.questions[state.questions.length - 1].isLast = true;
        state.questions[0].visible = true;
        state.questions.forEach((question, index) => {
          question.questionIndex = index;
        });
        state.questions
          .filter((question, index) => index < state.seenQuestions)
          .forEach((question) => {
            question.done = true;
            question.visible = true;
          });
      })
      .addCase(getPracticeElectiveQuestion.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getPracticeTopicQuestion.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPracticeTopicQuestion.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questions = action.payload.records;
        state.correctAnswers = action.payload.correctAnswers;
        state.wrongAnswers = action.payload.wrongAnswers;
        state.skippedQuestions = action.payload.skippedQuestions;
        state.seenQuestions =
          action.payload.seenQuestions === 0 ? 1 : action.payload.seenQuestions;
        state.questions[state.questions.length - 1].isLast = true;
        state.questions[0].visible = true;
        state.questions.forEach((question, index) => {
          question.questionIndex = index;
        });
        state.questions
          .filter((question, index) => index < state.seenQuestions)
          .forEach((question) => {
            question.done = true;
            question.visible = true;
          });
      })
      .addCase(getPracticeTopicQuestion.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getPracticeSmartQuestion.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPracticeSmartQuestion.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questions = action.payload.records;
        state.correctAnswers = action.payload.correctAnswers;
        state.wrongAnswers = action.payload.wrongAnswers;
        state.skippedQuestions = action.payload.skippedQuestions;
        state.seenQuestions =
          action.payload.seenQuestions === 0 ? 1 : action.payload.seenQuestions;
        state.questions[state.questions.length - 1].isLast = true;
        state.questions[0].visible = true;
        state.questions.forEach((question, index) => {
          question.questionIndex = index;
        });
        state.questions
          .filter((question, index) => index < state.seenQuestions)
          .forEach((question) => {
            question.done = true;
            question.visible = true;
          });
      })
      .addCase(getPracticeSmartQuestion.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const {
  setStatus,
  setQuestionVisible,
  setQuestionState,
  setSeenQuestions,
  setCorrectAnswer,
  setSkippedQuestions,
  setWrongAnswer,
  setQuestionFinished,
  setCorrectQuestion,
  setQuestions,
  setPracticeImage,
  clearPracticeSlice
} = practiceElectiveSlice.actions;

export const selectQuestions = (state) => state.practiceElective.questions;
export const selectStatus = (state) => state.practiceElective.status;

export default practiceElectiveSlice.reducer;
