import React, { useEffect, useState } from "react";
import BackgroundImageFromUrl from "../../parseImage/BackgroundImageFromUrl";

const PrimaryButtonLevelSection = ({
  disabled,
  prefix,
  isClick,
  suffix,
  onClick,
  isDarkColor,
  isYellowColor,
  children,
  style,
}) => {
  var buttonStyle = {
    width: "10rem",
    height: "2.5rem",
    border: "none",
    borderRadius: "1.25rem",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    boxShadow: "none",
    // color: disabled ? "#8C9DC1" : "#FAFBFC",
  };
  var prefixStyle = {
    position: "absolute",
    width: "1rem",
    height: "1rem",
    zIndex: "100",
    top: "0.75rem",
    left: "1.375rem",
    // backgroundColor: disabled ? "#8C9DC1" : "#FAFBFC",
  };
  var suffixStyle = {
    position: "absolute",
    width: "1rem",
    height: "1rem",
    zIndex: "100",
    top: "0.75rem",
    right: "1.375rem",
    // backgroundColor: disabled ? "#8C9DC1" : "#FAFBFC",
  };
  const [defaultState, setDefaultState] = useState(disabled ? false : true);
  const [hoveredState, setHoveredState] = useState(false);
  const [clickedState, setClickedState] = useState(isClick ? isClick : false);
  const audio = new Audio("/sounds/click.mp3");
  // var objectId = "";
  if (defaultState) {
    if (isDarkColor) {
      buttonStyle = {
        ...buttonStyle,
        backgroundImage:
          "url('/images/button/primary-button-section-level-bg-dark.svg')",
      };
    } else if (isYellowColor) {
      buttonStyle = {
        ...buttonStyle,
        backgroundImage:
          "url('/images/button/primary-button-section-level-bg-yellow.svg')",
      };
    } else {
      buttonStyle = {
        ...buttonStyle,
        backgroundImage:
          "url('/images/button/primary-button-section-level-bg-default.svg')",
      };
    }
    // objectId =
    //   "admin/frontend-images/button/primary-button-section-level-bg-default.svg";
  }
  if (hoveredState) {
    if (isDarkColor) {
      buttonStyle = {
        ...buttonStyle,
        backgroundImage:
          "url('/images/button/primary-button-section-level-bg-dark.svg')",
      };
    } else if (isYellowColor) {
      buttonStyle = {
        ...buttonStyle,
        backgroundImage:
          "url('/images/button/primary-button-section-level-bg-yellow.svg')",
      };
    } else {
      buttonStyle = {
        ...buttonStyle,
        backgroundImage:
          "url('/images/button/primary-button-section-level-bg-hovered.svg')",
      };
    }
    // objectId =
    //   "admin/frontend-images/button/primary-button-section-level-bg-hovered.svg";
  }

  const handleMouseEnter = () => {
    if (!clickedState && !disabled) {
      setDefaultState(false);
      setHoveredState(true);
    }
  };
  const handleMouseLeave = () => {
    if (!clickedState && !disabled) {
      setDefaultState(true);
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    if (!disabled) {
      audio.play();
      if (onClick) {
        onClick();
      }
      setClickedState(!clickedState);
      setHoveredState(false);
      setDefaultState(clickedState);
    }
  };

  const handleBlur = () => {
    setClickedState(false);
    setDefaultState(true);
  };

  useEffect(() => {
    if (disabled === false || disabled === undefined) {
      setDefaultState(true);
    } else {
      setDefaultState(false);
    }
  }, [disabled]);

  return (
    <div
      className="main is-relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onBlur={handleBlur}
      style={{ maxWidth: "10rem", ...style }}
    >
      {prefix && (
        <BackgroundImageFromUrl
          objectId={prefix}
          style={prefixStyle}
        ></BackgroundImageFromUrl>
      )}
      <button
        className={`${
          defaultState
            ? (isDarkColor || isYellowColor)
              ? "is-kurio-purple-light-3 has-text-kurio-main-color-purple"
              : "is-primary-button-default-color has-text-kurio-main-color-white"
            : hoveredState
            ? (isDarkColor || isYellowColor)
              ? "is-kurio-purple-light-0 has-text-kurio-main-color-purple"
              : "is-primary-button-hovered-color has-text-kurio-main-color-white"
            : clickedState
            ? (isDarkColor || isYellowColor)
              ? "is-kurio-main-color-purple has-text-kurio-main-color-white"
              : "is-secondary-button-clicked-color has-text-kurio-main-color-white"
            : disabled
            ? "is-button-disabled-color"
            : ""
        }  button  main-color has-text-weight-bold is-uppercase has-text-centered ${
          disabled ? "has-text-kurio-gray-main-accent" : ""
        }`}
        style={{ ...buttonStyle, ...style }}
        onClick={handleClick}
        type="button"
      >
        {children}
      </button>
      {suffix && (
        <BackgroundImageFromUrl
          objectId={suffix}
          style={suffixStyle}
        ></BackgroundImageFromUrl>
      )}
    </div>
  );
};

export default PrimaryButtonLevelSection;
