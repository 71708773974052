/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import {
  checkRequestResetPassword,
  selectCheck,
  selectStatusAuth,
} from "../../reducers/authSlice";

const LoadingResetPassword = () => {
  const { requestId, otp } = useParams();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loadStatus = useSelector(selectStatusAuth);
  const check = useSelector(selectCheck);
  var backgroundImage = {
    backgroundImage: "url('/images/auth/bg-desktop-change-pw.svg')",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
  };

  useEffect(() => {
    if (loadStatus === "idle") {
      dispatch(
        checkRequestResetPassword({
          requestId: requestId,
          otp: otp,
        })
      );
    } else if (loadStatus === "succeeded") {
      setTimeout(() => {
        navigate("/reset-password-email");
      }, 1000);
    } else if (loadStatus === "failed") {
      setTimeout(() => {
        setError(check);
      }, 1000);
    }
    // checkRequest()
  }, [check, dispatch, loadStatus, navigate]);
  return (
    <div className="hero is-fullheight is-relative" style={backgroundImage}>
      <div
        style={{
          width: "21.5rem",
          height: "8.3125rem",
          backgroundImage: "url('/images/auth/logo-reset-pw.svg')",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "absolute",
          left: "0",
          bottom: "0",
        }}
      ></div>
      <div className="columns m-0" style={{ padding: "3.5rem" }}>
        <div className="column m-0 p-0"></div>

        <div
          className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between has-background-white m-0 has-text-kurio-main-color-black has-text-centered"
          style={{
            padding: "8.125rem 4rem 8.125rem 3.5rem",
            height: "40rem",
            width: "27.5rem",
            maxWidth: "27.5rem",
            boxShadow: "0.75rem 0.6875rem #0A2A66",
            border: "0.0625rem solid #0A2A66",
          }}
        >
          {error !== "" ? (
            <>
              <p className="has-text-danger">{error}</p>
              <PrimaryButtonPageLevel
                  onClick={()=> navigate("/")}
                  style={{
                    height: "4rem",
                    width: "20rem",
                    maxWidth: "20rem",
                  }}
                  type="submit"
                >
                  Về trang chủ
                </PrimaryButtonPageLevel>
            </>
          ) : (
            <>
              <div
                className="is-flex is-flex-direction-column is-align-items-center"
                style={{ gap: "2rem" }}
              >
                <div
                  style={{
                    height: "11.1875rem",
                    width: "12.5rem",
                    backgroundImage: "url('/images/button/cat-head.svg')",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                ></div>
                <p style={{ fontSize: "1.125rem", lineHeight: "1rem" }}>
                  Xin hãy đợi trong giây lát...
                </p>
                <div
                  style={{
                    height: "3rem",
                    width: "2.4375rem",
                    backgroundImage: "url('/images/auth/logo-loading.svg')",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
            </>
          )}
        </div>
        <div className="column m-0 p-0"></div>
      </div>
    </div>
  );
};

export default LoadingResetPassword;
