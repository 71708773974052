import React from "react";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ScrollRef from "../../detail-components/ScrollRef";
import jsonParser from "../helper/jsonParser";
import MainContent1Title from "./MainContent1Title";

const MainContent3Text = ({ data , isLast}) => {
    const content = data?.content;
    const text =
        content?.texts[0] !== null &&
        content?.texts[0] !== undefined &&
        content?.texts[0]?.length > 0
            ? jsonParser(content?.texts[0])
            : content?.texts[0];
    return (
        <div className="has-text-centered has-text-kurio-main-color-black">
            {!isLast && <ScrollRef />}
            {data?.title && <MainContent1Title title={data?.title} />}

            <BackgroundImageFromUrl
                objectId={content?.backgroundImage}
                className="content-text m-auto"
                style={{
                    maxWidth: "40rem",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "1rem",
                    fontSize: "1.25rem",
                }}
            >
                {text}
            </BackgroundImageFromUrl>
        </div>
    );
};

export default MainContent3Text;
