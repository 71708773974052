/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";
import BackgroundImageFromUrl from "../../../components/parseImage/BackgroundImageFromUrl";
import ContentWrapper from "./ContentWrapper";
import NextLessonWrapper from "./NextLessonWrapper";
import '../LessonMainWrapper.css';
import { connect } from "react-redux";

export const McqContext = React.createContext();
function LessonRightWrapper({
  data,
  section,
  slide,
  submitLesson,
  unitId,
  listUnit,
  order,
  nextSection,
  UnitLessonProgress,
  handleEndLesson,
  setScrollTop,
  scrollTop,
  className,
  backgroundRef,
  isLast,
  isPreview
}) {

  // read from data["Template"]["Content"] and data["Template"]["Question"]
  // to refer to the content and question template in templates/content and templates/question
  // var contentTemplate = data?.template.content;

  var questionTemplate = data?.template.question;
  var backgroundStyle = {
    backgroundSize: "cover",
    backgroundImage: `url(${data?.backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    maxWidth: "60rem",
  };
  if (questionTemplate && questionTemplate.length > 0) {
    backgroundStyle = {
      ...backgroundStyle,
      filter: "drop-shadow(0rem 0.375rem 1rem rgba(10, 42, 102, 0.25))",
      border: "1.5px solid #0a2a66",
      boxShadow: " 0.125rem 0.125rem 0rem 0rem #0a2a66",
      borderRadius: "1rem",
    };
  }

  return (
    <BackgroundImageFromUrl
      backgroundRef={backgroundRef}
      className={`${className} p-0 mb-8 is-relative`}
      objectId={data?.backgroundImage}
      style={backgroundStyle}
    >
      <ContentWrapper data={data} order={order} isLast={scrollTop ? scrollTop : isLast} />

      <div className="has-text-centered">
        <NextLessonWrapper
          section={section}
          unitId={unitId}
          slide={slide}
          data={data}
          order={order}
          isPreview={isPreview}
          setScrollTop={setScrollTop}
          handleEndLesson={handleEndLesson}
          template={data?.template.question}
          submitLesson={(section, slide) => submitLesson(section, slide)}
          nextSection={nextSection}
        />
      </div>
    </BackgroundImageFromUrl>
  );
}

function mapStateToProps(state) {
  return {
    sections: state.questions.sections,
  };
}

export default connect(mapStateToProps)(LessonRightWrapper);
