import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ScrollRef from "../../detail-components/ScrollRef";
import {
  fetchQuestions,
  resetLesson,
  setSectionFinished,
  setSectionVisible
} from "../../reducers/questionsSlice";
import { getAllImage } from "../../templates/helper/getAllImages";
import { LoadingImage } from "../../templates/helper/LoadingImage";
import LessonLeftWrapper from "./left/LessonLeftWrapper";
import LessonRightWrapper from "./right/LessonRightWrapper";

function MockLessonMainWrapper({ sections, lesson, currentLesson }) {
  const dispatch = useDispatch();
  const [selectedSection, setSelectedSection] = React.useState(0);
  const [isSectionFinished, setIsSectionFinished] = React.useState(false);
  const [isClick, setIsClick] = useState(false);
  const [isSlideFinished, setIsSLideFinished] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  let isLast = true;
  if (sections) {
    isLast = selectedSection === sections.length - 1;
  }
  const handleSetLoadingImage = (state) => {
    setLoadingImage(state);
  };
  useEffect(() => {
    if (sections) {
      setIsSectionFinished(sections[selectedSection].isFinished);
      if (!isClick && currentLesson && currentLesson.length > 0) {
        currentLesson.map((item, i) => {
          sections.map((section, index) => {
            if (section.visible && section._id === item.currentSectionId) {
              setSelectedSection(index);
            }
            return true;
          });
          return true;
        });
      }
    }
  }, [selectedSection, sections, currentLesson, isClick]);

  function handleSectionChange(val) {
    setSelectedSection(val - 1);
    setIsClick(true);
  }

  function nextSection() {
    if (isLast) {
      return;
    }
    setIsClick(true);
    setSelectedSection(selectedSection + 1);
    dispatch(setSectionVisible(selectedSection + 1));
    // submitLessonProgress(selectedSection + 1, 0);
  }

  function finishSection() {
    setIsSectionFinished(true);
    setIsSLideFinished(true);
    dispatch(setSectionFinished(selectedSection));
    if (
      !isLast &&
      !(
        sections[selectedSection].slides[
          sections[selectedSection].slides.length - 1
        ].template.question.length > 0
      )
    ) {
      dispatch(setSectionVisible(selectedSection + 1));
      setIsClick(true);
      setSelectedSection(selectedSection + 1);
      // submitLessonProgress(selectedSection + 1, 0);
    }
  }

  const handleGetMockData = () => {
    dispatch(fetchQuestions());
    setLoadingImage(true);
  };
  const submitLessonProgress = async (section, slide) => {};
  const handleReset = async () => {
    dispatch(resetLesson(sections));
    setSelectedSection(0);
    setIsSectionFinished(false);
  };
  const orderNumber = (slides, index) => {
    var order = 0;
    for (let i = 0; i <= index; i++) {
      if (
        slides[i].template.question !== null &&
        slides[i].template.question &&
        slides[i].template.question.length > 0
      ) {
        order += 1;
      }
    }
    if (order === 0) {
      order = 1;
    }
    return order;
  };
  if (loadingImage) {
    if (sections !== null && sections?.length > 0) {
      const result = getAllImage(sections,"LESSON");
      if (result && result?.length > 0) {
        return (
          <>
            <LoadingImage
              images={result}
              router={""}
              handleSetLoadingImage={(state) => handleSetLoadingImage(state)}
            />
          </>
        );
      }else {
        setLoadingImage(false);
      }
    }
  }

  return (
    <>
      {!loadingImage && (
        <div className="columns is-mobile m-0">
          {/* <div class="modal-background"></div> */}
          <div
            className="column is-one-fifth m-0 p-0 has-background-kurio-main-color-white"
            style={{ position: "fixed", minHeight: "100vh" }}
          >
            {/* <GetALesson /> */}
            <button className="is-clickable" onClick={handleReset}>
              Reset
            </button>
            <button className="is-clickable" onClick={handleGetMockData}>
              Get Mock Data
            </button>
            <LessonLeftWrapper
              selected={selectedSection + 1}
              handleSectionChange={handleSectionChange}
            />
          </div>
          <BackgroundImageFromUrl
            className="column is-offset-one-fifth has-background-kurio-main-color-light-gray"
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              padding: "5rem 5rem",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundAttachment: "fixed",
            }}
            objectId={sections && sections[selectedSection]?.backgroundImage}
          >
            {/* <div> */}
            {sections &&
              sections[selectedSection].slides.map((item, index) => {
                if (item.visible) {
                  var order = orderNumber(
                    sections[selectedSection].slides,
                    index
                  );
                  return (
                    <LessonRightWrapper
                      key={index}
                      data={item}
                      section={selectedSection}
                      slide={index}
                      isLast={
                        (index === 0
                          ? sections[selectedSection]?.slides.length === 1
                            ? true
                            : false
                          : true) &&
                        isSectionFinished &&
                        sections[selectedSection]?.isFinished &&
                        isSlideFinished
                      }
                      submitLesson={(section, slide) =>
                        submitLessonProgress(section, slide)
                      }
                      order={order}
                      nextSection={finishSection}
                    />
                  );
                } else {
                  return null;
                }
              })}
            {/* </div> */}
            {!isLast && isSectionFinished && (
              <>
                <ScrollRef />
                <PrimaryButtonPageLevel
                  onClick={nextSection}
                  style={{ height: "4rem", margin: "0 auto" }}
                >
                  Tiếp theo
                </PrimaryButtonPageLevel>
              </>
            )}
            {sections && isLast && isSectionFinished && (
              <>
                <ScrollRef />
                <PrimaryButtonPageLevel
                  style={{ height: "4rem", margin: "0 auto" }}
                >
                  Kết thúc bài học
                </PrimaryButtonPageLevel>
              </>
            )}
          </BackgroundImageFromUrl>
        </div>
      )}
      {loadingImage && <LoadingImage />}
    </>
  );
}

function mapStateToProps(state) {
  return {
    sections: state.questions.sections,
    lesson: state.questions.lesson,
    currentLesson: state.questions.currentLesson,
  };
}

export default connect(mapStateToProps)(MockLessonMainWrapper);
