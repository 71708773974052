import React, { useState } from "react";
import BackgroundImageFromUrl from "../../parseImage/BackgroundImageFromUrl";

const TertiaryButtonLevelPage = ({
  disabled,
  prefix,
  suffix,
  style,
  onClick,
  children,
}) => {
  const [defaultState, setDefaultState] = useState(disabled ? false : true);
  const [hoveredState, setHoveredState] = useState(false);
  const [clickedState, setClickedState] = useState(false);
  const audio = new Audio("/sounds/click.mp3");

  var buttonStyle = {
    width: "8.75rem",
    height: "1.375rem",
    fontSize: "1.125rem",
    border: "none",
    boxShadow: "none",
    backgroundColor: "transparent",
  };
  var prefixStyle = {
    minWidth: "1.125rem",
    height: "1.125rem",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: disabled
      ? "#8C9DC1"
      : clickedState
      ? "#0A2A66"
      : hoveredState
      ? "#572CD1"
      : "#0A2A66",
  };
  var suffixStyle = {
    minWidth: "1.125rem",
    height: "1.125rem",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: disabled
      ? "#8C9DC1"
      : clickedState
      ? "#0A2A66"
      : hoveredState
      ? "#572CD1"
      : "#0A2A66",
  };
  const handleMouseEnter = () => {
    if (!clickedState && !disabled) {
      setDefaultState(false);
      setHoveredState(true);
    }
  };
  const handleMouseLeave = () => {
    if (!clickedState && !disabled) {
      setDefaultState(true);
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    if (!disabled) {
      audio.play();
      setClickedState(!clickedState);
      setHoveredState(false);
      setDefaultState(clickedState);
      if (onClick) {
        onClick();
      }
    }
  };

  return (
    <div
      className="main is-flex is-flex-direction-row is-align-items-center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ maxWidth: "8.75rem" }}
    >
      <button
        className={`${
          defaultState
            ? "has-text-secondary-button-clicked-color"
            : hoveredState
            ? "has-text-primary-button-hovered-color"
            : clickedState
            ? "has-text-secondary-button-clicked-color"
            : disabled
            ? "has-text-button-disabled-text-color"
            : ""
        } is-underlined button main-color is-capitalized has-text-centered p-0 is-flex is-flex-direction-row is-align-items-center is-flex-gap-3`}
        style={{ ...buttonStyle, ...style }}
        onClick={handleClick}
      >
        {prefix && (
          <BackgroundImageFromUrl
            objectId={prefix}
            style={prefixStyle}
          ></BackgroundImageFromUrl>
        )}
        {children}
        {suffix && (
          <BackgroundImageFromUrl
            objectId={suffix}
            style={suffixStyle}
          ></BackgroundImageFromUrl>
        )}
      </button>
    </div>
  );
};

export default TertiaryButtonLevelPage;
