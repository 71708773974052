import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getLesson, getUnitLessonProgress } from '../config/lesson';

const initialState = {
    header: null,
    sections: null,
    lesson: null,
    currentLesson: null,
    error: null,
    status: 'idle',
};

export const fetchQuestions = createAsyncThunk(
    'questions/fetchQuestions',
    async () => {
        // TODO: Change this to the actual API endpoint
        const {data} = await axios.get('../mock-lesson-data/mock-data.json')
        return data
    }
);
export const getALesson = createAsyncThunk(
  'questions/getALesson',
  async ({lessonId,token}, thunkAPI) => {
    try {
      const  {result}  = await getLesson(lessonId, token)
      if(!result.header){
        result.header = {title: result.title, unit: result.unit, description: result.description, grade: result.grade}
      }
      return result
    } catch (error) {
      if (error?.response.status === 409) {
        return { error: 409 };
      } else {
        return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
      }
    }
  }
)
export const getLessonSuccess = createAsyncThunk(
  'questions/getLessonSuccess',
  async ({unitId}, thunkAPI) => {
    try {
      const {result} = await getUnitLessonProgress(unitId)
      return result
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage)
    }
  }
)

export const questionsSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {
        setHeader: (state, action) => {
            state.header = action.payload
        },
        clearStateQuestion:()=>initialState,
        setSections: (state, action) => {
            state.sections = action.payload
        },
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setSectionVisible: (state, action) => {
          if (state.sections && state.sections[action.payload]) {
            state.sections[action.payload].visible = true;
          }
        },
        setSectionFinished: (state, action) => {
          if (state.sections && state.sections[action.payload]) {
            state.sections[action.payload].isFinished = true
          }
        },
        setQuestionVisible: (state, action) => {
          if (state.sections && state.sections[action.payload.section]) {
            if (state.sections[action.payload.section].slides[action.payload.index - 1].isLast) {
                return
            } else {
                state.sections[action.payload.section].slides[action.payload.index].visible = true
            }
          }
        },
        setQuestionState: (state, action) => {
          if (state.sections && state.sections[action.payload.section]) {
            state.sections[action.payload.section].slides[action.payload.index].state = action.payload.state
          }
        },
        resetLesson : (state, action)=>{

          state.sections = action.payload
          
          state.sections.map((section, index) => {
            if (index === 0) {
              state.sections[index].visible = true
            } else {
              state.sections[index].visible = false
            }
            state.sections[index].isFinished = false
            section.slides.map((slide)=> delete slide.state)
            section.slides.map((slide, index)=> index === 0 ? slide.visible = true : slide.visible = false)
            section.slides[section.slides.length - 1].isLast = true
            return section
          })

        },
        setCurrentLessonProgress :(state, action)=>{
          state.currentLesson = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchQuestions.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(fetchQuestions.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.header = action.payload.header
                state.sections = action.payload.sections
                state.sections[0].visible = true
                state.sections.map((section) => {
                    section.slides[0].visible = true
                    section.slides[section.slides.length - 1].isLast = true
                    return section
                })
            })
            .addCase(fetchQuestions.rejected, (state) => {
                state.status = 'failed'
            })
            .addCase(getALesson.pending, (state) => {
              state.status = 'loading'
            })
            .addCase(getALesson.fulfilled, (state, action) => {
              if (action.payload?.error === 409) {
                state.error = 409;
              } else{
                state.status = 'succeeded'
                state.lesson = action.payload
                state.header = action.payload.header
                state.sections = action.payload.sections
                state.sections[0].visible = true
                state.sections.map((section) => {
                    section.slides[0].visible = true
                    section.slides[section.slides.length - 1].isLast = true
                    return section
                })
              }
            })
            .addCase(getALesson.rejected, (state, action) => {
                state.status = 'failed'
                state.lesson = action.payload
            })
            .addCase(getLessonSuccess.fulfilled, (state, action)=>{
              state.currentLesson = action.payload
            })
    }
});

export const { clearStateQuestion, setHeader, setSections, setStatus, setSectionVisible, setSectionFinished, setQuestionVisible, setQuestionState, resetLesson, setCurrentLessonProgress } = questionsSlice.actions;

export const selectHeader = (state) => state.questions.header;
export const selectSections = (state) => state.questions.sections;
export const selectStatus = (state) => state.questions.status;

export default questionsSlice.reducer;