// Still needs to review explanation part
// Revamp color and scaling

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import ConstantStrings from "../../constants/ConstantStrings";
import { checkImage } from "../../constants/checkImage";
import { loadWidthImage } from "../../constants/loadWidthImage";
import QuestionNumberTag from "../../detail-components/QuestionNumberTag";
import {
  selectAnsweredQuestions,
  setQuestionAnswered,
  setQuestionFinished,
  setQuestionState,
  setQuestionsAnswered,
  submitAnswers
} from "../../reducers/practiceQuestionSlice";
import "../../styling/layout-components/MultipleScroll.style.css";
import jsonParser from "../helper/jsonParser";
function VerticalMultipleChoice({
  sections,
  data,
  order,
  question,
  questions,
  questionId,
  handleQuestionChange,
  backgroundImage,
  questionIndex,
  practiceQuizId,
}) {
  var questionTexts =
    question.texts !== null &&
    question.texts !== undefined &&
    question.texts?.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;
  var questionChoices = question.choices;
  var questionSolutions = question.solutions;
  const cookies = new Cookies();
  const token = cookies.get("signinUser");
  const dispatch = useDispatch();
  const answeredQuestions = useSelector(selectAnsweredQuestions);
  const [selectedItem] = useState(() => {
    return questions
      .filter((question) => question.questionIndex === questionIndex)
      .map((item) => item.answer)
      .flat()
      .indexOf(true);
  });
  //create a state variable to store the selected answer
  var [selected, setSelected] = useState(() => {
    if (question.state) {
      return question.state.selected;
    } else {
      if (selectedItem !== -1) {
        return selectedItem;
      } else {
        return -1;
      }
    }
  });
  //create a state variable to store the explanation state
  const [imgWidth, setImgWidth] = useState(0);
  const [widthButton, setWidthButton] = useState(0);
  const [maxWidth, setMaxWidth] = useState(60);

  //get max length of a choice
  var maxLength = 0;
  for (var i = 0; i < questionChoices.length; i++) {
    if (questionChoices[i].length > maxLength) {
      maxLength = questionChoices[i].length;
    }
  }
  var backgroundStyle = {
    borderRadius: "1rem 0 0 1rem",
    width: "50%",
    height: `40rem`,
    opacity: 1,
  };
  var nextButtonStyle = {
    width: "100%",
    padding: "1rem 0",
    marginTop: "2.5rem",
  };
  if (backgroundImage !== null && backgroundImage) {
    backgroundStyle = {
      ...backgroundStyle,
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundStyle = {
      ...backgroundStyle,
      backgroundColor: "#E1D8FC",
    };
  }
  let buttonStyle = {};
  if (maxLength <= 10 && !checkImage(questionChoices[0])) {
    buttonStyle = {
      minWidth: "7rem",
      width: "auto",
      maxWidth: "10rem",
      padding: "1rem",
      height: "4rem",
      opacity: 1,
      fontWeight: "bold",
      fontSize: "1.125rem",
    };
  } else if (
    maxLength >= 11 &&
    maxLength <= 32 &&
    !checkImage(questionChoices[0])
  ) {
    buttonStyle = {
      minWidth: "15rem",
      maxWidth: "20rem",
      padding: "1rem",
      width: `auto`,
      height: "4rem",
      fontWeight: "bold",
      outline: "0.0625rem solid",
      opacity: 1,
      fontSize: "1.125rem",
    };
  } else {
    if (checkImage(questionChoices[0])) {
      buttonStyle = {
        maxWidth: "20rem",
        width: `${imgWidth !== 0 ? `${imgWidth + 0.125}rem` : "auto"}`,
        height: "auto",
        padding: "1rem",
        whiteSpace: "pre-wrap",
        outline: "0.0625rem solid",
        opacity: 1,
        borderRadius: "1.5rem",
        fontWeight: "bold",
        fontSize: "1.125rem",
      };
    } else {
      buttonStyle = {
        maxWidth: "20rem",
        minWidth: "15rem",
        width: "auto",
        height: "auto",
        padding: "1rem 2.5rem",
        whiteSpace: "pre-wrap",
        outline: "0.0625rem solid",
        opacity: 1,
        fontWeight: "bold",
        fontSize: "1.125rem",
        color: "#0A2A66",
      };
    }
  }

  var selectedStyle = {
    ...buttonStyle,
    backgroundColor: "#E6E1F5",
    outline: "2px solid #572CD1",
  };

  var iconStyle = {
    zIndex: 100,
    position: "relative",
    top: "1.25rem",
    width: "1.5rem",
    height: "1.5rem",
    left: "0.8rem",
  };
  //McqButton component
  var maxHeight = 0;
  var maxWidthButton = 0;
  const divRef = useRef(null);

  const McqButton = ({ id, index, children }) => {
    const buttonRef = useRef(null);
    useEffect(() => {
      if (checkImage(questionChoices[0])) {
        loadWidthImage(questionChoices[0], (err, img) => {
          setImgWidth(
            (img.width / ConstantStrings.BASE_REM) *
              ConstantStrings.MULTIPLIERIMAGE
          );
        });
      }

      if (buttonRef.current.clientHeight > maxHeight) {
        maxHeight = buttonRef.current.clientHeight;
      }
      if (buttonRef.current.clientWidth > maxWidthButton) {
        maxWidthButton = buttonRef.current.clientWidth;
      }
      if (widthButton < maxWidthButton / ConstantStrings.BASE_REM) {
        setWidthButton(maxWidthButton / ConstantStrings.BASE_REM);
      }
      //set all auto-height buttons to the same height
      if (id === "auto-height") {
        buttonRef.current.style.width = `${
          maxWidthButton / ConstantStrings.BASE_REM
        }rem`;
      }
    }, [id]);
    return (
      //if index = selected, set the style to selectedStyle
      //if index = selected and checkingState = true, set the style to correctStyle if the answer is correct, set the style to incorrectStyle if the answer is incorrect
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          {index === selected ? (
            questionSolutions[selected] === "True" ? (
              <img src="/images/common/greenV.svg" style={iconStyle} alt="loading" />
            ) : (
              <img src="/images/common/redX.svg" style={iconStyle} alt="loading" />
            )
          ) : (
            <></>
          )}
          <button
            id={id}
            ref={buttonRef}
            className={`${
              index === selected
                ? "has-background-kurio-purple-light-3 is-kurio-purple-main-accent has-text-kurio-purple-main-accent"
                : ""
            } button is-shadowless has-text-weight-bold is-outlined has-background-kurio-main-color-white`}
            style={index === selected ? selectedStyle : buttonStyle}
            onClick={() => handleSelect(index)}
          >
            {checkImage(children) ? (
              <>
                <img alt={children} src={children} />
              </>
            ) : (
              jsonParser(children)
            )}
          </button>
        </div>
      </div>
    );
  };

  //mapping each choice to a component
  const MapChoices = questionChoices.map((choice, index) => {
    return (
      //id = "auto-height" if the choice is longer than 32 characters
      <div className="column is-narrow is-centered p-2" key={index}>
        <McqButton
          id={choice.length > 10 && !checkImage(choice) ? "auto-height" : ""}
          index={index}
        >
          {choice}
        </McqButton>
      </div>
    );
  });

  //create a function to handle the selection of an answer
  //when an answer is selected, set the selected state variable to the index of the answer
  const handleSelect = (index) => {
    if (selected === index) {
      setSelected(-1);
    } else {
      setSelected(index);
    }
  };

  const showNextQuestion = () => {
    var answer = questionChoices.map((item) => false);
    answer[selected] = true;

    dispatch(
      setQuestionState({
        index: questionIndex,
        state: {
          selected: Number(selected),
        },
      })
    );

    if (selected !== -1) {
      answer[selected] = true;
    } else {
      answer = null;
    }

    var newAnswer = {
      questionId: questionId,
      answer: answer,
      questionIndex: questionIndex,
      selected: selected,
    };
    // setSelected(selected);
    var result = [...answeredQuestions];
    if (result.length === 0) {
      result.push(newAnswer);
    } else {
      var flag = true;
      for (let question = 0; question < answeredQuestions.length; question++) {
        if (answeredQuestions[question].questionId === newAnswer.questionId) {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      if (!flag) {
        result.push(newAnswer);
      } else {
        result = answeredQuestions.map((question) =>
          question.questionId === questionId ? newAnswer : question
        );
      }
    }

    dispatch(setQuestionsAnswered(result));

    const submit = { submit: false, questions: [newAnswer] };
    dispatch(submitAnswers({ submit, token, practiceQuizId }));

    if (answer !== null || selectedItem !== -1) {
      dispatch(
        setQuestionAnswered({
          questionIndex: questionIndex,
          status: true,
        })
      );
    } else {
      dispatch(
        setQuestionAnswered({
          questionIndex: questionIndex,
          status: false,
        })
      );
    }
    if (questionIndex !== questions.length - 1) {
      handleQuestionChange(questionIndex + 1);
    } else {
      return;
    }
  };

  const showPreviousQuestion = () => {
    dispatch(
      setQuestionState({
        index: questionIndex,
        state: {
          selected: Number(selected),
        },
      })
    );

    var answer = questionChoices.map((item) => false);
    answer[selected] = true;

    if (selected !== -1) {
      answer[selected] = true;
    } else {
      answer = null;
    }
    var newAnswer = {
      questionId: questionId,
      answer: answer,
      questionIndex: questionIndex,
      selected: selected,
    };
    // setSelected(selected);
    var result = [...answeredQuestions];
    if (result.length === 0) {
      result.push(newAnswer);
    } else {
      var flag = true;
      for (let question = 0; question < answeredQuestions.length; question++) {
        if (answeredQuestions[question].questionId === newAnswer.questionId) {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      if (!flag) {
        result.push(newAnswer);
      } else {
        result = answeredQuestions.map((question) =>
          question.questionId === questionId ? newAnswer : question
        );
      }
    }
    dispatch(setQuestionsAnswered(result));
    const submit = { submit: false, questions: [newAnswer] };
    dispatch(submitAnswers({ submit, token, practiceQuizId }));

    dispatch(setQuestionFinished(questionIndex));
    if (answer !== null || selectedItem !== -1) {
      dispatch(
        setQuestionAnswered({
          questionIndex: questionIndex,
          status: true,
        })
      );
    } else {
      dispatch(
        setQuestionAnswered({
          questionIndex: questionIndex,
          status: false,
        })
      );
    }
    handleQuestionChange(questionIndex - 1);
  };

  const McqImageWrap = () => {
    return (
      <>
        {questionChoices.length === 4 &&
        (imgWidth + 0.125 + 1.5) * 4 + 8.5 > maxWidth ? (
          <div>
            <div className="columns is-centered p-0 m-0">
              {MapChoices[0]}
              {MapChoices[1]}
            </div>
            <div className="columns is-centered p-0 m-0">
              {MapChoices[2]}
              {MapChoices[3]}
            </div>
          </div>
        ) : questionChoices.length === 5 ? (
          <>
            <div>
              <div className="columns is-centered p-0 m-0">
                {MapChoices[0]}
                {MapChoices[1]}
              </div>
              <div className="columns is-centered p-0 m-0">
                {MapChoices[2]}

                {MapChoices[3]}
              </div>
              <div className="columns is-centered p-0 m-0">{MapChoices[4]}</div>
            </div>
          </>
        ) : questionChoices.length === 6 ? (
          <>
            {(imgWidth + 0.125 + 1.5) * 3 + 4 > maxWidth && (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                  {MapChoices[2]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[3]}
                  {MapChoices[4]}
                  {MapChoices[5]}
                </div>
              </div>
            )}
          </>
        ) : questionChoices.length === 3 ? (
          <>
            {(imgWidth + 0.125 + 1.5) * 3 + 4 > maxWidth && (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[2]}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="columns is-multiline is-centered">{MapChoices}</div>
        )}
      </>
    );
  };
  useEffect(() => {
    if (divRef.current.clientWidth / ConstantStrings.BASE_REM !== maxWidth) {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    };

    window.addEventListener("resize", setWidth);

    return () => window.removeEventListener("resize", setWidth);
  }, [maxWidth]);

  //map each choice to a component
  return (
    <div style={{ height: `40rem` }} className="is-flex is-flex-direction-row">
      <div style={backgroundStyle} ref={divRef}></div>

      <div style={{ width: "50%", padding: "2rem", position: "relative" }}>
        <div className="question-container">
          <div>
            {data?.template.question !== null &&
              data?.template.question &&
              data?.template.question.length > 0 && (
                <div className="mb-4">
                  <QuestionNumberTag
                    number={order === 0 ? data?.order : order}
                  />
                </div>
              )}
          </div>
          {/* {jsonParser("$$\\begin{aligned} 12 \\times (20 - 15) &= 12 \\times 5 \\\\ &=  \\color{red}{60}\\end{aligned}$$  \\n @@<p style='color:red'>test ok</p>@@ ")} */}
          {
            //render questionTexts if its array has length greater than 0
            questionTexts !== null &&
              questionTexts !== undefined &&
              questionTexts?.length > 0 && (
                <div
                  className="content"
                  style={{
                    color: "#0A2A66",
                    lineHeight: "2rem",
                  }}
                >
                  {questionTexts}
                </div>
              )
          }
          {checkImage(questionChoices[0]) ? (
            <>
              <McqImageWrap />
            </>
          ) : (
            <div className="columns is-multiline p-2">
              {
                <>
                  {questionChoices.length === 4 &&
                  (imgWidth + 0.125 + 1.5) * 4 + 8.5 > maxWidth ? (
                    <div>
                      <div className="columns p-0 m-0">
                        {MapChoices[0]}
                        {MapChoices[1]}
                      </div>
                      <div className="columns p-0 m-0">
                        {MapChoices[2]}
                        {MapChoices[3]}
                      </div>
                    </div>
                  ) : questionChoices.length === 5 ? (
                    <>
                      <div>
                        <div className="columns p-0 m-0">
                          {MapChoices[0]}
                          {MapChoices[1]}
                        </div>
                        <div className="columns p-0 m-0">
                          {MapChoices[2]}
                          {MapChoices[3]}
                        </div>
                        <div className="columns p-0 m-0">{MapChoices[4]}</div>
                      </div>
                    </>
                  ) : questionChoices.length === 6 ? (
                    <>
                      {(imgWidth + 0.125 + 1.5) * 3 + 4 > maxWidth && (
                        <div>
                          <div className="columns p-0 m-0">
                            {MapChoices[0]}
                            {MapChoices[1]}
                            {MapChoices[2]}
                          </div>
                          <div className="columns p-0 m-0">
                            {MapChoices[3]}
                            {MapChoices[4]}
                            {MapChoices[5]}
                          </div>
                        </div>
                      )}
                    </>
                  ) : questionChoices.length === 3 ? (
                    <>
                      {(imgWidth + 0.125 + 1.5) * 3 + 4 > maxWidth && (
                        <div>
                          <div className="columns p-0 m-0">
                            {MapChoices[0]}
                            {MapChoices[1]}
                          </div>
                          <div className="columns p-0 m-0">{MapChoices[2]}</div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="columns is-multiline">{MapChoices}</div>
                  )}
                </>
              }
            </div>
          )}
        </div>

        {!question.isLast && (
          <div
            style={nextButtonStyle}
            className="is-flex is-flex-direction-row is-justify-content-space-between"
          >
            <button
              className="button"
              onClick={showPreviousQuestion}
              // style={nextButtonStyle}
              disabled={questionIndex === 0}
            >
              Previous
            </button>

            {questionIndex !== questions.length - 1 && (
              <button
                className="button"
                onClick={showNextQuestion}
                style={{ position: "absolute", right: "0" }}
              >
                Next
              </button>
            ) }
          </div>
        )}
      </div>
    </div>
  );
}

export default VerticalMultipleChoice;
