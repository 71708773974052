import React, { memo, useEffect, useState } from "react";
import { getListComplexity } from "../../config/complexity";
import "./SelectDifficult.style.css";

const SelectDifficult = memo(({ handleSelect, gradeId }) => {
  const [checkAll, setCheckAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [listComplexity, setListComplexity] = useState([]);
  // const cookies = new Cookies();
  // const token = cookies.get("signinUser");
  const handleCheckAll = () => {
    setCheckAll(!checkAll);
    setSelected(listComplexity.map((item) => item._id));
    handleSelect(listComplexity.map((item) => item._id));
    if (checkAll) {
      setSelected([]);
      handleSelect([]);
    }
  };
  const handleCheck = (_id) => {
    if (selected.includes(_id)) {
      setSelected(selected.filter((item) => item !== _id));
      setCheckAll(false);
      handleSelect(selected.filter((item) => item !== _id));
    } else {
      if ([...selected, _id].length === 3) {
        setCheckAll(true);
        setSelected(listComplexity.map((item) => item._id));
        handleSelect(listComplexity.map((item) => item._id));
      } else {
        setCheckAll(false);
        setSelected([...selected, _id]);
        handleSelect([...selected, _id]);
      }
    }
  };
  const handleChange = (name, check, value) => {
    if (name && name === "checkAll") {
      if (check) {
        handleSelect(value);
      } else {
        handleSelect([]);
      }
    } else if (check) {
      handleSelect([...selected, value]);
    } else {
      handleSelect(selected.filter((i) => i !== value));
    }
  };
  useEffect(() => {
    const getComplexity = async () => {
      const { result } = await getListComplexity();
      setListComplexity(result);
    };
    if (listComplexity.length <= 0) {
      getComplexity();
    }
  }, [listComplexity.length]);

  return (
    <div>
      <div
        className="is-flex is-flex-direction-row is-flex-wrap-wrap is-align-items-start"
        style={{
          gap: "1rem",
          borderRadius: "0.125rem",
        }}
      >
        {listComplexity &&
          listComplexity.map((item, index) => {
            return (
              <button
                className="is-flex is-flex-direction-row is-justify-content-space-between  btn-button"
                key={index}
                onClick={() => {
                  handleCheck(item._id);
                }}
                style={
                  checkAll || selected.includes(item._id)
                    ? {
                      color: "#FAFBFC",
                      backgroundColor: "#0A2A66",
                    }
                    : { boxShadow: "0.125rem 0.125rem 0rem 0rem #0A2A66" }
                }
              >
                <p
                  className="has-text-weight-bold"
                  style={
                    checkAll || selected.includes(item._id)
                      ? {
                        color: "#fff",
                        fontSize: "1.125rem",
                      }
                      : {
                        color: "#0A2A66",
                        fontSize: "1.125rem",
                      }
                  }
                >
                  {item.name === "Easy"
                    ? "Dễ"
                    : item.name === "Medium"
                      ? "Trung Bình"
                      : item.name === "Hard"
                        ? "Khó"
                        : ""}
                </p>
                <input
                  onClick={() => {
                    handleCheck(item._id);
                  }}
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.checked, item._id);
                  }}
                  checked={
                    checkAll
                      ? checkAll
                      : selected.includes(item._id)
                        ? true
                        : false
                  }
                  type={"checkbox"}
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    backgroundColor: "#FAFBFC",
                    cursor: "pointer",
                  }}
                />
              </button>
            );
          })}
        <div
          className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center btn-button"
          onClick={handleCheckAll}
          style={
            checkAll
              ? {
                color: "#FAFBFC",
                backgroundColor: "#0A2A66",
              }
              : { boxShadow: "0.125rem 0.125rem 0rem 0rem #0A2A66" }
          }
        >
          <p
            className="has-text-weight-bold"
            style={
              checkAll
                ? {
                  color: "#fff",
                  fontSize: "1.125rem",
                }
                : {
                  color: "#0A2A66",
                  fontSize: "1.125rem",
                }
            }
          >
            Tất cả
          </p>
          <input
            name="checkAll"
            onChange={(e) => {
              handleChange(
                e.target.name,
                e.target.checked,
                listComplexity.map((item) => item._id)
              );
            }}
            onClick={handleCheckAll}
            checked={checkAll}
            type={"checkbox"}
            style={{
              width: "1.5rem",
              height: "1.5rem",
              backgroundColor: "#FAFBFC",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    </div>
  );
});

export default SelectDifficult;
