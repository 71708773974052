import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import dailyMissionReducer from "./dailyMissionSlice";
import examsQuestionReducer from "./examsQuestionSlice";
import gradesReducer from "./gradesSlice";
import practiceElectiveReducer from "./practiceElectiveSlice";
import practiceQuestionReducer from "./practiceQuestionSlice";
import questionsReducer from "./questionsSlice";
import roadMapReducer from "./roadmapSlice"

export const store = configureStore({
  reducer: {
    questions: questionsReducer,
    grades: gradesReducer,
    practiceElective: practiceElectiveReducer,
    auth: authReducer,
    practiceQuestion: practiceQuestionReducer,
    examsQuestion: examsQuestionReducer,
    dailyMission: dailyMissionReducer,
    roadMap: roadMapReducer,
  },
});
