/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { getGradeUnitProgress } from "../../config/ListLesson";
import ScrollRefWrapper from "../../detail-components/ScrollRefWrapper";
import { clearExamQuestion } from "../../reducers/examsQuestionSlice";
import { clearUnits } from "../../reducers/gradesSlice";
import ListUnitContent from "./components/Content/ListUnitContent/ListUnitContent";

const ListUnitMainWrapper = ({ grades, listGrades, units }) => {
  const [gradeUnitProgress, setGradeUnitProgress] = useState([]);
  const dispatch = useDispatch()
  const cookies = new Cookies()

  const GradeUnitProgress = async () => {
    try {
      if (grades && grades !== null) {
        const { result } = await getGradeUnitProgress(grades);
        setGradeUnitProgress(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(clearExamQuestion())
    if (grades) {
      GradeUnitProgress();
    }
    if (units !== null) {
      dispatch(clearUnits())
    }
    if (cookies.get("unitId")) {
      cookies.remove("unitId")
    }
  }, [grades]);

  return (
    <>
      <ScrollRefWrapper />
      <div className="is-relative is-flex is-align-items-center is-justify-content-center" style={{ padding: "1rem 4.5rem 3rem 4.5rem" }}>
        <div>
          <ListUnitContent
            listGradeUnits={listGrades}
            gradeUnitProgress={gradeUnitProgress}
          />
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    grades: state.grades.gradeId,
    listGrades: state.grades.grades,
    units: state.grades.units,
  };
}

export default connect(mapStateToProps)(ListUnitMainWrapper);
