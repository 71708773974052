/* eslint-disable default-case */
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import ButtonQuizExam from "../../../components/button/button-quiz-exam/ButtonQuizExam";
import PaginationBigButton from "../../../components/button/pagination-button/PaginationBigButton";
import PrimaryButtonPageLevel from "../../../components/button/primary-button/PrimaryButtonLevelPage";
import SecondaryButtonLevelPage from "../../../components/button/secondary-button/SecondaryButtonLevelPage";
import PopupCloseButton from "../../../components/button/special-button/PopupCloseButton";
import BackgroundImageFromUrl from "../../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../../components/parseImage/ImageFromUrl";
import { submitExamAnswers } from "../../../config/examsQuestion";
import {
  clearExamQuestion,
  clearState,
  getExamQuestion,
  getExamQuestionSolutions,
  getListExamsInformation,
  getListMonthlyExamsQuestion,
  selectAnsweredQuestions,
  setQuestionAnswered,
  setQuestionState,
  setQuestionsAnswered,
  submitExamAnswer
} from "../../../reducers/examsQuestionSlice";
import "./MonthlyExamQuestionsLeftWrapper.css";

const MonthlyExamQuestionsLeftWrapper = ({
  examQuestions,
  maxScore,
  currentScore,
  selectedIndex,
  handleQuestionChange,
  deadline,
  takeExamsQuestionId,
  setIsPreview,
  handleChangePagination,
  isPreview,
  idExamAdmin,
  pagination,
  takeExamIdLPInformation,
  serverTime,
}) => {
  const [showPopupCancel, setShowPopupCancel] = useState(false);
  const [showPopupSubmit, setShowPopupSubmit] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [showFinishedQuiz, setShowFinishedQuiz] = useState(false);
  // const [showFinishedYearExam, setShowFinishedYearExam] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isCountdownPlaying, setCountdownPlaying] = useState(true);
  // const [remainingTime, setRemainingTime] = useState(null);
  const [start, setStart] = useState(false);
  const [changeTime, setChangeTime] = useState(false);
  // const [timeDifference, setTimeDifference] = useState(null);
  const [countdownDate, setCountDownDate] = useState(null);
  const [count, setCount] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const page = 1;
  const size = 60;
  // const location = useLocation();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const token = cookies.get("signinUser");
  // const tokenAdmin = cookies.get("signinAdmin");
  const timeStop = useRef(null);
  // var timeDifference = 0;
  // if (new Date(serverTime).getTime() > 0 && timeDifference === null) {
  //   // timeDifference = new Date(serverTime).getTime() - new Date().getTime();
  //   setTimeDifference(new Date(serverTime).getTime() - new Date().getTime());

  //   // localStorage.setItem("timeDifference",JSON.stringify(timeDifference))
  // }

  var checkStateAnswered =
    examQuestions && examQuestions.filter((item) => item.isAnswered === false);

  var answeredQuestions = useSelector(selectAnsweredQuestions);

  const checkingState = examQuestions?.every(
    (item) => item.question.showSolution === true
  );

  const clickShowPopupSubmit = async () => {
    setToggle(!toggle);
    if (isPreview !== undefined) {
      if (toggle === false) {
        setIsPreview(true);
      } else {
        setIsPreview(false);
      }
    } else {
      const result = JSON.parse(localStorage.getItem("questionStateExams"));

      if (result) {
        dispatch(setQuestionState(result.questionState));
        dispatch(setQuestionAnswered(result.questionAnswered));
        dispatch(setQuestionsAnswered(result.questionsAnswered));
      }
      // if (
      //   JSON.parse(localStorage.getItem("questionStateExams")) ===
      //     undefined ||
      //   (examQuestions
      //     .map((item) => item.isAnswered === true)
      //     .flat()
      //     .indexOf(true) !== -1 &&
      //     JSON.parse(localStorage.getItem("questionStateExams"))
      //       .questionsAnswered.map(
      //         (item) => item.answer !== null && item.answer?.length > 0
      //       )
      //       .flat()
      //       .includes(false) === true)
      // ) {
      //   setShowPopupSubmit(true);
      // } else {
      //   handleSubmit();
      // }
      setShowPopupSubmit(true);
    }

    if (checkingState || localStorage.getItem("examYearIdMonthly")) {
      if (location.state !== null) {
        if (location.state.prevRouter === "/monthly-exams") {
          navigate("/monthly-exams");
        } else {
          navigate("/");
        }
      } else {
        if (localStorage.getItem("examYearIdMonthly")) {
          navigate("/monthly-exams");
        }
      }

      if (localStorage.getItem("examYearIdMonthly")) {
        navigate("/monthly-exams");
      }

      localStorage.removeItem("takeExamIdLPInformation");
      localStorage.removeItem("questionStateExams");
      localStorage.removeItem("examYearIdMonthly");
      localStorage.removeItem("takeExamIdInformation");
      localStorage.removeItem("examsQuestionId");
      localStorage.removeItem("takeExamIdLPMonthly");
      localStorage.removeItem("nameExam");
      localStorage.removeItem("examYearIdMonthly");
      localStorage.removeItem("solutionInfo");
      localStorage.removeItem("listExamInfoMonthly");
      localStorage.removeItem("takeExamIdInfo");

      dispatch(clearState());

      // if (localStorage.getItem("examMonthlyNow")) {
      //   navigate("/monthly-exams");
      // } else {
      //   navigate("/exams-vault");
      // }
    }
  };

  const clickShowPopupCancel = () => {
    if (!checkingState) {
      setShowPopupCancel(true);
    } else if (checkingState || localStorage.getItem("examYearIdMonthly")) {
      if (location.state !== null) {
        if (location.state.prevRouter === "/monthly-exams") {
          navigate("/monthly-exams");
        } else {
          navigate("/");
        }
      } else {
        if (
          localStorage.getItem("examYearIdMonthly") ||
          localStorage.getItem("takeExamIdLPMonthly")
        ) {
          navigate("/monthly-exams");
        }
      }

      if (
        localStorage.getItem("examYearIdMonthly") ||
        localStorage.getItem("takeExamIdLPMonthly")
      ) {
        navigate("/monthly-exams");
      }

      localStorage.removeItem("takeExamIdLPInformation");
      localStorage.removeItem("takeExamIdInformation");
      localStorage.removeItem("examYearIdMonthly");
      localStorage.removeItem("takeExamIdLPMonthly");
      localStorage.removeItem("examsQuestionId");
      localStorage.removeItem("nameExam");
      dispatch(clearState([]));
    }
  };

  const handleNavigate = () => {
    if (location.state.prevRouter === "/monthly-exams") {
      dispatch(getListMonthlyExamsQuestion({ page: 1, size: 60 }));

      if (
        JSON.parse(localStorage.getItem("listExamInfoMonthly")) &&
        JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]?.id &&
        JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]?.takeExamId
      ) {
        dispatch(
          getListExamsInformation({
            examYearId: JSON.parse(
              localStorage.getItem("listExamInfoMonthly")
            )[0]?.id,
          })
        );

        dispatch(
          getExamQuestion({
            takeExamsQuestionId: localStorage.getItem("takeExamIdInfo")
              ? localStorage.getItem("takeExamIdInfo")
              : JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
                ?.takeExamId,
            token,
          })
        );
      }

      // dispatch(clearState([]));
      navigate("/monthly-exams");
    } else {
      navigate("/");
    }
  };

  const handleCancel = () => {
    const result = JSON.parse(localStorage.getItem("questionStateExams"));

    if (result) {
      dispatch(setQuestionState(result.questionState));
      dispatch(setQuestionAnswered(result.questionAnswered));
      dispatch(setQuestionsAnswered(result.questionsAnswered));
      var submit = result.submit;
      dispatch(
        submitExamAnswer({
          submit,
          examAnswerId: localStorage.getItem("takeExamIdInfo")
            ? localStorage.getItem("takeExamIdInfo")
            : JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
              ?.takeExamId,
        })
      );
    }

    localStorage.removeItem("questionStateExams");
    // localStorage.removeItem("targets");
    dispatch(clearState());
    dispatch(clearExamQuestion());

    if (location.state !== null) {
      if (location.state.prevRouter === "/monthly-exams") {
        dispatch(getListMonthlyExamsQuestion({ page: 1, size: 60 }));

        if (
          JSON.parse(localStorage.getItem("listExamInfoMonthly")) &&
          JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]?.id &&
          JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]?.takeExamId
        ) {
          dispatch(
            getListExamsInformation({
              examYearId: JSON.parse(
                localStorage.getItem("listExamInfoMonthly")
              )[0]?.id,
            })
          );

          dispatch(
            getExamQuestion({
              takeExamsQuestionId: localStorage.getItem("takeExamIdInfo")
                ? localStorage.getItem("takeExamIdInfo")
                : JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
                  ?.takeExamId,
              token,
            })
          );
        }

        navigate("/monthly-exams");
      } else {
        navigate("/");
      }
    } else {
      if (localStorage.getItem("examYearIdMonthly")) {
        dispatch(getListMonthlyExamsQuestion({ page: 1, size: 60 }));

        if (
          JSON.parse(localStorage.getItem("listExamInfoMonthly")) &&
          JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]?.id &&
          JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]?.takeExamId
        ) {
          dispatch(
            getListExamsInformation({
              examYearId: JSON.parse(
                localStorage.getItem("listExamInfoMonthly")
              )[0]?.id,
            })
          );

          dispatch(
            getExamQuestion({
              takeExamsQuestionId: localStorage.getItem("takeExamIdInfo")
                ? localStorage.getItem("takeExamIdInfo")
                : JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
                  ?.takeExamId,
              token,
            })
          );
        }

        // dispatch(clearState([]));
        navigate("/monthly-exams");
      }
    }

    localStorage.removeItem("takeExamIdLPInformation");
    localStorage.removeItem("examYearIdMonthly");
    localStorage.removeItem("takeExamIdInformation");
    localStorage.removeItem("examsQuestionId");
    localStorage.removeItem("takeExamIdLPMonthly");
    localStorage.removeItem("nameExam");
    // localStorage.removeItem("targets");
    localStorage.removeItem("createExamQuestionId");
    localStorage.removeItem("takeExamMonthlyQuestionId");
    localStorage.removeItem("takeExamYearQuestionId");
    localStorage.removeItem("listExamInfo");
  };

  const removePopup = () => {
    if (showPopupCancel) {
      setShowPopupCancel(false);
    }
    if (showPopupSubmit) {
      setShowPopupSubmit(false);
    }
  };

  const handleChange = (val) => {
    setShowPopupSubmit(false);
    handleQuestionChange(val);
  };

  const handleCountdownComplete = () => {
    setCountdownPlaying(false);
  };

  const handleSubmit = async () => {
    timeStop.current.pause();

    if (!checkingState) {
      const result = JSON.parse(localStorage.getItem("questionStateExams"));

      if (result) {
        dispatch(setQuestionState(result.questionState));
        dispatch(setQuestionAnswered(result.questionAnswered));
        dispatch(setQuestionsAnswered(result.questionsAnswered));
        answeredQuestions = result.questionsAnswered;
      } else {
        answeredQuestions = examQuestions.map((question) => ({
          questionId: question.questionId,
          questionIndex: question.questionIndex,
          answer: question.answer,
        }));
      }

      var payload = {
        submit: true,
        questions: answeredQuestions,
      };

      try {
        if (localStorage.getItem("examMonthlyNow")) {
          takeExamsQuestionId = localStorage.getItem("takeExamIdInfo")
            ? localStorage.getItem("takeExamIdInfo")
            : JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
              ?.takeExamId;
        }

        await submitExamAnswers(payload, takeExamsQuestionId);

        if (!localStorage.getItem("examMonthlyNow")) {
          dispatch(
            getExamQuestionSolutions({
              takeExamsQuestionId: localStorage.getItem(
                "takeExamYearQuestionId"
              )
                ? localStorage.getItem("takeExamYearQuestionId")
                : localStorage.getItem("takeExamIdInformation"),
              page,
              size,
            })
          );
        }

        if (currentScore !== 0) {
          localStorage.setItem("currentScore", currentScore);
        }

        if (
          localStorage.getItem("examMonthlyNow") &&
          !localStorage.getItem("yearExamNow")
        ) {
          setShowFinishedQuiz(true);
          setIsRunning(true);
          localStorage.removeItem("questionStateExams");
          localStorage.removeItem("targets");
        }
      } catch (error) {
        console.log("err", error);
      }
    } else {
      if (currentScore !== 0) {
        localStorage.setItem("currentScore", currentScore);
      }

      if (location.state.prevRouter === "/monthly-exams") {
        dispatch(clearState([]));
        navigate("/monthly-exams");
      } else {
        navigate("/");
      }
    }
  };

  // const handleCheckCurrentScore = () => {
  //   const listExamInfo = JSON.parse(localStorage.getItem("listExamInfo"));
  //   const listNewExamSolutions = JSON.parse(
  //     localStorage.getItem("listNewExamSolutions")
  //   );

  //   let currentScore = 0;
  //   listExamInfo?.map((item, index) => {
  //     if (
  //       item.id === localStorage.getItem("examsQuestionId") &&
  //       item.takeExamLP !== null
  //     ) {
  //       currentScore = listNewExamSolutions[index].currentScore;
  //     }

  //     return true;
  //   });

  //   return currentScore;
  // };

  // const handleCheckMaxScore = () => {
  //   const listExamInfo = JSON.parse(localStorage.getItem("listExamInfo"));
  //   const listNewExamSolutions = JSON.parse(
  //     localStorage.getItem("listNewExamSolutions")
  //   );

  //   let maxScore = 0;
  //   listExamInfo?.map((item, index) => {
  //     if (
  //       item.id === localStorage.getItem("examsQuestionId") &&
  //       item.takeExamLP !== null
  //     ) {
  //       maxScore = listNewExamSolutions[index].maxScore;
  //     }

  //     return true;
  //   });

  //   return maxScore;
  // };

  // TODO
  const renderScreen = () => {
    switch (currentIndex) {
      case 0:
        return (
          <>
            <div className="img__quiz">
              <ImageFromUrl
                objectId="admin/frontend-images/quiz/image-quiz.svg"
                alt=""
              />
            </div>
            <div>
              <p className="title__loading">Đang chấm điểm... </p>
              <span className="loader__quiz"></span>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="is-flex is-flex-direction-row is-align-items-center">
              <div
                className="has-background-kurio-main-color-white is-flex is-flex-direction-column is-flex-gap-5 is-justify-content-center is-align-items-center"
                style={{
                  width: "21rem",
                  border: "1.5px solid #0A2A66",
                  boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
                  borderRadius: "10rem 10rem 2.5rem 2.5rem",
                  padding: "2.5rem",
                  height: "32.125rem",
                }}
              >
                <div className="is-flex is-flex-direction-column" style={{ gap: "2.5rem" }}>
                  <img
                    style={{ objectFit: "cover" }}
                    src="/images/exam/icon-meo-monthly.svg"
                    alt=""
                  />
                  <div className="is-flex is-flex-direction-column is-justify-content-center is-flex-gap-4 is-align-items-center">
                    <div
                      className="has-text-kurio-main-color-black has-text-weight-bold has-text-centered"
                      style={{ fontSize: "2.5rem", lineHeight: "normal" }}
                    >
                      <p>
                        {
                          JSON.parse(localStorage.getItem("examMonthlyNow"))
                            ?.name
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="has-background-kurio-purple-light-1 is-flex is-align-items-center is-justify-content-center is-relative"
                  style={{
                    // position: "absolute",
                    // width: "19.5rem",
                    height: "5rem",
                    // background: "#E6E1F5",
                    // left: "0.4rem",
                    // top: "35.75rem",
                    padding: "0.75rem 1rem",
                    borderRadius: "1rem",
                    width: "16rem",
                    border: "1.5px solid #0A2A66",
                    boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
                  }}
                >
                  <p
                    className="has-text-centered has-text-weight-bold has-text-kurio-main-color-white has-background-kurio-main-color-black is-flex is-align-items-center"
                    style={{
                      fontSize: "0.875rem",
                      lineHeight: "normal",
                      width: "6rem",
                      height: "3rem",
                      borderRadius: "0.5rem",
                    }}
                  >
                    Thời gian làm bài
                  </p>
                  <div
                    className=" has-text-kurio-main-color-purple is-uppercase has-text-weight-bold has-text-centered has-background-kurio-purple-light-3 is-flex is-align-items-center is-justify-content-center"
                    style={{
                      fontSize: "2rem",
                      lineHeight: "normal",
                      borderRadius: "0.5rem",
                      height: "3rem",
                      width: "8.5rem",
                    }}
                  >
                    <div className="is-flex is-align-items-center is-flex-direction-row is-justify-content-center">
                      <>
                        <p
                          style={{
                            marginRight: "0.25rem",
                            fontWeight: 700,
                            fontSize: "1.5rem",
                            lineHeight: "normal",
                            textTransform: "uppercase",
                          }}
                        >
                          {Math.abs(
                            JSON.parse(localStorage.getItem("examMonthlyNow"))
                              ?.examTime -
                            parseInt(localStorage.getItem("minutes"))
                          )}
                        </p>
                        <div>
                          <p
                            style={{
                              marginRight: "0.25rem",
                              fontSize: "1.5rem",
                            }}
                          >
                            {" "}
                            :{" "}
                          </p>
                        </div>

                        <p
                          style={{
                            textTransform: "uppercase",
                            display: "flex",
                            fontWeight: 700,
                            fontSize: "1.5rem",
                            lineHeight: "normal",
                          }}
                        >
                          {60 - parseInt(localStorage.getItem("seconds")) < 10
                            ? `${"0" +
                            (60 - parseInt(localStorage.getItem("seconds")))
                            }`
                            : 60 - parseInt(localStorage.getItem("seconds"))}
                        </p>
                      </>
                    </div>
                  </div>
                </div>
              </div>
              {/* <p className="title-notice">
              Kết quả {localStorage.getItem("nameExam")}
            </p> */}

              {/* <div className="exam-score-card">
              <div className="group-exam">
                <p>Số điểm của bạn</p>
              </div>
              <div className="exam-score">
                <div className="mark-group">
                  <div className="score">
                    <p>{currentScore}</p>
                    <span>/{maxScore}</span>
                  </div>
                </div>
              </div>
            </div> */}

              <div className="is-flex is-flex-direction-column is-align-items-flex-end">
                {/* <img src="/images/exam/rocket.svg" alt="" /> */}
                <div className="is-flex is-align-items-center">
                  <div className="is-flex is-flex-direction-column is-flex-gap-1">
                    <div
                      className="has-background-kurio-main-color-black"
                      style={{
                        width: "1rem",
                        height: "2.0625rem",
                      }}
                    ></div>
                    <div
                      className="has-background-kurio-main-color-black"
                      style={{
                        width: "1rem",
                        height: "2.0625rem",
                      }}
                    ></div>
                    <div
                      className="has-background-kurio-main-color-black"
                      style={{
                        width: "1rem",
                        height: "2.0625rem",
                      }}
                    ></div>
                  </div>
                  <div
                    className="is-flex is-align-items-center is-flex-direction-column has-background-kurio-main-color-white"
                    style={{
                      gap: "2rem",
                      padding: "2.5rem",
                      borderRadius: "2.5rem",
                      border: "1.5px solid #0A2A66",
                      boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
                      width: "31.3125rem",
                      height: "32.625rem",
                    }}
                  >
                    <div
                      style={{
                        width: "22.6875rem",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        className=" is-relative has-background-kurio-main-color-white is-relative"
                        style={{
                          border: "1.5px solid #0A2A66",
                          boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
                          padding: "0.5rem 1.5rem",
                          borderRadius: "1rem",
                        }}
                      >
                        <div
                          className=" is-flex is-align-items-center is-justify-content-center has-text-kurio-main-color-black has-text-centered has-text-weight-bold"
                          style={{ fontSize: "2.5rem", lineHeight: "normal" }}
                        >
                          <p>
                            {/* {((currentScore / maxScore) * 100).toFixed(0)}{" "} */}
                            100
                          </p>
                          <img
                            style={{
                              objectFit: "cover",
                              width: "2.0031rem",
                              height: "2rem",
                            }}
                            src="/images/hub/star.png"
                            alt=""
                          />
                        </div>
                        <div
                          className=""
                          style={{
                            position: "absolute",
                            bottom: "-0.75rem",
                            right: "-13.3125rem",
                            width: "14.3125rem",
                            height: "5.625rem",
                          }}
                        >
                          <img
                            src="/images/exam/image-success-monthly-exam.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="is-flex is-flex-direction-column is-align-items-flex-start"
                      style={{
                        maxWidth: "38.625rem",
                        gap: "2.5rem",
                        textAlign: "center",
                      }}
                    >
                      <div
                        className="is-flex is-flex-direction-column has-text-kurio-main-color-black"
                        style={{ gap: "1.5rem" }}
                      >
                        <p
                          style={{
                            fontSize: "1.25rem",
                            fontWeight: 700,
                            color: "#0a2a66",
                          }}
                        >
                          Chúc mừng! <br />{" "}
                          <b className="title-notice-monthly">
                            Bạn đã Hoàn thành <br />
                            {
                              JSON.parse(localStorage.getItem("examMonthlyNow"))
                                ?.name
                            }
                            !
                          </b>
                        </p>

                        <div
                          className="document-monthly-exam"
                          style={{ fontSize: "1.25rem" }}
                        >
                          <p>
                            Hãy trở lại vào lúc{" "}
                            <b style={{ color: "#0A2A66", fontWeight: 700 }}>
                              {localStorage.getItem("examMonthlyNow") &&
                                moment(
                                  JSON.parse(
                                    localStorage.getItem("examMonthlyNow")
                                  ).endDate
                                )
                                  .format("DD/MM/YYYY HH:mm")
                                  .split(" ")[1]}{" "}
                              ngày
                            </b>{" "}
                            <b style={{ color: "#0A2A66", fontWeight: 700 }}>
                              {localStorage.getItem("examMonthlyNow") &&
                                moment(
                                  JSON.parse(
                                    localStorage.getItem("examMonthlyNow")
                                  ).endDate
                                )
                                  .format("DD/MM/YYYY HH:mm")
                                  .split(" ")[0]}
                            </b>{" "}
                            để xem điểm của bạn và lời giải cho{" "}
                            <b style={{ color: "#0A2A66", fontWeight: 700 }}>
                              {
                                JSON.parse(
                                  localStorage.getItem("examMonthlyNow")
                                )?.name
                              }{" "}
                            </b>
                            nhé!
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ width: "100%" }}
                      className="is-flex is-justify-content-center"
                    >
                      <PrimaryButtonPageLevel
                        className="back-quiz"
                        style={{
                          borderRadius: "1rem",
                          height: "4rem",
                        }}
                        onClick={handleNavigate}
                      >
                        Thoát
                      </PrimaryButtonPageLevel>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div
              className="finished-notice is-flex is-flex-direction-row is-align-items-center"
              style={{ gap: "5rem" }}
            >
              <ImageFromUrl
                style={{ width: "100%", height: "100%" }}
                objectId="admin/frontend-images/quiz/exam-score.svg"
                alt=""
              />

              <div
                className="is-flex is-flex-direction-column is-align-items-flex-start"
                style={{ maxWidth: "38.625rem", gap: "2.5rem" }}
              >
                <p className="title-notice-monthly">
                  Xin chúc mừng! <br /> Bạn đã hoàn thành{" "}
                  {JSON.parse(localStorage.getItem("examMonthlyNow"))?.name}
                </p>

                <div
                  className="document-monthly-exam"
                  style={{ fontSize: "1.25rem" }}
                >
                  <p>
                    Hãy trở lại vào lúc{" "}
                    <b>
                      {localStorage.getItem("examMonthlyNow") &&
                        moment(
                          JSON.parse(localStorage.getItem("examMonthlyNow"))
                            .endDate
                        )
                          .format("DD-MM-YYYY HH:mm:ss")
                          .split(" ")[1]}
                    </b>{" "}
                    ngày{" "}
                    <b>
                      {localStorage.getItem("examMonthlyNow") &&
                        moment(
                          JSON.parse(localStorage.getItem("examMonthlyNow"))
                            .endDate
                        )
                          .format("DD-MM-YYYY HH:mm:ss")
                          .split(" ")[0]}
                    </b>{" "}
                    <br /> để xem điểm của bạn và lời giải cho{" "}
                    <b>
                      {JSON.parse(localStorage.getItem("examMonthlyNow"))?.name}{" "}
                    </b>
                    nhé!
                  </p>
                </div>
              </div>
            </div>
            <BackgroundImageFromUrl
              objectId="admin/frontend-images/quiz/image-right-exam.svg"
              style={{
                position: "absolute",
                bottom: "0",
                right: "0",
                width: "29.625rem",
                height: "10.125rem",
              }}
            ></BackgroundImageFromUrl>
            <TertiaryButtonLevelPage
              suffix={"avx"}
              className="back-quiz"
              style={{ position: "absolute", bottom: "3rem", right: "3rem" }}
              onClick={handleNavigate}
            >
              Về trang chủ
            </TertiaryButtonLevelPage> */}
          </>
        );
    }
  };

  useEffect(() => {
    // TODO
    if (isRunning) {
      const timer = setTimeout(() => {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 3000);

      return () => clearTimeout(timer);
    }

    if (deadline !== null && !start) {
      const count = Math.floor(
        new Date(deadline).getTime() - new Date(serverTime).getTime()
      );

      setStart(true);
      setTimeLeft(count);
    }

    const countdown = setInterval(() => {
      setChangeTime(true);
      setCount((count) => count + 1);
      // setTimeLeft((timeLeft)=> timeLeft - 60000)
      setCountDownDate(new Date(serverTime).getTime() + timeLeft);
    }, 60000);
    return () => clearInterval(countdown);
  }, [
    deadline,
    isRunning,
    currentIndex,
    serverTime,
    isCountdownPlaying,
    start,
    countdownDate,
    timeLeft,
    count,
    changeTime,
  ]);

  const rerender = ({ hours, minutes, seconds, completed }) => {
    // console.log("test", (minutes + hours * 60 + seconds )*1000 - timeLeft)
    localStorage.setItem("minutes", zeroPad(minutes + hours * 60));
    localStorage.setItem("seconds", zeroPad(seconds));

    if (minutes + hours * 60 === 0 && seconds === 2) {
      handleSubmit();
    } else {
      return (
        <>
          <p
            style={{
              marginRight: "0.25rem",
              fontWeight: 700,
              fontSize: "1.5rem",
              lineHeight: "normal",
              textTransform: "uppercase",
            }}
          >
            {zeroPad(minutes + hours * 60)}
          </p>
          <div>
            <p style={{ marginRight: "0.25rem", fontSize: "1.5rem" }}> : </p>
          </div>

          <p
            style={{
              textTransform: "uppercase",
              display: "flex",
              fontWeight: 700,
              fontSize: "1.5rem",
              lineHeight: "normal",
            }}
          >
            {zeroPad(seconds)}
          </p>
        </>
      );
    }
  };

  return (
    <>
      {showFinishedQuiz && (
        <BackgroundImageFromUrl
          objectId="admin/frontend-images/exam/background-monthly-exam-update"
          className="modal is-active modal-background background-monthly-exam"
          style={{
            opacity: 1,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <div className="modal__monthly-exam">{renderScreen()}</div>
        </BackgroundImageFromUrl>
      )}

      {showPopupCancel && !checkingState && (
        <div
          onClick={removePopup}
          className="modal is-active modal-background"
          style={{ opacity: 1 }}
        >
          <div className="modal-quiz is-relative">
            <h2 style={{ color: "#0A2A66" }} className="title-popup-quiz">
              ! Chờ đã !
            </h2>
            <p className="document-quiz">
              Bạn có muốn nộp bài trước khi thoát không?
            </p>

            <div
              className="is-flex is-flex-direction-column"
              style={{ gap: "1rem", margin: "0 auto" }}
            >
              <PrimaryButtonPageLevel
                onClick={removePopup}
                style={{ height: "4rem" }}
              >
                Tiếp tục
              </PrimaryButtonPageLevel>
              <SecondaryButtonLevelPage
                disabled={isPreview !== undefined}
                onClick={handleCancel}
              >
                Thoát
              </SecondaryButtonLevelPage>

              <SecondaryButtonLevelPage
                disabled={isPreview !== undefined}
                onClick={clickShowPopupSubmit}
              >
                Nộp Bài Ngay
              </SecondaryButtonLevelPage>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {showPopupSubmit && !checkingState && (
        <div className="modal is-active " style={{ opacity: 1, zIndex: 2 }}>
          {/* <div
          className="modal-background"
          onClick={removePopup}
          style={{ opacity: 0.8 }}
        ></div> */}
          <div
            className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center has-background-kurio-main-color-white is-relative has-text-kurio-main-color-black"
            style={{
              padding: "8.4375rem 15.75rem",
              // borderRadius: "2rem",
              width: "100%",
              height: "100%",
              // width: "56.375rem",
              // height: "30.25rem",
              gap: "2rem",
            }}
          >
            <div className="is-flex is-flex-direction-column is-flex-gap-2 is-align-items-center">
              <p
                className="has-text-weight-bold"
                style={{
                  fontSize: "2.25rem",
                  fontStyle: "normal",
                  lineHeight: "normal",
                }}
              >
                ! Nộp bài !
              </p>
              <div className="is-flex is-flex-direction-column is-justify-content-center is-flex-gap-4 is-align-items-center">
                <div
                  className="has-text-kurio-main-color-black has-text-weight-bold has-text-centered"
                  style={{ fontSize: "1.5rem", lineHeight: "normal" }}
                >
                  <p className="">
                    {JSON.parse(localStorage.getItem("examMonthlyNow"))?.name}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="is-flex is-flex-direction-row is-align-items-center"
              style={{ gap: "5rem" }}
            >
              <div
                className="is-flex is-flex-direction-column is-flex-gap-5"
                style={{ width: "25.25rem" }}
              >
                <div className="is-flex is-align-items-center is-flex-gap-5">
                  <div
                    className=" is-flex is-align-items-center is-flex-gap-2"
                    style={{ fontSize: "0.875rem", lineHeight: "normal" }}
                  >
                    <p
                      className=""
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        background: "#E5E3EB",
                      }}
                    ></p>
                    <p>Chưa làm</p>
                  </div>
                  <div
                    className=" is-flex is-align-items-center is-flex-gap-2"
                    style={{ fontSize: "0.875rem", lineHeight: "normal" }}
                  >
                    <p
                      className="has-background-kurio-main-color-yellow"
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                      }}
                    ></p>
                    <p>Đã làm</p>
                  </div>
                </div>

                <div
                  className="columns m-0 is-multiline"
                  style={{ gap: "0.75rem" }}
                >
                  {examQuestions &&
                    examQuestions.map((question, index) => {
                      return (
                        <div className={`column is-narrow m-0 p-0`} key={index}>
                          <PaginationBigButton
                            onClick={() => handleChange(index)}
                            IKMCMode={true}
                            key={index}
                            isClick={false}
                            id={question.questionId}
                            value={question.questionIndex}
                            isAnswered={question.isAnswered}
                            isCorrect={question.isCorrect}
                          >
                            {question.questionIndex + 1}
                          </PaginationBigButton>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div
                className="is-flex is-flex-direction-column is-flex-gap-6 is-align-items-flex-start"
                style={{}}
              >
                <div
                  className="has-text-weight-bold is-flex is-flex-direction-column is-flex-gap-2"
                  style={{
                    fontSize: "1.125rem",
                    lineHeight: "normal",
                    fontStyle: "normal",
                  }}
                >
                  <p className="has-text-weight-medium">
                    Tổng số câu hỏi: {examQuestions?.length} câu
                  </p>
                  <p>
                    Số câu đã làm:{" "}
                    {examQuestions?.length - checkStateAnswered?.length} /{" "}
                    {examQuestions?.length} câu.
                  </p>
                  <p className="has-text-kurio-red-main-accent">
                    Số câu chưa làm: {checkStateAnswered?.length} /{" "}
                    {examQuestions?.length} câu.
                  </p>
                  {/* <p>Bạn muốn tiếp tục làm bài?</p> */}
                </div>
                <div
                  className="is-flex is-flex-direction-column"
                  style={{ gap: "1rem" }}
                >
                  <PrimaryButtonPageLevel
                    onClick={handleSubmit}
                    style={{
                      height: "4rem",
                      borderRadius: "1rem",
                      maxWidth: "20rem",
                      width: "20rem",
                    }}
                  >
                    Xác Nhận Nộp bài
                  </PrimaryButtonPageLevel>
                  <SecondaryButtonLevelPage
                    style={{
                      borderRadius: "1rem",
                      maxWidth: "20rem",
                      width: "20rem",
                    }}
                    onClick={removePopup}
                  >
                    Chưa nộp
                  </SecondaryButtonLevelPage>
                </div>
              </div>
            </div>

            {/* <div
            onClick={removePopup}
            className=""
            style={{
              position: "absolute",
              top: "-8%",
              left: "-4%",
              zIndex: 9999,
              background: "transparent",
              border: "none",
              width: "5rem",
              fontSize: "2rem",
            }}
          >
            <PopupCloseButton />
          </div> */}
          </div>
        </div>
      )}

      <div
        className="has-background-kurio-main-color-white is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between is-relative"
        style={{
          padding: "2.5rem 1.5rem 1.5rem 1.5rem",
          // height: "28.5rem",
          borderRadius: " 2.5rem 2.5rem 1.5rem 1.5rem",
          border: "1.5px solid #0A2A66",
          boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
          width: "16rem",
          gap: "2.5rem",
        }}
      >
        <div
          onClick={clickShowPopupCancel}
          className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center is-align-content-center"
          style={{
            padding: "0.5rem 0.75rem",
            background: "#E9EEF8",
            borderRadius: "1rem",
            gap: "0.75rem",
            height: "2.5rem",
            cursor: "pointer",
            border: "1.5px solid #0A2A66",
            boxShadow: "0.125rem 0.125rem 0 0 #0A2A66",
            position: "absolute",
            zIndex: 1,
            top: "-1.25rem",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M7.41425 6.00001L11.7072 1.70701C12.0982 1.31601 12.0982 0.684006 11.7072 0.293006C11.3162 -0.0979941 10.6842 -0.0979941 10.2933 0.293006L6.00025 4.58601L1.70725 0.293006C1.31625 -0.0979941 0.68425 -0.0979941 0.29325 0.293006C-0.09775 0.684006 -0.09775 1.31601 0.29325 1.70701L4.58625 6.00001L0.29325 10.293C-0.09775 10.684 -0.09775 11.316 0.29325 11.707C0.48825 11.902 0.74425 12 1.00025 12C1.25625 12 1.51225 11.902 1.70725 11.707L6.00025 7.41401L10.2933 11.707C10.4882 11.902 10.7443 12 11.0002 12C11.2562 12 11.5122 11.902 11.7072 11.707C12.0982 11.316 12.0982 10.684 11.7072 10.293L7.41425 6.00001Z"
              fill="#0A2A66"
            />
          </svg>
          <span
            style={{
              fontSize: "0.875rem",
              color: "#0a2a66",
              lineHeight: "normal",
              fontWeight: 700,
            }}
          >
            Thoát
          </span>
        </div>

        <div
          className="is-flex is-flex-direction-column is-align-items-center"
          style={{ gap: "1rem", width: "11.5rem" }}
        >
          <div
            className="is-flex is-flex-direction-column"
            style={{ gap: "1rem" }}
          >
            <p
              style={{
                fontWeight: 700,
                fontSize: "1.25rem",
                lineHeight: "normal",
                textAlign: "center",
                color: "#0A2A66",
              }}
            >
              {checkingState ||
                localStorage.getItem("examYearIdMonthly") ||
                localStorage.getItem("takeExamIdLPMonthly")
                ? `${JSON.parse(localStorage.getItem("solutionInfo"))?.name +
                " " +
                "- Lời giải"
                }`
                : JSON.parse(localStorage.getItem("examMonthlyNow"))?.name}
            </p>
          </div>

          <div
            className="columns m-0 is-multiline is-align-items-flex-start"
            style={{
              gap: "0.5rem",
              width: "11.5rem",
              // overflow: "auto",
              // padding:"2.5rem 1.5rem 1.5rem 1.5rem",
              // // height: "calc(100% - 20rem)",
              // height:"27rem"
            }}
          >
            {examQuestions &&
              examQuestions.map((question, index) => {
                return (
                  <div
                    className={`${pagination === 1
                      ? index <= 19
                        ? ""
                        : "is-hidden"
                      : index <= 19
                        ? "is-hidden"
                        : ""
                      } column is-narrow m-0 p-0`}
                    key={index}
                  >
                    <PaginationBigButton
                      onClick={() => handleChange(index)}
                      IKMCMode={true}
                      key={index}
                      isClick={index === selectedIndex ? true : false}
                      id={question.questionId}
                      value={question.questionIndex}
                      isAnswered={question.isAnswered}
                      isCorrect={localStorage.getItem("takeExamIdLPInformation") || localStorage.getItem("takeExamIdLPMonthly") ? question.isCorrect : undefined}
                    >
                      {question.questionIndex + 1}
                    </PaginationBigButton>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="is-flex is-flex-direction-column is-align-items-center is-flex-gap-4">
          {examQuestions?.length > 20 && (
            <div
              className="is-flex is-flex-direction-row is-justify-content-center has-text-kurio-main-color-black is-uppercase has-text-weight-bold is-align-items-center is-align-content-center"
              style={{
                padding: "0.5rem 0.75rem",
                background: "#E9EEF8",
                borderRadius: "1rem",
                gap: "0.75rem",
                height: "2.5rem",
                cursor: "pointer",
                border: "1.5px solid #0A2A66",
                fontSize: "0.75rem",
                boxShadow: "0.125rem 0.125rem 0 0 #0A2A66",
              }}
              onClick={() => {
                handleChangePagination(pagination === 1 ? 2 : 1);
                localStorage.setItem("pagination", pagination === 1 ? 2 : 1);
              }}
            >
              {pagination === 1 && (
                <>
                  <p>Trang sau</p>
                  <img src="/images/button/icon-arrow.svg" alt="icon" />
                </>
              )}
              {pagination === 2 && (
                <>
                  <img
                    style={{ transform: "scaleX(-1)" }}
                    src="/images/button/icon-arrow.svg"
                    alt="icon"
                  />
                  <p>Trang trước</p>
                </>
              )}
            </div>
          )}
          {localStorage.getItem("examMonthlyNow") ||
            localStorage.getItem("takeExamIdLPInformation") ? <>
            {localStorage.getItem("takeExamIdLPInformation") || localStorage.getItem("takeExamIdLPMonthly") ? <>
              <div className="is-flex is-flex-direction-row is-flex-gap-2">
                <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                  <span style={{ width: "0.75rem", height: "0.75rem", background: "#E5E3EB" }}></span>
                  <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>Chưa làm</p>
                </div>
                <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                  <span style={{ width: "0.75rem", height: "0.75rem", background: "#4B9631" }}></span>
                  <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>Đúng</p>
                </div>
                <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                  <span style={{ width: "0.75rem", height: "0.75rem", background: "#E34D4D" }}></span>
                  <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>Sai</p>
                </div>
              </div>
            </> : <>
              <div className="is-flex is-flex-direction-row is-flex-gap-2">
                <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                  <span style={{ width: "0.75rem", height: "0.75rem", background: "#E5E3EB" }}></span>
                  <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>Câu khác</p>
                </div>
                <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                  <span style={{ width: "0.75rem", height: "0.75rem", background: "#572CD1" }}></span>
                  <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>Hiện tại</p>
                </div>
              </div>
            </>}

          </> : <>
            <div style={{ width: "105%" }} className="is-flex is-flex-direction-row is-flex-gap-2">
              <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                <span style={{ width: "0.75rem", height: "0.75rem", background: "#E5E3EB" }}></span>
                <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>Chưa làm</p>
              </div>
              <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                <span style={{ width: "0.75rem", height: "0.75rem", background: "#FED131" }}></span>
                <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>Đã làm</p>
              </div>
              <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                <span style={{ width: "0.75rem", height: "0.75rem", background: "#572CD1" }}></span>
                <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>Hiện tại</p>
              </div>
            </div>
          </>}
        </div>
      </div>

      {deadline !== null &&
        deadline !== undefined &&
        !localStorage.getItem("takeExamIdLPMonthly") &&
        !localStorage.getItem("examYearIdMonthly") ? (
        <div
          className="has-background-kurio-purple-light-1 is-flex is-align-items-center is-justify-content-center is-relative"
          style={{
            // position: "absolute",
            // width: "19.5rem",
            height: "5rem",
            // background: "#E6E1F5",
            // left: "0.4rem",
            // top: "35.75rem",
            padding: "0.75rem 1rem",
            borderRadius: "1rem",
            width: "16rem",
            border: "1.5px solid #0A2A66",
            boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
          }}
        >
          <p
            className="has-text-centered has-text-weight-bold has-text-kurio-main-color-white has-background-kurio-main-color-black is-flex is-align-items-center"
            style={{
              fontSize: "0.875rem",
              lineHeight: "normal",
              width: "6rem",
              height: "3rem",
              borderRadius: "0.5rem",
            }}
          >
            Thời gian còn lại
          </p>
          <div
            className=" has-text-kurio-main-color-purple is-uppercase has-text-weight-bold has-text-centered has-background-kurio-purple-light-3 is-flex is-align-items-center is-justify-content-center"
            style={{
              fontSize: "2rem",
              lineHeight: "normal",
              borderRadius: "0.5rem",
              height: "3rem",
              width: "8.5rem",
            }}
          >
            <div className="is-flex is-align-items-center is-flex-direction-row is-justify-content-center">
              {start && (
                <Countdown
                  date={
                    changeTime && countdownDate !== null
                      ? countdownDate
                      : new Date(serverTime).getTime() + timeLeft
                  }
                  ref={timeStop}
                  onComplete={handleCountdownComplete}
                  renderer={rerender}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="is-relative is-flex is-align-items-center is-justify-content-center is-justify-content-space-between has-background-kurio-purple-light-1"
          style={{
            borderRadius: "1rem",
            width: "16rem",
            border: "1.5px solid #0A2A66",
            boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
            height: "5rem",
            padding: "0.75rem",
          }}
        >
          <p
            className="has-text-kurio-main-color-white has-background-kurio-main-color-black has-text-weight-bold has-text-centered is-flex is-justify-content-center is-align-items-center"
            style={{
              fontSize: "0.875rem",
              lineHeight: "normal",
              width: "6rem",
              height: "3rem",
              borderRadius: "0.5rem",
            }}
          >
            {localStorage.getItem("examMonthlyNow") ||
              localStorage.getItem("takeExamIdLPInformation")
              ? "Điểm lần này"
              : "Điểm lần trước"}
          </p>
          <div
            className=" has-text-kurio-main-color-purple is-uppercase has-text-weight-bold has-text-centered"
            style={{
              fontSize: "2rem",
              lineHeight: "2.43rem",
            }}
          >
            {localStorage.getItem("takeExamIdLPMonthly") ||
              localStorage.getItem("takeExamIdLPInformation") ||
              (localStorage.getItem("listNewExamSolutions") &&
                localStorage.getItem("maxScore") !== "0") ? (
              <div
                className="is-flex is-align-items-center is-flex-direction-row is-flex-gap-1 is-justify-content-center  has-background-kurio-purple-light-3"
                style={{
                  width: "8.5rem",
                  height: "3rem",
                  borderRadius: "0.5rem",
                }}
              >
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    lineHeight: "normal",
                    textTransform: "uppercase",
                  }}
                >
                  {localStorage.getItem("currentScore")
                    ? localStorage.getItem("currentScore")
                    : currentScore}
                </p>
                <p style={{ fontSize: "1.5rem" }}>/</p>

                <p
                  style={{
                    textTransform: "uppercase",
                    display: "flex",
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    lineHeight: "normal",
                  }}
                >
                  {localStorage.getItem("maxScore")
                    ? localStorage.getItem("maxScore")
                    : maxScore}
                </p>
              </div>
            ) : (
              <div
                className="has-background-kurio-purple-light-3"
                style={{
                  marginRight: "0.25rem",
                  fontWeight: 700,
                  fontSize: "1.1rem",
                  lineHeight: "2.4rem",
                  textTransform: "uppercase",
                  width: "8.5rem",
                  height: "3rem",
                  borderRadius: "0.5rem",
                }}
              >
                <ImageFromUrl
                  objectId="admin/frontend-images/hub/icon-point-exam.svg"
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
      )}

      <div
        className=" "
        style={{
          // position: "absolute",
          height: "5rem",
          // width: "100%",
          margin: "0 center",
          // left: "2.3rem",
          // top: "40.7rem",
        }}
      >
        <ButtonQuizExam
          onClick={clickShowPopupSubmit}
          isClick={false}
          style={{
            maxWidth: "16rem",
            width: "16rem",
            padding: "1rem",
            borderRadius: "1rem",
            border: "1.5px solid #0A2A66",
            boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
          }}
        >
          {checkingState ||
            localStorage.getItem("examYearIdMonthly") ||
            isPreview
            ? "Đã xem xong"
            : "Nộp bài"}
        </ButtonQuizExam>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    maxScore: state.examsQuestion.maxScore,
    idExamAdmin: state.examsQuestion.idExamAdmin,
    serverTime: state.examsQuestion.serverTime,
    deadline: state.examsQuestion.checkExamTime,
    currentScore: state.examsQuestion.currentScore,
    takeExamsQuestionId: state.examsQuestion.takeExamsQuestion,
    examQuestions: state.examsQuestion.questions,
    seenQuestions: state.examsQuestion.seenQuestions,
    takeExamIdLPInformation: state.examsQuestion.takeExamIdLPInformation,
    takeExamIdInformation: state.examsQuestion.takeExamIdInformation,
  };
}

export default connect(mapStateToProps)(MonthlyExamQuestionsLeftWrapper);
