import React, { useState } from "react";
import "./Radio.style.css";
const Radio = ({ children, onClick, defaultChecked }) => {
  const [check, setCheck] = useState(false);
  const audio = new Audio("/sounds/click.mp3");
  const handleClick = () => {
    setCheck(!check);
    audio.play();
    if (onClick) {
      onClick();
    }
  };
  return (
    <div className="control is-clickable">
      <label className="is-flex is-align-items-center" style={{ gap: "1rem" }}>
        <input
          type="radio"
          className="is-clickable"
          name="radio"
          defaultChecked={check}
          onClick={handleClick}
        />
        {children}
      </label>
    </div>
  );
};

export default Radio;
