/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { connect, useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import ButtonQuizExam from "../../../components/button/button-quiz-exam/ButtonQuizExam";
import PaginationBigButton from "../../../components/button/pagination-button/PaginationBigButton";
import PrimaryButtonPageLevel from "../../../components/button/primary-button/PrimaryButtonLevelPage";
import SecondaryButtonLevelPage from "../../../components/button/secondary-button/SecondaryButtonLevelPage";
import PopupCloseButton from "../../../components/button/special-button/PopupCloseButton";
import BackgroundImageFromUrl from "../../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../../components/parseImage/ImageFromUrl";
import { checkServerTimeError } from "../../../config/checkAndCallDailyLoginApi";
import { submitRoadMapExamAnswers } from "../../../config/roadMap";
import { getGradeUserInfo } from "../../../reducers/gradesSlice";
import {
  clearStateRoadMap,
  getRoadMapExamSolution,
  selectAnsweredQuestions,
  setQuestionAnswered,
  setQuestionState,
  setQuestionsAnswered,
  submitRoadMapExamAnswer,
} from "../../../reducers/roadmapSlice";
import { checkAndGetAnonymousUserId } from "../../../templates/helper/CheckAndGetAnonymousUserId";

const RoadMapExamLeftWrapper = ({
  maxScore,
  currentScore,
  selectedIndex,
  handleQuestionChange,
  deadline,
  takeExamsQuestionId,
  questionsRoadMap,
  setIsPreview,
  isPreview,
  serverTime,
  handleChangePagination,
  setShowBigExam,
  pagination,
  gradeName,
}) => {
  const [showPopupCancel, setShowPopupCancel] = useState(false);
  const [showPopupSubmit, setShowPopupSubmit] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(
    localStorage.getItem("currentIndex")
      ? parseInt(localStorage.getItem("currentIndex"))
      : 0
  );
  const [isRunning, setIsRunning] = useState(
    localStorage.getItem("currentIndex") ? true : false
  );
  const [showFinishedYearExam, setShowFinishedYearExam] = useState(
    localStorage.getItem("currentIndex") ? true : false
  );
  const [toggle, setToggle] = useState(false);
  const [start, setStart] = useState(false);
  const [changeTime, setChangeTime] = useState(false);
  // const [timeDifference, setTimeDifference] = useState(null);
  const [countdownDate, setCountDownDate] = useState(null);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();

  const cookies = new Cookies();
  const token = cookies.get("signinUser") || cookies.get("register");

  var checkStateAnswered =
    questionsRoadMap &&
    questionsRoadMap.filter((item) => item.isAnswered === false);

  var answeredQuestions = useSelector(selectAnsweredQuestions);

  const checkingState = questionsRoadMap?.every(
    (item) => item.question.showSolution === true
  );
  // const findTakeExamId = (action, examId) => {
  //   var result = "";
  //   if (action === "takeExamId") {
  //     listExamInfo?.map((item) =>
  //       item.id === examId ? (result = item.takeExamId) : item
  //     );
  //   } else if (action === "takeExamLP") {
  //     listExamInfo?.map((item) =>
  //       item.id === examId ? (result = item.takeExamLP) : item
  //     );
  //   }
  //   return result;
  // };

  const clickShowPopupSubmit = async () => {
    setToggle(!toggle);
    if (isPreview !== undefined) {
      if (toggle === false) {
        setIsPreview(true);
      } else {
        setIsPreview(false);
      }
    } else {

      const result = JSON.parse(localStorage.getItem("questionStateExams"));

      if (result) {
        dispatch(setQuestionState(result.questionState));
        dispatch(setQuestionAnswered(result.questionAnswered));
        dispatch(setQuestionsAnswered(result.questionsAnswered));
      }

      setShowPopupSubmit(true);
    }

    if (localStorage.getItem("submitted")) {
      localStorage.removeItem("takeRoadMapExamId");
      localStorage.removeItem("submitted");
      localStorage.removeItem("currentIndex")
      dispatch(clearStateRoadMap());
      setShowBigExam(false);
    }
  };

  const clickShowPopupCancel = () => {
    if (!checkingState) {
      setShowPopupCancel(true);
    } else if (checkingState) {
      if (localStorage.getItem("submitted")) {
        localStorage.removeItem("takeRoadMapExamId");
        localStorage.removeItem("submitted");
        localStorage.removeItem("questionStateExams");
        localStorage.removeItem("nameExam");
        localStorage.removeItem("currentIndex");
        dispatch(clearStateRoadMap());
        setShowBigExam(false);
      }
    }
  };

  const handleCancel = () => {
    const result = JSON.parse(localStorage.getItem("questionStateExams"));

    if (result) {
      dispatch(setQuestionState(result.questionState));
      dispatch(setQuestionAnswered(result.questionAnswered));
      dispatch(setQuestionsAnswered(result.questionsAnswered));
      var submit = result.submit;
      dispatch(
        submitRoadMapExamAnswer({
          examId: localStorage.getItem("takeRoadMapExamId"),
          answers: submit,
          isAnonymous: token ? false : true,
          anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
        })
      );
    }

    localStorage.removeItem("questionStateExams");
    localStorage.removeItem("nameExam");

    localStorage.removeItem("takeRoadMapExamId");
    localStorage.removeItem("currentIndex");
    dispatch(clearStateRoadMap());
    setShowBigExam(false);
  };

  const removePopup = () => {
    if (showPopupCancel) {
      setShowPopupCancel(false);
    }
    if (showPopupSubmit) {
      setShowPopupSubmit(false);
    }
  };

  const handleChange = (val) => {
    setShowPopupSubmit(false);
    handleQuestionChange(val);
  };

  const handleSubmit = async () => {
    localStorage.removeItem("pagination");

    const result = JSON.parse(localStorage.getItem("questionStateExams"));
    handleChangePagination(1);
    handleQuestionChange(0);

    if (result) {
      dispatch(setQuestionState(result.questionState));
      dispatch(setQuestionAnswered(result.questionAnswered));
      dispatch(setQuestionsAnswered(result.questionsAnswered));
      answeredQuestions = result.questionsAnswered;
    } else {
      answeredQuestions = questionsRoadMap.map((question) => ({
        questionId: question.questionId,
        questionIndex: question.questionIndex,
        answer: question.answer,
      }));
    }

    var answers = {
      submit: true,
      questions: answeredQuestions,
    };

    try {
      takeExamsQuestionId = localStorage.getItem("takeRoadMapExamId");
      await submitRoadMapExamAnswers(
        takeExamsQuestionId,
        answers,
        token ? false : true,
        token ? undefined : checkAndGetAnonymousUserId()
      );
      if (currentScore !== 0) {
        localStorage.setItem("currentScore", currentScore);
      }
      dispatch(
        getRoadMapExamSolution({
          examId: takeExamsQuestionId,
          page: 1,
          size: 50,
          isAnonymous: token ? false : true,
          anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
        })
      );
      setShowFinishedYearExam(true);
      setIsRunning(true);
      localStorage.removeItem("questionStateExams");
      localStorage.removeItem("targets");
    } catch (error) {
      const result = await checkServerTimeError();
      if (result) {
        if (currentScore !== 0) {
          localStorage.setItem("currentScore", currentScore);
        }

        setShowFinishedYearExam(true);
        setIsRunning(true);
        localStorage.removeItem("questionStateExams");
        localStorage.removeItem("targets");
      }
      console.log("err", error);
    }

    // localStorage.removeItem("takeRoadMapExamId")
    // setShowBigExam(false);
  };

  const handleClickButton = (action) => {
    localStorage.removeItem("currentIndex");
    dispatch(setQuestionsAnswered([]));

    if (action === "XEM LOI GIAI") {
      if (localStorage.getItem("takeRoadMapExamId")) {
        dispatch(
          getRoadMapExamSolution({
            examId: localStorage.getItem("takeRoadMapExamId"),
            page: 1,
            size: 50,
            isAnonymous: token ? false : true,
            anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
          })
        );
      }
      localStorage.removeItem("questionStateExams");
      setShowFinishedYearExam(false);
      localStorage.setItem("submitted", true);
      localStorage.removeItem("currentIndex");
    }
  };

  const renderScreenYearExam = () => {
    localStorage.setItem("currentScore", currentScore);
    switch (currentIndex) {
      case 0:
        return (
          <>
            <div className="img__quiz">
              {/* <ImageFromUrl
                objectId="admin/frontend-images/quiz/image-quiz.svg"
                alt=""
              /> */}
              <img src="/images/exam/icon-meo.svg" alt="" />
            </div>
            <div className="is-flex is-flex-direction-column is-align-items-flex-start is-flex-gap-4">
              <p className="title__loading has-text-kurio-main-color-black">
                Đang tính toán...{" "}
              </p>
              <span className="loader__ikmc"></span>
            </div>
          </>
        );
      // case 1:
      //   return (
      //     <>
      //       <div className="finished-notice">
      //         {/* <ImageFromUrl
      //           objectId="admin/frontend-images/quiz/image-quiz.svg"
      //           alt=""
      //         /> */}
      //         <img src="/images/exam/icon-meo.svg" alt="" />

      //         <p className="title-notice">
      //           Kết quả {localStorage.getItem("nameExam")}
      //         </p>

      //         <div className="exam-score-card">
      //           <div className="group-exam">
      //             <p>Số điểm của bạn</p>
      //           </div>
      //           <div className="exam-score">
      //             <div className="mark-group">
      //               <div className="score">
      //                 <p>{currentScore}</p>
      //                 <span>/{maxScore}</span>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </>
      //   );
      default:
        return (
          <div className="is-flex is-flex-direction-column is-flex-gap-6 is-align-items-center">
            <div className="is-flex is-flex-direction-row is-align-items-flex-end">
              <div
                className="has-background-kurio-main-color-white is-flex is-flex-direction-column is-flex-gap-5 is-justify-content-center is-align-items-center"
                style={{
                  width: "21rem",
                  border: "1.5px solid #0A2A66",
                  boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
                  borderRadius: "10rem 10rem 2.5rem 2.5rem",
                  padding: "2.5rem",
                }}
              >
                <img
                  style={{ objectFit: "cover" }}
                  src="/images/exam/icon-meo.svg"
                  alt=""
                />
                <div className="is-flex is-flex-direction-column is-justify-content-center is-flex-gap-4 is-align-items-center">
                  <div
                    className="has-text-kurio-main-color-black has-text-weight-bold has-text-centered"
                    style={{ fontSize: "2.5rem", lineHeight: "normal" }}
                  >
                    {/* <p>Đề thi</p> */}
                    <p className="is-uppercase">
                      {localStorage.getItem("nameExam")}
                    </p>
                  </div>
                  <p
                    className="has-text-weight-bold has-text-kurio-main-color-black is-uppercase has-background-kurio-main-color-light-gray"
                    style={{
                      fontSize: "1.25rem",
                      lineHeight: "normal",
                      padding: "0.5rem 0.75rem",
                      borderRadius: "1rem",
                    }}
                  >
                    {gradeName === "Lớp 1" || gradeName === "Lớp 2"
                      ? "Level 1"
                      : gradeName === "Lớp 3" || gradeName === "Lớp 4"
                        ? "Level 2"
                        : gradeName === "Lớp 5" || gradeName === "Lớp 6"
                          ? "Level 3"
                          : gradeName === "Lớp 7" || gradeName === "Lớp 8"
                            ? "Level 4"
                            : "Level 1"}
                  </p>
                </div>
              </div>
              <div className="is-flex is-flex-direction-column is-align-items-flex-end is-align-self-stretch">
                <img src="/images/exam/rocket.svg" alt="" />
                <div className="is-flex is-align-items-center">
                  <div className="is-flex is-flex-direction-column is-flex-gap-1">
                    <div
                      className="has-background-kurio-main-color-black"
                      style={{
                        width: "1rem",
                        height: "2.0625rem",
                      }}
                    ></div>
                    <div
                      className="has-background-kurio-main-color-black"
                      style={{
                        width: "1rem",
                        height: "2.0625rem",
                      }}
                    ></div>
                    <div
                      className="has-background-kurio-main-color-black"
                      style={{
                        width: "1rem",
                        height: "2.0625rem",
                      }}
                    ></div>
                  </div>
                  <div
                    className="is-flex is-flex-direction-row has-background-kurio-main-color-white"
                    style={{
                      gap: "1.5rem",
                      padding: "2.5rem",
                      borderRadius: "2.5rem",
                      border: "1.5px solid #0A2A66",
                      boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
                      width: "37.5rem",
                      height: "19.5rem",
                    }}
                  >
                    <div
                      className="is-relative is-flex is-flex-direction-column is-align-items-center is-flex-gap-3 is-justify-content-center has-background-kurio-purple-light-1"
                      style={{
                        borderRadius: "1rem",
                        width: "100%",
                        border: "1.5px solid #0A2A66",
                        boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
                        padding: "0.75rem",
                      }}
                    >
                      <p
                        className="has-text-kurio-main-color-black has-background-kurio-main-color-white has-text-weight-bold has-text-centered is-flex is-justify-content-center is-align-items-center"
                        style={{
                          fontSize: "0.875rem",
                          lineHeight: "normal",
                          height: "3rem",
                          width: "100%",
                          borderRadius: "0.5rem",
                          border: "1.5px solid #0A2A66",
                          boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
                        }}
                      >
                        Điểm thi
                      </p>
                      <div
                        className=" has-text-kurio-main-color-white is-uppercase has-text-weight-bold has-text-centered "
                        style={{ width: "100%", height: "8.6875rem" }}
                      >
                        <div
                          className="is-flex is-align-items-center is-flex-direction-column is-align-items-center is-align-self-center is-justify-content-center  has-background-kurio-main-color-purple"
                          style={{
                            borderRadius: "0.5rem",
                            padding: "1rem 0rem",
                            width: "100%",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 700,
                              fontSize: "4rem",
                              lineHeight: "normal",
                              textTransform: "uppercase",
                            }}
                          >
                            {currentScore}
                          </p>
                          <div className="is-flex is-flex-gap-2 is-align-items-center">
                            <p style={{ fontSize: "1.5rem" }}>/</p>
                            <p
                              style={{
                                textTransform: "uppercase",
                                display: "flex",
                                fontWeight: 700,
                                fontSize: "1.5rem",
                                lineHeight: "normal",
                              }}
                            >
                              {maxScore}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="is-flex is-flex-direction-column"
                      style={{ gap: "1rem" }}
                    >
                      {/* <PrimaryButtonPageLevel
                          isDarkColor={true}
                          style={{
                            height: "4rem",
                            border: "0.09375rem solid #0a2a66",
                            boxShadow: " 0.125rem 0.125rem 0rem 0rem #0a2a66",
                            borderRadius: "1rem",
                          }}
                          onClick={() =>
                            handleClickButton(
                              "LAM LAI",
                              localStorage.getItem("examsQuestionId")
                            )
                          }
                        >
                          Làm lại
                        </PrimaryButtonPageLevel> */}
                      <PrimaryButtonPageLevel
                        isDarkColor={true}
                        style={{
                          height: "4rem",
                          border: "0.09375rem solid #0a2a66",
                          boxShadow: " 0.125rem 0.125rem 0rem 0rem #0a2a66",
                          borderRadius: "1rem",
                        }}
                        onClick={() => {
                          handleClickButton(
                            "XEM LOI GIAI",
                            localStorage.getItem("takeRoadMapExamId")
                          );
                        }}
                      >
                        Xem lời giải
                      </PrimaryButtonPageLevel>
                      <PrimaryButtonPageLevel
                        // isDarkColor={true}
                        style={{
                          height: "4rem",
                          border: "0.09375rem solid #0a2a66",
                          boxShadow: " 0.125rem 0.125rem 0rem 0rem #0a2a66",
                          borderRadius: "1rem",
                        }}
                        onClick={handleCancel}
                      >
                        Thoát
                      </PrimaryButtonPageLevel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {(findTakeExamId("takeExamLP", examId) === null ||
              findTakeExamId("takeExamLP", examId)?.length === 0) && (
                <div
                  className=" is-relative has-background-kurio-main-color-white is-relative"
                  style={{
                    border: "1.5px solid #0A2A66",
                    boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
                    padding: "0.5rem 1.5rem",
                    borderRadius: "1rem",
                  }}
                >
                  <div
                    className=" is-flex is-align-items-center is-justify-content-center has-text-kurio-main-color-black has-text-centered has-text-weight-bold"
                    style={{ fontSize: "2.5rem", lineHeight: "normal" }}
                  >
                    <p>
                      60
                    </p>
                    <img
                      style={{
                        objectFit: "cover",
                        width: "2.0031rem",
                        height: "2rem",
                      }}
                      src="/images/hub/star.png"
                      alt=""
                    />
                  </div>
                  <div
                    className=""
                    style={{
                      position: "absolute",
                      bottom: "-0.75rem",
                      right: "-13.3125rem",
                      width: "14.3125rem",
                      height: "5.625rem",
                    }}
                  >
                    <ImageFromUrl
                      objectId="admin/frontend-images/exam/image-success-year-exams.svg"
                      alt=""
                    />
                  </div>
                </div>
              )} */}
          </div>
        );
    }
  };

  useEffect(() => {
    // TODO
    if (isRunning && currentIndex < 2) {
      const timer = setTimeout(() => {
        localStorage.setItem("currentIndex", currentIndex + 1);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 3000);

      return () => clearTimeout(timer);
    }
    if (
      (!gradeName || gradeName?.length === 0) &&
      !window.location.pathname.includes("preview") &&
      token
    ) {
      dispatch(getGradeUserInfo());
    }

    if (deadline !== null && !start) {
      const count = Math.floor(
        new Date(deadline).getTime() - new Date(serverTime).getTime()
      );

      setStart(true);
      setTimeLeft(count);
    }
    const countdown = setInterval(() => {
      setChangeTime(true);
      setCount((count) => count + 1);
      // setTimeLeft((timeLeft)=> timeLeft - 60000)
      setCountDownDate(new Date(serverTime).getTime() + timeLeft);
    }, 60000);
    return () => clearInterval(countdown);
  }, [
    deadline,
    isRunning,
    currentIndex,
    serverTime,
    start,
    countdownDate,
    timeLeft,
    count,
    changeTime,
    gradeName,
    dispatch,
  ]);
  const rerender = ({ hours, minutes, seconds, completed }) => {
    // console.log("test", (minutes + hours * 60 + seconds )*1000 - timeLeft)
    if (minutes + hours * 60 === 0 && seconds === 2) {
      handleSubmit();
    } else {
      return (
        <>
          <p
            style={{
              marginRight: "0.25rem",
              fontWeight: 700,
              fontSize: "1.5rem",
              lineHeight: "normal",
              textTransform: "uppercase",
            }}
          >
            {zeroPad(minutes + hours * 60)}
          </p>
          <div>
            <p
              style={{
                marginRight: "0.25rem",
                fontSize: "1.5rem",
                lineHeight: "normal",
              }}
            >
              {" "}
              :{" "}
            </p>
          </div>

          <p
            style={{
              textTransform: "uppercase",
              display: "flex",
              fontWeight: 700,
              fontSize: "1.5rem",
              lineHeight: "normal",
            }}
          >
            {zeroPad(seconds)}
          </p>
        </>
      );
    }
  };

  return (
    <>
      {showFinishedYearExam && (
        <BackgroundImageFromUrl
          objectId={"admin/frontend-images/hub/background-exam-vault.jpg"}
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            // backgroundImage: "url('/images/hub/background-exam-vault.jpg')",
            minHeight: "100vh",
            opacity: 1,
          }}
          // objectId="admin/frontend-images/quiz/background-quiz.svg"
          className="modal is-active modal-background background-quiz"
        >
          <div className="modal__quiz">{renderScreenYearExam()}</div>
        </BackgroundImageFromUrl>
      )}

      {showPopupCancel && !checkingState && (
        <div
          onClick={removePopup}
          className="modal is-active modal-background"
          style={{ opacity: 1 }}
        >
          <div className="modal-quiz is-relative">
            <h2 style={{ color: "#0A2A66" }} className="title-popup-quiz">
              ! Chờ đã !
            </h2>
            <p className="document-quiz">
              Bạn có muốn nộp bài trước khi thoát không?
            </p>

            <div
              className="is-flex is-flex-direction-column"
              style={{ gap: "1rem", margin: "0 auto" }}
            >
              <PrimaryButtonPageLevel
                onClick={removePopup}
                style={{ height: "4rem" }}
              >
                Tiếp tục
              </PrimaryButtonPageLevel>
              <SecondaryButtonLevelPage onClick={handleCancel}>
                Thoát
              </SecondaryButtonLevelPage>

              <SecondaryButtonLevelPage onClick={clickShowPopupSubmit}>
                Nộp Bài Ngay
              </SecondaryButtonLevelPage>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {showPopupSubmit && !checkingState && (
        <div className="modal is-active " style={{ opacity: 1, zIndex: 2 }}>
          {/* <div
            className="modal-background"
            onClick={removePopup}
            style={{ opacity: 0.8 }}
          ></div> */}
          <div
            className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center has-background-kurio-main-color-white is-relative has-text-kurio-main-color-black"
            style={{
              padding: "8.4375rem 15.75rem",
              // borderRadius: "2rem",
              width: "100%",
              height: "100%",
              // width: "56.375rem",
              // height: "30.25rem",
              gap: "2rem",
            }}
          >
            <div className="is-flex is-flex-direction-column is-flex-gap-2 is-align-items-center">
              <p
                className="has-text-weight-bold"
                style={{
                  fontSize: "2.25rem",
                  fontStyle: "normal",
                  lineHeight: "normal",
                }}
              >
                ! Nộp bài !
              </p>
              <div className="is-flex is-flex-direction-column is-justify-content-center is-flex-gap-4 is-align-items-center">
                <div
                  className="has-text-kurio-main-color-black has-text-weight-bold has-text-centered is-flex is-flex-gap-1"
                  style={{ fontSize: "1.5rem", lineHeight: "normal" }}
                >
                  {/* <p>Đề thi</p> */}
                  <p className="is-uppercase">
                    {localStorage.getItem("nameExam")}
                  </p>
                </div>
                <p
                  className="has-text-weight-bold has-text-kurio-main-color-black is-uppercase has-background-kurio-main-color-light-gray"
                  style={{
                    fontSize: "0.75rem",
                    lineHeight: "normal",
                    padding: "0.5rem 0.75rem",
                    borderRadius: "1rem",
                  }}
                >
                  {gradeName === "Lớp 1" || gradeName === "Lớp 2"
                    ? "Level 1"
                    : gradeName === "Lớp 3" || gradeName === "Lớp 4"
                      ? "Level 2"
                      : gradeName === "Lớp 5" || gradeName === "Lớp 6"
                        ? "Level 3"
                        : gradeName === "Lớp 7" || gradeName === "Lớp 8"
                          ? "Level 4"
                          : ""}
                </p>
              </div>
            </div>
            <div
              className="is-flex is-flex-direction-row is-align-items-center"
              style={{ gap: "5rem" }}
            >
              <div
                className="is-flex is-flex-direction-column is-flex-gap-5"
                style={{ width: "25.25rem" }}
              >
                <div className="is-flex is-align-items-center is-flex-gap-5">
                  <div
                    className=" is-flex is-align-items-center is-flex-gap-2"
                    style={{ fontSize: "0.875rem", lineHeight: "normal" }}
                  >
                    <p
                      className=""
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        background: "#E5E3EB",
                      }}
                    ></p>
                    <p>Chưa làm</p>
                  </div>
                  <div
                    className=" is-flex is-align-items-center is-flex-gap-2"
                    style={{ fontSize: "0.875rem", lineHeight: "normal" }}
                  >
                    <p
                      className="has-background-kurio-main-color-yellow"
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                      }}
                    ></p>
                    <p>Đã làm</p>
                  </div>
                </div>

                <div
                  className="columns m-0 is-multiline"
                  style={{ gap: "0.75rem" }}
                >
                  {questionsRoadMap &&
                    questionsRoadMap.map((question, index) => {
                      return (
                        <div className={`column is-narrow m-0 p-0`} key={index}>
                          <PaginationBigButton
                            onClick={() => handleChange(index)}
                            IKMCMode={true}
                            key={index}
                            isClick={false}
                            id={question.questionId}
                            value={question.questionIndex}
                            isAnswered={question.isAnswered}
                            isCorrect={question.isCorrect}
                          >
                            {question.questionIndex + 1}
                          </PaginationBigButton>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div
                className="is-flex is-flex-direction-column is-flex-gap-6 is-align-items-flex-start"
                style={{}}
              >
                <div
                  className="has-text-weight-bold is-flex is-flex-direction-column is-flex-gap-2"
                  style={{
                    fontSize: "1.125rem",
                    lineHeight: "normal",
                    fontStyle: "normal",
                  }}
                >
                  <p className="has-text-weight-medium">
                    Tổng số câu hỏi: {questionsRoadMap?.length} câu
                  </p>
                  <p>
                    Số câu đã làm:{" "}
                    {questionsRoadMap?.length - checkStateAnswered?.length} /{" "}
                    {questionsRoadMap?.length} câu.
                  </p>
                  <p className="has-text-kurio-red-main-accent">
                    Số câu chưa làm: {checkStateAnswered?.length} /{" "}
                    {questionsRoadMap?.length} câu.
                  </p>
                  {/* <p>Bạn muốn tiếp tục làm bài?</p> */}
                </div>
                <div
                  className="is-flex is-flex-direction-column"
                  style={{ gap: "1rem" }}
                >
                  <PrimaryButtonPageLevel
                    onClick={handleSubmit}
                    style={{
                      height: "4rem",
                      borderRadius: "1rem",
                      maxWidth: "20rem",
                      width: "20rem",
                    }}
                  >
                    Xác Nhận Nộp bài
                  </PrimaryButtonPageLevel>
                  <SecondaryButtonLevelPage
                    style={{
                      borderRadius: "1rem",
                      maxWidth: "20rem",
                      width: "20rem",
                    }}
                    onClick={removePopup}
                  >
                    Chưa nộp
                  </SecondaryButtonLevelPage>
                </div>
              </div>
            </div>

            {/* <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div> */}
          </div>
        </div>
      )}

      <div
        className="has-background-kurio-main-color-white is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between is-relative"
        style={{
          padding: "2.5rem 1.5rem 1.5rem 1.5rem",
          // height: "28.5rem",
          borderRadius: " 2.5rem 2.5rem 1.5rem 1.5rem",
          border: "1.5px solid #0A2A66",
          boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
          width: "16rem",
          gap: "2.5rem",
        }}
      >
        <div
          onClick={clickShowPopupCancel}
          className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center is-align-content-center"
          style={{
            padding: "0.5rem 0.75rem",
            background: "#E9EEF8",
            borderRadius: "1rem",
            gap: "0.75rem",
            height: "2.5rem",
            cursor: "pointer",
            border: "1.5px solid #0A2A66",
            boxShadow: "0.125rem 0.125rem 0 0 #0A2A66",
            position: "absolute",
            zIndex: 1,
            top: "-1.25rem",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M7.41425 6.00001L11.7072 1.70701C12.0982 1.31601 12.0982 0.684006 11.7072 0.293006C11.3162 -0.0979941 10.6842 -0.0979941 10.2933 0.293006L6.00025 4.58601L1.70725 0.293006C1.31625 -0.0979941 0.68425 -0.0979941 0.29325 0.293006C-0.09775 0.684006 -0.09775 1.31601 0.29325 1.70701L4.58625 6.00001L0.29325 10.293C-0.09775 10.684 -0.09775 11.316 0.29325 11.707C0.48825 11.902 0.74425 12 1.00025 12C1.25625 12 1.51225 11.902 1.70725 11.707L6.00025 7.41401L10.2933 11.707C10.4882 11.902 10.7443 12 11.0002 12C11.2562 12 11.5122 11.902 11.7072 11.707C12.0982 11.316 12.0982 10.684 11.7072 10.293L7.41425 6.00001Z"
              fill="#0A2A66"
            />
          </svg>
          <span
            style={{
              fontSize: "0.875rem",
              color: "#0a2a66",
              lineHeight: "normal",
              fontWeight: 700,
            }}
          >
            Thoát
          </span>
        </div>

        <div
          className="is-flex is-flex-direction-column is-align-items-center"
          style={{ gap: "1rem", width: "11.5rem" }}
        >
          <div className="is-flex is-flex-gap-2 is-flex-direction-column is-align-items-center is-justify-content-center">
            <p
              className=" has-text-centered"
              style={{
                fontWeight: 700,
                fontSize: "1.25rem",
                lineHeight: "normal",
                color: "#0A2A66",
              }}
            >
              {localStorage.getItem("nameExam")}
            </p>

            {
              ""
              // <>
              //   <div className="is-flex is-flex-direction-row is-flex-gap-2 is-justify-content-center" style={{ width: "100%" }}>
              //     <span className="is-flex has-text-kurio-main-color-black has-background-kurio-main-color-light-gray is-justify-content-center is-align-items-center" style={{ borderRadius: "1rem", padding: "0.5rem 0.75rem", fontSize: "0.75rem", textTransform: "uppercase", fontWeight: 700 }}>{localStorage.getItem('gradeName')}</span>
              //     <span className="is-flex is-justify-content-center is-align-items-center" style={{ borderRadius: "1rem", padding: "0.5rem 0.75rem", fontSize: "0.75rem", textTransform: "uppercase", fontWeight: 700, background: "#FFE883", color: "#BF610D" }}>Lời giải</span>
              //   </div>
              // </>
              //   : ""
            }
          </div>
          <div
            className="columns m-0 is-multiline is-align-items-flex-start"
            style={{
              gap: "0.5rem",
              width: "11.5rem",
              // overflow: "auto",
              // padding:"2.5rem 1.5rem 1.5rem 1.5rem",
              // // height: "calc(100% - 20rem)",
              // height:"27rem"
            }}
          >
            {questionsRoadMap &&
              questionsRoadMap.map((question, index) => {
                return (
                  <div
                    className={`${pagination === 1
                      ? index <= 19
                        ? ""
                        : "is-hidden"
                      : index <= 19
                        ? "is-hidden"
                        : ""
                      } column is-narrow m-0 p-0`}
                    key={index}
                  >
                    <PaginationBigButton
                      onClick={() => handleChange(index)}
                      IKMCMode={true}
                      key={index}
                      isClick={index === selectedIndex ? true : false}
                      id={question.questionId}
                      value={question.questionIndex}
                      isAnswered={question.isAnswered}
                      isCorrect={question.isCorrect}
                    >
                      {question.questionIndex + 1}
                    </PaginationBigButton>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="is-flex is-flex-direction-column is-align-items-center is-flex-gap-4">
          {questionsRoadMap?.length > 20 && (
            <div
              className="is-flex is-flex-direction-row is-justify-content-center has-text-kurio-main-color-black is-uppercase has-text-weight-bold is-align-items-center is-align-content-center"
              style={{
                padding: "0.5rem 0.75rem",
                background: "#E9EEF8",
                borderRadius: "1rem",
                gap: "0.75rem",
                height: "2.5rem",
                cursor: "pointer",
                border: "1.5px solid #0A2A66",
                fontSize: "0.75rem",
                boxShadow: "0.125rem 0.125rem 0 0 #0A2A66",
              }}
              onClick={() => {
                handleChangePagination(pagination === 1 ? 2 : 1);
                localStorage.setItem("pagination", pagination === 1 ? 2 : 1);
              }}
            >
              {pagination === 1 && (
                <>
                  <p>Trang sau</p>
                  <img src="/images/button/icon-arrow.svg" alt="icon" />
                </>
              )}
              {pagination === 2 && (
                <>
                  <img
                    style={{ transform: "scaleX(-1)" }}
                    src="/images/button/icon-arrow.svg"
                    alt="icon"
                  />
                  <p>Trang trước</p>
                </>
              )}
            </div>
          )}

          {localStorage.getItem("submitted") ? (
            <>
              <div className="is-flex is-flex-direction-row is-flex-gap-2">
                <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                  <span
                    style={{
                      width: "0.75rem",
                      height: "0.75rem",
                      background: "#E5E3EB",
                    }}
                  ></span>
                  <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>
                    Chưa làm
                  </p>
                </div>
                <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                  <span
                    style={{
                      width: "0.75rem",
                      height: "0.75rem",
                      background: "#4B9631",
                    }}
                  ></span>
                  <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>Đúng</p>
                </div>
                <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                  <span
                    style={{
                      width: "0.75rem",
                      height: "0.75rem",
                      background: "#E34D4D",
                    }}
                  ></span>
                  <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>Sai</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                style={{ width: "105%" }}
                className="is-flex is-flex-direction-row is-flex-gap-2"
              >
                <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                  <span
                    style={{
                      width: "0.75rem",
                      height: "0.75rem",
                      background: "#E5E3EB",
                    }}
                  ></span>
                  <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>
                    Chưa làm
                  </p>
                </div>
                <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                  <span
                    style={{
                      width: "0.75rem",
                      height: "0.75rem",
                      background: "#FED131",
                    }}
                  ></span>
                  <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>Đã làm</p>
                </div>
                <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                  <span
                    style={{
                      width: "0.75rem",
                      height: "0.75rem",
                      background: "#572CD1",
                    }}
                  ></span>
                  <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>
                    Hiện tại
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {deadline !== null &&
        deadline !== undefined &&
        !checkingState &&
        !localStorage.getItem("takeExamIdLPMonthly") &&
        !localStorage.getItem("examYearIdMonthly") ? (
        <div
          className="has-background-kurio-purple-light-1 is-flex is-align-items-center is-justify-content-center is-relative"
          style={{
            // position: "absolute",
            // width: "19.5rem",
            height: "5rem",
            // background: "#E6E1F5",
            // left: "0.4rem",
            // top: "35.75rem",
            padding: "0.75rem 1rem",
            borderRadius: "1rem",
            width: "16rem",
            border: "1.5px solid #0A2A66",
            boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
          }}
        >
          <p
            className="has-text-centered has-text-weight-bold has-text-kurio-main-color-white has-background-kurio-main-color-black is-flex is-align-items-center"
            style={{
              fontSize: "0.875rem",
              lineHeight: "normal",
              width: "6rem",
              height: "3rem",
              borderRadius: "0.5rem",
            }}
          >
            Thời gian còn lại
          </p>
          <div
            className=" has-text-kurio-main-color-purple is-uppercase has-text-weight-bold has-text-centered has-background-kurio-purple-light-3 is-flex is-align-items-center is-justify-content-center"
            style={{
              fontSize: "2rem",
              lineHeight: "2.43rem",
              borderRadius: "0.5rem",
              height: "3rem",
              width: "8.5rem",
            }}
          >
            <div className="is-flex is-align-items-center is-flex-direction-row is-justify-content-center">
              {start && (
                <Countdown
                  date={
                    changeTime && countdownDate !== null
                      ? countdownDate
                      : new Date(serverTime).getTime() + timeLeft
                  }
                  renderer={rerender}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="is-relative is-flex is-align-items-center is-justify-content-center is-justify-content-space-between has-background-kurio-purple-light-1"
          style={{
            borderRadius: "1rem",
            width: "16rem",
            border: "1.5px solid #0A2A66",
            boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
            height: "5rem",
            padding: "0.75rem",
          }}
        >
          <p
            className="has-text-kurio-main-color-white has-background-kurio-main-color-black has-text-weight-bold has-text-centered is-flex is-justify-content-center is-align-items-center"
            style={{
              fontSize: "0.875rem",
              lineHeight: "normal",
              width: "6rem",
              height: "3rem",
              borderRadius: "0.5rem",
            }}
          >
            {localStorage.getItem("submitted")
              ? "Điểm lần trước"
              : "Điểm lần này"}
          </p>
          <div className=" has-text-kurio-main-color-purple is-uppercase has-text-weight-bold has-text-centered ">
            {localStorage.getItem("submitted") ? (
              <div
                className="is-flex is-align-items-center is-flex-direction-row is-flex-gap-1 is-justify-content-center  has-background-kurio-purple-light-3"
                style={{
                  width: "8.5rem",
                  height: "3rem",
                  borderRadius: "0.5rem",
                }}
              >
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    lineHeight: "normal",
                    textTransform: "uppercase",
                  }}
                >
                  {currentScore}
                </p>
                <p style={{ fontSize: "1.5rem" }}>/</p>

                <p
                  style={{
                    textTransform: "uppercase",
                    display: "flex",
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    lineHeight: "normal",
                  }}
                >
                  {maxScore}
                </p>
              </div>
            ) : (
              <div
                className="has-background-kurio-purple-light-3"
                style={{
                  marginRight: "0.25rem",
                  fontWeight: 700,
                  fontSize: "1.1rem",
                  lineHeight: "2.4rem",
                  textTransform: "uppercase",
                  width: "8.5rem",
                  height: "3rem",
                  borderRadius: "0.5rem",
                }}
              >
                <ImageFromUrl
                  objectId="admin/frontend-images/hub/icon-point-exam.svg"
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
      )}

      <div
        className=" "
        style={{
          // position: "absolute",
          height: "5rem",
          // width: "100%",
          margin: "0 center",
          // left: "2.3rem",
          // top: "40.7rem",
        }}
      >
        <ButtonQuizExam
          onClick={clickShowPopupSubmit}
          isClick={false}
          style={{
            maxWidth: "16rem",
            width: "16rem",
            padding: "1rem",
            borderRadius: "1rem",
            border: "1.5px solid #0A2A66",
            boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
          }}
        >
          {checkingState || localStorage.getItem("submitted")
            ? "Đã xem xong"
            : "Nộp bài"}
        </ButtonQuizExam>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    gradeName: state.grades.gradeName,
    maxScore: state.roadMap.maxScore,
    // idExamAdmin: state.examsQuestion.idExamAdmin,
    serverTime: state.roadMap.serverTime,
    deadline: state.roadMap.checkExamTime,
    currentScore: state.roadMap.currentScore,
    takeExamsQuestionId: state.examsQuestion.takeExamsQuestion,
    questionsRoadMap: state.roadMap.questions,
    seenQuestions: state.roadMap.seenQuestions,
  };
}

export default connect(mapStateToProps)(RoadMapExamLeftWrapper);
