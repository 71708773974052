import React from "react";
import jsonParser from "../helper/jsonParser";

const MainContent1Title = ({ title, style }) => {
  return (
    <div className="m-auto" style={{ maxWidth: "40rem" }}>
      <div
        className="content-title-1 is-size-3 has-text-centered has-text-kurio-main-color-black"
        style={{ margin: "0rem 0rem 2.5rem 0rem", ...style }}
      >
        <p style={style} className="has-text-weight-bold">
          {jsonParser(title, 0, "MainContentTitle")}
        </p>
      </div>
    </div>
  );
};

export default MainContent1Title;
