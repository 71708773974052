/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { decodeToken } from "react-jwt";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getALesson } from "../../reducers/questionsSlice";
import { LoadingImage } from "../../templates/helper/LoadingImage";
import { getAllImage } from "../../templates/helper/getAllImages";
import PreviewLessonMainWrapper from "./PreviewLessonMainWrapper";

const PreviewLesson = ({ sections }) => {
  const [loadingImage, setLoadingImage] = useState(false);
  const navigate = useNavigate();
  // const cookies = new Cookies();
  // const token = cookies.get("signinUser");
  const dispatch = useDispatch();
  const handleSetLoadingImage = (state) => {
    setLoadingImage(state);
  };
  useEffect(() => {
    const getLessonPreview = async (lessonId, token) => {
      try {
        dispatch(getALesson({ lessonId, token }));
        setLoadingImage(true);
      } catch (error) {
        console.log("errr", error.response?.data?.errorMessage);
        alert(error.response?.data?.errorMessage);
      }
    };
    const arrayURL =
      process.env.REACT_APP_ENV === "development"
        ? JSON.parse(process.env.REACT_APP_DEV_ARRAY_ADMIN_URL)
        : JSON.parse(process.env.REACT_APP_ARRAY_ADMIN_URL);
    const handleRes = (e) => {
      if (arrayURL.some((url) => e.origin.includes(url))) {
        if (e?.data.lessonId && e?.data.token) {
          var roles = ["CREATOR", "REVIEWER"];
          var check = decodeToken(e?.data.token).roles.some((role) =>
            roles.includes(role)
          );
          if (!check) {
            alert("Khong duoc vao");
            navigate("/");
          } else {
            getLessonPreview(e?.data.lessonId, e?.data.token);
          }
        }
      }
    };
    window.addEventListener("message", handleRes);
    return () => window.removeEventListener("message", handleRes);
  }, [dispatch, navigate, loadingImage]);
  if (loadingImage && sections !== null && sections?.length > 0) {
    const result = getAllImage(sections, "LESSON");
    if (result && result?.length > 0) {
      return (
        <>
          <LoadingImage
            images={result}
            router={""}
            handleSetLoadingImage={(state) => handleSetLoadingImage(state)}
          />
        </>
      );
    }else {
      setLoadingImage(false);
    }
  }
  return (
    <>
      {!loadingImage && sections !== null && sections && (
        <div className="m-auto">{<PreviewLessonMainWrapper />}</div>
      )}
      {/* {loadingImage && <LoadingImage />} */}
    </>
  );
};
function mapStateToProps(state) {
  return {
    sections: state.questions.sections,
  };
}
export default connect(mapStateToProps)(PreviewLesson);
