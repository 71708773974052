import React, { useState } from "react";
import { checkImage } from "../../constants/checkImage";
import { loadWidthImage } from "../../constants/loadWidthImage";
import ScrollRef from "../../detail-components/ScrollRef";
import jsonParser from "../helper/jsonParser";
import { cacheImage, getCacheImage, getImageUrl } from "../helper/parseImage";
import MainContent1Title from "./MainContent1Title";

const MainContent11Mixed = ({ data, isLast }) => {
  const content = data?.content;
  const contentTexts =
    content.texts !== null &&
    content.texts !== undefined &&
    content.texts?.length > 0
      ? content.texts.map((text, index) =>
          jsonParser(text, index, "MainContent11Context")
        )
      : content.texts;
  const contentImages = content?.images;
  var key = getImageUrl(content?.images[0]);
  const [isLoading, setIsLoading] = useState(() => {
    if (getCacheImage([key]) || !checkImage(content?.images[0])) {
      return false;
    } else {
      return true;
    }
  });
  loadWidthImage(key, (err, img) => {
    cacheImage([key]);
    setIsLoading(false);
  });
  return (
    <div
      className={`${
        isLoading ? "is-hidden" : ""
      } has-text-centered has-text-kurio-main-color-black`}
    >
      {!isLast && !isLoading && <ScrollRef />}
      {isLoading && <div>Loading</div>}
      {data?.title && !isLoading && <MainContent1Title title={data?.title} />}
      <div
        className={`is-flex is-flex-direction-column is-flex-gap-8 is-justify-content-center is-align-items-center`}
      >
        {((content?.texts !== null &&
          content?.texts !== undefined &&
          content?.texts?.length > 0) ||
          (content?.image !== null &&
            content?.image !== undefined &&
            content?.images?.length > 0)) &&
          contentTexts?.length >= contentImages?.length &&
          contentTexts.map((item, index) => (
            <div
              key={index}
              className="is-flex is-flex-direction-column is-flex-gap-8 is-justify-content-center is-align-items-center"
            >
              {contentTexts !== null &&
                contentTexts !== undefined &&
                content?.texts[index]?.length > 0 && (
                  <div
                    className={`${isLoading ? "is-hidden" : ""}`}
                    key={index}
                    style={{ maxWidth: "40rem", fontSize: "1.25rem" }}
                  >
                    {item}
                  </div>
                )}
              {contentImages !== null &&
                contentImages !== undefined &&
                contentImages[index]?.length > 0 && (
                  <>
                    <img
                      src={getImageUrl(contentImages[index])}
                      alt={contentImages[index]}
                    />
                  </>
                )}
            </div>
          ))}
        {contentTexts?.length < contentImages?.length &&
          contentImages.map((item, index) => (
            <div
              key={index}
              className="is-flex is-flex-direction-column is-flex-gap-8 is-justify-content-center is-align-items-center"
            >
              {contentTexts !== null &&
                contentTexts !== undefined &&
                content?.texts[index]?.length > 0 && (
                  <div
                    className={`${isLoading ? "is-hidden" : ""}`}
                    key={index}
                    style={{ maxWidth: "40rem", fontSize: "1.25rem" }}
                  >
                    {contentTexts[index]}
                  </div>
                )}
              {contentImages !== null &&
                contentImages !== undefined &&
                item?.length > 0 && (
                  <>
                    <img src={getImageUrl(item)} alt={item} />
                  </>
                )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default MainContent11Mixed;
