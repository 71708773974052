import React from "react";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";

const ResultPayment = ({handleChangeShowPhone}) => {
  return (
    <div
      className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
      style={{ gap: "2.5rem", height: "30rem" }}
    >
      <div>
        <p className="has-text-kurio-main-color-black has-text-weight-normal has-text-centered" style={{fontSize:"1.125rem"}}>
          Xin hãy liên lạc với bộ phận chăm sóc khách hàng qua Zalo hoặc qua số
          hotline <b>0971 500 120</b> và làm theo hướng dẫn để hoàn thành thủ tục xác nhận
          chuyển khoản trực tiếp và thực hiện nâng cấp tài khoản VIP thành công.
        </p>
      </div>
      <div className="is-flex is-flex-direction-column" style={{ gap: "1rem" }}>
        <PrimaryButtonPageLevel
        onClick={()=>handleChangeShowPhone(true)}
          style={{ height: "4rem", borderRadius: "1rem" }}
        >
          Gọi Điện
        </PrimaryButtonPageLevel>
        <PrimaryButtonPageLevel
        onClick={()=>handleChangeShowPhone(true)}
          style={{ height: "4rem", borderRadius: "1rem" }}
        >
          Chat Zalo
        </PrimaryButtonPageLevel>
      </div>
    </div>
  );
};

export default ResultPayment;
