import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkResetPasswordRequest } from "../config/auth";

const initialState = {
  auth: null,
  status: "idle",
  check: null,
  error:null,
};

export const checkRequestResetPassword = createAsyncThunk(
  "auth/checkRequestResetPassword",
  async (check,thunkAPI) => {
    try {
      const result = await checkResetPasswordRequest(check);
      return {result,check};
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.errorMessage)
    }
    
  }
);
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkRequestResetPassword.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(checkRequestResetPassword.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.check = action.payload.check;
      state.auth = action.payload.result;
    });
    builder.addCase(checkRequestResetPassword.rejected, (state,action) => {
      state.status = "failed";
      state.check = action.payload
    });
  },
});
export const { setStatus } = authSlice.actions;
export const selectStatusAuth = (state) => state.auth.status;
export const selectCheck = (state) => state.auth.check;
export default authSlice.reducer;
