import { checkImage } from "../../constants/checkImage";
import { getCacheImage, getImageUrl } from "./parseImage";

export const getAllImage = (sections, type) => {
  var allImages = [];
  const sectionBackgroundImage = sections.filter(
    (item) => item.backgroundImage?.length > 0
  );
  sectionBackgroundImage?.map((item) =>
    item?.length > 0 ? allImages.push(item) : item
  );
  var array = sections.map((item) => item.slides).flat();
  if (type !=="LESSON") {
    array = sections
  }
  for (let i = 0; i < array.length; i++) {
    if (array[i]?.backgroundImage?.length > 0) {
      allImages.push(array[i].backgroundImage);
    }
    if (array[i]?.content?.backgroundImage?.length > 0) {
      allImages.push(array[i].content.backgroundImage);
    }
    if (array[i].question?.backgroundImage?.length > 0) {
      allImages.push(array[i].question.backgroundImage);
    }
    if (array[i]?.content?.images?.length > 0) {
      array[i].content.images.map((image) =>
        image.length > 0 ? allImages.push(image) : image
      );
    }
    if (array[i]?.prompt?.images?.length > 0) {
      array[i].prompt.images.map((image) =>
        image.length > 0 ? allImages.push(image) : image
      );
    }
    if (array[i].explanation?.images?.length > 0) {
      array[i].explanation?.images.map((image) =>
        image.length > 0 ? allImages.push(image) : image
      );
    }
    if (array[i].question?.choices?.length > 0) {
      array[i].question?.choices
        .filter((image) => checkImage(image))
        .map((item) => (item.length > 0 ? allImages.push(item) : item));
    }
    if (array[i].question?.targets?.length > 0) {
      array[i].question?.targets
        .filter((image) => checkImage(image))
        .map((item) => (item.length > 0 ? allImages.push(item) : item));
    }
  }
  allImages.map((item) => getImageUrl(item));
  const check = allImages.map((item) => getCacheImage(item));
  if (check.length === allImages.length) {
    return check
  }
};
