import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import jsonParser from "../../templates/helper/jsonParser";
import "./SelectLesson.style.css";
const SelectLesson = ({ lessons, handleSelect, height }) => {
  const [checkAll, setCheckAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [elementsWithEllipsis, setElementsWithEllipsis] = useState([]);

  const handleCheckAll = () => {
    setCheckAll(!checkAll);
    setSelected(lessons.map((item) => item._id));
    handleSelect(lessons.map((item) => item._id));
    if (checkAll) {
      setSelected([]);
      handleSelect([]);
    }
  };
  const handleCheck = (_id) => {
    if (selected.includes(_id)) {
      setSelected(selected.filter((item) => item !== _id));
      setCheckAll(false);
      handleSelect(selected.filter((item) => item !== _id));
    } else {
      if (checkAll) {
        setSelected(selected.filter((item) => item !== _id));
        handleSelect(lessons.map((item) => item._id).filter((i) => i !== _id));
      } else {
        if ([...selected, _id].length === lessons?.length) {
          setCheckAll(true);
        } else {
          setCheckAll(false);
        }
        setSelected([...selected, _id]);
        handleSelect([...selected, _id]);
      }
    }
  };
  useEffect(() => {
    const elements = document.querySelectorAll(".ellipsis-text");
    const elementsWithEllipsisArray = [];
    elements.forEach((element) => {
      if (element.scrollHeight > element.clientHeight) {
        elementsWithEllipsisArray.push(element?.innerText);
      }
    });
    setElementsWithEllipsis(elementsWithEllipsisArray);
  }, [lessons]);
  return (
    <div className="is-flex is-flex-direction-column" style={{ gap: "1.5rem" }}>
      <div
        className=" main-content-select columns is-center is-multiline m-auto"
        style={{
          width: "43.75rem",
          gap: "1rem",
          overflowY: "overlay",
          maxHeight: "17.5rem",
        }}
      >
        <div className="is-flex is-flex-direction-row" style={{ gap: "1rem" }}>
          <button
            className="btn-select-all-lesson"
            checked={checkAll}
            onClick={handleCheckAll}
            style={
              checkAll
                ? { backgroundColor: "#0A2A66", color: "#FAFBFC" }
                : { boxShadow: "0.125rem 0.125rem 0rem 0rem #0A2A66" }
            }
          >
            <div
              className="columns is-center is-align-items-center is-justify-content-center"
              style={{
                padding: "0rem 1.5rem 0rem 0.625rem",
                gap: "1.5rem",
                cursor: "pointer",
              }}
            >
              <p
                className="is-narrow"
                style={{
                  padding: "1rem 0",
                  fontSize: "0.875rem",
                }}
              >
                Tất cả chương
              </p>
              <input
                name="checkAll"
                onClick={handleCheckAll}
                onChange={handleCheckAll}
                checked={checkAll}
                type={"checkbox"}
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  backgroundColor: "#FAFBFC",
                  cursor: "pointer",
                }}
              />
            </div>
          </button>

          {lessons &&
            lessons.map((item, index) => {
              if (index === 0) {
                return (
                  <button
                    onClick={() => {
                      handleCheck(item._id);
                    }}
                    key={index}
                    className="column btn-select-lesson"
                    style={
                      checkAll || selected.includes(item._id)
                        ? {
                          color: "#ffffff",
                          backgroundColor: "#0A2A66",
                        }
                        : { boxShadow: "0.125rem 0.125rem 0rem 0rem #0A2A66" }
                    }
                  >
                    <div
                      className="columns is-center is-align-items-center is-justify-content-center is-relative"
                      style={{
                        padding: "0 1.5rem",
                        gap: "1.5rem",
                      }}
                    >
                      <div
                        className="column has-text-weight-bold is-flex is-align-items-center is-flex-direction-row "
                        style={{
                          padding: "1.28125rem 0",
                          width: "13.1875rem",
                          fontSize: "0.875rem",
                        }}
                      >
                        <div
                          className="column has-text-weight-bold is-flex is-align-items-center is-flex-direction-row p-0 tooltip-container"
                          style={{
                            width: "10rem",
                            fontSize: "0.875rem",
                            gap: "1rem",
                          }}
                        >
                          <p
                            style={
                              checkAll || selected.includes(item._id)
                                ? {
                                  color: "#fff",
                                  fontSize: "2rem",
                                }
                                : {
                                  color: "#0A2A66",
                                  fontSize: "2rem",
                                }
                            }
                          >
                            0{index + 1}
                          </p>
                          {elementsWithEllipsis?.length > 0 &&
                            elementsWithEllipsis.some((element) =>
                              element.includes(item.title)
                            ) && (
                              <p
                                className="tooltip-select-lesson has-text-weight-medium has-text-kurio-main-color-black"
                                style={{
                                  position: "absolute",
                                  top: "-0.125rem",
                                  left: "2.75rem",
                                  zIndex: 999,
                                  padding: "0.5rem 0.75rem",
                                  borderRadius: "0.5rem",
                                  width: "18rem",
                                  fontSize: "0.875rem",
                                  lineHeight: "normal",
                                  background: "#fffec8",
                                  filter: "saturate(50%)"
                                }}
                              >
                                {jsonParser(
                                  item.title,
                                  0,
                                  "select-lesson-title"
                                )}
                              </p>
                            )}
                          <p
                            className="is-narrow ellipsis-text"
                            style={
                              checkAll || selected.includes(item._id)
                                ? { color: "#fff" }
                                : { color: "#0A2A66" }
                            }
                          >
                            {item.title &&
                              jsonParser(
                                item.title,
                                index,
                                "select-lesson-title"
                              )}
                          </p>
                        </div>
                      </div>
                      <input
                        onClick={() => {
                          handleCheck(item._id);
                        }}
                        onChange={(e) => {
                          handleCheck(item._id);
                        }}
                        checked={
                          checkAll
                            ? checkAll
                            : selected.includes(item._id)
                              ? true
                              : false
                        }
                        type={"checkbox"}
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          backgroundColor: "#FAFBFC",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </button>
                );
              }
              return true;
            })}
        </div>
        <div className="columns is-multiline p-0 m-0" style={{ gap: "1rem" }}>
          {lessons &&
            lessons.map((item, index) => {
              if (index !== 0) {
                return (
                  <button
                    onClick={() => {
                      handleCheck(item._id);
                    }}
                    key={index}
                    className="column is-narrow btn-select-lesson"
                    style={
                      checkAll || selected.includes(item._id)
                        ? {
                          color: "#ffffff",
                          backgroundColor: "#0A2A66",
                          width: "20.625rem",
                        }
                        : {
                          width: "20.625rem",
                          boxShadow: "0.125rem 0.125rem 0rem 0rem #0A2A66",
                        }
                    }
                  >
                    <div
                      className="columns is-center is-align-items-center is-justify-content-center is-relative"
                      style={{
                        padding: "0 1.5rem",
                        gap: "1.5rem",
                      }}
                    >
                      <div
                        className="column has-text-weight-bold is-flex is-align-items-center is-flex-direction-row "
                        style={{
                          padding: "1.28125rem 0",
                          width: "13.1875rem",
                          fontSize: "0.875rem",
                        }}
                      >
                        <div
                          className="column has-text-weight-bold is-flex is-align-items-center is-flex-direction-row p-0 tooltip-container"
                          style={{
                            width: "10rem",
                            fontSize: "0.875rem",
                            gap: "1rem",
                          }}
                        >
                          <p
                            style={
                              checkAll || selected.includes(item._id)
                                ? {
                                  color: "#fff",
                                  fontSize: "2rem",
                                }
                                : {
                                  color: "#0A2A66",
                                  fontSize: "2rem",
                                }
                            }
                          >
                            0{index + 1}
                          </p>
                          {elementsWithEllipsis?.length > 0 &&
                            elementsWithEllipsis.some((element) =>
                              element.includes(item.title)
                            ) && (
                              <p
                                className="tooltip-select-lesson has-text-weight-medium has-text-kurio-main-color-black"
                                style={{
                                  position: "absolute",
                                  top: "-1rem",
                                  left: "2.75rem",
                                  zIndex: 999,
                                  padding: "0.5rem 0.75rem",
                                  borderRadius: "0.5rem",
                                  width: "18rem",
                                  fontSize: "0.875rem",
                                  lineHeight: "normal",
                                  background: "#fffec8",
                                  filter: "saturate(50%)"
                                }}
                              >
                                {jsonParser(
                                  item.title,
                                  0,
                                  "select-lesson-title"
                                )}
                              </p>
                            )}
                          <p
                            className="is-narrow ellipsis-text"
                            style={
                              checkAll || selected.includes(item._id)
                                ? { color: "#fff" }
                                : { color: "#0A2A66" }
                            }
                          >
                            {item.title &&
                              jsonParser(
                                item.title,
                                index,
                                "select-lesson-title"
                              )}
                          </p>
                        </div>
                      </div>
                      <input
                        onClick={() => {
                          handleCheck(item._id);
                        }}
                        onChange={(e) => {
                          handleCheck(item._id);
                        }}
                        checked={
                          checkAll
                            ? checkAll
                            : selected.includes(item._id)
                              ? true
                              : false
                        }
                        type={"checkbox"}
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          backgroundColor: "#FAFBFC",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </button>
                );
              }
              return true;
            })}
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    lessons: state.grades.lessons,
  };
}
export default connect(mapStateToProps)(SelectLesson);
