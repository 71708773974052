import React, { useState } from "react";
import BackgroundImageFromUrl from "../../parseImage/BackgroundImageFromUrl";

const HelpButton = ({ disabled, isClick, onClick, children }) => {
  var style = {
    width: "2.5rem",
    height: "2.5rem",
    border: "0.0625rem solid #0A2A66",
    borderRadius: "9999rem",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    boxShadow: "none",
  };

  const [defaultState, setDefaultState] = useState(disabled ? false : true);
  const [hoveredState, setHoveredState] = useState(false);
  const [clickedState, setClickedState] = useState(isClick ? isClick : false);
  var objectId = "";
  if (hoveredState || clickedState) {
    objectId = "admin/frontend-images/button/help-button-clicked.svg";
  }
  if (defaultState) {
    objectId = "admin/frontend-images/button/help-button-default.svg";
  }
  const audio = new Audio("/sounds/click.mp3");

  const handleMouseEnter = () => {
    if (!clickedState && !disabled && !hoveredState) {
      setDefaultState(false);
      setHoveredState(true);
    }
  };
  const handleMouseLeave = () => {
    if (!clickedState && !disabled && hoveredState) {
      setDefaultState(true);
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    if (!disabled) {
      audio.play();
      setClickedState(!clickedState);
      setHoveredState(false);
      setDefaultState(clickedState);
      if (onClick) {
        onClick();
      }
    }
  };
  const handleBlur = () => {
    setClickedState(false);
    setDefaultState(true);
  };

  return (
    <div
      className=""
      onBlur={handleBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ maxWidth: "2.5rem", height: "2.5rem" }}
    >
      <BackgroundImageFromUrl
        objectId={objectId}
        className={`${
          defaultState
            ? "is-kurio-main-color-white"
            : hoveredState || clickedState
            ? "is-kurio-main-color-black"
            : ""
        }  button has-text-weight-bold is-uppercase has-text-centered`}
        style={style}
        onClick={handleClick}
        type="button"
      >
        {children}
      </BackgroundImageFromUrl>
    </div>
  );
};

export default HelpButton;
