import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import "./DeleteAccount.css";
import { deleteAccount } from "../../config/auth";
import Cookies from "universal-cookie";
import ScrollRef from "../../detail-components/ScrollRef";
import { clearStateGrade } from "../../reducers/gradesSlice";
import { setClearStateDailyLogout } from "../../reducers/dailyMissionSlice";
import { clearStateRoadMap } from "../../reducers/roadmapSlice";
import { useDispatch } from "react-redux";

const DeleteAccount = () => {
  const [showModal, setShowModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [deleteResult, setDeleteResult] = useState(null);
  const cookies = new Cookies();
  const token = cookies.get("signinUser") || cookies.get("register");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const arrayPath = [
    "/account",
    "/profile",
    "/upgrade",
    "/list-unit",
    "/exercise",
    "/activities",
    "/achievement",
    "/",
  ];

  const handleDelete = () => {
    setShowModal(true);
  };

  const confirmDelete = async () => {
    // Handle the delete operation here
    try {
      const res = await deleteAccount();
      setDeleteResult("success");
      setShowModal(false);
      setShowResultModal(true);
    } catch (error) {
      setDeleteResult("error");
      setShowModal(false);
      setShowResultModal(true);
    }
  };

  const closeModal = () => {
    if (deleteResult === "success") {
      const listCookies = cookies.getAll();
      localStorage.clear();
      dispatch(clearStateGrade());
      dispatch(setClearStateDailyLogout());
      dispatch(clearStateRoadMap())
      Object.keys(listCookies).forEach((cookie) => {
        cookies.remove(cookie);
      });
      arrayPath
        .forEach((path) => {
          if (cookies.get("signinUser", { path: path })) {
            cookies.remove("signinUser", { path: path });
          }
        })
        ?.then(() => {
          window.location.href =
            process.env.REACT_APP_ENV === "development"
              ? process.env.REACT_APP_DEV_SIGNIN
              : process.env.REACT_APP_SIGNIN;
        })
        .catch((error) => {
          console.error("Error removing cookie:", error);
        });
      const check = arrayPath.every((path) =>
        cookies.get("signinUser", { path: path })
      );
      if (!check) {
        window.location.href =
          process.env.REACT_APP_ENV === "development"
            ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
            : process.env.REACT_APP_LANDING_PAGE_URL;
      }
    }
    setShowModal(false);
    setShowResultModal(false);
  };

  return (
    <>
      <ScrollRef />
      <div className="section">
        <div className="container">

          <p className="document-container">
            <b style={{ fontSize: "2rem" }}>&#8226;</b> Công ty cổ phần Ikemso Toàn cầu (Ikemso Global Joint Stock Company) <br />
            <b style={{ fontSize: "2rem" }}>&#8226;</b> Có 2 cách để yêu cầu xoá tài khoản: <br />
            <div style={{ marginLeft: "2rem", fontSize: "1.1rem" }}>
              &#9900; Đăng nhập vào app hoặc web <Link to="/">kurio.vn</Link>, vào phần Tài khoản, chọn Xoá tài khoản (Yêu cầu xoá tài khoản sẽ được xử lý trong 30 ngày) <br />
              &#9900; Gọi điện tới hotline chăm sóc khách hàng qua số 0971 500 120 <br />
            </div>
            <b style={{ fontSize: "2rem" }}>&#8226;</b> Các thông tin thu thập từ khách hàng: <br />
            <div style={{ marginLeft: "2rem", fontSize: "1.1rem" }}>
              &#9900; Số điện thoại <br />
              &#9900; Email <br />
              &#9900; Ảnh đại diện <br />
              &#9900; Lịch sử giao dịch các gói sử dụng <Link to="/">kurio.vn</Link> <br />
              &#9900; Lịch sử đăng nhập <br />
              &#9900; Tên người dùng (tuỳ thuộc người dùng nhập) <br />
              &#9900; Tên cha/mẹ, email cha/mẹ, số điện thoại cha/mẹ (tuỳ thuộc người dùng nhập) <br />
              &#9900; Thông tin trường, khối lớp <br />
            </div>
          </p>

          <div>
            <button className="button btn-back" onClick={() => navigate("/")}>
              Về trang chủ
            </button>

            {token ? (<>
              <button className="button is-danger" onClick={handleDelete}>
                Yêu cầu xóa tài khoản
              </button>
            </>) : ""}
          </div>

          <p>Chú ý: Tất cả các thông tin của bạn sẽ được xoá khỏi Kurio, bạn hãy cân nhắc trước khi thực hiện!</p>

          {showModal && (
            <div className="modal is-active">
              <div className="modal-background" onClick={closeModal}></div>
              <div className="modal-content">
                <div className="box">
                  <h2 className="title">CHÚ Ý</h2>
                  <p>Bạn có chắc chắc muốn xoá tài khoản ?</p>
                  <button className="button is-danger" onClick={confirmDelete}>
                    Có
                  </button>
                  <button className="button" onClick={closeModal}>
                    Không
                  </button>
                </div>
              </div>
              <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
            </div>
          )}

          {/* modal success or error */}
          {showResultModal && (
            <div className="modal is-active">
              <div className="modal-background" onClick={closeModal}></div>
              <div className="modal-content">
                <div className="box">
                  <h2 className="title">{deleteResult === "success" ? "Thành công" : "Lỗi"}</h2>
                  <p>
                    {deleteResult === "success"
                      ? "Yêu cầu xoá tài khoản đã được gửi thành công. Bạn sẽ được đăng xuất sau khi tài khoản của bạn đã được xoá."
                      : "Có lỗi xảy ra khi thực hiện yêu cầu xoá tài khoản của bạn. Vui lòng thử lại sau."}
                  </p>
                  <button className="button" onClick={closeModal}>
                    Close
                  </button>
                </div>
              </div>
              <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DeleteAccount;
