import vi from "date-fns/locale/vi"; // the locale you want
import moment from "moment";
import React, { useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Input.style.css";
registerLocale("vi", vi);

const InputDate = ({ className, name, value, onChange, label }) => {
  const myRef = useRef(null);

  var dateParts = value?.split("/");

  // month is 0-based, that's why we need dataParts[1] - 1
  var newDate =
    value?.length > 0
      ? dateParts[1] + "/" + dateParts[0] + "/" + dateParts[2]
      : null;
  var dateObject = new Date(newDate);

  const [selectedDate, setSelectedDate] = useState(
    new Date(value).toString() === "Invalid Date"
      ? moment(value, "dd/MM/yyyy").toDate().toString() === "Invalid Date"
        ? null
        : dateObject
      : dateObject
  );
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const handleDateChange = (e) => {
    setSelectedDate(e);
    setOpenDatePicker(false);
    if (onChange) {
      if (e !== null) {
        const date = new Date(e);
        onChange(
          name,
          `${date.getDate().toLocaleString("vi-VN", {
            minimumIntegerDigits: 2,
          })}/${(date.getMonth() + 1).toLocaleString("vi-VN", {
            minimumIntegerDigits: 2,
          })}/${date.getFullYear()}`
        );
      } else {
        onChange(name, null)
      }
    }
  };

  return (
    <div
      style={{}}
      className={` is-relative form-outline date-picker is-clickable p-0`}
    >
      {openDatePicker ? (
        <DatePicker
          ref={myRef}
          locale="vi"
          name={name}
          className={`${
            className ? className : "is-secondary-button-clicked-color"
          } form-input `}
          selected={selectedDate}
          value={value?.length > 0 ? dateObject : selectedDate}
          dateFormat="dd/MM/yyyy"
          onChange={(e) => handleDateChange(e)}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          open={true}
        />
      ) : (
        <DatePicker
          ref={myRef}
          locale="vi"
          name={name}
          className={`${
            className ? className : "is-secondary-button-clicked-color"
          } form-input `}
          selected={selectedDate}
          value={value?.length > 0 ? dateObject : selectedDate}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          dateFormat="dd/MM/yyyy"
          onChange={(e) => handleDateChange(e)}
        />
      )}
      <label className="form-label">{label}</label>

      <span
        onClick={() => setOpenDatePicker(true)}
        onBlur={() => setOpenDatePicker(false)}
        style={{
          top: "1.875rem",
          right: "1.5rem",
          width: "1.125rem",
          height: "1.25rem",
          zIndex: 99,
          position: "absolute",
        }}
        className="is-clickable"
      >
        <img src="/images/input/date-picker.svg" alt="loading" />
      </span>
    </div>
  );
};

export default InputDate;
