/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import SecondaryButtonQuestion from "../../components/button/secondary-button/SecondaryButtonQuestion";
import TertiaryButtonQuestion from "../../components/button/tertiary-button/TertiaryButtonQuestion";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import TagCheckMultipleResponse from "../../components/question/TagCheckMultipleResponse";
import ConstantStrings from "../../constants/ConstantStrings";
import { checkImage } from "../../constants/checkImage";
import { loadWidthImage } from "../../constants/loadWidthImage";
import {
  setQuestionState,
  setQuestionVisible,
} from "../../reducers/questionsSlice";
import { cachesQuestion, getCachesQuestion } from "../helper/cacheQuestion";
import { sumWithButton } from "../helper/calculateWidthButton";
import jsonParser from "../helper/jsonParser";

function MultipleResponse({
  sections,
  question,
  explanation,
  backgroundImage,
  section,
  slide,
  setScrollTop,
  submitLesson,
  nextSection,
}) {
  const cache = getCachesQuestion(section, slide);
  const dispatch = useDispatch();
  var questionTexts = question.texts
    ? question.texts.map((text, index) => jsonParser(text, index))
    : question.texts;
  var questionChoices = question.choices;
  var questionSolutions = question.solutions;
  //check if explanation is null
  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      explanation.texts.some((item) => item?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      explanation.images.some((item) => item?.length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }

  //create a state variable to store the selected answers
  var [selected, setSelected] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.selected;
    } else {
      return [];
    }
  });
  //create a state variable to store the checking state
  var [checkingState, setCheckingState] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.checkingState;
    } else {
      return false;
    }
  });
  //create a state variable to store the explanation state
  var [explanationState, setExplanationState] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.explanationState;
    } else {
      return false;
    }
  });
  const [allCorrect, setAllCorrect] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.allCorrect;
    } else {
      return false;
    }
  });
  //get max length of a choice
  var maxLength = 0;
  for (var i = 0; i < questionChoices.length; i++) {
    if (questionChoices[i].length > maxLength) {
      maxLength = questionChoices[i].length;
    }
  }
  const [imgWidth, setImgWidth] = useState(() => {
    if (cache?.imgWidth) {
      return cache.imgWidth;
    } else {
      return 0;
    }
  });
  const [widthButton] = useState(
    checkImage(questionChoices[0]) ? 0 : maxLength >= 11 ? 20 : 10
  );
  const [maxWidth, setMaxWidth] = useState(() => {
    if (cache?.maxWidth) {
      return cache.maxWidth;
    } else {
      return 60;
    }
  });
  const [isLoading, setIsLoading] = useState(
    imgWidth === 0 && checkImage(questionChoices[0]) ? true : false
  );
  var backgroundStyle = {
    borderRadius: "0 0 1rem 1rem",
  };
  if (
    backgroundImage !== null &&
    backgroundImage &&
    checkImage(backgroundImage)
  ) {
    backgroundStyle = {
      ...backgroundStyle,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  }
  let buttonStyle = {};
  if (maxLength <= 10 && !checkImage(questionChoices[0])) {
    buttonStyle = {
      width: "10rem",
      padding: "0.75rem 1rem",
      height: "3rem",
      opacity: 1,
      fontWeight: "bold",
      fontSize: "1.125rem",
    };
  } else {
    if (checkImage(questionChoices[0])) {
      buttonStyle = {
        maxWidth: "20rem",
        width: `${imgWidth !== 0 ? `${imgWidth + 0.125 + 2}rem` : "auto"}`,
        height: "auto",
        padding: "1rem",
        whiteSpace: "pre-wrap",
        outline: "0.0625rem solid",
        opacity: 1,
        borderRadius: "1.5rem",
        fontWeight: "bold",
        fontSize: "1.125rem",
      };
    } else {
      buttonStyle = {
        width: "20rem",
        height: "auto",
        padding: "0.75rem 1.25rem",
        whiteSpace: "pre-wrap",
        outline: "0.0625rem solid",
        opacity: 1,
        fontWeight: "bold",
        fontSize: "1.125rem",
      };
    }
  }

  var selectedStyle = {
    ...buttonStyle,
    outline: "0.125rem solid",
  };

  var iconStyle = {
    zIndex: 100,
    position: "absolute",
    top: "0.7rem",
    width: "1.5rem",
    height: "1.5rem",
    left: "-0.7rem",
  };

  //McqButton component
  const divRef = useRef(null);
  // const handleSetWidthImage = (width) => {
  //   setImgWidth(width);
  // };
  const McqButton = ({ index, children }) => {
    const buttonRef = useRef(null);
    var imageUrl = ConstantStrings.IMAGE_URL + encodeURIComponent(children);
    imageUrl = imageUrl.replace(/\(/g, '%28').replace(/\)/g, '%29')
    if (index === 0 && checkImage(children) && imgWidth === 0) {
      loadWidthImage(imageUrl, (err, img) => {
        setImgWidth(
          (img.width / ConstantStrings.BASE_REM) *
          ConstantStrings.MULTIPLIERIMAGE
        );
      });
    }
    return (
      //if index in selected, set the style to selectedStyle
      //if index in selected and checkingState = true, set the style to correctStyle if the answer is correct, set the style to incorrectStyle if the answer is incorrect
      <div className="is-relative">
        {/* <button id={id} ref={buttonRef} className="button" style={index === selected ? (checkingState ? (questionSolutions[selected] === true ? correctStyle : incorrectStyle) : selectedStyle) : buttonStyle} onClick={() => handleSelect(index)} disabled={checkingState}>
                    {children}
                </button> */}
        {selected.includes(index) && checkingState ? (
          questionSolutions[index] === true && allCorrect ? (
            <img
              src="/images/common/greenV.svg"
              style={iconStyle}
              alt="loading"
            />
          ) : (
            <>
              <div style={iconStyle} className="icon"></div>
            </>
          )
        ) : (
          <>
            <div
              style={{
                ...iconStyle,
                width: "0.75rem",
                height: "0.75rem",
              }}
              className="icon"
            ></div>
          </>
        )}
        <button
          ref={buttonRef}
          className={`${selected.includes(index)
            ? checkingState && allCorrect
              ? questionSolutions[index] === true
                ? "has-background-kurio-green-light-3 is-kurio-green-main-accent has-text-kurio-green-main-accent"
                : "has-background-kurio-red-light-3 is-kurio-red-main-accent has-text-kurio-red-main-accent"
              : "has-background-kurio-purple-light-3 is-kurio-purple-main-accent has-text-kurio-purple-main-accent"
            : checkingState
              ? " is-kurio-gray-main-accent has-text-kurio-gray-main-accent"
              : "has-text-kurio-main-color-black is-kurio-main-color-black"
            } button is-shadowless has-text-weight-bold is-outlined has-background-kurio-main-color-white`}
          style={
            selected.includes(index) && !checkingState
              ? selectedStyle
              : buttonStyle
          }
          onClick={() => handleSelect(index)}
          disabled={checkingState}
        >
          {!children.slice(0, 2).includes("@@") && checkImage(children) ? (
            <>
              <img src={imageUrl} alt="loading" />
            </>
          ) : (
            jsonParser(children)
          )}
        </button>
      </div>
    );
  };

  //mapping each choice to a component
  const MapChoices = questionChoices.map((choice, index) => {
    return (
      //id = "auto-height" if the choice is longer than 32 characters
      <div
        className="column is-narrow is-centered"
        style={{ padding: "0.5rem 0.75rem" }}
        key={index}
      >
        <McqButton index={index}>{choice}</McqButton>
      </div>
    );
  });

  //create a function to handle the selection of an answer
  //when an answer is selected, add the index of the answer to the selected state variable
  const handleSelect = (index) => {
    if (selected.includes(index)) {
      setSelected(selected.filter((item) => item !== index));
    } else {
      setSelected([...selected, index]);
    }
  };

  //create a function to handle the checking of the answer
  //when the answer is checked, set the checking state variable to true
  //if all selected answers correct, alert "Correct!", change the background color of the selected answer to green
  //if not all selected answer correct, alert "Incorrect!", change the background color of the selected answer to red
  const handleCheck = () => {
    var correctFlag = true;
    var countCorrectANswer = 0;
    if (selected.length > 0) {
      setCheckingState(true);
      for (var i = 0; i < selected.length; i++) {
        if (questionSolutions[selected[i]] !== true) {
          correctFlag = false;
        }
      }
      for (let i = 0; i < questionSolutions.length; i++) {
        if (questionSolutions[i] === true) {
          countCorrectANswer++;
        }
      }
      if (selected.length !== countCorrectANswer) {
        correctFlag = false;
      }
    } else {
      // alert("Please select an answer!");
    }

    if (correctFlag) {
      // alert("Correct!");
      setAllCorrect(true);
    } else {
      // alert("Incorrect!");
    }
  };

  //create a function to handle the reset of the question
  //when the question is reset, set the selected state variable to -1
  //set the checking state variable to false
  const handleReset = () => {
    setSelected([]);
    setCheckingState(false);
    setAllCorrect(false);
  };

  //create a function to handle the showing of the explanation
  const handleShowExplanation = () => {
    setExplanationState(true);
  };

  const showNextSlide = () => {
    dispatch(
      setQuestionState({
        section: section,
        index: slide,
        state: {
          selected: selected,
          checkingState: checkingState,
          explanationState: explanationState,
          allCorrect: allCorrect,
        },
      })
    );
    submitLesson(section, slide);
    if (sections[section].slides[slide].isLast) {
      nextSection();
    } else {
      dispatch(setQuestionVisible({ section: section, index: slide + 1 }));
    }

    setScrollTop(false)
  };
  const McqImageWrap = () => {
    return (
      <>
        {questionChoices.length === 4 &&
          sumWithButton("IMAGE", imgWidth, 4) >= maxWidth ? (
          <div>
            <div className="columns is-centered p-0 m-0">
              {MapChoices[0]}
              {MapChoices[1]}
            </div>
            <div className="columns is-centered p-0 m-0">
              {MapChoices[2]}
              {MapChoices[3]}
            </div>
          </div>
        ) : questionChoices.length === 5 ? (
          <>
            {sumWithButton("IMAGE", imgWidth, 5) >= maxWidth ? (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[2]}
                  {MapChoices[3]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[4]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                  {MapChoices[2]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[3]}
                  {MapChoices[4]}
                </div>
              </div>
            )}
          </>
        ) : questionChoices.length === 6 ? (
          <>
            {sumWithButton("IMAGE", imgWidth, 6) >= maxWidth ? (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[2]}
                  {MapChoices[3]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[4]}
                  {MapChoices[5]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                  {MapChoices[2]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[3]}
                  {MapChoices[4]}
                  {MapChoices[5]}
                </div>
              </div>
            )}
          </>
        ) : questionChoices.length === 3 ? (
          <>
            {sumWithButton("IMAGE", imgWidth, 3) >= maxWidth ? (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[2]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                  {MapChoices[2]}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="columns is-multiline is-center p-0 m-0">
            {MapChoices}
          </div>
        )}
      </>
    );
  };
  useEffect(() => {
    if (imgWidth !== 0) {
      cachesQuestion(section, slide, imgWidth, maxWidth);
      setIsLoading(false);
    }
    if (divRef.current.clientWidth / ConstantStrings.BASE_REM !== maxWidth) {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    };
    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [maxWidth, imgWidth]);
  return (
    <div ref={divRef}>
      <BackgroundImageFromUrl
        style={backgroundStyle}
        objectId={backgroundImage}
        className={`${!checkImage(backgroundImage)
          ? "has-background-kurio-main-color-light-gray"
          : ""
          }`}
      >
        {isLoading && <div className="has-text-centered">Loading</div>}
        {!explanationState && (
          <div
            className={`${isLoading ? "is-hidden" : ""
              } question-container is-flex is-flex-direction-column is-align-items-center has-text-kurio-main-color-black`}
            style={{
              padding: "1.5rem 2rem",
              gap: "1rem",
            }}
          >
            {
              //render questionTexts if its array has length greater than 0
              questionTexts !== null &&
              questionTexts !== undefined &&
              questionTexts?.length > 0 && (
                <div
                  className=" has-text-centered m-auto"
                  style={{ maxWidth: "40rem" }}
                >
                  {questionTexts}
                </div>
              )
            }
            {checkImage(questionChoices[0]) ? (
              <>
                <McqImageWrap />
              </>
            ) : (
              <>
                {questionChoices.length === 4 &&
                  sumWithButton("TEXT", widthButton, 4) >= maxWidth ? (
                  <div>
                    <div className="columns is-centered p-0 m-0">
                      {MapChoices[0]}
                      {MapChoices[1]}
                    </div>
                    <div className="columns is-centered p-0 m-0">
                      {MapChoices[2]}
                      {MapChoices[3]}
                    </div>
                  </div>
                ) : // if questionChoices has length 5, render the choices in a 3x2 grid
                  questionChoices.length === 5 ? (
                    <>
                      {sumWithButton("TEXT", widthButton, 5) >= maxWidth ? (
                        <div>
                          <div className="columns is-centered p-0 m-0">
                            {MapChoices[0]}
                            {MapChoices[1]}
                          </div>
                          <div className="columns is-centered p-0 m-0">
                            {MapChoices[2]}
                            {MapChoices[3]}
                          </div>
                          <div className="columns is-centered p-0 m-0">
                            {MapChoices[4]}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="columns is-centered p-0 m-0">
                            {MapChoices[0]}
                            {MapChoices[1]}
                            {MapChoices[2]}
                          </div>
                          <div className="columns is-centered p-0 m-0">
                            {MapChoices[3]}
                            {MapChoices[4]}
                          </div>
                        </div>
                      )}
                    </>
                  ) : // if questionChoices has length 6, render the choices in a 3x3 grid
                    questionChoices.length === 6 ? (
                      <>
                        {sumWithButton("TEXT", widthButton, 6) >= maxWidth ? (
                          <div>
                            <div className="columns is-centered p-0 m-0">
                              {MapChoices[0]}
                              {MapChoices[1]}
                            </div>
                            <div className="columns is-centered p-0 m-0">
                              {MapChoices[2]}
                              {MapChoices[3]}
                            </div>
                            <div className="columns is-centered p-0 m-0">
                              {MapChoices[4]}
                              {MapChoices[5]}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="columns is-centered p-0 m-0">
                              {MapChoices[0]}
                              {MapChoices[1]}
                              {MapChoices[2]}
                            </div>
                            <div className="columns is-centered p-0 m-0">
                              {MapChoices[3]}
                              {MapChoices[4]}
                              {MapChoices[5]}
                            </div>
                          </div>
                        )}
                      </>
                    ) : questionChoices.length === 3 ? (
                      <>
                        {sumWithButton("TEXT", widthButton, 3) >= maxWidth ? (
                          <div>
                            <div className="columns is-centered p-0 m-0">
                              {MapChoices[0]}
                              {MapChoices[1]}
                            </div>
                            <div className="columns is-centered p-0 m-0">
                              {MapChoices[2]}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="columns is-centered p-0 m-0">
                              {MapChoices[0]}
                              {MapChoices[1]}
                              {MapChoices[2]}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="columns is-multiline is-centered p-0 m-0">
                        {MapChoices}
                      </div>
                    )}
              </>
            )}
            {checkingState && allCorrect && (
              <div>
                <TagCheckMultipleResponse check={true} />
              </div>
            )}
            {checkingState && !allCorrect && (
              <div>
                <TagCheckMultipleResponse check={false} />
              </div>
            )}
          </div>
        )}

        {/* only appears when explanationState is true */}
        {explanationState && (
          <div
            className="explanation-container has-text-kurio-main-color-black is-flex is-flex-direction-column is-align-items-center "
            style={{
              gap: "1.5rem",
              fontSize: "1.25rem",
              padding: " 2rem 2rem 1.5rem",
            }}
          >
            {((explanationTexts !== null &&
              explanationTexts !== undefined &&
              explanationTexts?.length > 0) ||
              (explanationImages !== null &&
                explanationImages !== undefined &&
                explanationImages?.length > 0)) &&
              explanation.texts?.length >= explanation.images?.length && (
                <>
                  {explanation.texts.map((item, index) => (
                    <div
                      key={index}
                      className="is-flex is-flex-direction-column is-align-items-center"
                      style={{ gap: "1.5rem" }}
                    >
                      {explanationTexts !== null &&
                        explanationTexts !== undefined &&
                        item?.length > 0 && (
                          <div key={item}>{explanationTexts[index]}</div>
                        )}
                      {/* only appears when explanationImages has length greater than 0 */}
                      {explanationImages !== null &&
                        explanationImages !== undefined &&
                        explanationImages[index]?.length > 0 && (
                          <ImageFromUrl objectId={explanationImages[index]} />
                        )}
                    </div>
                  ))}
                </>
              )}
            {((explanationTexts !== null &&
              explanationTexts !== undefined &&
              explanationTexts?.length > 0) ||
              (explanationImages !== null &&
                explanationImages !== undefined &&
                explanationImages?.length > 0)) &&
              explanation.texts?.length < explanation.images?.length && (
                <>
                  {explanationImages.map((item, index) => (
                    <div
                      key={item}
                      className="is-flex is-flex-direction-column is-align-items-center"
                      style={{ gap: "1.5rem" }}
                    >
                      {explanationTexts !== null &&
                        explanationTexts !== undefined &&
                        explanation.texts[index]?.length > 0 && (
                          <div key={index}>{explanationTexts[index]}</div>
                        )}
                      {/* only appears when explanationImages has length greater than 0 */}
                      {explanationImages !== null &&
                        explanationImages !== undefined &&
                        item?.length > 0 && (
                          <ImageFromUrl objectId={explanationImages[index]} />
                        )}
                    </div>
                  ))}
                </>
              )}
          </div>
        )}

        <div
          className={` is-flex is-flex-direction-row ${!(slide + 1 > sections[section].slides.length - 1
            ? sections[section].isFinished
            : sections[section].slides[slide + 1].visible)
            ? checkingState &&
              allCorrect === true &&
              explanation?.texts?.every((item) => item?.length <= 0) &&
              explanation?.images?.every((item) => item?.length <= 0)
              ? "is-align-items-center is-justify-content-center"
              : "is-align-items-flex-end is-justify-content-space-between"
            : "is-align-items-center is-justify-content-center"
            }`}
          style={{
            padding: "0 2rem 2rem",
            gap: "1rem",
          }}
        >
          {/* only appears when checkingState is false */}
          {!checkingState && (
            <PrimaryButtonQuestion
              className="button"
              disabled={selected.length <= 0}
              onClick={handleCheck}
            >
              {ConstantStrings.SUBMIT}
            </PrimaryButtonQuestion>
          )}
          {/* only appears when checkingState is true and any selected answer is incorrect */}
          {checkingState && !allCorrect && (
            <PrimaryButtonQuestion className="button" onClick={handleReset}>
              {ConstantStrings.RETRY}
            </PrimaryButtonQuestion>
          )}
          {/* Next and "Xem giai thich" button appears when checkingState is true and all selected answers is correct */}
          <div
            className="is-flex is-flex-direction-row is-align-items-flex-start"
            style={{ gap: "1.5rem" }}
          >
            {checkingState && allCorrect && explanationState === false && (
              <>
                {explanation !== null &&
                  explanation !== undefined &&
                  ((explanationImages !== null &&
                    explanationImages?.length > 0) ||
                    (explanationTexts !== null &&
                      explanationTexts?.length > 0)) && (
                    <SecondaryButtonQuestion
                      className="button"
                      defaultColor={"is-kurio-purple-light-2"}
                      onClick={handleShowExplanation}
                    >
                      {ConstantStrings.SHOW_EXPLANATION}
                    </SecondaryButtonQuestion>
                  )}
              </>
            )}
            {/* "Xem cau hoi" button appears when explanationState is true */}
            {explanationState && (
              <SecondaryButtonQuestion
                className="button"
                defaultColor={"is-kurio-purple-light-2"}
                onClick={() => {
                  setExplanationState(false);
                }}
              >
                {ConstantStrings.SHOW_QUESTION}
              </SecondaryButtonQuestion>
            )}
          </div>
          {checkingState && allCorrect && (
            <div
              className={`${(
                slide + 1 > sections[section].slides.length - 1
                  ? sections[section].isFinished
                  : sections[section].slides[slide + 1].visible
              )
                ? "is-hidden"
                : ""
                }`}
              onClick={showNextSlide}
            >
              <PrimaryButtonQuestion>
                {ConstantStrings.NEXT}
              </PrimaryButtonQuestion>
            </div>
          )}

          {(!allCorrect || !checkingState) && (
            <div
              className={`${(
                slide + 1 > sections[section].slides.length - 1
                  ? sections[section].isFinished
                  : sections[section].slides[slide + 1].visible
              )
                ? "is-hidden"
                : ""
                }`}
            >
              <TertiaryButtonQuestion
                onClick={showNextSlide}
                suffix="admin/frontend-images/button/button-skip-icon"
              >
                {ConstantStrings.SKIP}
              </TertiaryButtonQuestion>
            </div>
          )}
        </div>
      </BackgroundImageFromUrl>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    sections: state.questions.sections,
  };
}

export default connect(mapStateToProps)(MultipleResponse);
