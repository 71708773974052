/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import MainContent10MemoryCard from "../../templates/content/MainContent10MemoryCard";
import MainContent2Image from "../../templates/content/MainContent2Image";
import MainContent3Text from "../../templates/content/MainContent3Text";
import MainContent4ImageTextLarge from "../../templates/content/MainContent4ImageTextLarge";
import MainContent5ImageTextNormal from "../../templates/content/MainContent5ImageTextNormal";
import MainContent6VideoNormal from "../../templates/content/MainContent6VideoNormal";
import MainContent7VideoPopupThumbnail from "../../templates/content/MainContent7VideoPopupThumbnail";
import MainContent8VideoPopup from "../../templates/content/MainContent8VideoPopup";
import MainContent9Conclusion from "../../templates/content/MainContent9Conclusion";
import QPrompt1_1 from "../../templates/content/QPrompt1_1";
import QPrompt1_2 from "../../templates/content/QPrompt1_2";
import QPrompt1_3 from "../../templates/content/QPrompt1_3";
import QPrompt2_1 from "../../templates/content/QPrompt2_1";
import QPrompt2_2 from "../../templates/content/QPrompt2_2";
import QPrompt2_3 from "../../templates/content/QPrompt2_3";
import QPrompt2_4 from "../../templates/content/QPrompt2_4";
import QPrompt3_1 from "../../templates/content/QPrompt3_1";
import QPrompt3_2 from "../../templates/content/QPrompt3_2";
import { getImageUrl } from "../../templates/helper/parseImage";
import DragDrop from "../../templates/practice-elective/DragDrop";
import DragDropMulti from "../../templates/practice-elective/DragDropMulti";
import Gapfill from "../../templates/practice-elective/Gapfill";
import MultipleChoice from "../../templates/practice-elective/MultipleChoice";
import MultipleResponse from "../../templates/practice-elective/MultipleResponse";

const PreviewExerciseLessonContent = ({ data }) => {
  var contentTemplate = data?.template.prompt;
  var questionTemplate = data?.template.question;
  const [image, setImage] = useState({
    backgroundImage: "",
  });
  useEffect(() => {
    if (data?.backgroundImage) {
      setImage({...image, backgroundImage: getImageUrl(data?.backgroundImage)})
    }
    const exerciseState = JSON.parse(localStorage.getItem("exerciseState"));
    if (exerciseState) {
      localStorage.removeItem("exerciseState");
    }
  }, [data?.backgroundImage, image]);
  const backgroundStyle = {
    backgroundSize: "cover",
    backgroundImage: `url(${image.backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "calc(100% - 5rem)",
    maxWidth: "60rem",
  };

  const TemplateComponent = () => {
    switch (contentTemplate) {
      case "QPrompt1_1":
        return <QPrompt1_1 order={data._id} data={data} />;
      case "QPrompt1_2":
        return <QPrompt1_2 order={data._id} data={data} />;
      case "QPrompt1_3":
        return <QPrompt1_3 order={data._id} data={data} />;
      case "QPrompt2_1":
        return <QPrompt2_1 order={data._id} data={data} />;
      case "QPrompt2_2":
        return <QPrompt2_2 order={data._id} data={data} />;
      case "QPrompt2_3":
        return <QPrompt2_3 order={data._id} data={data} />;
      case "QPrompt2_4":
        return <QPrompt2_4 order={data._id} data={data} />;
      case "QPrompt3_1":
        return <QPrompt3_1 order={data._id} data={data} />;
      case "QPrompt3_2":
        return <QPrompt3_2 order={data._id} data={data} />;
      case "MainContent2Image":
        return <MainContent2Image data={data} />;
      case "MainContent3Text":
        return <MainContent3Text data={data} />;
      case "MainContent4ImageTextLarge":
        return <MainContent4ImageTextLarge data={data} />;
      case "MainContent5ImageTextNormal":
        return <MainContent5ImageTextNormal data={data} />;
      case "MainContent6VideoNormal":
        return <MainContent6VideoNormal data={data} />;
      case "MainContent7VideoPopupThumbnail":
        return <MainContent7VideoPopupThumbnail data={data} />;
      case "MainContent8VideoPopup":
        return <MainContent8VideoPopup data={data} />;
      case "MainContent9Conclusion":
        return <MainContent9Conclusion data={data} />;
      case "MainContent10MemoryCard":
        return <MainContent10MemoryCard data={data} />;
      default:
        return <div>Template not found</div>;
    }
  };

  const TemplateQuestion = () => {
    switch (questionTemplate) {
      case "MultipleChoice":
        return (
          <MultipleChoice
            question={data.question}
            explanation={data.explanation}
            backgroundImage={data?.question.backgroundImage}
            isPreview={true}
            questionIndex={data.questionIndex}
          />
        );
      case "MultipleResponse":
        return (
          <MultipleResponse
            question={data.question}
            explanation={data.explanation}
            backgroundImage={data?.question.backgroundImage}
            isPreview={true}
            questionIndex={data.questionIndex}
          />
        );
      case "Gapfill":
        return (
          <Gapfill
            question={data.question}
            explanation={data.explanation}
            backgroundImage={data?.question.backgroundImage}
            isPreview={true}
            questionIndex={data.questionIndex}
          />
        );
      case "DragDrop":
        return (
          <DragDrop
            question={data.question}
            explanation={data.explanation}
            backgroundImage={data?.question.backgroundImage}
            isPreview={true}
            questionIndex={data.questionIndex}
          />
        );
      case "DragDropMulti":
        return (
          <DragDropMulti
            question={data.question}
            explanation={data.explanation}
            backgroundImage={data?.question.backgroundImage}
            isPreview={true}
            questionIndex={data.questionIndex}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className="m-auto p-6" style={backgroundStyle}>
      <div className="">
        <TemplateComponent />
      </div>
      <div className="">
        <TemplateQuestion />
      </div>
    </div>
  );
};

export default PreviewExerciseLessonContent;
