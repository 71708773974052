/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// Still needs to review explanation part
// Revamp color and scaling

import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import SecondaryButtonQuestion from "../../components/button/secondary-button/SecondaryButtonQuestion";
import TertiaryButtonQuestion from "../../components/button/tertiary-button/TertiaryButtonQuestion";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import ConstantStrings from "../../constants/ConstantStrings";
import { checkImage } from "../../constants/checkImage";
import QuestionNumberTag from "../../detail-components/QuestionNumberTag";
import ScrollRef from "../../detail-components/ScrollRef";
import {
  setQuestionState,
  setQuestionVisible
} from "../../reducers/questionsSlice";
import "../../styling/layout-components/MultipleScroll.style.css";
import jsonParser from "../helper/jsonParser";
function VerticalMultipleChoice({
  sections,
  data,
  order,
  question,
  explanation,
  backgroundImage,
  section,
  slide,
  submitLesson,
  nextSection,
}) {
  const dispatch = useDispatch();
  var questionTexts =
    question.texts !== null &&
    question.texts !== undefined &&
    question.texts?.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;
  var questionChoices = question.choices;
  var questionSolutions = question.solutions;
  //check if explanation is null
  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      explanation.texts.some((item) => item?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      explanation.images.some((item) => item?.length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }

  //create a state variable to store the selected answer
  var [selected, setSelected] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.selected;
    } else {
      return -1;
    }
  });
  //create a state variable to store the checking state
  var [checkingState, setCheckingState] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.checkingState;
    } else {
      return false;
    }
  });
  //create a state variable to store the explanation state
  var [explanationState, setExplanationState] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.explanationState;
    } else {
      return false;
    }
  });
  //get max length of a choice
  var maxLength = 0;
  for (var i = 0; i < questionChoices.length; i++) {
    if (questionChoices[i].length > maxLength) {
      maxLength = questionChoices[i].length;
    }
  }
  // const [imgWidth, setImgWidth] = useState(0);
  const [widthButton, setWidthButton] = useState(maxLength >= 11 ? 20 : 10);
  const [maxWidth, setMaxWidth] = useState(60);
  var backgroundStyle = {
    borderRadius: "1rem 0 0 1rem",
    width: "50%",
    height: `35rem`,
    opacity: 1,
  };
  var backgroundLeftStyle = {};
  if (
    backgroundImage[0] !== null &&
    backgroundImage[0] &&
    checkImage(backgroundImage[0])
  ) {
    backgroundLeftStyle = {
      ...backgroundStyle,
      width: "100%",
      // backgroundImage: `url(${memoizedImageData[1][0]})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundLeftStyle = {
      ...backgroundStyle,
      width: "100%",
    };
  }
  if (
    backgroundImage[1] !== null &&
    backgroundImage[1] &&
    checkImage(backgroundImage[1])
  ) {
    backgroundStyle = {
      ...backgroundStyle,
      borderRadius: "0 1rem 1rem 0",
      // backgroundImage: `url(${memoizedImageData[1][1]})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundStyle = {
      ...backgroundStyle,
      borderRadius: "0 1rem 1rem 0",
    };
  }
  let buttonStyle = {};
  if (maxLength <= 10) {
    buttonStyle = {
      width: "10rem",
      padding: "0.75rem 1rem",
      height: "3rem",
      opacity: 1,
      fontWeight: "bold",
      fontSize: "1.125rem",
    };
  } else {
    buttonStyle = {
      width: "20rem",
      height: "auto",
      padding: "0.75rem 1.25rem",
      whiteSpace: "pre-wrap",
      outline: "0.0625rem solid",
      opacity: 1,
      fontWeight: "bold",
      fontSize: "1.125rem",
    };
  }

  var selectedStyle = {
    ...buttonStyle,
    backgroundColor: "#E6E1F5",
    outline: "2px solid #572CD1",
  };

  var iconStyle = {
    zIndex: 100,
    position: "absolute",
    top: "0.7rem",
    width: "1.5rem",
    height: "1.5rem",
    left: "-0.7rem",
  };
  //McqButton component
  var maxWidthButton = 0;
  const divRef = useRef(null);

  const McqButton = ({ id, index, children }) => {
    const buttonRef = useRef(null);
    useEffect(() => {
      if (buttonRef.current.clientWidth > maxWidthButton) {
        maxWidthButton = buttonRef.current.clientWidth;
      }
      if (widthButton < maxWidthButton / ConstantStrings.BASE_REM) {
        // setWidthButton(maxWidthButton / ConstantStrings.BASE_REM);
        buttonRef.current.style.width = `${
          maxWidthButton / ConstantStrings.BASE_REM
        }rem`;
      } else {
        buttonRef.current.style.width = `${widthButton}rem`;
      }
    }, [id]);
    return (
      //if index = selected, set the style to selectedStyle
      //if index = selected and checkingState = true, set the style to correctStyle if the answer is correct, set the style to incorrectStyle if the answer is incorrect
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="is-relative">
          {index === selected && checkingState ? (
            questionSolutions[selected] === true ? (
              <img src="/images/common/greenV.svg" style={iconStyle} alt="loading" />
            ) : (
              <img src="/images/common/redX.svg" style={iconStyle} alt="loading" />
            )
          ) : (
            <div
              className="icon"
              style={{
                ...iconStyle,
                width: "0.75rem",
                height: "0.75rem",
              }}
            ></div>
          )}
          <button
            id={id}
            ref={buttonRef}
            className={`${
              index === selected
                ? checkingState
                  ? questionSolutions[index] === true
                    ? "has-background-kurio-green-light-3 is-kurio-green-main-accent has-text-kurio-green-main-accent"
                    : "has-background-kurio-red-light-3 is-kurio-red-main-accent has-text-kurio-red-main-accent"
                  : "has-background-kurio-purple-light-3 is-kurio-purple-main-accent has-text-kurio-purple-main-accent"
                : "has-background-kurio-main-color-white has-text-kurio-main-color-black is-kurio-main-color-black"
            } button is-shadowless has-text-weight-bold is-outlined`}
            style={
              index === selected && !checkingState ? selectedStyle : buttonStyle
            }
            onClick={() => handleSelect(index)}
            disabled={checkingState}
          >
            {jsonParser(children)}
          </button>
        </div>
      </div>
    );
  };

  //mapping each choice to a component
  const MapChoices = questionChoices.map((choice, index) => {
    return (
      //id = "auto-height" if the choice is longer than 32 characters
      <div
        className="column is-narrow "
        style={{ padding: "0.5rem 0.75rem" }}
        key={index}
      >
        <McqButton
          id={choice.length > 10 && !checkImage(choice) ? "auto-height" : ""}
          index={index}
        >
          {choice}
        </McqButton>
      </div>
    );
  });

  //create a function to handle the selection of an answer
  //when an answer is selected, set the selected state variable to the index of the answer
  const handleSelect = (index) => {
    if (selected === index) {
      setSelected(-1);
    } else {
      setSelected(index);
    }
  };

  //create a function to handle the checking of the answer
  //when the answer is checked, set the checking state variable to true
  //if the selected answer is correct, alert "Correct!", change the background color of the selected answer to green
  //if the selected answer is incorrect, alert "Incorrect!", change the background color of the selected answer to red
  const handleCheck = () => {
    if (selected !== -1) {
      if (questionSolutions[selected] === true) {
        // alert("Correct!");
      } else {
        // alert("Incorrect!");
      }
      setCheckingState(true);
    } else {
      // alert("Please select an answer!");
    }
  };

  //create a function to handle the reset of the question
  //when the question is reset, set the selected state variable to -1
  //set the checking state variable to false
  const handleReset = () => {
    setSelected(-1);
    setCheckingState(false);
  };

  //create a function to handle the showing of the explanation
  const handleShowExplanation = () => {
    setExplanationState(true);
  };

  const showNextSlide = () => {
    dispatch(
      setQuestionState({
        section: section,
        index: slide,
        state: {
          selected: selected,
          checkingState: checkingState,
          explanationState: explanationState,
        },
      })
    );
    submitLesson(section, slide);
    if (sections[section].slides[slide].isLast) {
      nextSection();
    } else {
      dispatch(setQuestionVisible({ section: section, index: slide + 1 }));
    }
  };

  useEffect(() => {
    if (divRef.current.clientWidth / ConstantStrings.BASE_REM !== maxWidth) {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    };

    window.addEventListener("resize", setWidth);

    return () => window.removeEventListener("resize", setWidth);
  }, [maxWidth]);

  //map each choice to a component
  return (
    <div style={{ height: `35rem` }} className="is-flex is-flex-direction-row">
      <ScrollRef />
      <div style={{ width: "50%" }} ref={divRef}>
        <BackgroundImageFromUrl
          style={backgroundLeftStyle}
          objectId={backgroundImage[0]}
          className={`${
            !checkImage(backgroundImage[0])
              ? "has-background-kurio-main-color-light-gray"
              : ""
          }`}
        ></BackgroundImageFromUrl>
      </div>
      <BackgroundImageFromUrl
        style={{
          ...backgroundStyle,
          width: "50%",
          padding: "2rem",
          position: "relative",
        }}
        objectId={backgroundImage[1]}
        className={`${
          !checkImage(backgroundImage[1])
            ? "has-background-kurio-main-color-light-gray"
            : ""
        } is-flex is-flex-direction-column is-justify-content-space-between has-text-left `}
      >
        {!explanationState && (
          <div className="question-container">
            <div>
              {data?.template.question !== null &&
                data?.template.question &&
                data?.template.question.length > 0 && (
                  <div className="mb-4">
                    <QuestionNumberTag
                      number={order === 0 ? data?.order : order}
                    />
                  </div>
                )}
            </div>
            {/* {jsonParser("$$\\begin{aligned} 12 \\times (20 - 15) &= 12 \\times 5 \\\\ &=  \\color{red}{60}\\end{aligned}$$  \\n @@<p style='color:red'>test ok</p>@@ ")} */}
            {
              //render questionTexts if its array has length greater than 0
              questionTexts !== null &&
                questionTexts !== undefined &&
                questionTexts?.length > 0 && (
                  <div
                    className=" has-text-kurio-main-color-black mb-4"
                    style={{
                      lineHeight: "2rem",
                    }}
                  >
                    {questionTexts}
                  </div>
                )
            }

            <div className="">
              {questionChoices.length === 4 &&
              (widthButton + 1.5) * 2 + 4 < maxWidth ? (
                <div>
                  <div className="columns  p-0 m-0">
                    {MapChoices[0]}
                    {MapChoices[1]}
                  </div>
                  <div className="columns  p-0 m-0">
                    {MapChoices[2]}
                    {MapChoices[3]}
                  </div>
                </div>
              ) : // if questionChoices has length 5, render the choices in a 3x2 grid
              questionChoices.length === 5 ? (
                <>
                  {(widthButton + 1.5) * 2 + 4 > maxWidth ? (
                    <div className="columns is-multiline  p-0 m-0">
                      {MapChoices}
                    </div>
                  ) : (
                    <div>
                      <div className="columns p-0 m-0">
                        {MapChoices[0]}
                        {MapChoices[1]}
                      </div>
                      <div className="columns p-0 m-0">
                        {MapChoices[2]}
                        {MapChoices[3]}
                      </div>
                      <div className="columns p-0 m-0">{MapChoices[4]}</div>
                    </div>
                  )}
                </>
              ) : // if questionChoices has length 6, render the choices in a 3x3 grid
              questionChoices.length === 6 ? (
                <>
                  {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                    <div>
                      <div className="columns  p-0 m-0">
                        {MapChoices[0]}
                        {MapChoices[1]}
                      </div>
                      <div className="columns  p-0 m-0">
                        {MapChoices[2]}
                        {MapChoices[3]}
                      </div>
                      <div className="columns  p-0 m-0">
                        {MapChoices[4]}
                        {MapChoices[5]}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="columns  p-0 m-0">
                        {MapChoices[0]}
                        {MapChoices[1]}
                        {MapChoices[2]}
                      </div>
                      <div className="columns  p-0 m-0">
                        {MapChoices[3]}
                        {MapChoices[4]}
                        {MapChoices[5]}
                      </div>
                    </div>
                  )}
                </>
              ) : questionChoices.length === 3 ? (
                <>
                  {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                    <div className="columns is-multiline  p-0 m-0">
                      {MapChoices}
                    </div>
                  ) : (
                    <div>
                      <div className="columns  p-0 m-0">
                        {MapChoices[0]}
                        {MapChoices[1]}
                      </div>
                      <div className="columns  p-0 m-0">{MapChoices[2]}</div>
                    </div>
                  )}
                </>
              ) : (
                <div className="columns is-multiline  p-0 m-0">
                  {MapChoices}
                </div>
              )}
            </div>
          </div>
        )}
        {explanationState && (
          <div className="explanation-container  is-flex is-flex-direction-column">
            <div>
              {data?.template.question !== null &&
                data?.template.question &&
                data?.template.question.length > 0 && (
                  <div className="mb-4">
                    <QuestionNumberTag
                      number={order === 0 ? data?.order : order}
                    />
                  </div>
                )}
            </div>

            <div
              className="main-content-multiple-vertical has-text-kurio-main-color-black is-flex is-flex-direction-column"
              style={{
                height: "16.5rem",
                overflow: "hidden",
                overflowY: "scroll",
                gap: "1rem",
              }}
            >
              {((explanationTexts !== null &&
                explanationTexts !== undefined &&
                explanationTexts?.length > 0) ||
                (explanationImages !== null &&
                  explanationImages !== undefined &&
                  explanationImages?.length > 0)) &&
                explanation.texts?.length >= explanation.images?.length && (
                  <>
                    {explanation.texts.map((item, index) => (
                      <div
                        key={index}
                        className="is-flex is-flex-direction-column is-align-items-center"
                        style={{ gap: "1.5rem" }}
                      >
                        {explanationTexts !== null &&
                          explanationTexts !== undefined &&
                          item?.length > 0 && (
                            <div key={item}>{explanationTexts[index]}</div>
                          )}
                        {/* only appears when explanationImages has length greater than 0 */}
                        {explanationImages !== null &&
                          explanationImages !== undefined &&
                          explanationImages[index]?.length > 0 && (
                            <ImageFromUrl objectId={explanationImages[index]} />
                          )}
                      </div>
                    ))}
                  </>
                )}
              {((explanationTexts !== null &&
                explanationTexts !== undefined &&
                explanationTexts?.length > 0) ||
                (explanationImages !== null &&
                  explanationImages !== undefined &&
                  explanationImages?.length > 0)) &&
                explanation.texts?.length < explanation.images?.length && (
                  <>
                    {explanationImages.map((item, index) => (
                      <div
                        key={item}
                        className="is-flex is-flex-direction-column is-align-items-center"
                        style={{ gap: "1.5rem" }}
                      >
                        {explanationTexts !== null &&
                          explanationTexts !== undefined &&
                          explanation.texts[index]?.length > 0 && (
                            <div key={index}>{explanationTexts[index]}</div>
                          )}
                        {/* only appears when explanationImages has length greater than 0 */}
                        {explanationImages !== null &&
                          explanationImages !== undefined &&
                          item?.length > 0 && (
                            <ImageFromUrl objectId={explanationImages[index]} />
                          )}
                      </div>
                    ))}
                  </>
                )}
            </div>
          </div>
        )}
        <div
          className=" is-inline-flex is-flex-direction-row is-justify-content-space-between is-align-items-flex-end"
          style={{
            height: "9rem",
          }}
        >
          {/* only appears when checkingState is false */}
          {!checkingState && (
            <PrimaryButtonQuestion
              disabled={!(selected !== -1)}
              className="button"
              onClick={handleCheck}
            >
              {ConstantStrings.SUBMIT}
            </PrimaryButtonQuestion>
          )}
          {/* only appears when checkingState is true and the selected answer is incorrect */}
          {checkingState && questionSolutions[selected] === false && (
            <PrimaryButtonQuestion className="button" onClick={handleReset}>
              {ConstantStrings.RETRY}
            </PrimaryButtonQuestion>
          )}
          {/* Next and "Xem giai thich" button appears when checkingState is true and selected answer is correct */}
          <div
            className="is-flex is-flex-direction-column"
            style={{ gap: "1rem" }}
          >
            {checkingState &&
              questionSolutions[selected] === true &&
              explanationState === false && (
                <>
                  {explanation !== null &&
                    explanation !== undefined &&
                    ((explanationImages !== null &&
                      explanationImages !== undefined &&
                      explanationImages.length > 0) ||
                      (explanationTexts !== null &&
                        explanationTexts !== undefined &&
                        explanationTexts.length > 0)) && (
                      <SecondaryButtonQuestion
                        defaultColor={"is-kurio-purple-light-2"}
                        className="button"
                        onClick={handleShowExplanation}
                      >
                        {ConstantStrings.SHOW_EXPLANATION}
                      </SecondaryButtonQuestion>
                    )}
                </>
              )}
            {/* "Xem cau hoi" button appears when explanationState is true */}
            {explanationState && (
              <SecondaryButtonQuestion
                className="button"
                defaultColor={"is-kurio-purple-light-2"}
                onClick={() => {
                  setExplanationState(false);
                }}
              >
                {ConstantStrings.SHOW_QUESTION}
              </SecondaryButtonQuestion>
            )}
            {checkingState && questionSolutions[selected] === true && (
              <div
                className={`${
                  (
                    slide + 1 > sections[section].slides.length - 1
                      ? sections[section].isFinished
                      : sections[section].slides[slide + 1].visible
                  )
                    ? "is-hidden"
                    : ""
                }`}
              >
                <PrimaryButtonQuestion onClick={showNextSlide}>
                  {ConstantStrings.NEXT}
                </PrimaryButtonQuestion>
              </div>
            )}
          </div>
          {((questionSolutions[selected] !== true && checkingState) ||
            !checkingState) && (
            <div
              className={`${
                (
                  slide + 1 > sections[section].slides.length - 1
                    ? sections[section].isFinished
                    : sections[section].slides[slide + 1].visible
                )
                  ? "is-hidden"
                  : ""
              }`}
              style={{ float: "right" }}
            >
              <TertiaryButtonQuestion
                onClick={showNextSlide}
                suffix={"admin/frontend-images/button/button-skip-icon"}
              >
                {ConstantStrings.SKIP}
              </TertiaryButtonQuestion>
            </div>
          )}
        </div>
      </BackgroundImageFromUrl>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    sections: state.questions.sections,
  };
}

export default connect(mapStateToProps)(VerticalMultipleChoice);
