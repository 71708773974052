import { useState } from "react";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import { checkImage } from "../../constants/checkImage";
import { loadWidthImage } from "../../constants/loadWidthImage";
import QuestionNumberTag from "../../detail-components/QuestionNumberTag";
import ScrollRef from "../../detail-components/ScrollRef";
import jsonParser from "../helper/jsonParser";
import { cacheImage, getCacheImage, getImageUrl } from "../helper/parseImage";

function QPrompt1_2({ data, order, isLast }) {
  const content = data?.content || data?.prompt;
  const contentText1 =
    content?.texts[0] !== null &&
      content?.texts[0] !== undefined &&
      content?.texts[0]?.length > 0
      ? jsonParser(content?.texts[0])
      : content?.texts[0];
  const contentText2 =
    content?.texts[1] !== null &&
      content?.texts[1] !== undefined &&
      content?.texts[1]?.length > 0
      ? jsonParser(content?.texts[1])
      : content?.texts[1];
  const [isLoading, setIsLoading] = useState(
    checkImage(content?.images[0])
      ? getCacheImage([content?.images[0]])
        ? false
        : true
      : false
  );
  var key = getImageUrl(content?.images[0]);
  loadWidthImage(key, (err, img) => {
    cacheImage([key]);
    setIsLoading(false);
  });
  return (
    <div>
      {!isLast &&
        !isLoading &&
        <ScrollRef />}

      <BackgroundImageFromUrl
        objectId={content?.backgroundImage}
        className={`${isLoading ? "is-hidden" : ""
          }  has-text-centered m-auto is-flex is-flex-direction-column is-justify-content-center is-align-items-center has-background-kurio-main-color-white has-text-kurio-main-color-black`}
        style={{
          borderRadius: "1rem 1rem 0 0",
          gap: "2rem",
          fontSize: "1.25rem",
          lineHeight: "160%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          maxWidth: "60rem",
          padding: "2rem",
        }}
      >
        <div
          className={`${isLoading ? "is-hidden" : ""} m-auto`}
          style={{ maxWidth: "40rem" }}
        >
          {data?.template.question !== null &&
            data?.template.question &&
            data?.template.question.length > 0 && (
              <div className="mb-4">
                <QuestionNumberTag number={order === 0 ? data?.order : order} />
              </div>
            )}
          {contentText1}
        </div>

        <img
          style={{ borderRadius: "1rem" }}
          src={key}
          alt={content?.images[0]}
        />
        <div
          className={`${isLoading ? "is-hidden" : ""} m-auto`}
          style={{ maxWidth: "40rem" }}
        >
          {contentText2}
        </div>
      </BackgroundImageFromUrl>
    </div>
  );
}

export default QPrompt1_2;
