import React, { useCallback, useEffect, useRef, useState } from "react";
import ConstantStrings from "../../constants/ConstantStrings";
import "./ScrollBar.style.css";
const ScrollBar = ({ children, className, ischeckscroll, ...props }) => {
  const contentRef = useRef(null);
  const scrollTrackRef = useRef(null);
  const scrollThumbRef = useRef(null);
  const observer = useRef(null);
  const [thumbHeight, setThumbHeight] = useState(20);
  const [scrollStartPosition, setScrollStartPosition] = useState(null);
  const [initialScrollTop, setInitialScrollTop] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  function handleResize(ref, trackSize) {
    const { clientHeight, scrollHeight } = ref;
    setThumbHeight(Math.max((clientHeight / scrollHeight) * trackSize, 20));
  }
  // function handleScrollButton(direction) {
  //   const { current } = contentRef;
  //   if (current) {
  //     const scrollAmount = direction === "down" ? 200 : -200;
  //     current.scrollBy({ top: scrollAmount, behavior: "smooth" });
  //   }
  // }
  const handleTrackClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const { current: trackCurrent } = scrollTrackRef;
      const { current: contentCurrent } = contentRef;
      if (trackCurrent && contentCurrent) {
        // setIsScroll(true);
        const { clientY } = e;
        const target = e.target;
        const rect = target.getBoundingClientRect();
        const trackTop = rect.top;
        const thumbOffset = -(thumbHeight / 2);
        const clickRatio =
          (clientY - trackTop + thumbOffset) / trackCurrent.clientHeight;
        const scrollAmount = Math.floor(
          clickRatio * contentCurrent.scrollHeight
        );
        contentCurrent.scrollTo({
          top: scrollAmount,
          behavior: "smooth",
        });
      }
    },
    [thumbHeight]
  );
  const handleThumbPosition = useCallback(() => {
    if (
      !contentRef.current ||
      !scrollTrackRef.current ||
      !scrollThumbRef.current
    ) {
      return;
    }
    const {
      scrollTop: contentTop,
      scrollHeight: contentHeight,
      clientHeight,
    } = contentRef.current;
    const { clientHeight: trackHeight } = scrollTrackRef.current;
    if (clientHeight >= contentHeight) {
      setIsScroll(false);
    } else {
      setIsScroll(true);
    }
    let newTop = (+contentTop / +contentHeight) * trackHeight;
    newTop = Math.min(newTop, trackHeight - thumbHeight);
    const thumb = scrollThumbRef.current;
    thumb.style.top = `${newTop / ConstantStrings.BASE_REM}rem`;
  }, [thumbHeight]);

  const handleThumbMousedown = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    // setIsScroll(true);
    setScrollStartPosition(e.clientY);
    if (contentRef.current) setInitialScrollTop(contentRef.current.scrollTop);
    setIsDragging(true);
  }, []);

  const handleThumbMouseup = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isDragging) {
        setIsDragging(false);
      }
    },
    [isDragging]
  );

  const handleThumbMousemove = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isDragging) {
        const {
          scrollHeight: contentScrollHeight,
          offsetHeight: contentOffsetHeight,
        } = contentRef.current;
        if (scrollStartPosition) {
          const deltaY =
            (e.clientY - scrollStartPosition) *
            (contentOffsetHeight / thumbHeight);
          const newScrollTop = Math.min(
            initialScrollTop + deltaY,
            contentScrollHeight - contentOffsetHeight
          );
          if (contentRef.current) {
            contentRef.current.scrollTop = newScrollTop;
          }
        }
      }
    },
    [initialScrollTop, isDragging, scrollStartPosition, thumbHeight]
  );

  // If the content and the scrollbar track exist, use a ResizeObserver to adjust height of thumb and listen for scroll event to move the thumb
  useEffect(() => {
    if (contentRef.current && scrollTrackRef.current) {
      setTimeout(() => {
        const ref = contentRef.current;
        const { clientHeight: trackSize } = scrollTrackRef?.current;
        observer.current = new ResizeObserver(() => {
          handleResize(ref, trackSize);
        });
        observer.current.observe(ref);
        setIsScroll(false);
        ref.addEventListener("scroll", handleThumbPosition);
        return () => {
          observer.current?.unobserve(ref);
          ref.removeEventListener("scroll", handleThumbPosition);
        };
      }, 500);
    }
  }, [handleThumbPosition, props.question]);

  // Listen for mouse events to handle scrolling by dragging the thumb
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        behavior: 'smooth', top: 0
      })
    }

    if (!props.question.template.question.includes("DragDrop")) {
      document.addEventListener("mousemove", handleThumbMousemove);
    }
    document.addEventListener("mouseup", handleThumbMouseup);
    document.addEventListener("mouseleave", handleThumbMouseup);
    return () => {
      if (!props.question.template.question.includes("DragDrop")) {
        document.removeEventListener("mousemove", handleThumbMousemove);
      }
      document.removeEventListener("mouseup", handleThumbMouseup);
      document.removeEventListener("mouseleave", handleThumbMouseup);
    };
  }, [
    handleThumbMousemove,
    handleThumbMouseup,
    props.question.template.question,
  ]);

  return (
    <div className="custom-scrollbars__container">
      <div className="custom-scrollbars__content" ref={contentRef} {...props}>
        {children}
      </div>
      <div className="custom-scrollbars__scrollbar">
        <div className="custom-scrollbars__track-and-thumb">
          <div
            className={` ${ischeckscroll === undefined
              ? isScroll
                ? "has-background-kurio-purple-light-2"
                : "has-background-kurio-purple-light-2"
              : ischeckscroll === "true"
                ? "has-background-kurio-main-color-white"
                : "has-background-kurio-main-color-white"
              }  custom-scrollbars__track `}
            ref={scrollTrackRef}
            onClick={handleTrackClick}
            style={{ cursor: isDragging ? "grabbing" : "", width: "0.5rem" }}
          ></div>
          <div
            className={
              ischeckscroll === undefined
                ? isScroll
                  ? "custom-scrollbars__thumb"
                  : "custom-scrollbars__thumb"
                : ischeckscroll === "true"
                  ? "custom-scrollbars__thumb-daily"
                  : "custom-scrollbars__thumb-daily"

              // isScroll && ischeckscroll === false
              //   ? "custom-scrollbars__thumb"
              //   : isScroll && ischeckscroll === "true"
              //   ? "custom-scrollbars__thumb-daily"
              //   : "custom-scrollbars__thumb-daily"
            }
            ref={scrollThumbRef}
            onMouseDown={handleThumbMousedown}
            style={{
              cursor: isDragging ? "grabbing" : "grab",
              height: `${thumbHeight / ConstantStrings.BASE_REM}rem`,
              opacity: isScroll ? 1 : 0,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ScrollBar;
