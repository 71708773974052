import React from "react";
import { connect, useDispatch } from "react-redux";
import NextButton from "../../components/button/pagination-button/NextButton";
import PaginationButtonSmall from "../../components/button/pagination-button/PaginationButtonSmall";
import PreviousButton from "../../components/button/pagination-button/PreviousButton";
import SkipToFirstButton from "../../components/button/pagination-button/SkipToFirstButton";
import SkipToLastButton from "../../components/button/pagination-button/SkipToLastButton";
import {
  setQuestionVisible,
  setSeenQuestions,
} from "../../reducers/practiceElectiveSlice";

function PracticeNav({
  questions,
  seenQuestions,
  handleQuestionChange,
  selected,
  IKMCMode
}) {
  const dispatch = useDispatch();
  const handleChange = (val) => {
    handleQuestionChange(val);
  };

  const selectFirst = () => {
    handleChange(0);
  };

  const selectPrev = () => {
    if (selected === 0) {
      return;
    }
    handleChange(selected - 1);
  };

  const selectNext = () => {
    if (selected === questions.length - 1) {
      return;
    }
    if (selected === seenQuestions - 1) {
      dispatch(setSeenQuestions(seenQuestions + 1));
      dispatch(setQuestionVisible(seenQuestions));
    }
    handleChange(selected + 1);
  };

  const selectLast = () => {
    handleChange(seenQuestions - 1);
  };

  const lineStyle = {
    width: "1.25rem",
    height: "0",
    borderTop: "0.25rem dotted #8C9DC1",
  };

  return (
    <div
      className={`${IKMCMode ? "has-background-kurio-blue-light-2":"has-background-kurio-main-color-black"} is-flex is-flex-direction-row is-align-items-center is-justify-content-center`}
      style={{
        display: "flex",
        gap: "1rem",
        flexWrap: "nowrap",
        width: "51rem",
        position: "fixed",
        height:"5.8125rem",
        zIndex: "100",
        bottom: "0rem",
        borderRadius:"2rem 2rem 0 0"
      }}
    >
      <SkipToLastButton onClick={selectFirst} disabled={selected === 0} />
      <PreviousButton onClick={selectPrev} disabled={selected === 0} />
      {questions && (
        <div
          className="column  is-three-quarters mx-0 px-0 has-background-kurio-main-color-white"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexWrap: "nowrap",
            borderRadius: "2rem",
            border: IKMCMode ? "0.25rem solid #BCD9F1" :"0.25rem solid #C4B5F2",
            padding: "0.75rem 2rem",
            height: "3.5rem",
            width: "32.5rem",
          }}
        >
          {selected >= 7 && <div style={lineStyle}></div>}
          {questions
            .filter((q, index) => {
              if (seenQuestions <= 7 || selected < 6) {
                return index < seenQuestions && index < 7;
              } else {
                return index >= selected - 6 && index <= selected;
              }
            })
            .map((question, index) => (
              <div
                key={question.questionIndex}
                className="is-flex is-flex-direction-row is-align-items-center"
              >
                <PaginationButtonSmall
                  disabled={question.visible ? false : true}
                  isClick={
                    question.visible && selected === question.questionIndex
                  }
                  isCorrect={question.isCorrect}
                  onClick={() => handleChange(question.questionIndex)}
                  key={index}
                >
                  {question.questionIndex + 1}
                </PaginationButtonSmall>
                {question.questionIndex < selected && (
                  <div style={{ ...lineStyle, width: "2.5rem" }}></div>
                )}
                {question.questionIndex >= selected &&
                  question.questionIndex < seenQuestions - 1 && (
                    <div style={{ ...lineStyle, width: "2.5rem" }}></div>
                  )}
              </div>
            ))}
        </div>
      )}
      <NextButton
        onClick={selectNext}
        disabled={
          questions[selected].isLast || !questions[selected + 1].visible
        }
      />
      <SkipToFirstButton
        onClick={selectLast}
        disabled={selected === seenQuestions - 1}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    questions: state.practiceElective.questions,
    seenQuestions: state.practiceElective.seenQuestions,
  };
}

export default connect(mapStateToProps)(PracticeNav);
