export const sumWithButton = (typeButton, width, numberButton) => {
  var sum = width;
  if (typeButton === "IMAGE") {
    if (numberButton === 4) {
      //0.125: border * 2, 2: padding*2, 1.5: padding ngoài * 2 , 4: padding box button * 2
      sum = (width + 0.125 + 2 + 1.5) * 4 + 4;
    } else if (numberButton >= 3 && numberButton <= 6) {
      sum = (width + 0.125 + 2 + 1.5) * 3 + 4;
    }
  } else {
    if (numberButton === 4) {
      sum = (width + 0.125 + 1.5) * 4 + 4;
    } else if (numberButton >= 3 && numberButton <= 6) {
      sum = (width + 0.125 + 1.5) * 3 + 4;
    }
  }
  return sum;
};