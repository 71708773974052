import React, { useEffect } from "react";

function ScrollRef() {
  const tagRef = React.useRef(null);
  // const scrollToRef = () => tagRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  useEffect(() => {
    setTimeout(() => {
      // tagRef.current?.scrollIntoView({ behavior: 'smooth', top: 0 })
      tagRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, 300);
  }, []);

  return <div ref={tagRef}></div>;
}

export default ScrollRef;
