import React, { useState } from "react";
import BackgroundImageFromUrl from "../../parseImage/BackgroundImageFromUrl";

const CompanionChatAndHelp = ({ onClick, disabled, children }) => {
  const [defaultState, setDefaultState] = useState(true);
  const [hoveredState, setHoveredState] = useState(false);
  const [clickedState, setClickedState] = useState(false);
  const audio = new Audio("/sounds/click.mp3");

  var style = {
    minWidth: "10rem",
    height: "3.75rem",
    color: "#A33829",
    borderRadius: "5rem 6.5rem 3rem 5rem",
    position: "absolute",
    left: "0%",
    right: "21.57%",
    top: "20.45%",
    bottom: "11.36%",
    border: "none",
    boxShadow: "inset 0rem 0.25rem 0.75rem rgba(10, 42, 102, 0.25)",
  };
  var catHeadStyle = {
    minWidth: "6.125rem",
    position: "absolute",
    top: "0%",
    right: "0%",
    bottom: "0.19%",
    left: "51.96%",
    zIndex: "100",
    height: "5.5rem",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };
  var objectId = "";
  if (defaultState) {
    style = {
      ...style,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    };
    objectId = "admin/frontend-images/button/companion-chat-and-help-bg.svg";
  }
  var shadow = {
    maxWidth: "12.75rem",
    height: "5.4rem",
    filter: "drop-shadow(0rem 0.375rem 0.75rem rgba(10, 42, 102, 0.25))",
  };

  const handleMouseEnter = () => {
    if (!clickedState && !disabled) {
      setDefaultState(false);
      setHoveredState(true);
    }
  };
  const handleMouseLeave = () => {
    if (!clickedState && !disabled) {
      setDefaultState(true);
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    if (!disabled) {
      audio.play();
      setClickedState(!clickedState);
      setHoveredState(false);
      setDefaultState(clickedState);
      if (onClick) {
        onClick();
      }
    }
  };
  return (
    <div
      className="is-relative is-clickable"
      style={shadow}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <BackgroundImageFromUrl
        objectId={"admin/frontend-images/button/cat-head.svg"}
        style={catHeadStyle}
      ></BackgroundImageFromUrl>
      <BackgroundImageFromUrl
        objectId={objectId}
        className={`${
          defaultState
            ? "is-companion-help-and-chat-default-color"
            : hoveredState
            ? "is-companion-help-and-chat-hovered-color"
            : clickedState
            ? "is-companion-help-and-chat-clicked-color"
            : ""
        } button main-color has-text-weight-bold is-uppercase has-text-centered`}
        style={style}
        type="button"
      >
        {children}
      </BackgroundImageFromUrl>
    </div>
  );
};

export default CompanionChatAndHelp;
