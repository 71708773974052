import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ScrollRefWrapper from '../../detail-components/ScrollRefWrapper';

var colorTextH1 = "#572CD1";
var colorWhite = "#FAFBFC";
var colorBlack = "#0A2A66";

const PaymentPolicy = () => {
  const [showPopupDownload, setShowPopupDownload] = useState(true)

  const backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Cuộn mượt mà
    });
  };
  const handleClosePopupDownload = ()=>{
    setShowPopupDownload(false)
  }

  const  getMobileOperatingSystem=()=> {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
  }

  const handleDownloadApp = ()=>{
    var mobileOS = getMobileOperatingSystem();

    if (mobileOS === "Android") {
      window.location.href = "https://play.google.com/store/apps/details?id=vn.kurio.android"
    } else if (mobileOS === "iOS") {
      window.location.href = "https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573"
    }
  }

  return (
    <>
      <ScrollRefWrapper />
      {/* Desktop */}
      <div className='container-landingpage' id='dekstop-landingpage'>
        <section id='header-landingpage' className='fixedHeader' style={{ background: "#F1EEFC", width: "100%" }}>
          <header style={{ width: "100%", height: "6rem", padding: "0rem 2.5rem" }} className={"is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"}>
            <img style={{ width: "9.27144rem", height: "3rem" }} src="/images/landing-page/logo.svg" alt="" />

            <Link to={'/'}>
              <button className='is-flex is-align-items-center is-justify-content-center' style={{ gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "12.6875rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                <p style={{ color: colorWhite, fontSize: "1.25rem", fontWeight: 700, textTransform: "uppercase" }}>Về trang chủ</p>
              </button>
              {/* <button className='btn-landingpage' type="" style={{ width: "15rem", height: "4rem", borderRadius: "2rem", border: "1px solid #0A2A66" }}>
                <p style={{ textTransform: "uppercase", fontSize: "1.5rem", fontWeight: 700, color: colorWhite, lineHeight: "2.25rem" }}>Về trang chủ</p>
              </button> */}
            </Link>
          </header>

        </section>

        <div
          id="landingpage_introduction"
          style={{ padding: "8rem 5rem 0rem", color: "#272266", background: colorWhite, width: "100%" }}
          className="landingpage_desktop landingpage_horizontal-intro-payment-section"
        >
          <h1 style={{ fontSize: "28px", fontWeight: 700, color: colorTextH1 }}>ĐIỀU KHOẢN SỬ DỤNG TÀI KHOẢN KURIO</h1>
          <p style={{ color: "#272266", fontWeight: 400, marginBottom: "1rem" }}>
            Điều khoản sử dụng tài khoản Kurio (sau đây gọi tắt là “Điều khoản
            sử dụng”) là một thỏa thuận/hợp đồng điện tử được thiết lập và
            ràng buộc các quyền và nghĩa vụ giữa CÔNG TY CỔ PHẦN IKEMSO TOÀN
            CẦU (“Công ty”) và Khách hàng khi Khách hàng sử dụng dịch vụ, sản
            phẩm mà Công ty cung cấp. <br /> <br />
            Để truy cập và sử dụng tài khoản Kurio, Khách hàng vui lòng xem
            xét cẩn trọng và xác nhận đồng ý với Điều khoản sử dụng này trước
            khi bắt đầu sử dụng tài khoản Kurio với các điều khoản và điều
            kiện sau đây: <br />
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            1. Các điều khoản về việc sử dụng
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            {" "}
            Với việc sử dụng tài khoản Kurio, Khách hàng đồng ý tuân theo tất
            cả những điều khoản dưới đây:{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Khi đăng ký tài khoản, học
            viên phải cung cấp đầy đủ và chính xác thông tin về Tên, Email, Số
            điện thoại... Những thông tin này sẽ được dùng làm căn cứ để hỗ
            trợ giải quyết các vấn đề phát sinh trong quá trình học viên theo
            học tại Kurio.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Khi tạo tài khoản trên
            Kurio, Khách hàng đồng ý nhận những email cập nhật các tính năng
            và bài viết mới, cũng như những email thông báo các khuyến mãi từ
            Kurio. Kurio sẽ cung cấp đường link ở cuối email để Khách hàng có
            thể hủy đăng ký nhận thông báo đó bằng cách kích vào link đó.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Khách hàng sẽ không truy
            cập Kurio bằng các chương trình tự động.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Trong quá trình sử dụng
            tài khoản Kurio, tất cả những dữ liệu, bao gồm các thông tin về
            kết quả và lịch sử học tập của Khách hàng, tài liệu học tập, hình
            ảnh, âm thanh và tất cả các dữ liệu khác do Khách hàng tạo ra hay
            được thu thập bởi Kurio thông qua việc học của Khách hàng, dù miễn
            phí hay không, sẽ là tài sản tuyệt đối và của riêng Kurio.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Khách hàng sẽ không sao
            chép, phát tán hay phát hành bất kì tài liệu nào của Kurio, dù
            miễn phí hay không, mà không có sự cho phép bằng văn bản của
            Kurio. Tuy nhiên, Khách hàng có thế để đường dẫn (link) đến những
            tài liệu trên Kurio.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Mật khẩu của tài khoản:
            Sau khi đăng ký, học viên được cung cấp tài khoản và mật khẩu. Mật
            khẩu được sử dụng để đăng nhập vào website Kurio.vn và các dịch vụ
            trong hệ thống của Kurio. Học viên có trách nhiệm tự bảo quản mật
            khẩu, nếu mật khẩu bị lộ ra ngoài dưới bất kỳ hình thức nào, Kurio
            sẽ không chịu trách nhiệm về mọi tổn thất phát sinh.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem", marginBottom: "1rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Nếu Khách hàng vi phạm bất
            kì điều khoản sử dụng nào ở trên, Kurio giữ quyền khóa tài khoản
            của Khách hàng vô thời hạn và việc này có thể khiến cho Khách hàng
            không còn có thế sử dụng những sản phẩm hay dịch vụ mà Khách hàng
            đã mua ở Kurio (nếu có).
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            2. Các điều khoản về việc mua hàng/sử dụng dịch vụ
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Khi mua một sản phẩm hay dịch vụ trên Kurio, ví dụ như mua tài
            khoản để học, Khách hàng đồng ý tuân theo tất cả những điều khoản
            sau:{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Những sản phẩm và dịch vụ
            mà Khách hàng đã mua trên Kurio, ví dụ như các tài khoản học, là
            để chỉ mình Khách hàng sử dụng. Khách hàng không được phép để bất
            kì người nào khác đăng nhập vào tài khoản của Khách hàng để sử
            dụng những sản phẩm hay dịch vụ mà Khách hàng đã mua.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Khi thực hiện thanh toán
            trên Kurio, Khách hàng đồng ý sẽ cung cấp những thông tin thanh
            toán xác thực và chính xác nhất.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem", marginBottom: "1rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Nếu Khách hàng vi phạm bất
            kì điều khoản sử dụng nào ở trên, Kurio giữ quyền khóa tài khoản
            của Khách hàng vô thời hạn và việc này có thể khiến cho Khách hàng
            không còn có thế sử dụng những sản phẩm hay dịch vụ mà Khách hàng
            đã mua ở Kurio (nếu có).
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            3. Phương thức thanh toán
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Kurio chấp nhận nhiều hình thức thanh toán khác nhau, bao gồm:{" "}
            <br /> <br />
            a. Thanh toán bằng thẻ quốc tế như Visa/Master được phát hành hợp
            pháp bởi một tổ chức tín dụng tại Việt Nam.
            <br /> <br />
            b. Thanh toán bằng thẻ ATM nội địa có chức năng Internet Banking
            hoặc QR Code được cung cấp bởi một tổ chức tín dụng tại Việt Nam.{" "}
            <br /> <br />
            c. Thanh toán bằng chuyển khoản theo thông tin dưới đây: <br />{" "}
            <br />
            CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU <br /> <br />
            Số tài khoản: 19036816201018 <br /> <br />
            Ngân hàng: TMCP Kỹ thương Việt Nam (Techcombank) - Chi nhánh Ba
            Đình - Hà Nội <br /> <br />
            Ghi chú: Khi chuyển khoản tại mục "Nội dung" Khách hàng ghi rõ: Số
            điện thoại - Họ Tên - Email đăng ký học - Khóa học đăng ký. <br />{" "}
            <br />
            Ví dụ: 0901234567 – Nguyen Van A - nguyevana@gmail.com – Toan tu
            duy <br /> <br />
            <b>Hướng dẫn thanh toán:</b> <br /> <br />
            Sau khi Khách hàng đã đăng ký tài khoản mới trên Kurio hoặc đăng
            nhập vào tài khoản đã đăng ký trên Kurio, Khách hàng thực hiện các
            bước sau để mua khóa học phù hợp và tiến hành thanh toán:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bước 1: Tại trang chủ
            Kurio, nhấn chọn “Nâng cấp” sau đó chọn khóa học mà Khách hàng
            muốn mua.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bước 2: Nhấn chọn “mua
            khóa học”.{" "}
          </p>
          <p><b style={{ lineHeight: "normal", color: "#272266", fontSize: "1rem" }}>“Mua khóa học”: Chỉ có thể truy cập vào một khóa học mà Khách hàng
            đăng ký mua và giới hạn thời gian cụ thể cho từng khóa học.</b></p> <p />
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bước 3: Nhấn chọn “thanh
            toán” để tiến hành thanh toán.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bước 4. Ở màn hình chọn
            Phương thức thanh toán (ví dụ: người dùng chọn phương thức "Thẻ
            ATM") rồi nhấn Thanh toán.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bước 5. Tiến hành nhập đầy
            đủ các thông tin theo yêu cầu và nhấn Thanh toán.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bước 6. Khách hàng sẽ nhận
            được thông báo thanh toán hoàn tất và có thể tiến hành truy cập
            khóa học. Khách hàng vào mục "Tài khoản" để kiểm tra thông tin
            khóa học.{" "}
          </p>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Trường hợp sau khi thanh toán, nếu Khách hàng phát hiện Gói dịch
            vụ có lỗi xin vui lòng thông báo ngay theo số điện thoại 0971 500
            120 của Kurio để được trợ giúp.{" "}
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            4. Toàn bộ thỏa thuận
          </h2>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Tất cả những điều khoản trên là toàn bộ bản hợp đồng giữa Kurio và
            Khách hàng về tất cả những khía cạnh của việc sử dụng tài khoản
            Kurio của Khách hàng. Những điều khoản này sẽ thay thế cho những
            điều khoản ở trước đó.{" "}
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            5. Chính sách hoàn trả
          </h2>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Trong trường hợp Khách hàng muốn hỗ trợ hoàn trả khóa học trên web Kurio, vui lòng liên hệ với chúng tôi theo hotline 0971500120  hoặc email cskh@kurio.vn để được hỗ trợ.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            6. Thông tin liên lạc
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Nếu Khách hàng có câu hỏi nào về những điều khoản này, Khách hàng
            hãy liên lạc với chúng tôi: <br />
            <br />
            CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU <br /> <br />
            Email: cskh@kurio.vn <br /> <br />
            Tel: 0971500120 <br /> <br />
            Địa chỉ trụ sở: số 128 Nguyễn Thái Học, phường Điện Biên, quận Ba
            Đình, Hà Nội <br /> <br />
          </p>
          <h1 style={{ marginBottom: "21px", fontSize: "28px", fontWeight: 700, color: colorTextH1 }}>
            CHÍNH SÁCH BẢO MẬT THÔNG TIN
          </h1>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            1. Chúng tôi thu thập những thông tin gì?
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Chúng tôi thu nhập thông tin từ bạn khi bạn đăng ký trên trang web
            của chúng tôi. <br /> <br />
            Dữ liệu cá nhân mà Kurio có thể thu thập bao gồm, nhưng không giới
            hạn: <br />
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Họ tên{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Địa chỉ email{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Ngày sinh{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Tài khoản ngân hàng và
            thông tin thanh toán{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Số điện thoại{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Giới tính{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Địa chỉ thanh toán{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Thông tin được gửi bởi
            hoặc liên quan đến (các) thiết bị được sử dụng để truy cập vào Các
            Dịch vụ hoặc Nền tảng của chúng tôi.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bất kỳ thông tin nào khác
            về người dùng khi người dùng đăng nhập để sử dụng Các Dịch Vụ hoặc
            Nền tảng của chúng tôi.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Dữ liệu tổng hợp về nội
            dung người dùng sử dụng.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem", marginBottom: "1rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bạn có thể tự chỉnh sửa
            được tên trong trang quản lý tài khoản. Nếu cần đổi email đăng
            nhập hoặc số điện thoại, bạn cần gửi yêu cầu cho chúng tôi và trải
            qua các bước xác thực.{" "}
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>2. Chúng tôi sẽ làm gì với thông tin của bạn?</h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Kurio sử dụng thông tin chúng tôi thu thập cho các mục đích:{" "}
            <br />
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để xem xét và/hoặc xử lý
            đơn đăng ký/giao dịch; đăng ký email của bạn với chúng tôi{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để quản lý việc bạn sử
            dụng dịch vụ của chúng tôi{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để đáp ứng, xử lý, giải
            quyết hoặc hoàn tất một giao dịch và/hoặc đáp ứng các yêu cầu của
            bạn đối với các sản phẩm và dịch vụ nhất định và thông báo cho bạn
            về các vấn đề dịch vụ và các hoạt động tài khoản bất thường{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để thực thi các Điều Khoản
            Dịch Vụ của chúng tôi{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để bảo vệ sự an toàn cá
            nhân và các quyền, tài sản hoặc sự an toàn của người khác{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để nhận dạng và/hoặc xác
            minh{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để giải quyết hoặc tạo
            điều kiện dịch vụ khách hàng, thực hiện các khiếu nại của bạn,
            giải quyết hoặc trả lời bất kỳ thắc mắc nào được gửi bởi (hoặc
            nhằm được gửi bởi) bạn hoặc thay mặt bạn{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để liên hệ với bạn hoặc
            liên lạc với bạn qua điện thoại, tin nhắn văn bản, email nhằm mục
            đích quản trị quan hệ của bạn với chúng tôi{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để gửi thông báo tới bạn
            qua trình duyệt hoặc qua ứng dụng Kurio trên điện thoại{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để tiến hành các hoạt động
            nghiên cứu, phân tích và phát triển (bao gồm, nhưng không giới hạn
            ở, phân tích dữ liệu, khảo sát, phát triển và/hoặc lập đặc tính
            sản phẩm và dịch vụ), để phân tích cách thức bạn sử dụng Các Dịch
            Vụ của chúng tôi, để cải thiện Các Dịch Vụ hoặc sản phẩm của chúng
            tôi và/hoặc để cải thiện trải nghiệm khách hàng của bạn{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Vì mục đích tiếp thị, gửi
            cho bạn qua email, thông tin và tài liệu tiếp thị và quảng bá liên
            quan đến các sản phẩm và/hoặc dịch vụ. Bạn có thể hủy đăng ký nhận
            các thông tin tiếp thị tại bất cứ thời điểm nào bằng cách sử dụng
            chức năng hủy đăng ký email{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem", marginBottom: "1rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để ngăn chặn hoặc điều tra
            bất kỳ hoạt động gian lận, phi pháp, thiếu sót hay hành vi sai
            trái nào, cho dù có liên quan đến việc bạn sử dụng Các Dịch Vụ của
            chúng tôi{" "}
          </p>
          <p />
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>3. Bảo mật</h2>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Bảo mật thông tin cá nhân của Khách hàng là ưu tiên hàng đầu của
            chúng tôi. Các thông tin đăng ký mua hàng của Khách hàng sẽ được
            bảo mật bằng cách sử dụng công nghệ mã hóa an toàn Secure Sockets
            Layer (SSL). Chúng tôi cam kết sẽ cố gắng để đảm bảo an ninh ở mức
            cao nhất cho Khách hàng. Tuy nhiên, Kurio xin nhấn mạnh không có
            phương án nào đảm bảo được tính bảo mật 100%. Nếu bạn có bất kỳ
            câu hỏi về vấn đề bảo mật trên trang web của chúng tôi, bạn có thể
            gửi thắc mắc về email cskh@kurio.vn.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            4. Cookies và công nghệ phân tích dữ liệu khác
          </h2>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Cookies là một lượng nhỏ dữ liệu, được gửi tới trình duyệt của bạn
            từ một trang web và được lưu trữ trên ổ đĩa cứng của máy tính.
            Cookies cho phép trang web hoặc hệ thống của các bên cung cấp dịch
            vụ nhận diện trình duyệt của bạn và lưu lại một số thông tin nhất
            định. Chúng tôi dùng cookies để hiểu và lưu trữ hoạt động của bạn
            và đáp ứng các nhu cầu của bạn tốt hơn cho lần truy cập sau.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            5. Chúng tôi có cung cấp thông tin cho bên khác không?
          </h2>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Chúng tôi không bán, trao đổi, hoặc sử dụng các hình thức thương
            mại khác với những thông tin cá nhân của bạn. Tuy nhiên, thông tin
            không định danh của những người truy cập vào trang web có thể được
            sử dụng và cung cấp cho các bên khác nhằm mục đích marketing,
            quảng cáo hoặc mục đích sử dụng khác.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            6. Xóa tài khoản
          </h2>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Các cá nhân sở hữu tài khoản trên Kurio được quyền xóa bỏ tài
            khoản bất kỳ thời điểm nào. Trong trường hợp Khách hàng muốn xóa
            tài khoản, vui lòng liên hệ với Kurio theo hotline 0971500120 hoặc
            email <a href>cskh@kurio.vn</a> để được hỗ trợ. Ngoài ra, chúng
            tôi chỉ hỗ trợ khôi phục lại tài khoản trong trường hợp nhầm lẫn
            hoặc tài khoản bị tấn công trong thời gian 30 ngày kể từ thời điểm
            xóa. Vui lòng đọc kỹ các thông tin được cung cấp trước khi thực sự
            làm hành động này.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            7. Thông tin liên hệ
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Nếu bạn có thắc mắc, hãy liên hệ với chúng tôi: <br />
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>
            Hotline: 0971500120{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Email: cskh@kurio.vn{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Địa chỉ: số 128 Nguyễn
            Thái Học, phường Điện Biên, quận Ba Đình, Hà Nội.
          </p>
          <p /> <br />
        </div>

        <section id='footer-landingpage' style={{ background: colorWhite, width: "100%", }}>
          <footer className='is-flex is-align-items-start' style={{ gap: "4rem", padding: "4rem" }}>
            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem", width: "20rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Liên hệ</p>
              <div className='is-flex is-flex-direction-column' style={{ gap: "1rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU</p>

                <span style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Giấy chứng nhận Đăng ký Kinh doanh số 0109501325 do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp ngày 19/01/2021.</span>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-gmail.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>contact@ikemso.com</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-phone.svg" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>1900 8157</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-zalo.png" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>0971 500 120</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-address.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "1.5rem" }}>Số 128, phố Nguyễn Thái Học,
                    phường Điện Biên, quận Ba Đình, Hà Nội</p>
                </div>
              </div>
            </div>

            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Chính sách</p>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <Link to={'/payment-methods'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Hình thức thanh toán</p></Link>
                <Link to={'/return-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Vận chuyển - Trả hàng hoàn tiền</p></Link>
                <Link to={'/privacy-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Chính sách bảo mật</p></Link>
              </div>

              <Link to={'/payment-policy'}>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "15rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorWhite, boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                  <img style={{ width: "6.25rem", height: "1.89581rem" }} src="/images/landing-page/image-vnpay.png" alt="" />
                  <p style={{ width: "5.125rem", fontSize: "0.875rem", fontWeight: 700, color: colorBlack, lineHeight: "normal" }}>Chính sách thanh toán</p>
                </div>
              </Link>
            </div>

            <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "3rem" }}>
              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Ứng dụng</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <Link to={'https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573'}>
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho iOS</p></Link>
                  <Link to={'https://play.google.com/store/apps/details?id=vn.kurio.android'}>
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho Android</p></Link>
                </div>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Mạng xã hội</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <Link to={'https://www.facebook.com/kurio.vn'}>
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Facebook</p>
                  </Link>
                  {/* <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Youtube</p> */}
                  <Link to={'https://zalo.me/0971500120'}>
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Zalo</p>
                  </Link>
                </div>
              </div>
            </div>

            <div style={{ height: "24.125rem" }} className='is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center'>
              <Link to={'http://online.gov.vn/Home/WebDetails/110320?AspxAutoDetectCookieSupport=1'}>
                <img style={{ width: "12.5rem", height: "4.75113rem" }} src="/images/landing-page/image-bct.png" alt="" />
              </Link>
              <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "1.5rem" }}>
                <img onClick={() => backToTop()} style={{ width: "5rem", height: "5rem", cursor: "pointer" }} src="/images/landing-page/icon-back-to-top.svg" alt="" />

                <p onClick={() => backToTop()} style={{ fontSize: "1.25rem", color: colorBlack, cursor: "pointer", textTransform: "uppercase", fontWeight: 700, lineHeight: "normal", width: "6rem" }}>Về đầu trang</p>
              </div>
            </div>
          </footer>
        </section>
      </div>

      {/* Tablet */}
      <div className='container-landingpage' id='tablet-landingpage'>
        <section id='tablet-header' className='fixedHeader' style={{ background: "#F1EEFC", width: "100%" }}>
          <header style={{ width: "100%", height: "6rem", padding: "0rem 2.5rem" }} className={"is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"}>
            <img style={{ width: "9.27144rem", height: "3rem" }} src="/images/landing-page/logo.svg" alt="" />

            <Link to={'/'}>
              <button className='is-flex is-align-items-center is-justify-content-center' style={{ gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "12.6875rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                <p style={{ color: colorWhite, fontSize: "1.25rem", fontWeight: 700, textTransform: "uppercase" }}>Về trang chủ</p>
                {/* <img src="/images/landing-page/icon-arrow.svg" alt="" /> */}
              </button>
              {/* <button className='btn-landingpage' type="" style={{ width: "15rem", height: "4rem", borderRadius: "2rem", border: "1px solid #0A2A66" }}>
                <p style={{ textTransform: "uppercase", fontSize: "1.5rem", fontWeight: 700, color: colorWhite, lineHeight: "2.25rem" }}>Về trang chủ</p>
              </button> */}
            </Link>
          </header>

        </section>

        <div
          id="landingpage_introduction"
          style={{ padding: "8rem 5rem 0rem", color: "#272266", background: colorWhite, width: "100%" }}
          className="landingpage_desktop landingpage_horizontal-intro-payment-section"
        >
          <h1 style={{ fontSize: "28px", fontWeight: 700, color: colorTextH1 }}>ĐIỀU KHOẢN SỬ DỤNG TÀI KHOẢN KURIO</h1>
          <p style={{ color: "#272266", fontWeight: 400, marginBottom: "1rem" }}>
            Điều khoản sử dụng tài khoản Kurio (sau đây gọi tắt là “Điều khoản
            sử dụng”) là một thỏa thuận/hợp đồng điện tử được thiết lập và
            ràng buộc các quyền và nghĩa vụ giữa CÔNG TY CỔ PHẦN IKEMSO TOÀN
            CẦU (“Công ty”) và Khách hàng khi Khách hàng sử dụng dịch vụ, sản
            phẩm mà Công ty cung cấp. <br /> <br />
            Để truy cập và sử dụng tài khoản Kurio, Khách hàng vui lòng xem
            xét cẩn trọng và xác nhận đồng ý với Điều khoản sử dụng này trước
            khi bắt đầu sử dụng tài khoản Kurio với các điều khoản và điều
            kiện sau đây: <br />
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            1. Các điều khoản về việc sử dụng
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            {" "}
            Với việc sử dụng tài khoản Kurio, Khách hàng đồng ý tuân theo tất
            cả những điều khoản dưới đây:{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Khi đăng ký tài khoản, học
            viên phải cung cấp đầy đủ và chính xác thông tin về Tên, Email, Số
            điện thoại... Những thông tin này sẽ được dùng làm căn cứ để hỗ
            trợ giải quyết các vấn đề phát sinh trong quá trình học viên theo
            học tại Kurio.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Khi tạo tài khoản trên
            Kurio, Khách hàng đồng ý nhận những email cập nhật các tính năng
            và bài viết mới, cũng như những email thông báo các khuyến mãi từ
            Kurio. Kurio sẽ cung cấp đường link ở cuối email để Khách hàng có
            thể hủy đăng ký nhận thông báo đó bằng cách kích vào link đó.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Khách hàng sẽ không truy
            cập Kurio bằng các chương trình tự động.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Trong quá trình sử dụng
            tài khoản Kurio, tất cả những dữ liệu, bao gồm các thông tin về
            kết quả và lịch sử học tập của Khách hàng, tài liệu học tập, hình
            ảnh, âm thanh và tất cả các dữ liệu khác do Khách hàng tạo ra hay
            được thu thập bởi Kurio thông qua việc học của Khách hàng, dù miễn
            phí hay không, sẽ là tài sản tuyệt đối và của riêng Kurio.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Khách hàng sẽ không sao
            chép, phát tán hay phát hành bất kì tài liệu nào của Kurio, dù
            miễn phí hay không, mà không có sự cho phép bằng văn bản của
            Kurio. Tuy nhiên, Khách hàng có thế để đường dẫn (link) đến những
            tài liệu trên Kurio.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Mật khẩu của tài khoản:
            Sau khi đăng ký, học viên được cung cấp tài khoản và mật khẩu. Mật
            khẩu được sử dụng để đăng nhập vào website Kurio.vn và các dịch vụ
            trong hệ thống của Kurio. Học viên có trách nhiệm tự bảo quản mật
            khẩu, nếu mật khẩu bị lộ ra ngoài dưới bất kỳ hình thức nào, Kurio
            sẽ không chịu trách nhiệm về mọi tổn thất phát sinh.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem", marginBottom: "1rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Nếu Khách hàng vi phạm bất
            kì điều khoản sử dụng nào ở trên, Kurio giữ quyền khóa tài khoản
            của Khách hàng vô thời hạn và việc này có thể khiến cho Khách hàng
            không còn có thế sử dụng những sản phẩm hay dịch vụ mà Khách hàng
            đã mua ở Kurio (nếu có).
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            2. Các điều khoản về việc mua hàng/sử dụng dịch vụ
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Khi mua một sản phẩm hay dịch vụ trên Kurio, ví dụ như mua tài
            khoản để học, Khách hàng đồng ý tuân theo tất cả những điều khoản
            sau:{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Những sản phẩm và dịch vụ
            mà Khách hàng đã mua trên Kurio, ví dụ như các tài khoản học, là
            để chỉ mình Khách hàng sử dụng. Khách hàng không được phép để bất
            kì người nào khác đăng nhập vào tài khoản của Khách hàng để sử
            dụng những sản phẩm hay dịch vụ mà Khách hàng đã mua.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Khi thực hiện thanh toán
            trên Kurio, Khách hàng đồng ý sẽ cung cấp những thông tin thanh
            toán xác thực và chính xác nhất.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem", marginBottom: "1rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Nếu Khách hàng vi phạm bất
            kì điều khoản sử dụng nào ở trên, Kurio giữ quyền khóa tài khoản
            của Khách hàng vô thời hạn và việc này có thể khiến cho Khách hàng
            không còn có thế sử dụng những sản phẩm hay dịch vụ mà Khách hàng
            đã mua ở Kurio (nếu có).
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            3. Phương thức thanh toán
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Kurio chấp nhận nhiều hình thức thanh toán khác nhau, bao gồm:{" "}
            <br /> <br />
            a. Thanh toán bằng thẻ quốc tế như Visa/Master được phát hành hợp
            pháp bởi một tổ chức tín dụng tại Việt Nam.
            <br /> <br />
            b. Thanh toán bằng thẻ ATM nội địa có chức năng Internet Banking
            hoặc QR Code được cung cấp bởi một tổ chức tín dụng tại Việt Nam.{" "}
            <br /> <br />
            c. Thanh toán bằng chuyển khoản theo thông tin dưới đây: <br />{" "}
            <br />
            CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU <br /> <br />
            Số tài khoản: 19036816201018 <br /> <br />
            Ngân hàng: TMCP Kỹ thương Việt Nam (Techcombank) - Chi nhánh Ba
            Đình - Hà Nội <br /> <br />
            Ghi chú: Khi chuyển khoản tại mục "Nội dung" Khách hàng ghi rõ: Số
            điện thoại - Họ Tên - Email đăng ký học - Khóa học đăng ký. <br />{" "}
            <br />
            Ví dụ: 0901234567 – Nguyen Van A - nguyevana@gmail.com – Toan tu
            duy <br /> <br />
            <b>Hướng dẫn thanh toán:</b> <br /> <br />
            Sau khi Khách hàng đã đăng ký tài khoản mới trên Kurio hoặc đăng
            nhập vào tài khoản đã đăng ký trên Kurio, Khách hàng thực hiện các
            bước sau để mua khóa học phù hợp và tiến hành thanh toán:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bước 1: Tại trang chủ
            Kurio, nhấn chọn “Nâng cấp” sau đó chọn khóa học mà Khách hàng
            muốn mua.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bước 2: Nhấn chọn “mua
            khóa học”.{" "}
          </p>
          <p> <b style={{ lineHeight: "normal", color: "#272266", fontSize: "1rem" }}>“Mua khóa học”: Chỉ có thể truy cập vào một khóa học mà Khách hàng
            đăng ký mua và giới hạn thời gian cụ thể cho từng khóa học.</b></p> <p />
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bước 3: Nhấn chọn “thanh
            toán” để tiến hành thanh toán.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bước 4. Ở màn hình chọn
            Phương thức thanh toán (ví dụ: người dùng chọn phương thức "Thẻ
            ATM") rồi nhấn Thanh toán.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bước 5. Tiến hành nhập đầy
            đủ các thông tin theo yêu cầu và nhấn Thanh toán.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bước 6. Khách hàng sẽ nhận
            được thông báo thanh toán hoàn tất và có thể tiến hành truy cập
            khóa học. Khách hàng vào mục "Tài khoản" để kiểm tra thông tin
            khóa học.{" "}
          </p>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Trường hợp sau khi thanh toán, nếu Khách hàng phát hiện Gói dịch
            vụ có lỗi xin vui lòng thông báo ngay theo số điện thoại 0971 500
            120 của Kurio để được trợ giúp.{" "}
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            4. Toàn bộ thỏa thuận
          </h2>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Tất cả những điều khoản trên là toàn bộ bản hợp đồng giữa Kurio và
            Khách hàng về tất cả những khía cạnh của việc sử dụng tài khoản
            Kurio của Khách hàng. Những điều khoản này sẽ thay thế cho những
            điều khoản ở trước đó.{" "}
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            5. Chính sách hoàn trả
          </h2>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Trong trường hợp Khách hàng muốn hỗ trợ hoàn trả khóa học trên web Kurio, vui lòng liên hệ với chúng tôi theo hotline 0971500120  hoặc email cskh@kurio.vn để được hỗ trợ.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            6. Thông tin liên lạc
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Nếu Khách hàng có câu hỏi nào về những điều khoản này, Khách hàng
            hãy liên lạc với chúng tôi: <br />
            <br />
            CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU <br /> <br />
            Email: cskh@kurio.vn <br /> <br />
            Tel: 0971500120 <br /> <br />
            Địa chỉ trụ sở: số 128 Nguyễn Thái Học, phường Điện Biên, quận Ba
            Đình, Hà Nội <br /> <br />
          </p>
          <h1 style={{ marginBottom: "21px", fontSize: "28px", fontWeight: 700, color: colorTextH1 }}>
            CHÍNH SÁCH BẢO MẬT THÔNG TIN
          </h1>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            1. Chúng tôi thu thập những thông tin gì?
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Chúng tôi thu nhập thông tin từ bạn khi bạn đăng ký trên trang web
            của chúng tôi. <br /> <br />
            Dữ liệu cá nhân mà Kurio có thể thu thập bao gồm, nhưng không giới
            hạn: <br />
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Họ tên{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Địa chỉ email{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Ngày sinh{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Tài khoản ngân hàng và
            thông tin thanh toán{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Số điện thoại{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Giới tính{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Địa chỉ thanh toán{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Thông tin được gửi bởi
            hoặc liên quan đến (các) thiết bị được sử dụng để truy cập vào Các
            Dịch vụ hoặc Nền tảng của chúng tôi.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bất kỳ thông tin nào khác
            về người dùng khi người dùng đăng nhập để sử dụng Các Dịch Vụ hoặc
            Nền tảng của chúng tôi.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Dữ liệu tổng hợp về nội
            dung người dùng sử dụng.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem", marginBottom: "1rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bạn có thể tự chỉnh sửa
            được tên trong trang quản lý tài khoản. Nếu cần đổi email đăng
            nhập hoặc số điện thoại, bạn cần gửi yêu cầu cho chúng tôi và trải
            qua các bước xác thực.{" "}
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>2. Chúng tôi sẽ làm gì với thông tin của bạn?</h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Kurio sử dụng thông tin chúng tôi thu thập cho các mục đích:{" "}
            <br />
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để xem xét và/hoặc xử lý
            đơn đăng ký/giao dịch; đăng ký email của bạn với chúng tôi{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để quản lý việc bạn sử
            dụng dịch vụ của chúng tôi{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để đáp ứng, xử lý, giải
            quyết hoặc hoàn tất một giao dịch và/hoặc đáp ứng các yêu cầu của
            bạn đối với các sản phẩm và dịch vụ nhất định và thông báo cho bạn
            về các vấn đề dịch vụ và các hoạt động tài khoản bất thường{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để thực thi các Điều Khoản
            Dịch Vụ của chúng tôi{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để bảo vệ sự an toàn cá
            nhân và các quyền, tài sản hoặc sự an toàn của người khác{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để nhận dạng và/hoặc xác
            minh{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để giải quyết hoặc tạo
            điều kiện dịch vụ khách hàng, thực hiện các khiếu nại của bạn,
            giải quyết hoặc trả lời bất kỳ thắc mắc nào được gửi bởi (hoặc
            nhằm được gửi bởi) bạn hoặc thay mặt bạn{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để liên hệ với bạn hoặc
            liên lạc với bạn qua điện thoại, tin nhắn văn bản, email nhằm mục
            đích quản trị quan hệ của bạn với chúng tôi{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để gửi thông báo tới bạn
            qua trình duyệt hoặc qua ứng dụng Kurio trên điện thoại{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để tiến hành các hoạt động
            nghiên cứu, phân tích và phát triển (bao gồm, nhưng không giới hạn
            ở, phân tích dữ liệu, khảo sát, phát triển và/hoặc lập đặc tính
            sản phẩm và dịch vụ), để phân tích cách thức bạn sử dụng Các Dịch
            Vụ của chúng tôi, để cải thiện Các Dịch Vụ hoặc sản phẩm của chúng
            tôi và/hoặc để cải thiện trải nghiệm khách hàng của bạn{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Vì mục đích tiếp thị, gửi
            cho bạn qua email, thông tin và tài liệu tiếp thị và quảng bá liên
            quan đến các sản phẩm và/hoặc dịch vụ. Bạn có thể hủy đăng ký nhận
            các thông tin tiếp thị tại bất cứ thời điểm nào bằng cách sử dụng
            chức năng hủy đăng ký email{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem", marginBottom: "1rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để ngăn chặn hoặc điều tra
            bất kỳ hoạt động gian lận, phi pháp, thiếu sót hay hành vi sai
            trái nào, cho dù có liên quan đến việc bạn sử dụng Các Dịch Vụ của
            chúng tôi{" "}
          </p>
          <p />
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>3. Bảo mật</h2>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Bảo mật thông tin cá nhân của Khách hàng là ưu tiên hàng đầu của
            chúng tôi. Các thông tin đăng ký mua hàng của Khách hàng sẽ được
            bảo mật bằng cách sử dụng công nghệ mã hóa an toàn Secure Sockets
            Layer (SSL). Chúng tôi cam kết sẽ cố gắng để đảm bảo an ninh ở mức
            cao nhất cho Khách hàng. Tuy nhiên, Kurio xin nhấn mạnh không có
            phương án nào đảm bảo được tính bảo mật 100%. Nếu bạn có bất kỳ
            câu hỏi về vấn đề bảo mật trên trang web của chúng tôi, bạn có thể
            gửi thắc mắc về email cskh@kurio.vn.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            4. Cookies và công nghệ phân tích dữ liệu khác
          </h2>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Cookies là một lượng nhỏ dữ liệu, được gửi tới trình duyệt của bạn
            từ một trang web và được lưu trữ trên ổ đĩa cứng của máy tính.
            Cookies cho phép trang web hoặc hệ thống của các bên cung cấp dịch
            vụ nhận diện trình duyệt của bạn và lưu lại một số thông tin nhất
            định. Chúng tôi dùng cookies để hiểu và lưu trữ hoạt động của bạn
            và đáp ứng các nhu cầu của bạn tốt hơn cho lần truy cập sau.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            5. Chúng tôi có cung cấp thông tin cho bên khác không?
          </h2>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Chúng tôi không bán, trao đổi, hoặc sử dụng các hình thức thương
            mại khác với những thông tin cá nhân của bạn. Tuy nhiên, thông tin
            không định danh của những người truy cập vào trang web có thể được
            sử dụng và cung cấp cho các bên khác nhằm mục đích marketing,
            quảng cáo hoặc mục đích sử dụng khác.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            6. Xóa tài khoản
          </h2>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Các cá nhân sở hữu tài khoản trên Kurio được quyền xóa bỏ tài
            khoản bất kỳ thời điểm nào. Trong trường hợp Khách hàng muốn xóa
            tài khoản, vui lòng liên hệ với Kurio theo hotline 0971500120 hoặc
            email <a href>cskh@kurio.vn</a> để được hỗ trợ. Ngoài ra, chúng
            tôi chỉ hỗ trợ khôi phục lại tài khoản trong trường hợp nhầm lẫn
            hoặc tài khoản bị tấn công trong thời gian 30 ngày kể từ thời điểm
            xóa. Vui lòng đọc kỹ các thông tin được cung cấp trước khi thực sự
            làm hành động này.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            7. Thông tin liên hệ
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Nếu bạn có thắc mắc, hãy liên hệ với chúng tôi: <br />
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>
            Hotline: 0971500120{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Email: cskh@kurio.vn{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Địa chỉ: số 128 Nguyễn
            Thái Học, phường Điện Biên, quận Ba Đình, Hà Nội.
          </p>
          <p /> <br />
        </div>

        <section id='tablet-footer' style={{ background: colorWhite, width: "100%", }}>
          <footer className='is-flex is-flex-direction-column is-align-items-start' style={{ gap: "4rem", padding: "4rem" }}>
            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Liên hệ</p>
              <div className='is-flex is-flex-direction-column' style={{ gap: "1rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU</p>

                <span style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Giấy chứng nhận Đăng ký Kinh doanh số 0109501325 do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp ngày 19/01/2021.</span>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-gmail.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>contact@ikemso.com</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-phone.svg" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>1900 8157</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-zalo.png" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>0971 500 120</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-address.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "1.5rem" }}>Số 128, phố Nguyễn Thái Học, <br />
                    phường Điện Biên, quận Ba Đình, Hà Nội</p>
                </div>
              </div>
            </div>

            <div className='is-flex is-flex-direction-row is-justify-content-center is-align-items-flex-start' style={{ gap: "4rem" }}>
              <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Chính sách</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <Link to={'/payment-methods'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Hình thức thanh toán</p></Link>
                  <Link to={'/return-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Vận chuyển - Trả hàng hoàn tiền</p></Link>
                  <Link to={'/privacy-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Chính sách bảo mật</p></Link>
                </div>

                <Link to={'/payment-policy'}>
                  <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "15rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorWhite, boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                    <img style={{ width: "6.25rem", height: "1.89581rem" }} src="/images/landing-page/image-vnpay.png" alt="" />
                    <p style={{ width: "5.125rem", fontSize: "0.875rem", fontWeight: 700, color: colorBlack, lineHeight: "normal" }}>Chính sách thanh toán</p>
                  </div>
                </Link>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "3rem" }}>
                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                  <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Ứng dụng</p>

                  <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                    <Link to={'https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573'}>
                      <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho iOS</p></Link>
                    <Link to={'https://play.google.com/store/apps/details?id=vn.kurio.android'}>
                      <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho Android</p></Link>
                  </div>
                </div>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                  <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Mạng xã hội</p>

                  <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                    <Link to={'https://www.facebook.com/kurio.vn'}>
                      <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Facebook</p>
                    </Link>
                    {/* <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Youtube</p> */}
                    <Link to={'https://zalo.me/0971500120'}>
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Zalo</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ width: "100%" }} className='is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center'>
              <Link to={'http://online.gov.vn/Home/WebDetails/110320?AspxAutoDetectCookieSupport=1'}>
                <img style={{ width: "12.5rem", height: "4.75113rem" }} src="/images/landing-page/image-bct.png" alt="" />
              </Link>
              <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "1.5rem" }}>
                <img onClick={() => backToTop()} style={{ width: "5rem", height: "5rem", cursor: "pointer" }} src="/images/landing-page/icon-back-to-top.svg" alt="" />

                <p onClick={() => backToTop()} style={{ fontSize: "1.25rem", color: colorBlack, cursor: "pointer", textTransform: "uppercase", fontWeight: 700, lineHeight: "normal", width: "6rem" }}>Về đầu trang</p>
              </div>
            </div>
          </footer>
        </section>
      </div>

      {/* Mobile */}
      <div className='container-landingpage' id='mobile-landingpage'>
          {showPopupDownload && (
            <div style={{ position: "fixed", bottom: "0", width: "100%" }}>
              <div id='bottom-popup' className='is-relative' style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <span className='icon-cancel is-flex is-justify-content-center is-align-items-center' onClick={handleClosePopupDownload} style={{ position: "absolute",zIndex:2, right: "1rem", top: "15%", width: "1.5rem", height: "1.5rem", borderRadius: "1rem 1rem 0rem 0rem", background: "#E8E3F8" }}>
                  <img src="/images/landing-page/icon-cancel.svg" alt="" />
                </span>
                <div style={{ position: "relative", maxWidth: "100%", width: "100%", bottom: "-0.7rem" }}>
                  <img style={{ width: "100%" }} src="/images/landing-page/image-mobile-popup.svg" alt="" />
                  <button className='btn-mobile-popup is-flex is-align-items-center is-justify-content-center' onClick={handleDownloadApp} style={{ position: "absolute",zIndex:1, top: "60%", right: "5%", transform: "translateY(-50%)", gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "11.5rem", height: "2.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                    <img src="/images/landing-page/icon-download.svg" alt="" />
                    <p style={{ color: colorWhite, fontSize: "1rem", fontWeight: 700, textTransform: "uppercase" }}>tải app ngay</p>
                  </button>
                </div>
              </div>
            </div>
          )}

        <section id='mobile-header' className='fixedHeader' style={{ background: "#F1EEFC", width: "100%" }}>
          <header style={{ width: "100%", height: "5rem", padding: "0rem 1rem" }} className='is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center'>
            <img style={{ width: "2.63394rem", height: "3rem" }} src="/images/landing-page/logo-mobile.svg" alt="" />

            <Link to={'/'}>
              <button className='is-flex is-align-items-center is-justify-content-center' style={{ gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "10rem", height: "2.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                <p style={{ color: colorWhite, fontSize: "1rem", fontWeight: 700, textTransform: "uppercase" }}>Về trang chủ</p>
              </button>
              {/* <button className='btn-landingpage' type="" style={{ width: "12.5rem", height: "3rem", borderRadius: "2rem", border: "1px solid #0A2A66" }}>
                <p style={{ textTransform: "uppercase", fontSize: "1rem", fontWeight: 700, color: colorWhite, lineHeight: "2.25rem" }}>Về trang chủ</p>
              </button> */}
            </Link>
          </header>
        </section>

        <div
          id="landingpage_introduction"
          style={{ padding: "8rem 5rem 0rem", color: "#272266", background: colorWhite, width: "100%" }}
          className="landingpage_desktop landingpage_horizontal-intro-payment-section"
        >
          <h1 style={{ fontSize: "28px", fontWeight: 700, color: colorTextH1 }}>ĐIỀU KHOẢN SỬ DỤNG TÀI KHOẢN KURIO</h1>
          <p style={{ color: "#272266", fontWeight: 400, marginBottom: "1rem" }}>
            Điều khoản sử dụng tài khoản Kurio (sau đây gọi tắt là “Điều khoản
            sử dụng”) là một thỏa thuận/hợp đồng điện tử được thiết lập và
            ràng buộc các quyền và nghĩa vụ giữa CÔNG TY CỔ PHẦN IKEMSO TOÀN
            CẦU (“Công ty”) và Khách hàng khi Khách hàng sử dụng dịch vụ, sản
            phẩm mà Công ty cung cấp. <br /> <br />
            Để truy cập và sử dụng tài khoản Kurio, Khách hàng vui lòng xem
            xét cẩn trọng và xác nhận đồng ý với Điều khoản sử dụng này trước
            khi bắt đầu sử dụng tài khoản Kurio với các điều khoản và điều
            kiện sau đây: <br />
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            1. Các điều khoản về việc sử dụng
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            {" "}
            Với việc sử dụng tài khoản Kurio, Khách hàng đồng ý tuân theo tất
            cả những điều khoản dưới đây:{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Khi đăng ký tài khoản, học
            viên phải cung cấp đầy đủ và chính xác thông tin về Tên, Email, Số
            điện thoại... Những thông tin này sẽ được dùng làm căn cứ để hỗ
            trợ giải quyết các vấn đề phát sinh trong quá trình học viên theo
            học tại Kurio.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Khi tạo tài khoản trên
            Kurio, Khách hàng đồng ý nhận những email cập nhật các tính năng
            và bài viết mới, cũng như những email thông báo các khuyến mãi từ
            Kurio. Kurio sẽ cung cấp đường link ở cuối email để Khách hàng có
            thể hủy đăng ký nhận thông báo đó bằng cách kích vào link đó.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Khách hàng sẽ không truy
            cập Kurio bằng các chương trình tự động.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Trong quá trình sử dụng
            tài khoản Kurio, tất cả những dữ liệu, bao gồm các thông tin về
            kết quả và lịch sử học tập của Khách hàng, tài liệu học tập, hình
            ảnh, âm thanh và tất cả các dữ liệu khác do Khách hàng tạo ra hay
            được thu thập bởi Kurio thông qua việc học của Khách hàng, dù miễn
            phí hay không, sẽ là tài sản tuyệt đối và của riêng Kurio.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Khách hàng sẽ không sao
            chép, phát tán hay phát hành bất kì tài liệu nào của Kurio, dù
            miễn phí hay không, mà không có sự cho phép bằng văn bản của
            Kurio. Tuy nhiên, Khách hàng có thế để đường dẫn (link) đến những
            tài liệu trên Kurio.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Mật khẩu của tài khoản:
            Sau khi đăng ký, học viên được cung cấp tài khoản và mật khẩu. Mật
            khẩu được sử dụng để đăng nhập vào website Kurio.vn và các dịch vụ
            trong hệ thống của Kurio. Học viên có trách nhiệm tự bảo quản mật
            khẩu, nếu mật khẩu bị lộ ra ngoài dưới bất kỳ hình thức nào, Kurio
            sẽ không chịu trách nhiệm về mọi tổn thất phát sinh.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem", marginBottom: "1rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Nếu Khách hàng vi phạm bất
            kì điều khoản sử dụng nào ở trên, Kurio giữ quyền khóa tài khoản
            của Khách hàng vô thời hạn và việc này có thể khiến cho Khách hàng
            không còn có thế sử dụng những sản phẩm hay dịch vụ mà Khách hàng
            đã mua ở Kurio (nếu có).
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            2. Các điều khoản về việc mua hàng/sử dụng dịch vụ
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Khi mua một sản phẩm hay dịch vụ trên Kurio, ví dụ như mua tài
            khoản để học, Khách hàng đồng ý tuân theo tất cả những điều khoản
            sau:{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Những sản phẩm và dịch vụ
            mà Khách hàng đã mua trên Kurio, ví dụ như các tài khoản học, là
            để chỉ mình Khách hàng sử dụng. Khách hàng không được phép để bất
            kì người nào khác đăng nhập vào tài khoản của Khách hàng để sử
            dụng những sản phẩm hay dịch vụ mà Khách hàng đã mua.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Khi thực hiện thanh toán
            trên Kurio, Khách hàng đồng ý sẽ cung cấp những thông tin thanh
            toán xác thực và chính xác nhất.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem", marginBottom: "1rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Nếu Khách hàng vi phạm bất
            kì điều khoản sử dụng nào ở trên, Kurio giữ quyền khóa tài khoản
            của Khách hàng vô thời hạn và việc này có thể khiến cho Khách hàng
            không còn có thế sử dụng những sản phẩm hay dịch vụ mà Khách hàng
            đã mua ở Kurio (nếu có).
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            3. Phương thức thanh toán
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Kurio chấp nhận nhiều hình thức thanh toán khác nhau, bao gồm:{" "}
            <br /> <br />
            a. Thanh toán bằng thẻ quốc tế như Visa/Master được phát hành hợp
            pháp bởi một tổ chức tín dụng tại Việt Nam.
            <br /> <br />
            b. Thanh toán bằng thẻ ATM nội địa có chức năng Internet Banking
            hoặc QR Code được cung cấp bởi một tổ chức tín dụng tại Việt Nam.{" "}
            <br /> <br />
            c. Thanh toán bằng chuyển khoản theo thông tin dưới đây: <br />{" "}
            <br />
            CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU <br /> <br />
            Số tài khoản: 19036816201018 <br /> <br />
            Ngân hàng: TMCP Kỹ thương Việt Nam (Techcombank) - Chi nhánh Ba
            Đình - Hà Nội <br /> <br />
            Ghi chú: Khi chuyển khoản tại mục "Nội dung" Khách hàng ghi rõ: Số
            điện thoại - Họ Tên - Email đăng ký học - Khóa học đăng ký. <br />{" "}
            <br />
            Ví dụ: 0901234567 – Nguyen Van A - nguyevana@gmail.com – Toan tu
            duy <br /> <br />
            <b>Hướng dẫn thanh toán:</b> <br /> <br />
            Sau khi Khách hàng đã đăng ký tài khoản mới trên Kurio hoặc đăng
            nhập vào tài khoản đã đăng ký trên Kurio, Khách hàng thực hiện các
            bước sau để mua khóa học phù hợp và tiến hành thanh toán:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bước 1: Tại trang chủ
            Kurio, nhấn chọn “Nâng cấp” sau đó chọn khóa học mà Khách hàng
            muốn mua.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bước 2: Nhấn chọn “mua
            khóa học”.{" "}
          </p>
          <p><b style={{ lineHeight: "normal", color: "#272266" }}>“Mua khóa học”: Chỉ có thể truy cập vào một khóa học mà Khách hàng
            đăng ký mua và giới hạn thời gian cụ thể cho từng khóa học.</b></p> <p />
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bước 3: Nhấn chọn “thanh
            toán” để tiến hành thanh toán.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bước 4. Ở màn hình chọn
            Phương thức thanh toán (ví dụ: người dùng chọn phương thức "Thẻ
            ATM") rồi nhấn Thanh toán.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bước 5. Tiến hành nhập đầy
            đủ các thông tin theo yêu cầu và nhấn Thanh toán.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bước 6. Khách hàng sẽ nhận
            được thông báo thanh toán hoàn tất và có thể tiến hành truy cập
            khóa học. Khách hàng vào mục "Tài khoản" để kiểm tra thông tin
            khóa học.{" "}
          </p>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Trường hợp sau khi thanh toán, nếu Khách hàng phát hiện Gói dịch
            vụ có lỗi xin vui lòng thông báo ngay theo số điện thoại 0971 500
            120 của Kurio để được trợ giúp.{" "}
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            4. Toàn bộ thỏa thuận
          </h2>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Tất cả những điều khoản trên là toàn bộ bản hợp đồng giữa Kurio và
            Khách hàng về tất cả những khía cạnh của việc sử dụng tài khoản
            Kurio của Khách hàng. Những điều khoản này sẽ thay thế cho những
            điều khoản ở trước đó.{" "}
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            5. Chính sách hoàn trả
          </h2>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Trong trường hợp Khách hàng muốn hỗ trợ hoàn trả khóa học trên web Kurio, vui lòng liên hệ với chúng tôi theo hotline 0971500120  hoặc email cskh@kurio.vn để được hỗ trợ.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            6. Thông tin liên lạc
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Nếu Khách hàng có câu hỏi nào về những điều khoản này, Khách hàng
            hãy liên lạc với chúng tôi: <br />
            <br />
            CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU <br /> <br />
            Email: cskh@kurio.vn <br /> <br />
            Tel: 0971500120 <br /> <br />
            Địa chỉ trụ sở: số 128 Nguyễn Thái Học, phường Điện Biên, quận Ba
            Đình, Hà Nội <br /> <br />
          </p>
          <h1 style={{ marginBottom: "21px", fontSize: "28px", fontWeight: 700, color: colorTextH1 }}>
            CHÍNH SÁCH BẢO MẬT THÔNG TIN
          </h1>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            1. Chúng tôi thu thập những thông tin gì?
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Chúng tôi thu nhập thông tin từ bạn khi bạn đăng ký trên trang web
            của chúng tôi. <br /> <br />
            Dữ liệu cá nhân mà Kurio có thể thu thập bao gồm, nhưng không giới
            hạn: <br />
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Họ tên{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Địa chỉ email{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Ngày sinh{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Tài khoản ngân hàng và
            thông tin thanh toán{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Số điện thoại{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Giới tính{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Địa chỉ thanh toán{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Thông tin được gửi bởi
            hoặc liên quan đến (các) thiết bị được sử dụng để truy cập vào Các
            Dịch vụ hoặc Nền tảng của chúng tôi.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bất kỳ thông tin nào khác
            về người dùng khi người dùng đăng nhập để sử dụng Các Dịch Vụ hoặc
            Nền tảng của chúng tôi.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Dữ liệu tổng hợp về nội
            dung người dùng sử dụng.{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem", marginBottom: "1rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Bạn có thể tự chỉnh sửa
            được tên trong trang quản lý tài khoản. Nếu cần đổi email đăng
            nhập hoặc số điện thoại, bạn cần gửi yêu cầu cho chúng tôi và trải
            qua các bước xác thực.{" "}
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>2. Chúng tôi sẽ làm gì với thông tin của bạn?</h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Kurio sử dụng thông tin chúng tôi thu thập cho các mục đích:{" "}
            <br />
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để xem xét và/hoặc xử lý
            đơn đăng ký/giao dịch; đăng ký email của bạn với chúng tôi{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để quản lý việc bạn sử
            dụng dịch vụ của chúng tôi{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để đáp ứng, xử lý, giải
            quyết hoặc hoàn tất một giao dịch và/hoặc đáp ứng các yêu cầu của
            bạn đối với các sản phẩm và dịch vụ nhất định và thông báo cho bạn
            về các vấn đề dịch vụ và các hoạt động tài khoản bất thường{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để thực thi các Điều Khoản
            Dịch Vụ của chúng tôi{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để bảo vệ sự an toàn cá
            nhân và các quyền, tài sản hoặc sự an toàn của người khác{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để nhận dạng và/hoặc xác
            minh{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để giải quyết hoặc tạo
            điều kiện dịch vụ khách hàng, thực hiện các khiếu nại của bạn,
            giải quyết hoặc trả lời bất kỳ thắc mắc nào được gửi bởi (hoặc
            nhằm được gửi bởi) bạn hoặc thay mặt bạn{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để liên hệ với bạn hoặc
            liên lạc với bạn qua điện thoại, tin nhắn văn bản, email nhằm mục
            đích quản trị quan hệ của bạn với chúng tôi{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để gửi thông báo tới bạn
            qua trình duyệt hoặc qua ứng dụng Kurio trên điện thoại{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để tiến hành các hoạt động
            nghiên cứu, phân tích và phát triển (bao gồm, nhưng không giới hạn
            ở, phân tích dữ liệu, khảo sát, phát triển và/hoặc lập đặc tính
            sản phẩm và dịch vụ), để phân tích cách thức bạn sử dụng Các Dịch
            Vụ của chúng tôi, để cải thiện Các Dịch Vụ hoặc sản phẩm của chúng
            tôi và/hoặc để cải thiện trải nghiệm khách hàng của bạn{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Vì mục đích tiếp thị, gửi
            cho bạn qua email, thông tin và tài liệu tiếp thị và quảng bá liên
            quan đến các sản phẩm và/hoặc dịch vụ. Bạn có thể hủy đăng ký nhận
            các thông tin tiếp thị tại bất cứ thời điểm nào bằng cách sử dụng
            chức năng hủy đăng ký email{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem", marginBottom: "1rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Để ngăn chặn hoặc điều tra
            bất kỳ hoạt động gian lận, phi pháp, thiếu sót hay hành vi sai
            trái nào, cho dù có liên quan đến việc bạn sử dụng Các Dịch Vụ của
            chúng tôi{" "}
          </p>
          <p />
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>3. Bảo mật</h2>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Bảo mật thông tin cá nhân của Khách hàng là ưu tiên hàng đầu của
            chúng tôi. Các thông tin đăng ký mua hàng của Khách hàng sẽ được
            bảo mật bằng cách sử dụng công nghệ mã hóa an toàn Secure Sockets
            Layer (SSL). Chúng tôi cam kết sẽ cố gắng để đảm bảo an ninh ở mức
            cao nhất cho Khách hàng. Tuy nhiên, Kurio xin nhấn mạnh không có
            phương án nào đảm bảo được tính bảo mật 100%. Nếu bạn có bất kỳ
            câu hỏi về vấn đề bảo mật trên trang web của chúng tôi, bạn có thể
            gửi thắc mắc về email cskh@kurio.vn.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            4. Cookies và công nghệ phân tích dữ liệu khác
          </h2>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Cookies là một lượng nhỏ dữ liệu, được gửi tới trình duyệt của bạn
            từ một trang web và được lưu trữ trên ổ đĩa cứng của máy tính.
            Cookies cho phép trang web hoặc hệ thống của các bên cung cấp dịch
            vụ nhận diện trình duyệt của bạn và lưu lại một số thông tin nhất
            định. Chúng tôi dùng cookies để hiểu và lưu trữ hoạt động của bạn
            và đáp ứng các nhu cầu của bạn tốt hơn cho lần truy cập sau.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            5. Chúng tôi có cung cấp thông tin cho bên khác không?
          </h2>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Chúng tôi không bán, trao đổi, hoặc sử dụng các hình thức thương
            mại khác với những thông tin cá nhân của bạn. Tuy nhiên, thông tin
            không định danh của những người truy cập vào trang web có thể được
            sử dụng và cung cấp cho các bên khác nhằm mục đích marketing,
            quảng cáo hoặc mục đích sử dụng khác.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            6. Xóa tài khoản
          </h2>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Các cá nhân sở hữu tài khoản trên Kurio được quyền xóa bỏ tài
            khoản bất kỳ thời điểm nào. Trong trường hợp Khách hàng muốn xóa
            tài khoản, vui lòng liên hệ với Kurio theo hotline 0971500120 hoặc
            email <a href>cskh@kurio.vn</a> để được hỗ trợ. Ngoài ra, chúng
            tôi chỉ hỗ trợ khôi phục lại tài khoản trong trường hợp nhầm lẫn
            hoặc tài khoản bị tấn công trong thời gian 30 ngày kể từ thời điểm
            xóa. Vui lòng đọc kỹ các thông tin được cung cấp trước khi thực sự
            làm hành động này.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
            7. Thông tin liên hệ
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Nếu bạn có thắc mắc, hãy liên hệ với chúng tôi: <br />
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>
            Hotline: 0971500120{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Email: cskh@kurio.vn{" "}
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Địa chỉ: số 128 Nguyễn
            Thái Học, phường Điện Biên, quận Ba Đình, Hà Nội.
          </p>
          <p /> <br />
        </div>

        <section id='mobile-footer' style={{ background: colorWhite, width: "100%", marginBottom: "10%" }}>
          <footer className='is-flex is-flex-direction-column is-align-items-start' style={{ gap: "3rem", padding: "4rem 2rem" }}>
            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Liên hệ</p>
              <div className='is-flex is-flex-direction-column' style={{ gap: "0.5rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU</p>

                <span style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Giấy chứng nhận Đăng ký Kinh doanh số 0109501325 do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp ngày 19/01/2021.</span>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.75rem" }}>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-gmail.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>contact@ikemso.com</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-phone.svg" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>1900 8157</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-zalo.png" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>0971 500 120</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-address.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Số 128, phố Nguyễn Thái Học,
                    phường Điện Biên, quận Ba Đình, Hà Nội</p>
                </div>
              </div>
            </div>

            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Chính sách</p>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.5rem" }}>
                <Link to={'/payment-methods'}><p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Hình thức thanh toán</p></Link>
                <Link to={'/return-policy'}><p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Vận chuyển - Trả hàng hoàn tiền</p></Link>
                <Link to={'/privacy-policy'}><p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Chính sách bảo mật</p></Link>
              </div>

              <Link to={'/payment-policy'}>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "15rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorWhite, boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                  <img style={{ width: "6.25rem", height: "1.89581rem" }} src="/images/landing-page/image-vnpay.png" alt="" />
                  <p style={{ width: "5.125rem", fontSize: "0.875rem", fontWeight: 700, color: colorBlack, lineHeight: "normal" }}>Chính sách thanh toán</p>
                </div>
              </Link>
            </div>

            <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "3rem" }}>
              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Ứng dụng</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.5rem" }}>
                  <Link to={'https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573'}>
                    <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho iOS</p></Link>
                  <Link to={'https://play.google.com/store/apps/details?id=vn.kurio.android'}>
                    <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho Android</p></Link>
                </div>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Mạng xã hội</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.5rem" }}>
                  <Link to={'https://www.facebook.com/kurio.vn'}>
                    <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Facebook</p>
                  </Link>
                  {/* <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Youtube</p> */}
                  <Link to={'https://zalo.me/0971500120'}>
                  <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Zalo</p>
                  </Link>
                </div>
              </div>
            </div>

            <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "4rem" }}>
              <Link to={'http://online.gov.vn/Home/WebDetails/110320?AspxAutoDetectCookieSupport=1'}>
                <img style={{ width: "6.90625rem", height: "2.625rem" }} src="/images/landing-page/image-bct.png" alt="" />
              </Link>
              <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "1rem" }}>
                <img onClick={() => backToTop()} style={{ zIndex: 2, width: "2.5rem", height: "2.5rem", cursor: "pointer" }} src="/images/landing-page/icon-back-to-top.svg" alt="" />

                <p onClick={() => backToTop()} style={{ zIndex: 2, fontSize: "0.875rem", color: colorBlack, cursor: "pointer", textTransform: "uppercase", fontWeight: 700, lineHeight: "normal", width: "3.75rem" }}>Về đầu trang</p>
              </div>
            </div>
          </footer>
        </section>
      </div>
    </>
  )
}

export default PaymentPolicy