import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "universal-cookie";
import {
  getListCurrentEvent,
  submitQuizEventAnswers
} from "../config/dailyMission";

const cookies = new Cookies();
const initialState = {
  questions: null,
  currentHp: 0,
  startingHp: 0,
  imageUrls: [],
  seenQuestions: 0,
  correctAnswers: null,
  wrongAnswers: 0,
  skippedQuestions: 0,
  status: "idle",
  error: null,
  answerDailyEvent: [],
  userEventId: null,
};

export const fetchPracticeElective = createAsyncThunk(
  "practiceElective/fetchPracticeElective",
  async () => {
    // TODO: Change this to the actual API endpoint
    const { data } = await axios.get("mock-lesson-data/practice.json");
    return data;
  }
);

export const getCurrentEvent = createAsyncThunk(
  "dailyMission/getCurrentEvent",
  async (thunkAPI) => {
    try {
      const { result } = await getListCurrentEvent();
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.errorMessage);
    }
  }
);

export const submitAnswer = createAsyncThunk(
  "dailyMission/submitAnswer",
  async ({ questionId, submit }, thunkAPI) => {
    try {
      const { result } = await submitQuizEventAnswers(questionId, submit);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const dailyMission = createSlice({
  name: "dailyMission",
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setQuestionVisible: (state, action) => {
      if (state.questions[action.payload - 1].isLast) {
        return;
      } else {
        state.questions[action.payload].visible = true;
      }
    },

    setQuestionState: (state, action) => {
      state.questions[action.payload.index].question.state =
        action.payload.state;
    },

    setAnswerDailyEvent: (state, action) => {
      state.answerDailyEvent = action.payload;
      for (var i = 0; i < action.payload.length; i++) {
        if (action.payload[i]) {
          state.questions[action.payload[i].questionIndex].answer =
            action.payload[i].answer;
        }
      }
      // state.questions[action.payload.questionIndex].answer = action.payload[questionIndex].answer
    },

    setClearStateDaily: (state) => {
      state.answerDailyEvent = [];
    },

    setClearStateImageUrlDaily: (state) => {
      state.imageUrls = null;
    },

    setClearStateDailyLogout: (state) => {
      state.questions = null;
    },

    setSeenQuestions: (state, action) => {
      state.seenQuestions = action.payload;
    },
    setQuestionFinished: (state, action) => {
      state.questions[action.payload].isFinished = true;
    },
    setSkippedQuestions: (state, action) => {
      state.skippedQuestions = action.payload + state.skippedQuestions;
    },
    setCorrectQuestion: (state, action) => {
      state.questions[action.payload.questionIndex].correct =
        action.payload.correct;
    },
    setCorrectAnswer: (state, action) => {
      state.correctAnswers = action.payload;
    },
    setWrongAnswer: (state, action) => {
      state.wrongAnswers = action.payload + state.wrongAnswers;
    },
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPracticeElective.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPracticeElective.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questions = action.payload.result.records;
        state.seenQuestions = action.payload.result.seenQuestions;
        state.questions[state.questions.length - 1].isLast = true;
        state.questions.forEach((question, index) => {
          question.questionIndex = index;
        });
        state.questions
          .filter((question, index) => index < state.seenQuestions)
          .forEach((question) => {
            question.done = true;
            question.visible = true;
          });
      })
      .addCase(fetchPracticeElective.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getCurrentEvent.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getCurrentEvent.fulfilled, (state, action) => {
        if (action.payload !== null) {
          state.status = "succeeded";
          state.userEventId = action.payload?.userEventId;
          state.questions = action.payload?.questions;
          state.currentHp = action.payload?.currentHp;
          state.startingHp = action.payload?.startingHp;
          state.imageUrls = action.payload?.imageUrls;
          state.correctAnswers = action.payload?.correctAnswers;
          state.wrongAnswers = action.payload?.wrongAnswers;
          state.skippedQuestions = action.payload?.skippedQuestions;
          state.seenQuestions =
            action.payload?.seenQuestions === 0
              ? 1
              : action.payload?.seenQuestions;
          action.payload?.questions?.length > 0
            ? (state.questions[state.questions?.length - 1].isLast = true)
            : (state.questions = []);
          action.payload?.questions?.length > 0
            ? (state.questions[0].visible = true)
            : (state.questions = []);
          state.questions.forEach((question, index) => {
            question.questionIndex = index;
          });
          state.questions
            .filter((question, index) => index < state.seenQuestions)
            .forEach((question) => {
              question.done = true;
              question.visible = true;
            });
        } else {
          state.questions = null;
          state.currentHp = 0;
          state.startingHp = 0;
        }
      })
      .addCase(getCurrentEvent.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(submitAnswer.fulfilled, (state, action) => {
        state.correctAnswers = action.payload.correct;
        state.currentHp = action.payload.currentHp;
        state.status = "succeeded";
      })

      .addCase(submitAnswer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const {
  setStatus,
  setQuestionVisible,
  setQuestionState,
  setSeenQuestions,
  setCorrectAnswer,
  setSkippedQuestions,
  setAnswerDailyEvent,
  setWrongAnswer,
  setQuestionFinished,
  setCorrectQuestion,
  setQuestions,
  setClearStateDailyLogout,
  setClearStateDaily,
  setClearStateImageUrlDaily,
} = dailyMission.actions;

export const selectQuestions = (state) => state.dailyMission.questions;
export const selectQuizEvents = (state) => state.dailyMission.answerDailyEvent;
export const selectStatus = (state) => state.dailyMission.status;

export default dailyMission.reducer;
