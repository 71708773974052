/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createPracticeByElo, submitPracticeByEloAnswers } from "../../../config/practiceByElo";
import { chunk } from "../../../templates/helper/splitArray";
import { getAllImage } from "../../../templates/helper/getAllImages";
import { LoadingImage } from "../../../templates/helper/LoadingImage";
import BackgroundImageFromUrl from "../../../components/parseImage/BackgroundImageFromUrl";
import ExerciseCloseButton from "../../../components/button/special-button/ExerciseCloseButton";
import ImageFromUrl from "../../../components/parseImage/ImageFromUrl";
import PrimaryButtonPageLevel from "../../../components/button/primary-button/PrimaryButtonLevelPage";
import PracticeContent from "../../practice-elective/PracticeContent";
import { getPracticeSmartQuestion, setPracticeImage, setQuestionState } from "../../../reducers/practiceElectiveSlice";
import PracticeSmartNav from "./PracticeSmartNav";
import { getGradeUserInfo } from "../../../reducers/gradesSlice";
import ScrollRef from "../../../detail-components/ScrollRef";

function PracticeSmartMainWrapper({
  questions,
  seenQuestions,
  correctAnswers,
  handleEndPractice,
  practiceByEloId,
  practiceImages,
  setPracticeByEloId,
  IKMCMode,
  listUserInfo,
  setShowPopupError,
}) {
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [showModal, setShowModal] = useState("");
  const [endPracticeState, setEndPracticeState] = useState(false);
  const [exitState, setExitState] = useState(false);
  const [images, setImages] = useState([]);
  const [showElo, setShowElo] = useState(false)
  const listIndexDisplayLoadingImage = [0, 10, 20, 30, 40];
  const commonStyle = {
    display: "flex",
    flexDirection: "column",

    alignItems: "center",
  };
  const dispatch = useDispatch();

  const closeModal = () => {
    setShowModal("");
    dispatch(getGradeUserInfo())

    const answer = JSON.parse(localStorage.getItem("exerciseAnswer"));
    if (answer) {
      submitAnswers(answer);
    }
    localStorage.removeItem("exerciseAnswer");
    setSelectedQuestion(0);
    if (handleEndPractice) {
      handleEndPractice();
    }
  };

  const showPracticeSmartContinue = async () => {
    localStorage.setItem('eloPractice', parseInt(listUserInfo?.elo))
    try {
      const { result } = await createPracticeByElo()
      setPracticeByEloId(result);

      dispatch(
        getPracticeSmartQuestion({
          id: result,
          page: 1,
          size: 50,
        })
      );
    } catch (error) {
      if (error.response.status === 400) {
        setShowPopupError(true)
      }
    }
    setSelectedQuestion(0)
    setExitState(false)
    setEndPracticeState(false)
    setShowModal(true)
  }

  const showEndPractice = (showElo) => {
    const exerciseState = JSON.parse(localStorage.getItem("exerciseState"));
    if (exerciseState) {
      dispatch(setQuestionState(exerciseState.questionState));
    }
    localStorage.removeItem("exerciseState");

    setEndPracticeState(true);

    setShowElo(false);

    setTimeout(() => {
      setShowElo(true);

    }, 3000); // Đợi 1.5 giây

    dispatch(getGradeUserInfo());

  };

  useEffect(() => {
    // dispatch(fetchPracticeElective());
    if (questions) {
      setShowModal("is-active");
      const questionsChunk = chunk(questions, 10);
      var splitArrayImage = [];
      questionsChunk.map((item) => {
        const result = getAllImage(item, "PRACTICE");
        splitArrayImage.push(result);
        return true;
      });
      if (practiceImages === null) {
        dispatch(setPracticeImage(splitArrayImage));
      }
    }
    if (practiceImages !== null) {
      const listIndexLoadingImage = [0, 9, 19, 29, 39];
      const check = listIndexLoadingImage.includes(selectedQuestion);
      if (check) {
        setImages([
          ...images,
          practiceImages[listIndexLoadingImage.indexOf(selectedQuestion)],
        ]);
      }
    }
  }, [dispatch, practiceImages, questions, selectedQuestion]);

  const handleQuestionChange = (val) => {
    localStorage.removeItem("exerciseState");
    setSelectedQuestion(val);
  };
  const submitAnswers = async (answer) => {
    var answers = [
      {
        answer: answer,
        questionId: questions[selectedQuestion].questionId,
      },
    ];
    try {
      await submitPracticeByEloAnswers(practiceByEloId, answers);
    } catch (error) {
      console.log("err", error);
    }
  };
  // const createElective = () => {
  //   setShowModal("is-active");
  // };
  return (
    <div>
      {/* <button className="button" onClick={createElective}>
        Create Elective
      </button> */}
      {listIndexDisplayLoadingImage.includes(selectedQuestion) &&
        images?.length !== 0 && (
          <LoadingImage
            images={
              images[listIndexDisplayLoadingImage.indexOf(selectedQuestion)]
            }
            type={"PRACTICE"}
          />
        )}
      {questions && (
        <BackgroundImageFromUrl
          objectId={"admin/frontend-images/exercise/background-popup-practice-smart.svg"}
          // objectId="admin/frontend-images/exercise/exercise-bg.svg"
          className={`modal ${showModal.length > 0 ? `${showModal} ` : ""
            } p-0 `}
          style={{
            backgroundSize: "cover",
            zIndex: "200",
            backgroundPosition: "bottom",
          }}
        >
          <BackgroundImageFromUrl
            objectId={"admin/frontend-images/exercise/background-popup-practice-smart.svg"}
            // objectId="admin/frontend-images/exercise/exercise-bg.svg"
            className="modal-background"
            style={{
              minHeight: "100%",
              top: "1.25rem",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              opacity: "80%",
            }}
          ></BackgroundImageFromUrl>
          {!exitState && (
            <BackgroundImageFromUrl
              className=" is-relative"
              objectId={
                IKMCMode
                  ? "admin/frontend-images/practice-ikmc/practice-ikmc-question-bg.svg"
                  : "admin/frontend-images/exercise/background-practice-smart-question.svg"
              }
              style={{
                ...commonStyle,
                width: "75rem",
                height: "calc(100% - 2.5rem)",
                background: "#EFEFEF",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                borderRadius: "7.5rem 7.5rem 0 0",
                padding: "3rem 3.13rem 3.31rem 3.12rem",
                top: "2.5rem",
                zIndex: "200",
              }}
            >
              <div
                className={`${IKMCMode
                  ? "has-background-kurio-main-color-white"
                  : "has-background-kurio-purple-light-3"
                  } is-justify-content-center`}
                style={{
                  ...commonStyle,
                  width: "100%",
                  height: "100%",
                  borderRadius: "5rem 5rem 2.5rem 2.5rem",
                  border: IKMCMode ? "4px solid #BCD9F1" : "4px solid #C4B5F2",
                  padding: "0 4.88rem 0 4.88rem",
                }}
              >
                <ScrollRef />

                <div
                  style={{
                    position: "absolute",
                    top: "-1rem",
                    zIndex: 1000,
                    left: "-2rem",
                  }}
                >
                  <ExerciseCloseButton onClick={handleEndPractice} />
                </div>
                <BackgroundImageFromUrl
                  objectId={
                    IKMCMode
                      ? ""
                      : "admin/frontend-images/exercise/exercise-score-small.svg"
                  }
                  style={{
                    ...commonStyle,
                    position: "absolute",
                    justifyContent: "center",
                    top: "-1.5rem",
                    right: "-1rem",
                    width: "9rem",
                    height: "9rem",
                    zIndex: "100",
                    backgroundImage: IKMCMode
                      ? "url('/images/practice-ikmc/ikmc-score-small.svg')"
                      : "",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <p
                    className="has-text-weight-bold mt-4"
                    style={{ fontSize: "1.75rem" }}
                  >
                    <span className="mr-2 has-text-kurio-purple-dark">
                      {correctAnswers}
                    </span>
                    <span className="has-text-kurio-purple-light-0">/</span>
                    <span className="ml-2 has-text-kurio-purple-light-0">
                      {seenQuestions}
                    </span>
                  </p>
                </BackgroundImageFromUrl>
                <div
                  style={{
                    position: "fixed",
                    zIndex: "100",
                    top: "-0.7rem",
                  }}
                >
                  {IKMCMode ? (
                    <img
                      src="/images/practice-ikmc/practice-ikmc-nav.svg"
                      alt="ikmc"
                    />
                  ) : (
                    <ImageFromUrl
                      objectId="admin/frontend-images/exercise/practice-smart-nav.svg"
                      alt="exercise"
                    />
                  )}
                </div>
                {!endPracticeState && (
                  <PracticeSmartNav
                    selected={selectedQuestion}
                    IKMCMode={IKMCMode}
                    handleQuestionChange={handleQuestionChange}
                  />
                )}
                {endPracticeState && (
                  <>
                    {((correctAnswers > 7 &&
                      listUserInfo?.premiumFrom &&
                      listUserInfo?.premiumTo &&
                      new Date(listUserInfo?.premiumFrom).getTime() <=
                      new Date().getTime() &&
                      new Date(listUserInfo?.premiumTo).getTime() >
                      new Date().getTime())
                    ) && (
                        <>
                          <div style={{ gap: "2.5rem" }} className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                            <div
                              className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center has-text-kurio-main-color-black m-auto"
                              style={{
                                gap: "4rem",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{ gap: "1.5rem" }}
                                className="is-flex is-flex-direction-column"
                              >
                                <p
                                  style={{ fontSize: "1.75rem", marginTop: "4rem", lineHeight: "normal" }}
                                  className="has-text-weight-bold"
                                >
                                  Hoàn thành xuất sắc <br />
                                  chuỗi 10 câu hỏi <br />
                                  Luyện tập Thông minh!
                                </p>

                                <div className="is-flex is-flex-direction-column" style={{ gap: "0.5rem" }}>
                                  <div className="has-background-kurio-main-color-white is-flex is-justify-content-center is-align-items-center" style={{ height: "3.5rem", padding: "0.5rem 1rem", gap: "1rem", borderRadius: "1rem", border: "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)", boxShadow: "2px 2px 0px 0px #0A2A66" }}>
                                    <p className="has-text-kurio-main-color-black has-text-weight-bold"
                                      style={{ fontSize: "1rem" }}>Làm đúng</p>
                                    <div>
                                      <div
                                        className="has-background-kurio-green-light-2 is-flex is-justify-content-center is-align-items-center"
                                        style={{ padding: "0.5rem", gap: "0.3rem", borderRadius: "0.5rem", fontSize: "1rem" }}
                                      >
                                        <span className="has-text-kurio-green-dark has-text-weight-bold">
                                          {correctAnswers}
                                        </span>
                                        <span className="has-text-kurio-green-dark has-text-weight-bold">/</span>
                                        <span className="has-text-kurio-green-dark has-text-weight-bold">
                                          {seenQuestions}
                                        </span>
                                      </div>
                                    </div>

                                    <p style={{ fontSize: "1rem" }} className="has-text-kurio-main-color-black has-text-weight-bold">câu hỏi</p>
                                  </div>

                                  <div className="has-background-kurio-main-color-white is-flex is-justify-content-center is-align-items-center" style={{ height: "3.5rem", padding: "0.5rem 1rem", gap: "1rem", borderRadius: "1rem", border: "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)", boxShadow: "2px 2px 0px 0px #0A2A66" }}>
                                    <p className="has-text-kurio-main-color-black has-text-weight-bold" style={{ fontSize: "1rem" }}>
                                      Điểm Elorio
                                    </p>

                                    <div className="has-text-kurio-main-color-purple has-background-kurio-purple-light-2 is-flex is-justify-content-center is-align-items-center" style={{ fontSize: "1rem", fontWeight: 700, gap: '0.625rem', padding: '0.5rem', borderRadius: '0.5rem' }}>
                                      {!showElo ? <img style={{ width: "2rem" }} src="/images/exercise/giphy.gif" alt="" /> :
                                        <p>{parseInt(listUserInfo?.elo)} <b
                                          style={parseInt(listUserInfo?.elo) - JSON.parse(localStorage.getItem('eloPractice')) >= 0 ? { color: "green" } : { color: "red" }}

                                        >({parseInt(parseInt(listUserInfo?.elo) - JSON.parse(localStorage.getItem('eloPractice'))) >= 0 ? '+' + parseInt(parseInt(listUserInfo?.elo) - JSON.parse(localStorage.getItem('eloPractice'))) :
                                          parseInt(parseInt(listUserInfo?.elo) - JSON.parse(localStorage.getItem('eloPractice')))})</b></p>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <ImageFromUrl
                                objectId="admin/frontend-images/exercise/end-practice-smart-correct-2.png"
                                alt=""
                              />
                            </div>

                            <div className="is-flex is-flex-direction-row is-align-items-center" style={{ gap: "1.5rem" }}>
                              <p className="has-text-kurio-main-color-black has-text-weight-medium" style={{ fontSize: "1rem" }}>Tiếp tục làm bài và tăng hạng Elorio?</p>

                              <PrimaryButtonPageLevel onClick={showPracticeSmartContinue} style={{ maxWidth: "21.3125rem", width: "21.3125rem", borderRadius: "1rem" }}>Xem 10 câu tiếp theo</PrimaryButtonPageLevel>
                            </div>
                          </div>

                        </>
                      )}
                    {((correctAnswers <= 7) ||
                      (listUserInfo?.premiumFrom &&
                        listUserInfo?.premiumTo &&
                        (new Date(listUserInfo?.premiumFrom).getTime() >
                          new Date().getTime() ||
                          new Date(listUserInfo?.premiumTo).getTime() <
                          new Date().getTime())) ||
                      listUserInfo.premiumFrom === null ||
                      listUserInfo.premiumTo === null) && (
                        <>
                          <div style={{ gap: "2.5rem" }} className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                            <div
                              className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center has-text-kurio-main-color-black m-auto"
                              style={{
                                gap: "4rem",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{ gap: "1.5rem" }}
                                className="is-flex is-flex-direction-column"
                              >
                                <p
                                  style={{ fontSize: "1.75rem", marginTop: "4rem", lineHeight: "normal" }}
                                  className="has-text-weight-bold"
                                >
                                  Bạn đã làm xong <br />
                                  chuỗi 10 câu hỏi <br />
                                  Luyện tập Thông minh!
                                </p>

                                <div className="is-flex is-flex-direction-column" style={{ gap: "0.5rem" }}>
                                  <div className="has-background-kurio-main-color-white is-flex is-justify-content-center is-align-items-center" style={{ height: "3.5rem", padding: "0.5rem 1rem", gap: "1rem", borderRadius: "1rem", border: "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)", boxShadow: "2px 2px 0px 0px #0A2A66" }}>
                                    <p className="has-text-kurio-main-color-black has-text-weight-bold"
                                      style={{ fontSize: "1rem" }}>Làm đúng</p>
                                    <div>
                                      <div
                                        className="has-background-kurio-green-light-2 is-flex is-justify-content-center is-align-items-center"
                                        style={{ padding: "0.5rem", gap: "0.3rem", borderRadius: "0.5rem", fontSize: "1rem" }}
                                      >
                                        <span className="has-text-kurio-green-dark has-text-weight-bold">
                                          {correctAnswers}
                                        </span>
                                        <span className="has-text-kurio-green-dark has-text-weight-bold">/</span>
                                        <span className="has-text-kurio-green-dark has-text-weight-bold">
                                          {seenQuestions}
                                        </span>
                                      </div>
                                    </div>

                                    <p style={{ fontSize: "1rem" }} className="has-text-kurio-main-color-black has-text-weight-bold">câu hỏi</p>
                                  </div>

                                  <div className="has-background-kurio-main-color-white is-flex is-justify-content-center is-align-items-center" style={{ height: "3.5rem", padding: "0.5rem 1rem", gap: "1rem", borderRadius: "1rem", border: "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)", boxShadow: "2px 2px 0px 0px #0A2A66" }}>
                                    <p className="has-text-kurio-main-color-black has-text-weight-bold" style={{ fontSize: "1rem" }}>
                                      Điểm Elorio
                                    </p>

                                    <div className="has-text-kurio-main-color-purple has-background-kurio-purple-light-2 is-flex is-justify-content-center is-align-items-center" style={{ fontSize: "1rem", fontWeight: 700, gap: '0.625rem', padding: '0.5rem', borderRadius: '0.5rem' }}>
                                      {!showElo ? <img style={{ width: "2rem" }} src="/images/exercise/giphy.gif" alt="" /> :
                                        <p>{parseInt(listUserInfo?.elo)} <b
                                          style={parseInt(listUserInfo?.elo) - JSON.parse(localStorage.getItem('eloPractice')) >= 0 ? { color: "green" } : { color: "red" }}

                                        >({parseInt(parseInt(listUserInfo?.elo) - JSON.parse(localStorage.getItem('eloPractice'))) >= 0 ? '+' + parseInt(parseInt(listUserInfo?.elo) - JSON.parse(localStorage.getItem('eloPractice'))) :
                                          parseInt(parseInt(listUserInfo?.elo) - JSON.parse(localStorage.getItem('eloPractice')))})</b></p>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <ImageFromUrl
                                objectId="admin/frontend-images/exercise/end-practice-smart-correct-1.png"
                                alt=""
                              />
                            </div>

                            <div className="is-flex is-flex-direction-row is-align-items-center" style={{ gap: "1.5rem" }}>
                              <p className="has-text-kurio-main-color-black has-text-weight-medium" style={{ fontSize: "1rem" }}>Tiếp tục làm bài và tăng hạng Elorio?</p>

                              <PrimaryButtonPageLevel onClick={showPracticeSmartContinue} style={{ maxWidth: "21.3125rem", width: "21.3125rem", borderRadius: "1rem" }}>Xem 10 câu tiếp theo</PrimaryButtonPageLevel>
                            </div>
                          </div>

                        </>
                      )}
                  </>
                )}
                {!endPracticeState && !exitState && (
                  <PracticeContent
                    question={questions[selectedQuestion]}
                    questions={questions}
                    seenQuestions={seenQuestions}
                    handleQuestionChange={handleQuestionChange}
                    closeModal={closeModal}
                    showEndPractice={showEndPractice}
                    submitAnswers={submitAnswers}
                  />
                )}
              </div>
            </BackgroundImageFromUrl>
          )}
        </BackgroundImageFromUrl>
      )}
      {/* {questions === null && (
        <div className="has-text-center has-text-kurio-main-color-black has-text-weight-bold">
          Loading...
        </div>
      )} */}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    questions: state.practiceElective.questions,
    practiceImages: state.practiceElective.practiceImages,
    seenQuestions: state.practiceElective.seenQuestions,
    correctAnswers: state.practiceElective.correctAnswers,
    listUserInfo: state.grades.listUserInfo,
  };
}

export default connect(mapStateToProps)(PracticeSmartMainWrapper);
