import React, { useEffect, useRef, useState } from "react";

const Otp = ({ value, onChange, resetFocus }) => {
    var inputStyle = {
        width: "3rem",
        height: "3rem",
        borderRadius: "0.5rem",
        color: "#0A2A66",
        border: "0.0625rem solid #0A2A66",
        boxShadow: "none",
    };
    const [focus, setFocus] = useState(true);
    const [indexFocus, setIndexFocus] = useState(0);
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const handleFocus = (index) => {
        setIndexFocus(index)
        setFocus(true)
    };
    const handleBlur = (index) => {
        if (indexFocus !== null && index === indexFocus && value.length === 0) {
            input1Ref.current.style.backgroundColor = "#FAFBFC";
            input2Ref.current.style.backgroundColor = "#FAFBFC";
            input3Ref.current.style.backgroundColor = "#FAFBFC";
            input4Ref.current.style.backgroundColor = "#FAFBFC";
            input5Ref.current.style.backgroundColor = "#FAFBFC";
            input6Ref.current.style.backgroundColor = "#FAFBFC";
            setFocus(false);
        }
    };
    const handleChange = (value, index, name) => {
        if (onChange) {
            onChange(value, index, name);
        }
    };
    useEffect(() => {
        if((focus && (indexFocus === 0)) || resetFocus){
          input1Ref.current.focus();
        }
        if (
            document.activeElement === input1Ref.current ||
            document.activeElement === input2Ref.current ||
            document.activeElement === input3Ref.current ||
            document.activeElement === input4Ref.current ||
            document.activeElement === input5Ref.current ||
            document.activeElement === input6Ref.current 
        ) {
            input1Ref.current.style.backgroundColor = "#E9EEF8";
            input2Ref.current.style.backgroundColor = "#E9EEF8";
            input3Ref.current.style.backgroundColor = "#E9EEF8";
            input4Ref.current.style.backgroundColor = "#E9EEF8";
            input5Ref.current.style.backgroundColor = "#E9EEF8";
            input6Ref.current.style.backgroundColor = "#E9EEF8";
        }
    }, [focus, indexFocus, resetFocus]);
    return (
        <div
            className="columns p-0 m-0"
            style={{ width: "20.5rem", gap: "0.5rem" }}
        >
            <input
                style={inputStyle}
                className="input has-text-weight-bold has-text-centered column "
                type="text"
                ref={input1Ref}
                maxLength={1}
                value={value[0] || ""}
                required
                onFocus={()=>{handleFocus(0)}}
                onBlur={()=>{handleBlur(0)}}
                onChange={(e) => {
                    handleChange(e.target.value, 0, "");
                    if (e.target.value.length >= 1) {
                        input2Ref.current?.focus();
                    }
                }}
            />

            <input
                style={inputStyle}
                className="input has-text-weight-bold has-text-centered column "
                ref={input2Ref}
                type="text"
                maxLength={1}
                value={value[1] || ""}
                required
                onFocus={()=>{handleFocus(1)}}
                onBlur={()=>{handleBlur(1)}}
                onChange={(e) => {
                    handleChange(e.target.value, 1, "");
                    if (e.target.value.length >= 1) {
                        input3Ref.current?.focus();
                    }
                }}
            />

            <input
                style={inputStyle}
                className="input has-text-weight-bold has-text-centered column "
                ref={input3Ref}
                type="text"
                maxLength={1}
                value={value[2] || ""}
                required
                onFocus={()=>{handleFocus(2)}}
                onBlur={()=>{handleBlur(2)}}
                onChange={(e) => {
                    handleChange(e.target.value, 2, "");
                    if (e.target.value.length >= 1) {
                        input4Ref.current?.focus();
                    }
                }}
            />

            <input
                style={inputStyle}
                className="input has-text-weight-bold has-text-centered column "
                ref={input4Ref}
                type="text"
                maxLength={1}
                value={value[3] || ""}
                required
                onFocus={()=>{handleFocus(3)}}
                onBlur={()=>{handleBlur(3)}}
                onChange={(e) => {
                    handleChange(e.target.value, 3, "");
                    if (e.target.value.length >= 1) {
                        input5Ref.current?.focus();
                    }
                }}
            />

            <input
                style={inputStyle}
                className="input has-text-weight-bold has-text-centered column "
                ref={input5Ref}
                type="text"
                maxLength={1}
                value={value[4] || ""}
                required
                onFocus={()=>{handleFocus(4)}}
                onBlur={()=>{handleBlur(4)}}
                onChange={(e) => {
                    handleChange(e.target.value, 4, "");
                    if (e.target.value.length >= 1) {
                        input6Ref.current?.focus();
                    }
                }}
            />

            <input
                style={inputStyle}
                className="input has-text-weight-bold has-text-centered column "
                ref={input6Ref}
                type="text"
                maxLength={1}
                value={value[5] || ""}
                required
                onFocus={()=>{handleFocus(5)}}
                onBlur={()=>{handleBlur(5)}}
                onChange={(e) => {
                    handleChange(e.target.value, 5, "");
                    if (e.target.value.length >= 1) {
                        input6Ref.current?.focus();
                    }
                }}
            />
        </div>
    );
};

export default Otp;
