import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import QuestionNumberTag from "../../detail-components/QuestionNumberTag";
import ScrollRef from "../../detail-components/ScrollRef";
import jsonParser from "../helper/jsonParser";

function QPrompt3_1({ data, order, isLast }) {
  const content = data?.content || data?.prompt;
  const contentText =
    content?.texts[0] !== null &&
      content?.texts[0] !== undefined &&
      content?.texts[0]?.length > 0
      ? jsonParser(content?.texts[0])
      : content?.texts[0];


  return (
    <div>
      {!isLast &&
        <ScrollRef />}
      <BackgroundImageFromUrl
        objectId={content?.backgroundImage}
        className="has-text-centered has-background-kurio-main-color-white is-flex is-flex-direction-column is-justify-content-center is-align-items-center has-text-kurio-main-color-black "
        style={{
          borderRadius: "1rem 1rem 0 0",
          padding: "2rem",
          fontSize: "1.25rem",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        {data?.template.question !== null &&
          data?.template.question &&
          data?.template.question.length > 0 && (
            <div className="mb-4">
              <QuestionNumberTag number={order === 0 ? data?.order : order} />
            </div>
          )}

        <div className="content" style={{ maxWidth: "40rem" }}>
          {contentText}
        </div>
      </BackgroundImageFromUrl>
    </div>
  );
}

export default QPrompt3_1;
