import React, { useState } from "react";
import Cookies from "universal-cookie";
import PrimaryButtonPageLevel from "./button/primary-button/PrimaryButtonLevelPage";
import PopupCloseButton from "./button/special-button/PopupCloseButton";

const DuplicateLoginErrorComponent = ({ isShowPopup }) => {
  const [showPopup, setShowPopup] = useState(isShowPopup ? isShowPopup : false);
  const removePopup = () => {
    setShowPopup(false);
    const cookies = new Cookies();
    const listCookies = cookies.getAll();
    Object.keys(listCookies).forEach((cookie) => {
      cookies.remove(cookie);
    });
    const arrayPath = [
      "/account",
      "/profile",
      "/upgrade",
      "/list-unit",
      "/exercise",
      "/activities",
      "/achievement",
      "/",
    ];
    localStorage.clear();
    arrayPath
      .forEach((path) => {
        if (cookies.get("signinUser", { path: path })) {
          cookies.remove("signinUser", { path: path });
        }
      })
      ?.then(() => {
        window.location.href =
          process.env.REACT_APP_ENV === "development"
            ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
            : process.env.REACT_APP_LANDING_PAGE_URL;
      })
      .catch((error) => {
        console.error("Error removing cookie:", error);
      });
    const check = arrayPath.every((path) =>
      cookies.get("signinUser", { path: path })
    );
    if (!check) {
      window.location.href =
        process.env.REACT_APP_ENV === "development"
          ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
          : process.env.REACT_APP_LANDING_PAGE_URL;
    }
  };
  return (
    <>
      {showPopup && (
        <div className=" modal is-active  ">
          <div
            className="modal-background"
            // onClick={removePopup}
            style={{ opacity: 0.8 }}
          ></div>
          <div
            className="is-flex is-flex-direction-column has-text-centered is-relative is-align-items-center has-text-kurio-main-color-black is-flex-gap-6 has-background-kurio-main-color-white is-justify-content-center"
            style={{
              borderRadius: "1rem",
              width: "31.1875rem",
              height: "24rem",
              padding: "2.5rem",
            }}
          >
            <h2
              className=" has-text-weight-bold"
              style={{
                fontSize: "2.25rem",
                lineHeight: "normal",
                fontStyle: "normal",
              }}
            >
              ! Tài khoản của bạn đã được đăng nhập trên thiết bị khác !
            </h2>
            <PrimaryButtonPageLevel
              style={{ width: "20rem", height: "4rem", maxWidth: "20rem" }}
              onClick={removePopup}
            >
              Đóng
            </PrimaryButtonPageLevel>
            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DuplicateLoginErrorComponent;
