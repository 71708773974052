import React, { useState } from "react";
import BackgroundImageFromUrl from "../../parseImage/BackgroundImageFromUrl";

const TertiaryButtonLevelSection = ({
  disabled,
  prefix,
  suffix,
  onClick,
  children,
}) => {
  const [defaultState, setDefaultState] = useState(disabled ? false : true);
  const [hoveredState, setHoveredState] = useState(false);
  const [clickedState, setClickedState] = useState(false);
  const audio = new Audio("/sounds/click.mp3");
  var style = {
    width: "5.625rem",
    height: "1.25rem",
    border: "none",
    boxShadow: "none",
    backgroundColor: "transparent",
  };
  var prefixStyle = {
    minWidth: "1rem",
    height: "1rem",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: disabled
      ? "#8C9DC1"
      : clickedState
      ? "#0A2A66"
      : hoveredState
      ? "#572CD1"
      : "#0A2A66",
  };
  var suffixStyle = {
    minWidth: "1rem",
    height: "1rem",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: disabled
      ? "#8C9DC1"
      : clickedState
      ? "#0A2A66"
      : hoveredState
      ? "#572CD1"
      : "#0A2A66",
  };
  const handleMouseEnter = () => {
    if (!clickedState && !disabled) {
      setDefaultState(false);
      setHoveredState(true);
    }
  };
  const handleMouseLeave = () => {
    if (!clickedState && !disabled) {
      setDefaultState(true);
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    if (!disabled) {
      audio.play();
      setClickedState(!clickedState);
      setHoveredState(false);
      setDefaultState(clickedState);
      if (onClick) {
        onClick();
      }
    }
  };

  return (
    <div
      className="main is-flex is-flex-direction-row is-align-items-center "
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ maxWidth: "5.625rem" }}
    >
      {prefix && (
        <BackgroundImageFromUrl
          objectId={prefix}
          style={prefixStyle}
        ></BackgroundImageFromUrl>
      )}
      <button
        className={`${
          defaultState
            ? "has-text-secondary-button-clicked-color"
            : hoveredState
            ? "has-text-primary-button-hovered-color"
            : clickedState
            ? "has-text-secondary-button-clicked-color"
            : disabled
            ? "has-text-button-disabled-text-color"
            : ""
        } is-underlined button main-color is-capitalized has-text-centered`}
        style={style}
        onClick={handleClick}
      >
        {children}
      </button>
      {suffix && (
        <BackgroundImageFromUrl
          objectId={suffix}
          style={suffixStyle}
        ></BackgroundImageFromUrl>
      )}
    </div>
  );
};

export default TertiaryButtonLevelSection;
