import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getGradeCategory,
  getGradeUnits,
  getLastLesson,
  getListUnit,
} from "../config/ListLesson";
import { getUserInfo } from "../config/auth";

const initialState = {
  status: "idle",
  lessons: null,
  grades: null,
  units: null,
  gradeId: null,
  unitId: null,
  listUserInfo: null,
  gradeName: "",
  isChangeGrade: false,
  lastlesson: null,
  gradeCategory: null,
  error: null,
};

export const getAnUnit = createAsyncThunk(
  "grades/getAnUnit",
  async ({ unitId }, thunkAPI) => {
    try {
      const { result } = await getListUnit(unitId);
      return result;
    } catch (error) {
      if (error?.response.status === 409) {
        return { error: 409 };
      } else {
        return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
      }
    }
  }
);

export const getListGradeUnits = createAsyncThunk(
  "grades/getListGradeUnits",
  async ({ grades, page, size }, thunkAPI) => {
    try {
      const { result } = await getGradeUnits(grades, page, size);
      return result.records;
    } catch (error) {
      if (error?.response.status === 409) {
        return { error: 409 };
      } else {
        return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
      }
    }
  }
);

// export const getListUnits = createAsyncThunk(
//   "grades/getListUnits",
//   async ({gradeId, page, size, token}, thunkAPI)=>{
//     try {
//       const {result} = await getGradeUnits(gradeId, page, size, token)
//       return result
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.response?.data?.errorMessage)
//     }
//   }
// )

export const getGradeUserInfo = createAsyncThunk(
  "grades/getGradeUserInfo",
  async (thunkAPI) => {
    try {
      const { result } = await getUserInfo();
      return result;
    } catch (error) {
      if (error?.response.status === 409) {
        return { error: 409 };
      } else {
        return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
      }
    }
  }
);
export const getAllGrade = createAsyncThunk(
  "grades/getAllGrade",
  async (thunkAPI) => {
    try {
      const { result } = await getGradeCategory();
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);
export const getAllLesson = createAsyncThunk(
  "grades/getAllLesson",
  async ({ listUnitId }, thunkAPI) => {
    try {
      var list = [];
      await Promise.all(
        listUnitId.map(async (item) => {
          const { result } = await getListUnit(item);
          list = list.concat(result.lessons);
          return list;
        })
      );
      return list;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const getListLastLesson = createAsyncThunk(
  "grades/getListLastLesson",
  async (thunkAPI) => {
    try {
      const { result } = await getLastLesson();
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const gradesSlice = createSlice({
  name: "grades",
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setLastLesson: (state, action) => {
      state.lastlesson = action.payload;
    },

    setAnUnits: (state, action) => {
      state.status = action.payload;
    },

    clearStateGrade: (state) => {
      state.unitId = null;
      state.lessons = null;
      state.units = null;
      state.listUserInfo = null;
      state.grades = null;
      state.lastlesson = null;
      state.gradeId = null;
    },
    clearGrades: (state) => {
      state.grades = null;
    },
    clearLessons: (state) => {
      state.lessons = null;
      state.lastlesson = null;
      state.unitId = null;
      state.units = null;
    },
    clearUnits: (state) => {
      state.units = null;
    },

    setGradeUserInfo: (state, action) => {
      state.status = action.payload;
    },
    setIsChangeGrade: (state, action) => {
      state.isChangeGrade = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAnUnit.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAnUnit.fulfilled, (state, action) => {
        if (action.payload?.error === 409) {
          state.error = 409;
        } else {
          state.status = "succeeded";
          state.unitId = action.payload._id;
          state.lessons = action.payload.lessons;
          state.units = action.payload;
        }
      })

      .addCase(getAnUnit.rejected, (state, action) => {
        state.status = "failed";
        state.lesson = action.payload;
      })

      .addCase(getGradeUserInfo.fulfilled, (state, action) => {
        if (action.payload?.error === 409) {
          state.error = 409;
        } else if (
          action.payload?.gradeId !== null &&
          action.payload?.gradeId?.length > 0 &&
          action.payload?.fullName?.length > 0 &&
          action.payload?.birthday?.length > 0 &&
          action.payload?.gradeName?.length > 0
        ) {
          state.listUserInfo = action.payload;
          state.gradeId = action.payload.gradeId;
          state.gradeName = action.payload.gradeName;
        }
      })
      // .addCase(getListUnits.pending, (state)=>{
      //   state.status = "loading"
      // })
      // .addCase(getListUnits.rejected, (state)=>{
      //   state.status = "failed"
      // })
      // .addCase(getListUnits.fulfilled, (state, action)=>{
      //   state.status = "succeeded"
      //   state.units = action.payload.records
      // })
      .addCase(getAllLesson.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllLesson.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getAllLesson.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.lessons = action.payload;
      })
      .addCase(getListGradeUnits.fulfilled, (state, action) => {
        state.grades = action.payload;
      })

      .addCase(getListLastLesson.fulfilled, (state, action) => {
        state.lastlesson = action.payload;
      })
      .addCase(getAllGrade.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllGrade.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getAllGrade.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.gradeCategory = action.payload;
      });
  },
});

export const {
  setIsChangeGrade,
  clearStateGrade,
  clearGrades,
  clearLessons,
  clearUnits,
  setLastLesson,
} = gradesSlice.actions;
export const selectLessons = (state) => state.lessons;
export const selectStatus = (state) => state.questions.status;
export const selectUnits = (state) => state.grades.units;

export default gradesSlice.reducer;
