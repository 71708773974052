/* eslint-disable react/jsx-pascal-case */
import { memo } from "react";
import MainContent10MemoryCard from "../../../templates/content/MainContent10MemoryCard";
import MainContent11Mixed from "../../../templates/content/MainContent11Mixed";
import MainContent2Image from "../../../templates/content/MainContent2Image";
import MainContent3Text from "../../../templates/content/MainContent3Text";
import MainContent4ImageTextLarge from "../../../templates/content/MainContent4ImageTextLarge";
import MainContent5ImageTextNormal from "../../../templates/content/MainContent5ImageTextNormal";
import MainContent6VideoNormal from "../../../templates/content/MainContent6VideoNormal";
import MainContent7VideoPopupThumbnail from "../../../templates/content/MainContent7VideoPopupThumbnail";
import MainContent8VideoPopup from "../../../templates/content/MainContent8VideoPopup";
import MainContent9Conclusion from "../../../templates/content/MainContent9Conclusion";
import QPrompt1_1 from "../../../templates/content/QPrompt1_1";
import QPrompt1_2 from "../../../templates/content/QPrompt1_2";
import QPrompt1_3 from "../../../templates/content/QPrompt1_3";
import QPrompt2_1 from "../../../templates/content/QPrompt2_1";
import QPrompt2_2 from "../../../templates/content/QPrompt2_2";
import QPrompt2_3 from "../../../templates/content/QPrompt2_3";
import QPrompt2_4 from "../../../templates/content/QPrompt2_4";
import QPrompt3_1 from "../../../templates/content/QPrompt3_1";
import QPrompt3_2 from "../../../templates/content/QPrompt3_2";

export const ContentWrapper = memo(function ContentWrapper({
  data,
  isLast,
  order,
}) {
  // read from data["Template"]["Content"] and data["Template"]["Question"]
  // to refer to the content and question template in templates/content and templates/question
  var contentTemplate = data?.template.content || data?.template.prompt;

  const TemplateComponent = () => {
    switch (contentTemplate) {
      case "QPrompt1_1":
        return <QPrompt1_1 data={data} order={order} isLast={isLast} />;
      case "QPrompt1_2":
        return <QPrompt1_2 data={data} order={order} isLast={isLast} />;
      case "QPrompt1_3":
        return <QPrompt1_3 data={data} order={order} isLast={isLast} />;
      case "QPrompt2_1":
        return <QPrompt2_1 data={data} order={order} isLast={isLast} />;
      case "QPrompt2_2":
        return <QPrompt2_2 data={data} order={order} isLast={isLast} />;
      case "QPrompt2_3":
        return <QPrompt2_3 data={data} order={order} isLast={isLast} />;
      case "QPrompt2_4":
        return <QPrompt2_4 data={data} order={order} isLast={isLast} />;
      case "QPrompt3_1":
        return <QPrompt3_1 data={data} order={order} isLast={isLast} />;
      case "QPrompt3_2":
        return <QPrompt3_2 data={data} order={order} isLast={isLast} />;
      case "MainContent2Image":
        return <MainContent2Image data={data} isLast={isLast} />;
      case "MainContent3Text":
        return <MainContent3Text data={data} isLast={isLast} />;
      case "MainContent4ImageTextLarge":
        return <MainContent4ImageTextLarge data={data} isLast={isLast} />;
      case "MainContent5ImageTextNormal":
        return <MainContent5ImageTextNormal data={data} isLast={isLast} />;
      case "MainContent6VideoNormal":
        return <MainContent6VideoNormal data={data} isLast={isLast} />;
      case "MainContent7VideoPopupThumbnail":
        return <MainContent7VideoPopupThumbnail data={data} isLast={isLast} />;
      case "MainContent8VideoPopup":
        return <MainContent8VideoPopup data={data} isLast={isLast} />;
      case "MainContent9Conclusion":
        return <MainContent9Conclusion data={data} isLast={isLast} />;
      case "MainContent10MemoryCard":
        return <MainContent10MemoryCard data={data} isLast={isLast} />;
      case "MainContent11Mixed":
        return <MainContent11Mixed data={data} isLast={isLast} />;
      default:
        return <div></div>;
    }
  };

  return (
    <div>
      <TemplateComponent />
    </div>
  );
});

export default ContentWrapper;
