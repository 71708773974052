import instance from "./instance";

const userUrl = "/auth/v1/user";

export const registerPhone = (phone) => {
  return instance.post(`${userUrl}/account/create-register`, phone);
};
export const confirmRegisterOtp = (data) => {
  return instance.post(`${userUrl}/account/confirm-register-otp`, data);
};
export const registerInfo = (data) => {
  return instance.put(`${userUrl}/account/register`, data);
};
export const createValidateEmail = (email, token) => {
  return instance.post(`${userUrl}/account/create-validate-email`, email);
};
export const validateEmail = (otp, token) => {
  return instance.post(`${userUrl}/account/validate-email`, otp);
};
export const createValidatePhone = (email, token) => {
  return instance.post(`${userUrl}/account/create-validate-phone`, email);
};
export const validatePhone = (otp, token) => {
  return instance.post(`${userUrl}/account/validate-phone`, otp);
};
export const createResetPasswordPhone = (phone) => {
  return instance.post(`${userUrl}/account/create-reset-password-phone`, phone);
};
export const confirmResetPasswordPhone = (phone) => {
  return instance.post(`${userUrl}/account/confirm-reset-password-phone-otp`, phone);
};
export const resetPasswordPhone = (newPassword) => {
  return instance.post(`${userUrl}/account/reset-password-phone`, newPassword);
};
export const createResetPasswordEmail = (email) => {
  return instance.post(`${userUrl}/account/create-reset-password-email`, email);
};
export const checkResetPasswordRequest = (check) => {
  return instance.post(`${userUrl}/account/check-reset-password-email-request`, check);
};
export const resetPasswordEmail = (newPassword) => {
  return instance.post(`${userUrl}/account/reset-password-email`, newPassword);
};
export const signin = (account) => {
  return instance.post(`${userUrl}/auth/token`, account);
};
export const getUserInfo = () => {
  return instance.get(`${userUrl}/account/get-user-info`);
};
export const editUserInfo = (data) => {
  return instance.post(`${userUrl}/account/edit-user-info`, data);
};
export const changePassword = (data) => {
  return instance.post(`${userUrl}/account/change-password`, data);
};

export const deleteAccount = (data) => {
  return instance.post(`${userUrl}/account/create-delete-account-request`, data);
};
