import React, { useState } from "react";
import PrimaryButtonLevelSection from "../../components/button/primary-button/PrimaryButtonLevelSection";
import ScrollRefWrapper from "../../detail-components/ScrollRefWrapper";
import "./assets/css/main.css";

var colorTextH1 = "#572CD1";
var colorWhite = "#FAFBFC";

const RegisterMobilePage = () => {
  const [showPopupDownload, setShowPopupDownload] = useState(true);

  const handleClosePopupDownload = () => {
    setShowPopupDownload(false);
  };
  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  const handleDownloadApp = () => {
    var mobileOS = getMobileOperatingSystem();

    if (mobileOS === "Android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=vn.kurio.android";
    } else if (mobileOS === "iOS") {
      window.location.href =
        "https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573";
    }
  };

  const handleClick = () => {
    window.location.href = process.env.REACT_APP_LANDING_PAGE_URL;
  };

  return (
    <>
      <ScrollRefWrapper />
      <div
        className="wrapper-content is-flex is-align-items-center is-justify-content-center"
        style={{ backgroundColor: "#F1EEFC" }}
      >
        {/* Mobile landing page */}
        {showPopupDownload && (
          <div
            style={{
              position: "fixed",
              bottom: "0",
              width: "100%",
              zIndex: 1000,
            }}
          >
            <div
              id="bottom-popup"
              className="is-relative"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                className="icon-cancel is-flex is-justify-content-center is-align-items-center"
                onClick={handleClosePopupDownload}
                style={{
                  position: "absolute",
                  zIndex: 2,
                  right: "1rem",
                  top: "15%",
                  width: "1.5rem",
                  height: "1.5rem",
                  borderRadius: "1rem 1rem 0rem 0rem",
                  background: "#E8E3F8",
                }}
              >
                <img src="/images/landing-page/icon-cancel.svg" alt="" />
              </span>
              <div
                style={{
                  position: "relative",
                  maxWidth: "100%",
                  width: "100%",
                  bottom: "-0.7rem",
                }}
              >
                <img
                  style={{ width: "100%" }}
                  src="/images/landing-page/image-mobile-popup.svg"
                  alt=""
                />
                <button
                  className="btn-mobile-popup is-flex is-align-items-center is-justify-content-center"
                  onClick={handleDownloadApp}
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    top: "60%",
                    right: "5%",
                    transform: "translateY(-50%)",
                    gap: "0.5rem",
                    cursor: "pointer",
                    boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px",
                    width: "11.5rem",
                    height: "2.5rem",
                    borderRadius: "1rem",
                    border: "1px solid #0A2A66",
                    background: colorTextH1,
                  }}
                >
                  <img src="/images/landing-page/icon-download.svg" alt="" />
                  <p
                    style={{
                      color: colorWhite,
                      fontSize: "1rem",
                      fontWeight: 700,
                      textTransform: "uppercase",
                    }}
                  >
                    tải app ngay
                  </p>
                </button>
              </div>
            </div>
          </div>
        )}
        <div
          className="is-relative"
          style={{ maxWidth: "33.75rem", zIndex: 1 }}
        >
          <div
            className="has-text-kurio-main-color-black has-text-weight-bold  is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
            style={{
              minHeight: "100vh",
              padding: "2.75rem 0rem",
              backgroundColor: "#F1EEFC",
            }}
          >
            <div
              className="is-flex is-flex-direction-column is-flex-gap-5 is-align-items-flex-start"
              style={{ width: "20rem" }}
            >
              <div
                className="is-flex is-flex-direction-column is-flex-gap-2"
                style={{
                  lineHeight: "normal",
                  fontStyle: "normal",
                  fontSize: "0.875rem",
                }}
              >
                <div className="" style={{ marginBottom: "1rem" }}>
                  <iframe
                    title="register-form"
                    src="https://docs.google.com/forms/d/e/1FAIpQLSd99Js1SbExqSNbTnA8GQbiO47ZgfiHlNiDa4fJ7iyPjB8YTg/viewform?embedded=true"
                    width="320"
                    height={window.innerHeight}
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                  >
                    Loading…
                  </iframe>
                </div>
                <div className=" is-flex is-flex-gap-4 has-text-kurio-main-color-black has-text-weight-bold is-align-items-center">
                  <img src="/images/error/icon-zalo.svg" alt="" />
                  <p>0971 500 120</p>
                </div>
                <div className=" is-flex is-flex-gap-4 has-text-kurio-main-color-black has-text-weight-bold is-align-items-center">
                  <img src="/images/error/icon-fb.svg" alt="" />
                  <a
                    className="has-text-kurio-main-color-black is-underlined"
                    target="_blank"
                    href="https://www.facebook.com/kurio.vn"
                    rel="noreferrer"
                  >
                    facebook.com/kurio.vn
                  </a>
                </div>
              </div>
              <PrimaryButtonLevelSection
                onClick={handleClick}
                style={{
                  width: "20rem",
                  maxWidth: "20rem",
                  height: "2.5rem",
                  marginBottom:"8rem"
                }}
              >
                Về trang chủ
              </PrimaryButtonLevelSection>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterMobilePage;
