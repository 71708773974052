import instance from "./instance";

const roadMapUrl = "/practice/v1/user/road-map";
const roadMapAnonymousUrl = "/practice/v1/user/road-map/anonymous";
const takeRoadMapUrl = "/practice/v1/user/take-road-map-exam";
const takeRoadMapAnonymousUrl = "/practice/v1/user/take-road-map-exam/anonymous";
const takeProblemSetUrl = "/practice/v1/user/take-road-map-problem-set";
const takeProblemSetAnonymousUrl = "/practice/v1/user/take-road-map-problem-set/anonymous";
const roadMapCategoryUrl = "/practice/v1/user/road-map-category"
const headersConfig = (anonymousUserValue) => {
  return {
    headers: {
      anonymousUser: anonymousUserValue,
    },
  };
};

export const getAllRoadMapCategory = ()=>{
  return instance.get(`${roadMapCategoryUrl}/get-all-road-map-categorys`)
}

export const getARoadMap = (gradeId, isAnonymous, anonymousUserId, categoryId) => {
  if (isAnonymous) {
    return instance.get(
      `${roadMapAnonymousUrl}/get-a-road-map/${gradeId}?categoryId=${categoryId}`,
      headersConfig(anonymousUserId)
    );
  } else {
    return instance.get(`${roadMapUrl}/get-a-road-map/${gradeId}?categoryId=${categoryId}`);
  }
};

export const resetRoadMap = (roadMapId, isAnonymous, anonymousUserId) => {
  if (isAnonymous) {
    return instance.post(
      `${roadMapAnonymousUrl}/reset-a-road-map/${roadMapId}`,
      headersConfig(anonymousUserId)
    );
  } else {
    return instance.post(`${roadMapUrl}/reset-a-road-map/${roadMapId}`);
  }
};

export const takeExamRoadMap = (roadmapId, examId, isAnonymous, anonymousUserId) => {
  if (isAnonymous) {
    return instance.put(`${takeRoadMapAnonymousUrl}/take-exam/${roadmapId}/${examId}`, {},
      headersConfig(anonymousUserId)
    );
  } else {
    return instance.put(`${takeRoadMapUrl}/take-exam/${roadmapId}/${examId}`);
  }
};
export const getRoadMapExamQuestions = (examId, page, size, isAnonymous, anonymousUserId) => {
  if (isAnonymous) {
    return instance.get(
      `${takeRoadMapAnonymousUrl}/get-exam-questions/${examId}?page=${page}&size=${size}`,
      headersConfig(anonymousUserId)
    );
  } else {
    return instance.get(
      `${takeRoadMapUrl}/get-exam-questions/${examId}?page=${page}&size=${size}`
    );
  }
};
export const submitRoadMapExamAnswers = (examId, answers, isAnonymous, anonymousUserId) => {
  if (isAnonymous) {
    return instance.post(
      `${takeRoadMapAnonymousUrl}/submit-exam-answers/${examId}`, answers,
      headersConfig(anonymousUserId)
    );
  } else {
    return instance.post(
      `${takeRoadMapUrl}/submit-exam-answers/${examId}`,
      answers
    );
  }
};
export const getRoadMapExamSolutions = (examId, page, size, isAnonymous, anonymousUserId) => {
  if (isAnonymous) {
    return instance.get(`${takeRoadMapAnonymousUrl}/get-exam-solutions/${examId}?page=${page}&size=${size}`,
      headersConfig(anonymousUserId)
    );
  } else {
    return instance.get(`${takeRoadMapUrl}/get-exam-solutions/${examId}?page=${page}&size=${size}`);
  }
};
export const takeProblemSet = (roadmapId, examId, problemSetId, isAnonymous, anonymousUserId) => {
  if (isAnonymous) {
    return instance.put(`${takeProblemSetAnonymousUrl}/take-problem-set/${roadmapId}/${examId}/${problemSetId}`, {},
      headersConfig(anonymousUserId)
    );
  } else {
    return instance.put(`${takeProblemSetUrl}/take-problem-set/${roadmapId}/${examId}/${problemSetId}`);
  }
};
export const getProblemSetQuestions = (problemSetId, page, size, isAnonymous, anonymousUserId) => {
  if (isAnonymous) {
    return instance.get(
      `${takeProblemSetAnonymousUrl}/get-problem-set-questions/${problemSetId}?page=${page}&size=${size}`,
      headersConfig(anonymousUserId)
    );
  } else {
    return instance.get(
      `${takeProblemSetUrl}/get-problem-set-questions/${problemSetId}?page=${page}&size=${size}`
    );
  }
};
export const submitProblemSetAnswers = (problemSetId, answers, isAnonymous, anonymousUserId) => {
  if (isAnonymous) {
    return instance.post(
      `${takeProblemSetAnonymousUrl}/submit-problem-set-answers/${problemSetId}`, answers,
      headersConfig(anonymousUserId)
    );
  } else {
    return instance.post(
      `${takeProblemSetUrl}/submit-problem-set-answers/${problemSetId}`,
      answers
    );
  }
};
export const getProblemSetSolutions = (problemSetId, page, size, isAnonymous, anonymousUserId) => {
  if (isAnonymous) {
    return instance.get(`${takeProblemSetAnonymousUrl}/get-problem-set-solutions/${problemSetId}?page=${page}&size=${size}`,
      headersConfig(anonymousUserId)
    );
  } else {
    return instance.get(`${takeProblemSetUrl}/get-problem-set-solutions/${problemSetId}?page=${page}&size=${size}`);
  }
};

export const restartProblemSets = (problemSetId, isAnonymous, anonymousUserId) => {
  if (isAnonymous) {
    return instance.post(`${takeProblemSetAnonymousUrl}/restart/${problemSetId}`, [],
      headersConfig(anonymousUserId)
    );
  } else {
    return instance.post(`${takeProblemSetUrl}/restart/${problemSetId}`, []);
  }
};
