import { decodeToken } from "react-jwt";
import instance from "./instance";
const lessonUrl = "/lesson/v1/user/lesson";
const lessonAdmUrl = "/lesson/v1/admin/lesson";
const progressUrl = "/progress/v1/user/lesson-progress";

export const getLesson = (lessonId, token) => {
  const roles = ["CREATOR", "REVIEWER"];
  const decode = decodeToken(token);
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  if (
    token !== undefined &&
    decode.roles.some((role) => roles.includes(role))
  ) {
    return instance.get(`${lessonAdmUrl}/get-a-lesson/${lessonId}`,config);
  } else {
    // checkAndCallDailyLoginAPI()
    return instance.get(`${lessonUrl}/get-a-lesson/${lessonId}`);
  }
};
export const getUnitLessonProgress = (lessonProgressId) => {
  // checkAndCallDailyLoginAPI()
  return instance.get(
    `${progressUrl}/get-unit-lesson-progress/${lessonProgressId}`
  );
};
export const submitLesson = (data) => {
  // checkAndCallDailyLoginAPI()
  return instance.post(`${progressUrl}/submit-lesson-progress`, data);
};
