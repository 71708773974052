import React from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import PrimaryButtonLevelSection from "../../components/button/primary-button/PrimaryButtonLevelSection";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import { getAllRoadMapCategory } from "../../config/roadMap";
import ConstantStrings from "../../constants/ConstantStrings";
import ScrollRefWrapper from "../../detail-components/ScrollRefWrapper";
import { getRoadMap } from "../../reducers/roadmapSlice";
import {
  checkAndGetAnonymousUserId,
  getCategoryRoadmap,
} from "../../templates/helper/CheckAndGetAnonymousUserId";

const PracticeIKMC = ({ gradeId, gradeName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const token = cookies.get("signinUser") || cookies.get("register");

  const handleClickRoadMap = async () => {
    localStorage.removeItem("takeRoadMapExamId");
    localStorage.removeItem("takeRoadMapProblemSetId");
    localStorage.removeItem("submittedProblemSet");
    localStorage.removeItem("submitted");
    const { result: categories } = await getAllRoadMapCategory();
    dispatch(
      getRoadMap({
        gradeId: cookies.get("gradeId"),
        isAnonymous: token ? false : true,
        anonymousUserId: checkAndGetAnonymousUserId(),
        categoryId: categories.filter(
          (item) => item.name === getCategoryRoadmap(gradeName)
        )[0]._id,
      })
    );
    navigate("/roadmap-exam");
  };
  return (
    <div
      className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center is-relative"
      style={{ minHeight: "100vh", top: "-2.25rem" }}
    >
      <ScrollRefWrapper />
      <div className="is-flex is-flex-direction-column is-align-items-center is-flex-gap-5 has-text-kurio-main-color-black">
        <p
          className="has-text-weight-bold"
          style={{
            fontSize: "2rem",
            lineHeight: "normal",
            fontStyle: "normal",
          }}
        >
          Luyện thi
        </p>
        <BackgroundImageFromUrl
          className="is-flex is-align-items-center is-align-self-stretch is-justify-content-center has-text-kurio-main-color-white"
          objectId={
             "admin/frontend-images/exam-preparation/background-exam-preparation.svg"
          }
          style={{
            borderRadius: "1rem",
            border: "0.09375rem solid #0A2A66",
            boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
            gap: "0.625rem",
            width: "52rem",
            height: "13.5rem",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <div
            className="is-flex is-align-items-center is-justify-content-space-between"
            style={{ width: "47.5rem" }}
          >
            
            <ImageFromUrl
              style={{ width: "10.66763rem", height: "10rem" }}
              objectId="admin/frontend-images/road-map/exam-preparation-image.png"
              alt=""
            />
            <img
              style={{
                width: "15.0625rem",
                height: "7.625rem",
                objectFit: "contain",
              }}
              src="/images/exam-preparation/text-banner.png"
              alt=""
            />

            <div
              className="is-flex is-flex-direction-column is-flex-gap-4 is-justify-content-center is-align-items-center"
              style={{
                fontSize: "1rem",
                lineHeight: "normal",
                height: "8.75rem",
                width: "13.9375rem",
              }}
            >
              <p>
                {ConstantStrings.HIDE_GRADES.includes(gradeName)
                  ? "Lộ trình Ôn thi IKMC được thiết kế để tối đa kết quả học tập cho từng học sinh!"
                  : "Ôn thi học kỳ chưa bao giờ trở nên đơn giản và hiệu quả như bây giờ!"}
              </p>
              <PrimaryButtonPageLevel
                isDarkColor={true}
                onClick={handleClickRoadMap}
                style={{
                  height: "4rem",
                  width: "12.5rem",
                  maxWidth: "12.5rem",
                  borderRadius: "1rem",
                }}
              >
                làm ngay
              </PrimaryButtonPageLevel>
            </div>
          </div>
        </BackgroundImageFromUrl>
        <div className=" is-flex is-flex-gap-6 is-align-items-flex-start is-justify-content-center">
          <BackgroundImageFromUrl
            objectId={
              "admin/frontend-images/practice-ikmc/exam-question-vault-bg.svg"
            }
            className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
            style={{
              border: "0.09375rem solid #0A2A66",
              borderRadius: "1rem",
              width: "25rem",
              height: "15rem",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
            }}
          >
            <div
              className="is-flex is-flex-direction-column is-flex-gap-4 is-align-items-center is-justify-content-center"
              style={{ width: "20.5rem" }}
            >
              <p
                className="has-text-weight-normal has-text-centered"
                style={{
                  fontSize: "2rem",
                  fontFamily: "Dela Gothic One",
                  fontStyle: "normal",
                  lineHeight: "110%",
                }}
              >
                Đề thi IKMC
              </p>
              <p
                className="has-text-centered"
                style={{
                  fontSize: "1rem",
                  fontStyle: "normal",
                  lineHeight: "normal",
                }}
              >
                Trải nghiệm chân thực Đề thi IKMC các năm trước với chức năng
                Phòng Thi Ảo.
              </p>
              <PrimaryButtonLevelSection
                onClick={() => navigate("/exams-vault")}
              >
                tới kho đề
              </PrimaryButtonLevelSection>
            </div>
          </BackgroundImageFromUrl>
          <BackgroundImageFromUrl
            objectId={
              "admin/frontend-images/practice-ikmc/challenge-ikmc-bg.svg"
            }
            className="is-flex is-align-items-center is-justify-content-center has-text-kurio-main-color-white"
            style={{
              border: "0.09375rem solid #0A2A66",
              borderRadius: "1rem",
              width: "25rem",
              height: "15rem",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
            }}
          >
            <div
              className="is-flex is-flex-direction-column is-flex-gap-4 is-align-items-center is-justify-content-center"
              style={{ width: "20.5rem" }}
            >
              <p
                className="has-text-weight-normal has-text-centered"
                style={{
                  fontSize: "2rem",
                  fontFamily: "Dela Gothic One",
                  fontStyle: "normal",
                  lineHeight: "110%",
                }}
              >
                Thử thách IKMC
              </p>
              <p
                className="has-text-centered"
                style={{
                  fontSize: "1rem",
                  fontStyle: "normal",
                  lineHeight: "normal",
                }}
              >
                Luyện tập ngẫu nhiên với hệ thống câu hỏi mô phỏng Đề thi IKMC.
              </p>
              <PrimaryButtonLevelSection
                isDarkColor={true}
                onClick={() => navigate("/challenge-ikmc")}
              >
                bắt đầu
              </PrimaryButtonLevelSection>
            </div>
          </BackgroundImageFromUrl>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    gradeId: state.grades.gradeId,
    gradeName: state.grades.gradeName,
  };
}

export default connect(mapStateToProps)(PracticeIKMC);
