import { useEffect, useState } from "react";
import { decodeToken } from "react-jwt";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Cookies from "universal-cookie";
import { getExamQuestion } from "../../reducers/examsQuestionSlice";
import { LoadingImage } from "../../templates/helper/LoadingImage";
import { getAllImage } from "../../templates/helper/getAllImages";
import PreviewExamContent from "./PreviewExamContent";

const PreviewExam = ({ examQuestions }) => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const token = cookies.get("signinUser");
  const dispatch = useDispatch();
  const [loadingImage, setLoadingImage] = useState(false);
  const handleSetLoadingImage = (state) => {
    setLoadingImage(state);
  };
  useEffect(() => {
    const getExamPreview = async (takeExamsQuestionId, token) => {
      try {
        dispatch(
          getExamQuestion({
            takeExamsQuestionId,
            token,
          })
        );
        setLoadingImage(true);
      } catch (error) {
        console.log("errr", error.response?.data?.errorMessage);
        alert(error.response?.data?.errorMessage);
      }
    };
    const arrayURL =
      process.env.REACT_APP_ENV === "development"
        ? JSON.parse(process.env.REACT_APP_DEV_ARRAY_ADMIN_URL)
        : JSON.parse(process.env.REACT_APP_ARRAY_ADMIN_URL);
    const handleRes = (e) => {
      if (arrayURL.some((url) => e.origin.includes(url))) {
        if (e?.data.examId && e?.data.token) {
          var roles = ["CREATOR", "REVIEWER"];
          var check = decodeToken(e?.data.token).roles.some((role) =>
            roles.includes(role)
          );
          if (!check) {
            alert("Khong duoc vao");
            navigate("/");
          } else {
            getExamPreview(e?.data.examId, e?.data.token);
          }
        }
      }
    };
    window.addEventListener("message", handleRes);
    return () => window.removeEventListener("message", handleRes);
  }, [dispatch, navigate, token]);
  if (loadingImage && examQuestions !== null && examQuestions?.length > 0) {
    const result = getAllImage(examQuestions, "EXAM");
    if (result && result?.length > 0) {
      return (
        <>
          <LoadingImage
            images={result}
            router={""}
            handleSetLoadingImage={(state) => handleSetLoadingImage(state)}
          />
        </>
      );
    } else {
      setLoadingImage(false);
    }
  }
  return (
    <>
      {/* {loadingImage && <LoadingImage />} */}
      {!loadingImage && examQuestions !== null && examQuestions?.length > 0 && (
        <div className="m-auto">{<PreviewExamContent />}</div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    examQuestions: state.examsQuestion.questions,
  };
}
export default connect(mapStateToProps)(PreviewExam);
